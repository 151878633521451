import { firebaseApp } from './firebaseFactory';
import { cleanFirestoreListResult, cleanFirestoreResult } from '../../util/firestore-utils';

export function searchCar() {
  return firebaseApp.firestore().collection('cars').get().then(cleanFirestoreListResult);
}

export function getOneCar(carId) {
  return firebaseApp.firestore().collection('cars').doc(carId).get().then(cleanFirestoreResult);
}

export function oneCarListener(carId, onResult, onError) {
  return firebaseApp.firestore().collection('cars').doc(carId).onSnapshot(onResult, onError);
}

export function getOneOwnerCar(carId) {
  return firebaseApp
    .firestore()
    .collection('cars')
    .doc(carId)
    .get()
    .then(cleanFirestoreResult)
    .then((car) => {
      return firebaseApp
        .firestore()
        .collection('cars')
        .doc(carId)
        .collection('documents')
        .get()
        .then(cleanFirestoreListResult)
        .then((documents) => ({ ...car, documents: documents }));
    });
}

export function addOneCar(car) {
  return firebaseApp.firestore().collection('cars').add(car).then(cleanFirestoreResult);
}

export function getUserCars(userId) {
  return firebaseApp.firestore().collection('cars').where('ownerId', '==', userId).get().then(cleanFirestoreListResult);
}

export function getUnValidatedUserCars(userId) {
  return firebaseApp
    .firestore()
    .collection('cars')
    .where('ownerId', '==', userId)
    .where('carValidated', '==', false)
    .get()
    .then(cleanFirestoreListResult);
}

export function updateOneCar(car) {
  const carToStore = { ...car };
  const documents = car.documents ?? [];
  documents
    .filter((doc) => !doc?.uid)
    .map((doc) => {
      firebaseApp.firestore().collection('cars').doc(car.uid).collection('documents').add(doc);
    });
  delete carToStore.documents;
  for (const i in carToStore) {
    if (['numRatings', 'translatedContent', 'visible', 'accountValidated', 'carApproved', 'carValidated', 'alert'].includes(i)) {
      delete carToStore[i];
    }
  }
  return firebaseApp.firestore().collection('cars').doc(carToStore.uid).update(carToStore);
}
