import { firebaseApp } from './firebase/firebaseFactory';

// our "constructor"
const create = () => {
  // ------
  // STEP 1
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //
  const getUser = (userId) => firebaseApp.firestore().collection('users').doc(userId).get();
  const getAllUsers = (options) => firebaseApp.firestore().collection('users').get();
  const createUser = (user) => firebaseApp.firestore().collection('users').doc(user.uid).set(user);
  const updateUser = (user) => {
    const toUp = (({
      birthday,
      conditionAccepted,
      currency,
      firstName,
      language,
      lastName,
      photoURL,
      userMode,
      countryCode,
      originalContent,
      tokens,
    }) => ({
      birthday,
      conditionAccepted,
      currency,
      firstName,
      language,
      lastName,
      photoURL,
      userMode,
      countryCode,
      originalContent,
      tokens,
    }))(user);
    for (const i in toUp) {
      if (typeof toUp[i] === 'undefined') {
        delete toUp[i];
      }
    }
    return firebaseApp.firestore().collection('users').doc(user.uid).update(toUp);
  };
  const deleteUser = (userId) => firebaseApp.firestore().collection('users').doc(userId).delete();

  const getCar = (carId) => firebaseApp.firestore().collection('users').doc(carId).get();
  const getAllCars = (options) => firebaseApp.firestore().collection('users').get();
  const createCar = (car) => firebaseApp.firestore().collection('cars').add(car);
  const updateCar = (car) => firebaseApp.firestore().collection('cars').doc(car.uid).set(car);
  const deleteCar = (carId) => firebaseApp.firestore().collection('cars').doc(carId).delete();
  // jhipster-react-native-api-method-needle

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    createUser,
    updateUser,
    getAllUsers,
    getUser,
    deleteUser,

    createCar,
    updateCar,
    getAllCars,
    getCar,
    deleteCar,
    // jhipster-react-native-api-export-needle
  };
};

// let's return back our create method as the default.
export default {
  create,
};
