import React, { useState } from 'react';

import {
  Button,
  ButtonSpinner,
  ButtonText,
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  Input,
  InputField,
  ScrollView,
  VStack,
} from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { goBackOrDefault } from '../../navigation/nav-ref';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import errorToast from '../../shared/components/toast/errorToast';
import successToast from '../../shared/components/toast/successToast';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { useDidUpdateEffect } from '../../shared/util/use-did-update-effect';

function ChangePasswordScreen() {
  // Use useSelector to access the state values needed
  const error = useSelector((state) => state.changePassword.error);
  const fetching = useSelector((state) => state.changePassword.fetching);

  // No need to define mapDispatchToProps, since useDispatch will be used directly

  // setup error state for displaying error messages
  const [formData, setData] = useState({});

  const { t } = useTranslation();

  // skip the first render but check for API responses and show error if not fetching
  useDidUpdateEffect(() => {
    if (!fetching && error) {
      errorToast(error);
    } else if (!fetching) {
      successToast(t('login.passwordChanged'));
      goBackOrDefault('Profile');
    }
  }, [fetching, error, t]);

  // set up validation schema for the form
  const validationSchema = Yup.object().shape({
    password: Yup.string().required().min(6).label('Password'),
  });

  const onSubmit = async () => {
    validationSchema
      .validate(formData, { abortEarly: true })
      .then((valid) => {
        firebaseApp
          .auth()
          .currentUser.updatePassword(formData.password)
          .then(() => {
            successToast(t('login.passwordChanged'));
            goBackOrDefault('Profile');
          })
          .catch((e) => errorHandler(e, true));
      })
      .catch((err) => {
        errorHandler(err, false);
        errorToast(err?.errors[0]);
      });
  };

  return (
    <ScrollView>
      <VStack space={'md'} my={'$4'} mx={'$screenMargin'}>
        <FormControl>
          <FormControlLabel>
            <FormControlLabelText>{t('login.newPassword')}</FormControlLabelText>
          </FormControlLabel>

          <Input>
            <InputField type="password" onChangeText={(value) => setData({ ...formData, password: value })} />
          </Input>
        </FormControl>
        <Button isDisabled={fetching} onPress={onSubmit}>
          {fetching && <ButtonSpinner mr="$1" />}
          <ButtonText>{t('login.changePassword')}</ButtonText>
        </Button>
      </VStack>
    </ScrollView>
  );
}

export default ChangePasswordScreen;
