import React, { useEffect, useRef } from 'react';
import { Platform, useWindowDimensions } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Button, ButtonText, Heading, HStack, Icon, Text } from '@gluestack-ui/themed';
import { VStack } from '@gluestack-ui/themed';
import { Pressable } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { useTranslation } from 'react-i18next';

import HeroPagePro from './components/hero';
import SectionContact from './components/sectionContact';
import SectionImagePoint from './components/sectionImagePoint';
import SectionPoint from './components/sectionPoint';
import SectionReview from './components/sectionReview';
import StatsBarPagePro from './components/statsBar';
import { goBackOrDefault } from '../../navigation/nav-ref';
import Layout from '../../shared/components/layout/layout';
import WebScrollView from '../../shared/components/web/webScrollView';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import imagesUrl from '../../shared/themes/imagesUrl';
import { renderDone } from '../../shared/util/web.utils';

function openLink(url) {
  if (Platform.OS === 'web') {
    window.open(url, '_blank');
  } else {
    Linking.openURL(url); // normal Linking react-native
  }
}

const solveArray = [
  {
    title: 'landingPro.solve1Title',
    description: 'landingPro.solve1Description',
    imageUrl: imagesUrl.iconCard,
    onPress: () => openLink('https://help.flyandcar.com/for-owner/payments'),
  },
  {
    title: 'landingPro.solve2Title',
    description: 'landingPro.solve2Description',
    imageUrl: imagesUrl.iconTarget,
    onPress: () => openLink('https://help.flyandcar.com/for-owner/manage-booking/car-quotation'),
  },
  {
    title: 'landingPro.solve3Title',
    description: 'landingPro.solve3Description',
    imageUrl: imagesUrl.iconBell,
    onPress: () => openLink('https://help.flyandcar.com/for-owner/manage-booking'),
  },
  {
    title: 'landingPro.solve4Title',
    description: 'landingPro.solve4Description',
    imageUrl: imagesUrl.iconPlay,
    onPress: () => openLink('https://help.flyandcar.com/for-owner/manage-booking/car-inspection-process'),
  },
  {
    title: 'landingPro.solve5Title',
    description: 'landingPro.solve5Description',
    imageUrl: imagesUrl.iconMessage,
    onPress: () => openLink('https://help.flyandcar.com/for-owner/manage-booking/messaging-driver'),
  },
  {
    title: 'landingPro.solve6Title',
    description: 'landingPro.solve6Description',
    imageUrl: imagesUrl.iconDocument,
    onPress: () => openLink('https://help.flyandcar.com/for-owner/getting-started/car-documents'),
  },
];

export default function LandingPagePro() {
  const { width } = useWindowDimensions();
  const maxWidth = useMaxWidth();
  const { t } = useTranslation();
  const navigation = useNavigation();

  useEffect(() => {
    renderDone();
  }, []);

  const maxW = maxWidth > 1200 ? 1200 : maxWidth;

  function getStarted() {
    navigation.navigate('AddCarFlow');
  }

  return (
    <Layout title={t('landingPro.title')} description={t('landingPro.subTitle')} image={imagesUrl.previewPro}>
      <WebScrollView alignItems={'center'} maxWidth={width}>
        <VStack alignItems={'center'} $base-gap={'$8'} $md-gap={'$16'}>
          <Box px={'$screenMargin'} w={maxW}>
            <Pressable
              onPress={() => goBackOrDefault('Search')}
              bgColor="$white"
              h={'$8'}
              w={'$8'}
              my={'$4'}
              rounded={'$full'}
              alignContent="center"
              justifyContent="center"
              alignSelf="flex-start"
              hardShadow="1">
              <Icon as={Ionicons} name={'chevron-back-outline'} color="$black" />
            </Pressable>
            <HeroPagePro
              title={t('landingPro.title')}
              subTitle={t('landingPro.subTitle')}
              imageUrl={imagesUrl.phonePro}
              maxWidth={maxW}
              button2OnPress={getStarted}
              button1OnPress={() => openLink('https://calendly.com/flyncar/demo')}
              button1Text={t('landingPro.askDemo')}
              button2Text={t('landingPro.getStarted')}
            />
          </Box>
          <Box w={width}>
            <StatsBarPagePro
              maxWidth={maxW}
              stats={[
                { title: t('landingPro.stat1Title'), subTitle: t('landingPro.stat1Description') },
                { title: t('landingPro.stat2Title'), subTitle: t('landingPro.stat2Description') },
                { title: t('landingPro.stat3Title'), subTitle: t('landingPro.stat3Description') },
              ]}
            />
          </Box>

          <Box px={'$screenMargin'} w={maxW}>
            <SectionImagePoint
              title={t('landingPro.title2')}
              imageUrl={imagesUrl.benefitPro}
              points={[
                {
                  title: t('landingPro.benefit1Title'),
                  imageUrl: imagesUrl.iconFlash,
                  texts: [
                    t('landingPro.benefit1DescriptionP1'),
                    t('landingPro.benefit1DescriptionP2'),
                    t('landingPro.benefit1DescriptionP3'),
                  ],
                },
                {
                  title: t('landingPro.benefit2Title'),
                  imageUrl: imagesUrl.iconClock,
                  texts: [
                    t('landingPro.benefit2DescriptionP1'),
                    t('landingPro.benefit2DescriptionP2'),
                    t('landingPro.benefit2DescriptionP3'),
                  ],
                },
                {
                  title: t('landingPro.benefit3Title'),
                  imageUrl: imagesUrl.iconSuccess,
                  texts: [
                    t('landingPro.benefit3DescriptionP1'),
                    t('landingPro.benefit3DescriptionP2'),
                    t('landingPro.benefit3DescriptionP3'),
                  ],
                },
              ]}
            />
          </Box>
          <Box w={width} bgColor="$white" alignItems="center" py={'$4'}>
            <Box px={'$screenMargin'} my={'$4'} w={maxW}>
              <SectionPoint
                maxWidth={maxWidth}
                points={solveArray}
                title={t('landingPro.title3')}
                buttonText={t('landingPro.getStarted')}
                onPressButton={getStarted}
              />
            </Box>
          </Box>
          <Box px={'$screenMargin'} w={maxW}>
            <SectionReview
              name={'CoolRides'}
              review={t('landingPro.reviewCoolRide')}
              imageUrl={'https://assets.flyandcar.com/profile/051783e1-8273-413f-8be3-034c7c0a63d7.jpeg'}
            />
          </Box>
          <Box w={width} bgColor="$white" alignItems="center" py={'$4'}>
            <Box px={'$screenMargin'} my={'$4'} w={maxW}>
              <SectionContact title={t('landingPro.contactTitle')} description={t('landingPro.contactDescription')} />
            </Box>
          </Box>
        </VStack>
      </WebScrollView>
    </Layout>
  );
}
