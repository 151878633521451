import React, { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Divider, FlatList, Heading, HStack, Icon, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';

import { getUserCars } from '../../services/firebase/car-firebase';
import { Metrics } from '../../themes';
import metrics from '../../themes/metrics';
import { getThumbnailUrl } from '../../util/car-utils';
import { resizedImage } from '../../util/image-utils';
import { errorHandler } from '../errorHandler/errorHander';
import Image from '../image/image';
import Skeleton from '../skeleton/skeleton';

export default function UserCarsCarousel({ userId, title, onPress, displayAllCar }) {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigation = useNavigation();

  useEffect(() => {
    console.debug('Effect: refresh userId');
    if (userId) {
      setLoading(true);
      getUserCars(userId)
        .then((res) => {
          setCars(res);
          setLoading(false);
        })
        .catch((e) => {
          errorHandler(e, true);
          setLoading(false);
        });
    }
  }, [userId]);

  function renderItem({ item, index }) {
    return (
      <TouchableOpacity onPress={() => (onPress ? onPress(item) : navigation.navigate('CarDetail', { carId: item.uid }))}>
        <VStack space={'xs'} maxWidth={metrics.images.xlarge} mr={'$4'} {...(index === 0 ? { ml: '$screenMargin' } : null)}>
          <Image
            rounded={'$2xl'}
            source={{ uri: resizedImage(getThumbnailUrl(item?.images)) }}
            w={metrics.images.xlarge}
            h={metrics.images.logo}
            alt={'thumbnail'}
          />
          <Heading numberOfLines={1} size={'h4'}>
            {item.brand + ' ' + item.model}
          </Heading>
          <HStack alignItems={'center'} space={'sm'}>
            <Icon as={Ionicons} size={'xs'} name={'star'} color={'$light700'} />
            <Text variant={'grey'} size={'secondary'}>
              {item?.avgRating + ' (' + item?.numRatings + ')'}
            </Text>
          </HStack>
        </VStack>
      </TouchableOpacity>
    );
  }

  if (loading) {
    return (
      <VStack space={'md'} mx={'$screenMargin'} mb={'$4'}>
        <Skeleton width={'35%'} height={'$6'} rounded={'$full'} mb={'$4'} />
        <HStack space={'md'}>
          <VStack space={'xs'}>
            <Skeleton w={metrics.images.xlarge} h={metrics.images.logo} rounded="$lg" />
            <Skeleton.Text lines={1} width={'40%'} />
          </VStack>
          <VStack space={'xs'}>
            <Skeleton w={metrics.images.xlarge} h={metrics.images.logo} rounded="$lg" />
            <Skeleton.Text lines={1} width={'40%'} />
          </VStack>
          <VStack space={'xs'}>
            <Skeleton w={metrics.images.xlarge} h={metrics.images.logo} rounded="$lg" />
            <Skeleton.Text lines={1} width={'40%'} />
          </VStack>
        </HStack>
      </VStack>
    );
  }
  return (
    <VStack space={'md'} w={'$full'}>
      {cars.length > 0 ? <Heading mx={'$screenMargin'}>{title}</Heading> : null}
      <FlatList
        showsHorizontalScrollIndicator={false}
        data={!displayAllCar ? cars.filter((c) => c?.visible === true) : cars}
        horizontal
        renderItem={renderItem}
        keyExtractor={(item) => item.uid}
      />
      {cars.length > 0 ? (
        <Box mx={'$screenMargin'}>
          <Divider my={'$4'} />
        </Box>
      ) : null}
    </VStack>
  );
}
