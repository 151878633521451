import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { Box, Button, ButtonText, Divider, Heading, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CountDown from '../../shared/components/countDown/countDown';
import DeliveryLocationDisplay from '../../shared/components/deliveryLocation/deliveryLocationDisplay';
import MessageButton from '../../shared/components/entities/message/messageButton';
import ProfileCardById from '../../shared/components/entities/user/ProfileCardById';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import CarDetailCover from '../../shared/components/info/carDetailCover';
import CarRentalDetailBar from '../../shared/components/info/carRentalBarDetail';
import InvoiceFullDetail from '../../shared/components/info/invoiceFullDetail';
import InsuranceDisplay from '../../shared/components/insurance/insurance';
import { PressableRow } from '../../shared/components/pressable/pressableRow';
import UnderLineButton from '../../shared/components/pressable/underLineButton';
import WebScrollView from '../../shared/components/web/webScrollView';
import WebSpliter from '../../shared/components/web/webSpliter';
import { getOneCar } from '../../shared/services/firebase/car-firebase';
import { oneReservationListener } from '../../shared/services/firebase/reservation-firebase';
import { Metrics } from '../../shared/themes';
import { reservationStatus } from '../../shared/util/car-utils';
import { checkCarTypes } from '../../shared/util/check-car-steps';
import { formatDateByLocaleWithCheckTime } from '../../shared/util/date-transforms';
import { screenBreakPoint } from '../../shared/util/device-utils';
import { cleanFirestoreResult } from '../../shared/util/firestore-utils';
import { InternalLinks } from '../../shared/util/link-utils';

function ReservationDetail({ route }) {
  const { carId, reservationId } = route?.params ?? {};

  const [reservation, setReservation] = useState({});
  const [additionalPaymentToPay, setAdditionalPaymentToPay] = useState([]);
  const [reservationLoading, setReservationLoading] = useState(true);
  const [carLoading, setCarLoading] = useState(true);
  const [car, setCar] = useState({});

  const { t } = useTranslation();
  const navigation = useNavigation();
  const { width } = useWindowDimensions();

  const isMobile = useMemo(() => screenBreakPoint(width, [true, true, false, false, false]), [width]);

  useEffect(() => {
    console.debug('Effect: refresh navigation');
    navigation.getParent()?.setOptions({
      tabBarStyle: {
        display: 'none',
      },
    });
    return () =>
      navigation.getParent()?.setOptions({
        tabBarStyle: undefined,
      });
  }, [navigation]);

  function onCarSnapshot(snapshot) {
    const cleanData = cleanFirestoreResult(snapshot);
    setReservation(cleanData);
    fetchCar(cleanData?.carId);
    setAdditionalPaymentToPay(
      cleanData?.additionalPayments ? cleanData?.additionalPayments.filter((aP) => aP?.paymentStatus !== 'success') : [],
    );
    setReservationLoading(false);
  }

  function onCarSnapshotError(e) {
    errorHandler(e, true);
    setReservationLoading(false);
  }

  useEffect(() => {
    console.debug('Effect: refresh user reservation');
    if (reservationId) {
      const subscriber = oneReservationListener(reservationId, onCarSnapshot, onCarSnapshotError);
      return () => subscriber();
    }
  }, [reservationId]);

  function fetchCar(carID) {
    setCarLoading(true);
    getOneCar(carID)
      .then((res) => {
        setCar(res);
        setCarLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setCarLoading(false);
      });
  }
  useEffect(() => {
    console.debug('Effect: refresh carId');
    fetchCar(carId);
  }, [carId]);

  const loading = (reservationLoading && !reservation?.uid) || (carLoading && !car?.uid);

  const checkCarPrepareTime = useCallback(() => {
    return new Date(reservation?.to * 1000).getTime() / 1000 + reservation?.checkOutHour * 60 * 60 + reservation?.checkOutMinute * 60;
  }, [reservation]);

  return (
    <WebScrollView>
      <VStack space={'md'} mb={'$4'}>
        <Box mx={isMobile ? 0 : Metrics.screenWidthMarging}>
          <CarDetailCover isMobile={isMobile} carId={carId} images={car?.images} hideShare={true} />
        </Box>
        <WebSpliter
          space={'md'}
          left={
            <VStack space={'md'} mx={'$screenMargin'}>
              <CarRentalDetailBar car={car} reservation={reservation} />
              {reservation?.isAdditionalPaymentToPay &&
                additionalPaymentToPay
                  .filter((aP) => aP.status !== reservationStatus.payementSucced)
                  .map((aP) => (
                    <UnderLineButton
                      key={aP.uid}
                      color={'$red400'}
                      hideLeftIcon={true}
                      onPress={() =>
                        navigation.navigate('AdditionalPayment', { reservationId: reservationId, additionalPaymentId: aP.uid })
                      }
                      text={t('rental.additionalPaymentRequested')}
                    />
                  ))}
              {reservation?.checkCarNextStep === checkCarTypes.signIn && reservation?.driverAction === 'SIGN' && (
                <VStack space={'sm'}>
                  <Text variant={'grey'} size={'secondary'}>
                    {formatDateByLocaleWithCheckTime(reservation.from, reservation.checkInHour, reservation.checkInMinute)}
                  </Text>
                  <Button
                    onPress={() =>
                      navigation.navigate('AddCheckCarScreen', {
                        reservationId: reservation?.uid,
                        carId: reservation?.carId,
                        type: checkCarTypes.signIn,
                        isDriver: true,
                      })
                    }>
                    <ButtonText>{t('rental.signIn')}</ButtonText>
                  </Button>
                  <Divider mt={'$2'} />
                </VStack>
              )}
              {reservation?.checkCarNextStep === checkCarTypes.postCheck && (
                <VStack space={'sm'}>
                  <Text>{t('rental.prepareCheckOutInfo')}</Text>
                  <Text variant={'grey'} size={'secondary'}>
                    {formatDateByLocaleWithCheckTime(reservation.to, reservation.checkOutHour, reservation.checkOutMinute)}
                  </Text>
                  <Button
                    onPress={() =>
                      navigation.navigate('AddCheckCarScreen', {
                        reservationId: reservation?.uid,
                        carId: reservation?.carId,
                        type: checkCarTypes?.postCheck,
                      })
                    }
                    isDisabled={checkCarPrepareTime() > new Date().getTime() / 1000}>
                    <ButtonText>
                      {checkCarPrepareTime() > new Date().getTime() / 1000 ? (
                        <Text alignSelf={'center'} color={'$white'}>
                          {t('rental.prepareCheckOutIn')} <CountDown durationUntil={12 * 60 * 60} to={checkCarPrepareTime()} />
                        </Text>
                      ) : (
                        t('rental.prepareCheckOut')
                      )}
                    </ButtonText>
                  </Button>
                  <Divider mt={'$2'} />
                </VStack>
              )}
              <DeliveryLocationDisplay
                car={reservation?.car}
                loading={reservationLoading}
                location={reservation?.delivery}
                isReadOnly={true}
              />
              <Divider />
            </VStack>
          }
          right={
            <VStack mx={'$screenMargin'} space={'md'}>
              <Heading>{t('rental.carReservation')}</Heading>
              <VStack mt={-4}>
                <PressableRow
                  isSkeleton={loading}
                  text={t('rental.yourCar')}
                  leftIconName={'car-sport-outline'}
                  onPress={() => navigation.navigate('CarDetail', { carId: car?.uid, hideBookingAction: true })}
                />
                <Divider />
                <InsuranceDisplay insuranceDocuments={reservation?.insuranceDocuments} />
                <PressableRow
                  isSkeleton={loading}
                  onPress={() =>
                    navigation.navigate('CheckCarScreen', {
                      reservationId: reservation?.uid,
                    })
                  }
                  text={t('rental.seeChecking')}
                  leftIconName={'checkmark-circle-outline'}
                />
                <Divider />
                <InvoiceFullDetail reservation={reservation} invoiceKey={'invoice'} loading={loading} navigation={navigation} />
                <Divider />
                <PressableRow
                  leftIconName={'help-circle-outline'}
                  text={t('rental.getHelp')}
                  onPress={() => navigation.navigate('CustomWebView', { url: InternalLinks.driverGetHelp, title: t('rental.getHelp') })}
                />
                <VStack space={'md'}>
                  <Divider />
                  <Heading>{t('car.owner')}</Heading>
                  <VStack space={'md'}>
                    <ProfileCardById
                      userId={car?.ownerId}
                      onPress={() => navigation.navigate('PublicProfileScreen', { userId: car?.ownerId })}
                    />
                    <MessageButton variant={'outline'} userToContact={car.ownerId} carId={car.uid} text={t('car.contactOwner')} />
                  </VStack>
                </VStack>
              </VStack>
            </VStack>
          }
        />
      </VStack>
    </WebScrollView>
  );
}

export default ReservationDetail;
