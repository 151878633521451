export const userDocumentStatus = {
  waintingApproval: 'WAITING_APPROVAL',
  approved: 'APPROVED',
  rejected: 'REJECTED',
  toDelete: 'TO_DELETE',
};

export const userDocumentType = {
  driverLicence: 'DRIVER_LICENCE',
  id: 'ID',
};
