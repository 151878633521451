import React, { createContext, useContext, useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

import { screenBreakPoint } from '../util/device-utils';

// Create context
export const MaxWidthContext = createContext();

export const MaxWidthProvider = ({ children }) => {
  const { width } = useWindowDimensions();
  const maxWidth = useMemo(() => screenBreakPoint(width, [width, width, width, 990, 1080]), [width]);

  return <MaxWidthContext.Provider value={maxWidth}>{children}</MaxWidthContext.Provider>;
};

export const useMaxWidth = () => {
  const maxWidth = useContext(MaxWidthContext);
  return maxWidth;
};
