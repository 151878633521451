import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import ReservationCard from './reservationCard';
import { getOneReservation } from '../../../services/firebase/reservation-firebase';
import { errorHandler } from '../../errorHandler/errorHander';

export default function ReservationCardById({ reservationId, onPress, disabled }) {
  const currentUser = useSelector((state) => state.users.currentUser);

  const [reservation, setReservation] = useState({});

  useEffect(() => {
    if (reservationId && currentUser?.uid) {
      getOneReservation(reservationId)
        .then((r) => setReservation(r))
        .catch((e) => errorHandler(e, true));
    }
  }, [currentUser?.uid, reservationId]);

  return <ReservationCard reservation={reservation} disabled={disabled} onPress={onPress} />;
}
