import { Dimensions, Platform } from 'react-native';

export const PAGE_WIDTH = Dimensions.get('window').width;

export const IS_WEB = Platform.OS === 'web';

export const screenBreakPoint = (width, breakpoint) => {
  if (width > 1200) return breakpoint[4];
  if (width > 992) return breakpoint[3];
  if (width > 768) return breakpoint[2];
  if (width > 576) return breakpoint[1];

  return breakpoint[0];
};

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'Desktop';
}
