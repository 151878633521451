import React, { useEffect, useState } from 'react';

import { Button, ButtonSpinner, ButtonText, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import ConfirmationCode from '../../shared/components/confirmationCode/confirmationCode';
import CardContainer from '../../shared/components/container/cardContainer';
import UserConnectionListener from '../../shared/components/entities/user/userConnectionListener';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Layout from '../../shared/components/layout/layout';
import errorToast from '../../shared/components/toast/errorToast';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { IS_WEB } from '../../shared/util/device-utils';

function VerificationCode({ route }) {
  const { phoneNumber } = route?.params ?? {};

  const currentUser = useSelector((state) => state.users.currentUser);
  const lastRedirectableRoute = useSelector((state) => state.users.lastRedirectableRoute);

  // If null, no SMS has been sent
  const [confirm, setConfirm] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [code, setCode] = useState();
  const [userId, setUserId] = useState(null);

  const { t } = useTranslation();
  const navigation = useNavigation();

  useEffect(() => {
    console.debug('Effect: refresh currentUser');
    if (currentUser?.uid && currentUser?.conditionAccepted) {
      if (lastRedirectableRoute) {
        navigation.navigate(lastRedirectableRoute?.name, lastRedirectableRoute?.params);
      } else {
        navigation.popToTop();
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, navigation]);

  useEffect(() => {
    console.debug('Effect: refresh SignIn');
    signInWithPhoneNumber(phoneNumber);
  }, [phoneNumber]);

  // Handle the button press
  async function signInWithPhoneNumber(phone) {
    if (IS_WEB) {
      try {
        setLoading(true);
        const recaptcha = new firebaseApp.auth.RecaptchaVerifier('recaptcha-container', {
          size: 'invisible',
        });
        const confirmation = await firebaseApp.auth().signInWithPhoneNumber(phone, recaptcha);
        setConfirm(confirmation);
        setLoading(false);
      } catch (e) {
        errorHandler(e, true);
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const confirmation = await firebaseApp.auth().signInWithPhoneNumber(phone);
        setConfirm(confirmation);
        setLoading(false);
      } catch (e) {
        errorHandler(e, true);
        setLoading(false);
      }
    }
  }

  async function confirmCode(phoneCode) {
    try {
      setLoading(true);
      confirm.confirm(phoneCode).then((user) => {
        setUserId(user.user.uid);
        setLoading(false);
      });
    } catch (error) {
      errorHandler(error, true);
      setLoading(false);
    }
  }

  // set up validation schema for the form
  const validationSchemaCode = Yup.object().shape({
    code: Yup.string().required().min(6).max(6).label('Code'),
  });

  const onSubmitPhoneCode = async () => {
    setLoadingCode(true);
    validationSchemaCode
      .validate({ code: code }, { abortEarly: true })
      .then((valid) => {
        confirmCode(code);
        setLoadingCode(false);
      })
      .catch((err) => {
        errorHandler(err, false);
        errorToast(err?.errors[0]);
        setLoadingCode(false);
      });
  };

  return (
    <Layout>
      <CardContainer>
        <VStack mt={'$4'} mx={'$screenMargin'} space={'md'}>
          <Text variant={'grey'} size={'secondary'}>
            {t('login.verificationCodeDetail')}
          </Text>
          <ConfirmationCode value={code} setValue={setCode} />
          {IS_WEB && <div id="recaptcha-container" />}
          <Button mt={'$2'} onPress={onSubmitPhoneCode} isDisabled={loadingCode || loading}>
            {(loadingCode || loading) && <ButtonSpinner mr="$1" />}
            <ButtonText>{t('login.verifyPhoneCode')}</ButtonText>
          </Button>
          <UserConnectionListener userId={userId} />
        </VStack>
      </CardContainer>
    </Layout>
  );
}

export default VerificationCode;
