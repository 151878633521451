import React, { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, FlatList, Text } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import AgendaComponent from './agenda';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Layout from '../../shared/components/layout/layout';
import errorToast from '../../shared/components/toast/errorToast';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { Colors } from '../../shared/themes';
import { lightnessGradient, stringToColour } from '../../shared/util/color-utils';
import { cleanFirestoreListResult } from '../../shared/util/firestore-utils';

function OwnerCalendarScreen() {
  const currentUser = useSelector((state) => state.users.currentUser);

  const [loading, setLoading] = useState(true);
  const [carFilter, setCarFilter] = useState([]);
  const [markedDates, setMarkedDates] = useState({ uid: uuidv4() });

  const maxWidth = useMaxWidth();

  const { t } = useTranslation();

  const [allCars, setAllCars] = useState({
    name: t('global.allCars'),
    color: Colors.blue[400],
    uid: uuidv4(),
    isAllCars: true,
    isSelected: true,
    bookedDays: [],
    eventDays: [],
    ownerBookedDays: [],
  });

  useEffect(() => {
    console.debug('Effect: reload cars of owner');
    if (currentUser?.uid) {
      const subscriber = firebaseApp
        .firestore()
        .collection('cars')
        .where('ownerId', '==', currentUser?.uid)
        .onSnapshot((res) => {
          const data = cleanFirestoreListResult(res);
          setCarFilter(
            data.map((r) => ({
              name: `${r.brand} ${r.model}`,
              uid: r.uid,
              isSelected: false,
              isAllCars: false,
              bookedDays: r?.bookedDays ?? [],
              eventDays: r?.eventDays ?? [],
              ownerBookedDays: r?.ownerBookedDays ?? [],
            })),
          );
          setLoading(false);
        });

      return () => subscriber();
    }
  }, [currentUser?.uid]);

  async function updateCalendar(carFilt, allC) {
    try {
      console.debug('Effect: calendar filter chang');
      const marked = { uid: uuidv4() };
      if (allC.isSelected) {
        carFilt &&
          carFilt?.length &&
          carFilt.forEach((car) => {
            car?.eventDays &&
              car?.eventDays?.length &&
              car?.eventDays.forEach((d) => {
                marked[d] = {
                  isEvent: true,
                };
              });
          });
        setMarkedDates(marked);
      } else {
        carFilt &&
          carFilt?.length &&
          carFilt
            .filter((c) => c.isSelected)
            .forEach((car, i) => {
              const maxIndex = carFilt.filter((c) => c.isSelected).length;
              car?.ownerBookedDays &&
                car?.ownerBookedDays.length &&
                car.ownerBookedDays.forEach((d) => {
                  if (marked[d]) {
                    const prevBlockeds = marked[d]?.blockeds || [];
                    marked[d] = {
                      ...marked[d],
                      blockeds: [...prevBlockeds, { index: i, color: stringToColour(car.uid) }],
                    };
                  } else {
                    marked[d] = { blockeds: [{ index: i, color: stringToColour(car.uid) }], periods: [], dots: [], maxIndex: maxIndex };
                  }
                });
              car?.bookedDays &&
                car?.bookedDays.length &&
                car.bookedDays.forEach((d) => {
                  if (!car?.ownerBookedDays?.includes(d)) {
                    if (marked[d]) {
                      marked[d] = {
                        ...marked[d],
                        periods: [...marked[d].periods, { index: i, color: stringToColour(car.uid) }],
                      };
                    } else {
                      marked[d] = { periods: [{ index: i, color: stringToColour(car.uid) }], dots: [], maxIndex: maxIndex };
                    }
                  }
                });
              car?.eventDays &&
                car?.eventDays.length &&
                car.eventDays.forEach((d) => {
                  if (marked[d]) {
                    marked[d] = {
                      ...marked[d],
                      dots: [...marked[d].dots, { index: i, color: stringToColour(car.uid) }],
                    };
                  } else {
                    marked[d] = { periods: [], dots: [{ index: i, color: stringToColour(car.uid) }], maxIndex: maxIndex };
                  }
                });
            });
        setMarkedDates(marked);
      }
    } catch (e) {
      errorHandler(e, true);
    }
  }

  useEffect(() => {
    updateCalendar(carFilter, allCars);
  }, [carFilter, allCars]);

  async function pressFilter(item) {
    if (item?.isAllCars) {
      setAllCars((prev) => ({ ...prev, isSelected: !prev.isSelected }));
      if (!item.isSelected) {
        setCarFilter((carFil) => carFil.map((car) => ({ ...car, isSelected: false })));
      }
    } else if (carFilter.filter((c) => c.isSelected).length >= 3 && !item.isSelected) {
      errorToast(t('error.calendarMoreThan3Filter'));
    } else {
      if (item.isSelected) {
        setCarFilter((prevState) => [
          ...prevState.filter((r) => r.uid !== item.uid).map((c) => ({ ...c, isSelected: false })),
          { ...item, isSelected: !item.isSelected },
        ]);
        setAllCars((prev) => ({ ...prev, isSelected: true }));
      } else {
        setAllCars((prev) => ({ ...prev, isSelected: false }));
        setCarFilter((prevState) => [
          { ...item, isSelected: !item.isSelected },
          ...prevState.filter((r) => r.uid !== item.uid).map((c) => ({ ...c, isSelected: false })),
        ]);
      }
    }
  }

  function renderItem({ item }) {
    const color = item?.color ?? stringToColour(item.uid);
    return (
      <TouchableOpacity onPress={() => pressFilter(item)}>
        <Box mx={'$2'} px={'$4'} py={'$2'} rounded="$full" backgroundColor={item.isSelected ? color : '$light300'}>
          <Text color={item.isSelected ? (lightnessGradient(color.substring(1)) > 0.5 ? '$black' : '$white') : '$black'}>{item.name}</Text>
        </Box>
      </TouchableOpacity>
    );
  }

  return (
    <Layout>
      <Box w={maxWidth} alignSelf="center" flex={1}>
        <Box>
          <FlatList
            //refreshControl={<RefreshControl refreshing={loading} onRefresh={getAllCar} />}
            horizontal
            showsHorizontalScrollIndicator={false}
            data={[allCars, ...carFilter]}
            keyExtractor={(item) => item.uid}
            renderItem={renderItem}
          />
        </Box>

        <AgendaComponent markedDates={markedDates} />
      </Box>
    </Layout>
  );
}

export default OwnerCalendarScreen;
