import { useCallback, useState } from 'react';

import { Ionicons } from '@expo/vector-icons';
import {
  Box,
  Button,
  ButtonText,
  CheckboxIcon,
  CheckboxIndicator,
  Divider,
  Heading,
  HStack,
  Text,
  useToken,
  VStack,
} from '@gluestack-ui/themed';
import { Checkbox } from '@gluestack-ui/themed';
import { CheckboxLabel } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import ActionSheet from '../../../shared/components/actionSheet/actionSheet';
import BottomSheetFooter from '../../../shared/components/bottomSheet/BottomSheetFooter';
import BottomSheetTextInput from '../../../shared/components/bottomSheet/BottomSheetTextInput';
import InfoCard from '../../../shared/components/infoCard/infoCard';
import InputPriceCard from '../../../shared/components/input/inputPrice';
import { PressableRow } from '../../../shared/components/pressable/pressableRow';
import UnderLineButton from '../../../shared/components/pressable/underLineButton';
import { carAddingSetter } from '../../../shared/reducers/car.reducer';
import { getPriceFromCurrency } from '../../../shared/util/currency';

function ExtraOption() {
  const dispatch = useDispatch();
  const car = useSelector((state) => state.cars.carAdding);
  const currency = useSelector((state) => state.users.currency);

  const [selectedOption, setSelectedOption] = useState({});
  const [isOpen, setOpen] = useState(false);

  const { t } = useTranslation();
  const { bottom } = useSafeAreaInsets();
  const fontSizePrimary = useToken('fontSizes', 'primary');

  function setCar(updatedCar) {
    dispatch(carAddingSetter(updatedCar));
  }

  function saveOption() {
    const extraOptions = car?.extraOptions ?? [];
    const originalContent = car?.originalContent ? { ...car?.originalContent } : {};

    const oldId = selectedOption?.id ?? uuidv4();
    const nextId = uuidv4();

    originalContent[nextId] = selectedOption?.name;
    delete originalContent[oldId];

    setCar({
      ...car,
      extraOptions: [...extraOptions.filter((option) => option?.id !== oldId), { ...selectedOption, id: nextId }],
      originalContent: originalContent,
    });
    setOpen(false);
  }

  function selectOption(option) {
    setSelectedOption(option);
    setOpen(true);
  }

  function deleteSelectedOption() {
    const extraOptions = car?.extraOptions ?? [];
    const originalContent = car?.originalContent ? { ...car?.originalContent } : {};

    const oldId = selectedOption?.id ?? uuidv4();
    delete originalContent[oldId];

    setCar({ ...car, extraOptions: extraOptions.filter((option) => option?.id !== oldId), originalContent: originalContent });
    setOpen(false);
  }

  function addOption() {
    setSelectedOption({});
    setOpen(true);
  }

  function displayOptionText(option) {
    const perTime = option?.perTime === 'day' ? t('addCar.carExtraOptionPerDay') : t('addCar.carExtraOptionPerRental');
    const price = getPriceFromCurrency(option.price, car.currency, currency);
    return `${option?.name} - ${price} / ${perTime}`;
  }

  const isDisable = useCallback(() => selectedOption?.name && selectedOption?.price && selectedOption?.perTime, [selectedOption]);

  const renderFooter = useCallback(
    (props) => (
      <BottomSheetFooter {...props} bottomInset={bottom}>
        <Box backgroundColor={'$white'} mb={-bottom} paddingBottom={bottom}>
          <HStack justifyContent={'space-between'} alignItems={'center'} mt={'$4'} mx={'$screenMargin'} my={'$2'}>
            {selectedOption?.id && (
              <UnderLineButton color={'$red400'} onPress={deleteSelectedOption} text={t('global.delete')} hideLeftIcon={true} />
            )}
            <Button
              testID="button.confirmExtraOption"
              isDisabled={!isDisable()}
              onPress={saveOption}
              {...(selectedOption?.id === undefined ? { w: '100%' } : null)}>
              <ButtonText>{t('global.save')}</ButtonText>
            </Button>
          </HStack>
        </Box>
      </BottomSheetFooter>
    ),
    [bottom, selectedOption, isDisable, deleteSelectedOption, saveOption],
  );

  return (
    <VStack space={'md'}>
      <Text mb={'$4'} variant={'grey'} size={'secondary'}>
        {t('addCar.carExtraOptionDescription')}
      </Text>
      <VStack space={'md'}>
        <Heading>{t('addCar.carExtraOptionSectionTitle')}</Heading>
        <InfoCard text={t('addCar.carExtraOptionInfo')} />
        <VStack>
          {car?.extraOptions &&
            car?.extraOptions.map((option) => (
              <VStack key={option?.id}>
                <PressableRow testID={option?.name} text={displayOptionText(option)} onPress={() => selectOption(option)} />
                <Divider />
              </VStack>
            ))}
        </VStack>
        <Button testID="button.addExtraOption" variant={'outline'} onPress={addOption}>
          <ButtonText>{t('addCar.carExtraOptionButtonText')}</ButtonText>
        </Button>
      </VStack>
      <ActionSheet isOpen={isOpen} setOpen={setOpen} headerText={t('addCar.carExtraOptionSectionTitle')} renderFooter={renderFooter}>
        <VStack mx={'$screenMargin'} space={'lg'}>
          <VStack space={'md'}>
            <Heading>{t('addCar.carExtraOptionName')}</Heading>
            <HStack space={'md'}>
              <Box
                rounded={'$md'}
                borderWidth={'$1'}
                px={'$8'}
                py={'$3'}
                space={'md'}
                justifyContent={'center'}
                alignItems={'flex-start'}
                w={'60%'}>
                <BottomSheetTextInput
                  testID={'input.extraOptionName'}
                  style={{ fontSize: fontSizePrimary, minWidth: 50, width: '100%' }}
                  keyboardType={'default'}
                  textAlign={'left'}
                  placeholder={t('addCar.carExtraOptionName')}
                  defaultValue={selectedOption?.name ?? ''}
                  onChangeText={(value) => setSelectedOption((prev) => ({ ...prev, name: value }))}
                />
              </Box>
              <InputPriceCard
                testID={'input.extraOptionPrice'}
                isBottomSheet={true}
                placeHolder={t('addCar.carExtraOptionPrice')}
                currency={currency}
                dolarPrice={selectedOption?.price}
                onChangePrice={(value) => setSelectedOption((prev) => ({ ...prev, price: value }))}
                label={t('addCar.carExtraOptionPrice')}
                w={'30%'}
              />
            </HStack>
          </VStack>
          <VStack space={'md'}>
            <Heading>{t('addCar.carExtraOptionPerTime')}</Heading>
            <Checkbox
              aria-label={'day'}
              size="md"
              justifyContent={'space-between'}
              isChecked={selectedOption?.perTime === 'day'}
              onChange={() => setSelectedOption((prev) => ({ ...prev, perTime: 'day' }))}>
              <CheckboxLabel w={'80%'}>{t('addCar.carExtraOptionPerDay')}</CheckboxLabel>
              <CheckboxIndicator ml="$2">
                <CheckboxIcon as={Ionicons} name={'checkmark-outline'} />
              </CheckboxIndicator>
            </Checkbox>
            <Checkbox
              aria-label={'rental'}
              size="md"
              justifyContent={'space-between'}
              isChecked={selectedOption?.perTime === 'rental'}
              onChange={() => setSelectedOption((prev) => ({ ...prev, perTime: 'rental' }))}>
              <CheckboxLabel w={'80%'}>{t('addCar.carExtraOptionPerRental')}</CheckboxLabel>
              <CheckboxIndicator ml="$2">
                <CheckboxIcon as={Ionicons} name={'checkmark-outline'} />
              </CheckboxIndicator>
            </Checkbox>
          </VStack>
        </VStack>
      </ActionSheet>
    </VStack>
  );
}

export default ExtraOption;
