import React from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, Icon, Text, VStack } from '@gluestack-ui/themed';

export default function FeaturesCard({ testID, iconName, iconLibrary, text, isPressed, onPress }) {
  return (
    <TouchableOpacity testID={`feature.${testID}`} onPress={onPress}>
      <Box variant={'featureBox'} borderColor={isPressed ? '$black' : '$light200'}>
        <VStack m={'$4'} space={'sm'} alignItems="flex-start">
          <Icon as={iconLibrary} name={iconName} size={'xl'} alignSelf="flex-start" />
          <Text>{text}</Text>
        </VStack>
      </Box>
    </TouchableOpacity>
  );
}
