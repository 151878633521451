export function stringToColour(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

export function lightnessGradient(color) {
  // Convert a two-digit hexadecimal number to a decimal number
  const hexToDecimal = (hex) => parseInt(hex, 16) / 255;

  // Linearize color component
  const linearize = (component) => {
    if (component <= 0.03928) {
      return component / 12.92;
    } else {
      return Math.pow((component + 0.055) / 1.055, 2.4);
    }
  };

  const R = linearize(hexToDecimal(color.slice(0, 2)));
  const G = linearize(hexToDecimal(color.slice(2, 4)));
  const B = linearize(hexToDecimal(color.slice(4, 6)));

  const L = 0.2126 * R + 0.7152 * G + 0.0722 * B;

  return L;
}

export function toRGBA(hexCol, alpha = 1) {
  const hex = hexCol.toUpperCase();

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}