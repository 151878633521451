import React, { useEffect, useState } from 'react';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CheckCarDescription from './checkCarDescription';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Layout from '../../shared/components/layout/layout';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { checkCarTypes } from '../../shared/util/check-car-steps';
import { cleanFirestoreListResult } from '../../shared/util/firestore-utils';
import { tabScreenOption } from '../../shared/util/navigation-utils';

const Tab = createMaterialTopTabNavigator();

function CheckCarScreen({ route }) {
  const { reservationId } = route?.params ?? {};
  const [checks, setChecks] = useState({});
  const [loading, setLoading] = useState(true);
  const maxWidth = useMaxWidth();
  const { t } = useTranslation();

  const currentUser = useSelector((state) => state.users.currentUser);

  useEffect(() => {
    if (currentUser?.uid && reservationId) {
      setLoading(true);
      firebaseApp
        .firestore()
        .collection('checkCar')
        .where('reservationId', '==', reservationId)
        .get()
        .then(cleanFirestoreListResult)
        .then((res) => {
          setChecks(res);
          setLoading(false);
        })
        .catch((e) => {
          errorHandler(e, true);
          setLoading(false);
        });
    }
  }, [currentUser?.uid, reservationId]);

  return (
    <Layout>
      <Tab.Navigator
        initialRouteName={checkCarTypes.preCheck}
        screenOptions={{
          ...tabScreenOption,
          tabBarStyle: {
            ...tabScreenOption.tabBarStyle,
            width: maxWidth > 700 ? 700 : maxWidth,
            alignSelf: 'center',
          },
        }}>
        <Tab.Screen name={checkCarTypes.preCheck} options={{ title: t('reservation.check-in') }}>
          {(props) => (
            <CheckCarDescription
              checkCarCheck={checks?.length > 0 && checks?.filter((c) => c?.type === checkCarTypes.preCheck)?.pop()}
              checkCarSign={checks?.length > 0 && checks?.filter((c) => c?.type === checkCarTypes.signIn)?.pop()}
              loading={loading}
            />
          )}
        </Tab.Screen>
        <Tab.Screen name={checkCarTypes.signIn} options={{ title: t('reservation.check-out') }}>
          {(props) => (
            <CheckCarDescription
              checkCarCheck={checks?.length > 0 && checks?.filter((c) => c?.type === checkCarTypes.postCheck)?.pop()}
              checkCarSign={checks?.length > 0 && checks?.filter((c) => c?.type === checkCarTypes.signOut)?.pop()}
              loading={loading}
            />
          )}
        </Tab.Screen>
      </Tab.Navigator>
    </Layout>
  );
}

export default CheckCarScreen;
