import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { Button, ButtonText, Heading, VStack } from '@gluestack-ui/themed';
import { Divider } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import MyProfileCardById from '../../../shared/components/entities/user/MyProfileCardById';
import { errorHandler } from '../../../shared/components/errorHandler/errorHander';
import AppVersion from '../../../shared/components/info/appVersion';
import Layout from '../../../shared/components/layout/layout';
import ModalSwitcher from '../../../shared/components/modalSwitcher/modalSwitcher';
import { PressableRow } from '../../../shared/components/pressable/pressableRow';
import UnderLineButton from '../../../shared/components/pressable/underLineButton';
import { RentYourCar } from '../../../shared/components/static/rentYourCar';
import errorToast from '../../../shared/components/toast/errorToast';
import WebScrollView from '../../../shared/components/web/webScrollView';
import { useIsDrawer } from '../../../shared/provider/isDrawerProvider';
import { useMaxWidth } from '../../../shared/provider/maxWidthProvider';
import { setUserMode, userCurrentLogOut, userUpdateRequest } from '../../../shared/reducers/user.reducer';
import { signOut } from '../../../shared/services/firebase/auth-firebase';
import { firebaseApp } from '../../../shared/services/firebase/firebaseFactory';
import { InternalLinks } from '../../../shared/util/link-utils';
import { useDidUpdateEffect } from '../../../shared/util/use-did-update-effect';

function Profile() {
  const currentUser = useSelector((state) => state.users.currentUser);
  const userMode = useSelector((state) => state.users.userMode);
  const dispatch = useDispatch();

  const [displayScreen, setDisplayScreen] = useState({});
  const [isSwitchModalOpen, setSwitchModalOpen] = useState(false);
  const [isAdmin, setAdmin] = useState(false);

  const { t } = useTranslation();
  const navigation = useNavigation();
  const isDrawer = useIsDrawer();
  const maxWidth = useMaxWidth();

  useEffect(() => {
    if (currentUser?.uid && firebaseApp.auth().currentUser) {
      firebaseApp
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          if (idTokenResult?.claims?.admin) {
            setAdmin(true);
          } else {
            setAdmin(false);
          }
        })
        .catch((error) => {
          errorHandler(error, true);
        });
    }
  }, [currentUser]);

  useDidUpdateEffect(() => {
    const currentRoute = navigation.getState().routes[0].name;
    if (userMode && currentRoute === 'Profile') {
      console.debug('go to Search');
      navigation.navigate('Search');
    } else if (currentRoute === 'Profile') {
      console.debug('go to OwnerHome');
      navigation.navigate('OwnerHome');
    }
  }, [userMode]);

  function logout() {
    signOut().catch((e) => {
      errorHandler(e, true);
      dispatch(setUserMode(true));
      dispatch(userCurrentLogOut());
    });
    dispatch(setUserMode(true));
    dispatch(userCurrentLogOut());
  }

  function giveReview() {
    if (Platform.OS === 'android') {
      Linking.openURL('market://details?id=com.flyandcar.app&showAllReviews=true').catch((e) => errorHandler(e, true));
    } else if (Platform.OS === 'ios') {
      Linking.openURL('https://apps.apple.com/app/apple-store/id6449300955?action=write-review').catch((e) => errorHandler(e, true));
    } else {
      errorToast(t('error.noStore'));
    }
  }

  function switchUserMode() {
    setSwitchModalOpen(true);
    dispatch(userUpdateRequest({ uid: currentUser?.uid, userMode: userMode ? false : true }));
    setTimeout(() => {
      dispatch(setUserMode(!userMode));
      setSwitchModalOpen(false);
    }, 500);
  }

  function screenToDisplay(name, params) {
    navigation.navigate(name, params);
  }

  return (
    <Layout>
      <WebScrollView maxWidth={maxWidth > 700 ? 700 : maxWidth}>
        <VStack space={'lg'} mx={'$screenMargin'} my={'$4'}>
          <MyProfileCardById
            userId={currentUser?.uid}
            onPress={() => navigation.navigate('PublicProfileScreen', { userId: currentUser?.uid })}
          />
          <Divider />
          <RentYourCar />
          <Divider />
          <VStack space={'lg'}>
            {isAdmin && (
              <VStack>
                <Heading mt={'$4'}>Admin</Heading>
                <PressableRow to={{ screen: 'BackOfficeHome' }} text={t('screen.backOffice')} leftIconName={'shield-checkmark-outline'} />
                <Divider />
              </VStack>
            )}
            <Heading mt={'$4'}>{t('profile.accountSettingTitle')}</Heading>
            <VStack mt={-4}>
              <PressableRow
                onPress={() => screenToDisplay('EditProfileScreen', { register: true })}
                leftIconName={'person-circle-outline'}
                text={t('profile.personalInfo')}
              />
              <Divider />
              {userMode && (
                <>
                  <PressableRow onPress={() => screenToDisplay('CardDetail')} leftIconName={'card-outline'} text={t('profile.payment')} />
                  <Divider />
                </>
              )}
              <PressableRow
                leftIconName={'language-outline'}
                text={t('profile.language')}
                onPress={() => screenToDisplay('LanguageScreen')}
              />
              <Divider />
              <PressableRow
                leftIconName={'swap-horizontal-outline'}
                leftIconColor={'$blue400'}
                text={t(userMode ? 'profile.switchToPro' : 'profile.switchToUser')}
                onPress={switchUserMode}
              />
              <Divider />
            </VStack>
          </VStack>
          <VStack space={'md'}>
            <Heading mt={'$4'}>{t('profile.support')}</Heading>
            <VStack mt={-4}>
              <PressableRow
                leftIconName={'help-circle-outline'}
                text={t('profile.getHelp')}
                onPress={() => navigation.navigate('CustomWebView', { url: InternalLinks.helpHome, title: t('profile.getHelp') })}
              />
              <Divider />
              <PressableRow onPress={giveReview} leftIconName={'create-outline'} text={t('profile.giveFeedBack')} />
              <Divider />
            </VStack>
          </VStack>

          <VStack>
            <Heading mt={'$4'}>{t('profile.legal')}</Heading>
            <PressableRow
              leftIconName={'document-text-outline'}
              text={t('profile.termOfService')}
              onPress={() => navigation.navigate('CustomWebView', { url: InternalLinks.TOU, title: t('profile.termOfService') })}
            />
            <Divider />
            <PressableRow
              leftIconName={'document-text-outline'}
              text={t('profile.privacyPolicy')}
              onPress={() => navigation.navigate('CustomWebView', { url: InternalLinks.privacyPolicy, title: t('profile.privacyPolicy') })}
            />
            <Divider />
          </VStack>
          <VStack space={'sm'} mt={'$4'}>
            <UnderLineButton testID={'logOut'} hideLeftIcon={true} onPress={logout} text={t('login.logout')} />
            <Divider mt={'$2'} />
            <AppVersion />
            <UnderLineButton
              hideLeftIcon={true}
              onPress={() => screenToDisplay('DeleteAccountScreen')}
              text={t('delete.button')}
              color={'$red400'}
              size={'$xs'}
            />
          </VStack>
        </VStack>
      </WebScrollView>
      <ModalSwitcher
        isOpen={isSwitchModalOpen}
        setOpen={setSwitchModalOpen}
        text={t(userMode ? 'profile.switchToPro' : 'profile.switchToUser')}
      />
    </Layout>
  );
}

export default Profile;
