import React from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Heading, HStack, Icon, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { Metrics } from '../../../themes';
import { getThumbnailUrl } from '../../../util/car-utils';
import { formatDateByLocaleWithCheckTime } from '../../../util/date-transforms';
import { resizedImage } from '../../../util/image-utils';
import Image from '../../image/image';
import Skeleton from '../../skeleton/skeleton';

export default function EventCard({ reservation, onPress, disabled, type, date, cardOnly }) {
  const { t } = useTranslation();
  function getArrowNameFromType() {
    if (type === 'check-in') {
      return 'arrow-forward';
    } else {
      return 'arrow-back';
    }
  }

  if (!date || !type) {
    return (
      <HStack alignItems={'center'} w={'100%'}>
        <Box position={'absolute'} left={-17} justifyContent={'center'} width={'$10'} height={'$1'}>
          <Skeleton h={'$6'} w={'$6'} rounded={'$full'} m={'$1'} />
        </Box>
        <Box variant={'cardContainer'} width={'90%'} p={'$2'} mx={'$4'}>
          <HStack space={'md'} alignItems={'center'}>
            <Skeleton h={'$16'} w={'$16'} rounded={'$md'} />
            <Skeleton.Text lines={3} w={100} />
          </HStack>
        </Box>
      </HStack>
    );
  }

  return (
    <HStack alignItems={'center'} w={'100%'}>
      {!cardOnly && (
        <Box position={'absolute'} left={-17} justifyContent={'center'} width={'$10'} height={'$1'}>
          <Icon as={Ionicons} size={'xl'} name={getArrowNameFromType()} color={'$light500'} />
        </Box>
      )}
      <Box variant={'cardContainer'} width={'90%'} p={'$2'} mx={'$4'}>
        <Box overflow={'hidden'}>
          <TouchableOpacity disabled={disabled} onPress={onPress}>
            <HStack space={'md'} alignItems={'center'}>
              <Image
                rounded={'$md'}
                source={{ uri: resizedImage(getThumbnailUrl(reservation?.car?.images)) }}
                size={'md'}
                alt={'carThumbnail'}
              />
              <VStack justifyContent={'center'} w={'100%'}>
                <Heading size={'h4'} numberOfLines={1}>
                  {reservation?.car?.brand + ' ' + reservation?.car?.model}
                </Heading>
                <HStack space={'md'} alignItems={'center'} maxWidth={'70%'}>
                  <Text fontWeight={'$bold'} size={'secondary'}>
                    {t('reservation.' + type)}
                  </Text>
                  <Text size={'secondary'}>
                    {formatDateByLocaleWithCheckTime(
                      date.getTime() / 1000,
                      type === 'check-in' ? reservation?.checkInHour : reservation?.checkOutHour,
                      type === 'check-in' ? reservation?.checkInMinute : reservation?.checkOutMinute,
                    )}
                  </Text>
                </HStack>
                <Text variant={'grey'} size={'secondary'} numberOfLines={1} maxWidth={'70%'}>
                  {type === 'check-in' ? reservation?.delivery?.pickUp?.name : reservation?.delivery?.return?.name}
                </Text>
              </VStack>
            </HStack>
          </TouchableOpacity>
        </Box>
      </Box>
    </HStack>
  );
}
