import React, { useEffect, useState } from 'react';

import { Heading, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import InvoiceDetail from '../../../shared/components/info/invoiceDetail';
import InputPriceCard from '../../../shared/components/input/inputPriceCard';
import { quoteSetter } from '../../../shared/reducers/quote.reducer';
import { firebaseApp } from '../../../shared/services/firebase/firebaseFactory';
import { quoteToReservation } from '../../../shared/util/booking-utils';
import { formatCurrency } from '../../../shared/util/currency';
import { cleanFirestoreResult } from '../../../shared/util/firestore-utils';

function QuoteDiscount() {
  const [car, setCar] = useState({});
  const [reservation, setReservation] = useState({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser);
  const quote = useSelector((state) => state.quote.quote);
  const currency = useSelector((state) => state.users.currency);

  useEffect(() => {
    if (currentUser?.uid) {
      firebaseApp
        .firestore()
        .collection('cars')
        .doc(quote?.carId)
        .get()
        .then(cleanFirestoreResult)
        .then((resCar) => setCar(resCar));
    }
  }, [currentUser?.uid, quote?.carId]);

  useEffect(() => {
    if (car?.uid) {
      setReservation(
        quoteToReservation(currentUser, car, currency, quote?.dateRange, quote?.extraOptions, quote?.location, quote?.discountAmount ?? 0),
      );
    }
  }, [car, quote, currency, currentUser]);

  const setQuote = (quote) => {
    dispatch(quoteSetter(quote));
  };

  return (
    <VStack space={'lg'}>
      <Text variant={'grey'}>{t('quote.discountSubTitle')}</Text>
      <VStack space={'xs'}>
        <Heading>{t('quote.discount')}</Heading>
        <InputPriceCard
          placeHolder={t('addCar.price')}
          info={t('quote.discountPriceInfo')}
          currency={currency}
          dolarPrice={quote?.discountAmount}
          onChangePrice={(value) => setQuote({ ...quote, discountAmount: value, currency: 'USD' })}
        />
        <Text variant={'grey'} size={'secondary'}>
          {t('addCar.actualCurrencyInfo', { currency: currency.current })}
        </Text>
      </VStack>
      <VStack bgColor={'$light100'} rounded={'$md'} p={'$4'} space={'sm'}>
        <Heading>{t('quote.invoice')}</Heading>
        <InvoiceDetail reservationInvoice={reservation?.invoice} ownerInvoice={reservation?.invoiceOwner} isLoading={!reservation?.carId} />
      </VStack>
    </VStack>
  );
}

export default QuoteDiscount;
