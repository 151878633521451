// Assuming we're using an API client like Algolia's or Axios for a REST API
import { carsIndex } from './algolia';
import { distanceBetweenCoord } from '../util/place-utils';

const carCanBeDisplay = (location, car) => {
  if (!location?.pickUp?.location?.lat || !location?.pickUp?.location?.lng) {
    return true;
  }
  const distancePickUp = distanceBetweenCoord(location?.pickUp?.location, car._geoloc);
  const distanceReturn = distanceBetweenCoord(location?.return?.location, car._geoloc);
  const allDelLoc = car?.deliveryLocation ?? [];
  if (allDelLoc.some((d) => d?.placeId === location?.pickUp?.placeId) || distancePickUp < car?.deliveryRadius * 1000) {
    if (
      !location?.return?.location ||
      allDelLoc.some((d) => d?.placeId === location?.return?.placeId) ||
      distanceReturn < car?.deliveryRadius * 1000
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

/**
 * Fetch cars based on filters and pagination.
 * @param {string} filterString - The filter query string.
 * @param {object} location - The pick-up location object containing latitude and longitude.
 * @param {number} page - The current page number for pagination.
 * @returns {Promise<{results: Array, nbPages: number}>} - A promise that resolves to the search results and number of pages.
 */
const fetchCars = async (filterString, location, page = 0) => {
  try {
    // Constructing search parameters
    const searchParams = {
      filters: filterString,
      page: page,
      hitsPerPage: 10, // Adjust based on your needs
    };

    // If location is provided, add geographical search parameters
    if (location && location?.pickUp?.lat && location?.pickUp?.lng) {
      searchParams.aroundLatLng = `${location?.pickUp?.lat},${location?.pickUp?.lng}`;
      searchParams.aroundRadius = 500000; // Adjust the radius as per your requirement
    }

    // Perform the search
    const response = await carsIndex.search('', searchParams);

    // Here you might want to further process response.hits based on your app's logic
    const results = response.hits.filter((car) => carCanBeDisplay(location, car));

    return {
      results,
      nbPages: response.nbPages,
    };
  } catch (error) {
    console.error('Error fetching cars:', error);
    throw error; // Rethrow or handle as per your error handling policy
  }
};

export { fetchCars };
