import React from 'react';

import { Modal, Spinner, Text, VStack } from '@gluestack-ui/themed';
import { SafeAreaView } from 'react-native-safe-area-context';

import { Images } from '../../themes';
import imagesUrl from '../../themes/imagesUrl';
import Image from '../image/image';

export default function ModalSwitcher({ isOpen, setOpen, text }) {
  return (
    <Modal isOpen={isOpen} onClose={() => setOpen(false)} backgroundColor={'$blue900'} justifyContent={'center'} alignItems={'center'}>
      <SafeAreaView style={{ flex: 1 }} justifyContent={'center'} alignItems={'center'}>
        <VStack space={'md'} justifyContent={'center'} alignItems={'center'} h={'100%'}>
          <Image source={{ uri: imagesUrl.brandName }} height={100} w={300} alt={'logo'} removeTransition={true} />
          <Text alignSelf={'center'} color={'$white'}>
            {text}
          </Text>
          <Spinner color={'$white'} size={100} />
        </VStack>
      </SafeAreaView>
    </Modal>
  );
}
