import React, { useEffect, useState } from 'react';

import { Ionicons } from '@expo/vector-icons';
import {
  Box,
  Button,
  ButtonSpinner,
  ButtonText,
  Divider,
  FormControl,
  FormControlError,
  FormControlErrorIcon,
  FormControlErrorText,
  FormControlLabel,
  FormControlLabelText,
  HStack,
  Text,
  Textarea,
  TextareaInput,
  VStack,
} from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { goBackOrDefault } from '../../navigation/nav-ref';
import CarSmallCard from '../../shared/components/entities/car/carSmallCard';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Image from '../../shared/components/image/image';
import Rating from '../../shared/components/rating/rating';
import Skeleton from '../../shared/components/skeleton/skeleton';
import WebScrollView from '../../shared/components/web/webScrollView';
import { getOneReservation } from '../../shared/services/firebase/reservation-firebase';
import { addOneReview } from '../../shared/services/firebase/review-firebase';
import { getOneUser } from '../../shared/services/firebase/user-firebase';
import { rangeToDateRange } from '../../shared/util/date-transforms';
import { resizedImage } from '../../shared/util/image-utils';
import { getExtraInputProps } from '../../shared/util/input-utils';

const minChar = 30;

function AddReview({ navigation, route }) {
  const { carId, reservationId, reviewedUserId, reviewDriver } = route?.params ?? {};
  const currentUser = useSelector((state) => state.users.currentUser);

  const [reservation, setReservation] = useState({});
  const [userToReview, setUserToReview] = useState({});
  const [review, setReview] = useState({ rating: 5, text: '' });
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [reservationLoading, setReservationLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    console.debug('Effect: refresh user');
    setUserLoading(true);
    getOneUser(reviewedUserId)
      .then((res) => {
        setUserToReview(res);
        setUserLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setUserLoading(true);
      });
  }, [reviewedUserId]);

  useEffect(() => {
    console.debug('Effect: refresh reservation');
    setReservationLoading(true);
    getOneReservation(reservationId)
      .then((res) => {
        setReservation(res);
        setReservationLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setReservationLoading(false);
      });
  }, [reservationId]);

  function sendReview() {
    setLoading(true);
    const rev = {
      ...review,
      reservationId: reservationId,
      userId: currentUser?.uid,
      userPhotoURL: currentUser.photoURL,
      userName: currentUser.firstName + ' ' + currentUser.lastName,
      reviewedUserId: reviewedUserId,
      language: currentUser.language,
      createdAt: new Date(),
    };

    if (userToReview?.uid === reservation?.ownerId && reservation?.uid) {
      rev.carId = carId;
    }

    addOneReview(rev)
      .then(() => {
        setLoading(false);
        goBackOrDefault('Search');
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoading(false);
      });
  }

  return (
    <WebScrollView>
      <VStack space={'md'} my={'$4'} mx={'$screenMargin'}>
        {userLoading || reservationLoading ? (
          <HStack space={'md'} alignItems={'center'}>
            <Skeleton h={20} w={20} rounded={'$full'} />
            <Skeleton.Text lines={2} />
          </HStack>
        ) : userToReview?.uid === reservation?.userId && reservation?.uid ? (
          <HStack space={'md'} alignItems={'center'}>
            <Image source={{ uri: resizedImage(userToReview?.photoURL) }} size={'sm'} rounded={'$full'} />
            <Text flexShrink={1}>
              {t('review.reviewDriverText', {
                carName: `${reservation.car.brand} ${reservation.car.model}`,
                userName: `${userToReview.firstName} ${userToReview.lastName}`,
              })}
            </Text>
          </HStack>
        ) : (
          <CarSmallCard
            disabled={true}
            userCar={userToReview}
            car={{ uid: carId }}
            dateRange={rangeToDateRange(reservation.from, reservation.to)}
          />
        )}
        <Divider my={'$4'} />
        <Box mx={'$4'}>
          <Rating size={'xl'} space={'sm'} onChange={(rating) => setReview({ ...review, rating: rating })} value={review.rating} />
        </Box>
        <FormControl
          mt={'$4'}
          isInvalid={review?.originalContent?.text ? (review?.originalContent?.text?.length > minChar ? false : true) : false}>
          <FormControlLabel>
            <FormControlLabelText>{t('review.addReview')}</FormControlLabelText>
          </FormControlLabel>
          <Textarea {...getExtraInputProps()}>
            <TextareaInput
              value={review?.originalContent?.text}
              onChangeText={(t) => setReview((prev) => ({ ...prev, originalContent: { text: t } }))}
              placeholder={
                userToReview?.uid === reservation?.userId && reservation?.uid
                  ? t('review.reviewOwnerExample')
                  : t('review.reviewDriverExample')
              }
            />
          </Textarea>
          <FormControlError>
            <FormControlErrorIcon as={Ionicons} name={'alert-circle-outline'} />
            <FormControlErrorText>{t('error.tooShortInput', { number: minChar })}</FormControlErrorText>
          </FormControlError>
        </FormControl>
        <Button
          isDisabled={loading || !review?.originalContent?.text || review?.originalContent?.text?.length < minChar}
          onPress={sendReview}>
          {loading && <ButtonSpinner mr="$1" />}
          <ButtonText>{t('review.publish')}</ButtonText>
        </Button>
      </VStack>
    </WebScrollView>
  );
}

export default AddReview;
