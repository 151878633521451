import React, { useCallback, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { Box, Button, ButtonText, Center, HStack, useToken } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';
import { useDispatch, useSelector } from 'react-redux';

import AeraSelector from './aeraSelector';
import BottomSheet from '../../bottomSheet/bottomSheet';
import BottomSheetFooter from '../../bottomSheet/BottomSheetFooter';
import UnderLineButton from '../../pressable/underLineButton';

function AeraPicker({ isOpen, setOpen, location, setLocation, deliveryLocation, carLocation, onRefresh, onlyOneLocation }) {
  const { t } = useTranslation();
  const layout = useWindowDimensions();
  const { bottom } = useSafeAreaInsets();

  const colorWhite = useToken('colors', 'White');
  const colorBlue900 = useToken('colors', 'blue900');
  const colorBlack = useToken('colors', 'black');
  const colorLight400 = useToken('colors', 'light400');
  const spaceScreenMargin = useToken('space', 'screenMargin');

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: 'pickup', title: t('rental.pickUp') },
    { key: 'return', title: t('rental.return') },
  ]);

  const currency = useSelector((state) => state.users.currency);

  const renderScene = ({ route, jumpTo }) => {
    switch (route.key) {
      case 'pickup':
        return (
          <AeraSelector
            location={location?.pickUp}
            setLocation={(loc) => setLocation({ ...location, pickUp: loc })}
            deliveryLocation={deliveryLocation}
            currency={currency}
            carLocation={carLocation}
          />
        );
      case 'return':
        return (
          <AeraSelector
            location={location?.return}
            setLocation={(loc) => setLocation({ ...location, return: loc })}
            deliveryLocation={deliveryLocation}
            currency={currency}
            carLocation={carLocation}
          />
        );
    }
  };

  const renderFooter = useCallback(
    (props) => (
      <BottomSheetFooter {...props} bottomInset={bottom}>
        <Box testID="locationFooter" backgroundColor={'$white'} mb={-bottom} paddingBottom={bottom}>
          <HStack p={'$4'} justifyContent={'space-between'}>
            <Center>
              <UnderLineButton text={t('search.clear')} hideLeftIcon={true} onPress={clearLocation} />
            </Center>
            <Center>
              <Button testID="locationSelect" onPress={onSelect} minWidth={'$40'}>
                <ButtonText>{t('global.select')}</ButtonText>
              </Button>
            </Center>
          </HStack>
        </Box>
      </BottomSheetFooter>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bottom, setOpen, t, onSelect],
  );

  function clearLocation() {
    setLocation({});
    setOpen(false);
  }

  const onSelect = useCallback(() => {
    onRefresh && onRefresh();
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRefresh]);

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      indicatorContainerStyle={{
        width: 'auto',
        marginHorizontal: spaceScreenMargin,
        paddingHorizontal: spaceScreenMargin,
      }}
      labelStyle={{ textTransform: 'none' }}
      indicatorStyle={{ backgroundColor: colorBlue900 }}
      style={{ backgroundColor: colorWhite }}
      activeColor={colorBlack}
      inactiveColor={colorLight400}
    />
  );

  return (
    <BottomSheet isOpen={isOpen} setOpen={setOpen} snapToTop={true} headerText={t('car.delivery')} renderFooter={renderFooter}>
      {onlyOneLocation ? (
        <AeraSelector
          location={location}
          setLocation={setLocation}
          deliveryLocation={deliveryLocation}
          currency={currency}
          carLocation={carLocation}
        />
      ) : (
        <TabView
          renderTabBar={renderTabBar}
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={setIndex}
          initialLayout={{ width: layout.width }}
        />
      )}
    </BottomSheet>
  );
}

export default AeraPicker;
