import React, { useCallback } from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import {
  Box,
  Button,
  ButtonText,
  Center,
  Checkbox,
  CheckboxIcon,
  CheckboxIndicator,
  CheckboxLabel,
  Divider,
  Heading,
  HStack,
  Icon,
  Text,
  VStack,
} from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { carFeaturesList, carFuelList, carTransmissionList, carTypeList } from '../../util/car-utils';
import BottomSheet from '../bottomSheet/bottomSheet';
import BottomSheetFooter from '../bottomSheet/BottomSheetFooter';
import BottomSheetScrollView from '../bottomSheet/bottomSheetScrollView';
import UnderLineButton from '../pressable/underLineButton';

function SearchFilter({ isOpen, setOpen, filter, setFilter, onRefresh }) {
  const { t } = useTranslation();
  const { bottom } = useSafeAreaInsets();

  function carTypeOnPress(cType) {
    let fct = [...(filter?.carType ?? [])];

    if (fct.includes(cType.name)) {
      fct = fct.filter((item) => item !== cType.name);
    } else {
      fct.push(cType.name);
    }
    setFilter({ ...filter, carType: fct });
  }

  function isCarTypeFiltered(cType, fil) {
    let fct = fil?.carType ?? [];

    return fct.includes(cType.name);
  }

  function carFeatureOnPress(cFeature) {
    let fct = [...(filter?.feature ?? [])];

    if (fct.includes(cFeature.name)) {
      fct = fct.filter((item) => item !== cFeature.name);
    } else {
      fct.push(cFeature.name);
    }
    setFilter({ ...filter, feature: fct });
  }

  function isCarFeatureFiltered(cFeature, fil) {
    let fcf = fil?.feature ?? [];

    return fcf.includes(cFeature.name);
  }

  function changeTransmission(type) {
    if (filter?.transmission === type) {
      setFilter({ ...filter, transmission: undefined });
    } else {
      setFilter({ ...filter, transmission: type });
    }
  }

  function changeFuel(type) {
    if (filter?.fuel === type) {
      setFilter({ ...filter, fuel: undefined });
    } else {
      setFilter({ ...filter, fuel: type });
    }
  }

  function clearFilter() {
    setFilter({});
  }

  const onSelect = useCallback(() => {
    onRefresh && onRefresh();
    setOpen(false);
  }, [onRefresh, setOpen]);

  const renderFooter = useCallback(
    (props) => (
      <BottomSheetFooter {...props} bottomInset={bottom}>
        <Box testID="filterFooter" backgroundColor={'$white'} mb={-bottom} paddingBottom={bottom}>
          <HStack p={'$4'} justifyContent={'space-between'}>
            <Center>
              <UnderLineButton text={t('search.clear')} hideLeftIcon={true} onPress={clearFilter} />
            </Center>
            <Center>
              <Button testID="filterSelect" pr={'$4'} pl={'$4'} onPress={onSelect} minWidth={'$40'}>
                <ButtonText>{t('global.select')}</ButtonText>
              </Button>
            </Center>
          </HStack>
        </Box>
      </BottomSheetFooter>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bottom, onSelect, t],
  );

  return (
    <BottomSheet isOpen={isOpen} setOpen={setOpen} renderFooter={renderFooter} snapToTop={true} headerText={t('filter.title')}>
      <BottomSheetScrollView>
        <Box variant={'contentWithFooter'} mt={'$8'} pb={200}>
          <VStack space={'md'} mx={'$screenMargin'} mb={'$4'} pb={bottom}>
            <Heading>{t('filter.typeOfCar')}</Heading>
            <HStack flexWrap={'wrap'} space={'sm'}>
              {carTypeList.map((carType) => (
                <Box
                  key={carType.name}
                  variant={'cardContainer'}
                  rounded={'$full'}
                  px={'$4'}
                  py={'$2'}
                  mb={'$4'}
                  backgroundColor={isCarTypeFiltered(carType, filter) ? '$primary900' : '$white'}>
                  <TouchableOpacity onPress={() => carTypeOnPress(carType)}>
                    <Text size={'secondary'} color={isCarTypeFiltered(carType, filter) ? '$white' : '$primary900'}>
                      {t(carType.translateName)}
                    </Text>
                  </TouchableOpacity>
                </Box>
              ))}
            </HStack>
            <Divider my={'$4'} />
            <Heading>{t('filter.transmission')}</Heading>
            <VStack />
            <Checkbox
              aria-label={'day'}
              size="md"
              justifyContent={'space-between'}
              isChecked={filter?.transmission === undefined}
              onChange={() => changeTransmission(undefined)}>
              <CheckboxLabel w={'80%'}>{t('filter.all')}</CheckboxLabel>
              <CheckboxIndicator ml="$2">
                <CheckboxIcon as={Ionicons} name={'checkmark-outline'} />
              </CheckboxIndicator>
            </Checkbox>
            {carTransmissionList.map((transmission) => (
              <Checkbox
                key={transmission.name}
                aria-label={'day'}
                size="md"
                justifyContent={'space-between'}
                isChecked={filter?.transmission === transmission.name}
                onChange={() => changeTransmission(transmission.name)}>
                <CheckboxLabel w={'80%'}>{t(transmission.translateName)}</CheckboxLabel>
                <CheckboxIndicator ml="$2">
                  <CheckboxIcon as={Ionicons} name={'checkmark-outline'} />
                </CheckboxIndicator>
              </Checkbox>
            ))}
            <Divider my={'$4'} />
            <Heading>{t('filter.fuel')}</Heading>
            <Checkbox
              aria-label={'day'}
              size="md"
              justifyContent={'space-between'}
              isChecked={filter?.fuel === undefined}
              onChange={() => changeFuel(undefined)}>
              <CheckboxLabel w={'80%'}>{t('filter.all')}</CheckboxLabel>
              <CheckboxIndicator ml="$2">
                <CheckboxIcon as={Ionicons} name={'checkmark-outline'} />
              </CheckboxIndicator>
            </Checkbox>
            {carFuelList.map((fuel) => (
              <Checkbox
                key={fuel.name}
                aria-label={'day'}
                size="md"
                justifyContent={'space-between'}
                isChecked={filter?.fuel === fuel.name}
                onChange={() => changeFuel(fuel.name)}>
                <CheckboxLabel w={'80%'}>{t(fuel.translateName)}</CheckboxLabel>
                <CheckboxIndicator ml="$2">
                  <CheckboxIcon as={Ionicons} name={'checkmark-outline'} />
                </CheckboxIndicator>
              </Checkbox>
            ))}
            <Divider my={'$4'} />
            <Heading>{t('filter.options')}</Heading>
            <HStack flexWrap={'wrap'} space={'sm'}>
              {carFeaturesList.map((carFeature) => (
                <Box
                  key={carFeature.name}
                  variant={'cardContainer'}
                  rounded={'$full'}
                  px={'$4'}
                  py={'$2'}
                  mr={'$4'}
                  mb={'$4'}
                  backgroundColor={isCarFeatureFiltered(carFeature, filter) ? '$primary900' : '$white'}>
                  <TouchableOpacity onPress={() => carFeatureOnPress(carFeature)}>
                    <HStack space={'sm'} alignItems={'center'}>
                      <Icon
                        as={carFeature.iconLibrary}
                        name={carFeature.iconName}
                        size={'lg'}
                        color={isCarFeatureFiltered(carFeature, filter) ? '$white' : '$primary900'}
                      />
                      <Text
                        size={'secondary'}
                        alignSelf={'center'}
                        color={isCarFeatureFiltered(carFeature, filter) ? '$white' : '$primary900'}>
                        {t(carFeature.translateName)}
                      </Text>
                    </HStack>
                  </TouchableOpacity>
                </Box>
              ))}
            </HStack>
          </VStack>
        </Box>
      </BottomSheetScrollView>
    </BottomSheet>
  );
}

export default SearchFilter;
