import React, { useEffect, useState } from 'react';
import { Dimensions, Modal, TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Icon, View } from '@gluestack-ui/themed';

import Image from '../components/image/image';
import { Colors, Metrics } from '../themes';

export default function ImageView({ visible, onRequestClose, imageIndex, images }) {
  const [index, setIndex] = useState(imageIndex);

  const imageUrl = images && images?.length > index && index >= 0 ? images[index].uri : images?.length > 0 && images[0]?.uri;

  useEffect(() => {
    setIndex(imageIndex);
  }, [imageIndex]);

  return (
    <Modal animationType="slide" transparent={true} visible={visible} onRequestClose={onRequestClose}>
      <View flex={1} bgColor={'$transparent3'} w={Metrics.fullWidth} h={Metrics.fullHeight} alignItems={'center'} justifyContent={'center'}>
        <Image
          alt="car"
          height={Dimensions.get('window').height * 0.8}
          w={Metrics.fullWidth}
          resizeMode="contain"
          source={{ uri: imageUrl }}
          mx={'$1'}
        />
        <Box variant={'absoluteTopLeft'} m={'$4'}>
          <TouchableOpacity onPress={onRequestClose}>
            <Box bgColor={'$white'} rounded={'$full'} p={'$2'}>
              <Icon as={Ionicons} name="close" size={'xl'} />
            </Box>
          </TouchableOpacity>
        </Box>
        {index > 0 && (
          <Box variant={'absoluteLeft'} m={'$4'} alignSelf={'center'}>
            <TouchableOpacity onPress={() => setIndex(index - 1)}>
              <Box bgColor={'$white'} rounded={'$full'} p={'$2'}>
                <Icon as={Ionicons} name="chevron-back-outline" size={'xl'} />
              </Box>
            </TouchableOpacity>
          </Box>
        )}
        {images?.length > index + 1 && (
          <Box variant={'absoluteRight'} m={'$4'}>
            <TouchableOpacity onPress={() => setIndex(index + 1)}>
              <Box bgColor={'$white'} rounded={'$full'} p={'$2'}>
                <Icon as={Ionicons} name="chevron-forward-outline" size={'xl'} />
              </Box>
            </TouchableOpacity>
          </Box>
        )}
      </View>
    </Modal>
  );
}
