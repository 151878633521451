import React from 'react';

import { FormControlLabel, FormControlLabelText, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Select from '../../../shared/components/select/select';
import { carAddingSetter } from '../../../shared/reducers/car.reducer';
import { carData } from '../carData/carData';

function TypeOfCar() {
  const car = useSelector((state) => state.cars.carAdding);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function carToArrayYears(carDatas) {
    if (carDatas && carDatas.length > 0) {
      const carData = carDatas[0];

      const endYear = carData.yearEnd !== '-' ? carData.yearEnd : 2024;
      const startYear = carData.yearStart !== '-' ? carData.yearStart : 1960;
      const si = endYear - startYear + 1;

      const yearArray = [];
      new Array(si > 0 ? si : 1).fill().forEach((e, i) => {
        yearArray.push({ label: (endYear - i).toString(), value: endYear - i });
      });
      return yearArray;
    } else {
      return [];
    }
  }

  function setCar(value) {
    dispatch(carAddingSetter(value));
  }

  return (
    <VStack space={'md'}>
      <Text mb={'$4'} variant={'grey'} size={'secondary'}>
        {t('addCar.typeOfCarDescription')}
      </Text>
      <VStack space={'xs'}>
        <FormControlLabel>
          <FormControlLabelText>{t('addCar.brand')}</FormControlLabelText>
        </FormControlLabel>
        <Select
          testID={'brand'}
          title={t('addCar.brand')}
          data={carData
            .filter((value, index, self) => index === self.findIndex((t) => t?.brand === value?.brand))
            .map((brand) => ({ value: brand?.brand, label: brand?.brand }))}
          onSelect={(item) => setCar({ ...car, brand: item.value })}
          placeHolder={t('addCar.brand')}
          value={car?.brand}
        />
      </VStack>

      <VStack space={'xs'}>
        <FormControlLabel>
          <FormControlLabelText>{t('addCar.model')}</FormControlLabelText>
        </FormControlLabel>
        <Select
          testID={'model'}
          title={t('addCar.model')}
          data={carData
            .filter((value) => value?.brand === car?.brand)
            .map((carD) => ({ value: carD?.model, label: carD?.model.toString() }))}
          onSelect={(item) => setCar({ ...car, model: item.value })}
          placeHolder={t('addCar.model')}
          value={car?.model}
        />
      </VStack>
      <VStack space={'xs'}>
        <FormControlLabel>
          <FormControlLabelText>{t('addCar.year')}</FormControlLabelText>
        </FormControlLabel>
        <Select
          testID={'year'}
          title={t('addCar.year')}
          data={carToArrayYears(carData.filter((value) => value?.brand === car?.brand && value?.model === car?.model))}
          onSelect={(item) => setCar({ ...car, year: item?.value })}
          placeHolder={t('addCar.year')}
          value={car?.year}
        />
      </VStack>
    </VStack>
  );
}

export default TypeOfCar;
