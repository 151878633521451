import React from 'react';

import { Divider, Heading, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import Image from '../../../shared/components/image/image';
import { Images } from '../../../shared/themes';
import imagesUrl from '../../../shared/themes/imagesUrl';

export default function EarnByRenting(props) {
  const { t } = useTranslation();

  return (
    <VStack space={'lg'}>
      <HStack justifyContent={'space-between'}>
        <Heading size={'h4'}>1</Heading>
        <VStack w={'50%'} space={'sm'}>
          <Heading size={'h4'}>{t('addCar.tellUsAboutYourCar')}</Heading>
          <Text variant={'grey'} size={'secondary'}>
            {t('addCar.tellUsAboutYourCarSmallDescription')}
          </Text>
        </VStack>
        <Image size={'lg'} resizeMode="contain" source={{ uri: imagesUrl.garage }} alt={'Step1'} />
      </HStack>
      <Divider />
      <HStack justifyContent={'space-between'}>
        <Heading size={'h4'}>2</Heading>
        <VStack w={'50%'} space={'sm'}>
          <Heading size={'h4'}>{t('addCar.makeItStandOut')}</Heading>
          <Text variant={'grey'} size={'secondary'}>
            {t('addCar.makeItStandOutSmallDescription')}
          </Text>
        </VStack>
        <Image size={'lg'} resizeMode="contain" source={{ uri: imagesUrl.podium }} alt={'Step2'} />
      </HStack>
      <Divider />
      <HStack justifyContent={'space-between'}>
        <Heading size={'h4'}>3</Heading>
        <VStack w={'50%'} space={'sm'}>
          <Heading size={'h4'}>{t('addCar.previewAndFinish')}</Heading>
          <Text variant={'grey'} size={'secondary'}>
            {t('addCar.previewAndFinishSmallDescription')}
          </Text>
        </VStack>
        <Image size={'lg'} resizeMode="contain" source={{ uri: imagesUrl.street }} alt={'Step3'} />
      </HStack>
    </VStack>
  );
}
