import React, { useEffect, useMemo, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Button, ButtonIcon, ButtonText, Divider, Heading, HStack, ScrollView, useToken, VStack } from '@gluestack-ui/themed';
import { Text } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import BottomSheet from '../../../shared/components/bottomSheet/bottomSheet';
import CarTinyCard from '../../../shared/components/entities/car/carTinyCard';
import { errorHandler } from '../../../shared/components/errorHandler/errorHander';
import FirestoreList from '../../../shared/components/firestore/firestoreList';
import Image from '../../../shared/components/image/image';
import PlaceHolder from '../../../shared/components/placeHolder/placeHolder';
import Skeleton from '../../../shared/components/skeleton/skeleton';
import { useIsDrawer } from '../../../shared/provider/isDrawerProvider';
import { firebaseApp } from '../../../shared/services/firebase/firebaseFactory';
import { updateRoom } from '../../../shared/services/firebase/function-firebase';
import imagesUrl from '../../../shared/themes/imagesUrl';
import { ctaTypes } from '../../../shared/types/chat';
import { getThumbnailUrl } from '../../../shared/util/car-utils';
import { cleanFirestoreResult } from '../../../shared/util/firestore-utils';
import { resizedImage } from '../../../shared/util/image-utils';

export default function ChatCTA({ room }) {
  const [car, setCar] = useState({});
  const [isOpen, setOpen] = useState(false);
  const [loading, setLodaing] = useState(false);

  const currentUser = useSelector((state) => state.users.currentUser);

  const navigation = useNavigation();
  const { t } = useTranslation();
  const isDrawer = useIsDrawer();
  const isOwner = useMemo(() => car?.ownerId === currentUser.uid, [car?.ownerId, currentUser.uid]);

  const screenMargin = useToken('sizes', 'screenMargin');

  const ctaButton = useMemo(() => {
    const cta = [];
    if (room?.ctaType === ctaTypes.quote) {
      cta.push({
        key: t('rental.seeQuote'),
        onPress: () => navigation.navigate('QuoteDetail', { quoteId: room?.quoteId }),
        text: t('rental.seeQuote'),
        icon: null,
      });
    }
    if (room?.ctaType === ctaTypes.review) {
      cta.push({
        key: isOwner ? t('rental.reviewRentalDriver') : t('rental.reviewrentalTrip'),
        onPress: () =>
          navigation.navigate(isOwner ? 'RequestDetailScreen' : 'ReservationDetail', {
            reservationId: room?.reservationId,
          }),
        text: isOwner ? t('rental.reviewRentalDriver') : t('rental.reviewRentalTrip'),
        icon: <ButtonIcon as={Ionicons} name={'star-outline'} size={'sm'} color="$black" />,
      });
    }
    if (room?.ctaType === ctaTypes.rental || room?.ctaType === ctaTypes.review) {
      cta.push({
        key: t('rental.seeRental'),
        onPress: () =>
          navigation.navigate(isOwner ? 'RequestDetailScreen' : 'ReservationDetail', {
            reservationId: room?.reservationId,
          }),
        text: t('rental.seeRental'),
        icon: null,
      });
    }
    if (isOwner && room?.ctaType !== ctaTypes.rental && room?.ctaType !== ctaTypes.review) {
      cta.push({
        key: room?.user1Id + room?.user2Id,
        onPress: () =>
          navigation.navigate('AddQuoteFlow', {
            driverId: currentUser?.uid === room?.user1Id ? room?.user2Id : room?.user1Id,
          }),
        text: t('rental.createQuote'),
        icon: <ButtonIcon as={Ionicons} name={'add'} size={'sm'} color="$black" />,
      });
      cta.push({
        key: t('rental.offerNewListing'),
        onPress: () => setOpen(true),
        text: t('rental.offerNewListing'),
        icon: null,
      });
    }
    if (!isOwner && room?.ctaType === ctaTypes.complete) {
      cta.push({
        key: t('rental.bookAgain'),
        onPress: () => navigation.navigate('CarDetail', { carId: room?.carId }),
        text: t('rental.bookAgain'),
        icon: null,
      });
    }
    if (!isOwner && room?.ctaType !== ctaTypes.rental && room?.ctaType !== ctaTypes.review && room?.ctaType !== ctaTypes.complete) {
      cta.push({
        key: t('rental.requestBooking'),
        onPress: () => navigation.navigate('CarDetail', { carId: room?.carId }),
        text: t('rental.requestBooking'),
        icon: null,
      });
    }
    return cta;
  }, [
    currentUser?.uid,
    isOwner,
    navigation,
    room?.carId,
    room?.ctaType,
    room?.quoteId,
    room?.reservationId,
    room?.user1Id,
    room?.user2Id,
    t,
  ]);

  useEffect(() => {
    if (room?.carId) {
      firebaseApp
        .firestore()
        .collection('cars')
        .doc(room?.carId)
        .get()
        .then((data) => {
          setCar(cleanFirestoreResult(data));
        })
        .catch((e) => {
          errorHandler(e, true);
        });
    }
  }, [room?.carId]);

  function selectCar(item) {
    setLodaing(true);
    setOpen(false);
    updateRoom(room?.uid, item?.uid)
      .then(() => {
        setLodaing(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setLodaing(false);
      });
  }

  function clickCTA() {
    if (!room?.ctaType) {
      navigation.navigate('CarDetail', { carId: room?.carId });
    }
    switch (room?.ctaType) {
      case ctaTypes.undef:
        navigation.navigate('CarDetail', { carId: room?.carId });
        break;
      case ctaTypes.quote:
        navigation.navigate('QuoteDetail', { quoteId: room?.quoteId });
        break;
      case ctaTypes.rental:
      case ctaTypes.review:
        navigation.navigate(isOwner ? 'RequestDetailScreen' : 'ReservationDetail', {
          reservationId: room?.reservationId,
        });
        break;
      default:
        navigation.navigate('CarDetail', { carId: room?.carId });
        break;
    }
  }

  function renderItem({ item }) {
    return <CarTinyCard car={item} onPress={() => selectCar(item)} />;
  }

  if (!car?.uid) {
    return null;
  }

  return (
    <>
      <VStack>
        {!isDrawer && <Divider />}
        {loading ? (
          <Box mx={'$screenMargin'} my={'$2'}>
            <HStack space="md">
              <Skeleton h={'$12'} w={'$12'} rounded={'$md'} />
              <VStack space="md">
                <Skeleton.Text w={150} />
                <Skeleton.Text w={200} />
              </VStack>
            </HStack>
          </Box>
        ) : (
          <Box mx={'$screenMargin'} my={'$2'}>
            <TouchableOpacity onPress={clickCTA}>
              <HStack justifyContent="space-between" alignItems="center">
                <HStack space="md">
                  <Image source={{ uri: resizedImage(getThumbnailUrl(car?.images)) }} rounded={'$md'} size={'sm'} />
                  <VStack>
                    <Heading>{`${car?.brand} ${car?.model}`}</Heading>
                    <Text variant="grey">{room?.ctaType ? t(`chat.${room?.ctaType}`) : t('chat.UNDEF')}</Text>
                  </VStack>
                </HStack>
                {isDrawer && (
                  <HStack space="sm">
                    {ctaButton.map((button, index) => (
                      <Button
                        key={button.key}
                        variant="outline"
                        size={'sm'}
                        rounded={'$full'}
                        borderColor="$light300"
                        h={'$8'}
                        onPress={button.onPress}>
                        {button.icon}
                        <ButtonText>{button.text}</ButtonText>
                      </Button>
                    ))}
                  </HStack>
                )}
              </HStack>
            </TouchableOpacity>
          </Box>
        )}
        {!isDrawer && (
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <HStack space="md">
              {ctaButton.map((button, index) => (
                <Button
                  key={button.key}
                  ml={index === 0 ? '$screenMargin' : '$none'}
                  mr={index === ctaButton.length - 1 ? '$screenMargin' : '$none'}
                  variant="outline"
                  size={'sm'}
                  rounded={'$full'}
                  borderColor="$light300"
                  h={'$8'}
                  onPress={button.onPress}>
                  {button.icon}
                  <ButtonText>{button.text}</ButtonText>
                </Button>
              ))}
            </HStack>
          </ScrollView>
        )}
        <Divider mt={isDrawer ? '$none' : '$2'} />
      </VStack>
      <BottomSheet isOpen={isOpen} setOpen={setOpen} snapToTop={true} headerText={t('rental.offerNewListing')}>
        <VStack mx={'$screenMargin'}>
          <Text variant="grey">{t('rental.offerNewListingDescription')}</Text>
          <FirestoreList
            keyExtractor={(i) => i?.uid + '_' + i?.carApproved + i?.carValidated + i?.accountValidated + i?.visible}
            ItemSeparatorComponent={<Box />}
            isConnected={true}
            collectionName={'cars'}
            filters={[
              { field: 'ownerId', operator: '==', value: currentUser?.uid },
              { field: 'visible', operator: '==', value: true },
            ]}
            renderItem={renderItem}
            contentContainerStyle={{ alignSelf: 'center', width: '100%', marginHorizontal: screenMargin }}
            ListEmptyComponent={
              <Box>
                <PlaceHolder
                  image={{ uri: imagesUrl.empty }}
                  title={t('placeHolder.noCarTitle')}
                  text={t('placeHolder.noCarInfo')}
                  element={
                    <Button onPress={() => navigation.navigate('AddCarFlow')}>
                      <ButtonText>{t('ownerCar.addNewCar')}</ButtonText>
                    </Button>
                  }
                />
              </Box>
            }
          />
        </VStack>
      </BottomSheet>
    </>
  );
}
