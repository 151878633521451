import 'react-native-gesture-handler';
import 'react-native-url-polyfill/auto';
import * as React from 'react';

import { AntDesign, Ionicons } from '@expo/vector-icons/build/Icons';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useFonts } from 'expo-font';
import * as Notifications from 'expo-notifications';
import * as SplashScreen from 'expo-splash-screen';
import { CookiesProvider } from 'react-cookie';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import InitApp from './app/init/initApp';
import NavigContainer from './app/navigation/navigation-container';
import { CustomProvider } from './app/shared/provider';
import { persistor, runSaga, store } from './app/shared/reducers';
import { GluestackUIProvider } from './GlueStackProvider';

// import i18n
import './app/shared/i18n/i18n';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

runSaga();

export default function App() {
  // prevent the splashscreen from disappearing until the redux store is completely ready (hidden in nav-container.js)

  let [fontsLoaded, fontError] = useFonts({
    Heebo_400Regular: require('./assets/fonts/Heebo_400Regular.ttf'),
    Heebo_500Medium: require('./assets/fonts/Heebo_500Medium.ttf'),
    ...Ionicons.font,
    ...AntDesign.font,
  });

  if (!fontsLoaded && !fontError) {
    return null;
  }

  return (
    <SafeAreaProvider>
      <GoogleOAuthProvider clientId="342293326176-k6do501ce5dhrvcu66me090g63526ovs.apps.googleusercontent.com">
        <GluestackUIProvider>
          <CustomProvider>
            <Provider store={store}>
              <PersistGate persistor={persistor} onBeforeLift={() => SplashScreen.hideAsync()}>
                <CookiesProvider>
                  <InitApp>
                    <NavigContainer />
                  </InitApp>
                </CookiesProvider>
              </PersistGate>
            </Provider>
          </CustomProvider>
        </GluestackUIProvider>
      </GoogleOAuthProvider>
    </SafeAreaProvider>
  );
}
