import React from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Heading, HStack, Icon, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { Images } from '../../themes';
import imagesUrl from '../../themes/imagesUrl';
import metrics from '../../themes/metrics';
import { getThumbnailUrl } from '../../util/car-utils';
import { resizedImage } from '../../util/image-utils';
import FirestoreList from '../firestore/firestoreList';
import Image from '../image/image';
import PlaceHolder from '../placeHolder/placeHolder';

export default function UserCars({ userId }) {
  const navigation = useNavigation();
  const { t } = useTranslation();

  function renderItem({ item, index }) {
    return (
      <TouchableOpacity onPress={() => navigation.navigate('CarDetail', { carId: item.uid })}>
        <VStack space={'xs'} maxWidth={metrics.images.xlarge} mr={'$4'} {...(index === 0 ? { ml: '4' } : null)}>
          <Image
            rounded={'$2xl'}
            source={{ uri: resizedImage(getThumbnailUrl(item?.images)) }}
            w={metrics.images.xlarge}
            h={metrics.images.logo}
            alt={'thumbnail'}
          />
          <Heading size={'h4'}>{item.brand + ' ' + item.model}</Heading>
          <HStack alignItems={'center'} space={'sm'}>
            <Icon as={Ionicons} size={'xs'} name={'star'} color={'$light700'} />
            <Text variant={'grey'} size={'secondary'}>
              {item?.avgRating + ' (' + item?.numRatings + ')'}
            </Text>
          </HStack>
        </VStack>
      </TouchableOpacity>
    );
  }

  return (
    <VStack space={'md'} m={'$2'}>
      <FirestoreList
        keyExtractor={(item) => item?.uid}
        ItemSeparatorComponent={<Box my={'$1'} />}
        isConnected={false}
        collectionName={'cars'}
        numColumns={3}
        filters={[
          { field: 'ownerId', operator: '==', value: userId },
          { field: 'visible', operator: '==', value: true },
        ]}
        renderItem={renderItem}
        ListEmptyComponent={<PlaceHolder image={{ uri: imagesUrl.empty }} title={t('placeHolder.noSearchDescription')} />}
      />
    </VStack>
  );
}
