import React, { useEffect, useState } from 'react';

import { VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { getUserReviews } from '../../../services/firebase/review-firebase';
import { Images } from '../../../themes';
import imagesUrl from '../../../themes/imagesUrl';
import { errorHandler } from '../../errorHandler/errorHander';
import PlaceHolder from '../../placeHolder/placeHolder';
import UnderLineButton from '../../pressable/underLineButton';
import ReviewCard from '../../reviewCard/reviewCard';
import ReviewSkeleton from '../../skeleton/reviewSkeleton';

export default function UserReviews({ userId, limit }) {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigation = useNavigation();
  const { t } = useTranslation();

  useEffect(() => {
    console.debug('Effect: refresh userID, limit');
    setLoading(true);
    getUserReviews(userId, limit)
      .then((res) => {
        setReviews(res);
        setLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoading(false);
      });
  }, [userId, limit]);

  if (loading) {
    return (
      <VStack space={'md'} my={'$4'}>
        <ReviewSkeleton />
        <ReviewSkeleton />
        <ReviewSkeleton />
      </VStack>
    );
  }

  return (
    <VStack space={'xl'} my={'$4'}>
      {reviews.length === 0 && <PlaceHolder image={{ uri: imagesUrl.jungle1 }} title={t('placeHolder.noReviewDescription')} />}
      {reviews.length > 0 ? reviews.map((review) => <ReviewCard key={review.uid} review={review} />) : null}
    </VStack>
  );
}
