import { useToast } from '@gluestack-ui/themed';

let toast = null;

export const globalToast = () => {
  if (!toast) {
    console.error('no Toast available');
    return undefined;
  }
  return toast;
};

export const ToastProvider = ({ children }) => {
  toast = useToast();

  return children;
};
