import React from 'react';

import { FormControlLabel, FormControlLabelText, Heading, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import InputDiscountCard from '../../../shared/components/input/inputDiscountCard';
import KeyboardAvoiding from '../../../shared/components/keyboardAvoiding/keyboardAvoiding';
import { carAddingSetter } from '../../../shared/reducers/car.reducer';

function SetYourDiscountOffer() {
  const car = useSelector((state) => state.cars.carAdding);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const setCar = (car) => dispatch(carAddingSetter(car));

  return (
    <KeyboardAvoiding>
      <VStack space={'md'}>
        <Text mb={'$4'} variant={'grey'} size={'secondary'}>
          {t('addCar.setYourDiscountDescription')}
        </Text>
        <VStack space={'xs'}>
          <FormControlLabel>
            <FormControlLabelText>{t('addCar.discountPerWeek')}</FormControlLabelText>
          </FormControlLabel>
          <InputDiscountCard
            numberValue={car?.discountPerWeek}
            placeHolder={t('addCar.discountPerWeek')}
            onChangeValue={(value) => setCar({ ...car, discountPerWeek: value })}
            label={t('addCar.perWeek')}
            info={t('addCar.perDiscountRangeWeekly', { min: 8, max: 15 })}
            rightElement={
              <Text alignSelf={'center'} mr={'$4'} size={'h2'}>
                %
              </Text>
            }
          />
        </VStack>
        <VStack space={'xs'}>
          <FormControlLabel>
            <FormControlLabelText>{t('addCar.discountPerMonth')}</FormControlLabelText>
          </FormControlLabel>

          <InputDiscountCard
            numberValue={car?.discountPerMonth}
            placeHolder={t('addCar.discountPerMonth')}
            onChangeValue={(value) => setCar({ ...car, discountPerMonth: value })}
            label={t('addCar.perMonth')}
            info={t('addCar.perDiscountRangeMonthly', { min: 12, max: 20 })}
            rightElement={
              <Text alignSelf={'center'} mr={'$4'} size={'h2'}>
                %
              </Text>
            }
          />
        </VStack>
      </VStack>
    </KeyboardAvoiding>
  );
}

export default SetYourDiscountOffer;
