import AddCheckCarMileage from '../../screen/checkCar/steps/addCheckCarMileage';
import AddCheckCarPhoto from '../../screen/checkCar/steps/addCheckCarPhoto';
import AddCheckCarSelfieAndSign from '../../screen/checkCar/steps/addCheckCarSelfieAndSign';
import DriverSummary from '../../screen/checkCar/steps/driverSummary';
import GivePhoneToDriver from '../../screen/checkCar/steps/givePhoneToDriver';
import { driverMediaType } from '../types/checkCar';

export const checkCarStepList = [
  {
    titleToTranslate: 'checkCar.addAdditionalPhoto',
    component: () => <AddCheckCarPhoto />,
    validator: (checkCar) => true,
    viewerStep: 3,
    atCreation: true,
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'checkCar.mileage',
    component: () => <AddCheckCarMileage />,
    validator: (checkCar) => checkCar?.fuel && checkCar?.mileage,
    viewerStep: 3,
    atCreation: true,
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'checkCar.givePhoneToDriver',
    component: () => <GivePhoneToDriver />,
    validator: () => true,
    viewerStep: 4,
    atCreation: true,
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'checkCar.driverSummary',
    component: () => <DriverSummary />,
    validator: () => true,
    viewerStep: 5,
    atCreation: true,
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'checkCar.selfiesAndSign',
    component: () => <AddCheckCarSelfieAndSign />,
    validator: (checkCar) =>
      checkCar?.images &&
      checkCar.images.filter((cc) => cc.type === driverMediaType.selfie).length > 0 &&
      checkCar.images.filter((cc) => cc.type === driverMediaType.signature).length > 0,
    viewerStep: 6,
    atCreation: true,
    buttonTextTranslate: 'global.finishCreate',
  },
];
