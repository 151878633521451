'use client';
import React from 'react';

import { StyledProvider } from '@gluestack-style/react';
import { OverlayProvider } from '@gluestack-ui/overlay';
import { createProvider } from '@gluestack-ui/provider';
import { ToastProvider } from '@gluestack-ui/toast';

// Change the config file path
import { config } from './gluestack-ui.config';

const GluestackUIStyledProvider = createProvider({ StyledProvider });

export const GluestackUIProvider = ({ children, ...props }) => {
  return (
    <>
      {/** @ts-ignore */}
      <GluestackUIStyledProvider colorMode={'light'} config={config}>
        <OverlayProvider>
          <ToastProvider>{children}</ToastProvider>
        </OverlayProvider>
      </GluestackUIStyledProvider>
    </>
  );
};
