import React, { useEffect, useMemo, useState } from 'react';

import { Button, ButtonText, Heading, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import * as Clipboard from 'expo-clipboard';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';

import { goBackOrDefault } from '../../navigation/nav-ref';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Layout from '../../shared/components/layout/layout';
import Steps from '../../shared/components/steps/steps';
import successToast from '../../shared/components/toast/successToast';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { quoteSetter } from '../../shared/reducers/quote.reducer';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { addQuoteStep } from '../../shared/util/car-quote-list';
import { defaultDateRange } from '../../shared/util/date-transforms';

const AddQuoteFlow = ({ route }) => {
  const quote = useSelector((state) => state.quote.quote);
  const currentUser = useSelector((state) => state.users.currentUser);
  const dispatch = useDispatch();
  const maxWidth = useMaxWidth();

  const { carId, driverId } = route?.params ?? {};

  const [storedQuote, setStoredQuote] = useState({});
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const { top } = useSafeAreaInsets();

  const stepsFlow = useMemo(() => {
    let stepToByPass = [];
    if (carId) {
      stepToByPass.push('quote.selectCar');
    }
    if (driverId) {
      stepToByPass.push('quote.addEmail');
    } else {
      stepToByPass.push('quote.confirmUser');
    }
    return addQuoteStep.filter((step) => !stepToByPass.includes(step.titleToTranslate));
  }, [carId, driverId]);

  const setQuote = (quote) => dispatch(quoteSetter(quote));

  useEffect(() => {
    if (carId && quote?.carId !== carId) {
      setQuote({
        carId: carId,
        dateRange: defaultDateRange,
        userId: currentUser?.uid,
        language: currentUser?.language,
        ...(driverId ? { driverId: driverId } : {}),
      });
    } else if (!carId) {
      setQuote({
        dateRange: defaultDateRange,
        userId: currentUser?.uid,
        language: currentUser?.language,
        ...(driverId ? { driverId: driverId } : {}),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carId]);

  const onFinish = () => {
    setLoading(true);
    firebaseApp
      .firestore()
      .collection('quotes')
      .add({ ...quote, createdAt: new Date() })
      .then((res) => {
        setStoredQuote({ ...quote, uid: res.id });
        setQuote({});
        successToast(t('quote.success'));
        setLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoading(false);
      });
  };

  const onCopy = () => {
    Clipboard.setStringAsync('https://app.flyandcar.com/' + i18n.language + '/quote/' + storedQuote?.uid);
    successToast(t('quote.linkCopySuccessfully'));
  };

  const goBack = () => {
    setStoredQuote({});
    goBackOrDefault('QuoteTabs');
  };

  if (storedQuote?.uid) {
    return (
      <Layout>
        <VStack pt={top} px={'$screenMargin'} my={'$4'} space={'md'} w={maxWidth} alignSelf="center">
          <Heading size={'h2'}>{t('quote.created')}</Heading>
          <Text>{t('quote.addQuoteSubTitle')}</Text>
          <Button variant={'outline'} onPress={onCopy}>
            <ButtonText>{t('quote.copyLink')}</ButtonText>
          </Button>
          <Button onPress={goBack}>
            <ButtonText>{t('global.finishCreate')}</ButtonText>
          </Button>
        </VStack>
      </Layout>
    );
  }

  return (
    <Layout>
      <Steps loading={loading} routes={stepsFlow} onFinish={onFinish} dataToValidate={quote} />
    </Layout>
  );
};

export default AddQuoteFlow;
