import React, { useCallback, useEffect, useRef, useState } from 'react';
import { I18nManager, Modal, Platform, TouchableOpacity, useWindowDimensions } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import {
  Box,
  Button,
  ButtonSpinner,
  ButtonText,
  Divider,
  HStack,
  Icon,
  ScrollView,
  Spinner,
  Text,
  useToken,
  VStack,
} from '@gluestack-ui/themed';
import { useFocusEffect } from '@react-navigation/native';
import { Camera, FlashMode } from 'expo-camera';
import * as Device from 'expo-device';
import * as ImagePicker from 'expo-image-picker';
import { DeviceMotion } from 'expo-sensors';
import { useTranslation } from 'react-i18next';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { SafeAreaProvider, SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';

import MediaStepRaw from './mediaStepRaw';
import { goBackOrDefault } from '../../../navigation/nav-ref';
import { firebaseApp } from '../../services/firebase/firebaseFactory';
import { Colors, Images, Metrics } from '../../themes';
import imagesUrl from '../../themes/imagesUrl';
import { defaultCheckCarSteps } from '../../util/check-car-steps';
import { IS_WEB } from '../../util/device-utils';
import { resizedImage } from '../../util/image-utils';
import { updloadMedia } from '../../util/storage-utils';
import ActionSheet from '../actionSheet/actionSheet';
import { errorHandler } from '../errorHandler/errorHander';
import Image from '../image/image';
import PlaceHolder from '../placeHolder/placeHolder';
import { PressableRow } from '../pressable/pressableRow';
import UnderLineButton from '../pressable/underLineButton';
import errorToast from '../toast/errorToast';

function getRightStep(length) {
  const l = defaultCheckCarSteps.filter((c) => c?.part).length - 3;
  if (length === l) {
    return length + 2;
  }
  return 0;
}

function orientationCalculation(gamma, beta) {
  if (isNaN(beta) || isNaN(gamma)) {
    return 0;
  }
  let ABSOLUTE_GAMMA = Math.abs(gamma);
  let ABSOLUTE_BETA = Math.abs(beta);
  let isGammaNegative = gamma < 0;
  let orientation = 0;

  if (ABSOLUTE_GAMMA <= 0.04 && ABSOLUTE_BETA <= 0.24) {
    //Portrait mode, on a flat surface.
    orientation = 0;
  } else if ((ABSOLUTE_GAMMA <= 1.0 || ABSOLUTE_GAMMA >= 2.3) && ABSOLUTE_BETA >= 0.5) {
    //General Portrait mode, accounting for forward and back tilt on the top of the phone.
    orientation = 0;
  } else {
    if (isGammaNegative) {
      //Landscape mode with the top of the phone to the left.
      orientation = 90;
    } else {
      //Landscape mode with the top of the phone to the right.
      orientation = -90;
    }
  }
  return orientation;
}

export default function MediaCheckFlow({ imageList, setImageList, storagePath, onFinish, loading }) {
  const [checkCarSteps, setCheckCarSteps] = useState(imageList && imageList?.length > 0 ? imageList : defaultCheckCarSteps);
  const [cameraReady, setCameraReady] = useState(false);
  const [step, setStep] = useState(getRightStep(imageList?.length > 0 ? imageList?.filter((c) => c?.url).length : 0));
  const [flashMode, setFlashMode] = useState(FlashMode.off);
  const [rotate, setRotate] = useState(0);
  const [isCameraAllowed, setCameraAllowed] = useState(false);
  const [isModalVisible, setModalVisible] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [heightBottom, setHeightBottom] = useState(0);

  const refCamera = useRef();
  const { height, width } = useWindowDimensions();
  const insert = useSafeAreaInsets();
  const space8 = useToken('space', '8');
  const { t } = useTranslation();

  const orientation = useSharedValue(0);

  const rotStyle = useAnimatedStyle(() => {
    return {
      transform: [{ rotateZ: `${orientation.value}deg` }],
    };
  });

  function onMotionUpdate(event) {
    const rot = orientationCalculation(Math.round(event.rotation.gamma * 1e2) / 1e2, Math.round(event.rotation.beta * 1e2) / 1e2);
    if (orientation.value !== rot) {
      orientation.value = withTiming(rot);
      setRotate(rot);
    }
  }

  useEffect(() => {
    if (firebaseApp.auth().currentUser) {
      firebaseApp
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          // Confirm the user is an Admin.
          if (idTokenResult?.claims?.admin) {
            // Show admin UI.
            setAdmin(true);
          } else {
            // Show regular user UI.
            setAdmin(false);
          }
        })
        .catch((error) => {
          errorHandler(error, true);
        });
    }
  }, []);

  useFocusEffect(
    useCallback(() => {
      setModalVisible(defaultCheckCarSteps[step]?.isStep === true || defaultCheckCarSteps[step]?.isUpload === true);
    }, [step]),
  );

  useEffect(() => {
    DeviceMotion.setUpdateInterval(2000);
    const listener = DeviceMotion.addListener(onMotionUpdate);
    return () => listener.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.debug('UseEffect: CheckCarSteps');
    setImageList && setImageList(checkCarSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkCarSteps]);

  useEffect(() => {
    console.debug('UseEffect: openCam');
    openCamera();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onUpload(step, url) {
    setCheckCarSteps((p) => updateCheckSteps(p, step, { url }));
  }

  // This function is triggered when the "Open camera" button pressed
  const openCamera = async () => {
    try {
      if (IS_WEB) {
        const isAvailable = await Camera.isAvailableAsync();
        if (!isAvailable) {
          errorToast(t('media.noCamera'));
          setCameraAllowed(false);
          return;
        }
      }
      const permission = await Camera.getCameraPermissionsAsync();

      if (permission.granted === false) {
        // Ask the user for the permission to access the camera
        const permissionResult = await Camera.requestCameraPermissionsAsync();

        if (permissionResult.granted === false) {
          errorToast(t('media.allowCamera'));
          setCameraAllowed(false);
        } else {
          setCameraAllowed(true);
        }
      } else {
        setCameraAllowed(true);
      }
    } catch (e) {
      errorHandler(e, false);
      errorToast(t('media.allowCamera'));
    }
  };

  function updateCheckSteps(prevSteps, actualStep, update) {
    return prevSteps?.length > 0
      ? prevSteps?.map((st, index) => {
          if (index !== actualStep || (!st.path && update?.url)) {
            return st;
          } else {
            return { ...st, ...update };
          }
        })
      : [];
  }

  function updateStep(nb, isEnd = false) {
    if (step + nb < 0) {
      setModalVisible(false);
      setStep(0);
      goBackOrDefault('Search');
    } else if (step + nb >= defaultCheckCarSteps.length) {
      isEnd && onFinish();
      setStep(defaultCheckCarSteps.length - 1);
    } else {
      setStep(step + nb);
    }
  }

  async function takePic() {
    if (refCamera.current) {
      setCameraReady(false);
      const photo = await refCamera.current.takePictureAsync();
      if (photo?.uri) {
        updateStep(1);
        const imageToUpload = { path: photo.uri, uploaded: false, uploading: true };
        setCheckCarSteps((p) => updateCheckSteps(p, step, { path: photo.uri }));
        updloadMedia(imageToUpload.path, storagePath, onUpload, step, -rotate);
      }

      setCameraReady(true);
    }
  }

  const ImageChoice = async () => {
    try {
      if (Platform.OS === 'ios') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          errorToast(t('error.permissionLibraryRequired'));
          return;
        }
      }
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsMultipleSelection: false,
      }).catch((e) => errorHandler(e, true));
      if (result?.canceled !== undefined && !result?.canceled) {
        result.assets.forEach((photo) => {
          updateStep(1);
          const imageToUpload = { path: photo.uri, uploaded: false, uploading: true };
          setCheckCarSteps((p) => updateCheckSteps(p, step, { path: photo.uri }));
          updloadMedia(imageToUpload.path, storagePath, onUpload, step, -rotate);
        });
        setOpen(false);
      } else {
        setOpen(false);
      }
    } catch (e) {
      setOpen(false);

      errorHandler(e, false);
      errorToast(t('error.errorSelectImage'));
    }
  };

  useEffect(() => {
    setCheckCarSteps((p) => updateCheckSteps(p, step, { ...defaultCheckCarSteps[step], url: undefined, path: undefined }));
  }, [step]);

  function goBack() {
    updateStep(-1);
  }

  const cameraHeight = height - 80 - heightBottom - insert.bottom * 2;

  return (
    <SafeAreaView
      style={{ height: Metrics.fullHeight, width: Metrics.fullWidth, flex: 1, backgroundColor: Colors.blue[900], overflow: 'hidden' }}>
      <MediaStepRaw step={step} stepList={checkCarSteps} width={width} orientation={orientation} />
      {isCameraAllowed ? (
        <Camera
          style={{ width: (cameraHeight / 4) * 3, height: cameraHeight, alignSelf: 'center' }}
          type="back"
          flashMode={flashMode}
          ref={refCamera}
          onCameraReady={() => setCameraReady(true)}
        />
      ) : (
        <Box bgColor="white" style={{ width: (cameraHeight / 4) * 3, height: cameraHeight, alignSelf: 'center' }}>
          <VStack justifyContent={'center'} height={cameraHeight}>
            <Box m={'$4'}>
              <PlaceHolder
                image={{ uri: imagesUrl.empty }}
                title={t('media.allowCamera')}
                element={
                  <Button variant={'outline'} onPress={openCamera}>
                    <ButtonText>{t('media.allowCameraButton')}</ButtonText>
                  </Button>
                }
              />
            </Box>
          </VStack>
        </Box>
      )}
      {rotate < 0 && (
        <Box
          variant={'absoluteBottom'}
          style={{
            transform: [{ rotate: `${rotate}deg` }, { translateY: -width / 2 + space8 }, { translateX: height / 2 }],
          }}
          alignItems="center"
          alignSelf="center">
          <Box m={'$2'} p={'$2'} backgroundColor="rgba(0,0,0,0.6)" rounded="$md">
            <Text color="white">{t('checkCarPart.' + defaultCheckCarSteps[step]?.part)}</Text>
          </Box>
        </Box>
      )}
      {rotate > 0 && (
        <Box
          variant={'absoluteBottom'}
          style={{
            transform: [{ rotate: `${rotate}deg` }, { translateY: -width / 2 + space8 }, { translateX: -height / 2 }],
          }}
          alignItems="center"
          alignSelf="center">
          <Box m={'$2'} p={'$2'} backgroundColor="rgba(0,0,0,0.6)" rounded="$md">
            <Text color="white">{t('checkCarPart.' + defaultCheckCarSteps[step]?.part)}</Text>
          </Box>
        </Box>
      )}
      {rotate === 0 && (
        <Box variant={'absoluteBottom'} bottom={heightBottom + insert.bottom} alignItems="center" alignSelf="center">
          <Box m={'$2'} p={'$2'} backgroundColor="rgba(0,0,0,0.6)" rounded="$md">
            <Text color="white">{t('checkCarPart.' + defaultCheckCarSteps[step]?.part)}</Text>
          </Box>
        </Box>
      )}

      <HStack w={'$full'} justifyContent="center" onLayout={(event) => setHeightBottom(event.nativeEvent.layout.height)}>
        <Box mx={'$screenMargin'} my={'$2'}>
          <HStack justifyContent="space-evenly" w={'$full'} alignItems={'center'}>
            <VStack space={'sm'}>
              <TouchableOpacity onPress={goBack}>
                <Animated.View style={[rotStyle]}>
                  <Box w={'$10'} h={'$10'} backgroundColor="white" rounded="$full" alignItems="center" justifyContent="center">
                    <Icon
                      size={'lg'}
                      as={Ionicons}
                      name="chevron-back-outline"
                      color="$light600"
                      style={{ transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }] }}
                    />
                  </Box>
                </Animated.View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => setFlashMode(flashMode === FlashMode.off ? FlashMode.torch : FlashMode.off)}>
                <Animated.View style={[rotStyle]}>
                  <Box backgroundColor="white" w={'$10'} h={'$10'} rounded="$full" alignItems="center" justifyContent="center">
                    <Icon as={Ionicons} name={flashMode === FlashMode.off ? 'flash-outline' : 'flash-off-outline'} color="$light600" />
                  </Box>
                </Animated.View>
              </TouchableOpacity>
            </VStack>
            <TouchableOpacity
              onPress={() => (isAdmin ? setOpen(true) : takePic())}
              disabled={!cameraReady && Device.isDevice}
              style={{ zIndex: 1 }}>
              <Animated.View style={[rotStyle]}>
                <Box backgroundColor={'$white'} rounded="$full" alignItems="center" justifyContent="center" w={'$16'} h={'$16'}>
                  <Box rounded="$full" bgColor={isAdmin ? '$primary600' : '$light600'} w={'$10'} h={'$10'} />
                </Box>
              </Animated.View>
            </TouchableOpacity>
            <VStack space={'sm'}>
              <TouchableOpacity onPress={() => updateStep(1)} disabled={!defaultCheckCarSteps[step]?.skippable}>
                <Animated.View style={[rotStyle]}>
                  <Box
                    backgroundColor={defaultCheckCarSteps[step]?.skippable ? '$white' : '$light500'}
                    w={'$10'}
                    h={'$10'}
                    rounded="$full"
                    alignItems="center"
                    justifyContent="center">
                    <Icon as={Ionicons} name="play-forward-outline" color="$light600" />
                  </Box>
                </Animated.View>
              </TouchableOpacity>
            </VStack>
          </HStack>
        </Box>
      </HStack>
      <ActionSheet isOpen={isOpen} setOpen={setOpen} headerText={t('media.selectMedia')}>
        <VStack mx={'$4'}>
          <PressableRow leftIconName={'image-outline'} text={t('media.library')} onPress={ImageChoice} removeRightCheveron={true} />
          <Divider />
        </VStack>
      </ActionSheet>
      <Modal visible={isModalVisible} animationType="slide" transparent>
        <Box h="$full" w="$full" bgColor="$blue900">
          <SafeAreaProvider>
            <SafeAreaView style={{ height: '100%' }}>
              <VStack justifyContent="space-between" mx={'$screenMargin'} h={'100%'} space={'lg'} alignItems={'center'}>
                <VStack space={'sm'} alignSelf={'flex-start'}>
                  <Text color="white">{t(defaultCheckCarSteps[step]?.title)}</Text>
                  <Text color="white" fontWeight={'$bold'} fontSize="$h3">
                    {t(defaultCheckCarSteps[step]?.subTitle)}
                  </Text>
                </VStack>
                <Image
                  resizeMode={'contain'}
                  source={{ uri: resizedImage(defaultCheckCarSteps[step]?.image ?? defaultCheckCarSteps[step]?.placeHolder) }}
                  rounded="$lg"
                  h={200}
                  w={200}
                />
                {defaultCheckCarSteps[step]?.text !== undefined && (
                  <Text color="white" alignSelf={'center'}>
                    {t(defaultCheckCarSteps[step]?.text)}
                  </Text>
                )}
                <ScrollView maxHeight={'30%'} w={'100%'}>
                  {defaultCheckCarSteps[step].isUpload && (
                    <VStack space={'sm'}>
                      {[...(imageList?.filter((st) => !st?.isStep && !st?.isUpload) || [])]?.reverse()?.map((st) => (
                        <VStack key={(st?.url ?? st.path ?? st.placeHolder) + '_' + st?.part} space={'sm'}>
                          <HStack space={'sm'} alignItems="center">
                            <Image h={25} w={25} source={{ uri: st?.url ?? st.path ?? st.placeHolder }} />
                            <Text alignSelf="center" color="white">
                              {t('checkCarPart.' + st?.part)}
                            </Text>
                            {st?.url ? (
                              <Icon as={Ionicons} name="checkmark-circle-outline" color="$green400" />
                            ) : st?.path ? (
                              <Spinner size="small" />
                            ) : (
                              <Icon as={Ionicons} name="warning-outline" color="$warning400" />
                            )}
                          </HStack>
                          <Divider />
                        </VStack>
                      ))}
                    </VStack>
                  )}
                </ScrollView>

                <Box variant={'absoluteBottom'} w={'$full'}>
                  <HStack justifyContent="space-between" space={'md'} alignSelf="center" alignItems={'center'} w="$full" my={'$4'}>
                    <UnderLineButton text={t('global.back')} onPress={() => updateStep(-1)} color="white" hideLeftIcon displayRightIcon />
                    <Button
                      variant="outline"
                      color="$white"
                      borderColor={'$white'}
                      onPress={() => updateStep(1, true)}
                      isDisabled={
                        loading ||
                        (defaultCheckCarSteps[step]?.isUpload &&
                          checkCarSteps.some((c) => !c.isStep && !c.isUpload && !c.skippable && !c?.url))
                      }>
                      {loading && <ButtonSpinner mr="$1" />}
                      <ButtonText color={'$white'}>{t(defaultCheckCarSteps[step]?.buttonText)}</ButtonText>
                    </Button>
                  </HStack>
                </Box>
              </VStack>
            </SafeAreaView>
          </SafeAreaProvider>
        </Box>
      </Modal>
    </SafeAreaView>
  );
}
