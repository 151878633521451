import React from 'react';
import { Touchable } from 'react-native';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { HStack, Icon } from '@gluestack-ui/themed';

const stars = [
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
];

export default function Rating({ value, onChange, readOnly, size, space }) {
  return (
    <HStack space={space}>
      {stars.map((star) => (
        <TouchableOpacity onPress={() => onChange(star.value)} key={star.value} disabled={readOnly}>
          <Icon as={Ionicons} color={star.value <= value ? '$gold' : '$light300'} size={size} name={'star'} />
        </TouchableOpacity>
      ))}
    </HStack>
  );
}
