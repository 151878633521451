import { Platform } from 'react-native';

export const getExtraInputProps = () => {
  if (Platform?.constants?.Manufacturer === 'samsung' && Platform?.constants?.Release === '13') {
    return {
      keyboardType: 'visible-password',
      returnKeyType: 'none',
    };
  }
  return {};
};
