import AsyncStorage from '@react-native-async-storage/async-storage';
import { createMigrate } from 'redux-persist';

import { initialState as initialStateCar } from '../shared/reducers/car.reducer';
import { initialState as initialStateCheckCar } from '../shared/reducers/checkCar.reducer';
import { initialState as initialStateQuote } from '../shared/reducers/quote.reducer';
import { initialState as initialStateSearch } from '../shared/reducers/search.reducer';
import { initialState as initialStateUser } from '../shared/reducers/user.reducer';

const migrations = {
  1: (state) => {
    return {
      users: initialStateUser,
      cars: initialStateCar,
      quote: initialStateQuote,
      checkCar: initialStateCheckCar,
      search: initialStateSearch,
    };
  },
};

const REDUX_PERSIST_CONFIG = {
  key: 'primary',
  version: 1,
  storage: AsyncStorage,
  migrate: createMigrate(migrations),
  //blacklist: ['appState'], // reducer keys that you do NOT want stored to persistence here
  // whitelist: [], Optionally, just specify the keys you DO want stored to
  // persistence. An empty array means 'don't store any reducers' -> infinitered/ignite#409
  // transforms: [immutablePersistenceTransform],
};

export default REDUX_PERSIST_CONFIG;
