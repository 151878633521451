import { createStyle } from '@gluestack-style/react';

export const BaseIcon = createStyle({
  color: '$black',
  alignItems: 'center',
  alignSelf: 'center',
  justifyContent: 'center',
  display: 'flex',
  _dark: {
    color: '$backgroundDark400',
  },
  variants: {
    size: {
      xs: {
        w: '$4',
        h: '$4',
        props: {
          size: 12,
        },
      },
      sm: {
        w: '$4',
        h: '$4',
        props: {
          size: 16,
        },
      },
      md: {
        w: '$5',
        h: '$5',
        props: {
          size: 20,
        },
      },
      lg: {
        w: '$6',
        h: '$6',
        props: {
          size: 24,
        },
      },
      xl: {
        w: '$7',
        h: '$7',
        props: {
          size: 28,
        },
      },
      '2xl': {
        w: '$8',
        h: '$8',
        props: {
          size: 32,
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
});

export const Icon = createStyle({
  props: {
    size: 'md',
    //@ts-ignore
    fill: 'none',
  },
  color: '$backgroundLight800',
  _dark: {
    //@ts-ignore
    color: '$backgroundDark400',
  },
});
