import React, { useCallback, useMemo } from 'react';

import { Box, Button, ButtonText, HStack } from '@gluestack-ui/themed';
import { ButtonSpinner } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useIsDrawer } from '../../../provider/isDrawerProvider';
import { Colors, Metrics } from '../../../themes';
import UnderLineButton from '../../pressable/underLineButton';

const StepFooter = ({ prevStep, nextStep, buttonText, isForwardActive, loading, maxStep, currentStep, lockDown, maxWidth }) => {
  const { t } = useTranslation();
  const isDrawer = useIsDrawer();
  const isFloating = isDrawer && !lockDown;
  const progress = useMemo(() => (currentStep / maxStep) * 100, [currentStep, maxStep]);

  return (
    <Box
      position={'absolute'}
      bottom={isFloating ? '$4' : '$0'}
      width={maxWidth}
      alignSelf="center"
      pb={isFloating ? '$0' : '$4'}
      bgColor={'$light100'}
      rounded={isFloating ? '$md' : '$none'}
      overflow={'hidden'}
      h={100}>
      <HStack width={'$full'} h={8}>
        <Box h={8} w={progress + '%'} backgroundColor={'$primary900'} />
        <Box h={8} w={100 - progress + '%'} backgroundColor={'$white'} />
      </HStack>
      <HStack mx={'$screenMargin'} justifyContent={'space-between'} alignItems={'center'} h={92}>
        <UnderLineButton testID={'back'} text={t('global.back')} displayRightIcon={true} hideLeftIcon={true} onPress={prevStep} />
        <Button testID="button.next" maxWidth={'45%'} isDisabled={loading || !isForwardActive()} onPress={nextStep}>
          {loading && <ButtonSpinner mr="$1" />}
          <ButtonText>{buttonText}</ButtonText>
        </Button>
      </HStack>
    </Box>
  );
};

export default StepFooter;
