import Constants from 'expo-constants';
import * as Updates from 'expo-updates';

// load extra config from the app.json file
const extra = Constants.expoConfig?.extra ?? {};

export default {
  // use 10.0.2.2 for Android to connect to host machine
  androidClientId: '342293326176-c292u9g1ikgj54qga20km17vc09ikig8.apps.googleusercontent.com',
  iosClientId: '342293326176-u6ptbdeh16e4e5q8o8id5ec3do5ikvp6.apps.googleusercontent.com',
  facebookAppId: '579922727584708',
  facebookId: '856745949204714',
  redirectAuthUrl: 'https://flyncar-app.firebaseapp.com/__/auth/handler',
  deepLinkUrl: 'https://app.flyandcar.com',
  algoliaApiId: extra.algoliaApiId,
  algoliaApiKey: extra.algoliaApiKey,
  appCheckAndroidToken: extra.appCheckAndroidToken,
  appCheckIosToken: extra.appCheckIosToken,
  storageBucketDomain: extra.storageBucketDomain,
  stripeKey: extra.stripeKey,
  googleMapKey: extra.googleMapKey,
  serviceFee: 1.07,
  isDetox: Updates?.channel === 'test_debug',
  // use fixtures instead of real API requests
  useFixtures: false,
  // debug mode
  debugMode: __DEV__,
  supportId: __DEV__ ? 'ELUzae7hicXOrKeB5w26OXuByyP2' : '8955gKrn19dGSq8ORNNaibbTip42',
  extra,
  env: extra.env,
};
