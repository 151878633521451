import React from 'react';

import { Button, ButtonText, Heading, HStack, VStack } from '@gluestack-ui/themed';
import { Text } from '@gluestack-ui/themed';
import { Box } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import PointIcon from './pointIcon';
import UnderLineButton from '../../../shared/components/pressable/underLineButton';

export default function SectionPoint({ title, points, maxWidth, buttonText, onPressButton }) {
  const { t } = useTranslation();

  return (
    <VStack w={'$full'} space="4xl">
      <Heading $base-textAlign="center" $md-textAlign="left" fontSize={32} lineHeight={40} mb={'$4'}>
        {title}
      </Heading>
      <HStack flexWrap="wrap" justifyContent="space-around" space="md">
        {points.map((point, index) => (
          <HStack
            key={point.title}
            space="md"
            $base-w={'$full'}
            $md-w={'40%'}
            $lg-w={'30%'}
            p={'$4'}
            $base-flexDirection="column"
            $md-flexDirection="row"
            $base-rounded={'$md'}
            $base-borderColor="$light200"
            $md-borderColor="$transparent"
            $base-borderWidth={'$1'}
            $base-bgColor={'$light100'}
            $md-bgColor={'$transparent'}
            $md-borderWidth={'$0'}
            $base-alignItems="center"
            $md-alignItems="flex-start">
            <PointIcon url={point.imageUrl} bgColor={'$transparent'} />
            <VStack w={'80%'} space="md" $base-alignItems="center" $md-alignItems="flex-start">
              <Heading $base-textAlign="center" $md-textAlign="left" size={'h4'}>
                {t(point.title)}
              </Heading>
              <Text size="secondary" $base-textAlign="center" $md-textAlign="left">
                {t(point.description)}
              </Text>
              <UnderLineButton text={t('landingPro.learnMore')} onPress={point.onPress} hideLeftIcon={true} />
            </VStack>
          </HStack>
        ))}
      </HStack>
      <Button variant="outline" onPress={onPressButton} $base-minWidth={'$full'} $md-minWidth={'$40'} alignSelf="center">
        <ButtonText>{buttonText}</ButtonText>
      </Button>
    </VStack>
  );
}
