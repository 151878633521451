import React, { useEffect, useState } from 'react';

import { HStack, Icon, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { carFeaturesList } from '../../util/car-utils';
import Skeleton from '../skeleton/skeleton';

export default function CarFeatures({ features, mode, loading }) {
  const [featureList, setFeatureList] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const size = mode === 'small' ? 3 : carFeaturesList.length;
    const list = [];
    carFeaturesList.forEach((feature) => {
      if (features && features.length > 0 && features.includes(feature.name)) {
        list.unshift({ strikeThrough: false, ...feature });
      } else {
        list.push({ strikeThrough: true, ...feature });
      }
    });
    setFeatureList(list.slice(0, size));
  }, [features, mode]);

  if (loading) {
    return (
      <VStack space={'md'}>
        <HStack space={'md'} alignItems={'center'}>
          <Skeleton w={'$6'} h={'$6'} rounded={'$full'} />
          <Skeleton.Text lines={1} w={'30%'} />
        </HStack>
        <HStack space={'md'} alignItems={'center'}>
          <Skeleton w={'$6'} h={'$6'} rounded={'$full'} />
          <Skeleton.Text lines={1} w={'50%'} />
        </HStack>
        <HStack space={'md'} alignItems={'center'}>
          <Skeleton w={'$6'} h={'$6'} rounded={'$full'} />
          <Skeleton.Text lines={1} w={'40%'} />
        </HStack>
      </VStack>
    );
  }

  return (
    <VStack space={'md'}>
      {featureList.map((content) => (
        <HStack key={content.translateName} alignItems={'center'} space={'md'}>
          <Icon as={content.iconLibrary} size={'md'} name={content.iconName} />
          <Text strikeThrough={content.strikeThrough} w={'40%'} numberOfLines={2} alignSelf={'center'}>
            {t(content.translateName)}
          </Text>
          {content.needToAsk && !content.strikeThrough && (
            <Text size={'tertiary'} variant={'grey'} strikeThrough={content.strikeThrough} w={'40%'}>
              {t('car.featureToAsk')}
            </Text>
          )}
        </HStack>
      ))}
    </VStack>
  );
}
