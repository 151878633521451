import React from 'react';
import { I18nManager, TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Heading, HStack, Icon, Text } from '@gluestack-ui/themed';

export default function UnderLineButton({ testID, text, onPress, displayRightIcon, hideLeftIcon, color, size }) {
  function pressButton() {
    if (onPress) {
      onPress();
    }
  }
  return (
    <Box alignItems={'flex-start'}>
      <TouchableOpacity testID={`underlineButton.${testID}`} onPress={pressButton}>
        <HStack space={'sm'} alignItems={'center'}>
          {!displayRightIcon ? null : (
            <Icon as={Ionicons} size={'md'} name={'chevron-back-outline'} style={{ transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }] }} />
          )}
          <Heading underline bold color={color ? color : '$black'} fontSize={size ?? '$secondary'}>
            {text}
          </Heading>
          {hideLeftIcon ? null : (
            <Icon as={Ionicons} size={'sm'} name={'chevron-forward'} style={{ transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }] }} />
          )}
        </HStack>
      </TouchableOpacity>
    </Box>
  );
}
