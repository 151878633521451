import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, ButtonText, Center, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-native-calendario';
import { NativeViewGestureHandler } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';

import appConfig from '../../../config/app-config';
import { calendariotheme } from '../../themes/calendarTheme';
import { formatCurrency } from '../../util/currency';
import { dateRangeToArrayDays, dateToCalendarTimeString, dateToDayOfYears, defaultDateRange } from '../../util/date-transforms';
import { IS_WEB } from '../../util/device-utils';
import { getCalendarLang } from '../../util/language-utils';
import BottomSheet from '../bottomSheet/bottomSheet';
import BottomSheetFooter from '../bottomSheet/BottomSheetFooter';
import InfoCard from '../infoCard/infoCard';
import UnderLineButton from '../pressable/underLineButton';
import TimePicker from '../timePicker/timePicker';
import errorToast from '../toast/errorToast';

function CalendarSheet({ dateRange, setDateRange, isOpen, setOpen, bookedDays, onPress, rightButtonText, info, priceData }) {
  const [date, setDate] = useState({
    start: dateRange?.from ? new Date(dateRange?.from * 1000) : undefined,
    end: dateRange?.to ? new Date(dateRange?.to * 1000) : undefined,
  });

  const [firstRender, setFirstRender] = useState(true);

  const { t } = useTranslation();
  const { bottom } = useSafeAreaInsets();
  const currentUser = useSelector((state) => state.users.currentUser);
  const currency = useSelector((state) => state.users.currency);

  const disableDays = useMemo(() => {
    const marked = {};
    if (bookedDays && bookedDays.length > 0) {
      bookedDays.forEach((d) => {
        marked[d] = { isDisable: true };
      });
    }
    return marked;
  }, [bookedDays]);

  useEffect(() => {
    setTimeout(() => {
      if (isOpen) {
        setFirstRender(false);
      } else {
        setFirstRender(true);
      }
    }, 200);
  }, [isOpen]);

  useEffect(() => {
    console.debug('Refresh DateRange 1');
    const timer = setTimeout(() => {
      if (date.start && date.end) {
        const start = date.start.getTime() / 1000;
        const end = date.end.getTime() / 1000;
        if (start !== dateRange?.from || end !== dateRange?.to)
          setDateRange({
            from: date.start.getTime() / 1000,
            fromDateString: dateToCalendarTimeString(date.start),
            to: date.end.getTime() / 1000,
            toDateString: dateToCalendarTimeString(date.end),
            checkInHour: dateRange?.checkInHour,
            checkOutHour: dateRange?.checkOutHour,
            checkInMinute: dateRange?.checkInMinute,
            checkOutMinute: dateRange?.checkOutMinute,
          });
      }
    }, 200); // To speed up UI
    return () => clearTimeout(timer);
  }, [date]);

  function clearFilter() {
    setDateRange(defaultDateRange);
    setDate({});
  }

  const closeActionSheet = useCallback(() => {
    if (onPress) {
      onPress();
    }
    setOpen(false);
  }, [onPress, setOpen]);

  const handleChangeDate = useCallback(
    (datePress) => {
      const dayPress = new Date(datePress.getTime() - datePress.getTimezoneOffset() * 60000);
      console.debug('CallBack: change date', dayPress);
      setDate((prev) => {
        let range = {};
        if (prev?.start) {
          if (prev?.start === prev.end && dayPress > prev?.start) {
            range = { start: prev.start, end: dayPress };
          } else if (prev?.start === prev.end && dayPress < prev?.start) {
            range = { start: dayPress, end: prev.end };
          } else {
            range = { start: dayPress, end: dayPress };
          }
        } else {
          range = { start: dayPress, end: dayPress };
        }
        if (range.start && range.end) {
          const arrayDays = dateRangeToArrayDays({
            from: range.start.getTime() / 1000,
            fromDateString: dateToCalendarTimeString(range.start),
            to: range.end.getTime() / 1000,
            toDateString: dateToCalendarTimeString(range.end),
            checkInHour: dateRange?.checkInHour,
            checkOutHour: dateRange?.checkOutHour,
            checkInMinute: dateRange?.checkInMinute,
            checkOutMinute: dateRange?.checkOutMinute,
          });
          if (arrayDays[0] !== arrayDays[1] && arrayDays.length < 3) {
            errorToast(t('search.selectAtLeast2Day'));
            return { start: dayPress, end: dayPress };
          } else if (bookedDays && bookedDays.length > 0 && arrayDays.some((d) => bookedDays.includes(d))) {
            return { start: dayPress, end: dayPress };
          } else {
            return range;
          }
        } else {
          return range;
        }
      });
    },
    [bookedDays, dateRange, t],
  );

  const renderDay = (day) => {
    const [ye, mo, da] = day.id.split('-');
    const dayNum = da.charAt(0) === '0' ? da.replace('0', '') : da;
    const color = day?.isEndDate || day?.isStartDate ? '$white' : '$black';
    if (!priceData) {
      return (
        <VStack alignItems={'center'}>
          <Text maxFontSizeMultiplier={1} color={color} alignSelf={'center'}>
            {dayNum}
          </Text>
        </VStack>
      );
    }
    const dayOfYear = dateToDayOfYears(day.id);
    const range = [
      ...(priceData?.filter((r) => {
        if (r.from < r.to && dayOfYear >= r.from && dayOfYear <= r.to) {
          return true;
        }
        if (r.from > r.to && (dayOfYear >= r.from || dayOfYear <= r.to)) {
          return true;
        } else {
          return false;
        }
      }) || []),
    ].shift() ?? { price: -1 };
    return (
      <VStack alignItems={'center'}>
        <Text testID={day.id} fontWeight={'$bold'} maxFontSizeMultiplier={1} color={color} alignSelf={'center'}>
          {dayNum}
        </Text>
        <Text maxFontSizeMultiplier={1} alignSelf={'center'} variant={'grey'} size={'tertiary'}>
          {formatCurrency(Math.round(range?.price * currency[currency?.current] * appConfig.serviceFee), currency.current)}
        </Text>
      </VStack>
    );
  };

  const renderFooter = useCallback(
    (props) => (
      <BottomSheetFooter {...props} bottomInset={bottom}>
        <VStack testID="calendarFooter" mb={-bottom} paddingBottom={bottom} backgroundColor={'$white'}>
          {info && <InfoCard text={info} />}
          <Box>
            <HStack m={'$4'} justifyContent={'space-between'} space={'2xl'} w="50%">
              <TimePicker
                title={t('rental.pickUp')}
                time={dateRange?.checkInHour ? new Date().setHours(dateRange?.checkInHour, dateRange?.checkInMinute, 0, 0) : undefined}
                setTime={(time) => setDateRange({ ...dateRange, checkInHour: time.getHours(), checkInMinute: time.getMinutes() })}
              />
              <TimePicker
                title={t('rental.return')}
                time={dateRange?.checkOutHour ? new Date().setHours(dateRange?.checkOutHour, dateRange?.checkOutMinute, 0, 0) : undefined}
                setTime={(time) => setDateRange({ ...dateRange, checkOutHour: time.getHours(), checkOutMinute: time.getMinutes() })}
              />
            </HStack>
          </Box>
          <Box backgroundColor={'$white'}>
            <HStack p={'$4'} justifyContent={'space-between'}>
              <Center>
                <UnderLineButton text={t('search.clear')} hideLeftIcon={true} onPress={clearFilter} />
              </Center>
              <Center>
                <Button testID="calendarSelect" pr={'$4'} pl={'$4'} onPress={closeActionSheet} minWidth={'$40'}>
                  <ButtonText>{rightButtonText ? rightButtonText : t('global.select')}</ButtonText>
                </Button>
              </Center>
            </HStack>
          </Box>
        </VStack>
      </BottomSheetFooter>
    ),
    [bottom, info, t, dateRange, closeActionSheet, rightButtonText, setDateRange],
  );

  return (
    <BottomSheet isOpen={isOpen} setOpen={setOpen} renderFooter={renderFooter}>
      {firstRender ? null : (
        <Box>
          <NativeViewGestureHandler disallowInterruption={true}>
            <Calendar
              monthHeight={470}
              renderDayContent={!priceData ? null : renderDay}
              locale={getCalendarLang(currentUser?.language)}
              minDate={dateToCalendarTimeString(new Date())}
              disabledDays={disableDays}
              theme={calendariotheme}
              onPress={handleChangeDate}
              startDate={date?.start ? new Date(date?.start.getTime() + new Date().getTimezoneOffset() * 60000) : undefined}
              endDate={date?.end ? new Date(date?.end.getTime() + new Date().getTimezoneOffset() * 60000) : undefined}
              renderAllMonths={IS_WEB}
              numberOfMonths={10}
              initialListSize={1}
            />
          </NativeViewGestureHandler>
        </Box>
      )}
    </BottomSheet>
  );
}

export default CalendarSheet;
