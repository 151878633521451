import { createSlice } from '@reduxjs/toolkit';

// Initial state
export const initialState = {
  fetchingOne: false,
  fetchingAll: false,
  updating: false,
  deleting: false,
  updateSuccess: false,
  car: { id: undefined },
  carAdding: {},
  carDisplay: {},
  carList: [],
  errorOne: null,
  errorAll: null,
  errorUpdating: null,
  errorDeleting: null,
};

const carSlice = createSlice({
  name: 'cars',
  initialState,
  reducers: {
    carRequest(state, action) {
      state.fetchingOne = true;
      state.errorOne = null;
      state.car = initialState.car;
    },
    carAllRequest(state) {
      state.fetchingAll = true;
      state.errorAll = null;
    },
    carUpdateRequest(state) {
      state.updating = true;
      state.updateSuccess = false;
    },
    carDeleteRequest(state) {
      state.deleting = true;
    },
    carAddingSetter(state, action) {
      state.carAdding = action.payload;
    },
    carDisplaySetter(state, action) {
      state.carDisplay = action.payload;
    },
    carSuccess(state, action) {
      state.fetchingOne = false;
      state.errorOne = null;
      state.car = action.payload;
    },
    carAllSuccess(state, action) {
      state.fetchingAll = false;
      state.errorAll = null;
      state.carList = action.payload;
    },
    carUpdateSuccess(state) {
      state.updating = false;
      state.updateSuccess = true;
      state.errorUpdating = null;
    },
    carDeleteSuccess(state) {
      state.deleting = false;
      state.errorDeleting = null;
      state.car = initialState.car;
    },
    carFailure(state, action) {
      state.fetchingOne = false;
      state.errorOne = action.payload;
      state.car = initialState.car;
    },
    carAllFailure(state, action) {
      state.fetchingAll = false;
      state.errorAll = action.payload;
      state.carList = [];
    },
    carUpdateFailure(state, action) {
      state.updating = false;
      state.updateSuccess = false;
      state.errorUpdating = action.payload;
    },
    carDeleteFailure(state, action) {
      state.deleting = false;
      state.errorDeleting = action.payload;
    },
    carReset() {
      return initialState;
    },
  },
});

// Actions and reducer export
export const {
  carRequest,
  carAllRequest,
  carUpdateRequest,
  carDeleteRequest,
  carAddingSetter,
  carDisplaySetter,
  carSuccess,
  carAllSuccess,
  carUpdateSuccess,
  carDeleteSuccess,
  carFailure,
  carAllFailure,
  carUpdateFailure,
  carDeleteFailure,
  carReset,
} = carSlice.actions;

export default carSlice.reducer;
