import React, { useEffect, useRef, useState } from 'react';
import { Modal, TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import {
  Box,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  Spinner,
  Text,
  Textarea,
  TextArea,
  TextareaInput,
  useToken,
  VStack,
} from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Signature from 'react-native-signature-canvas';
import { useDispatch, useSelector } from 'react-redux';

import ActionSheet from '../../../shared/components/actionSheet/actionSheet';
import { errorHandler } from '../../../shared/components/errorHandler/errorHander';
import Image from '../../../shared/components/image/image';
import MediaUploader from '../../../shared/components/media/mediaUploader';
import { PressableRow } from '../../../shared/components/pressable/pressableRow';
import ContentTranslation from '../../../shared/components/translation/contentTanslation';
import CheckCarAction, { checkCarSetter } from '../../../shared/reducers/checkCar.reducer';
import { firebaseApp } from '../../../shared/services/firebase/firebaseFactory';
import { Colors, Images, Metrics } from '../../../shared/themes';
import { driverMediaType } from '../../../shared/types/checkCar';
import { cleanFirestoreResult } from '../../../shared/util/firestore-utils';
import { getExtraInputProps } from '../../../shared/util/input-utils';
import { InternalLinks } from '../../../shared/util/link-utils';
import { updloadMedia } from '../../../shared/util/storage-utils';

function AddCheckCarSelfieAndSign() {
  const checkCar = useSelector((state) => state.checkCar.checkCar);
  const currentUser = useSelector((state) => state.users.currentUser);
  const dispatch = useDispatch();

  const ref = useRef();

  const [isOpen, setOpen] = useState(false);
  const [isGuideLineOpen, setGuideLineOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [car, setCar] = useState({});
  const [data, setData] = useState(
    checkCar?.originalContent && checkCar?.originalContent?.noteDriver ? checkCar?.originalContent?.noteDriver : '',
  );

  const { t } = useTranslation();
  const navigation = useNavigation();
  const { top, bottom } = useSafeAreaInsets();
  const theme = useToken();

  useEffect(() => {
    const content = checkCar?.originalContent ? { ...checkCar?.originalContent } : {};
    content.noteDriver = data;
    dispatch(checkCarSetter({ ...checkCar, originalContent: content }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    firebaseApp
      .firestore()
      .collection('cars')
      .doc(checkCar?.carId)
      .get()
      .then((res) => setCar(cleanFirestoreResult(res)))
      .catch((e) => errorHandler(e, true));
  }, [checkCar?.carId]);

  const imageArray = checkCar?.images ? checkCar?.images : [];

  const selfieArray = imageArray.filter((i) => i.type === driverMediaType.selfie);

  const signatureArray = imageArray.filter((i) => i.type === driverMediaType.signature);

  const signature = signatureArray.length > 0 ? signatureArray[0] : {};

  function openSignature() {
    setLoading(false);
    setOpen(true);
  }

  function closeSignature() {
    setOpen(false);
    setLoading(false);
  }

  function signSuccess(path, url) {
    dispatch(
      checkCarSetter({
        ...checkCar,
        images: [
          ...imageArray.filter((i) => i.type !== driverMediaType.signature),
          { url: url, type: driverMediaType.signature, part: 'DRIVER_' + driverMediaType.signature },
        ],
      }),
    );
    closeSignature();
  }

  function updateImages(images, type) {
    const cleanImages = images
      .filter((image) => image?.url !== undefined)
      .map((image) => ({ ...image, type: type, part: 'DRIVER_' + type }));
    dispatch(checkCarSetter({ ...checkCar, images: [...imageArray.filter((i) => i.type !== type), ...cleanImages] }));
  }

  return (
    <VStack space={'lg'}>
      <Text color={'$white'}>{t('checkCar.driverSelfieDescription')}</Text>
      <VStack>
        <Heading color={'$white'} size={'h4'}>
          {t('checkCar.contracts')}
        </Heading>

        <PressableRow
          leftIconName={'document-text-outline'}
          leftIconColor={'$white'}
          color={'$white'}
          onPress={() => setGuideLineOpen(true)}
          text={t('car.ownerGuidlines')}
        />
        <ActionSheet isOpen={isGuideLineOpen} setOpen={setGuideLineOpen} headerText={t('car.ownerGuidlines')}>
          <Box mx={'$4'}>
            <ContentTranslation object={car} field={'carGuidelines'} lang={currentUser?.language} limit={100000000} />
          </Box>
        </ActionSheet>
        <Divider />
        <PressableRow
          leftIconName={'document-text-outline'}
          leftIconColor={'$white'}
          color={'$white'}
          onPress={() => navigation.navigate('CustomWebView', { url: InternalLinks.rentalAgreement, title: t('legal.rentalAgreement') })}
          text={t('legal.rentalAgreement')}
        />
        <Divider />
      </VStack>
      <VStack space={'md'}>
        <Heading color={'$white'} size={'h4'}>
          {t('checkCar.addDriverSelfie')}
        </Heading>
        <Box height={Metrics.images.xlarge}>
          <MediaUploader
            initialList={selfieArray}
            onUpdate={(images) => updateImages(images, driverMediaType.selfie)}
            storagePath={'check-car'}
            multipleSelectionAllow={false}
            cameraOnly={true}
            frontCamera={true}
          />
        </Box>
      </VStack>
      <VStack space={'md'}>
        <Heading color={'$white'} size={'h4'}>
          {t('checkCar.signature')}
        </Heading>
        <TouchableOpacity onPress={openSignature}>
          <Image
            rounded={'$2xl'}
            height={Metrics.images.xlarge}
            width={'100%'}
            resizeMode="cover"
            alt={'image'}
            source={
              signature?.url
                ? {
                    uri: signature?.url,
                  }
                : Images.noImage
            }
          />
        </TouchableOpacity>
        <VStack space={'md'}>
          <Heading color={'$white'}>{t('checkCar.addNoteTitle')}</Heading>
          <Textarea {...getExtraInputProps()}>
            <TextareaInput color={'$white'} value={data} onChangeText={setData} />
          </Textarea>
        </VStack>
        <Modal visible={isOpen} onRequestClose={() => setOpen(false)} isOpen={isOpen} setOpen={setOpen}>
          <VStack space={'md'} mx={'$screenMargin'} my={'$4'} style={{ marginTop: top, marginBottom: bottom }}>
            <HStack alignItems={'flex-start'}>
              <Icon size={'xl'} as={Ionicons} name={'close'} onPress={() => setOpen(false)} />
              <Heading size={'h1'}>{t('checkCar.signHer')}</Heading>
            </HStack>
            <VStack space={'md'} alignItems={'center'} w={'100%'} h={'100%'}>
              {loading ? (
                <Center w="100%">
                  <Spinner size={100} />
                </Center>
              ) : (
                <Signature
                  ref={ref}
                  style={{
                    height: Metrics.images.xxlarge,
                    width: '100%',
                  }}
                  onOK={async (image) => {
                    setLoading(true);
                    await updloadMedia(image, 'check-car', signSuccess);
                  }}
                  onEmpty={() => console.info('empty')}
                  descriptionText={t('checkCar.signDescription')}
                  clearText={t('checkCar.clear')}
                  confirmText={t('checkCar.confirm')}
                  backgroundColor={Colors.white}
                  webStyle={`.m-signature-pad--footer
                  .button {
                    background-color: ${Colors.blue[400]};
                    border-radius: ${theme?.components.Button.baseStyle.borderRadius}
                    color: #FFF;
                  }`}
                  imageType={'image/png'}
                />
              )}
            </VStack>
          </VStack>
        </Modal>
      </VStack>
    </VStack>
  );
}

export default AddCheckCarSelfieAndSign;
