import React from 'react';

import { View, VStack } from '@gluestack-ui/themed';

const Skeleton = (props) => {
  return <View {...props} bgColor={'$light300'} />;
};

Skeleton.Text = ({ lines, ...props }) => {
  return (
    <VStack space={'sm'}>
      {Array.from({ length: lines ?? 1 }).map((_, index) => (
        <View key={index} h={'$4'} w={'$full'} rounded={'$full'} {...props} bgColor={'$light300'} />
      ))}
    </VStack>
  );
};

export default Skeleton;
