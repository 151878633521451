import React, { useEffect, useState } from 'react';

import { Ionicons } from '@expo/vector-icons';
import { Box, Button, ButtonSpinner, ButtonText, HStack, Icon, Input, InputField, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import CardContainer from '../../shared/components/container/cardContainer';
import RegionPicker from '../../shared/components/countryPicker/countryPicker';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Layout from '../../shared/components/layout/layout';
import errorToast from '../../shared/components/toast/errorToast';

export function ButtonPhoneAuth({ screen, params }) {
  const navigation = useNavigation();
  const { t } = useTranslation();

  return (
    <Button w={'$full'} variant={'outline'} onPress={() => navigation.navigate('LoginWithPhone', { screen: screen, params: params })}>
      <Icon as={Ionicons} name={'call-outline'} color={'$black'} mr={'$3'} />
      <ButtonText>{t('login.signInWithPhone')}</ButtonText>
    </Button>
  );
}

const phoneRegExp = /^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$/;

function PhoneAuth() {
  const currentUser = useSelector((state) => state.users.currentUser);
  const lastRedirectableRoute = useSelector((state) => state.users.lastRedirectableRoute);

  const [phone, setPhone] = useState({});
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const navigation = useNavigation();

  useEffect(() => {
    console.debug('Effect: refresh currentUser');
    if (currentUser?.uid && currentUser?.conditionAccepted) {
      if (lastRedirectableRoute) {
        navigation.navigate(lastRedirectableRoute?.name, lastRedirectableRoute?.params);
      } else {
        navigation.popToTop();
      }
      setLoading(false);
    }
  }, [currentUser, navigation]);

  const validationSchemaPhone = Yup.object().shape({
    phone: Yup.string().required('required').matches(phoneRegExp, 'Phone number is not valid').min(12, 'too short').max(12, 'too long'),
  });

  const onSubmitPhoneNumber = async () => {
    const fullPhone = (phone.code + '' + phone.number).trim();
    validationSchemaPhone
      .validate({ phone: fullPhone }, { abortEarly: true })
      .then((valid) => {
        navigation.navigate('VerificationCode', { phoneNumber: fullPhone });
      })
      .catch((err) => {
        errorHandler(err, false);
        errorToast(err?.errors[0]);
      });
  };

  return (
    <Layout>
      <CardContainer>
        <VStack m={'$4'} space={'md'}>
          <HStack space={'sm'} justifyContent={'space-between'}>
            <Box w={'30%'}>
              <RegionPicker
                title={t('login.country')}
                type={'phone'}
                placeHolder={t('login.countryPlaceHolder')}
                setPhoneCode={(code) => setPhone({ ...phone, code: code })}
                displayPhone={true}
              />
            </Box>

            <Input>
              <InputField w={'60%'} inputMode={'tel'} onChangeText={(value) => setPhone({ ...phone, number: value })} />
            </Input>
          </HStack>
          <Button isDisabled={loading} mt={'$4'} onPress={onSubmitPhoneNumber}>
            {loading && <ButtonSpinner mr="$1" />}
            <ButtonText>{t('login.sendPhoneVerificationCode')}</ButtonText>
          </Button>
        </VStack>
      </CardContainer>
    </Layout>
  );
}

export default PhoneAuth;
