import React from 'react';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useFocusEffect } from '@react-navigation/native';
import { t } from 'i18next';

import ReservationList from '../../shared/components/entities/reservation/reservationList';
import Layout from '../../shared/components/layout/layout';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { tabScreenOption } from '../../shared/util/navigation-utils';

const Tab = createMaterialTopTabNavigator();

function OwnerReservationList({ route, navigation }) {
  const { carId } = route?.params ?? {};
  const maxWidth = useMaxWidth();

  return (
    <Layout>
      <Tab.Navigator
        initialParams={{ carId: carId }}
        screenOptions={{
          ...tabScreenOption,
          tabBarStyle: {
            ...tabScreenOption.tabBarStyle,
            width: maxWidth,
            alignSelf: 'center',
          },
        }}>
        <Tab.Screen
          name="current"
          children={(props) => <ReservationList type={'current'} carId={carId} {...props} />}
          initialParams={{ carId: carId }}
          options={{ title: t('screen.current') }}
        />
        <Tab.Screen
          name="up-comming"
          children={(props) => <ReservationList type={'up-comming'} carId={carId} {...props} />}
          initialParams={{ carId: carId }}
          options={{ title: t('screen.upcoming') }}
        />
        <Tab.Screen
          name="passed"
          children={(props) => <ReservationList type={'passed'} carId={carId} {...props} />}
          initialParams={{ carId: carId }}
          options={{ title: t('screen.ended') }}
        />
      </Tab.Navigator>
    </Layout>
  );
}

export default OwnerReservationList;
