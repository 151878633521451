import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { userCurrentRequest } from '../../../reducers/user.reducer';
import { firebaseApp } from '../../../services/firebase/firebaseFactory';
import { errorHandler } from '../../errorHandler/errorHander';
import ModalSwitcher from '../../modalSwitcher/modalSwitcher';

function UserConnectionListener() {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser);
  const userId = currentUser?.uid;

  useEffect(() => {
    console.debug('Effect: listener on login');
    if (userId) {
      setOpen(true);
      const subscriber = firebaseApp
        .firestore()
        .collection('users')
        .doc(userId)
        .onSnapshot(
          (docSnapshot) => {
            dispatch(userCurrentRequest(docSnapshot.id));
          },
          (err) => {
            errorHandler(err, true);
          },
        );
      return () => subscriber();
    }
  }, [userId, dispatch]);

  useEffect(() => {
    console.debug('Effect: refresh currentUser');
    if (currentUser?.uid) {
      setOpen(false);
    }
  }, [currentUser]);

  return <ModalSwitcher isOpen={isOpen} setOpen={setOpen} text={t('profile.fetchingYourUser')} />;
}

export default UserConnectionListener;
