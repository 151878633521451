import React, { useMemo } from 'react';
import { Platform } from 'react-native';

import { View } from '@gluestack-ui/themed';
import { getLocales } from 'expo-localization';
import * as NavigationBar from 'expo-navigation-bar';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';

import { defaultCurrencyRate } from '..//shared/util/currency';
import SmartBanner from '../shared/components/smartBanner/smartBanner';
import CookieBanner from '../shared/components/web/cookieBanner';
import UserAction, {
  setUserMode as setMode,
  userCurrencyUpdate,
  userCurrentRequest,
  userCurrentSuccess,
  userUpdateRequest,
} from '../shared/reducers/user.reducer';
import { getCurrency } from '../shared/services/firebase/currency-firebase';
import { firebaseApp } from '../shared/services/firebase/firebaseFactory';
import { Colors, Metrics } from '../shared/themes';
import { getMobileOperatingSystem } from '../shared/util/device-utils';
import { supportedLang } from '../shared/util/language-utils';
import { registerForPushNotificationsAsync } from '../shared/util/notification-utils';
import { getCookie, setCookie } from '../shared/util/storage-utils';
import { useDidUpdateEffect } from '../shared/util/use-did-update-effect';

function InitApp(props) {
  const { i18n } = useTranslation();

  const dispatch = useDispatch();
  const userMode = useSelector((state) => state.users.userMode);
  const updating = useSelector((state) => state.users.updating);
  const errorUpdating = useSelector((state) => state.users.errorOne);
  const currentUser = useSelector((state) => state.users.currentUser);

  const setUserMode = (mode) => dispatch(setMode(mode));
  const updateUser = (userId) => dispatch(userUpdateRequest(userId));
  const setCurrentUser = (u) => dispatch(userCurrentSuccess(u));
  const currencyUpdate = (c) => dispatch(userCurrencyUpdate(c));
  const getCurrentUser = (userId) => dispatch(userCurrentRequest(userId));

  // Handle user state changes
  function onAuthStateChanged(user) {
    if (user?.uid) {
      //TODO check why no need it
      console.info('auth change: ', user?.uid);
      getCurrentUser(user?.uid);
    } else {
      console.info('no user auth change: ');
      setUserMode(true);
      setCurrentUser(undefined);
    }
  }

  useDidUpdateEffect(() => {
    if (currentUser?.language && i18n.language !== currentUser?.language) {
      window.location.pathname = window.location.pathname.replace('/' + i18n.language + '/', '/' + currentUser?.language + '/');
    }
  }, [currentUser?.language]);

  React.useEffect(() => {
    console.debug('Effect: Notification, i18n, crashlytics');
    if (currentUser?.uid) {
      registerForPushNotificationsAsync()
        .then((token) => {
          if (currentUser?.tokens && currentUser?.tokens.length > 0 && currentUser?.tokens.filter((t) => t === token).length > 0) {
            return;
          } else {
            console.debug('Add token to currentUser:', token);
            const settedToken = currentUser?.tokens ? currentUser.tokens : [];
            if (!settedToken.includes(token)) {
              updateUser({ uid: props?.currentUser?.uid, tokens: [...settedToken, token] });
            }
          }
        })
        .catch((e) => {});
      if (currentUser?.userMode !== userMode) {
        setUserMode(currentUser?.userMode);
      }
    }
    getCurrency('USD')
      .then((c) => {
        if (currentUser?.currency in c) {
          console.info('set user currency');
          currencyUpdate({ ...c, current: currentUser?.currency });
        } else if (getLocales()[0]?.currencyCode in c) {
          console.info('set device currency');
          currencyUpdate({ ...c, current: getLocales()[0]?.currencyCode });
        } else {
          console.info('set default currency');
          currencyUpdate({ ...c, current: 'USD' });
        }
      })
      .catch((e) => {
        console.info('set default currency after Error');
        currencyUpdate({ ...defaultCurrencyRate, current: 'USD' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  React.useEffect(() => {
    console.debug('Effect: Subscribe authChange ');
    const subscriber = firebaseApp.auth().onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    console.debug('Effect: Change navbar android');
    if (Platform.OS === 'android') {
      NavigationBar.setButtonStyleAsync('dark');
      NavigationBar.setBackgroundColorAsync(Colors.white);
    }
  }, []);

  useDidUpdateEffect(() => {
    console.debug('Effect: refresh user after updating');
    if (!updating && !errorUpdating) {
      console.info('refresh current user', firebaseApp.auth().currentUser?.uid);
      getCurrentUser(firebaseApp.auth().currentUser?.uid);
    }
  }, [updating, errorUpdating]);

  const loader = document.querySelector('.preloader');

  const showLoader = () => loader.classList.remove('preloader');
  const animatePreloader = () => loader.classList.add('loader-fadeOut');
  const hidePreloader = () => loader.classList.add('loader-hide');

  React.useEffect(() => {
    //showLoader();
    animatePreloader();
    setTimeout(() => {
      hidePreloader();
      showLoader();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // React.useEffect(() => {
  //   const alreadyRedirectToApp = getCookie('alreadyRedirectToApp');

  //   if (!alreadyRedirectToApp) {
  //     const os = getMobileOperatingSystem();

  //     setCookie('alreadyRedirectToApp', true, 0.01);

  //     window.location = window.location.href.replace(window.location.origin, 'flyncar:/');

  //     if (os === 'iOS') {
  //       setTimeout(function () {
  //         window.location = 'itms-apps://itunes.apple.com/us/app/flyncar/id6449300955';
  //       }, 10000);
  //     } else if (os === 'Android') {
  //       setTimeout(function () {
  //         window.location = 'market://details?id=com.flyandcar.app';
  //       }, 10000);
  //     }
  //   }
  // }, []);

  return (
    <View flex={1} h={Metrics.fullHeight} w={Metrics.fullWidth} overflow={'hidden'}>
      <View flex={1} h={Metrics.fullHeight} w={Metrics.fullWidth} overflow={'hidden'}>
        <Helmet>
          <meta name="locale" content={i18n.language} />
          <meta name="og:locale" content={i18n.language} />
          <meta name="twitter:url" content={window.location.href} />
          <meta name="og:url" content={window.location.href} />
          {supportedLang.map((lan) => (
            <link key={lan} rel="alternate" hreflang={lan} href={window.location.href.replace(`/${i18n?.language}`, `/${lan}`)} />
          ))}
          <link rel="alternate" hreflang="x-default" href={window.location.href} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <SmartBanner title={'FlynCar'} author={'FlynCar Inc.'} />
        {props.children}
      </View>
      <CookieBanner />
    </View>
  );
}

export default InitApp;
