import { call, put } from 'redux-saga/effects';

import { callApi } from './call-api.saga';
import { errorHandler } from '../components/errorHandler/errorHander';
import {
  carAllFailure,
  carAllSuccess,
  carDeleteFailure,
  carDeleteSuccess,
  carFailure,
  carSuccess,
  carUpdateFailure,
  carUpdateSuccess,
} from '../reducers/car.reducer';

function* getCar(api, action) {
  const carId = action.payload;
  // make the call to the api
  const apiCall = call(api.getCar, carId);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(carSuccess(response.data));
  } else {
    yield put(carFailure(response.data));
  }
}

function* getAllCars(api, action) {
  const options = action.payload;
  // make the call to the api
  const apiCall = call(api.getAllCars, options);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(carAllSuccess(response.data, response.headers));
  } else {
    yield put(carAllFailure(response.data));
  }
}

function* updateCar(api, action) {
  const car = action.payload;
  // make the call to the api
  try {
    const idIsNotNull = !(car.uid === null || car.uid === undefined);
    const apiCall = call(idIsNotNull ? api.updateCar : api.createCar, car);
    yield call(callApi, apiCall);
    yield put(carUpdateSuccess());
  } catch (e) {
    errorHandler(e, false);
    yield put(carUpdateFailure(e.message));
  }
}

function* deleteCar(api, action) {
  const carId = action.payload;
  // make the call to the api
  const apiCall = call(api.deleteCar, carId);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(carDeleteSuccess());
  } else {
    yield put(carDeleteFailure(response.data));
  }
}

export default {
  getAllCars,
  getCar,
  deleteCar,
  updateCar,
};
