import React from 'react';

import { useSelector } from 'react-redux';

import ProfileConnected from './component/profileConnected';
import { ProfileNotConnectedScreen } from './component/profileNotConnected';

function ProfileScreen() {
  const currentUser = useSelector((state) => state.users.currentUser);

  return !currentUser ? <ProfileNotConnectedScreen /> : <ProfileConnected />;
}

export default ProfileScreen;
