import React, { useCallback, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import {
  Box,
  Button,
  ButtonText,
  Center,
  Divider,
  FormControlLabel,
  FormControlLabelText,
  Heading,
  HStack,
  Icon,
  Text,
  useToken,
  VStack,
} from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import BottomSheet from '../../../shared/components/bottomSheet/bottomSheet';
import BottomSheetFooter from '../../../shared/components/bottomSheet/BottomSheetFooter';
import BottomSheetScrollView from '../../../shared/components/bottomSheet/bottomSheetScrollView';
import BottomSheetTextInput from '../../../shared/components/bottomSheet/BottomSheetTextInput';
import AeraPicker from '../../../shared/components/entities/aera/aeraPicker';
import InputPriceCard from '../../../shared/components/input/inputPriceCard';
import RadiusMap from '../../../shared/components/map/radiusMap';
import { PressableRow } from '../../../shared/components/pressable/pressableRow';
import UnderLineButton from '../../../shared/components/pressable/underLineButton';
import { carAddingSetter } from '../../../shared/reducers/car.reducer';
import { getPriceFromCurrency } from '../../../shared/util/currency';
import { getExtraInputProps } from '../../../shared/util/input-utils';

function DeliveryLocation() {
  const car = useSelector((state) => state.cars.carAdding);
  const currency = useSelector((state) => state.users.currency);

  const dispatch = useDispatch();

  const setCar = (car) => dispatch(carAddingSetter(car));

  const { t } = useTranslation();
  const { bottom } = useSafeAreaInsets();

  const space2 = useToken('space', '2');
  const radiiMd = useToken('radii', 'md');
  const colorLight500 = useToken('colors', 'light500');

  const [isAeraPickerOpen, setAeraPickerOpen] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [deliveryInfo, setDeliveryInfo] = useState({});
  const [text, setText] = useState('');

  function pickNewLocation(locationData) {
    setDeliveryInfo(locationData);
    setAeraPickerOpen(false);
    setText('');
    setOpen(true);
  }

  function saveDeliveryLocation() {
    const deliveryLocations = car?.deliveryLocation ?? [];
    const originalContent = car?.originalContent ? { ...car?.originalContent } : {};

    const uuid = deliveryInfo?.uid ?? uuidv4();

    originalContent[uuid] = text;

    setCar({
      ...car,
      deliveryLocation: [...deliveryLocations.filter((d) => d?.uid !== uuid), { ...deliveryInfo, text: text, uid: uuid }],
      originalContent: originalContent,
    });
    setOpen(false);
  }

  function deleteDeliveryLocation() {
    const uuid = deliveryInfo?.uid;
    const deliveryLocations = car?.deliveryLocation ?? [];
    const originalContent = car?.originalContent ? { ...car?.originalContent } : {};

    delete originalContent[uuid];

    setCar({ ...car, deliveryLocation: [...deliveryLocations.filter((d) => d?.uid !== uuid)], originalContent: originalContent });
    setOpen(false);
  }

  function selectDeliveryLocation(deliveryLoc) {
    const originalContent = car?.originalContent ? { ...car?.originalContent } : {};

    setDeliveryInfo(deliveryLoc);
    if (deliveryLoc?.uid in originalContent) {
      setText(originalContent[deliveryLoc?.uid]);
    } else {
      setText('');
    }
    setOpen(true);
  }

  const renderFooter = useCallback(
    (props) => (
      <BottomSheetFooter {...props} bottomInset={bottom}>
        <Box backgroundColor={'$white'} mb={-bottom} paddingBottom={bottom}>
          <HStack mx={'$screenMargin'} py={'$2'} justifyContent={'space-between'}>
            <Center>
              <UnderLineButton color={'$red400'} text={t('global.delete')} hideLeftIcon={true} onPress={deleteDeliveryLocation} />
            </Center>
            <Center>
              <Button testID="button.confirmDeliveryLocation" pr={'$4'} pl={'$4'} onPress={saveDeliveryLocation}>
                <ButtonText>{t('global.save')}</ButtonText>
              </Button>
            </Center>
          </HStack>
        </Box>
      </BottomSheetFooter>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bottom, deleteDeliveryLocation, saveDeliveryLocation],
  );

  return (
    <VStack space={'md'}>
      <Text mb={'$4'} variant={'grey'} size={'secondary'}>
        {t('addCar.deliveryLocationDescription')}
      </Text>
      <Box variant={'cardContainer'} rounded={'$full'} px={'$4'} py={'$4'} mt={'$2'} mx={'$4'}>
        <TouchableOpacity testID="button.selectDelivery" onPress={() => setAeraPickerOpen(true)}>
          <HStack space={'sm'}>
            <HStack space={'sm'} justifyContent={'center'} alignItems={'center'}>
              <Icon as={Ionicons} size={'md'} name="search" color={'$black'} />
              <Text w={'90%'}>{t('addCar.deliverySearchPlaceHolder')}</Text>
            </HStack>
          </HStack>
        </TouchableOpacity>
      </Box>
      <AeraPicker
        isOpen={isAeraPickerOpen}
        setOpen={setAeraPickerOpen}
        location={deliveryInfo}
        setLocation={pickNewLocation}
        forOwner={true}
        carLocation={car?._geoloc}
        onlyOneLocation={true}
      />
      <VStack>
        {car?.deliveryLocation &&
          car?.deliveryLocation.map((deliveryLocation) => (
            <VStack key={deliveryLocation?.uid}>
              <Divider />
              <PressableRow
                text={deliveryLocation.name}
                infoElement={
                  <Text alignSelf={'center'} fontWeight={'$bold'}>
                    {deliveryLocation?.price
                      ? getPriceFromCurrency(deliveryLocation?.price, deliveryLocation?.currency, currency)
                      : t('global.free')}
                  </Text>
                }
                onPress={() => selectDeliveryLocation(deliveryLocation)}
              />
            </VStack>
          ))}
      </VStack>
      <BottomSheet
        isOpen={isOpen}
        setOpen={setOpen}
        renderFooter={renderFooter}
        snapToTop={true}
        headerText={t('addCar.deliveryLocationDetail')}>
        <BottomSheetScrollView>
          <Box mb={120} space={'md'} w={'100%'} mt={'$4'}>
            <VStack space={'lg'} mx={'$screenMargin'}>
              <VStack space={'sm'}>
                <Text variant={'grey'} size={'secondary'}>
                  {deliveryInfo?.name}
                </Text>
                <RadiusMap radius={car?.deliveryRadius ?? 0} coord={car?._geoloc} locationCoord={deliveryInfo?.location} toMap={false} />
              </VStack>
              <VStack>
                <FormControlLabel>
                  <FormControlLabelText>{t('ownerCar.price')}</FormControlLabelText>
                </FormControlLabel>
                <InputPriceCard
                  testID={'input.price'}
                  placeHolder={t('ownerCar.price')}
                  currency={currency}
                  dolarPrice={deliveryInfo?.price}
                  onChangePrice={(value) => setDeliveryInfo({ ...deliveryInfo, price: value, currency: 'USD' })}
                  info={t('addCar.deliveryInformationForFree')}
                  label={t('car.deliveryOptionSubTitleForOwner')}
                  isBottomSheet={true}
                />
              </VStack>
              <VStack space={'sm'}>
                <Heading mt={'$2'}>{t('addCar.deliveryInformationTitle')}</Heading>
                <Text>{t('addCar.deliveryInformationInfo')}</Text>
                <BottomSheetTextInput
                  style={{
                    height: 200,
                    width: '100%',
                    borderRadius: radiiMd,
                    borderWidth: 1,
                    padding: space2,
                    textAlignVertical: 'top',
                    borderColor: colorLight500,
                  }}
                  {...getExtraInputProps()}
                  placeholder={t('addCar.carLocationDetailExample')}
                  defaultValue={text}
                  onChangeText={setText}
                  multiline
                />
              </VStack>
            </VStack>
          </Box>
        </BottomSheetScrollView>
      </BottomSheet>
    </VStack>
  );
}

export default DeliveryLocation;
