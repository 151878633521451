import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Button, ButtonText, Divider, Heading, HStack, ScrollView, VStack } from '@gluestack-ui/themed';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import * as Linking from 'expo-linking';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import BottomAction from '../../shared/components/bottomAction/bottomAction';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Image from '../../shared/components/image/image';
import InvoiceDetail from '../../shared/components/info/invoiceDetail';
import PaymentLegalText from '../../shared/components/legal/paymentLegal';
import successToast from '../../shared/components/toast/successToast';
import ContentTranslation from '../../shared/components/translation/contentTanslation';
import WebScrollView from '../../shared/components/web/webScrollView';
import ImageView from '../../shared/generic/ImageView';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { Metrics } from '../../shared/themes';
import { resizedImage } from '../../shared/util/image-utils';

function AdditionalPaymentElement({ reservationId, additionalPaymentId, paymentInfo }) {
  const [imageZoom, setImageZoom] = useState([]);
  const [isVisible, setVisible] = useState(false);
  const [imageIndex, setIndex] = useState(0);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [bottomActionHeight, setBottomActionHeight] = useState(0);

  const stripe = useStripe();
  const elements = useElements();
  const maxWidth = useMaxWidth();

  const { t, i18n } = useTranslation();

  const currentUser = useSelector((state) => state.users.currentUser);

  function payAdditionalPayment() {
    console.info('Start ReservationPayment');
    setPaymentLoading(true);
    stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: Linking.createURL(
            `/${i18n?.language}/additional-payment-detail?reservationId=${reservationId}&additionalPaymentId=${additionalPaymentId}&fromPayment=${true}`,
          ),
        },
      })
      .then((confirmPay) => {
        if (confirmPay.error) {
          errorHandler(confirmPay.error, true);
          setPaymentLoading(false);
        } else {
          console.info('Success payment');
          successToast('payment.success');
          setPaymentLoading(false);
        }
      })
      .catch(() => {
        setPaymentLoading(false);
      });
  }

  function zoomInImage(imArr, index) {
    setImageZoom(
      imArr.map((i) => {
        return { uri: i.url };
      }),
    );
    setIndex(index);
    setVisible(true);
  }

  return (
    <>
      <WebScrollView maxWidth={maxWidth > 700 ? 700 : maxWidth}>
        <VStack my={'$4'} mx={'$screenMargin'} space={'md'}>
          <ImageView images={imageZoom} imageIndex={imageIndex} visible={isVisible} onRequestClose={() => setVisible(false)} />
          <Heading>{t('additionalPayment.request')}</Heading>
          <InvoiceDetail
            reservationInvoice={{
              ...paymentInfo?.additionalPayment.invoice,
              reason: paymentInfo?.additionalPayment?.originalContent?.reason,
            }} // TODO get translation from field
            isLoading={false}
          />
          <Divider />
          <Heading>{t('additionalPayment.note')}</Heading>
          <ContentTranslation object={paymentInfo?.additionalPayment} field={'note'} lang={currentUser?.language} limit={100000000} />
          <Divider />
        </VStack>
        {paymentInfo?.additionalPayment?.images?.length > 0 && (
          <VStack space={'md'} my={'$4'}>
            <Heading mx={'$4'}>{t('additionalPayment.photo')}</Heading>
            <ScrollView horizontal showsHorizontalScrollIndicator={false}>
              <HStack>
                {paymentInfo?.additionalPayment?.images.map((i, index) => (
                  <TouchableOpacity key={i.url} onPress={() => zoomInImage(paymentInfo?.additionalPayment?.images, index)}>
                    <Image
                      rounded={'$2xl'}
                      alt="inside car"
                      mx={'$4'}
                      height={Metrics.images.xlarge}
                      width={Metrics.images.xxlarge}
                      source={{ uri: resizedImage(i.url) }}
                    />
                  </TouchableOpacity>
                ))}
              </HStack>
            </ScrollView>
            <Divider />
          </VStack>
        )}
        <VStack m={'$4'} space={'md'}>
          <Heading>{t('reservation.paymentMethod')}</Heading>
          <PaymentElement />
        </VStack>
      </WebScrollView>
      <BottomAction
        onLayout={(event) => {
          setBottomActionHeight(event?.nativeEvent?.layout?.height ?? 0);
        }}>
        <VStack mx={'$screenMargin'} my={'$4'} space={'sm'} w={maxWidth > 700 ? 700 : maxWidth} alignSelf="center">
          <PaymentLegalText />
          <Button isLoading={isPaymentLoading} onPress={payAdditionalPayment}>
            <ButtonText>{t('reservation.confirmAndPay')}</ButtonText>
          </Button>
        </VStack>
      </BottomAction>
    </>
  );
}

export default AdditionalPaymentElement;
