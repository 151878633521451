import React from 'react';

import { Box, Heading, HStack, VStack } from '@gluestack-ui/themed';
import { Text } from '@gluestack-ui/themed';

export default function StatsBarPagePro({ stats, maxWidth }) {
  return (
    <Box bgColor="$white" w={'$full'} alignItems="center" py={'$4'}>
      <HStack
        space="md"
        alignItems="center"
        justifyContent="space-evenly"
        py={'$4'}
        w={maxWidth}
        $base-flexDirection="column"
        $md-flexDirection="row">
        {stats.map((stat) => (
          <VStack key={stat?.title + stat?.subTitle} space="md" alignItems="center">
            <Heading fontSize={36} lineHeight={44}>
              {stat?.title}
            </Heading>
            <Text>{stat?.subTitle}</Text>
          </VStack>
        ))}
      </HStack>
    </Box>
  );
}
