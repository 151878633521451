import { firebaseApp } from './firebaseFactory';

export function setupBooking(
  carId,
  from,
  to,
  checkInHour,
  checkInMinute,
  checkOutHour,
  checkOutMinute,
  selectedExtraOption,
  pickUpPlaceId,
  returnPlaceId,
  quoteId,
) {
  return firebaseApp
    .functions()
    .httpsCallable('onCall-reservation-setupBooking')({
      carId: carId,
      from: from,
      to: to,
      checkInHour: checkInHour,
      checkInMinute: checkInMinute,
      checkOutHour: checkOutHour,
      checkOutMinute: checkOutMinute,
      selectedExtraOption: selectedExtraOption,
      pickUpPlaceId: pickUpPlaceId,
      returnPlaceId: returnPlaceId,
      quoteId: quoteId,
    })
    .then((res) => res.data);
}

export function getRoom(userToContactId, carId) {
  return firebaseApp
    .functions()
    .httpsCallable('onCall-message-getRoom')({ userToContactId: userToContactId, carId: carId })
    .then((res) => {
      return res.data;
    });
}

export function updateRoom(roomId, carId) {
  return firebaseApp
    .functions()
    .httpsCallable('onCall-message-updateRoom')({ roomId, carId })
    .then((res) => {
      return res.data;
    });
}

export function confirmBooking(reservationId, reservationAccepted) {
  return firebaseApp
    .functions()
    .httpsCallable('onCall-reservation-confirmBooking')({
      reservationId: reservationId,
      reservationAccepted: reservationAccepted,
    })
    .then((res) => {
      return res.data;
    });
}

export function requestAdditionalPayment(additionalPayment) {
  return firebaseApp
    .functions()
    .httpsCallable('onCall-reservation-requestAdditionalPayment')(additionalPayment)
    .then((res) => {
      return res.data;
    });
}

export function processAdditionalPayment(reservationId, additionalPaymentId) {
  return firebaseApp
    .functions()
    .httpsCallable('onCall-reservation-processAdditionalPayment')({
      reservationId: reservationId,
      additionalPaymentId: additionalPaymentId,
    })
    .then((res) => {
      return res.data;
    });
}

export function createCheckoutSessionSetup() {
  return firebaseApp
    .functions()
    .httpsCallable('onCall-payment-getSetupCheckoutSession')()
    .then((res) => {
      return res.data;
    });
}

export function getAccountLink(countryCode) {
  return firebaseApp
    .functions()
    .httpsCallable('onCall-payment-getAccountLink')({ countryCode: countryCode })
    .then((res) => {
      return res.data;
    });
}

export function getLoginLink() {
  return firebaseApp
    .functions()
    .httpsCallable('onCall-payment-getLoginLink')()
    .then((res) => {
      return res.data;
    });
}

export function contactFlynCarByForm(data) {
  return firebaseApp
    .functions()
    .httpsCallable('onCall-contact-contactFlynCar')(data)
    .then((res) => {
      return res.data;
    });
}
