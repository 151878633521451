import React, { useCallback, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Button, ButtonText, Heading, HStack, Icon, Text, VStack } from '@gluestack-ui/themed';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { errorHandler } from '../../../shared/components/errorHandler/errorHander';
import Image from '../../../shared/components/image/image';
import UnderLineButton from '../../../shared/components/pressable/underLineButton';
import Skeleton from '../../../shared/components/skeleton/skeleton';
import ImageView from '../../../shared/generic/ImageView';
import { getDriverDocuments, getUserDriversLicence, updateUserDocument } from '../../../shared/services/firebase/user-firebase';
import { resizedImage } from '../../../shared/util/image-utils';
import { userDocumentStatus } from '../../../shared/util/userDocument-utils';

function UserDocuments({ userId, setDocuments, isEditable, info }) {
  const [driverDocuments, setDriverDocuments] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isVisible, setVisible] = useState(false);
  const [imageToDisplay, setImageToDisplay] = useState([]);
  const [imageIndex, setIndex] = useState(0);

  const currentUser = useSelector((state) => state.users.currentUser);

  const { t } = useTranslation();
  const navigation = useNavigation();

  function getDoc(uId) {
    setLoading(true);
    if (uId === currentUser?.uid) {
      getUserDriversLicence(uId)
        .then((res) => {
          setDriverDocuments(res.filter((doc) => doc.status !== userDocumentStatus.toDelete));
          if (setDocuments) {
            setDocuments(res.filter((doc) => doc.status !== userDocumentStatus.toDelete));
          }
          setLoading(false);
        })
        .catch((e) => {
          errorHandler(e, true);
          setLoading(false);
        });
    } else {
      getDriverDocuments(uId, currentUser?.uid)
        .then((res) => {
          setDriverDocuments(res.filter((doc) => doc.status !== userDocumentStatus.toDelete));
          if (setDocuments) {
            setDocuments(res.filter((doc) => doc.status !== userDocumentStatus.toDelete));
          }
          setLoading(false);
        })
        .catch((e) => {
          errorHandler(e, true);
          setLoading(false);
        });
    }
  }

  useFocusEffect(
    useCallback(() => {
      console.info('Focus on userDocuments');
      getDoc(userId);
    }, [userId, currentUser]),
  );

  function toDeleteDocument(docId) {
    updateUserDocument(userId, docId, { status: userDocumentStatus.toDelete }).then(() => {
      getDoc(userId);
    });
  }

  function diplayTime(time) {
    if (!time) {
      return '';
    }
    const rTime = new Date((time?.seconds ?? time) * 1000);
    return rTime.toDateString();
  }

  function displayImage(images) {
    if (images && images?.length > 0) {
      setImageToDisplay(images.map((im) => ({ uri: im.url })));
      setIndex(0);
      setVisible(true);
    }
  }

  function getDocumentThumbnails(images) {
    if (images && images?.length > 0) {
      return images[0].url;
    } else {
      return;
    }
  }

  return (
    <VStack>
      <ImageView images={imageToDisplay} imageIndex={imageIndex} visible={isVisible} onRequestClose={() => setVisible(false)} />
      <VStack space={'sm'}>
        <Heading>{t('driverDocument.title')}</Heading>
        {info !== undefined && (
          <Text variant={'grey'} size={'secondary'}>
            {info}
          </Text>
        )}
        {loading && driverDocuments?.length === 0 && (
          <HStack space={'md'} justifyContent={'space-between'} alignItems={'center'}>
            <HStack space={'md'} w={'80%'} alignItems={'center'}>
              <Skeleton height={70} width={70} rounded={'$xl'} />
              <VStack space={'sm'}>
                <Skeleton.Text lines={1} w={50} />
                <Skeleton.Text lines={1} w={80} />
              </VStack>
              <Skeleton.Text w={70} lines={1} />
            </HStack>
            <Skeleton height={'$8'} width={'$8'} rounded={'$md'} />
          </HStack>
        )}
        {driverDocuments?.length > 0 &&
          driverDocuments.map((doc, i) => (
            <HStack space={'md'} key={doc.uid} justifyContent={'space-between'} alignItems={'center'}>
              <TouchableOpacity onPress={() => displayImage(doc?.images)}>
                <HStack space={'md'} w={'80%'} alignItems={'center'}>
                  <Image
                    rounded={'$2xl'}
                    alt={'driver licence'}
                    source={{ uri: resizedImage(getDocumentThumbnails(doc?.images)) }}
                    height={70}
                    width={70}
                  />
                  <VStack space={'xs'}>
                    <HStack space={'sm'}>
                      <Text size={'secondary'}>{`${doc?.firstName} ${doc.lastName} - ${doc.countryFlag} ${doc.countryCode}`}</Text>
                    </HStack>
                    <Text variant={'grey'} size={'secondary'}>
                      {doc?.number}
                    </Text>
                    <Text variant={'grey'} size={'secondary'}>
                      {t('driverDocument.validFrom', { validFrom: diplayTime(doc?.deliverTime) })}
                    </Text>
                  </VStack>
                </HStack>
              </TouchableOpacity>
              {isEditable && (
                <TouchableOpacity onPress={() => toDeleteDocument(doc.uid)}>
                  <Icon as={Ionicons} name="close" size={'xl'} />
                </TouchableOpacity>
              )}
            </HStack>
          ))}
        {!loading && isEditable === true ? (
          driverDocuments?.length === 0 ? (
            <Button testID="button.addDriver" variant={'outline'} onPress={() => navigation.navigate('AddUserDocument')}>
              <ButtonText>{t('driverDocument.addDocument')}</ButtonText>
            </Button>
          ) : (
            <UnderLineButton
              text={t('driverDocument.addAnotherDriver')}
              hideLeftIcon={true}
              onPress={() => navigation.navigate('AddUserDocument')}
            />
          )
        ) : !loading && driverDocuments?.length === 0 ? (
          <Text>{t('driverDocument.noDocument')}</Text>
        ) : null}
      </VStack>
    </VStack>
  );
}

export default UserDocuments;
