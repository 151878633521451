import React from 'react';

import { Box, FormControl, FormControlLabel, FormControlLabelText, ScrollView } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import LangSwitcher from '../../shared/components/web/langSwitcher';
import WebScrollView from '../../shared/components/web/webScrollView';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';

function LanguageScreen() {
  const { t } = useTranslation();
  const maxWidth = useMaxWidth();

  return (
    <WebScrollView maxWidth={maxWidth > 700 ? 700 : maxWidth} hideFooter={true}>
      <Box mx={'$screenMargin'}>
        <FormControl isRequired isReadOnly>
          <FormControlLabel>
            <FormControlLabelText>{t('language.language')}</FormControlLabelText>
          </FormControlLabel>
          <LangSwitcher />
        </FormControl>
      </Box>
    </WebScrollView>
  );
}

export default LanguageScreen;
