import { firebaseApp } from './firebaseFactory';
import { userToAccount } from '../../util/account-utils';

export function updateProfile(currentUser) {
  return firebaseApp.auth().currentUser.updateProfile(userToAccount(currentUser));
}

export function signOut() {
  return firebaseApp.auth().signOut();
}
