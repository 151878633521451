import React, { useEffect, useState } from 'react';

import { useToken } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';

import { goBackOrDefault } from '../../navigation/nav-ref';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import MediaCheckFlow from '../../shared/components/media/mediaCheckFlow';
import Steps from '../../shared/components/steps/steps';
import successToast from '../../shared/components/toast/successToast';
import { checkCarSetter } from '../../shared/reducers/checkCar.reducer';
import { addOneCheckCar } from '../../shared/services/firebase/checkCar-firebase';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { checkCarStepList } from '../../shared/util/check-car-step-list';
import { checkCarTypes, defaultCheckCarSteps } from '../../shared/util/check-car-steps';
import { waitDocumentUpdated } from '../../shared/util/firestore-utils';

function AddCheckCarScreen({ route }) {
  const { reservationId, carId, type, isDriver } = route?.params ?? {};
  const dispatch = useDispatch();
  const checkCar = useSelector((state) => state.checkCar.checkCar);
  const currentUser = useSelector((state) => state.users.currentUser);

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const colorPrimary900 = useToken('colors', 'primary900');

  useEffect(() => {
    console.debug('Effect: refresh reservation');
    console.debug('Refresh CheckCar');
    dispatch(
      checkCarSetter({
        language: currentUser?.language,
        reservationId: reservationId,
        images: defaultCheckCarSteps,
        type: type,
        carId: carId,
        fuel: 4,
        mileage: '0',
        originalContent: { noteOwner: '', noteDriver: '' },
      }),
    );
  }, [reservationId, carId, type, dispatch, currentUser]);

  function addCheckCar() {
    const toUpload = {
      ...checkCar,
      images: checkCar?.images?.filter((image) => image?.url)?.map((image) => ({ url: image?.url, part: image?.part, type: image.type })),
      createAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
    };
    setLoading(true);
    addOneCheckCar(toUpload)
      .then((res) => {
        waitDocumentUpdated(
          firebaseApp.firestore().collection('reservations').doc(reservationId),
          (resa) => resa?.checkCarNextStep !== undefined && resa?.checkCarNextStep !== checkCar?.type,
        )
          .then(() => {
            dispatch(checkCarSetter({}));
            successToast(t('checkCar.success'));
            goBackOrDefault('Profile');
            setLoading(false);
          })
          .catch((e) => {
            errorHandler(e, true);
            setLoading(false);
          });
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoading(false);
      });
  }

  if (checkCar?.type === checkCarTypes.preCheck || checkCar?.type === checkCarTypes.postCheck) {
    return (
      <MediaCheckFlow
        loading={loading}
        key={checkCar?.reservationId + checkCar?.carId + checkCar?.type}
        imageList={checkCar?.images ?? []}
        setImageList={(images) => dispatch(checkCarSetter({ ...checkCar, images: images }))}
        onFinish={addCheckCar}
        storagePath="check-car"
      />
    );
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colorPrimary900 }}>
      <Steps
        bgColor={'$blue900'}
        textColor={'$white'}
        loadBarColor={'$blue500'}
        routes={checkCarStepList?.filter((c) => {
          if (isDriver && c?.titleToTranslate !== 'checkCar.driverSummary' && c?.titleToTranslate !== 'checkCar.selfiesAndSign') {
            return false;
          }
          return true;
        })}
        loading={loading}
        onFinish={addCheckCar}
        dataToValidate={checkCar}
      />
    </SafeAreaView>
  );
}

export default AddCheckCarScreen;
