import React from 'react';

import { useTranslation } from 'react-i18next';
import StepIndicator from 'react-native-step-indicator';

import { Colors } from '../../themes';

const customStyles = {
  stepIndicatorSize: 25,
  currentStepIndicatorSize: 30,
  separatorStrokeWidth: 2,
  currentStepStrokeWidth: 3,
  stepStrokeCurrentColor: Colors.blue[400],
  stepStrokeWidth: 3,
  stepStrokeFinishedColor: Colors.blue[400],
  stepStrokeUnFinishedColor: '#aaaaaa',
  separatorFinishedColor: Colors.blue[400],
  separatorUnFinishedColor: '#aaaaaa',
  stepIndicatorFinishedColor: Colors.blue[400],
  stepIndicatorUnFinishedColor: '#ffffff',
  stepIndicatorCurrentColor: '#ffffff',
  stepIndicatorLabelFontSize: 13,
  currentStepIndicatorLabelFontSize: 13,
  stepIndicatorLabelCurrentColor: Colors.blue[400],
  stepIndicatorLabelFinishedColor: '#ffffff',
  stepIndicatorLabelUnFinishedColor: '#aaaaaa',
  labelColor: '#999999',
  labelSize: 13,
  currentStepLabelColor: Colors.blue[400],
};

export default function ProgressStep({ currentStep, maxStep }) {
  const { t } = useTranslation();

  return (
    <StepIndicator
      customStyles={customStyles}
      currentPosition={currentStep}
      stepCount={maxStep}
      labels={[t('checkCarTypes.PRE_CHECK'), t('checkCarTypes.SIGN_IN'), t('checkCarTypes.POST_CHECK'), t('checkCarTypes.SIGN_OUT')]}
    />
  );
}
