import React from 'react';

import { Divider, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { formatCurrency } from '../../util/currency';
import UnderLineButton from '../pressable/underLineButton';
import Skeleton from '../skeleton/skeleton';

export default function InvoiceDetail({ reservationInvoice, ownerInvoice, receiptUrl, isLoading, hideTotal, badge, toClose }) {
  const { t } = useTranslation();
  const navigation = useNavigation();

  if (isLoading) {
    return <Skeleton.Text lines={3} space={'md'} />;
  }

  function renderInvoiceItem(item) {
    if (item.basePrice === 0 && item.type !== 'DELIVERY') {
      return null;
    }
    return (
      <HStack key={item.type + item.basePrice} justifyContent={'space-between'} alignItems={'center'}>
        <Text w={'70%'} alignSelf={'center'}>
          {t('invoice.' + item.type, {
            ...item,
            reason: reservationInvoice?.reason,
            name: item?.extra?.name,
            basePriceDisplay:
              item.basePrice === 0
                ? t('global.free')
                : formatCurrency(Math.round(item.basePrice) / reservationInvoice?.smallerCurrencyUnit, reservationInvoice?.currency),
          })}
        </Text>
        {badge}
        <Text variant={'grey'} size={'secondary'} alignSelf={'center'}>
          {formatCurrency(Math.round(item.totalPrice) / reservationInvoice?.smallerCurrencyUnit, reservationInvoice?.currency)}
        </Text>
      </HStack>
    );
  }

  function onPress() {
    toClose && toClose();
    navigation.navigate('CustomWebView', { url: receiptUrl, title: t('payment.receipt') });
  }

  return (
    <VStack space={'md'}>
      {reservationInvoice &&
        reservationInvoice?.listProduct &&
        reservationInvoice?.listProduct.map((invoiceItem) => renderInvoiceItem(invoiceItem))}
      {!hideTotal && !ownerInvoice && (
        <HStack justifyContent={'space-between'} alignItems={'center'}>
          <Text>{t('reservation.total', { currency: reservationInvoice?.currency })}</Text>
          <Text fontWeight={'$bold'}>
            {formatCurrency(
              Math.round(reservationInvoice?.totalPrice) / reservationInvoice?.smallerCurrencyUnit,
              reservationInvoice?.currency,
            )}
          </Text>
        </HStack>
      )}
      {!hideTotal && ownerInvoice && (
        <VStack space={'sm'}>
          <Divider w={'20%'} alignSelf={'center'} />
          <HStack justifyContent={'space-between'} alignItems={'center'}>
            <Text>{t('reservation.totalEarn', { currency: ownerInvoice?.currency })}</Text>
            <Text fontWeight={'$bold'}>
              {formatCurrency(Math.round(ownerInvoice?.totalPrice) / ownerInvoice?.smallerCurrencyUnit, ownerInvoice?.currency)}
            </Text>
          </HStack>
        </VStack>
      )}
      {receiptUrl && <UnderLineButton hideLeftIcon={true} text={t('reservation.downloadReceipt')} onPress={onPress} />}
    </VStack>
  );
}
