import React from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, Heading, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { getThumbnailUrl, reservationStatusToColorSheme } from '../../../util/car-utils';
import { formatCurrency } from '../../../util/currency';
import { displayRangeDate } from '../../../util/date-transforms';
import { resizedImage } from '../../../util/image-utils';
import BadgeText from '../../badge/badgeText';
import Image from '../../image/image';
import Skeleton from '../../skeleton/skeleton';

export default function CarDriverCard(
  { car, invoice, dateRange, onPressCar, onPressDriver, driver, loadingCar, loadingDriver, reservation } = { disabled: true },
) {
  const { t } = useTranslation();

  if (loadingCar) {
    return null;
  }
  if (loadingCar || loadingDriver) {
    return (
      <VStack space="sm">
        <Skeleton.Text w={120} h={'$6'} lines={1} />
        <HStack justifyContent={'space-between'} alignItems={'center'}>
          <HStack space={'md'} alignItems={'center'} w={'60%'}>
            <Skeleton h={'$16'} w={'$16'} rounded={'$md'} />
            <VStack space="xs">
              <Skeleton.Text w={120} h={'$6'} lines={1} />
              <Skeleton.Text w={100} lines={2} />
            </VStack>
          </HStack>
          <Skeleton h={'$8'} rounded={'$full'} w={'30%'} />
        </HStack>
      </VStack>
    );
  }

  return (
    <TouchableOpacity onPress={onPressCar} disabled={!onPressCar}>
      <VStack space={'sm'}>
        <BadgeText colorScheme={reservationStatusToColorSheme(reservation?.status)}>
          {t('reservationStatus.' + reservation?.status)?.toUpperCase()}
        </BadgeText>
        <HStack justifyContent={'space-between'} alignItems={'center'}>
          <HStack space={'md'} alignItems={'center'} maxWidth={'60%'}>
            <Box>
              <Image source={{ uri: resizedImage(getThumbnailUrl(car?.images)) }} size={'md'} rounded={'$2xl'} />
              <Box variant={'shadow'} position={'absolute'} right={0} bottom={0} my={-2} rounded={'$full'}>
                <TouchableOpacity onPress={onPressDriver}>
                  <Image source={{ uri: resizedImage(driver?.photoURL) }} size={'xs'} rounded={'$full'} />
                </TouchableOpacity>
              </Box>
            </Box>
            <VStack justifyContent={'center'} space={'xs'} w={'70%'}>
              <Heading>{car?.brand + ' ' + car?.model}</Heading>

              <Text variant={'grey'} size={'secondary'} numberOfLines={1} maxw={'80%'}>
                {driver?.firstName && t('car.rentBy', { name: driver?.firstName + ' ' + driver?.lastName })}
              </Text>

              <Text variant={'grey'} size={'secondary'} maxw={'80%'}>
                {displayRangeDate(dateRange.from, dateRange.to, 'small')}
              </Text>
            </VStack>
          </HStack>
          <Text fontWeight={'$bold'} alignSelf={'center'}>
            {formatCurrency(Math.round(invoice?.totalPrice) / invoice?.smallerCurrencyUnit, invoice?.currency)}
          </Text>
        </HStack>
      </VStack>
    </TouchableOpacity>
  );
}
