import React, { useMemo, useState } from 'react';

import { Heading, HStack, Text } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import appConfig from '../../../config/app-config';
import { getPriceFromCurrency } from '../../util/currency';
import { dateRangeToDayOfYearsArray } from '../../util/date-transforms';
import Skeleton from '../skeleton/skeleton';

export default function PriceDisplay({ size, priceData, carCurrency, currency, dateRange }) {
  const { t } = useTranslation();

  function getPriceFromDayOfYear(day) {
    if (!priceData) {
      return 0;
    } else {
      const p = [
        ...priceData?.filter((r) => {
          if (r.from < r.to && day >= r.from && day <= r.to) {
            return true;
          }
          if (r.from > r.to && (day >= r.from || day <= r.to)) {
            return true;
          } else {
            return false;
          }
        }),
      ].shift() ?? { price: -1 };
      return p?.price;
    }
  }

  const avgPrice = useMemo(() => {
    if (!dateRange?.from || !dateRange?.to) {
      return priceData?.reduce(function (acc, c) {
        return Math.min(c.price, acc);
      }, Infinity);
    } else {
      const dayOyYearArray = dateRangeToDayOfYearsArray(dateRange).slice(0, -1);
      const priceArray = dayOyYearArray.map((d) => getPriceFromDayOfYear(d));
      return priceArray.reduce((a, b) => a + b) / priceArray.length;
    }
  }, [dateRange, priceData]);

  return (
    <HStack alignItems={'center'}>
      {avgPrice ? (
        <Heading alignSelf={'center'} fontSize={size ?? '$h3'}>
          {getPriceFromCurrency(avgPrice * appConfig.serviceFee, carCurrency, currency)}
        </Heading>
      ) : (
        <Skeleton h={'$8'} w={'$12'} rounded={'$full'} />
      )}
      <Text fontSize={size ?? '$h3'}>{t('car.perDay')}</Text>
    </HStack>
  );
}
