import { useMemo } from 'react';
import { TouchableOpacity, useWindowDimensions } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Button, ButtonText, Divider, HStack, Icon, VStack } from '@gluestack-ui/themed';
import { DrawerActions, Link, useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { firebaseApp } from '../../services/firebase/firebaseFactory';
import imagesUrl from '../../themes/imagesUrl';
import { screenBreakPoint } from '../../util/device-utils';
import { resizedImage } from '../../util/image-utils';
import CarSearchBar from '../carSearchBar/carSearchBar';
import Image from '../image/image';

function HeaderWeb() {
  const { width } = useWindowDimensions();
  const navigation = useNavigation();
  const { t } = useTranslation();

  const display = useMemo(
    () =>
      screenBreakPoint(width, [
        { brand: false, logo: false, search: true, profile: false },
        { brand: false, logo: false, search: true, profile: false },
        { brand: false, logo: false, search: true, profile: false },
        { brand: true, logo: false, search: true, profile: true },
        { brand: true, logo: false, search: true, profile: true },
      ]),
    [width],
  );

  const barWidth = useMemo(() => screenBreakPoint(width, ['100%', '100%', '100%', 400, 500]), [width]);

  return (
    <VStack w={'$full'}>
      <VStack mx={'$screenMargin'} w={'$full'} alignSelf={'center'}>
        <HStack py={'$2'} alignItems={'center'} justifyContent={'space-between'} mx={'$4'} space={'md'}>
          {display?.brand && (
            <Link to={{ screen: 'Search' }}>
              <Image source={{ uri: imagesUrl.brandNameDark }} h={51} w={119} resizeMode={'contain'} />
            </Link>
          )}
          {display?.logo && (
            <Link to={{ screen: 'Search' }}>
              <Image source={{ uri: imagesUrl.logo }} h={51} w={51} resizeMode={'contain'} />
            </Link>
          )}
          <Box w={barWidth}>
            <CarSearchBar />
          </Box>
          {display?.profile && (
            <HStack alignItems="center" space="md">
              <Button variant="outline" borderColor="$primary400" size={'sm'} onPress={() => navigation.navigate('LandingPagePro')}>
                <ButtonText color={'$primary400'}>{t('landingPro.becomeHost')}</ButtonText>
              </Button>
              <Box
                variant={'cardContainer'}
                rounded={'$full'}
                px={'$4'}
                py={'$2'}
                alignItems={'center'}
                justifyContent={'center'}
                alignSelf={'center'}>
                <TouchableOpacity onPress={() => navigation.dispatch(DrawerActions.openDrawer())}>
                  <HStack alignItems={'center'} space={'sm'}>
                    <Icon as={Ionicons} name={'menu-outline'} color={'$black'} size={'xl'} />
                    <Image
                      source={{
                        uri: resizedImage(
                          firebaseApp.auth()?.currentUser?.photoURL ?? 'https://assets.flyandcar.com/assets/default-pp.png',
                        ),
                      }}
                      h={35}
                      w={35}
                      rounded={'$full'}
                    />
                  </HStack>
                </TouchableOpacity>
              </Box>
            </HStack>
          )}
        </HStack>
      </VStack>
      <Divider bg={'$light100'} />
    </VStack>
  );
}

export default HeaderWeb;
