import React from 'react';

import { Box } from '@gluestack-ui/themed';

import { useIsDrawer } from '../../provider/isDrawerProvider';

export default function CardContainer({ children }) {
  const isDrawer = useIsDrawer();
  return (
    <Box variant={'cardContainer'} {...(isDrawer ? { maxW: 400, minWidth: '50%' } : { w: '90%' })} alignSelf={'center'} m={'$4'}>
      {children}
    </Box>
  );
}
