import { useEffect, useMemo, useRef, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { useToken } from '@gluestack-ui/themed';
import { AdvancedMarker, APIProvider, Map, Pin } from '@vis.gl/react-google-maps';

import { Circle } from './circle';
import { Images } from '../../themes';
import { screenBreakPoint } from '../../util/device-utils';
import { getMapRegion } from '../../util/place-utils';
import Image from '../image/image';
import Skeleton from '../skeleton/skeleton';

function latRad(lat) {
  var sin = Math.sin((lat * Math.PI) / 180);
  var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
}

export default function RadiusMap({ radius, coord, locationCoord, locationCoord1, toMap, height }) {
  const mapViewRef = useRef(null);
  const [lastAnimatedRegion, setLastAnimatedRegion] = useState(coordToTypedCoord(coord));
  const [zoom, setZoom] = useState(0);
  const colorPrimary400 = useToken('colors', 'primary400');
  const colorPrimary900 = useToken('colors', 'primary900');
  const colorPrimary700 = useToken('colors', 'primary700');
  const colorWhite = useToken('colors', 'white');
  const radiiXl = useToken('radii', 'xl');
  const imageSize = parseInt(useToken('space', '16'));
  const { width } = useWindowDimensions();

  const mapHight = useMemo(() => screenBreakPoint(width, [200, 200, 200, 300, 400]), [width]);

  useEffect(() => {
    if (coord?.lat && coord?.lng) {
      const { lat, lng, deltaLat, deltaLng } = getMapRegion([coord, locationCoord, locationCoord1]?.filter((co) => co) || [], radius);

      if (
        mapViewRef &&
        lat &&
        lng &&
        deltaLat &&
        deltaLng &&
        (lastAnimatedRegion?.lat !== lat ||
          lastAnimatedRegion?.lng !== lng ||
          lastAnimatedRegion?.deltaLat !== deltaLat ||
          lastAnimatedRegion?.deltaLng !== deltaLng)
      ) {
        console.debug('animate Region Web');
        setLastAnimatedRegion({
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        });
        let latFrac = latRad(deltaLat) / Math.PI;
        let lngFrac = latRad(deltaLng) / 360;

        let lngZoom = Math.log(1 / lngFrac) / Math.log(2);
        let latZoom = Math.log(1 / latFrac) / Math.log(2);

        setZoom(Math.min(lngZoom, latZoom) - 1);
      }
    }
  }, [
    radius,
    coord,
    locationCoord,
    lastAnimatedRegion?.lat,
    lastAnimatedRegion?.lng,
    lastAnimatedRegion?.deltaLat,
    lastAnimatedRegion?.deltaLng,
    locationCoord1,
  ]);

  function coordToTypedCoord(c) {
    return { lat: parseFloat(c?.lat ?? '0'), lng: parseFloat(c?.lng ?? '0') };
  }

  if (!lastAnimatedRegion?.lat || !lastAnimatedRegion?.lng) {
    return <Skeleton width={'100%'} height={height ?? mapHight} rounded={'$xl'} />;
  }

  return (
    <APIProvider apiKey={'AIzaSyBBCBuEAyEfClAmTeLDAJwrRDIgqLGFPDw'}>
      <Map
        style={{ width: '100%', borderRadius: radiiXl, height: height ?? mapHight }}
        zoom={zoom}
        center={lastAnimatedRegion}
        gestureHandling={'greedy'}
        disableDefaultUI={true}
        mapId={'8da43b57db6db442'}>
        {coord?.lat && coord?.lng && (
          <AdvancedMarker position={coordToTypedCoord(coord)} anchor={{ x: 0.5, y: 0.5 }}>
            <Image source={Images.carAera} size={'md'} mb={-(imageSize / 2)} />
          </AdvancedMarker>
        )}
        {locationCoord?.lat && locationCoord?.lng && (
          <AdvancedMarker position={locationCoord}>
            <Pin background={colorPrimary900} glyphColor={colorWhite} borderColor={colorWhite} />
          </AdvancedMarker>
        )}
        {locationCoord1?.lat && locationCoord1?.lng && (
          <AdvancedMarker position={locationCoord1}>
            <Pin background={colorPrimary700} glyphColor={colorWhite} borderColor={colorWhite} />
          </AdvancedMarker>
        )}
        {coord?.lat !== undefined && coord?.lng !== undefined && radius !== undefined && (
          <Circle
            center={coord}
            radius={radius * 1000}
            strokeColor={colorPrimary900}
            fillColor={colorPrimary400}
            fillOpacity={0.2}
            strokeWeight={1}
            strokeOpacity={0.5}
          />
        )}
      </Map>
    </APIProvider>
  );
}
