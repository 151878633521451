import React, { useMemo, useState } from 'react';

import { HStack, Text } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import appConfig from '../../../config/app-config';
import { getPriceFromCurrency } from '../../util/currency';
import { dateRangeToDayOfYearsArray } from '../../util/date-transforms';
import Skeleton from '../skeleton/skeleton';

export default function PriceDisplayTotal({ priceData, carCurrency, currency, dateRange, selectedExtraOption, location }) {
  const { t } = useTranslation();

  function getPriceFromDayOfYear(day) {
    if (!priceData) {
      return 0;
    } else {
      const p = [
        ...(priceData?.filter((r) => {
          if (r.from < r.to && day >= r.from && day <= r.to) {
            return true;
          }
          if (r.from > r.to && (day >= r.from || day <= r.to)) {
            return true;
          } else {
            return false;
          }
        }) || {}),
      ].shift() ?? { price: -1 };
      return p?.price;
    }
  }

  const totalPrice = useMemo(() => {
    if (dateRange?.from && dateRange?.to && priceData) {
      const dayOyYearArray = dateRangeToDayOfYearsArray(dateRange).slice(0, -1);
      const priceArray = dayOyYearArray.map((d) => getPriceFromDayOfYear(d));
      const nbDay = priceArray?.length;
      // TODO: add extra options
      const priceExtraOptions = selectedExtraOption.reduce((a, b) => a + (b.perTime === 'day' ? b.price * nbDay : b.price), 0);
      const priceRental = priceArray.reduce((a, b) => a + b) + priceExtraOptions;

      return priceRental + (location?.pickUp?.price ?? 0) + (location?.return?.price ?? 0);
    } else {
      return 0;
    }
  }, [dateRange, priceData, location, selectedExtraOption]);

  return (
    <HStack>
      <Text size={'h3'} fontWeight={'$bold'}>
        {totalPrice ? (
          getPriceFromCurrency(totalPrice * appConfig.serviceFee, carCurrency, currency)
        ) : (
          <Skeleton h={'$8'} w={'$12'} rounded={'$full'} />
        )}
      </Text>
    </HStack>
  );
}
