import React from 'react';

import { Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Image from '../../../shared/components/image/image';
import { checkCarSetter } from '../../../shared/reducers/checkCar.reducer';
import { Images, Metrics } from '../../../shared/themes';

function GivePhoneToDriver() {
  const { t } = useTranslation();

  return (
    <VStack space={'md'} mt={'$16'}>
      <Image
        source={{ uri: 'https://assets.flyandcar.com/assets/check_car/verify.webp' }}
        alt={'Step1'}
        height={Metrics.images.xxlarge}
        w={'100%'}
        resizeMode="contain"
      />
      <Text color={'$white'}>{t('addCar.givePhoneToDriverDescription')}</Text>
    </VStack>
  );
}

export default GivePhoneToDriver;
