import React, { useEffect, useState } from 'react';

import { Box, Spinner, Text, VStack } from '@gluestack-ui/themed';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Booking from './booking';
import appConfig from '../../config/app-config';
import LoginPlaceHolder from '../../shared/components/entities/user/loginPlaceHolder';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Image from '../../shared/components/image/image';
import Layout from '../../shared/components/layout/layout';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { getOneCar } from '../../shared/services/firebase/car-firebase';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { setupBooking } from '../../shared/services/firebase/function-firebase';
import { Images, Metrics } from '../../shared/themes';
import imagesUrl from '../../shared/themes/imagesUrl';
import { analyticsReservationCreated } from '../../shared/util/analytics';
import { rangeToDateRange } from '../../shared/util/date-transforms';
import { getDeliveryLocation } from '../../shared/util/place-utils';

const stripePromise = loadStripe(appConfig.stripeKey);

function ConfirmAndPay({ route }) {
  const currentUser = useSelector((state) => state.users.currentUser);

  const { carId, from, to, checkInHour, checkInMinute, checkOutHour, checkOutMinute, extraOptions, pickUpPlaceId, returnPlaceId, quoteId } =
    route?.params ?? {};

  const [dateRange, setDateRange] = useState(rangeToDateRange(from, to, checkInHour, checkInMinute, checkOutHour, checkOutMinute));
  const [paymentInfo, setPaymentInfo] = useState({});
  const [isInvoiceLoading, setInvoiceLoading] = useState(true);
  const [car, setCar] = useState({});
  const [selectedExtraOption, setSelectedExtraOptions] = useState(extraOptions ? [...extraOptions] : []);
  const [location, setLocation] = useState({ pickUp: { placeId: pickUpPlaceId }, return: { placeId: returnPlaceId ?? pickUpPlaceId } });

  const { t } = useTranslation();
  const maxWidth = useMaxWidth();

  useEffect(() => {
    if (
      location?.pickUp?.placeId &&
      car?.uid &&
      location?.return?.location &&
      (!location?.pickUp?.location || !location?.return?.location)
    ) {
      console.debug('refresh Location, car');
      getDeliveryLocation(car?.deliveryLocation, location?.pickUp?.placeId, location?.return?.placeId).then((res) => setLocation(res));
    }
  }, [location?.pickUp?.placeId, car, location?.return?.placeId, location?.pickUp?.location, location?.return?.location]);

  useEffect(() => {
    if (
      from != dateRange.from || // params is string
      to != dateRange.to ||
      checkInHour != dateRange.checkInHour ||
      checkInMinute != dateRange.checkInMinute ||
      checkOutHour != dateRange.checkOutHour ||
      checkOutMinute != dateRange.checkOutMinute
    ) {
      console.debug(
        'Effect: refresh from, to',
        dateRange,
        rangeToDateRange(from, to, checkInHour, checkInMinute, checkOutHour, checkOutMinute),
      );
      setDateRange(rangeToDateRange(from, to, checkInHour, checkInMinute, checkOutHour, checkOutMinute));
    }
  }, [from, to, checkInHour, checkInMinute, checkOutHour, checkOutMinute, dateRange]);

  useEffect(() => {
    if (carId) {
      console.debug('Effect: refresh carId');
      getOneCar(carId)
        .then((res) => setCar(res))
        .catch((e) => errorHandler(e, true));
    }
  }, [carId]);

  useEffect(() => {
    if (
      currentUser?.uid &&
      carId &&
      dateRange?.from &&
      dateRange?.to &&
      location?.pickUp?.placeId &&
      dateRange?.from < dateRange?.to &&
      firebaseApp.auth().currentUser?.uid
    ) {
      console.debug('Effect: refresh car, currentUser, daterange');
      setInvoiceLoading(true);
      setupBooking(
        carId,
        dateRange.from,
        dateRange.to,
        dateRange.checkInHour,
        dateRange.checkInMinute,
        dateRange.checkOutHour,
        dateRange.checkOutMinute,
        selectedExtraOption,
        location?.pickUp?.placeId,
        location?.return?.placeId ?? location?.pickUp?.placeId,
        quoteId,
      )
        .then((res) => {
          setPaymentInfo(res);
          setInvoiceLoading(false);
          analyticsReservationCreated(res?.reservation);
        })
        .catch((e) => {
          errorHandler(e, true);
          setInvoiceLoading(false);
        });
    }
  }, [carId, currentUser, dateRange, selectedExtraOption, location?.pickUp?.placeId, location?.return?.placeId, quoteId]);

  if (!currentUser?.uid) {
    return (
      <Layout>
        <Box py={'$4'} px={'$screenMargin'} w={maxWidth} alignSelf="center">
          <LoginPlaceHolder />
        </Box>
      </Layout>
    );
  }

  if (!paymentInfo?.paymentIntent) {
    return (
      <VStack space={'md'} justifyContent={'center'} alignItems={'center'} backgroundColor={'$blue900'} flex={1}>
        <Image source={{ uri: imagesUrl.brandName }} height={100} w={300} alt={'logo'} removeTransition={true} />
        <Text alignSelf={'center'} color={'$white'}>
          {t('payment.paymentPageWaiting')}
        </Text>
        <Spinner color={'$white'} size={100} />
      </VStack>
    );
  }

  const options = {
    clientSecret: paymentInfo?.paymentIntent?.paymentIntentClientSecret,
    appearance: { theme: 'flat', labels: 'floating' },
  };

  return (
    <Layout>
      <Elements stripe={stripePromise} options={options}>
        <Booking
          paymentInfo={paymentInfo}
          car={car}
          dateRange={dateRange}
          setDateRange={setDateRange}
          isInvoiceLoading={isInvoiceLoading}
          selectedExtraOption={selectedExtraOption}
          setSelectedExtraOptions={setSelectedExtraOptions}
          location={location}
          setLocation={setLocation}
          quoteId={quoteId}
        />
      </Elements>
    </Layout>
  );
}

export default ConfirmAndPay;
