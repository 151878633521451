import React from 'react';

import { Box, Heading, HStack, VStack } from '@gluestack-ui/themed';
import { Text } from '@gluestack-ui/themed';

import PointIcon from './pointIcon';
import Image from '../../../shared/components/image/image';

const RenderbulletPoint = ({ texts }) =>
  texts.map((text) => (
    <HStack key={text} space="sm">
      <Text size="secondary" ml={'$4'}>
        •
      </Text>
      <Text size="secondary">{text}</Text>
    </HStack>
  ));

export default function SectionImagePoint({ title, imageUrl, points }) {
  return (
    <VStack w={'$full'} space="4xl">
      <Heading $base-textAlign="center" $md-textAlign="left" fontSize={32} lineHeight={40} mb={'$4'}>
        {title}
      </Heading>
      <HStack justifyContent="space-between" flexWrap="wrap" w={'$full'} space="md">
        <Image
          source={{ uri: imageUrl }}
          $base-h={200}
          $md-h={'$full'}
          $base-w={'$full'}
          $md-w={'50%'}
          resizeMode={'contain'}
          $md-minWidth={300}
        />
        <VStack space="xl" $base-w={'$full'} $md-w={'40%'} alignSelf="center" $md-minWidth={300}>
          {points.map((point) => (
            <HStack
              key={point?.title}
              space="md"
              my={'$2'}
              $base-flexDirection="column"
              $md-flexDirection="row"
              $base-alignItems="center"
              $md-alignItems="flex-start">
              <PointIcon url={point?.imageUrl} />
              <VStack $base-w={'$full'} $md-w={'80%'}>
                <Heading
                  $base-textAlign="center"
                  $md-textAlign="left"
                  size="primary"
                  mb={'$2'}
                  $base-alignSelf="center"
                  $md-alignSelf="flex-start">
                  {point?.title}
                </Heading>
                <RenderbulletPoint texts={point?.texts} />
              </VStack>
            </HStack>
          ))}
        </VStack>
      </HStack>
    </VStack>
  );
}
