export function cleanFirestoreResult(response) {
  if (!response) {
    return null;
  }
  if (response?.data) {
    return { uid: response.id, ...response.data() };
  }
  return { uid: response.id };
}

export function cleanFirestoreListResult(querySnapshot) {
  if (!querySnapshot) {
    return null;
  }
  const cs = [];
  querySnapshot.forEach((c) => cs.push({ ...c.data(), uid: c.id }));
  return cs;
}

export function waitDocumentUpdated(query, condition) {
  return new Promise((resolve, reject) => {
    query.onSnapshot(
      (res) => {
        const data = cleanFirestoreResult(res);
        if (condition(data)) {
          resolve(true);
        }
      },
      (err) => reject(err),
    );
  });
}
