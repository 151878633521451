import React from 'react';

import { useTranslation } from 'react-i18next';

import BadgeText from './badgeText';
import { reservationStatusToColorSheme } from '../../util/car-utils';

export default function AdditionalPaymentBadge({ additionalPayment, w }) {
  const { t } = useTranslation();

  return (
    <BadgeText w={w} colorScheme={reservationStatusToColorSheme(additionalPayment?.status)}>
      {t('reservationStatus.' + additionalPayment?.status)}
    </BadgeText>
  );
}
