export const carData = [
  {
    brand: 'Acura',
    model: 'CDX',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Acura',
    model: 'CL',
    yearStart: 1998,
    yearEnd: 2003,
  },
  {
    brand: 'Acura',
    model: 'EL',
    yearStart: 1997,
    yearEnd: 2005,
  },
  {
    brand: 'Acura',
    model: 'ILX',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Acura',
    model: 'Integra',
    yearStart: 1995,
    yearEnd: 2006,
  },
  {
    brand: 'Acura',
    model: 'MDX',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Acura',
    model: 'NSX',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Acura',
    model: 'RDX',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Acura',
    model: 'RL',
    yearStart: 1995,
    yearEnd: 2012,
  },
  {
    brand: 'Acura',
    model: 'RLX',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Acura',
    model: 'RSX',
    yearStart: 2002,
    yearEnd: 2006,
  },
  {
    brand: 'Acura',
    model: 'TL',
    yearStart: 1999,
    yearEnd: 2014,
  },
  {
    brand: 'Acura',
    model: 'TLX',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Acura',
    model: 'TLX-L',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Acura',
    model: 'TSX',
    yearStart: 2003,
    yearEnd: 2014,
  },
  {
    brand: 'Acura',
    model: 'ZDX',
    yearStart: 2010,
    yearEnd: 2013,
  },
  {
    brand: 'Alfa Romeo',
    model: 146,
    yearStart: 1994,
    yearEnd: 2001,
  },
  {
    brand: 'Alfa Romeo',
    model: 147,
    yearStart: 1998,
    yearEnd: 2010,
  },
  {
    brand: 'Alfa Romeo',
    model: '147 GTA',
    yearStart: 2002,
    yearEnd: 2010,
  },
  {
    brand: 'Alfa Romeo',
    model: 156,
    yearStart: 1997,
    yearEnd: 2007,
  },
  {
    brand: 'Alfa Romeo',
    model: '156 GTA',
    yearStart: 2002,
    yearEnd: 2007,
  },
  {
    brand: 'Alfa Romeo',
    model: 159,
    yearStart: 2005,
    yearEnd: 2011,
  },
  {
    brand: 'Alfa Romeo',
    model: 166,
    yearStart: 1998,
    yearEnd: 2007,
  },
  {
    brand: 'Alfa Romeo',
    model: '4C',
    yearStart: 2013,
    yearEnd: 2020,
  },
  {
    brand: 'Alfa Romeo',
    model: '8C Competizione',
    yearStart: 2008,
    yearEnd: 2010,
  },
  {
    brand: 'Alfa Romeo',
    model: 'Brera',
    yearStart: 2005,
    yearEnd: 2010,
  },
  {
    brand: 'Alfa Romeo',
    model: 'Giulia',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Alfa Romeo',
    model: 'Giulietta',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'Alfa Romeo',
    model: 'GT',
    yearStart: 2003,
    yearEnd: 2022,
  },
  {
    brand: 'Alfa Romeo',
    model: 'GTV',
    yearStart: 1995,
    yearEnd: 2006,
  },
  {
    brand: 'Alfa Romeo',
    model: 'MiTo',
    yearStart: 2008,
    yearEnd: 2018,
  },
  {
    brand: 'Alfa Romeo',
    model: 'Spider',
    yearStart: 1998,
    yearEnd: 2010,
  },
  {
    brand: 'Alfa Romeo',
    model: 'Stelvio',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Alfa Romeo',
    model: 'Tonale',
    yearStart: 2022,
    yearEnd: '-',
  },
  {
    brand: 'Aston Martin',
    model: 'Cygnet',
    yearStart: 2011,
    yearEnd: 2013,
  },
  {
    brand: 'Aston Martin',
    model: 'DB11',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Aston Martin',
    model: 'DB9',
    yearStart: 2004,
    yearEnd: 2016,
  },
  {
    brand: 'Aston Martin',
    model: 'DBS',
    yearStart: 2003,
    yearEnd: 2012,
  },
  {
    brand: 'Aston Martin',
    model: 'DBS Superleggera',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Aston Martin',
    model: 'DBS Violante',
    yearStart: 2007,
    yearEnd: 2012,
  },
  {
    brand: 'Aston Martin',
    model: 'DBX',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Aston Martin',
    model: 'Rapide',
    yearStart: 2010,
    yearEnd: 2019,
  },
  {
    brand: 'Aston Martin',
    model: 'V12 Vanquish',
    yearStart: 2001,
    yearEnd: 2008,
  },
  {
    brand: 'Aston Martin',
    model: 'V12 Vantage',
    yearStart: 2010,
    yearEnd: 2018,
  },
  {
    brand: 'Aston Martin',
    model: 'V8 Vantage',
    yearStart: 2005,
    yearEnd: 2007,
  },
  {
    brand: 'Aston Martin',
    model: 'Valkyrie',
    yearStart: 2019,
    yearEnd: 2020,
  },
  {
    brand: 'Aston Martin',
    model: 'Vanquish',
    yearStart: 2011,
    yearEnd: 2018,
  },
  {
    brand: 'Aston Martin',
    model: 'Vantage',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Aston Martin',
    model: 'Virage',
    yearStart: 2011,
    yearEnd: 2012,
  },
  {
    brand: 'Aston Martin',
    model: 'Zagato Coupe',
    yearStart: 2016,
    yearEnd: 2016,
  },
  {
    brand: 'Audi',
    model: 'A1',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'A2',
    yearStart: 2000,
    yearEnd: 2006,
  },
  {
    brand: 'Audi',
    model: 'A3',
    yearStart: 1996,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'A4',
    yearStart: 1995,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'A4 Allroad Quattro',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'A5',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'A6',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'A7',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'A8',
    yearStart: 1994,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'Allroad',
    yearStart: 2000,
    yearEnd: 2011,
  },
  {
    brand: 'Audi',
    model: 'e-tron',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'e-tron GT',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'e-tron Sportback',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'Q2',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'Q3',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'Q3 Sportback',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'Q4',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'Q4 Sportback',
    yearStart: 2022,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'Q5',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'Q5 Sportback',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'Q7',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'Q8',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'R8',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'RS e-tron GT',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'RS Q3',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'RS Q3 Sportback',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'RS Q7',
    yearStart: 0,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'RS Q8',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'RS3',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'RS4',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'RS5',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'RS6',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'RS7',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'S1',
    yearStart: 2014,
    yearEnd: 2018,
  },
  {
    brand: 'Audi',
    model: 'S3',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'S4',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'S5',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'S6',
    yearStart: 1999,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'S7',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'S8',
    yearStart: 1996,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'SQ2',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'SQ5',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'SQ5 Sportback',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'SQ7',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'SQ8',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'TT',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'TT RS',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Audi',
    model: 'TTS',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Bentley',
    model: 'Arnage',
    yearStart: 1999,
    yearEnd: 2007,
  },
  {
    brand: 'Bentley',
    model: 'Azure',
    yearStart: 1999,
    yearEnd: 2009,
  },
  {
    brand: 'Bentley',
    model: 'Bentayga',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Bentley',
    model: 'Brooklands',
    yearStart: 2008,
    yearEnd: 2011,
  },
  {
    brand: 'Bentley',
    model: 'Continental',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Bentley',
    model: 'Continental Flying Spur',
    yearStart: 2005,
    yearEnd: 2013,
  },
  {
    brand: 'Bentley',
    model: 'Continental GT',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Bentley',
    model: 'Flying Spur',
    yearStart: 2013,
    yearEnd: 2019,
  },
  {
    brand: 'Bentley',
    model: 'Mulsanne',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: '1 series',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: '2 series',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: '3 series',
    yearStart: 1982,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: '4 series',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: '5 series',
    yearStart: 1988,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: '6 series',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: '7 series',
    yearStart: 1995,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: '8 series',
    yearStart: 1990,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'i3',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'i4',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'i8',
    yearStart: 2014,
    yearEnd: 2020,
  },
  {
    brand: 'BMW',
    model: 'iX',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'iX3',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'M2',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'M3',
    yearStart: 1992,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'M4',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'M5',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'M6',
    yearStart: 2005,
    yearEnd: 2018,
  },
  {
    brand: 'BMW',
    model: 'M8',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'X1',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'X2',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'X3',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'X3 M',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'X4',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'X4 M',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'X5',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'X5 M',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'X6',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'X6 M',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'X7',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'Z3',
    yearStart: 1996,
    yearEnd: 2003,
  },
  {
    brand: 'BMW',
    model: 'Z4',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'BMW',
    model: 'Z8',
    yearStart: 2000,
    yearEnd: 2006,
  },
  {
    brand: 'Brilliance',
    model: 'H230',
    yearStart: 2012,
    yearEnd: 2019,
  },
  {
    brand: 'Brilliance',
    model: 'V3',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Brilliance',
    model: 'V5',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Bugatti',
    model: 'Chiron',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Bugatti',
    model: 'Divo',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Bugatti',
    model: 'Veyron',
    yearStart: 2005,
    yearEnd: 2015,
  },
  {
    brand: 'Buick',
    model: 'Century',
    yearStart: 1997,
    yearEnd: 2005,
  },
  {
    brand: 'Buick',
    model: 'Enclave',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Buick',
    model: 'Encore',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Buick',
    model: 'Envision',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Buick',
    model: 'GL8 ES',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Buick',
    model: 'La Crosse',
    yearStart: 2004,
    yearEnd: 2016,
  },
  {
    brand: 'Buick',
    model: 'LaCrosse',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Buick',
    model: 'Le Sabre',
    yearStart: 1999,
    yearEnd: 2005,
  },
  {
    brand: 'Buick',
    model: 'Lucerne',
    yearStart: 2005,
    yearEnd: 2011,
  },
  {
    brand: 'Buick',
    model: 'Park Avenue',
    yearStart: 1996,
    yearEnd: 2012,
  },
  {
    brand: 'Buick',
    model: 'Rainier',
    yearStart: 2003,
    yearEnd: 2007,
  },
  {
    brand: 'Buick',
    model: 'Regal',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'Buick',
    model: 'Rendezvouz',
    yearStart: 2001,
    yearEnd: 2007,
  },
  {
    brand: 'Buick',
    model: 'Terraza',
    yearStart: 2005,
    yearEnd: 2007,
  },
  {
    brand: 'Buick',
    model: 'Verano',
    yearStart: 2012,
    yearEnd: 2016,
  },
  {
    brand: 'BYD',
    model: 'Qin',
    yearStart: 2014,
    yearEnd: 2020,
  },
  {
    brand: 'Cadillac',
    model: 'ATS',
    yearStart: 2012,
    yearEnd: 2019,
  },
  {
    brand: 'Cadillac',
    model: 'ATS-V',
    yearStart: 2016,
    yearEnd: 2019,
  },
  {
    brand: 'Cadillac',
    model: 'BLS',
    yearStart: 2006,
    yearEnd: 2010,
  },
  {
    brand: 'Cadillac',
    model: 'CT4',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Cadillac',
    model: 'CT4-V',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Cadillac',
    model: 'CT5',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Cadillac',
    model: 'CT5-V',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Cadillac',
    model: 'CT6',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Cadillac',
    model: 'CTS',
    yearStart: 2002,
    yearEnd: 2019,
  },
  {
    brand: 'Cadillac',
    model: 'De Ville',
    yearStart: 2000,
    yearEnd: 2005,
  },
  {
    brand: 'Cadillac',
    model: 'DTS',
    yearStart: 2006,
    yearEnd: 2011,
  },
  {
    brand: 'Cadillac',
    model: 'Eldorado',
    yearStart: 1992,
    yearEnd: 2002,
  },
  {
    brand: 'Cadillac',
    model: 'ELR',
    yearStart: 2013,
    yearEnd: 2016,
  },
  {
    brand: 'Cadillac',
    model: 'Escalade',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Cadillac',
    model: 'Seville',
    yearStart: 1998,
    yearEnd: 2004,
  },
  {
    brand: 'Cadillac',
    model: 'SRX',
    yearStart: 2004,
    yearEnd: 2016,
  },
  {
    brand: 'Cadillac',
    model: 'STS',
    yearStart: 2004,
    yearEnd: 2012,
  },
  {
    brand: 'Cadillac',
    model: 'XLR',
    yearStart: 2003,
    yearEnd: 2009,
  },
  {
    brand: 'Cadillac',
    model: 'XT4',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Cadillac',
    model: 'XT5',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Cadillac',
    model: 'XT6',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Cadillac',
    model: 'XTS',
    yearStart: 2012,
    yearEnd: 2019,
  },
  {
    brand: 'Changan',
    model: 'CS35',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Changan',
    model: 'CS35 Plus',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Changan',
    model: 'CS55',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Changan',
    model: 'CS55 Plus',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Changan',
    model: 'CS75',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Changan',
    model: 'CS75 Plus',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Changan',
    model: 'CS95',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Changan',
    model: 'Eado',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Changan',
    model: 'Raeton',
    yearStart: 2013,
    yearEnd: 2017,
  },
  {
    brand: 'Changan',
    model: 'Raeton CC',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Changan',
    model: 'Uni-K',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Changan',
    model: 'Uni-T',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Changan',
    model: 'Uni-V',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Amulet',
    yearStart: 2003,
    yearEnd: 2010,
  },
  {
    brand: 'Chery',
    model: 'Arrizo 5 Plus',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Arrizo 6',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Arrizo 6 Pro',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Arrizo 7',
    yearStart: 2013,
    yearEnd: 2020,
  },
  {
    brand: 'Chery',
    model: 'Arrizo 8',
    yearStart: 2022,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Bonus',
    yearStart: 2011,
    yearEnd: 2016,
  },
  {
    brand: 'Chery',
    model: 'Bonus 3',
    yearStart: 2013,
    yearEnd: 2016,
  },
  {
    brand: 'Chery',
    model: 'CrossEastar',
    yearStart: 2006,
    yearEnd: 2015,
  },
  {
    brand: 'Chery',
    model: 'Eastar',
    yearStart: 2003,
    yearEnd: 2015,
  },
  {
    brand: 'Chery',
    model: 'eQ',
    yearStart: 2015,
    yearEnd: 2020,
  },
  {
    brand: 'Chery',
    model: 'eQ1',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'eQ5',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Fora',
    yearStart: 2006,
    yearEnd: 2010,
  },
  {
    brand: 'Chery',
    model: 'IndiS',
    yearStart: 2011,
    yearEnd: 2016,
  },
  {
    brand: 'Chery',
    model: 'Kimo',
    yearStart: 2008,
    yearEnd: 2014,
  },
  {
    brand: 'Chery',
    model: 'M11',
    yearStart: 2008,
    yearEnd: 2017,
  },
  {
    brand: 'Chery',
    model: 'Omoda 5',
    yearStart: 0,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'QQ',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'QQ3',
    yearStart: 2003,
    yearEnd: 2018,
  },
  {
    brand: 'Chery',
    model: 'QQ6',
    yearStart: 2006,
    yearEnd: 2010,
  },
  {
    brand: 'Chery',
    model: 'Tiggo',
    yearStart: 2006,
    yearEnd: 2016,
  },
  {
    brand: 'Chery',
    model: 'Tiggo 2 Pro',
    yearStart: 2022,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Tiggo 3',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Tiggo 4',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Tiggo 4 Pro',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Tiggo 5',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Tiggo 7',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Tiggo 7 Pro',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Tiggo 8',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Tiggo 8 Plus',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Tiggo 8 Pro',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Tiggo 8 Pro Max',
    yearStart: 2022,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Tiggo e',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Chery',
    model: 'Very',
    yearStart: 2011,
    yearEnd: 2016,
  },
  {
    brand: 'Chevrolet',
    model: 'Astro',
    yearStart: 1995,
    yearEnd: 2005,
  },
  {
    brand: 'Chevrolet',
    model: 'Avalanche',
    yearStart: 2002,
    yearEnd: 2013,
  },
  {
    brand: 'Chevrolet',
    model: 'Aveo',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Beat',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Blazer',
    yearStart: 1994,
    yearEnd: 2005,
  },
  {
    brand: 'Chevrolet',
    model: 'Bolt',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Bolt EUV',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Camaro',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Captiva',
    yearStart: 2006,
    yearEnd: 2018,
  },
  {
    brand: 'Chevrolet',
    model: 'Cavalier',
    yearStart: 1995,
    yearEnd: 2005,
  },
  {
    brand: 'Chevrolet',
    model: 'Cobalt',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Colorado',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Corvette',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Cruze',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Epica',
    yearStart: 2006,
    yearEnd: 2012,
  },
  {
    brand: 'Chevrolet',
    model: 'Equinox',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Express',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'HHR',
    yearStart: 2006,
    yearEnd: 2011,
  },
  {
    brand: 'Chevrolet',
    model: 'Impala',
    yearStart: 2006,
    yearEnd: 2020,
  },
  {
    brand: 'Chevrolet',
    model: 'Lacetti',
    yearStart: 2002,
    yearEnd: 2012,
  },
  {
    brand: 'Chevrolet',
    model: 'Lanos',
    yearStart: 1997,
    yearEnd: 2012,
  },
  {
    brand: 'Chevrolet',
    model: 'Malibu',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Monte Carlo',
    yearStart: 2005,
    yearEnd: 2007,
  },
  {
    brand: 'Chevrolet',
    model: 'Niva',
    yearStart: 2002,
    yearEnd: 2020,
  },
  {
    brand: 'Chevrolet',
    model: 'Orlando',
    yearStart: 2011,
    yearEnd: 2018,
  },
  {
    brand: 'Chevrolet',
    model: 'Rezzo',
    yearStart: 2000,
    yearEnd: 2012,
  },
  {
    brand: 'Chevrolet',
    model: 'Silverado',
    yearStart: 1999,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Silverado 1500',
    yearStart: 1999,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Silverado 2500 HD',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Spark',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'SSR',
    yearStart: 2003,
    yearEnd: 2006,
  },
  {
    brand: 'Chevrolet',
    model: 'Suburban',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Tahoe',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Tracker',
    yearStart: 1988,
    yearEnd: 2016,
  },
  {
    brand: 'Chevrolet',
    model: 'TrailBlazer',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Traverse',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Trax',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Chevrolet',
    model: 'Uplander',
    yearStart: 2005,
    yearEnd: 2009,
  },
  {
    brand: 'Chevrolet',
    model: 'Venture',
    yearStart: 1996,
    yearEnd: 2005,
  },
  {
    brand: 'Chrysler',
    model: 200,
    yearStart: 2010,
    yearEnd: 2017,
  },
  {
    brand: 'Chrysler',
    model: 300,
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'Chrysler',
    model: '300M',
    yearStart: 1998,
    yearEnd: 2004,
  },
  {
    brand: 'Chrysler',
    model: 'Aspen',
    yearStart: 2006,
    yearEnd: 2009,
  },
  {
    brand: 'Chrysler',
    model: 'Concorde',
    yearStart: 1998,
    yearEnd: 2004,
  },
  {
    brand: 'Chrysler',
    model: 'Crossfire',
    yearStart: 2003,
    yearEnd: 2007,
  },
  {
    brand: 'Chrysler',
    model: 'Grand Caravan',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Chrysler',
    model: 'Grand Voyager',
    yearStart: 2008,
    yearEnd: 2015,
  },
  {
    brand: 'Chrysler',
    model: 'Pacifica',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'Chrysler',
    model: 'PT Cruiser',
    yearStart: 2000,
    yearEnd: 2010,
  },
  {
    brand: 'Chrysler',
    model: 'Sebring',
    yearStart: 2000,
    yearEnd: 2010,
  },
  {
    brand: 'Chrysler',
    model: 'Town & Country',
    yearStart: 2008,
    yearEnd: 2016,
  },
  {
    brand: 'Chrysler',
    model: 'Voyager',
    yearStart: 1995,
    yearEnd: '-',
  },
  {
    brand: 'Citroen',
    model: 'Berlingo',
    yearStart: 1996,
    yearEnd: '-',
  },
  {
    brand: 'Citroen',
    model: 'C-Crosser',
    yearStart: 2007,
    yearEnd: 2012,
  },
  {
    brand: 'Citroen',
    model: 'C-Elysee',
    yearStart: 2012,
    yearEnd: 2020,
  },
  {
    brand: 'Citroen',
    model: 'C1',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Citroen',
    model: 'C2',
    yearStart: 2003,
    yearEnd: 2009,
  },
  {
    brand: 'Citroen',
    model: 'C3',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Citroen',
    model: 'C3 Aircross',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Citroen',
    model: 'C3 Picasso',
    yearStart: 2009,
    yearEnd: 2017,
  },
  {
    brand: 'Citroen',
    model: 'C3 Pluriel',
    yearStart: 2003,
    yearEnd: 2010,
  },
  {
    brand: 'Citroen',
    model: 'C4',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'Citroen',
    model: 'C4 Aircross',
    yearStart: 2012,
    yearEnd: 2017,
  },
  {
    brand: 'Citroen',
    model: 'C4 Cactus',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Citroen',
    model: 'C4 Picasso',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Citroen',
    model: 'C4 SpaceTourer',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Citroen',
    model: 'C5',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Citroen',
    model: 'C5 Aircross',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Citroen',
    model: 'C6',
    yearStart: 2004,
    yearEnd: 2012,
  },
  {
    brand: 'Citroen',
    model: 'C8',
    yearStart: 2002,
    yearEnd: 2014,
  },
  {
    brand: 'Citroen',
    model: 'DS 7 Crossback',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Citroen',
    model: 'DS3',
    yearStart: 2009,
    yearEnd: 2019,
  },
  {
    brand: 'Citroen',
    model: 'DS4',
    yearStart: 2010,
    yearEnd: 2018,
  },
  {
    brand: 'Citroen',
    model: 'DS5',
    yearStart: 2011,
    yearEnd: 2018,
  },
  {
    brand: 'Citroen',
    model: 'Grand C4 Picasso',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Citroen',
    model: 'Grand C4 SpaceTourer',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Citroen',
    model: 'Jumper',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Citroen',
    model: 'Jumpy',
    yearStart: 2007,
    yearEnd: 2016,
  },
  {
    brand: 'Citroen',
    model: 'Nemo',
    yearStart: 2008,
    yearEnd: 2018,
  },
  {
    brand: 'Citroen',
    model: 'Saxo',
    yearStart: 1996,
    yearEnd: 2004,
  },
  {
    brand: 'Citroen',
    model: 'Spacetourer',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Citroen',
    model: 'Xsara',
    yearStart: 1997,
    yearEnd: 2006,
  },
  {
    brand: 'Citroen',
    model: 'Xsara Picasso',
    yearStart: 1999,
    yearEnd: 2012,
  },
  {
    brand: 'Dacia',
    model: 'Dokker',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Dacia',
    model: 'Lodgy',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Dacia',
    model: 'Solenza',
    yearStart: 2003,
    yearEnd: 2005,
  },
  {
    brand: 'Dacia',
    model: 'Spring',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Dacia',
    model: 'SupeRNova',
    yearStart: 2000,
    yearEnd: 2003,
  },
  {
    brand: 'Daewoo',
    model: 'Evanda',
    yearStart: 2002,
    yearEnd: 2007,
  },
  {
    brand: 'Daewoo',
    model: 'Kalos',
    yearStart: 2002,
    yearEnd: 2007,
  },
  {
    brand: 'Daewoo',
    model: 'Leganza',
    yearStart: 1997,
    yearEnd: 2002,
  },
  {
    brand: 'Daewoo',
    model: 'Magnus',
    yearStart: 2000,
    yearEnd: 2006,
  },
  {
    brand: 'Daewoo',
    model: 'Matiz',
    yearStart: 2000,
    yearEnd: 2015,
  },
  {
    brand: 'Daewoo',
    model: 'Nexia',
    yearStart: 1995,
    yearEnd: 2016,
  },
  {
    brand: 'Daewoo',
    model: 'Nubira',
    yearStart: 1997,
    yearEnd: 2008,
  },
  {
    brand: 'Daihatsu',
    model: 'Applause',
    yearStart: 1997,
    yearEnd: 2000,
  },
  {
    brand: 'Daihatsu',
    model: 'Cast',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Daihatsu',
    model: 'Copen',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Daihatsu',
    model: 'Cuore',
    yearStart: 2003,
    yearEnd: 2011,
  },
  {
    brand: 'Daihatsu',
    model: 'Gran Move',
    yearStart: 1996,
    yearEnd: 2002,
  },
  {
    brand: 'Daihatsu',
    model: 'Luxio',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'Daihatsu',
    model: 'Materia',
    yearStart: 2006,
    yearEnd: 2012,
  },
  {
    brand: 'Daihatsu',
    model: 'Mebius',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Daihatsu',
    model: 'Move',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Daihatsu',
    model: 'Rocky',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Daihatsu',
    model: 'Sirion',
    yearStart: 1998,
    yearEnd: 2004,
  },
  {
    brand: 'Daihatsu',
    model: 'Terios',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Daihatsu',
    model: 'Trevis',
    yearStart: 2006,
    yearEnd: 2011,
  },
  {
    brand: 'Daihatsu',
    model: 'YRV',
    yearStart: 2000,
    yearEnd: 2005,
  },
  {
    brand: 'Datsun',
    model: 'mi-DO',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Datsun',
    model: 'on-DO',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Dodge',
    model: 'Avenger',
    yearStart: 1994,
    yearEnd: 2014,
  },
  {
    brand: 'Dodge',
    model: 'Caliber',
    yearStart: 2006,
    yearEnd: 2012,
  },
  {
    brand: 'Dodge',
    model: 'Caliber SRT4',
    yearStart: 2007,
    yearEnd: 2010,
  },
  {
    brand: 'Dodge',
    model: 'Caravan',
    yearStart: 1995,
    yearEnd: 2016,
  },
  {
    brand: 'Dodge',
    model: 'Challenger',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Dodge',
    model: 'Charger',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Dodge',
    model: 'Dakota',
    yearStart: 2005,
    yearEnd: 2011,
  },
  {
    brand: 'Dodge',
    model: 'Dart',
    yearStart: 2012,
    yearEnd: 2017,
  },
  {
    brand: 'Dodge',
    model: 'Durango',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Dodge',
    model: 'Intrepid',
    yearStart: 1998,
    yearEnd: 2004,
  },
  {
    brand: 'Dodge',
    model: 'Journey',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Dodge',
    model: 'Magnum',
    yearStart: 2004,
    yearEnd: 2008,
  },
  {
    brand: 'Dodge',
    model: 'Neon',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Dodge',
    model: 'Nitro',
    yearStart: 2007,
    yearEnd: 2012,
  },
  {
    brand: 'Dodge',
    model: 'Ram 1500',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Dodge',
    model: 'Ram 2500',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Dodge',
    model: 'Ram 3500',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Dodge',
    model: 'Ram SRT10',
    yearStart: 2004,
    yearEnd: 2007,
  },
  {
    brand: 'Dodge',
    model: 'Stratus',
    yearStart: 2001,
    yearEnd: 2006,
  },
  {
    brand: 'Dodge',
    model: 'Viper',
    yearStart: 1996,
    yearEnd: 2017,
  },
  {
    brand: 'Dongfeng',
    model: 580,
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Dongfeng',
    model: 'A30',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Dongfeng',
    model: 'AX7',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Dongfeng',
    model: 'H30 Cross',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Exeed',
    model: 'TXL',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Exeed',
    model: 'VX',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'FAW',
    model: 'Bestune T77',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'FAW',
    model: 'Besturn B30',
    yearStart: 2016,
    yearEnd: 2020,
  },
  {
    brand: 'FAW',
    model: 'Besturn B50',
    yearStart: 2009,
    yearEnd: 2016,
  },
  {
    brand: 'FAW',
    model: 'Besturn B70',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'FAW',
    model: 'Besturn X40',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'FAW',
    model: 'Besturn X80',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'FAW',
    model: 'Oley',
    yearStart: 2012,
    yearEnd: 2018,
  },
  {
    brand: 'FAW',
    model: 'Vita',
    yearStart: 2006,
    yearEnd: 2012,
  },
  {
    brand: 'Ferrari',
    model: 296,
    yearStart: 2022,
    yearEnd: '-',
  },
  {
    brand: 'Ferrari',
    model: 348,
    yearStart: 1993,
    yearEnd: 1995,
  },
  {
    brand: 'Ferrari',
    model: 360,
    yearStart: 1999,
    yearEnd: 2005,
  },
  {
    brand: 'Ferrari',
    model: 456,
    yearStart: 1992,
    yearEnd: 2003,
  },
  {
    brand: 'Ferrari',
    model: 458,
    yearStart: 2009,
    yearEnd: 2016,
  },
  {
    brand: 'Ferrari',
    model: 488,
    yearStart: 2015,
    yearEnd: 2020,
  },
  {
    brand: 'Ferrari',
    model: 512,
    yearStart: 1991,
    yearEnd: 2004,
  },
  {
    brand: 'Ferrari',
    model: 550,
    yearStart: 1996,
    yearEnd: 2002,
  },
  {
    brand: 'Ferrari',
    model: '575 M',
    yearStart: 2002,
    yearEnd: 2006,
  },
  {
    brand: 'Ferrari',
    model: '599 GTB Fiorano',
    yearStart: 2006,
    yearEnd: 2012,
  },
  {
    brand: 'Ferrari',
    model: '599 GTO',
    yearStart: 2010,
    yearEnd: 2012,
  },
  {
    brand: 'Ferrari',
    model: 612,
    yearStart: 2004,
    yearEnd: 2011,
  },
  {
    brand: 'Ferrari',
    model: 812,
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Ferrari',
    model: 'California',
    yearStart: 2008,
    yearEnd: 2014,
  },
  {
    brand: 'Ferrari',
    model: 'California T',
    yearStart: 2014,
    yearEnd: 2017,
  },
  {
    brand: 'Ferrari',
    model: 'Challenge Stradale',
    yearStart: 2003,
    yearEnd: 2003,
  },
  {
    brand: 'Ferrari',
    model: 'Enzo',
    yearStart: 2002,
    yearEnd: 2004,
  },
  {
    brand: 'Ferrari',
    model: 'F12',
    yearStart: 2012,
    yearEnd: 2017,
  },
  {
    brand: 'Ferrari',
    model: 'F355',
    yearStart: 1994,
    yearEnd: 1999,
  },
  {
    brand: 'Ferrari',
    model: 'F430',
    yearStart: 2004,
    yearEnd: 2010,
  },
  {
    brand: 'Ferrari',
    model: 'F50',
    yearStart: 1995,
    yearEnd: 1997,
  },
  {
    brand: 'Ferrari',
    model: 'F512 M',
    yearStart: 1994,
    yearEnd: 1996,
  },
  {
    brand: 'Ferrari',
    model: 'F8 Spider',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Ferrari',
    model: 'F8 Tributo',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Ferrari',
    model: 'FF',
    yearStart: 2011,
    yearEnd: 2017,
  },
  {
    brand: 'Ferrari',
    model: 'GTC4 Lusso',
    yearStart: 2016,
    yearEnd: 2020,
  },
  {
    brand: 'Ferrari',
    model: 'LaFerrari',
    yearStart: 2013,
    yearEnd: 2015,
  },
  {
    brand: 'Ferrari',
    model: 'Portofino',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Ferrari',
    model: 'Portofino M',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Ferrari',
    model: 'Roma',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Ferrari',
    model: 'SF90 Spider',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Ferrari',
    model: 'SF90 Stradale',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Fiat',
    model: '124 Spider',
    yearStart: 2015,
    yearEnd: 2020,
  },
  {
    brand: 'Fiat',
    model: 500,
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Fiat',
    model: '500L',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Fiat',
    model: '500X',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Fiat',
    model: 'Albea',
    yearStart: 2002,
    yearEnd: 2012,
  },
  {
    brand: 'Fiat',
    model: 'Brava',
    yearStart: 1995,
    yearEnd: 2003,
  },
  {
    brand: 'Fiat',
    model: 'Bravo',
    yearStart: 1995,
    yearEnd: 2016,
  },
  {
    brand: 'Fiat',
    model: 'Coupe',
    yearStart: 1993,
    yearEnd: 2009,
  },
  {
    brand: 'Fiat',
    model: 'Croma',
    yearStart: 2005,
    yearEnd: 2011,
  },
  {
    brand: 'Fiat',
    model: 'Doblo',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Fiat',
    model: 'Ducato',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Fiat',
    model: 'Freemont',
    yearStart: 2011,
    yearEnd: 2016,
  },
  {
    brand: 'Fiat',
    model: 'Grande Punto',
    yearStart: 2005,
    yearEnd: 2009,
  },
  {
    brand: 'Fiat',
    model: 'Idea',
    yearStart: 2004,
    yearEnd: 2016,
  },
  {
    brand: 'Fiat',
    model: 'Linea',
    yearStart: 2007,
    yearEnd: 2015,
  },
  {
    brand: 'Fiat',
    model: 'Marea',
    yearStart: 1996,
    yearEnd: 2006,
  },
  {
    brand: 'Fiat',
    model: 'Multipla',
    yearStart: 1998,
    yearEnd: 2010,
  },
  {
    brand: 'Fiat',
    model: 'Palio',
    yearStart: 1996,
    yearEnd: 2017,
  },
  {
    brand: 'Fiat',
    model: 'Panda',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Fiat',
    model: 'Panda 4x4',
    yearStart: 2003,
    yearEnd: 2012,
  },
  {
    brand: 'Fiat',
    model: 'Punto',
    yearStart: 1999,
    yearEnd: 2018,
  },
  {
    brand: 'Fiat',
    model: 'Qubo',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Fiat',
    model: 'Sedici',
    yearStart: 2005,
    yearEnd: 2014,
  },
  {
    brand: 'Fiat',
    model: 'Siena',
    yearStart: 2014,
    yearEnd: 2021,
  },
  {
    brand: 'Fiat',
    model: 'Stilo',
    yearStart: 2001,
    yearEnd: 2010,
  },
  {
    brand: 'Fiat',
    model: 'Strada',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Fiat',
    model: 'Tipo',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Fiat',
    model: 'Ulysse',
    yearStart: 2002,
    yearEnd: 2010,
  },
  {
    brand: 'Fisker',
    model: 'Karma',
    yearStart: 2011,
    yearEnd: 2012,
  },
  {
    brand: 'Ford',
    model: 'B-Max',
    yearStart: 2012,
    yearEnd: 2017,
  },
  {
    brand: 'Ford',
    model: 'Bronco',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Bronco Sport',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'C-Max',
    yearStart: 2003,
    yearEnd: 2019,
  },
  {
    brand: 'Ford',
    model: 'Cougar',
    yearStart: 1998,
    yearEnd: 2004,
  },
  {
    brand: 'Ford',
    model: 'Crown Victoria',
    yearStart: 1999,
    yearEnd: 2011,
  },
  {
    brand: 'Ford',
    model: 'EcoSport',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Edge',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Endura',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Equator',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Escape',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Excursion',
    yearStart: 1999,
    yearEnd: 2005,
  },
  {
    brand: 'Ford',
    model: 'Expedition',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Explorer',
    yearStart: 1995,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Explorer Sport Trac',
    yearStart: 2007,
    yearEnd: 2010,
  },
  {
    brand: 'Ford',
    model: 'F-150',
    yearStart: 1992,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'F-250',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'F-350',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Falcon',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Fiesta',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Five Hundred',
    yearStart: 2005,
    yearEnd: 2007,
  },
  {
    brand: 'Ford',
    model: 'Flex',
    yearStart: 2008,
    yearEnd: 2019,
  },
  {
    brand: 'Ford',
    model: 'Focus',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Focus Active',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Focus Electric',
    yearStart: 2012,
    yearEnd: 2018,
  },
  {
    brand: 'Ford',
    model: 'Freestar',
    yearStart: 2003,
    yearEnd: 2009,
  },
  {
    brand: 'Ford',
    model: 'Freestyle',
    yearStart: 2004,
    yearEnd: 2007,
  },
  {
    brand: 'Ford',
    model: 'Fusion',
    yearStart: 2002,
    yearEnd: 2012,
  },
  {
    brand: 'Ford',
    model: 'Galaxy',
    yearStart: 1995,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Ka',
    yearStart: 2003,
    yearEnd: 2021,
  },
  {
    brand: 'Ford',
    model: 'Kuga',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Maverick',
    yearStart: 1992,
    yearEnd: 2009,
  },
  {
    brand: 'Ford',
    model: 'Mondeo',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Mustang',
    yearStart: 1995,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Mustang Mach-E',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Mustang Shelby GT350',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Mustang Shelby GT500',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Puma',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Ranger',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'S-Max',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Taurus',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Taurus X',
    yearStart: 2007,
    yearEnd: 2009,
  },
  {
    brand: 'Ford',
    model: 'Thunderbird',
    yearStart: 2000,
    yearEnd: 2006,
  },
  {
    brand: 'Ford',
    model: 'Tourneo Connect',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Transit',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Ford',
    model: 'Transit Connect',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Foton',
    model: 'Sauvana',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'GAC',
    model: 'GS5',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'GAC',
    model: 'Trumpchi GM8',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'GAC',
    model: 'Trumpchi GS8',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'GAZ',
    model: 3102,
    yearStart: 1997,
    yearEnd: 2009,
  },
  {
    brand: 'GAZ',
    model: 31105,
    yearStart: 1997,
    yearEnd: 2009,
  },
  {
    brand: 'GAZ',
    model: 'Gazelle',
    yearStart: 1994,
    yearEnd: 2010,
  },
  {
    brand: 'GAZ',
    model: 'Gazelle Business',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'GAZ',
    model: 'Gazelle Next',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'GAZ',
    model: 'Gazelle NN',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'GAZ',
    model: 'Gazelle Sity',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'GAZ',
    model: 'Siber',
    yearStart: 2008,
    yearEnd: 2010,
  },
  {
    brand: 'GAZ',
    model: 'Sobol',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Geely',
    model: 'Atlas',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Geely',
    model: 'Atlas Pro',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Geely',
    model: 'Azkarra',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Geely',
    model: 'Coolray',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Geely',
    model: 'Emgrand 7',
    yearStart: 2016,
    yearEnd: 2020,
  },
  {
    brand: 'Geely',
    model: 'Emgrand EC7',
    yearStart: 2009,
    yearEnd: 2017,
  },
  {
    brand: 'Geely',
    model: 'Emgrand GS',
    yearStart: 2016,
    yearEnd: 2020,
  },
  {
    brand: 'Geely',
    model: 'Emgrand X7',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Geely',
    model: 'GC9',
    yearStart: 2015,
    yearEnd: 2019,
  },
  {
    brand: 'Geely',
    model: 'GС6',
    yearStart: 2014,
    yearEnd: 2016,
  },
  {
    brand: 'Geely',
    model: 'Okavango',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Geely',
    model: 'MK',
    yearStart: 2008,
    yearEnd: 2019,
  },
  {
    brand: 'Geely',
    model: 'Monjaro',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Geely',
    model: 'Otaka',
    yearStart: 2006,
    yearEnd: 2009,
  },
  {
    brand: 'Geely',
    model: 'Preface',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Geely',
    model: 'Tugella',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Geely',
    model: 'Vision',
    yearStart: 2006,
    yearEnd: 2013,
  },
  {
    brand: 'Genesis',
    model: 'G70',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Genesis',
    model: 'G80',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Genesis',
    model: 'G90',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Genesis',
    model: 'GV60',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Genesis',
    model: 'GV70',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Genesis',
    model: 'GV80',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'GMC',
    model: 'Acadia',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'GMC',
    model: 'Canyon',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'GMC',
    model: 'Envoy',
    yearStart: 2001,
    yearEnd: 2009,
  },
  {
    brand: 'GMC',
    model: 'Sierra 1500',
    yearStart: 1999,
    yearEnd: '-',
  },
  {
    brand: 'GMC',
    model: 'Sierra 2500',
    yearStart: 1999,
    yearEnd: '-',
  },
  {
    brand: 'GMC',
    model: 'Sierra 3500',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'GMC',
    model: 'Terrain',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'GMC',
    model: 'Yukon',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Great Wall',
    model: 'Cowry',
    yearStart: 2007,
    yearEnd: 2014,
  },
  {
    brand: 'Great Wall',
    model: 'Deer',
    yearStart: 2003,
    yearEnd: 2013,
  },
  {
    brand: 'Great Wall',
    model: 'Hover',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Great Wall',
    model: 'Hover M2',
    yearStart: 2010,
    yearEnd: 2014,
  },
  {
    brand: 'Great Wall',
    model: 'Pegasus',
    yearStart: 2005,
    yearEnd: 2007,
  },
  {
    brand: 'Great Wall',
    model: 'Peri',
    yearStart: 2007,
    yearEnd: 2015,
  },
  {
    brand: 'Great Wall',
    model: 'Poer',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Great Wall',
    model: 'Safe',
    yearStart: 2002,
    yearEnd: 2010,
  },
  {
    brand: 'Great Wall',
    model: 'Sailor',
    yearStart: 2004,
    yearEnd: 2012,
  },
  {
    brand: 'Great Wall',
    model: 'Sing',
    yearStart: 2004,
    yearEnd: 2012,
  },
  {
    brand: 'Great Wall',
    model: 'Socool',
    yearStart: 2004,
    yearEnd: 2012,
  },
  {
    brand: 'Great Wall',
    model: 'Wingle',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Great Wall',
    model: 'Wingle 7',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Haval',
    model: 'Dargo',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Haval',
    model: 'F7',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Haval',
    model: 'F7x',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Haval',
    model: 'H4',
    yearStart: 2018,
    yearEnd: 2021,
  },
  {
    brand: 'Haval',
    model: 'H6',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Haval',
    model: 'H9',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Haval',
    model: 'Jolion',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Holden',
    model: 'Commodore',
    yearStart: 2004,
    yearEnd: 2017,
  },
  {
    brand: 'Holden',
    model: 'Corvette C8',
    yearStart: 2020,
    yearEnd: 2021,
  },
  {
    brand: 'Honda',
    model: 'Accord',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'Amaze',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'City',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'Civic',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'Civic Type R',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'CR-V',
    yearStart: 1995,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'CR-Z',
    yearStart: 2010,
    yearEnd: 2016,
  },
  {
    brand: 'Honda',
    model: 'Crosstour',
    yearStart: 2010,
    yearEnd: 2015,
  },
  {
    brand: 'Honda',
    model: 'e',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'Element',
    yearStart: 2003,
    yearEnd: 2011,
  },
  {
    brand: 'Honda',
    model: 'Fit',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'FR-V',
    yearStart: 2004,
    yearEnd: 2009,
  },
  {
    brand: 'Honda',
    model: 'HR-V',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'Insight',
    yearStart: 1999,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'Jade',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'Jazz',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'Legend',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'Odyssey',
    yearStart: 1999,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'Passport',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'Pilot',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'Prelude',
    yearStart: 1996,
    yearEnd: 2001,
  },
  {
    brand: 'Honda',
    model: 'Ridgeline',
    yearStart: 2005,
    yearEnd: 2014,
  },
  {
    brand: 'Honda',
    model: 'S2000',
    yearStart: 1999,
    yearEnd: 2009,
  },
  {
    brand: 'Honda',
    model: 'Shuttle',
    yearStart: 1994,
    yearEnd: 2002,
  },
  {
    brand: 'Honda',
    model: 'Stepwgn',
    yearStart: 2022,
    yearEnd: '-',
  },
  {
    brand: 'Honda',
    model: 'Stream',
    yearStart: 2001,
    yearEnd: 2015,
  },
  {
    brand: 'Honda',
    model: 'Vezel',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Hummer',
    model: 'H1',
    yearStart: 1992,
    yearEnd: 2006,
  },
  {
    brand: 'Hummer',
    model: 'H2',
    yearStart: 2003,
    yearEnd: 2010,
  },
  {
    brand: 'Hummer',
    model: 'H3',
    yearStart: 2005,
    yearEnd: 2010,
  },
  {
    brand: 'Hyundai',
    model: 'Accent',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Atos Prime',
    yearStart: 1999,
    yearEnd: 2014,
  },
  {
    brand: 'Hyundai',
    model: 'Azera',
    yearStart: 2006,
    yearEnd: 2011,
  },
  {
    brand: 'Hyundai',
    model: 'Bayon',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Centennial',
    yearStart: 1999,
    yearEnd: 2008,
  },
  {
    brand: 'Hyundai',
    model: 'Creta',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Creta Grand',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Elantra',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Entourage',
    yearStart: 2007,
    yearEnd: 2007,
  },
  {
    brand: 'Hyundai',
    model: 'Eon',
    yearStart: 2011,
    yearEnd: 2019,
  },
  {
    brand: 'Hyundai',
    model: 'Equus',
    yearStart: 2009,
    yearEnd: 2016,
  },
  {
    brand: 'Hyundai',
    model: 'Galloper',
    yearStart: 1998,
    yearEnd: 2003,
  },
  {
    brand: 'Hyundai',
    model: 'Genesis',
    yearStart: 2008,
    yearEnd: 2016,
  },
  {
    brand: 'Hyundai',
    model: 'Genesis Coupe',
    yearStart: 2008,
    yearEnd: 2016,
  },
  {
    brand: 'Hyundai',
    model: 'Getz',
    yearStart: 2002,
    yearEnd: 2011,
  },
  {
    brand: 'Hyundai',
    model: 'Grandeur',
    yearStart: 2005,
    yearEnd: 2017,
  },
  {
    brand: 'Hyundai',
    model: 'H-1',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'i10',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'i20',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'i20 N',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'i30',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'i30 N',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'i40',
    yearStart: 2011,
    yearEnd: 2019,
  },
  {
    brand: 'Hyundai',
    model: 'Ioniq',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Ioniq 5',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'ix20',
    yearStart: 2010,
    yearEnd: 2020,
  },
  {
    brand: 'Hyundai',
    model: 'ix35',
    yearStart: 2010,
    yearEnd: 2018,
  },
  {
    brand: 'Hyundai',
    model: 'Kona',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Kona N',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Kusto',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Matrix',
    yearStart: 2001,
    yearEnd: 2014,
  },
  {
    brand: 'Hyundai',
    model: 'Mistra',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Nexo',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Palisade',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Porter',
    yearStart: 1996,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Santa Cruz',
    yearStart: 2022,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Santa Fe',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Solaris',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Sonata',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Staria',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Terracan',
    yearStart: 2001,
    yearEnd: 2007,
  },
  {
    brand: 'Hyundai',
    model: 'Trajet',
    yearStart: 2000,
    yearEnd: 2008,
  },
  {
    brand: 'Hyundai',
    model: 'Tucson',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Veloster',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Venue',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Hyundai',
    model: 'Veracruz',
    yearStart: 2007,
    yearEnd: 2015,
  },
  {
    brand: 'Hyundai',
    model: 'Verna',
    yearStart: 2005,
    yearEnd: 2011,
  },
  {
    brand: 'Hyundai',
    model: 'Xcent',
    yearStart: 2014,
    yearEnd: 2021,
  },
  {
    brand: 'Hyundai',
    model: 'XG',
    yearStart: 1998,
    yearEnd: 2005,
  },
  {
    brand: 'Infiniti',
    model: 'EX',
    yearStart: 2008,
    yearEnd: 2014,
  },
  {
    brand: 'Infiniti',
    model: 'FX',
    yearStart: 2003,
    yearEnd: 2013,
  },
  {
    brand: 'Infiniti',
    model: 'G',
    yearStart: 2003,
    yearEnd: 2014,
  },
  {
    brand: 'Infiniti',
    model: 'I35',
    yearStart: 2002,
    yearEnd: 2004,
  },
  {
    brand: 'Infiniti',
    model: 'JX',
    yearStart: 2012,
    yearEnd: 2013,
  },
  {
    brand: 'Infiniti',
    model: 'M',
    yearStart: 2001,
    yearEnd: 2013,
  },
  {
    brand: 'Infiniti',
    model: 'Q30',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Infiniti',
    model: 'Q40',
    yearStart: 2014,
    yearEnd: 2015,
  },
  {
    brand: 'Infiniti',
    model: 'Q45',
    yearStart: 2002,
    yearEnd: 2007,
  },
  {
    brand: 'Infiniti',
    model: 'Q50',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Infiniti',
    model: 'Q60',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Infiniti',
    model: 'Q70',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Infiniti',
    model: 'QX30',
    yearStart: 2016,
    yearEnd: 2020,
  },
  {
    brand: 'Infiniti',
    model: 'QX4',
    yearStart: 1997,
    yearEnd: 2003,
  },
  {
    brand: 'Infiniti',
    model: 'QX50',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Infiniti',
    model: 'QX55',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Infiniti',
    model: 'QX56',
    yearStart: 2004,
    yearEnd: 2013,
  },
  {
    brand: 'Infiniti',
    model: 'QX60',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Infiniti',
    model: 'QX70',
    yearStart: 2013,
    yearEnd: 2017,
  },
  {
    brand: 'Infiniti',
    model: 'QX80',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Isuzu',
    model: 'Ascender',
    yearStart: 2002,
    yearEnd: 2008,
  },
  {
    brand: 'Isuzu',
    model: 'Axiom',
    yearStart: 2002,
    yearEnd: 2004,
  },
  {
    brand: 'Isuzu',
    model: 'D-Max',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Isuzu',
    model: 'D-Max Rodeo',
    yearStart: 2007,
    yearEnd: 2007,
  },
  {
    brand: 'Isuzu',
    model: 'I280',
    yearStart: 2005,
    yearEnd: 2006,
  },
  {
    brand: 'Isuzu',
    model: 'I290',
    yearStart: 2007,
    yearEnd: 2007,
  },
  {
    brand: 'Isuzu',
    model: 'I350',
    yearStart: 2005,
    yearEnd: 2006,
  },
  {
    brand: 'Isuzu',
    model: 'I370',
    yearStart: 2007,
    yearEnd: 2007,
  },
  {
    brand: 'Isuzu',
    model: 'mu-X',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Isuzu',
    model: 'Rodeo',
    yearStart: 1998,
    yearEnd: 2004,
  },
  {
    brand: 'Isuzu',
    model: 'Trooper',
    yearStart: 1992,
    yearEnd: 2002,
  },
  {
    brand: 'Isuzu',
    model: 'VehiCross',
    yearStart: 1997,
    yearEnd: 2001,
  },
  {
    brand: 'Iveco',
    model: 'Daily',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Jac',
    model: 'iEV7S',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Jac',
    model: 'T6',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Jaguar',
    model: 'E-Pace',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Jaguar',
    model: 'F-Pace',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Jaguar',
    model: 'F-Type',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Jaguar',
    model: 'I-Pace',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Jaguar',
    model: 'S-Type',
    yearStart: 1998,
    yearEnd: 2008,
  },
  {
    brand: 'Jaguar',
    model: 'X-Type',
    yearStart: 2001,
    yearEnd: 2009,
  },
  {
    brand: 'Jaguar',
    model: 'XE',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Jaguar',
    model: 'XF',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Jaguar',
    model: 'XJ',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Jaguar',
    model: 'XK/XKR',
    yearStart: 2002,
    yearEnd: 2015,
  },
  {
    brand: 'Jeep',
    model: 'Cherokee',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Jeep',
    model: 'Commander',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Jeep',
    model: 'Compass',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Jeep',
    model: 'Gladiator',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Jeep',
    model: 'Grand Cherokee',
    yearStart: 1999,
    yearEnd: '-',
  },
  {
    brand: 'Jeep',
    model: 'Grand Wagoneer',
    yearStart: 2022,
    yearEnd: '-',
  },
  {
    brand: 'Jeep',
    model: 'Liberty',
    yearStart: 2006,
    yearEnd: 2013,
  },
  {
    brand: 'Jeep',
    model: 'Meridian',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Jeep',
    model: 'Patriot',
    yearStart: 2006,
    yearEnd: 2016,
  },
  {
    brand: 'Jeep',
    model: 'Renegade',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Jeep',
    model: 'Wagoneer',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Jeep',
    model: 'Wrangler',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Carens',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Carnival',
    yearStart: 1999,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Ceed',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Cerato',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Clarus',
    yearStart: 1998,
    yearEnd: 2001,
  },
  {
    brand: 'Kia',
    model: 'EV6',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Forte',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'K5',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'K8',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'K900',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Magentis',
    yearStart: 2001,
    yearEnd: 2010,
  },
  {
    brand: 'Kia',
    model: 'Mohave',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Niro',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Opirus',
    yearStart: 2003,
    yearEnd: 2010,
  },
  {
    brand: 'Kia',
    model: 'Optima',
    yearStart: 2010,
    yearEnd: 2020,
  },
  {
    brand: 'Kia',
    model: 'Picanto',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'ProCeed',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Quoris',
    yearStart: 2012,
    yearEnd: 2018,
  },
  {
    brand: 'Kia',
    model: 'Ray',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Rio',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Rio X',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Rio X-Line',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Seltos',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Shuma',
    yearStart: 1997,
    yearEnd: 2005,
  },
  {
    brand: 'Kia',
    model: 'Sonet',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Sorento',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Sorento Prime',
    yearStart: 2015,
    yearEnd: 2020,
  },
  {
    brand: 'Kia',
    model: 'Soul',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Spectra',
    yearStart: 2005,
    yearEnd: 2009,
  },
  {
    brand: 'Kia',
    model: 'Sportage',
    yearStart: 1993,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Stinger',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Stonic',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Telluride',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Kia',
    model: 'Venga',
    yearStart: 2009,
    yearEnd: 2019,
  },
  {
    brand: 'Lamborghini',
    model: 'Aventador',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Lamborghini',
    model: 'Centenario',
    yearStart: 2016,
    yearEnd: 2017,
  },
  {
    brand: 'Lamborghini',
    model: 'Diablo',
    yearStart: 1991,
    yearEnd: 2001,
  },
  {
    brand: 'Lamborghini',
    model: 'Gallardo',
    yearStart: 2003,
    yearEnd: 2014,
  },
  {
    brand: 'Lamborghini',
    model: 'Huracan',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Lamborghini',
    model: 'Murcielago',
    yearStart: 2003,
    yearEnd: 2010,
  },
  {
    brand: 'Lamborghini',
    model: 'Reventon',
    yearStart: 2008,
    yearEnd: 2008,
  },
  {
    brand: 'Lamborghini',
    model: 'Sian',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Lamborghini',
    model: 'Urus',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Lancia',
    model: 'Delta',
    yearStart: 2008,
    yearEnd: 2014,
  },
  {
    brand: 'Lancia',
    model: 'Lybra',
    yearStart: 1999,
    yearEnd: 2007,
  },
  {
    brand: 'Lancia',
    model: 'Musa',
    yearStart: 2004,
    yearEnd: 2012,
  },
  {
    brand: 'Lancia',
    model: 'Phedra',
    yearStart: 2002,
    yearEnd: 2010,
  },
  {
    brand: 'Lancia',
    model: 'Thema',
    yearStart: 2011,
    yearEnd: 2014,
  },
  {
    brand: 'Lancia',
    model: 'Thesis',
    yearStart: 2001,
    yearEnd: 2009,
  },
  {
    brand: 'Lancia',
    model: 'Ypsilon',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Land Rover',
    model: 'Defender',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Land Rover',
    model: 'Discovery',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Land Rover',
    model: 'Discovery Sport',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Land Rover',
    model: 'Evoque',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Land Rover',
    model: 'Freelander',
    yearStart: 1998,
    yearEnd: 2014,
  },
  {
    brand: 'Land Rover',
    model: 'Range Rover',
    yearStart: 1994,
    yearEnd: '-',
  },
  {
    brand: 'Land Rover',
    model: 'Range Rover Sport',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'Land Rover',
    model: 'Range Rover Velar',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Lexus',
    model: 'CT',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'Lexus',
    model: 'ES',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Lexus',
    model: 'GS',
    yearStart: 1997,
    yearEnd: 2022,
  },
  {
    brand: 'Lexus',
    model: 'GX',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Lexus',
    model: 'HS',
    yearStart: 2009,
    yearEnd: 2014,
  },
  {
    brand: 'Lexus',
    model: 'IS',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Lexus',
    model: 'LC',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Lexus',
    model: 'LFA',
    yearStart: 2010,
    yearEnd: 2012,
  },
  {
    brand: 'Lexus',
    model: 'LM',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Lexus',
    model: 'LS',
    yearStart: 1995,
    yearEnd: '-',
  },
  {
    brand: 'Lexus',
    model: 'LX',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Lexus',
    model: 'NX',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Lexus',
    model: 'RC',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Lexus',
    model: 'RC F',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Lexus',
    model: 'RX',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Lexus',
    model: 'SC',
    yearStart: 1999,
    yearEnd: 2010,
  },
  {
    brand: 'Lexus',
    model: 'UX',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Lifan',
    model: 'Breez',
    yearStart: 2007,
    yearEnd: 2012,
  },
  {
    brand: 'Lifan',
    model: 'Cebrium',
    yearStart: 2013,
    yearEnd: 2017,
  },
  {
    brand: 'Lifan',
    model: 'Celliya',
    yearStart: 2013,
    yearEnd: 2016,
  },
  {
    brand: 'Lifan',
    model: 'Murman',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Lifan',
    model: 'Myway',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Lifan',
    model: 'Smily',
    yearStart: 2008,
    yearEnd: 2018,
  },
  {
    brand: 'Lifan',
    model: 'Solano',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Lifan',
    model: 'X50',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Lifan',
    model: 'X60',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Lifan',
    model: 'X70',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Lifan',
    model: 'X80',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Lincoln',
    model: 'Aviator',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Lincoln',
    model: 'Corsair',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Lincoln',
    model: 'Mark LT',
    yearStart: 2006,
    yearEnd: 2007,
  },
  {
    brand: 'Lincoln',
    model: 'MKC',
    yearStart: 2014,
    yearEnd: 2019,
  },
  {
    brand: 'Lincoln',
    model: 'MKS',
    yearStart: 2008,
    yearEnd: 2016,
  },
  {
    brand: 'Lincoln',
    model: 'MKT',
    yearStart: 2009,
    yearEnd: 2019,
  },
  {
    brand: 'Lincoln',
    model: 'MKX',
    yearStart: 2006,
    yearEnd: 2018,
  },
  {
    brand: 'Lincoln',
    model: 'MKZ',
    yearStart: 2006,
    yearEnd: 2020,
  },
  {
    brand: 'Lincoln',
    model: 'Nautilus',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Lincoln',
    model: 'Navigator',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Lincoln',
    model: 'Town Car',
    yearStart: 1998,
    yearEnd: 2011,
  },
  {
    brand: 'Lincoln',
    model: 'Zephyr',
    yearStart: 2006,
    yearEnd: 2006,
  },
  {
    brand: 'Lotus',
    model: 'Elise',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Lotus',
    model: 'Europa S',
    yearStart: 2005,
    yearEnd: 2010,
  },
  {
    brand: 'Lotus',
    model: 'Evora',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'Lotus',
    model: 'Exige',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Marussia',
    model: 'B1',
    yearStart: 2008,
    yearEnd: 2014,
  },
  {
    brand: 'Marussia',
    model: 'B2',
    yearStart: 2010,
    yearEnd: 2014,
  },
  {
    brand: 'Maserati',
    model: '3200 GT',
    yearStart: 1998,
    yearEnd: 2002,
  },
  {
    brand: 'Maserati',
    model: 'Ghibli',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Maserati',
    model: 'Gran Cabrio',
    yearStart: 2010,
    yearEnd: 2019,
  },
  {
    brand: 'Maserati',
    model: 'Gran Turismo',
    yearStart: 2007,
    yearEnd: 2019,
  },
  {
    brand: 'Maserati',
    model: 'Gran Turismo S',
    yearStart: 2008,
    yearEnd: 2012,
  },
  {
    brand: 'Maserati',
    model: 'Grecale',
    yearStart: 2022,
    yearEnd: '-',
  },
  {
    brand: 'Maserati',
    model: 'Levante',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Maserati',
    model: 'MC20',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Maserati',
    model: 'Quattroporte',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Maserati',
    model: 'Quattroporte S',
    yearStart: 2007,
    yearEnd: 2012,
  },
  {
    brand: 'Maybach',
    model: 57,
    yearStart: 2002,
    yearEnd: 2012,
  },
  {
    brand: 'Maybach',
    model: '57 S',
    yearStart: 2007,
    yearEnd: 2012,
  },
  {
    brand: 'Maybach',
    model: 62,
    yearStart: 2002,
    yearEnd: 2012,
  },
  {
    brand: 'Maybach',
    model: '62 S',
    yearStart: 2007,
    yearEnd: 2012,
  },
  {
    brand: 'Maybach',
    model: 'Landaulet',
    yearStart: 2003,
    yearEnd: 2012,
  },
  {
    brand: 'Mazda',
    model: 2,
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Mazda',
    model: 3,
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Mazda',
    model: 323,
    yearStart: 1998,
    yearEnd: 2003,
  },
  {
    brand: 'Mazda',
    model: 5,
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Mazda',
    model: 6,
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Mazda',
    model: 626,
    yearStart: 1997,
    yearEnd: 2001,
  },
  {
    brand: 'Mazda',
    model: 'B-Series',
    yearStart: 1999,
    yearEnd: 2006,
  },
  {
    brand: 'Mazda',
    model: 'BT-50',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Mazda',
    model: 'CX-3',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Mazda',
    model: 'CX-30',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Mazda',
    model: 'CX-30 EV',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Mazda',
    model: 'CX-4',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Mazda',
    model: 'CX-5',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Mazda',
    model: 'CX-60',
    yearStart: 2022,
    yearEnd: '-',
  },
  {
    brand: 'Mazda',
    model: 'CX-7',
    yearStart: 2006,
    yearEnd: 2012,
  },
  {
    brand: 'Mazda',
    model: 'CX-8',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Mazda',
    model: 'CX-9',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Mazda',
    model: 'MPV',
    yearStart: 1999,
    yearEnd: 2016,
  },
  {
    brand: 'Mazda',
    model: 'MX-30',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Mazda',
    model: 'MX-5',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Mazda',
    model: 'Premacy',
    yearStart: 1999,
    yearEnd: 2006,
  },
  {
    brand: 'Mazda',
    model: 'RX-7',
    yearStart: 1992,
    yearEnd: 2002,
  },
  {
    brand: 'Mazda',
    model: 'RX-8',
    yearStart: 2003,
    yearEnd: 2012,
  },
  {
    brand: 'Mazda',
    model: 'Tribute',
    yearStart: 2000,
    yearEnd: 2007,
  },
  {
    brand: 'McLaren',
    model: '540C',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'McLaren',
    model: '570S',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'McLaren',
    model: '600LT',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'McLaren',
    model: '650S',
    yearStart: 2014,
    yearEnd: 2017,
  },
  {
    brand: 'McLaren',
    model: '675LT',
    yearStart: 2015,
    yearEnd: 2017,
  },
  {
    brand: 'McLaren',
    model: '720S',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'McLaren',
    model: '720S Spider',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'McLaren',
    model: '765LT',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'McLaren',
    model: 'Artura',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'McLaren',
    model: 'MP4-12C',
    yearStart: 2011,
    yearEnd: 2014,
  },
  {
    brand: 'McLaren',
    model: 'P1',
    yearStart: 2013,
    yearEnd: 2016,
  },
  {
    brand: 'Mercedes',
    model: 'A-class',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'AMG GT',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'AMG GT 4-Door',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'B-class',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'C-class',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'C-class Sport Coupe',
    yearStart: 2001,
    yearEnd: 2007,
  },
  {
    brand: 'Mercedes',
    model: 'Citan',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'CL-class',
    yearStart: 1992,
    yearEnd: 2014,
  },
  {
    brand: 'Mercedes',
    model: 'CLA-class',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'CLC-class',
    yearStart: 2008,
    yearEnd: 2011,
  },
  {
    brand: 'Mercedes',
    model: 'CLK-class',
    yearStart: 1997,
    yearEnd: 2010,
  },
  {
    brand: 'Mercedes',
    model: 'CLS-class',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'E-class',
    yearStart: 1995,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'E-class Coupe',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'EQA',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'EQB',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'EQC',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'EQE',
    yearStart: 2022,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'EQE AMG',
    yearStart: 2022,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'EQS',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'EQS AMG',
    yearStart: 2022,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'EQV',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'G-class',
    yearStart: 1996,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'GL-class',
    yearStart: 2006,
    yearEnd: 2016,
  },
  {
    brand: 'Mercedes',
    model: 'GLA-class',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'GLA-class AMG',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'GLB-class',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'GLC-class',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'GLC-class AMG',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'GLC-class Coupe',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'GLE-class',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'GLE-class AMG',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'GLE-class Coupe',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'GLK-class',
    yearStart: 2008,
    yearEnd: 2015,
  },
  {
    brand: 'Mercedes',
    model: 'GLS-class',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'GLS-class AMG',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'M-class',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'R-class',
    yearStart: 2005,
    yearEnd: 2012,
  },
  {
    brand: 'Mercedes',
    model: 'S-class',
    yearStart: 1990,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'S-class Cabrio',
    yearStart: 2015,
    yearEnd: 2021,
  },
  {
    brand: 'Mercedes',
    model: 'S-class Coupe',
    yearStart: 2015,
    yearEnd: 2021,
  },
  {
    brand: 'Mercedes',
    model: 'SL-class',
    yearStart: 2001,
    yearEnd: 2021,
  },
  {
    brand: 'Mercedes',
    model: 'SL-Class AMG',
    yearStart: 2022,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'SLC-class',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'SLK-class',
    yearStart: 1996,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'SLR-class',
    yearStart: 2003,
    yearEnd: 2010,
  },
  {
    brand: 'Mercedes',
    model: 'SLS AMG',
    yearStart: 2010,
    yearEnd: 2014,
  },
  {
    brand: 'Mercedes',
    model: 'Sprinter',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'Vaneo',
    yearStart: 2001,
    yearEnd: 2006,
  },
  {
    brand: 'Mercedes',
    model: 'Viano',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Mercedes',
    model: 'Vito',
    yearStart: 1999,
    yearEnd: 2003,
  },
  {
    brand: 'Mercedes',
    model: 'X-class',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Mercury',
    model: 'Grand Marquis',
    yearStart: 2003,
    yearEnd: 2011,
  },
  {
    brand: 'Mercury',
    model: 'Mariner',
    yearStart: 2005,
    yearEnd: 2007,
  },
  {
    brand: 'Mercury',
    model: 'Milan',
    yearStart: 2006,
    yearEnd: 2011,
  },
  {
    brand: 'Mercury',
    model: 'Montego',
    yearStart: 2004,
    yearEnd: 2007,
  },
  {
    brand: 'Mercury',
    model: 'Monterey',
    yearStart: 1991,
    yearEnd: 2007,
  },
  {
    brand: 'Mercury',
    model: 'Mountaineer',
    yearStart: 2003,
    yearEnd: 2010,
  },
  {
    brand: 'Mercury',
    model: 'Sable',
    yearStart: 1995,
    yearEnd: 2005,
  },
  {
    brand: 'MG',
    model: 'Hector',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'MG',
    model: 'TF',
    yearStart: 2002,
    yearEnd: 2011,
  },
  {
    brand: 'MG',
    model: 'XPower SV',
    yearStart: 2003,
    yearEnd: 2008,
  },
  {
    brand: 'MG',
    model: 'ZR',
    yearStart: 2001,
    yearEnd: 2005,
  },
  {
    brand: 'MG',
    model: 'ZS',
    yearStart: 2001,
    yearEnd: 2005,
  },
  {
    brand: 'MG',
    model: 'ZS EV',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'MG',
    model: 'ZT',
    yearStart: 2001,
    yearEnd: 2005,
  },
  {
    brand: 'MG',
    model: 'ZT-T',
    yearStart: 2001,
    yearEnd: 2005,
  },
  {
    brand: 'Mini',
    model: 'Clubman',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Mini',
    model: 'Clubman S',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Mini',
    model: 'Clubvan',
    yearStart: 2012,
    yearEnd: 2014,
  },
  {
    brand: 'Mini',
    model: 'Cooper',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Mini',
    model: 'Cooper Cabrio',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Mini',
    model: 'Cooper S',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Mini',
    model: 'Cooper S Cabrio',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Mini',
    model: 'Cooper S Countryman All4',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'Mini',
    model: 'Countryman',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'Mini',
    model: 'One',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Mitsubishi',
    model: '3000 GT',
    yearStart: 1992,
    yearEnd: 2001,
  },
  {
    brand: 'Mitsubishi',
    model: 'ASX',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'Mitsubishi',
    model: 'Carisma',
    yearStart: 1995,
    yearEnd: 2004,
  },
  {
    brand: 'Mitsubishi',
    model: 'Colt',
    yearStart: 1995,
    yearEnd: 2012,
  },
  {
    brand: 'Mitsubishi',
    model: 'Dignity',
    yearStart: 2012,
    yearEnd: 2017,
  },
  {
    brand: 'Mitsubishi',
    model: 'Eclipse',
    yearStart: 1995,
    yearEnd: 2007,
  },
  {
    brand: 'Mitsubishi',
    model: 'Eclipse Cross',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Mitsubishi',
    model: 'Endeavor',
    yearStart: 2004,
    yearEnd: 2011,
  },
  {
    brand: 'Mitsubishi',
    model: 'Galant',
    yearStart: 1996,
    yearEnd: 2012,
  },
  {
    brand: 'Mitsubishi',
    model: 'Grandis',
    yearStart: 2003,
    yearEnd: 2011,
  },
  {
    brand: 'Mitsubishi',
    model: 'i-MiEV',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'Mitsubishi',
    model: 'L200',
    yearStart: 1996,
    yearEnd: '-',
  },
  {
    brand: 'Mitsubishi',
    model: 'Lancer',
    yearStart: 1996,
    yearEnd: '-',
  },
  {
    brand: 'Mitsubishi',
    model: 'Lancer Evo',
    yearStart: 2001,
    yearEnd: 2016,
  },
  {
    brand: 'Mitsubishi',
    model: 'Montero',
    yearStart: 1983,
    yearEnd: '-',
  },
  {
    brand: 'Mitsubishi',
    model: 'Montero Sport',
    yearStart: 1996,
    yearEnd: '-',
  },
  {
    brand: 'Mitsubishi',
    model: 'Mirage',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Mitsubishi',
    model: 'Outlander',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Mitsubishi',
    model: 'Outlander Sport',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'Mitsubishi',
    model: 'Outlander XL',
    yearStart: 2006,
    yearEnd: 2012,
  },
  {
    brand: 'Mitsubishi',
    model: 'Pajero',
    yearStart: 1990,
    yearEnd: '-',
  },
  {
    brand: 'Mitsubishi',
    model: 'Pajero Pinin',
    yearStart: 1999,
    yearEnd: 2007,
  },
  {
    brand: 'Mitsubishi',
    model: 'Pajero Sport',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Mitsubishi',
    model: 'Raider',
    yearStart: 2006,
    yearEnd: 2007,
  },
  {
    brand: 'Mitsubishi',
    model: 'Space Gear',
    yearStart: 1995,
    yearEnd: 2007,
  },
  {
    brand: 'Mitsubishi',
    model: 'Space Runner',
    yearStart: 1999,
    yearEnd: 2004,
  },
  {
    brand: 'Mitsubishi',
    model: 'Space Star',
    yearStart: 1998,
    yearEnd: 2004,
  },
  {
    brand: 'Mitsubishi',
    model: 'Xpander',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Mitsubishi',
    model: 'Zinger',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: '350Z',
    yearStart: 2002,
    yearEnd: 2009,
  },
  {
    brand: 'Nissan',
    model: '370Z',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Almera',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Almera Classic',
    yearStart: 2005,
    yearEnd: 2012,
  },
  {
    brand: 'Nissan',
    model: 'Almera Tino',
    yearStart: 2000,
    yearEnd: 2005,
  },
  {
    brand: 'Nissan',
    model: 'Altima',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Ariya',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Armada',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Bluebird Sylphy',
    yearStart: 2000,
    yearEnd: 2012,
  },
  {
    brand: 'Nissan',
    model: 'Frontier',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'GT-R',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Juke',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Leaf',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Maxima',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Micra',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Murano',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Navara',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Note',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'NP300',
    yearStart: 2008,
    yearEnd: 2013,
  },
  {
    brand: 'Nissan',
    model: 'Pathfinder',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Patrol',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Primera',
    yearStart: 1996,
    yearEnd: 2008,
  },
  {
    brand: 'Nissan',
    model: 'Qashqai',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Qashqai+2',
    yearStart: 2008,
    yearEnd: 2013,
  },
  {
    brand: 'Nissan',
    model: 'Quest',
    yearStart: 2003,
    yearEnd: 2017,
  },
  {
    brand: 'Nissan',
    model: 'Rogue',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Sentra',
    yearStart: 1999,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Skyline',
    yearStart: 2001,
    yearEnd: 2005,
  },
  {
    brand: 'Nissan',
    model: 'Sylphy',
    yearStart: 2012,
    yearEnd: 2020,
  },
  {
    brand: 'Nissan',
    model: 'Teana',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Terrano',
    yearStart: 1993,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Tiida',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Titan',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Titan XD',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'Versa',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'X-Trail',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Nissan',
    model: 'XTerra',
    yearStart: 2001,
    yearEnd: 2015,
  },
  {
    brand: 'Nissan',
    model: 'Z',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Opel',
    model: 'Adam',
    yearStart: 2013,
    yearEnd: 2020,
  },
  {
    brand: 'Opel',
    model: 'Agila',
    yearStart: 2000,
    yearEnd: 2014,
  },
  {
    brand: 'Opel',
    model: 'Ampera-e',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Opel',
    model: 'Antara',
    yearStart: 2006,
    yearEnd: 2015,
  },
  {
    brand: 'Opel',
    model: 'Astra',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Opel',
    model: 'Astra GTC',
    yearStart: 2011,
    yearEnd: 2018,
  },
  {
    brand: 'Opel',
    model: 'Astra OPC',
    yearStart: 2011,
    yearEnd: 2018,
  },
  {
    brand: 'Opel',
    model: 'Cascada',
    yearStart: 2013,
    yearEnd: 2019,
  },
  {
    brand: 'Opel',
    model: 'Combo',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Opel',
    model: 'Corsa',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Opel',
    model: 'Corsa OPC',
    yearStart: 2007,
    yearEnd: 2018,
  },
  {
    brand: 'Opel',
    model: 'Crossland',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Opel',
    model: 'Crossland X',
    yearStart: 2017,
    yearEnd: 2020,
  },
  {
    brand: 'Opel',
    model: 'Frontera',
    yearStart: 1998,
    yearEnd: 2004,
  },
  {
    brand: 'Opel',
    model: 'Grandland',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Opel',
    model: 'Grandland X',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Opel',
    model: 'Insignia',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Opel',
    model: 'Insignia OPC',
    yearStart: 2009,
    yearEnd: 2017,
  },
  {
    brand: 'Opel',
    model: 'Karl',
    yearStart: 2014,
    yearEnd: 2019,
  },
  {
    brand: 'Opel',
    model: 'Meriva',
    yearStart: 2003,
    yearEnd: 2017,
  },
  {
    brand: 'Opel',
    model: 'Mokka',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Opel',
    model: 'Omega',
    yearStart: 1994,
    yearEnd: 2004,
  },
  {
    brand: 'Opel',
    model: 'Signum',
    yearStart: 2003,
    yearEnd: 2008,
  },
  {
    brand: 'Opel',
    model: 'Speedster',
    yearStart: 2000,
    yearEnd: 2007,
  },
  {
    brand: 'Opel',
    model: 'Tigra',
    yearStart: 1994,
    yearEnd: 2009,
  },
  {
    brand: 'Opel',
    model: 'Vectra',
    yearStart: 1995,
    yearEnd: 2008,
  },
  {
    brand: 'Opel',
    model: 'Vivaro',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Opel',
    model: 'Zafira',
    yearStart: 1999,
    yearEnd: 2014,
  },
  {
    brand: 'Opel',
    model: 'Zafira Life',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Opel',
    model: 'Zafira Tourer',
    yearStart: 2012,
    yearEnd: 2020,
  },
  {
    brand: 'Peugeot',
    model: 1007,
    yearStart: 2004,
    yearEnd: 2009,
  },
  {
    brand: 'Peugeot',
    model: 107,
    yearStart: 2005,
    yearEnd: 2014,
  },
  {
    brand: 'Peugeot',
    model: 108,
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Peugeot',
    model: 2008,
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Peugeot',
    model: 206,
    yearStart: 1998,
    yearEnd: 2012,
  },
  {
    brand: 'Peugeot',
    model: 207,
    yearStart: 2006,
    yearEnd: 2014,
  },
  {
    brand: 'Peugeot',
    model: 208,
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Peugeot',
    model: 3008,
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'Peugeot',
    model: 301,
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Peugeot',
    model: 307,
    yearStart: 2000,
    yearEnd: 2011,
  },
  {
    brand: 'Peugeot',
    model: 308,
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Peugeot',
    model: 4007,
    yearStart: 2007,
    yearEnd: 2012,
  },
  {
    brand: 'Peugeot',
    model: 4008,
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Peugeot',
    model: 406,
    yearStart: 1995,
    yearEnd: 2004,
  },
  {
    brand: 'Peugeot',
    model: 407,
    yearStart: 2004,
    yearEnd: 2010,
  },
  {
    brand: 'Peugeot',
    model: 408,
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'Peugeot',
    model: 5008,
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'Peugeot',
    model: 508,
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Peugeot',
    model: 607,
    yearStart: 2000,
    yearEnd: 2010,
  },
  {
    brand: 'Peugeot',
    model: 807,
    yearStart: 2002,
    yearEnd: 2014,
  },
  {
    brand: 'Peugeot',
    model: 'Boxer',
    yearStart: 2008,
    yearEnd: 2014,
  },
  {
    brand: 'Peugeot',
    model: 'Expert',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Peugeot',
    model: 'Landtrek',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Peugeot',
    model: 'Manager',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Peugeot',
    model: 'Partner',
    yearStart: 1996,
    yearEnd: '-',
  },
  {
    brand: 'Peugeot',
    model: 'RCZ Sport',
    yearStart: 2010,
    yearEnd: 2015,
  },
  {
    brand: 'Peugeot',
    model: 'Rifter',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Peugeot',
    model: 'Traveller',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Plymouth',
    model: 'Road Runner',
    yearStart: 1968,
    yearEnd: 1970,
  },
  {
    brand: 'Pontiac',
    model: 'Aztec',
    yearStart: 2001,
    yearEnd: 2005,
  },
  {
    brand: 'Pontiac',
    model: 'Bonneville',
    yearStart: 1999,
    yearEnd: 2005,
  },
  {
    brand: 'Pontiac',
    model: 'Firebird',
    yearStart: 1993,
    yearEnd: 2002,
  },
  {
    brand: 'Pontiac',
    model: 'G5 Pursuit',
    yearStart: 2004,
    yearEnd: 2010,
  },
  {
    brand: 'Pontiac',
    model: 'G6',
    yearStart: 2004,
    yearEnd: 2010,
  },
  {
    brand: 'Pontiac',
    model: 'G8',
    yearStart: 2008,
    yearEnd: 2009,
  },
  {
    brand: 'Pontiac',
    model: 'Grand AM',
    yearStart: 1998,
    yearEnd: 2005,
  },
  {
    brand: 'Pontiac',
    model: 'Grand Prix',
    yearStart: 1996,
    yearEnd: 2008,
  },
  {
    brand: 'Pontiac',
    model: 'GTO',
    yearStart: 2004,
    yearEnd: 2006,
  },
  {
    brand: 'Pontiac',
    model: 'Montana',
    yearStart: 1997,
    yearEnd: 2009,
  },
  {
    brand: 'Pontiac',
    model: 'Solstice',
    yearStart: 2005,
    yearEnd: 2010,
  },
  {
    brand: 'Pontiac',
    model: 'Sunfire',
    yearStart: 1995,
    yearEnd: 2005,
  },
  {
    brand: 'Pontiac',
    model: 'Torrent',
    yearStart: 2005,
    yearEnd: 2009,
  },
  {
    brand: 'Pontiac',
    model: 'Vibe',
    yearStart: 2002,
    yearEnd: 2009,
  },
  {
    brand: 'Porsche',
    model: '718 Boxster',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Porsche',
    model: '718 Cayman',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Porsche',
    model: 911,
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Porsche',
    model: 'Boxster',
    yearStart: 1996,
    yearEnd: 2016,
  },
  {
    brand: 'Porsche',
    model: 'Cayenne',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Porsche',
    model: 'Cayman',
    yearStart: 2005,
    yearEnd: 2016,
  },
  {
    brand: 'Porsche',
    model: 'Macan',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Porsche',
    model: 'Panamera',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'Porsche',
    model: 'Taycan',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Ravon',
    model: 'Gentra',
    yearStart: 2015,
    yearEnd: 2019,
  },
  {
    brand: 'Renault',
    model: 'Alaskan',
    yearStart: 2016,
    yearEnd: 2020,
  },
  {
    brand: 'Renault',
    model: 'Arkana',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Avantime',
    yearStart: 2001,
    yearEnd: 2004,
  },
  {
    brand: 'Renault',
    model: 'Captur',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Clio',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Duster',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Duster Oroch',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Espace',
    yearStart: 1996,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Fluence',
    yearStart: 2010,
    yearEnd: 2019,
  },
  {
    brand: 'Renault',
    model: 'Grand Scenic',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Kadjar',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Kangoo',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Kaptur',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Kiger',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Koleos',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Laguna',
    yearStart: 1993,
    yearEnd: 2015,
  },
  {
    brand: 'Renault',
    model: 'Latitude',
    yearStart: 2010,
    yearEnd: 2018,
  },
  {
    brand: 'Renault',
    model: 'Logan',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Logan Stepway',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Master',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Megane',
    yearStart: 1996,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Modus',
    yearStart: 2004,
    yearEnd: 2012,
  },
  {
    brand: 'Renault',
    model: 'Sandero',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Sandero Stepway',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Scenic',
    yearStart: 1996,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Symbol',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Taliant',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Talisman',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Trafic',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Triber',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Twingo',
    yearStart: 1993,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Twizy',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Renault',
    model: 'Vel Satis',
    yearStart: 2002,
    yearEnd: 2009,
  },
  {
    brand: 'Renault',
    model: 'Wind',
    yearStart: 2010,
    yearEnd: 2013,
  },
  {
    brand: 'Renault',
    model: 'Zoe',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Rolls-Royce',
    model: 'Cullinan',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Rolls-Royce',
    model: 'Dawn',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Rolls-Royce',
    model: 'Ghost',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'Rolls-Royce',
    model: 'Phantom',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Rolls-Royce',
    model: 'Wraith',
    yearStart: 2013,
    yearEnd: '-',
  },
  {
    brand: 'Rover',
    model: 25,
    yearStart: 1999,
    yearEnd: 2008,
  },
  {
    brand: 'Rover',
    model: 400,
    yearStart: 1996,
    yearEnd: 2000,
  },
  {
    brand: 'Rover',
    model: 45,
    yearStart: 2000,
    yearEnd: 2008,
  },
  {
    brand: 'Rover',
    model: 600,
    yearStart: 1999,
    yearEnd: 2004,
  },
  {
    brand: 'Rover',
    model: 75,
    yearStart: 1999,
    yearEnd: 2005,
  },
  {
    brand: 'Rover',
    model: 'Streetwise',
    yearStart: 2003,
    yearEnd: 2005,
  },
  {
    brand: 'Saab',
    model: '9-2x',
    yearStart: 2004,
    yearEnd: 2007,
  },
  {
    brand: 'Saab',
    model: '9-3',
    yearStart: 2003,
    yearEnd: 2014,
  },
  {
    brand: 'Saab',
    model: '9-4x',
    yearStart: 2011,
    yearEnd: 2012,
  },
  {
    brand: 'Saab',
    model: '9-5',
    yearStart: 1997,
    yearEnd: 2012,
  },
  {
    brand: 'Saab',
    model: '9-7x',
    yearStart: 2005,
    yearEnd: 2009,
  },
  {
    brand: 'Saturn',
    model: 'Aura',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Saturn',
    model: 'Ion',
    yearStart: 2003,
    yearEnd: 2007,
  },
  {
    brand: 'Saturn',
    model: 'LW',
    yearStart: 1998,
    yearEnd: 2004,
  },
  {
    brand: 'Saturn',
    model: 'Outlook',
    yearStart: 2006,
    yearEnd: 2010,
  },
  {
    brand: 'Saturn',
    model: 'Sky',
    yearStart: 2007,
    yearEnd: 2007,
  },
  {
    brand: 'Saturn',
    model: 'Vue',
    yearStart: 2002,
    yearEnd: 2007,
  },
  {
    brand: 'Scion',
    model: 'FR-S',
    yearStart: 2012,
    yearEnd: 2016,
  },
  {
    brand: 'Scion',
    model: 'tC',
    yearStart: 2004,
    yearEnd: 2016,
  },
  {
    brand: 'Scion',
    model: 'xA',
    yearStart: 2003,
    yearEnd: 2007,
  },
  {
    brand: 'Scion',
    model: 'xB',
    yearStart: 2003,
    yearEnd: 2015,
  },
  {
    brand: 'Scion',
    model: 'xD',
    yearStart: 2007,
    yearEnd: 2014,
  },
  {
    brand: 'Seat',
    model: 'Alhambra',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Seat',
    model: 'Altea',
    yearStart: 2004,
    yearEnd: 2015,
  },
  {
    brand: 'Seat',
    model: 'Altea Freetrack',
    yearStart: 2007,
    yearEnd: 2015,
  },
  {
    brand: 'Seat',
    model: 'Altea XL',
    yearStart: 2007,
    yearEnd: 2015,
  },
  {
    brand: 'Seat',
    model: 'Arona',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Seat',
    model: 'Arosa',
    yearStart: 1997,
    yearEnd: 2006,
  },
  {
    brand: 'Seat',
    model: 'Ateca',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Seat',
    model: 'Cordoba',
    yearStart: 1999,
    yearEnd: 2009,
  },
  {
    brand: 'Seat',
    model: 'Exeo',
    yearStart: 2008,
    yearEnd: 2013,
  },
  {
    brand: 'Seat',
    model: 'Ibiza',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Seat',
    model: 'Leon',
    yearStart: 1999,
    yearEnd: '-',
  },
  {
    brand: 'Seat',
    model: 'Mii',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Seat',
    model: 'Tarraco',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Seat',
    model: 'Toledo',
    yearStart: 2004,
    yearEnd: 2018,
  },
  {
    brand: 'Skoda',
    model: 'Citigo',
    yearStart: 2011,
    yearEnd: 2020,
  },
  {
    brand: 'Skoda',
    model: 'Enyaq iV',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Skoda',
    model: 'Fabia',
    yearStart: 1999,
    yearEnd: '-',
  },
  {
    brand: 'Skoda',
    model: 'Felicia',
    yearStart: 1995,
    yearEnd: 2000,
  },
  {
    brand: 'Skoda',
    model: 'Kamiq',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Skoda',
    model: 'Karoq',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Skoda',
    model: 'Kodiaq',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Skoda',
    model: 'Octavia',
    yearStart: 1995,
    yearEnd: '-',
  },
  {
    brand: 'Skoda',
    model: 'Octavia Scout',
    yearStart: 2007,
    yearEnd: 2013,
  },
  {
    brand: 'Skoda',
    model: 'Octavia Tour',
    yearStart: 2006,
    yearEnd: 2013,
  },
  {
    brand: 'Skoda',
    model: 'Praktik',
    yearStart: 2007,
    yearEnd: 2015,
  },
  {
    brand: 'Skoda',
    model: 'Rapid',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Skoda',
    model: 'Rapid Spaceback (NH1)',
    yearStart: 2013,
    yearEnd: 2020,
  },
  {
    brand: 'Skoda',
    model: 'Roomster',
    yearStart: 2006,
    yearEnd: 2015,
  },
  {
    brand: 'Skoda',
    model: 'Scala',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Skoda',
    model: 'Superb',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Skoda',
    model: 'Yeti',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'Smart',
    model: 'Forfour',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'Smart',
    model: 'Fortwo',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Smart',
    model: 'Roadster',
    yearStart: 2003,
    yearEnd: 2012,
  },
  {
    brand: 'Ssang Yong',
    model: 'Actyon',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Ssang Yong',
    model: 'Actyon Sports',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Ssang Yong',
    model: 'Chairman',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Ssang Yong',
    model: 'Korando',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Ssang Yong',
    model: 'Kyron',
    yearStart: 2005,
    yearEnd: 2016,
  },
  {
    brand: 'Ssang Yong',
    model: 'Musso',
    yearStart: 1993,
    yearEnd: '-',
  },
  {
    brand: 'Ssang Yong',
    model: 'Musso Grand',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Ssang Yong',
    model: 'Musso Sport',
    yearStart: 2004,
    yearEnd: 2007,
  },
  {
    brand: 'Ssang Yong',
    model: 'Rexton',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Ssang Yong',
    model: 'Rexton Sports',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Ssang Yong',
    model: 'Rodius',
    yearStart: 2004,
    yearEnd: 2019,
  },
  {
    brand: 'Ssang Yong',
    model: 'Stavic',
    yearStart: 2013,
    yearEnd: 2019,
  },
  {
    brand: 'Ssang Yong',
    model: 'Tivoli',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Ssang Yong',
    model: 'Tivoli Grand',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Ssang Yong',
    model: 'XLV',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Subaru',
    model: 'Ascent',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Subaru',
    model: 'Baja',
    yearStart: 2002,
    yearEnd: 2007,
  },
  {
    brand: 'Subaru',
    model: 'BRZ',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Subaru',
    model: 'Crosstrack',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Subaru',
    model: 'Exiga',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Subaru',
    model: 'Forester',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Subaru',
    model: 'Impreza',
    yearStart: 1992,
    yearEnd: '-',
  },
  {
    brand: 'Subaru',
    model: 'Justy',
    yearStart: 1995,
    yearEnd: 2006,
  },
  {
    brand: 'Subaru',
    model: 'Legacy',
    yearStart: 1994,
    yearEnd: '-',
  },
  {
    brand: 'Subaru',
    model: 'Levorg',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Subaru',
    model: 'Outback',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Subaru',
    model: 'Traviq',
    yearStart: 2001,
    yearEnd: 2006,
  },
  {
    brand: 'Subaru',
    model: 'Tribeca',
    yearStart: 2005,
    yearEnd: 2014,
  },
  {
    brand: 'Subaru',
    model: 'WRX',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Subaru',
    model: 'XV',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Suzuki',
    model: 'Alto',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Suzuki',
    model: 'Baleno',
    yearStart: 1995,
    yearEnd: '-',
  },
  {
    brand: 'Suzuki',
    model: 'Celerio',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Suzuki',
    model: 'Ciaz',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Suzuki',
    model: 'Ertiga',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Suzuki',
    model: 'Grand Vitara',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Suzuki',
    model: 'Grand Vitara XL7',
    yearStart: 1998,
    yearEnd: 2014,
  },
  {
    brand: 'Suzuki',
    model: 'Ignis',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Suzuki',
    model: 'Jimny',
    yearStart: 1998,
    yearEnd: '-',
  },
  {
    brand: 'Suzuki',
    model: 'Kizashi',
    yearStart: 2009,
    yearEnd: 2015,
  },
  {
    brand: 'Suzuki',
    model: 'Liana',
    yearStart: 2002,
    yearEnd: 2007,
  },
  {
    brand: 'Suzuki',
    model: 'S-Presso',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Suzuki',
    model: 'Splash',
    yearStart: 2008,
    yearEnd: 2016,
  },
  {
    brand: 'Suzuki',
    model: 'Sidekick',
    yearStart: 1989,
    yearEnd: 1998,
  },
  {
    brand: 'Suzuki',
    model: 'Swift',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Suzuki',
    model: 'SX4',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Suzuki',
    model: 'Vitara',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Suzuki',
    model: 'Wagon R',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Suzuki',
    model: 'Wagon R+',
    yearStart: 2000,
    yearEnd: 2006,
  },
  {
    brand: 'Suzuki',
    model: 'XL6',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Suzuki',
    model: 'XL7',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Tesla',
    model: 'Model 3',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Tesla',
    model: 'Model S',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Tesla',
    model: 'Model X',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'Tesla',
    model: 'Model Y',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: '4Runner',
    yearStart: 1995,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Alphard',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Auris',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Avalon',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Avensis',
    yearStart: 1997,
    yearEnd: 2018,
  },
  {
    brand: 'Toyota',
    model: 'Avensis Verso',
    yearStart: 2001,
    yearEnd: 2010,
  },
  {
    brand: 'Toyota',
    model: 'Aygo',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Aygo X',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'C+pod',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'C-HR',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Caldina',
    yearStart: 2002,
    yearEnd: 2007,
  },
  {
    brand: 'Toyota',
    model: 'Camry',
    yearStart: 1996,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Celica',
    yearStart: 1999,
    yearEnd: 2006,
  },
  {
    brand: 'Toyota',
    model: 'Corolla',
    yearStart: 1995,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Corolla Cross',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Corolla Verso',
    yearStart: 2002,
    yearEnd: 2009,
  },
  {
    brand: 'Toyota',
    model: 'FJ Cruiser',
    yearStart: 2007,
    yearEnd: 2016,
  },
  {
    brand: 'Toyota',
    model: 'Fortuner',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'GT 86',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Hiace',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Highlander',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Hilux',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'iQ',
    yearStart: 2008,
    yearEnd: 2016,
  },
  {
    brand: 'Toyota',
    model: 'ist',
    yearStart: 2002,
    yearEnd: 2016,
  },
  {
    brand: 'Toyota',
    model: 'Land Cruiser',
    yearStart: 1990,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Land Cruiser Prado',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Mark II',
    yearStart: 2000,
    yearEnd: 2004,
  },
  {
    brand: 'Toyota',
    model: 'Mirai',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'MR2',
    yearStart: 1999,
    yearEnd: 2007,
  },
  {
    brand: 'Toyota',
    model: 'Picnic',
    yearStart: 1995,
    yearEnd: 2001,
  },
  {
    brand: 'Toyota',
    model: 'Previa',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Prius',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Prius Prime',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'RAV4',
    yearStart: 1994,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Rush',
    yearStart: 2006,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Sequoia',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Sienna',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Supra',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Tacoma',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Tundra',
    yearStart: 1999,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Venza',
    yearStart: 2008,
    yearEnd: 2016,
  },
  {
    brand: 'Toyota',
    model: 'Verso',
    yearStart: 2009,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Vitz',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Yaris',
    yearStart: 1999,
    yearEnd: '-',
  },
  {
    brand: 'Toyota',
    model: 'Yaris Verso',
    yearStart: 1999,
    yearEnd: 2005,
  },
  {
    brand: 'UAZ',
    model: 'Pickup',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'UAZ',
    model: 'Патриот',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'UAZ',
    model: 'Хантер',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'VAZ',
    model: '2101-2107',
    yearStart: 1976,
    yearEnd: 2012,
  },
  {
    brand: 'VAZ',
    model: '2108 – 2109 - 21099',
    yearStart: 1984,
    yearEnd: 2004,
  },
  {
    brand: 'VAZ',
    model: '2110 – 2111 – 2112',
    yearStart: 1996,
    yearEnd: 2014,
  },
  {
    brand: 'VAZ',
    model: '2113 -2114 – 2115',
    yearStart: 1997,
    yearEnd: 2015,
  },
  {
    brand: 'VAZ',
    model: '4x4 Urban',
    yearStart: 2014,
    yearEnd: '-',
  },
  {
    brand: 'VAZ',
    model: 'Granta',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'VAZ',
    model: 'Granta Cross',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'VAZ',
    model: 'Largus',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'VAZ',
    model: 'Largus Cross',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'VAZ',
    model: 'Niva Legend',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'VAZ',
    model: 'Niva Travel',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'VAZ',
    model: 'Vesta Cross',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'VAZ',
    model: 'Vesta Sport',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'VAZ',
    model: 'Vesta SW',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'VAZ',
    model: 'XRay',
    yearStart: 2016,
    yearEnd: '-',
  },
  {
    brand: 'VAZ',
    model: 'XRay Cross',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'VAZ',
    model: 'Веста',
    yearStart: 2015,
    yearEnd: '-',
  },
  {
    brand: 'VAZ',
    model: 'Калина',
    yearStart: 2004,
    yearEnd: 2013,
  },
  {
    brand: 'VAZ',
    model: 'Нива 4X4',
    yearStart: 1977,
    yearEnd: '-',
  },
  {
    brand: 'VAZ',
    model: 'Ока',
    yearStart: 1988,
    yearEnd: 2008,
  },
  {
    brand: 'VAZ',
    model: 'Приора',
    yearStart: 2007,
    yearEnd: 2018,
  },
  {
    brand: 'Volkswagen',
    model: 'Amarok',
    yearStart: 2010,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'Arteon',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'Beetle',
    yearStart: 2011,
    yearEnd: 2019,
  },
  {
    brand: 'Volkswagen',
    model: 'Bora',
    yearStart: 1998,
    yearEnd: 2005,
  },
  {
    brand: 'Volkswagen',
    model: 'Caddy',
    yearStart: 2004,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'CC',
    yearStart: 2012,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'Crafter',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'CrossGolf',
    yearStart: 2007,
    yearEnd: 2009,
  },
  {
    brand: 'Volkswagen',
    model: 'CrossPolo',
    yearStart: 2006,
    yearEnd: 2009,
  },
  {
    brand: 'Volkswagen',
    model: 'CrossTouran',
    yearStart: 2007,
    yearEnd: 2015,
  },
  {
    brand: 'Volkswagen',
    model: 'Eos',
    yearStart: 2006,
    yearEnd: 2015,
  },
  {
    brand: 'Volkswagen',
    model: 'Fox',
    yearStart: 2005,
    yearEnd: 2011,
  },
  {
    brand: 'Volkswagen',
    model: 'Golf',
    yearStart: 1991,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'ID.3',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'ID.4',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'ID.4 X',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'Jetta',
    yearStart: 2005,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'Lupo',
    yearStart: 1998,
    yearEnd: 2005,
  },
  {
    brand: 'Volkswagen',
    model: 'Multivan',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'New Beetle',
    yearStart: 1998,
    yearEnd: 2007,
  },
  {
    brand: 'Volkswagen',
    model: 'Passat',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'Passat CC',
    yearStart: 2008,
    yearEnd: 2012,
  },
  {
    brand: 'Volkswagen',
    model: 'Phaeton',
    yearStart: 2002,
    yearEnd: 2016,
  },
  {
    brand: 'Volkswagen',
    model: 'Pointer',
    yearStart: 1993,
    yearEnd: 1997,
  },
  {
    brand: 'Volkswagen',
    model: 'Polo',
    yearStart: 2001,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'Routan',
    yearStart: 2008,
    yearEnd: 2014,
  },
  {
    brand: 'Volkswagen',
    model: 'Scirocco',
    yearStart: 2008,
    yearEnd: 2017,
  },
  {
    brand: 'Volkswagen',
    model: 'Sharan',
    yearStart: 1995,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'T-Cross',
    yearStart: 2018,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'T-Roc',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'Taos',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'Teramont',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'Teramont X',
    yearStart: 2019,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'Tiguan',
    yearStart: 2007,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'Tiguan X',
    yearStart: 2020,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'Touareg',
    yearStart: 2002,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'Touran',
    yearStart: 2003,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'Transporter',
    yearStart: 1990,
    yearEnd: '-',
  },
  {
    brand: 'Volkswagen',
    model: 'Up',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Volvo',
    model: 'C30',
    yearStart: 2007,
    yearEnd: 2013,
  },
  {
    brand: 'Volvo',
    model: 'C40',
    yearStart: 2021,
    yearEnd: '-',
  },
  {
    brand: 'Volvo',
    model: 'C70',
    yearStart: 2006,
    yearEnd: 2013,
  },
  {
    brand: 'Volvo',
    model: 'C70 Convertible',
    yearStart: 1997,
    yearEnd: 2006,
  },
  {
    brand: 'Volvo',
    model: 'C70 Coupe',
    yearStart: 1996,
    yearEnd: 2007,
  },
  {
    brand: 'Volvo',
    model: 'S40',
    yearStart: 1995,
    yearEnd: 2012,
  },
  {
    brand: 'Volvo',
    model: 'S60',
    yearStart: 2000,
    yearEnd: '-',
  },
  {
    brand: 'Volvo',
    model: 'S70',
    yearStart: 1996,
    yearEnd: 2000,
  },
  {
    brand: 'Volvo',
    model: 'S80',
    yearStart: 1998,
    yearEnd: 2016,
  },
  {
    brand: 'Volvo',
    model: 'S90',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Volvo',
    model: 'V40',
    yearStart: 1995,
    yearEnd: '-',
  },
  {
    brand: 'Volvo',
    model: 'V50',
    yearStart: 2004,
    yearEnd: 2012,
  },
  {
    brand: 'Volvo',
    model: 'V60',
    yearStart: 2011,
    yearEnd: '-',
  },
  {
    brand: 'Volvo',
    model: 'V70',
    yearStart: 1996,
    yearEnd: '-',
  },
  {
    brand: 'Volvo',
    model: 'V90',
    yearStart: 1997,
    yearEnd: '-',
  },
  {
    brand: 'Volvo',
    model: 'XC40',
    yearStart: 2017,
    yearEnd: '-',
  },
  {
    brand: 'Volvo',
    model: 'XC60',
    yearStart: 2008,
    yearEnd: '-',
  },
  {
    brand: 'Volvo',
    model: 'XC70',
    yearStart: 2001,
    yearEnd: 2016,
  },
  {
    brand: 'Volvo',
    model: 'XC90',
    yearStart: 2002,
    yearEnd: '-',
  },
];
