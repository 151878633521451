import React from 'react';

import { useTranslation } from 'react-i18next';

import BadgeText from './badgeText';
import { reservationStatus, reservationStatusToColorSheme } from '../../util/car-utils';

export default function ReservationBadge({ reservation, w }) {
  const { t } = useTranslation();

  if (reservation?.isAdditionalPaymentToPay) {
    return <BadgeText colorScheme={'warning'}>{t('reservationStatus.' + reservationStatus.waitingPayment)}</BadgeText>;
  }

  return (
    <BadgeText colorScheme={reservationStatusToColorSheme(reservation?.status)}>{t('reservationStatus.' + reservation?.status)}</BadgeText>
  );
}
