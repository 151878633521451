import React, { useTransition } from 'react';

import { Box, Button, Text } from '@gluestack-ui/themed';
import { View } from '@gluestack-ui/themed';
import { ButtonText } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import Layout from '../../shared/components/layout/layout';
import PlaceHolder from '../../shared/components/placeHolder/placeHolder';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import imagesUrl from '../../shared/themes/imagesUrl';

export default function NotFound() {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const maxWidth = useMaxWidth();
  return (
    <Layout title={t('screen.notFound')} description={t('notFoundScreen.title')}>
      <Box my={'$8'} mx={'$2'} maxWidth={maxWidth > 700 ? 700 : maxWidth} alignSelf="center">
        <PlaceHolder
          image={{ uri: imagesUrl.snow }}
          title={t('screen.notFound')}
          text={t('notFoundScreen.title')}
          element={
            <Button variant={'outline'} onPress={() => (navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Search'))}>
              <ButtonText>{t('notFoundScreen.goHome')}</ButtonText>
            </Button>
          }
        />
      </Box>
    </Layout>
  );
}
