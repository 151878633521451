import React from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, Heading, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import TimeAgo from 'react-native-timeago';

import { resizedImage } from '../../util/image-utils';
import Image from '../image/image';
import InfoExtender from '../info/infoExtender';
import Rating from '../rating/rating';

function ReviewCard({ review }) {
  const navigation = useNavigation();

  return (
    <TouchableOpacity onPress={() => navigation.navigate('PublicProfileScreen', { userId: review?.userId })}>
      <VStack space="sm">
        <HStack space={'md'} justifyContent={'space-between'}>
          <HStack space={'md'} maxWidth={'50%'}>
            <Image source={{ uri: resizedImage(review.userPhotoURL) }} size={'xs'} rounded={'$full'} />
            <VStack>
              <Heading size={'h4'}>{review.userName}</Heading>
              <Text variant={'grey'} size={'secondary'}>
                <TimeAgo time={new Date(review.createdAt.seconds * 1000)} />
              </Text>
            </VStack>
          </HStack>
          <Rating size={'sm'} space={'xs'} value={review.rating} readOnly={true} />
        </HStack>
        <Box>
          <InfoExtender color={'$light600'} object={review} field={'text'} loading={false} limit={300} showHeader={false} />
        </Box>
      </VStack>
    </TouchableOpacity>
  );
}

export default ReviewCard;
