import React, { useCallback, useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Heading, HStack, VStack } from '@gluestack-ui/themed';
import { useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { firebaseApp } from '../../../services/firebase/firebaseFactory';
import { cleanFirestoreResult } from '../../../util/firestore-utils';
import { resizedImage } from '../../../util/image-utils';
import { errorHandler } from '../../errorHandler/errorHander';
import Image from '../../image/image';
import SmallCard from '../../pressable/smallCard';
import UnderLineButton from '../../pressable/underLineButton';
import Skeleton from '../../skeleton/skeleton';

export default function MyProfileCardById({ userId, onPress, isDisable }) {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const { t } = useTranslation();

  useFocusEffect(
    useCallback(() => {
      console.debug('Effect: refresh user', userId);
      if (userId) {
        setLoading(true);
        firebaseApp
          .firestore()
          .collection('profiles')
          .doc(userId)
          .get()
          .then(cleanFirestoreResult)
          .then((res) => {
            setProfile(res);
            setLoading(false);
          })
          .catch((e) => {
            errorHandler(e, true);
            setLoading(false);
          });
      }
    }, [userId]),
  );

  if (loading) {
    return (
      <HStack justifyContent={'space-between'} alignItems={'center'} w={'100%'}>
        <HStack space={'md'}>
          <Skeleton h={'$16'} w={'$16'} rounded={'$full'} />
          <VStack justifyContent={'center'} space={'sm'}>
            <Skeleton.Text lines={1} w={100} />
            <Skeleton.Text lines={1} w={200} />
          </VStack>
        </HStack>
      </HStack>
    );
  }

  return (
    <TouchableOpacity onPress={onPress} disabled={isDisable || !onPress} key={profile?.photoURL}>
      <VStack space={'sm'} rounded={'$md'} borderWidth={'$1'} borderColor={'$transparent'} p={'$1'}>
        <HStack justifyContent={'space-between'} alignItems={'center'}>
          <HStack space={'md'} alignItems={'center'} maxWidth={'90%'}>
            <Image source={{ uri: resizedImage(profile?.photoURL) }} size={'md'} rounded={'$full'} />
            <VStack justifyContent={'center'} space={'xs'} w={'70%'}>
              <Heading size={'h4'}>{profile?.name ?? `${profile?.firstName} ${profile?.lastName}`}</Heading>
              <UnderLineButton hideLeftIcon={true} text={t('profile.seeMyProfile')} onPress={onPress} />
            </VStack>
          </HStack>
        </HStack>
      </VStack>
    </TouchableOpacity>
  );
}
