import React, { useEffect, useMemo, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { View } from '@gluestack-ui/themed';
import { useHeaderHeight } from '@react-navigation/elements';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useIsDrawer } from '../../provider/isDrawerProvider';
import { Metrics } from '../../themes';
import { IS_WEB } from '../../util/device-utils';
import MetaTag from '../web/metaTag';

export default function Layout(props) {
  const { title, description, image, isBottomBar } = props;

  const [firstRender, setFirstRender] = useState(true);
  const { width } = useWindowDimensions();

  useEffect(() => {
    setFirstRender(false);
  }, []);

  const headerHeight = useHeaderHeight();
  const { height } = useWindowDimensions();
  const insert = useSafeAreaInsets();
  const isMobile = !useIsDrawer();

  const viewHeight = useMemo(() => {
    return height - headerHeight - insert.bottom - (isBottomBar && isMobile ? Metrics.bottomTabSize : 0);
  }, [headerHeight, height, insert.bottom, isBottomBar, isMobile]);

  if (firstRender) {
    return null;
  }

  return (
    <View flex={1} h={viewHeight} w={width} alignSelf={'center'} {...props}>
      <MetaTag title={title} description={description} image={image} />
      <View flex={IS_WEB ? undefined : 1} h={viewHeight}>
        {props.children}
      </View>
    </View>
  );
}
