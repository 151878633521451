import React from 'react';

import { Ionicons } from '@expo/vector-icons';
import { Heading, HStack, Icon, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Badge from '../../../shared/components/badge/badge';
import CarBigCard from '../../../shared/components/entities/car/carBigCard';

const nextSteps = [
  {
    icon: 'checkmark-circle-outline',
    titleTranslation: 'addCar.verifyCarTitle',
    subTitleTranslation: 'addCar.verifyCarDescription',
  },
  {
    icon: 'card-outline',
    titleTranslation: 'addCar.setUpPaymentTitle',
    subTitleTranslation: 'addCar.setUpPaymentDescription',
  },
  {
    icon: 'pencil-outline',
    titleTranslation: 'addCar.adjustSettingsTitle',
    subTitleTranslation: 'addCar.adjustSettingsDescription',
  },
];

function CarPreviewSummary() {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const car = useSelector((state) => state.cars.carAdding);
  const currency = useSelector((state) => state.users.currency);

  return (
    <VStack space={'md'}>
      <Text mb={'$4'} variant={'grey'} size={'secondary'}>
        {t('addCar.previewDescription')}
      </Text>
      <CarBigCard
        car={car}
        currency={currency}
        onPress={() => null}
        badge={<Badge colorScheme={'light'}>{t('addCar.showPreview')}</Badge>}
      />
      <Heading>{t('addCar.whatNext')}</Heading>
      {nextSteps.map((nextStep) => (
        <HStack space={'md'} key={nextStep.titleTranslation}>
          <Icon as={Ionicons} name={nextStep.icon} size={'xl'} color={'$black'} />
          <VStack space={'sm'}>
            <Heading size={'h4'}>{t(nextStep.titleTranslation)}</Heading>
            <Text>{t(nextStep.subTitleTranslation)}</Text>
          </VStack>
        </HStack>
      ))}
    </VStack>
  );
}

export default CarPreviewSummary;
