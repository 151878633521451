import React, { useEffect, useState } from 'react';
import { ImageBackground, TouchableOpacity } from 'react-native';

import { Box, Button, ButtonSpinner, ButtonText, Heading, Spinner, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { getAccountLink, getLoginLink } from '../../../services/firebase/function-firebase';
import { oneAccountListener } from '../../../services/firebase/payment-firebase';
import { Images } from '../../../themes';
import imagesUrl from '../../../themes/imagesUrl';
import { cleanFirestoreResult } from '../../../util/firestore-utils';
import ActionSheet from '../../actionSheet/actionSheet';
import RegionPicker from '../../countryPicker/countryPicker';
import { errorHandler } from '../../errorHandler/errorHander';
import InfoCard from '../../infoCard/infoCard';

export default function PaymentAccountCard({ userId }) {
  const [account, setAccount] = useState(null);
  const [loadingAccount, setLoadingAccount] = useState(true);
  const [accountLinkLoading, setAccountLinkLoading] = useState(false);
  const [loginLinkLoading, setLoginLinkLoading] = useState(false);
  const [countryPickerOpen, setCountryPickerOpen] = useState(false);
  const [countryCode, setCountryCode] = useState(undefined);

  const { t } = useTranslation();
  const navigation = useNavigation();

  function onSnapshot(snapshot) {
    setAccount(cleanFirestoreResult(snapshot));
    setLoadingAccount(false);
  }

  function onSnapshotError(e) {
    errorHandler(e, true);
    setLoadingAccount(false);
  }

  useEffect(() => {
    // it normal it trigger each time because it in DEV mode
    if (userId) {
      setLoadingAccount(true);
      const subscriber = oneAccountListener(userId, onSnapshot, onSnapshotError);
      return subscriber;
    }
  }, [userId]);

  function goToAccountLink() {
    setCountryPickerOpen(false);
    setAccountLinkLoading(true);
    getAccountLink(countryCode)
      .then((res) => {
        navigation.navigate('CustomWebView', { url: res.url, title: t('payment.dashboard') });
        setAccountLinkLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setAccountLinkLoading(false);
      });
  }

  function confirmCountry() {
    if (account?.id) {
      goToAccountLink();
    } else {
      setCountryPickerOpen(true);
    }
  }

  function goToLoginLink() {
    setLoginLinkLoading(true);
    getLoginLink()
      .then((res) => {
        navigation.navigate('CustomWebView', { url: res.url, title: t('payment.dashboard') });
        setLoginLinkLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoginLinkLoading(false);
      });
  }

  function onPress() {
    if (account?.details_submitted) {
      goToLoginLink();
    } else {
      confirmCountry();
      //goToAccountLink();
    }
  }

  function returnContentFromAccount(setUpContent, waitingContent, readyContent) {
    if (account?.charges_enabled === undefined) {
      return setUpContent;
    } else if (account?.charges_enabled === false) {
      return waitingContent;
    } else {
      return readyContent;
    }
  }

  return (
    <>
      <Box variant={'cardContainer'} rounded={'$lg'} overflow={'hidden'}>
        <TouchableOpacity disabled={accountLinkLoading || loginLinkLoading || loadingAccount} onPress={onPress}>
          <ImageBackground justifyContent={'center'} source={{ uri: imagesUrl.bgCardPayment }}>
            {(accountLinkLoading || loginLinkLoading) && (
              <Box variant={'absoluteMidle'} zIndex={2} justifyContent={'center'}>
                <Spinner size={100} color={'$white'} />
              </Box>
            )}
            <Box zIndex={1} m={'$4'}>
              <VStack space={'xs'}>
                <Heading size={'h3'} color={'$white'}>
                  {returnContentFromAccount(
                    t('payment.seeYourPaymentSetUpTitle'),
                    t('payment.seeYourPaymentWaitingTitle'),
                    t('payment.seeYourPaymentTitle'),
                  )}
                </Heading>
                <Text color={'$light200'} size={'secondary'} mr={'$12'}>
                  {returnContentFromAccount(
                    t('payment.seeYourPaymentSetUpSubTitle'),
                    t('payment.seeYourPaymentWaitingSubTitle'),
                    t('payment.seeYourPaymentDashboardSubTitle'),
                  )}
                </Text>
              </VStack>
            </Box>
            <Box zIndex={1} m={'$4'}>
              <Button
                size="sm"
                w={'60%'}
                onPress={onPress}
                isDisabled={accountLinkLoading || loginLinkLoading || loadingAccount}
                p={'$1'}
                variant={'outline'}
                borderColor={'$light200'}>
                <ButtonText color={'$light200'}>
                  {returnContentFromAccount(t('payment.getStarted'), t('payment.waiting'), t('payment.goToDashboard'))}
                </ButtonText>
              </Button>
            </Box>
          </ImageBackground>
        </TouchableOpacity>
      </Box>
      <ActionSheet isOpen={countryPickerOpen} setOpen={setCountryPickerOpen} headerText={t('payment.selectCountry')}>
        <VStack space={'lg'} mx={'$screenMargin'}>
          <InfoCard text={t('payment.infoCountry')} />
          <RegionPicker
            title={t('login.country')}
            defaultCountryCode={countryCode}
            placeHolder={t('login.countryPlaceHolder')}
            setCountryCode={(value) => setCountryCode(value)}
          />
          <Button isDisabled={accountLinkLoading || !countryCode} onPress={goToAccountLink}>
            {accountLinkLoading && <ButtonSpinner mr="$1" />}
            <ButtonText>{t('payment.createPaymentAccount')}</ButtonText>
          </Button>
        </VStack>
      </ActionSheet>
    </>
  );
}
