import React, { useEffect, useState } from 'react';

import { Box, Center, FormControl, Input, InputField, Text, VStack } from '@gluestack-ui/themed';
import { InputSlot } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { atoi } from '../../util/conversion';

export default function InputDiscountCard({ numberValue, onChangeValue, placeHolder, label, info, leftElement, rightElement }) {
  const [text, setText] = useState('');
  const [error, setError] = useState(undefined);

  const { t } = useTranslation();

  useEffect(() => {
    console.debug('Effect refresh dolarPrice');
    setText(numberValue.toString());
  }, [numberValue]);

  function changeInput(newValue) {
    setText(newValue);
    const newNumber = atoi(newValue, -1);
    if (newNumber >= 0) {
      if (newNumber < 100) {
        onChangeValue(newNumber);
        setError(undefined);
      } else {
        setError(t('error.moreThan100Discount'));
      }
    } else if (newValue?.length === 0) {
      setError(t('error.addDiscount'));
    } else {
      setError(t('error.addGoodNumberFormat'));
    }
  }

  return (
    <Box variant={'cardGrey'} mt={'$2'}>
      <VStack space={'sm'} alignItems={'center'} m={'$4'}>
        <VStack alignItems={'center'}>
          <Input size={'xl'}>
            <InputField
              keyboardType={'number-pad'}
              textAlign={'center'}
              value={text}
              placeholder={placeHolder}
              onChangeText={changeInput}
            />
            <InputSlot pr="$3">{rightElement}</InputSlot>
          </Input>
          {error !== undefined && (
            <Text alignSelf={'center'} size={'secondary'} color={'$error500'}>
              {error}
            </Text>
          )}
        </VStack>

        <Text alignSelf={'center'} variant={'grey'} size={'secondary'}>
          {label}
        </Text>
        <Text alignSelf={'center'} mt={'$2'} size={'secondary'} textAlign={'center'} maxWidth={'80%'}>
          {info}
        </Text>
      </VStack>
    </Box>
  );
}
