import AddCarPhoto from '../../screen/addCarFlow/steps/addCarPhoto';
import AdsCompliance from '../../screen/addCarFlow/steps/adsCompliance';
import AeraOfActivity from '../../screen/addCarFlow/steps/aeraOfActivity';
import BookingSetting from '../../screen/addCarFlow/steps/bookingSetting';
import CarDescription from '../../screen/addCarFlow/steps/carDescription';
import CarGuidelines from '../../screen/addCarFlow/steps/carGuidelines';
import CarPreviewSummary from '../../screen/addCarFlow/steps/carPreviewSummary';
import DeliveryLocation from '../../screen/addCarFlow/steps/deliveryLocation';
import EarnByRenting from '../../screen/addCarFlow/steps/EarnByRenting';
import ExtraOption from '../../screen/addCarFlow/steps/extraOption';
import MoreFeature from '../../screen/addCarFlow/steps/moreFeature';
import NumberPlate from '../../screen/addCarFlow/steps/numberPlate';
import SeasonalPrice from '../../screen/addCarFlow/steps/seasonalPrice';
import SetYourDiscountOffer from '../../screen/addCarFlow/steps/setYourDiscountOffer';
import SetYourPrice from '../../screen/addCarFlow/steps/setYourPrice';
import SpecOfCar from '../../screen/addCarFlow/steps/specOfCar';
import Step1 from '../../screen/addCarFlow/steps/step1';
import Step2 from '../../screen/addCarFlow/steps/step2';
import Step3 from '../../screen/addCarFlow/steps/step3';
import TypeOfCar from '../../screen/addCarFlow/steps/typeOfCar';

export const addCarStep = [
  {
    titleToTranslate: 'addCar.adsCompliance',
    component: () => <AdsCompliance />,
    validator: () => true,
    atCreation: false,
    atEdit: true,
    nameToTranslate: 'addCar.adsCompliance',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'addCar.earnByRenting',
    component: () => <EarnByRenting />,
    validator: (car) => true,
    atCreation: true,
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'addCar.step1',
    component: () => <Step1 />,
    validator: (car) => true,
    atCreation: true,
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'addCar.tellUsAboutYourCar',
    component: () => <TypeOfCar />,
    validator: (car) => (car?.brand && car?.model && car?.year ? true : false),
    atCreation: true,
    atEdit: true,
    nameToTranslate: 'ownerCar.typeOfCar',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'addCar.specOfCar',
    component: () => <SpecOfCar />,
    validator: (car) => (car?.seats && car?.doors && car?.transmission && car?.fuel && car?.mileage ? true : false),
    atCreation: true,
    atEdit: true,
    nameToTranslate: 'ownerCar.specOfCar',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'addCar.addMoreFeatures',
    component: () => <MoreFeature />,
    validator: (car) => (car?.features ? true : false),
    atCreation: true,
    atEdit: true,
    nameToTranslate: 'ownerCar.option',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'addCar.carExtraOptionTitle',
    component: () => <ExtraOption />,
    validator: () => true,
    atCreation: false,
    atEdit: true,
    nameToTranslate: 'addCar.carExtraOption',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'addCar.numberPlate',
    component: () => <NumberPlate />,
    validator: (car) => (car?.numberPlate ? true : false),
    atCreation: true,
    atEdit: true,
    nameToTranslate: 'ownerCar.numberPlate',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'addCar.aeraOfActivity',
    component: () => <AeraOfActivity />,
    validator: (car) => (car?._geoloc && car?.placeId ? true : false),
    atCreation: true,
    atEdit: true,
    nameToTranslate: 'ownerCar.aeraOfActivity',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'addCar.deliveryLocation',
    component: () => <DeliveryLocation />,
    validator: (car) => true,
    atCreation: true,
    atEdit: true,
    nameToTranslate: 'ownerCar.deliveryLocation',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'addCar.step2',
    component: () => <Step2 />,
    validator: (car) => true,
    atCreation: true,
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'addCar.addCarPhoto',
    component: () => <AddCarPhoto />,
    validator: (car) => (car?.images && car?.images.length >= 3 ? true : false),
    atCreation: true,
    atEdit: true,
    nameToTranslate: 'ownerCar.photo',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'addCar.addCarDescription',
    component: () => <CarDescription />,
    validator: (car) => (car?.originalContent?.carDescription ? true : false),
    atCreation: true,
    atEdit: true,
    nameToTranslate: 'ownerCar.description',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'addCar.addCarGuidelines',
    component: () => <CarGuidelines />,
    validator: (car) => (car?.originalContent?.carGuidelines ? true : false),
    atCreation: true,
    atEdit: true,
    nameToTranslate: 'ownerCar.guideline',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'addCar.setYourPrice',
    component: () => <SetYourPrice />,
    validator: (car) => (car?.priceData?.length > 0 ? true : false),
    atCreation: true,
    atEdit: false,
    nameToTranslate: 'ownerCar.price',
    buttonTextTranslate: 'global.next',
  },

  {
    titleToTranslate: 'addCar.setSeasonalPrice',
    component: () => <SeasonalPrice />,
    validator: (car) => (car?.priceData?.length > 0 ? true : false),
    atCreation: true,
    atEdit: true,
    full: true,
    nameToTranslate: 'ownerCar.price',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'addCar.step3',
    component: () => <Step3 />,
    validator: (car) => true,
    atCreation: true,
    buttonTextTranslate: 'global.preview',
  },
  {
    titleToTranslate: 'addCar.preview',
    component: () => <CarPreviewSummary />,
    validator: (car) => true,
    full: false,
    atEdit: false,
    atCreation: true,
    buttonTextTranslate: 'global.finishCreate',
  },
  {
    titleToTranslate: 'addCar.discountOfferTitle',
    component: () => <SetYourDiscountOffer />,
    validator: (car) => (typeof car?.discountPerWeek === 'number' && typeof car?.discountPerMonth === 'number' ? true : false),
    atCreation: false,
    atEdit: true,
    nameToTranslate: 'addCar.discountOffer',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'addCar.bookingSetting',
    component: () => <BookingSetting />,
    validator: (car) =>
      car?.availableToHour !== undefined &&
      car?.availableFromHour !== undefined &&
      car?.availableToMinute !== undefined &&
      car?.availableFromMinute !== undefined
        ? true
        : false,
    atCreation: false,
    atEdit: true,
    nameToTranslate: 'addCar.bookingSetting',
    buttonTextTranslate: 'global.next',
  },
];

export function checkCarNeedAction(car) {
  let res = false;
  addCarStep
    .filter((s) => s.atEdit === true)
    .forEach((step) => {
      if (!step.validator(car)) {
        res = true;
      }
    });
  return res;
}
