import React from 'react';

import { Divider, HStack, VStack } from '@gluestack-ui/themed';

import Skeleton from './skeleton';
import { Metrics } from '../../themes';

export default function CarRentalCardSkeleton() {
  return (
    <VStack space={'md'} rounded={'$xl'} borderWidth={'$1'} borderColor="$light200">
      <Skeleton h={Metrics.images.xxlarge} w={'$full'} rounded="$lg" />
      <Skeleton.Text mx={'$screenMargin'} lines={2} w={150} />
      <Divider />
      <HStack space={'sm'} justifyContent={'space-between'} mx={'$screenMargin'} my={'$4'}>
        <Skeleton.Text lines={2} w={100} />
        <Divider orientation="vertical" />
        <Skeleton.Text lines={1} w={100} />
      </HStack>
    </VStack>
  );
}
