import firebase from 'firebase/compat/app';

// Optionally import the services that you want to use
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';
import 'firebase/compat/app-check';
import appConfig from '../../../config/app-config';

// Initialize Firebase
const firebaseConfigPreProd = {
  apiKey: 'AIzaSyBArcEgej7ir6Uv2vQMf-Vjvlvl7W1UzOo',
  authDomain: 'preprod-flyncar-app.firebaseapp.com',
  databaseURL: 'https://preprod-flyncar-app-default-rtdb.firebaseio.com',
  projectId: 'preprod-flyncar-app',
  storageBucket: 'assets-preprod.flyandcar.com',
  //storageBucket: "preprod-flyncar-app.appspot.com",
  messagingSenderId: '386187985579',
  appId: '1:386187985579:web:2aded18e142fc76a782dee',
  measurementId: 'G-YMDQ1TWE82',
};

const firebaseConfigProd = {
  apiKey: 'AIzaSyC-S_sh2F63DAzwZ5Ss6sGFjNPzwylVllI',
  authDomain: 'flyncar-app.firebaseapp.com',
  databaseURL: 'https://flyncar-app-default-rtdb.firebaseio.com',
  projectId: 'flyncar-app',
  storageBucket: 'assets.flyandcar.com',
  //storageBucket: "flyncar-app.appspot.com",
  messagingSenderId: '342293326176',
  appId: '1:342293326176:web:91b08dd03721261ef9d605',
  measurementId: 'G-1GNVH96R3E',
};

firebase.initializeApp(appConfig?.env === 'PROD' ? firebaseConfigProd : firebaseConfigPreProd);
// firebase.appCheck().activate('6LfcrcQoAAAAACZPxHIEjrq9RHvozuShTJyvgiX2', true);
export const firebaseApp = {
  ...firebase,
};
