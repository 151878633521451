import { createSlice } from '@reduxjs/toolkit';

/* ------------- Initial State ------------- */
export const initialState = {
  checkCar: {},
};

/* ------------- Slice ------------- */
const checkCarSlice = createSlice({
  name: 'checkCar',
  initialState,
  reducers: {
    // Directly update the state thanks to Immer
    checkCarSetter(state, action) {
      state.checkCar = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { checkCarSetter } = checkCarSlice.actions;

export default checkCarSlice.reducer;
