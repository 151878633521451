import * as React from 'react';

import { useToken } from '@gluestack-ui/themed';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';

// import screens
import { tabScreens } from './screens';
import { Metrics } from '../shared/themes';
import { navigationOption } from '../shared/util/navigation-utils';

const Tab = createBottomTabNavigator();

function TabNavigation(props) {
  const userMode = useSelector((state) => state.users.userMode);

  const insert = useSafeAreaInsets();
  const colorSelected = useToken('colors', 'primary500');
  const colorUnSelected = useToken('colors', 'black');
  const colorWhite = useToken('colors', 'white');
  const colorLight100 = useToken('colors', 'light200');

  const { t } = useTranslation();

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarActiveTintColor: colorSelected,
        tabBarInactiveTintColor: colorUnSelected,
        tabBarStyle: {
          height: Metrics.bottomTabSize + insert.bottom,
          backgroundColor: colorWhite,
          borderTopColor: colorLight100,
          borderTopWidth: 1,
        },
      }}>
      {userMode ? (
        <>
          <Tab.Screen
            name={tabScreens.search.name}
            component={tabScreens.search.component}
            options={{
              ...navigationOption,
              ...tabScreens.search.options,
              ...(tabScreens.search.optionsMobile ?? {}),
              title: t(tabScreens.search.options.title),
              tabBarIcon: (s) => <tabScreens.search.iconLibrary size={25} name={tabScreens.search.iconName} color={s.color} />,
            }}
          />
          <Tab.Screen
            name={tabScreens.rental.name}
            component={tabScreens.rental.component}
            options={{
              ...navigationOption,
              ...tabScreens.rental.options,
              ...(tabScreens.rental.optionsMobile ?? {}),
              title: t(tabScreens.rental.options.title),
              tabBarIcon: (s) => <tabScreens.rental.iconLibrary size={25} name={tabScreens.rental.iconName} color={s.color} />,
            }}
          />
          <Tab.Screen
            name={tabScreens.message.name}
            component={tabScreens.message.component}
            options={{
              ...navigationOption,
              ...tabScreens.message.options,
              ...(tabScreens.message.optionsMobile ?? {}),
              title: t(tabScreens.message.options.title),
              tabBarIcon: (s) => <tabScreens.message.iconLibrary size={25} name={tabScreens.message.iconName} color={s.color} />,
            }}
          />
          <Tab.Screen
            name={tabScreens.profile.name}
            component={tabScreens.profile.component}
            options={{
              ...navigationOption,
              ...tabScreens.profile.options,
              ...(tabScreens.profile.optionsMobile ?? {}),
              title: t(tabScreens.profile.options.title),
              tabBarIcon: (s) => <tabScreens.profile.iconLibrary size={25} name={tabScreens.profile.iconName} color={s.color} />,
            }}
          />
        </>
      ) : (
        <>
          <Tab.Screen
            name={tabScreens.ownerHome.name}
            component={tabScreens.ownerHome.component}
            options={{
              ...navigationOption,
              ...tabScreens.ownerHome.options,
              ...(tabScreens.ownerHome.optionsMobile ?? {}),
              title: t(tabScreens.ownerHome.options.title),
              tabBarIcon: (s) => <tabScreens.ownerHome.iconLibrary size={25} name={tabScreens.ownerHome.iconName} color={s.color} />,
            }}
          />
          <Tab.Screen
            name={tabScreens.ownerCar.name}
            component={tabScreens.ownerCar.component}
            options={{
              ...navigationOption,
              ...tabScreens.ownerCar.options,
              ...(tabScreens.ownerCar.optionsMobile ?? {}),
              title: t(tabScreens.ownerCar.options.title),
              tabBarIcon: (s) => <tabScreens.ownerCar.iconLibrary size={25} name={tabScreens.ownerCar.iconName} color={s.color} />,
            }}
          />
          <Tab.Screen
            name={tabScreens.message.name}
            component={tabScreens.message.component}
            options={{
              ...navigationOption,
              ...tabScreens.message.options,
              ...(tabScreens.message.optionsMobile ?? {}),
              title: t(tabScreens.message.options.title),
              tabBarIcon: (s) => <tabScreens.message.iconLibrary size={25} name={tabScreens.message.iconName} color={s.color} />,
            }}
          />
          <Tab.Screen
            name={tabScreens.calendar.name}
            component={tabScreens.calendar.component}
            options={{
              ...navigationOption,
              ...tabScreens.calendar.options,
              ...(tabScreens.calendar.optionsMobile ?? {}),
              title: t(tabScreens.calendar.options.title),
              tabBarIcon: (s) => <tabScreens.calendar.iconLibrary size={25} name={tabScreens.calendar.iconName} color={s.color} />,
            }}
          />
          <Tab.Screen
            name={tabScreens.profile.name}
            component={tabScreens.profile.component}
            options={{
              ...navigationOption,
              ...tabScreens.profile.options,
              ...(tabScreens.profile.optionsMobile ?? {}),
              title: t(tabScreens.profile.options.title),
              tabBarIcon: (s) => <tabScreens.profile.iconLibrary size={25} name={tabScreens.profile.iconName} color={s.color} />,
            }}
          />
        </>
      )}
    </Tab.Navigator>
  );
}

export default TabNavigation;
