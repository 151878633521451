import { createElement, useEffect, useState } from 'react';

import { useToken } from '@gluestack-ui/themed';

export default function WebTimePicker({ changeDate, initDate }) {
  const [time, setTime] = useState(initDate ? new Date(initDate) : new Date(Date.now()));

  const space2 = useToken('space', '2');
  const radiiMd = useToken('radii', 'md');

  useEffect(() => {
    changeDate && changeDate(time);
  }, [time]);

  const dd = new Date();

  const getHourTime = () => {
    if (time) {
      return `${time?.getHours() < 10 ? '0' + time?.getHours() : time?.getHours()}:${
        time?.getMinutes() < 10 ? '0' + time?.getMinutes() : time?.getMinutes()
      }`;
    }
  };

  return createElement('input', {
    type: 'time',
    step: '900',
    value: getHourTime(),
    onChange: (event) => {
      const timeSplit = event.target.value.split(':');
      const t = new Date(timeSplit[0] * 60 * 60 * 1000 + timeSplit[1] * 60 * 1000 + dd.getTimezoneOffset() * 60 * 1000);
      setTime(t);
    },
    style: { height: 30, padding: space2, border: '1px solid #677788', borderRadius: radiiMd, width: '100%' },
  });
}
