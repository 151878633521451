import React, { useMemo } from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { getThumbnailUrl } from '../../../util/car-utils';
import { resizedImage } from '../../../util/image-utils';
import { distanceBetweenCoord } from '../../../util/place-utils';
import Badge from '../../badge/badge';
import Image from '../../image/image';
import CarDetailBar from '../../info/carDetailBar';
import Skeleton from '../../skeleton/skeleton';

export default function CarBigCard({ car, testID, currency, onPress, disabled, badge, dateRange, location } = { disabled: true }) {
  const { t } = useTranslation();

  const isFreeDelivery = useMemo(() => {
    if (!car) {
      return false;
    }
    if (!location?.pickUp?.location) {
      return true;
    }
    const distance = distanceBetweenCoord(location?.pickUp?.location, car._geoloc);
    const allDelLoc = car?.deliveryLocation ?? [];
    const place = allDelLoc.filter((d) => d?.placeId === location?.pickUp?.placeId).pop();

    if ((place && !place?.price) || distance < car?.deliveryRadius * 1000) {
      return true;
    } else {
      return false;
    }
  }, [car, location?.pickUp]);

  return (
    <TouchableOpacity testID={testID} disabled={disabled} onPress={onPress}>
      <VStack space={'md'}>
        {car?.images ? (
          <Image
            bgColor={'$light200'}
            rounded={'$2xl'}
            source={{ uri: resizedImage(getThumbnailUrl(car?.images)) }}
            h={200}
            w={'100%'}
            alt={'carThumbnail'}
          />
        ) : (
          <Skeleton h={200} rounded={'$lg'} />
        )}
        <CarDetailBar car={car} currency={currency} dateRange={dateRange} fullReview={true} priceSize={'$h4'} size={'h4'} />
        {badge && (
          <Box variant={'absoluteTopLeft'} m={'$4'}>
            {badge}
          </Box>
        )}
      </VStack>
      {isFreeDelivery && !badge && (
        <Box variant={'absoluteTopLeft'} m={'$2'}>
          <Badge>{t('car.freeDelivery')}</Badge>
        </Box>
      )}
    </TouchableOpacity>
  );
}
