import React, { useEffect, useState } from 'react';
import { RefreshControl } from 'react-native';

import { Box, Button, ButtonText, Divider, FlatList } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ChatScreen from './chat';
import RoomCard from '../../shared/components/entities/message/roomCard';
import LoginPlaceHolder from '../../shared/components/entities/user/loginPlaceHolder';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Layout from '../../shared/components/layout/layout';
import PlaceHolder from '../../shared/components/placeHolder/placeHolder';
import ScreenSpliter from '../../shared/components/web/screenSpliter';
import { useIsDrawer } from '../../shared/provider/isDrawerProvider';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { Images } from '../../shared/themes';
import imagesUrl from '../../shared/themes/imagesUrl';
import { IS_WEB } from '../../shared/util/device-utils';
import { cleanFirestoreResult } from '../../shared/util/firestore-utils';

function MessageScreen({ route }) {
  const currentUser = useSelector((state) => state.users.currentUser);

  const [cUser, setCUser] = useState(currentUser);
  const [room, setRoom] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const maxWidth = useMaxWidth();
  const isDrawer = useIsDrawer();

  const { roomId } = route?.params || {};

  const navigation = useNavigation();
  const { t } = useTranslation();

  useEffect(() => {
    if (firebaseApp.auth()?.currentUser?.uid && currentUser?.uid === firebaseApp.auth()?.currentUser?.uid) {
      if (roomId) {
        setRoom(roomId);
      }
      setLoading(true);
      const subscriber = firebaseApp
        .firestore()
        .collection('users')
        .doc(currentUser?.uid)
        .onSnapshot(
          (res) => {
            setCUser(cleanFirestoreResult(res));
            setLoading(false);
          },
          (err) => {
            errorHandler(err, true);
            setLoading(false);
          },
        );

      return () => subscriber();
    }
  }, [currentUser, roomId]);

  function renderItem({ item }) {
    return (
      <RoomCard
        roomId={item}
        userId={currentUser?.uid}
        onPress={() => (isDrawer ? setRoom(item) : navigation.navigate('ChatScreen', { roomId: item }))}
        isSelected={item === room}
      />
    );
  }

  if (!currentUser?.uid) {
    return (
      <Layout>
        <Box py={'$4'} px={'$screenMargin'} width={maxWidth} alignSelf="center">
          <LoginPlaceHolder />
        </Box>
      </Layout>
    );
  }

  if (!isDrawer) {
    return (
      <FlatList
        scrollEnabled={false}
        mt={'$4'}
        mx={'$screenMargin'}
        data={cUser?.rooms}
        keyExtractor={(item) => item}
        renderItem={renderItem}
        ItemSeparatorComponent={<Divider my={'$2'} />}
        ListEmptyComponent={
          <Box mt={'$4'}>
            <PlaceHolder
              image={{ uri: imagesUrl.river }}
              title={t('placeHolder.noMessageTitle')}
              text={t('placeHolder.noMessageDescription')}
              element={
                <Button w={'100%'} onPress={() => navigation.navigate('Search')}>
                  <ButtonText>{t('placeHolder.getACar')}</ButtonText>
                </Button>
              }
            />
          </Box>
        }
      />
    );
  }

  return (
    <ScreenSpliter
      isBottomBar
      left={
        <FlatList
          scrollEnabled={false}
          mt={'$4'}
          mx={'$screenMargin'}
          data={cUser?.rooms}
          keyExtractor={(item) => item}
          renderItem={renderItem}
          ItemSeparatorComponent={<Divider my={'$2'} />}
          ListEmptyComponent={
            <Box mt={'$4'}>
              <PlaceHolder
                image={{ uri: imagesUrl.river }}
                title={t('placeHolder.noMessageTitle')}
                text={t('placeHolder.noMessageDescription')}
                element={
                  <Button w={'100%'} onPress={() => navigation.navigate('Search')}>
                    <ButtonText>{t('placeHolder.getACar')}</ButtonText>
                  </Button>
                }
              />
            </Box>
          }
        />
      }
      right={<ChatScreen key={room} navigation={navigation} roomUID={room} />}
      isRigthDisplayable={room !== undefined}
      onClose={() => setRoom(undefined)}
      headerText={t('screen.message')}
    />
  );
}

export default MessageScreen;
