import { createSlice } from '@reduxjs/toolkit';

// Define the initial state
export const initialState = {
  quote: {},
};

// Create a slice for quotes which automatically generates action creators and action types
const quoteSlice = createSlice({
  name: 'quote', // the slice's name
  initialState,
  reducers: {
    // Define a reducer and automatically generate an associated action
    quoteSetter(state, action) {
      // Directly mutating the state is safe inside createSlice due to Immer
      state.quote = action.payload;
    },
  },
});

// Export the generated action creators
export const { quoteSetter } = quoteSlice.actions;

// Export the reducer
export default quoteSlice.reducer;
