import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { userUpdateRequest } from '../../reducers/user.reducer';
import { IS_WEB } from '../../util/device-utils';
import Select from '../select/select';

function LangSwitcher() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser);

  function changeLang(lang) {
    if (IS_WEB) {
      window.location.pathname = window.location.pathname.replace('/' + i18n.language + '/', '/' + lang + '/');
    }
    if (currentUser?.uid) {
      dispatch(userUpdateRequest({ uid: currentUser?.uid, language: lang }));
    }
  }

  return (
    <Select
      title={t('language.language')}
      data={[
        { value: 'en', label: '🇬🇧 English' },
        { value: 'es', label: '🇪🇸 Español' },
        { value: 'fr', label: '🇫🇷 Français' },
        { value: 'ar', label: 'اللغة العربية (تم إنشاؤها)' },
        { value: 'cz', label: '🇨🇿 Čeština (generováno)' },
        { value: 'de', label: '🇩🇪 deutsch (generiert)' },
        { value: 'he', label: 'עברית (נוצרה) 🇮🇱' },
        { value: 'it', label: '🇮🇹 Italiano (generato)' },
        { value: 'ja', label: '🇯🇵 日本人(生成)' },
        { value: 'pt', label: '🇵🇹 Português (Gerado)' },
        { value: 'ru', label: '🇷🇺 Русский (сгенерированный)' },
        { value: 'sk', label: '🇸🇰 Slovenčina (generovaný)' },
        { value: 'zh', label: '🇨🇳 中文（生成）' },
      ]}
      onSelect={(item) => changeLang(item.value)}
      placeHolder={t('language.language')}
      value={i18n?.language}
    />
  );
}

export default LangSwitcher;
