import React, { useMemo } from 'react';
import { Text, useWindowDimensions, View } from 'react-native';

import { HStack, VStack } from '@gluestack-ui/themed';
import { TEST_ID } from 'react-native-gifted-chat';

import { useIsDrawer } from '../../provider/isDrawerProvider';
import { screenBreakPoint } from '../../util/device-utils';

export default function WebSpliter({ left, right, space, inverse }) {
  const isMobile = !useIsDrawer();

  if (isMobile) {
    return (
      <VStack space={space}>
        {inverse ? right : left}
        {inverse ? left : right}
      </VStack>
    );
  }

  return (
    <HStack justifyContent={'space-between'} w={'100%'}>
      <VStack w={'50%'}>{left}</VStack>
      <VStack w={'50%'}>{right}</VStack>
    </HStack>
  );
}
