import React, { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, Divider, Heading, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import AdditionalInvoiceDetail from './additionalInvoiceDetail';
import InvoiceDetail from './invoiceDetail';
import { Images, Metrics } from '../../themes';
import imagesUrl from '../../themes/imagesUrl';
import { formatCurrency } from '../../util/currency';
import ActionSheet from '../actionSheet/actionSheet';
import Image from '../image/image';
import { PressableRow } from '../pressable/pressableRow';

export default function AdditionalPaymentRequested({ reservation, invoiceKey, loading, navigation }) {
  const [reservationInvoice, setReservationInvoice] = useState({});
  const [openAdditionalPayment, setOpenAdditionalPayment] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (invoiceKey in reservation) {
      setReservationInvoice(reservation[invoiceKey]);
    }
  }, [invoiceKey, reservation]);

  function renderInvoiceItem(item) {
    if (item.basePrice === 0) {
      return;
    }
    return (
      <HStack key={item.type} justifyContent={'space-between'}>
        <Text>
          {t('invoice.' + item.type, {
            ...item,
            reason: reservationInvoice?.reason,
            basePriceDisplay: formatCurrency(
              Math.round(item.basePrice) / reservationInvoice?.smallerCurrencyUnit,
              reservationInvoice?.currency,
            ),
          })}
        </Text>
        <Text variant={'grey'} size={'secondary'}>
          {formatCurrency(Math.round(item.totalPrice) / reservationInvoice?.smallerCurrencyUnit, reservationInvoice?.currency)}
        </Text>
      </HStack>
    );
  }

  return (
    <>
      <TouchableOpacity onPress={() => setOpenAdditionalPayment(!openAdditionalPayment)}>
        <Box rounded={'$lg'} borderWidth={'$1'} p={'$4'} borderColor="$light200">
          <HStack alignItems="center">
            <Image source={{ uri: imagesUrl.paymentRequested }} size={'md'} resizeMode={'contain'} />
            <VStack ml={'$4'}>
              <Heading size="h4">{t('ownerRequest.additionalPaymentRequested')}</Heading>
              <Text size={'secondary'}>{t('ownerRequest.additionalPaymentRequestedInfo')}</Text>
            </VStack>
          </HStack>
        </Box>
      </TouchableOpacity>
      <ActionSheet
        isOpen={openAdditionalPayment}
        setOpen={setOpenAdditionalPayment}
        headerText={
          <HStack alignItems={'center'} space={'md'} justifyContent={'space-between'}>
            <Heading>{t('rental.rentalInvoice')}</Heading>
            <Text variant={'grey'} size={'secondary'}>
              {reservation?.orderId}
            </Text>
          </HStack>
        }>
        <VStack space={'md'} mx={'$screenMargin'}>
          <VStack space={'sm'}>
            <InvoiceDetail
              reservationInvoice={reservationInvoice}
              receiptUrl={reservation?.receiptUrl}
              isLoading={loading}
              toClose={() => setOpenAdditionalPayment(false)}
            />
          </VStack>
          <Divider my={'$2'} />
          {reservation?.additionalPayments !== undefined && reservation?.additionalPayments?.length > 0 && (
            <VStack space={'sm'}>
              <Heading>{t('rental.additionalInvoice')}</Heading>
              <AdditionalInvoiceDetail
                additionalPayments={reservation?.additionalPayments}
                invoiceKey={invoiceKey}
                toClose={() => setOpenAdditionalPayment(false)}
              />
            </VStack>
          )}
        </VStack>

        {/* <VStack space={'md'} m={'$4'}>
          {reservationInvoice &&
            reservationInvoice?.listProduct &&
            reservationInvoice?.listProduct.map((invoiceItem) => renderInvoiceItem(invoiceItem))}
          <Divider w={'60%'} alignSelf={'center'} />
          {reservation?.additionalPayments && (
            <VStack space={'md'}>
              <Heading>{t('rental.additionalInvoice')}</Heading>
              <AdditionalInvoiceDetail
                additionalPayments={reservation?.additionalPayments}
                invoiceKey={invoiceKey}
                toClose={() => setOpenAdditionalPayment(!openAdditionalPayment)}
                navigation={navigation}
              />
            </VStack>
          )}
          <HStack my={'$4'} justifyContent={'space-between'}>
            <Text fontWeight={'$bold'} >
              {t('reservation.total', { currency: reservationInvoice?.currency })}
            </Text>
            <Text fontWeight={'$bold'} >
              {formatCurrency(
                Math.round(
                  (reservation?.additionalPayments
                    ? reservation?.additionalPayments.reduce((n, aP) => n + aP[invoiceKey].totalPrice, 0)
                    : 0) + (reservationInvoice?.totalPrice ? reservationInvoice?.totalPrice : 0),
                ) / reservationInvoice?.smallerCurrencyUnit,
                reservationInvoice?.currency,
              )}
            </Text>
          </HStack>
        </VStack> */}
      </ActionSheet>
    </>
  );
}
