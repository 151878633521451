import React, { useCallback, useEffect, useState } from 'react';
import { RefreshControl } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import {
  Box,
  Button,
  ButtonSpinner,
  ButtonText,
  Divider,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBackdrop,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Text,
  VStack,
} from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import BottomAction from '../../shared/components/bottomAction/bottomAction';
import CountDown from '../../shared/components/countDown/countDown';
import DeliveryLocationDisplay from '../../shared/components/deliveryLocation/deliveryLocationDisplay';
import CarDriverCard from '../../shared/components/entities/car/carDriverCard';
import MessageButton from '../../shared/components/entities/message/messageButton';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import AdditionalPaymentRequested from '../../shared/components/info/additionalPaymentRequested';
import InvoiceDetail from '../../shared/components/info/invoiceDetail';
import InvoiceFullDetail from '../../shared/components/info/invoiceFullDetail';
import InsuranceDisplay from '../../shared/components/insurance/insurance';
import { PressableRow } from '../../shared/components/pressable/pressableRow';
import UnderLineButton from '../../shared/components/pressable/underLineButton';
import ProgressStep from '../../shared/components/progressStep/progressStep';
import WebScrollView from '../../shared/components/web/webScrollView';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { confirmBooking } from '../../shared/services/firebase/function-firebase';
import { oneReservationListener } from '../../shared/services/firebase/reservation-firebase';
import { getOneUser } from '../../shared/services/firebase/user-firebase';
import { reservationStatus } from '../../shared/util/car-utils';
import { checkCarTypes } from '../../shared/util/check-car-steps';
import { formatDateByLocaleWithCheckTime, rangeToDateRange } from '../../shared/util/date-transforms';
import { cleanFirestoreResult } from '../../shared/util/firestore-utils';
import { InternalLinks } from '../../shared/util/link-utils';
import UserDocuments from '../profile/driverDocuments/userDocumentsDetail';

function RequestDetailScreen({ route }) {
  const { reservationId, userId, carId } = route?.params ?? {};
  const [reservation, setReservation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [finishModalOpen, setFinishModalOpen] = useState(false);
  const [driver, setDriver] = useState({});
  const [driverLoading, setDriverLoading] = useState(true);
  const [bottomActionHeight, setBottomActionHeight] = useState(0);
  const maxWidth = useMaxWidth();
  const navigation = useNavigation();
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.users.currentUser);
  const userI = userId ? userId : reservation?.userId;
  const carI = carId ? carId : reservation?.carId;

  function onCarSnapshot(snapshot) {
    const cleanData = cleanFirestoreResult(snapshot);
    setReservation(cleanData);
    getUser(cleanData?.userId);
    setLoading(false);
  }

  function onCarSnapshotError(e) {
    errorHandler(e, true);
    setLoading(false);
  }

  function getUser(id) {
    if (id) {
      setDriverLoading(true);
      getOneUser(id)
        .then((res) => {
          setDriver(res);
          setDriverLoading(false);
        })
        .catch((e) => {
          errorHandler(e, true);
          setDriverLoading(false);
        });
    }
  }

  useEffect(() => {
    console.debug('Effect: refresh owner reservation', reservationId);
    const subscriber = oneReservationListener(reservationId, onCarSnapshot, onCarSnapshotError);
    return () => subscriber();
  }, [reservationId]);

  useEffect(() => {
    if (reservation?.status === reservationStatus.waitingOwner) {
      navigation.setOptions({
        title: t('screen.requestDetail'),
      });
    }
  }, [navigation, reservation?.status, t]);

  function confirmReservation(accepted) {
    setAcceptLoading(true);
    confirmBooking(reservationId, accepted)
      .then((res) => {
        setAcceptLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setAcceptLoading(false);
      });
  }

  function downloadContract() {
    if (['en', 'fr', 'es'].includes(currentUser?.languange)) {
      Linking.openURL(`https://assets.flyandcar.com/assets/contract-${currentUser?.languange}.pdf`);
    } else {
      Linking.openURL('https://assets.flyandcar.com/assets/contract-en.pdf');
    }
  }

  function goToCheckCar(type) {
    navigation.navigate('AddCheckCarScreen', {
      reservationId: reservationId,
      carId: carI,
      type: type,
    });
  }

  const getCurrentStepFromReservation = useCallback(() => {
    if (!reservation?.checkCarNextStep || reservation?.checkCarNextStep === checkCarTypes.preCheck) {
      return 0;
    } else if (reservation?.checkCarNextStep === checkCarTypes.signIn) {
      return 1;
    } else if (reservation?.checkCarNextStep === checkCarTypes.postCheck) {
      return 2;
    } else if (reservation?.checkCarNextStep === checkCarTypes.signOut) {
      return 3;
    }
    return 4;
  }, [reservation?.checkCarNextStep]);

  const checkCarPrepareTime = useCallback(() => {
    return {
      to: new Date(reservation?.to * 1000).getTime() / 1000 + reservation?.checkOutHour * 60 * 60 + reservation?.checkOutMinute * 60,
      from: new Date(reservation?.from * 1000).getTime() / 1000 + reservation?.checkInHour * 60 * 60 + reservation?.checkInMinute * 60,
    };
  }, [
    reservation?.checkInHour,
    reservation?.checkInMinute,
    reservation?.checkOutHour,
    reservation?.checkOutMinute,
    reservation?.from,
    reservation?.to,
  ]);

  const checkCarButton = useCallback(() => {
    const nowTime = new Date().getTime() / 1000;
    const checkInterval = 12 * 60 * 60;
    const signInterval = 3 * 60 * 60;
    if (reservation?.checkCarNextStep === checkCarTypes.preCheck || !reservation?.checkCarNextStep) {
      if (checkCarPrepareTime().from - checkInterval > nowTime) {
        return (
          <Button onPress={() => goToCheckCar(checkCarTypes?.preCheck)} isDisabled={true}>
            <ButtonText>
              {t('rental.prepareCheckInIn')} <CountDown durationUntil={checkInterval} to={checkCarPrepareTime().from} />
            </ButtonText>
          </Button>
        );
      } else {
        return (
          <Button onPress={() => goToCheckCar(checkCarTypes?.preCheck)}>
            <ButtonText>{t('rental.prepareCheckIn')}</ButtonText>
          </Button>
        );
      }
    } else if (reservation?.checkCarNextStep === checkCarTypes.postCheck) {
      if (checkCarPrepareTime().to - checkInterval > nowTime) {
        return (
          <Button onPress={() => goToCheckCar(reservation?.checkCarNextStep)} isDisabled={true}>
            <ButtonText>
              {t('rental.prepareCheckOutIn')} <CountDown durationUntil={checkInterval} to={checkCarPrepareTime().to} />
            </ButtonText>
          </Button>
        );
      } else {
        return (
          <Button onPress={() => goToCheckCar(reservation?.checkCarNextStep)}>
            <ButtonText>{t('rental.prepareCheckOut')}</ButtonText>
          </Button>
        );
      }
    } else if (reservation?.checkCarNextStep === checkCarTypes.signIn) {
      if (checkCarPrepareTime().from - signInterval > nowTime) {
        return (
          <Button onPress={() => goToCheckCar(reservation?.checkCarNextStep)} isDisabled={true}>
            <ButtonText>
              {t('rental.signInIn')} <CountDown durationUntil={signInterval} to={checkCarPrepareTime().from} />
            </ButtonText>
          </Button>
        );
      } else {
        return (
          <Button onPress={() => goToCheckCar(reservation?.checkCarNextStep)}>
            <ButtonText>{t('rental.signIn')}</ButtonText>
          </Button>
        );
      }
    } else if (reservation?.checkCarNextStep === checkCarTypes.signOut) {
      if (checkCarPrepareTime().to - signInterval > nowTime) {
        return (
          <Button onPress={() => goToCheckCar(reservation?.checkCarNextStep)} isDisabled={true}>
            <ButtonText>
              {t('rental.signOutIn')} <CountDown durationUntil={signInterval} to={checkCarPrepareTime().to} />
            </ButtonText>
          </Button>
        );
      } else {
        return (
          <Button onPress={() => goToCheckCar(reservation?.checkCarNextStep)}>
            <ButtonText>{t('rental.signOut')}</ButtonText>
          </Button>
        );
      }
    }
  }, [checkCarPrepareTime, reservation?.checkCarNextStep, t]);

  return (
    <>
      <WebScrollView maxWidth={maxWidth > 700 ? 700 : maxWidth} refreshControl={<RefreshControl refreshing={loading} />}>
        <VStack space={'md'} my={'$4'} mx={'$screenMargin'} mb={bottomActionHeight}>
          <CarDriverCard
            car={reservation?.car}
            driver={driver}
            loadingCar={loading}
            loadingDriver={driverLoading}
            reservation={reservation}
            dateRange={rangeToDateRange(
              reservation?.from,
              reservation?.to,
              reservation?.checkInHour,
              reservation?.checkInMinute,
              reservation?.checkOutHour,
              reservation?.checkOutMinute,
            )}
            invoice={reservation?.invoice}
            invoiceOwner={reservation?.invoiceOwner}
            onPressCar={() =>
              navigation.navigate('OwnerCarsDetailScreen', {
                carId: carI,
              })
            }
            onPressDriver={() =>
              navigation.navigate('PublicProfileScreen', {
                userId: userI,
              })
            }
          />
          <MessageButton text={t('ownerRequest.message')} carId={reservation.carId} userToContact={reservation.userId} />
          {!loading && reservation?.status !== reservationStatus.waitingOwner && <Divider />}
          {(reservation?.status === reservationStatus.finished ||
            (reservation.status === reservationStatus.waitingReview && !reservation.isOwnerReviewed)) && (
            <Text>{t('rental.rentalFinishInfo')}</Text>
          )}
          {!loading &&
            (reservation.status === reservationStatus.waitingCheckIn ||
              reservation.status === reservationStatus.waitingCheckOut ||
              (reservation.status === reservationStatus.waitingReview && !reservation.isOwnerReviewed)) && (
              <ProgressStep currentStep={getCurrentStepFromReservation()} maxStep={4} />
            )}
          {!loading && reservation.status === reservationStatus.waitingReview && !reservation.isOwnerReviewed && (
            <Button
              onPress={() => {
                setFinishModalOpen(true);
              }}>
              <ButtonText>{t('rental.finish')}</ButtonText>
            </Button>
          )}
          {!loading &&
            (reservation.status === reservationStatus.waitingCheckIn || reservation.status === reservationStatus.waitingCheckOut) && (
              <VStack space={'md'}>
                <VStack>
                  <Text variant={'grey'} size={'secondary'}>
                    {reservation.status === reservationStatus.waitingCheckIn
                      ? formatDateByLocaleWithCheckTime(reservation.from, reservation.checkInHour, reservation.checkInMinute)
                      : formatDateByLocaleWithCheckTime(reservation.to, reservation.checkOutHour, reservation.checkOutMinute)}
                  </Text>
                  {checkCarButton()}
                </VStack>
                <HStack justifyContent={'space-between'} alignItems={'center'}>
                  <UnderLineButton text={t('ownerRequest.downloadContract')} onPress={downloadContract} hideLeftIcon={true} />
                  <UnderLineButton
                    text={t('global.howItWorks')}
                    onPress={() =>
                      navigation.navigate('CustomWebView', {
                        url: InternalLinks.guideRental,
                        title: t('global.howItWorks'),
                      })
                    }
                    hideLeftIcon={true}
                  />
                </HStack>
              </VStack>
            )}
          {reservation?.isAdditionalPaymentToPay && (
            <AdditionalPaymentRequested reservation={reservation} invoiceKey={'invoice'} loading={loading} navigation={navigation} />
          )}
          <Divider my={'$2'} />
          <DeliveryLocationDisplay car={reservation?.car} loading={loading} location={reservation?.delivery} isReadOnly={true} />
          <Divider my={'$2'} />
          <UserDocuments userId={reservation?.userId} />
          {!loading && reservation?.status === reservationStatus.waitingOwner && (
            <>
              <Divider my={'$2'} />
              <HStack space={'md'} alignItem={'center'}>
                <Heading>{t('ownerRequest.invoice')}</Heading>
                <Text alignSelf={'center'} variant={'grey'} size={'secondary'}>
                  {reservation?.orderId}
                </Text>
              </HStack>
              <InvoiceDetail isLoading={loading} reservationInvoice={reservation?.invoice} ownerInvoice={reservation?.invoiceOwner} />
            </>
          )}
          {!loading && reservation.status !== reservationStatus.waitingOwner && (
            <VStack>
              <PressableRow
                leftIconName={'cash-outline'}
                text={t('ownerRequest.requestPayment')}
                onPress={() =>
                  navigation.navigate('OwnerRequestAdditionalPayment', {
                    reservationId: reservationId,
                  })
                }
              />
              <Divider />
              <InvoiceFullDetail reservation={reservation} invoiceKey={'invoice'} loading={loading} navigation={navigation} />
              <Divider />
              <PressableRow
                leftIconName={'remove-circle-outline'}
                text={t('ownerRequest.cancelReservation')}
                onPress={() =>
                  navigation.navigate('CustomWebView', {
                    url: InternalLinks.cancellationsPolicie,
                    title: t('ownerRequest.cancelReservation'),
                  })
                }
              />
              <Divider />
              <PressableRow
                isSkeleton={loading}
                onPress={() =>
                  navigation.navigate('CheckCarScreen', {
                    reservationId: reservation?.uid,
                  })
                }
                text={t('rental.seeChecking')}
                leftIconName={'checkmark-circle-outline'}
              />
              <Divider />
              <InsuranceDisplay insuranceDocuments={reservation?.insuranceDocuments} />
              <PressableRow
                leftIconName={'help-circle-outline'}
                text={t('ownerRequest.getHelp')}
                onPress={() =>
                  navigation.navigate('CustomWebView', {
                    url: InternalLinks.ownerGetHelp,
                    title: t('ownerRequest.getHelp'),
                  })
                }
              />
              <Divider />
            </VStack>
          )}
        </VStack>
        <Modal isOpen={finishModalOpen} onClose={() => setFinishModalOpen(false)}>
          <ModalBackdrop />
          <ModalContent>
            <ModalHeader>
              <Heading size={'h2'}>{t('rental.finishTitle')}</Heading>
              <ModalCloseButton>
                <Icon as={Ionicons} name={'close'} />
              </ModalCloseButton>
            </ModalHeader>
            <ModalBody>
              <VStack justifyContent={'center'} space={'lg'}>
                <Text textAlign={'center'}>{t('rental.finishInfo')}</Text>
                <VStack justifyContent={'center'} alignItems={'center'} space={'md'}>
                  <Button
                    onPress={() => {
                      navigation.navigate('AddReview', {
                        carId: reservation.carId,
                        reservationId: reservation?.uid,
                        reviewedUserId: reservation.userId,
                      });
                      setFinishModalOpen(false);
                    }}>
                    <ButtonText>{t('rental.yesFinish')}</ButtonText>
                  </Button>
                  <UnderLineButton
                    onPress={() => {
                      navigation.navigate('OwnerRequestAdditionalPayment', {
                        reservationId: reservationId,
                      });
                      setFinishModalOpen(false);
                    }}
                    hideLeftIcon={true}
                    text={t('rental.wantRequestPayment')}
                  />
                </VStack>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </WebScrollView>
      {!loading && reservation?.status === reservationStatus.waitingOwner && (
        <BottomAction
          onLayout={(event) => {
            setBottomActionHeight(event?.nativeEvent?.layout?.height ?? 0);
          }}>
          <Box w={maxWidth > 700 ? 700 : maxWidth} alignSelf={'center'}>
            <VStack space={'sm'} mx={'$screenMargin'} my={'$4'}>
              <Text variant={'grey'} size={'secondary'}>
                {t('ownerRequest.infoQuickResponse')}
              </Text>
              <VStack space={'sm'}>
                <Button isDisabled={acceptLoading} onPress={() => confirmReservation(true)}>
                  {acceptLoading && <ButtonSpinner mr="$1" />}
                  <ButtonText>{t('global.accept')}</ButtonText>
                </Button>
                <Button isDisabled={acceptLoading} onPress={() => confirmReservation(false)} variant={'outline'}>
                  {acceptLoading && <ButtonSpinner mr="$1" />}
                  <ButtonText>{t('global.decline')}</ButtonText>
                </Button>
              </VStack>
            </VStack>
          </Box>
        </BottomAction>
      )}
    </>
  );
}

export default RequestDetailScreen;
