import { firebaseApp } from './firebaseFactory';

export function getCustomer(userId) {
  return firebaseApp
    .firestore()
    .collection('customers')
    .doc(userId)
    .get()
    .then((res) => {
      return { ...res.data(), uid: res.id };
    });
}

export function getCustomerCheckOutSession(userId) {
  return firebaseApp
    .firestore()
    .collection('customers')
    .doc(userId)
    .collection('checkout_sessions')
    .get()
    .then((res) => {
      const session = [];
      console.info('Total getCustomerCheckOutSession: ', res.size);
      res.forEach((r) => session.push({ ...r.data(), uid: r.id }));
      return session;
    });
}

export function getAccount(userId) {
  return firebaseApp
    .firestore()
    .collection('accounts')
    .doc(userId)
    .get()
    .then((res) => {
      return { ...res.data(), uid: res.id };
    });
}

export function oneAccountListener(userId, onResult, onError) {
  return firebaseApp.firestore().collection('accounts').doc(userId).onSnapshot(onResult, onError);
}
