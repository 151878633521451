import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  ButtonSpinner,
  ButtonText,
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  Heading,
  Input,
  InputField,
  Text,
  VStack,
} from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import BottomAction from '../../../../shared/components/bottomAction/bottomAction';
import RegionPicker from '../../../../shared/components/countryPicker/countryPicker';
import { errorHandler } from '../../../../shared/components/errorHandler/errorHander';
import MediaUploader from '../../../../shared/components/media/mediaUploader';
import errorToast from '../../../../shared/components/toast/errorToast';
import successToast from '../../../../shared/components/toast/successToast';
import WebScrollView from '../../../../shared/components/web/webScrollView';
import DatePicker from '../../../../shared/generic/DatePicker';
import { useMaxWidth } from '../../../../shared/provider/maxWidthProvider';
import { firebaseApp } from '../../../../shared/services/firebase/firebaseFactory';
import { Metrics } from '../../../../shared/themes';
import { cleanFirestoreListResult } from '../../../../shared/util/firestore-utils';
import { userDocumentStatus, userDocumentType } from '../../../../shared/util/userDocument-utils';
import CarDocumentSkeleton from '../carDocumentSkeleton';

const emptyDocument = {
  status: userDocumentStatus.waintingApproval,
  type: userDocumentType.id,
};

function AddUserID({ route }) {
  const { userId } = route?.params ?? {};

  const [document, setDocument] = useState(emptyDocument);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bottomActionHeight, setBottomActionHeight] = useState(0);

  const { t } = useTranslation();
  const navigation = useNavigation();
  const maxWidth = useMaxWidth();

  const currentUser = useSelector((state) => state.users.currentUser);

  function valid() {
    const isValid = document?.deliverTime < new Date();
    return (
      document?.number &&
      isValid &&
      document?.countryCode &&
      document?.images &&
      document?.images?.length > 0 &&
      document?.firstName &&
      document?.lastName &&
      currentUser?.uid
    );
  }

  function SaveDocuments() {
    if (valid()) {
      setUpdating(true);
      if (!document?.uid) {
        firebaseApp
          .firestore()
          .collection('users')
          .doc(userId)
          .collection('documents')
          .add(document)
          .then(() => {
            setUpdating(false);
            successToast(t('carDocuments.successfullyAdd'));
            navigation.canGoBack() && navigation.goBack();
          })
          .catch((e) => {
            errorHandler(e, true);
            setUpdating(false);
          });
      } else {
        firebaseApp
          .firestore()
          .collection('users')
          .doc(userId)
          .collection('documents')
          .doc(document?.uid)
          .update(document)
          .then(() => {
            setUpdating(false);
            successToast(t('carDocuments.successfullyAdd'));
            navigation.canGoBack() && navigation.goBack();
          })
          .catch((e) => {
            errorHandler(e, true);
            setUpdating(false);
          });
      }
    } else {
      errorToast(t('error.completeForm'));
    }
  }

  function updateImages(images) {
    const cleanImages = images.filter((image) => image?.url !== undefined);
    if (cleanImages && cleanImages?.length > 0) {
      setDocument((prevDoc) => {
        return { ...prevDoc, images: cleanImages };
      });
    }
  }

  useEffect(() => {
    if (currentUser?.uid && userId) {
      setLoading(true);
      firebaseApp
        .firestore()
        .collection('users')
        .doc(userId)
        .collection('documents')
        .where('type', '==', userDocumentType.id)
        .get()
        .then(cleanFirestoreListResult)
        .then((res) => {
          if (res?.length > 0) {
            setDocument(res[0]);
          } else {
            setDocument(emptyDocument);
          }
          setLoading(false);
        })
        .catch((e) => {
          errorHandler(e, true);
          setLoading(false);
        });
    }
  }, [currentUser, userId]);

  if (loading) {
    return <CarDocumentSkeleton />;
  }

  return (
    <>
      <WebScrollView testID={'scrollView'} hideFooter={true} maxWidth={maxWidth > 700 ? 700 : maxWidth}>
        <VStack my={'$4'} mx={'$screenMargin'} space={'md'} mb={bottomActionHeight}>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('login.firstName')}</FormControlLabelText>
            </FormControlLabel>
            <Input>
              <InputField
                testID="input.firstName"
                defaultValue={document?.firstName}
                onChangeText={(value) => setDocument({ ...document, firstName: value })}
              />
            </Input>
          </FormControl>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('login.lastName')}</FormControlLabelText>
            </FormControlLabel>
            <Input>
              <InputField
                testID="input.lastName"
                defaultValue={document?.lastName}
                onChangeText={(value) => setDocument({ ...document, lastName: value })}
              />
            </Input>
          </FormControl>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('carDocuments.ownership.idNumber')}</FormControlLabelText>
            </FormControlLabel>
            <Input>
              <InputField
                testID="input.idNumber"
                defaultValue={document?.number}
                onChangeText={(value) => setDocument({ ...document, number: value })}
              />
            </Input>
          </FormControl>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('driverDocument.deliverTime')}</FormControlLabelText>
            </FormControlLabel>
            <DatePicker
              title={t('driverDocument.deliverTime')}
              maximumDate={new Date(new Date().getFullYear(), 11, 31)}
              date={document?.deliverTime ? new Date(document?.deliverTime * 1000) : undefined}
              onConfirm={(date) => {
                setDocument((prev) => ({ ...prev, deliverTime: date.getTime() / 1000 }));
              }}
            />
          </FormControl>
          <RegionPicker
            title={t('login.country')}
            defaultCountryCode={document?.countryCode}
            placeHolder={t('login.countryPlaceHolder')}
            setCountryData={(cData) => setDocument({ ...document, countryCode: cData.code, countryFlag: cData.flag })}
          />
          <VStack space={'sm'}>
            <Heading>{t('carDocuments.ownership.uploadId')}</Heading>
            <Text variant={'grey'} size={'secondary'}>
              {t('carDocuments.ownership.uploadFrontAndBack')}
            </Text>
            <MediaUploader
              key={document?.uid}
              testID={'idDocument'}
              initialList={document?.images ?? []}
              onUpdate={(images) => updateImages(images)}
              storagePath={'user-documents'}
              multipleSelectionAllow={true}
              document={true}
            />
          </VStack>
        </VStack>
      </WebScrollView>
      <BottomAction
        onLayout={(event) => {
          setBottomActionHeight(event?.nativeEvent?.layout?.height ?? 0);
        }}>
        <Box px={'$screenMargin'} my={'$4'} w={maxWidth > 700 ? 700 : maxWidth} alignSelf={'center'}>
          <Button testID="button.addDocument" isDisabled={updating || !valid()} onPress={SaveDocuments}>
            {updating && <ButtonSpinner mr="$1" />}
            <ButtonText>{t('global.validate')}</ButtonText>
          </Button>
        </Box>
      </BottomAction>
    </>
  );
}

export default AddUserID;
