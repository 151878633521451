/* eslint-disable import/order */
import { all, takeLatest } from 'redux-saga/effects';

import AppConfig from '../../config/app-config';
import {
  userAllRequest,
  userCurrentRequest,
  userDeleteRequest,
  userRequest,
  UserTypes,
  userUpdateRequest,
} from '../../shared/reducers/user.reducer';
import UserSagas from '../../shared/sagas/user.sagas';
import API from '../services/api';
import FixtureAPI from '../services/fixture-api';

/* ------------- Types ------------- */

import { carAllRequest, carDeleteRequest, carRequest, carUpdateRequest } from '../reducers/car.reducer';
// jhipster-react-native-saga-redux-import-needle

/* ------------- Sagas ------------- */

import CarSagas from './car.sagas';
// jhipster-react-native-saga-method-import-needle

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = AppConfig.useFixtures ? FixtureAPI : API.create();

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // some sagas only receive an action

    takeLatest(carRequest, CarSagas.getCar, api),
    takeLatest(carAllRequest, CarSagas.getAllCars, api),
    takeLatest(carUpdateRequest, CarSagas.updateCar, api),
    takeLatest(carDeleteRequest, CarSagas.deleteCar, api),
    // jhipster-react-native-saga-redux-connect-needle
    takeLatest(userRequest, UserSagas.getUser, api),
    takeLatest(userCurrentRequest, UserSagas.getCurrentUser, api),
    takeLatest(userUpdateRequest, UserSagas.updateUser, api),
    takeLatest(userDeleteRequest, UserSagas.deleteUser, api),
    takeLatest(userAllRequest, UserSagas.getAllUsers, api),
  ]);
}
