import React from 'react';

import { Heading, VStack } from '@gluestack-ui/themed';

import WebTimePicker from '../web/DateTimePicker';

export default function TimePicker({ time, setTime, title, w }) {
  return (
    <VStack space={'sm'} justifyContent={'center'} alignItems={'flex-start'}>
      <Heading size={'h4'}>{title}</Heading>
      <WebTimePicker changeDate={(d) => setTime(d)} initDate={time ? new Date(time) : new Date()} />
    </VStack>
  );
}
