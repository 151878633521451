import { Colors } from '../themes';

const customAppearance = {
  shapes: {
    borderRadius: 12,
    borderWidth: 0.5,
  },
  primaryButton: {
    shapes: {
      borderRadius: 6,
    },
  },
  colors: {
    primary: Colors.blue[400],
    background: Colors.fullWhite,
    componentBackground: Colors.white,
    componentBorder: Colors.light[200],
    componentDivider: Colors.black,
    primaryText: Colors.black,
    secondaryText: Colors.black,
    componentText: Colors.black,
    placeholderText: Colors.light[400],
    icon: Colors.black,
  },
};

export function initPaymentSheetData(paymentIntent, customData) {
  return {
    appearance: customAppearance,
    merchantDisplayName: 'FlynCar Inc.',
    customerId: paymentIntent?.customer,
    customerEphemeralKeySecret: paymentIntent?.ephemeralKeySecret,
    setupIntentClientSecret: paymentIntent?.setupIntentClientSecret,
    paymentIntentClientSecret: paymentIntent?.paymentIntentClientSecret,
    allowsDelayedPaymentMethods: true,
    ...customData,
  };
}

const zeroDecimalCurrency = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];

const threeDecimalCurrency = ['BHD', 'JOD', 'KWD', 'OMR', 'TND'];

export function getSmallestCurrencyUnit(price, currency) {
  if (!currency) {
    return { amount: price, unit: 1 };
  }
  if (zeroDecimalCurrency.includes(currency.toUpperCase())) {
    return { amount: price, unit: 1 };
  } else if (threeDecimalCurrency.includes(currency.toUpperCase())) {
    return { amount: Math.round(price * 100) * 10, unit: 1000 };
  } else {
    return { amount: Math.round(price * 100), unit: 100 };
  }
}
