import { call, put } from 'redux-saga/effects';

import { callApi } from './call-api.saga';
import { errorHandler } from '../components/errorHandler/errorHander';
import {
  userAllFailure,
  userAllSuccess,
  userCurrentSuccess,
  userDeleteSuccess,
  userSuccess,
  userUpdateSuccess,
} from '../reducers/user.reducer';

function* getUser(api, action) {
  const userId = action.payload;

  try {
    const apiCall = call(api.getUser, userId);
    const response = yield call(callApi, apiCall);
    yield put(userSuccess(serializableTime(response.data())));
  } catch (e) {
    errorHandler(e, false);
    // yield put(userFailure(e.message));
  }
}

function* getCurrentUser(api, action) {
  const userId = action.payload;

  try {
    if (!userId) {
      yield put(userCurrentSuccess(null));
      return;
    }
    const apiCall = call(api.getUser, userId);
    const response = yield call(callApi, apiCall);
    yield put(userCurrentSuccess(serializableTime({ ...response.data(), uid: response.id })));
  } catch (e) {
    errorHandler(e, false);
    // yield put(userCurrentFailure(e.message));
  }
}

function* getAllUsers(api, action) {
  const options = action.payload;
  // make the call to the api
  const apiCall = call(api.getAllUsers, options);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(userAllSuccess(response.data));
  } else {
    yield put(userAllFailure(response.data));
  }
}

function* updateUser(api, action) {
  const user = action.payload;
  // make the call to the api

  try {
    const apiCall = call(api.updateUser, user);
    const response = yield call(callApi, apiCall);
    yield put(userUpdateSuccess(null));
  } catch (e) {
    errorHandler(e, false);
    // yield put(userUpdateFailure(e.message));
  }
}

function* deleteUser(api, action) {
  const userId = action.payload;
  // make the call to the api
  const apiCall = call(api.deleteUser, userId);
  const response = yield call(callApi, apiCall);

  // success?
  if (response.ok) {
    yield put(userDeleteSuccess());
  } else {
    // yield put(userDeleteFailure(response.data));
  }
}

function serializableTime(data) {
  const { createAt, ...other } = data;
  if (createAt?.seconds) {
    other.createAt = createAt?.seconds;
  }
  return other;
}

export default {
  getUser,
  getCurrentUser,
  getAllUsers,
  deleteUser,
  updateUser,
};
