import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, Heading, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { Metrics } from '../../themes';
import ActionSheet from '../actionSheet/actionSheet';

export default function UnderstandPrice(props) {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  return null;
  return (
    <Box w={'$full'}>
      <TouchableOpacity onPress={() => setOpen(true)}>{props.children}</TouchableOpacity>
      <ActionSheet headerText={t('addCar.understandPriceTitle')} isOpen={isOpen} setOpen={setOpen}>
        <VStack alignItems={'center'} space={'sm'} mx={'$screenMargin'}>
          <Heading alignSelf={'center'}>{t('addCar.understandPricePayedByDriver')}</Heading>
          <Heading alignSelf={'center'} color={'$primary400'}>
            =
          </Heading>
          <VStack borderWidth={'$1'} rounded={'$lg'} p={'$4'} alignItems={'center'} w={'100%'} space={'sm'}>
            <Heading alignSelf={'center'} size={'h4'}>
              {t('addCar.understandPriceYourEarning')}
            </Heading>
            <Text alignSelf={'center'} textAlign={'center'}>
              {t('addCar.understandPriceYourEarningDetail')}
            </Text>
          </VStack>
          <Heading alignSelf={'center'} color={'$primary400'}>
            +
          </Heading>
          <VStack borderWidth={'$1'} rounded={'$lg'} p={'$4'} alignItems={'center'} w={'100%'} space={'sm'}>
            <Heading alignSelf={'center'} size={'h4'}>
              {t('addCar.understandPriceStripe')}
            </Heading>
            <Text alignSelf={'center'} textAlign={'center'}>
              {t('addCar.understandPriceStripeDetail')}
            </Text>
          </VStack>
          <Heading alignSelf={'center'} color={'$primary400'}>
            +
          </Heading>
          <VStack alignSelf={'center'} borderWidth={'$1'} rounded={'$lg'} p={'$4'} alignItems={'center'} w={'100%'} space={'sm'}>
            <Heading alignSelf={'center'} size={'h4'}>
              {t('addCar.understandPriceService')}
            </Heading>
            <Text alignSelf={'center'} textAlign={'center'}>
              {t('addCar.understandPriceServiceDetail')}
            </Text>
          </VStack>
        </VStack>
      </ActionSheet>
    </Box>
  );
}
