import React from 'react';

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

export default function KeyboardAvoiding({ children, maxWidth, mx }) {
  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{ width: maxWidth ?? '100%', alignSelf: 'center', paddingHorizontal: mx ?? 0 }}
      testID="scrollView">
      {children}
    </KeyboardAwareScrollView>
  );
}
