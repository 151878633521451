import React from 'react';

import { Ionicons } from '@expo/vector-icons';
import { Heading, HStack, Icon, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import PriceDisplay from './priceDisplay';
import { carTransmissionList } from '../../util/car-utils';
import Skeleton from '../skeleton/skeleton';

export default function CarDetailBar({ car, loading, currency, fullReview, dateRange, size, hidePrice, priceSize }) {
  const { t } = useTranslation();

  if (!car?.priceData || !car?.currency || loading) {
    return (
      <VStack space={'sm'}>
        <HStack space={'md'} alignItems={'center'}>
          <Skeleton w={'40%'} h={'$5'} rounded={'$full'} />
          <Skeleton w={'10%'} h={'$5'} rounded={'$full'} />
        </HStack>
        <HStack justifyContent={'space-between'} alignItems={'flex-end'} space={'md'}>
          <Skeleton h={'$4'} w={'50%'} rounded={'$full'} />
          <Skeleton h={'$6'} w={100} rounded={'$full'} />
        </HStack>
      </VStack>
    );
  }

  return (
    <VStack justifyContent={'space-between'}>
      <HStack space={'sm'} alignItems={'center'} w={'$full'} justifyContent={fullReview ? 'flex-start' : 'space-between'}>
        <Heading maxWidth={'75%'} numberOfLines={1} size={size}>
          {car?.brand + ' ' + car?.model}
        </Heading>
        <HStack space={'sm'} maxWidth={'25%'} alignItems={'center'}>
          <Icon as={Ionicons} size={'xs'} name={'star'} color={'$light700'} />
          <Text variant={'grey'} size={'secondary'}>
            {car?.avgRating + ' (' + car?.numRatings + ')'}
          </Text>
        </HStack>
      </HStack>
      <HStack justifyContent={'space-between'} alignItems={'center'}>
        <Text alignSelf={'center'} maxWidth={'50%'} variant={'grey'} size={'secondary'}>
          {t('car.carCardInfo', {
            type: t('addCar.' + car?.type),
            fuel: t(carTransmissionList.find((element) => element.name === car?.transmission)?.translateName),
            seats: car?.seats,
          })}
        </Text>
        {!hidePrice && (
          <PriceDisplay priceData={car?.priceData} carCurrency={car?.currency} currency={currency} dateRange={dateRange} size={priceSize} />
        )}
      </HStack>
    </VStack>
  );
}
