import React from 'react';

import { HStack, Switch, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Toggle from '../../../shared/components/toggle/toggle';
import { carAddingSetter } from '../../../shared/reducers/car.reducer';

function AdsCompliance() {
  const car = useSelector((state) => state.cars.carAdding);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function clickAdsCompliance(booled) {
    dispatch(carAddingSetter({ ...car, acceptAds: booled }));
  }

  return (
    <VStack space={'lg'}>
      <Text mb={'$4'} variant={'grey'} size={'secondary'}>
        {t('addCar.adsComplianceDescription')}
      </Text>
      <HStack justifyContent={'space-between'} alignItems={'center'}>
        <Text>{t('addCar.adsComplianceAllow')}</Text>
        <Toggle isChecked={car?.acceptAds ?? false} onPress={clickAdsCompliance} />
      </HStack>
    </VStack>
  );
}

export default AdsCompliance;
