import React from 'react';

import { Box, Button, ButtonText, useToken, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CarTinyCard from '../../shared/components/entities/car/carTinyCard';
import FirestoreList from '../../shared/components/firestore/firestoreList';
import Layout from '../../shared/components/layout/layout';
import NotificationManager from '../../shared/components/notificationManager/notificationManager';
import PlaceHolder from '../../shared/components/placeHolder/placeHolder';
import Footer from '../../shared/components/web/footer';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import imagesUrl from '../../shared/themes/imagesUrl';

function OwnerCarsScreen() {
  const currentUser = useSelector((state) => state.users.currentUser);

  const maxWidth = useMaxWidth();
  const space4 = useToken('space', '4');
  const screenMargin = useToken('space', 'screenMargin');

  const navigation = useNavigation();
  const { t } = useTranslation();

  function goToOwnerCarDetail(car) {
    navigation.navigate('OwnerCarsDetailScreen', { carId: car?.uid });
  }

  function renderItem({ item }) {
    return <CarTinyCard car={item} onPress={() => goToOwnerCarDetail(item)} />;
  }

  return (
    <Layout isBottomBar>
      <NotificationManager my={'$4'} mx={'$screenMargin'} />
      <FirestoreList
        keyExtractor={(i) => i?.uid + '_' + i?.carApproved + i?.carValidated + i?.accountValidated + i?.visible}
        ItemSeparatorComponent={<Box />}
        isConnected={true}
        collectionName={'cars'}
        filters={[{ field: 'ownerId', operator: '==', value: currentUser?.uid }]}
        renderItem={renderItem}
        contentContainerStyle={{ alignSelf: 'center', width: maxWidth, paddingVertical: space4, paddingHorizontal: screenMargin }}
        ListEmptyComponent={
          <Box>
            <PlaceHolder
              image={{ uri: imagesUrl.empty }}
              title={t('placeHolder.noCarTitle')}
              text={t('placeHolder.noCarInfo')}
              element={
                <Button onPress={() => navigation.navigate('AddCarFlow')}>
                  <ButtonText>{t('ownerCar.addNewCar')}</ButtonText>
                </Button>
              }
            />
          </Box>
        }
        ListFooterNoEmptyComponent={
          <VStack my={'$8'}>
            <Button variant={'outline'} onPress={() => navigation.navigate('AddCarFlow')} w={'$full'}>
              <ButtonText>{t('ownerCar.addNewCar')}</ButtonText>
            </Button>
            <Footer />
          </VStack>
        }
      />
    </Layout>
  );
}

export default OwnerCarsScreen;
