import React, { useCallback, useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Divider, HStack, Icon, Text, View } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SearchParamsHooker from './searchParamsHooker';
import { navigationRef } from '../../../navigation/nav-ref';
import { setDateRange, setFilters, setLocation } from '../../reducers/search.reducer';
import { displayRangeDate } from '../../util/date-transforms';
import CalendarSheet from '../calendar/calendarSheet';
import AeraPicker from '../entities/aera/aeraPicker';
import SearchFilter from '../filter/filter';

function CarSearchBar() {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const location = useSelector((state) => state.search.location);
  const dateRange = useSelector((state) => state.search.dateRange);
  const filters = useSelector((state) => state.search.filters);

  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [isAeraPickerOpen, setAeraPickerOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);

  function isfilterEnable() {
    return (
      filters?.maxPrice ||
      filters?.minPrice ||
      (filters?.carType && filters?.carType.length > 0) ||
      (filters?.feature && filters?.feature.length > 0) ||
      filters?.transmission ||
      filters?.fuel
    );
  }

  function onRefresh() {
    navigation.navigate('Search', navigationRef.current.getCurrentRoute()?.params);
  }

  const handleSetLocation = useCallback(
    (location) => {
      dispatch(setLocation(location));
    },
    [dispatch],
  );

  const handleSetDateRange = useCallback(
    (dateRange) => {
      dispatch(setDateRange(dateRange));
    },
    [dispatch],
  );

  const handleSetFilters = useCallback(
    (filters) => {
      dispatch(setFilters(filters));
    },
    [dispatch],
  );

  const displayDate = useCallback(() => {
    return displayRangeDate(dateRange?.from, dateRange?.to, 'small');
  }, [dateRange?.from, dateRange?.to]);

  return (
    <View w={'100%'}>
      <SearchParamsHooker />
      <HStack alignItems={'center'} w={'100%'}>
        <Box testID="headerBar" variant={'cardContainer'} rounded={'$full'} px={'$4'} py={'$3'} w={'100%'}>
          <HStack alignItems={'center'} space={'sm'} justifyContent={'space-evenly'} w={'100%'}>
            <TouchableOpacity testID="headerBar.location" onPress={() => setAeraPickerOpen(true)} style={{ maxWidth: '30%' }}>
              <Box flexWrap={'wrap'} w={'100%'}>
                <HStack alignItems={'center'} space={'sm'} w={'100%'}>
                  <Icon as={Ionicons} size={'sm'} name="search" color={'$black'} />
                  <Text textBreakStrategy="balanced" numberOfLines={1} fontWeight={location?.pickUp?.name ? '$bold' : '$normal'}>
                    {location?.pickUp?.name ?? t('search.where')}
                  </Text>
                </HStack>
              </Box>
            </TouchableOpacity>
            <Divider mx={'$2'} orientation="vertical" />
            <TouchableOpacity testID="headerBar.calendar" onPress={() => setCalendarOpen(true)} style={{ maxWidth: '30%' }}>
              <Box flexWrap={'wrap'} w={'100%'}>
                <HStack space={'sm'} alignItems={'center'} w={'100%'}>
                  <Icon as={Ionicons} size={'sm'} name="calendar-outline" color={'$black'} />
                  {dateRange?.from && dateRange?.to ? (
                    <Text numberOfLines={1} fontWeight={'$bold'}>
                      {displayDate()}
                    </Text>
                  ) : (
                    <Text numberOfLines={1}>{t('search.when')}</Text>
                  )}
                </HStack>
              </Box>
            </TouchableOpacity>
            <Divider mx={'$2'} orientation="vertical" />
            <TouchableOpacity testID="headerBar.filter" onPress={() => setFilterOpen(true)} style={{ maxWidth: '10%' }}>
              <HStack>
                <Icon color={'$black'} as={Ionicons} name={'filter-outline'} size={'lg'} />
                {isfilterEnable() && <Box backgroundColor={'$blue400'} h={'$2'} w={'$2'} rounded={'$full'} />}
              </HStack>
            </TouchableOpacity>
          </HStack>
        </Box>
      </HStack>
      <AeraPicker
        isOpen={isAeraPickerOpen}
        setOpen={setAeraPickerOpen}
        location={location}
        setLocation={handleSetLocation}
        onRefresh={onRefresh}
      />
      <CalendarSheet
        isOpen={isCalendarOpen}
        setOpen={setCalendarOpen}
        dateRange={dateRange}
        setDateRange={handleSetDateRange}
        onPress={onRefresh}
      />
      <SearchFilter isOpen={isFilterOpen} setOpen={setFilterOpen} filter={filters} setFilter={handleSetFilters} onRefresh={onRefresh} />
    </View>
  );
}

export default CarSearchBar;
