import React from 'react';

import { useTranslation } from 'react-i18next';

import { navigationRef } from '../../../navigation/nav-ref';
import imagesUrl from '../../themes/imagesUrl';
import Card from '../pressable/card';

export const RentYourCar = ({ onPress }) => {
  const { t } = useTranslation();

  function onClick() {
    if (onPress) {
      onPress();
    } else {
      navigationRef.current.navigate('LandingPagePro');
    }
  }

  return (
    <Card
      testID={'rentYourCar'}
      title={t('profile.rentYourCarTitle')}
      subTitle={t('profile.rentYourCarSubTitle')}
      imageUrl={imagesUrl.addCarIlu}
      onPress={onClick}
    />
  );
};
