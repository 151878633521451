import React, { createContext, useContext, useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

import { screenBreakPoint } from '../util/device-utils';

// Create context
export const IsDrawerContext = createContext();

export const IsDrawerProvider = ({ children }) => {
  const { width } = useWindowDimensions();
  const isDrawer = useMemo(() => screenBreakPoint(width, [false, false, false, true, true]), [width]);

  return <IsDrawerContext.Provider value={isDrawer}>{children}</IsDrawerContext.Provider>;
};

export const useIsDrawer = () => {
  const isDrawer = useContext(IsDrawerContext);
  return isDrawer;
};
