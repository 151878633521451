import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView } from 'react-native';

import { Button, ButtonSpinner, ButtonText, Divider, Heading, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import * as Clipboard from 'expo-clipboard';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import BottomAction from '../../shared/components/bottomAction/bottomAction';
import DeliveryLocationDisplay from '../../shared/components/deliveryLocation/deliveryLocationDisplay';
import ProfileCardById from '../../shared/components/entities/user/ProfileCardById';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import ChooseExtraOption from '../../shared/components/extraOption/chooseExtraOption';
import InvoiceDetail from '../../shared/components/info/invoiceDetail';
import InsuranceDetail from '../../shared/components/insurance/insuranceDetail';
import Layout from '../../shared/components/layout/layout';
import SmallCard from '../../shared/components/pressable/smallCard';
import successToast from '../../shared/components/toast/successToast';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { userManagedRegisterFlow } from '../../shared/reducers/user.reducer';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { quoteToReservation } from '../../shared/util/booking-utils';
import { getThumbnailUrl } from '../../shared/util/car-utils';
import { formatCurrency } from '../../shared/util/currency';
import { formatDateByLocaleWithCheckTime } from '../../shared/util/date-transforms';
import { cleanFirestoreResult } from '../../shared/util/firestore-utils';

function QuoteDetail({ route }) {
  const [car, setCar] = useState({});
  const [quote, setQuote] = useState({});
  const [loading, setLoading] = useState(true);
  const [reservation, setReservation] = useState({});
  const [bottomActionHeight, setBottomActionHeight] = useState(0);

  const currentUser = useSelector((state) => state.users.currentUser);
  const currency = useSelector((state) => state.users.currency);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const navigation = useNavigation();
  const maxWidth = useMaxWidth();
  const { quoteId } = route?.params ?? {};

  useEffect(() => {
    if (quoteId) {
      setLoading(true);
      firebaseApp
        .firestore()
        .collection('quotes')
        .doc(quoteId)
        .get()
        .then((resQuote) => {
          setQuote(cleanFirestoreResult(resQuote));
          setLoading(false);
        })
        .catch((e) => {
          errorHandler(e, true);
          setLoading(false);
        });
    }
  }, [quoteId]);

  useEffect(() => {
    if (quote?.carId) {
      firebaseApp
        .firestore()
        .collection('cars')
        .doc(quote?.carId)
        .get()
        .then((resCar) => {
          setCar(cleanFirestoreResult(resCar));
        })
        .catch((e) => {
          errorHandler(e, true);
        });
    }
  }, [quote]);

  useEffect(() => {
    if (currentUser?.uid && quote?.uid && currentUser?.conditionAccepted && currentUser?.uid !== quote?.userId) {
      goToPayment();
    }
  }, [quote, currentUser, navigation, goToPayment]);

  useEffect(() => {
    if (car?.uid && quote?.uid) {
      setReservation(
        quoteToReservation(currentUser, car, currency, quote?.dateRange, quote?.extraOptions, quote?.location, quote?.discountAmount ?? 0),
      );
    }
  }, [car, quote, currency, currentUser]);

  function goToLogin() {
    dispatch(userManagedRegisterFlow(true));
    navigation.navigate('LoginOrSignUp', { redirect: i18n.language + '/quote/' + quoteId });
  }

  const goToPayment = useCallback(() => {
    navigation.navigate('ConfirmAndPay', {
      carId: quote?.carId,
      from: quote?.dateRange?.from,
      to: quote?.dateRange?.to,
      pickUpPlaceId: quote?.location?.pickUp?.placeId,
      returnPlaceId: quote?.location?.return?.placeId,
      checkInHour: quote?.dateRange?.checkInHour,
      checkInMinute: quote?.dateRange?.checkInMinute,
      checkOutHour: quote?.dateRange?.checkOutHour,
      checkOutMinute: quote?.dateRange?.checkOutMinute,
      extraOptions: quote?.extraOptions,
      quoteId: quote?.uid,
    });
  }, [
    navigation,
    quote?.carId,
    quote?.dateRange?.checkInHour,
    quote?.dateRange?.checkInMinute,
    quote?.dateRange?.checkOutHour,
    quote?.dateRange?.checkOutMinute,
    quote?.dateRange?.from,
    quote?.dateRange?.to,
    quote?.extraOptions,
    quote?.location?.pickUp?.placeId,
    quote?.location?.return?.placeId,
    quote?.uid,
  ]);

  const onCopy = () => {
    Clipboard.setStringAsync('https://app.flyandcar.com/' + i18n.language + '/quote/' + quote?.uid);
    successToast(t('quote.linkCopySuccessfully'));
  };

  return (
    <Layout>
      <ScrollView contentContainerStyle={{ width: maxWidth > 700 ? 700 : maxWidth, alignSelf: 'center' }}>
        <VStack space={'md'} mx={'$screenMargin'} pt={'$4'} mb={bottomActionHeight}>
          <SmallCard
            loading={!car?.brand}
            imageUrl={getThumbnailUrl(car?.images)}
            title={car?.brand + ' ' + car?.model}
            subTitle={t('car.carCardInfo', { type: t('addCar.' + car?.type), fuel: car?.fuel, seats: car?.seats })}
            onPress={() => navigation.navigate('CarDetail', { carId: car?.uid })}
            info={
              formatCurrency(
                Math.round(
                  reservation?.invoiceOwner?.totalPrice /
                    reservation?.invoiceOwner?.numberOfDay /
                    reservation?.invoiceOwner?.smallerCurrencyUnit,
                ),
                reservation?.invoiceOwner?.currency,
              ) +
              ' ' +
              t('extraOption.perDay')
            }
          />
          <Divider />
          <HStack justifyContent={'space-evenly'} alignItems={'center'} space={'md'}>
            <VStack space={'sm'} maxWidth={'45%'}>
              <Heading size={'h4'}>{t('rental.pickUp')}</Heading>
              <Text>{formatDateByLocaleWithCheckTime(reservation?.from, reservation?.checkInHour, reservation?.checkInMinute)}</Text>
            </VStack>
            <Divider orientation="vertical" />
            <VStack space={'sm'} maxWidth={'45%'}>
              <Heading size={'h4'}>{t('rental.return')}</Heading>
              <Text>{formatDateByLocaleWithCheckTime(reservation?.to, reservation?.checkOutHour, reservation?.checkOutMinute)}</Text>
            </VStack>
          </HStack>
          <Divider />
          <ProfileCardById userId={car?.ownerId} onPress={() => navigation.navigate('PublicProfileScreen', { userId: car.ownerId })} />
          <Divider />
          <DeliveryLocationDisplay car={car} location={quote?.location} setLocation={() => null} isReadOnly={true} />
          <Divider />
          {quote?.extraOptions && (
            <ChooseExtraOption
              car={car}
              lang={currentUser?.language}
              selectedExtraOption={quote?.extraOptions ?? []}
              setSelectedExtraOptions={() => null}
              displayAll={true}
              isReadOnly={true}
            />
          )}
          <Heading>{t('quote.invoice')}</Heading>
          <InvoiceDetail reservationInvoice={reservation?.invoice} isLoading={!reservation?.carId} />
          <Divider />
          <InsuranceDetail insurances={car?.insurances ?? []} car={car} currency={currency} loading={loading} />
        </VStack>
      </ScrollView>
      <BottomAction
        onLayout={(event) => {
          setBottomActionHeight(event?.nativeEvent?.layout?.height ?? 0);
        }}>
        {quote?.userId && quote?.userId === currentUser?.uid ? (
          <VStack space={'xs'} px={'$screenMargin'} my={'$4'} w={maxWidth > 700 ? 700 : maxWidth} alignSelf="center">
            <Button variant={'outline'} onPress={onCopy}>
              <ButtonText>{t('quote.copyLink')}</ButtonText>
            </Button>
          </VStack>
        ) : currentUser?.uid ? (
          <VStack space={'xs'} px={'$screenMargin'} my={'$4'} w={maxWidth > 700 ? 700 : maxWidth} alignSelf="center">
            <Button isDisabled={loading} onPress={goToPayment}>
              {loading && <ButtonSpinner mr="$1" />}
              <ButtonText>{t('global.book')}</ButtonText>
            </Button>
          </VStack>
        ) : (
          <VStack space={'xs'} px={'$screenMargin'} my={'$4'} w={maxWidth > 700 ? 700 : maxWidth} alignSelf="center">
            <Text variant={'grey'} size={'secondary'}>
              {t('quote.pleaseSignIn')}
            </Text>
            <Button onPress={goToLogin}>
              <ButtonText>{t('screen.loginOrSignUp')}</ButtonText>
            </Button>
          </VStack>
        )}
      </BottomAction>
    </Layout>
  );
}

export default QuoteDetail;
