import { createSlice } from '@reduxjs/toolkit';

// Initial state
export const initialState = {
  location: {},
  dateRange: {},
  filters: {},
};

// Create slice
const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    // Action to set the location
    setLocation(state, action) {
      state.location = action.payload;
    },
    // Action to set the date range
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
    // Action to set the filters
    setFilters(state, action) {
      state.filters = action.payload;
    },
  },
});

// Export actions and reducer
export const { setLocation, setDateRange, setFilters } = searchSlice.actions;
export default searchSlice.reducer;
