import { firebaseApp } from './firebaseFactory';

export function getOneRoom(roomId) {
  return firebaseApp
    .database()
    .ref(`/rooms/${roomId}`)
    .once('value')
    .then((room) => {
      return { ...room.val(), uid: roomId };
    });
}
