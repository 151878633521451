import React from 'react';

import { Text, useToken } from '@gluestack-ui/themed';

export default function BadgeText({ colorScheme, children }) {
  const color900 = useToken('colors', (colorScheme ?? 'light') + '500');

  return (
    <Text color={color900} fontWeight={'$bold'} size={'secondary'}>
      {children}
    </Text>
  );
}
