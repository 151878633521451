import React from 'react';
import { useWindowDimensions } from 'react-native';

import { Box, HStack, ScrollView, useToken } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { useIsDrawer } from '../../provider/isDrawerProvider';
import { useMaxWidth } from '../../provider/maxWidthProvider';
import { Metrics } from '../../themes';
import Layout from '../layout/layout';
import UnderLineButton from '../pressable/underLineButton';

export default function ScreenSpliter({ left, right, isRigthDisplayable, onClose, headerText, isBottomBar }) {
  const { t } = useTranslation();
  const isMobile = !useIsDrawer();
  const maxWidth = useMaxWidth();
  const { height } = useWindowDimensions();
  const space4 = parseInt(useToken('space', '4'), 10);

  if (isMobile && isRigthDisplayable) {
    return (
      <>
        <Box mx={'$screenMargin'} mb={'$4'}>
          <UnderLineButton text={t('global.back')} onPress={onClose} hideLeftIcon={true} displayRightIcon={true} />
        </Box>
        {right}
      </>
    );
  }

  if (isMobile) {
    return (
      <ScrollView testID="scrollView" h={height - Metrics.headerSize - Metrics.headerTitleSize}>
        {left}
      </ScrollView>
    );
  }

  return (
    <Layout isBottomBar={isBottomBar}>
      <HStack w={maxWidth} alignSelf="center" h={'$full'}>
        <ScrollView w={300} h={'$full'}>
          {left}
        </ScrollView>
        <Box w={maxWidth - 300} alignItems={'center'} justifyContent={'flex-start'}>
          <Box
            m={'$2'}
            w={maxWidth - 300 - space4}
            borderColor={'$light200'}
            borderWidth={'$1'}
            rounded={'$md'}
            overflow={'hidden'}
            h={height - Metrics.headerSize - Metrics.headerTitleSize}>
            {right}
          </Box>
        </Box>
      </HStack>
    </Layout>
  );
}
