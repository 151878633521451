import React from 'react';

import { HStack, VStack } from '@gluestack-ui/themed';

import Skeleton from '../../../shared/components/skeleton/skeleton';
import WebScrollView from '../../../shared/components/web/webScrollView';
import { useMaxWidth } from '../../../shared/provider/maxWidthProvider';
import { Metrics } from '../../../shared/themes';

export default function CarDocumentSkeleton() {
  const maxWidth = useMaxWidth();

  return (
    <WebScrollView testID={'scrollView'} hideFooter={true} maxWidth={maxWidth > 700 ? 700 : maxWidth}>
      <VStack my={'$4'} mx={'$screenMargin'} space={'lg'}>
        <Skeleton.Text lines={2} />
        <VStack space={'lg'} mt={'$4'}>
          <Skeleton.Text lines={1} h={'$6'} w={200} />
          <Skeleton rounded={'$xl'} h={'$10'} w={400} />
        </VStack>
        <VStack space={'sm'} mt={'$4'}>
          <Skeleton.Text lines={1} h={'$6'} w={200} />
          <HStack space={'md'} flexWrap={'wrap'}>
            <Skeleton rounded={'$xl'} h={'$24'} w={'$24'} />
            <Skeleton rounded={'$xl'} h={'$24'} w={'$24'} />
            <Skeleton rounded={'$xl'} h={'$24'} w={'$24'} />
          </HStack>
        </VStack>
      </VStack>
    </WebScrollView>
  );
}
