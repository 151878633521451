import React from 'react';

import { IsDrawerProvider } from './isDrawerProvider';
import { MaxWidthProvider } from './maxWidthProvider';
import { ToastProvider } from './toastProvider';

// Create context

export const CustomProvider = ({ children }) => {
  return (
    <IsDrawerProvider>
      <MaxWidthProvider>
        <ToastProvider>{children}</ToastProvider>
      </MaxWidthProvider>
    </IsDrawerProvider>
  );
};
