import React, { useState } from 'react';
import { I18nManager, TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, HStack, Icon, Text } from '@gluestack-ui/themed';

import BottomSheet from '../bottomSheet/bottomSheet';
import BottomSheetFlatList from '../bottomSheet/bottomSheetFlatList';
import { PressableRow } from '../pressable/pressableRow';

export default function Select({ testID, data, onSelect, placeHolder, value, title }) {
  const [isOpen, setOpen] = useState(false);

  function onClick(item) {
    setOpen(false);
    onSelect(item);
  }

  function renderItem({ item }) {
    return (
      <Box mx={'$4'}>
        <PressableRow
          testID={`item.${testID}`}
          text={item.label}
          infoElement={item.value === value ? <Icon as={Ionicons} name={'checkmark-outline'} size={'md'} /> : undefined}
          onPress={() => onClick(item)}
          removeRightCheveron={true}
        />
      </Box>
    );
  }

  function getLabelFromValue(val) {
    if (!val) {
      return placeHolder;
    }
    const label = data.filter((d) => d.value === val);

    if (label && label.length > 0) {
      return label[0].label;
    } else {
      return placeHolder;
    }
  }

  return (
    <>
      <TouchableOpacity testID={`button.${testID}`} onPress={() => setOpen(true)}>
        <Box variant={'select'} justifyContent={'center'}>
          <HStack justifyContent={'space-between'} alignItems={'center'}>
            <Text alignSelf={'center'} size={'secondary'}>
              {getLabelFromValue(value)}
            </Text>
            <Icon
              as={Ionicons}
              name={'chevron-down-outline'}
              size={'md'}
              ml={'$2'}
              style={{ transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }] }}
            />
          </HStack>
        </Box>
      </TouchableOpacity>
      <BottomSheet isOpen={isOpen} setOpen={setOpen} headerText={title}>
        <BottomSheetFlatList data={data} keyExtractor={(i) => i.value} renderItem={renderItem} />
      </BottomSheet>
    </>
  );
}
