import { useState } from 'react';

import { Ionicons } from '@expo/vector-icons';
import { Box, Checkbox, CheckboxIcon, CheckboxIndicator, CheckboxLabel, Divider, Heading, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getPriceFromCurrency } from '../../util/currency';
import { getDefaultLanguage } from '../../util/language-utils';
import ActionSheet from '../actionSheet/actionSheet';
import UnderLineButton from '../pressable/underLineButton';
import ContentTranslation from '../translation/contentTanslation';

function ChooseExtraOption({ car, lang, setSelectedExtraOptions, selectedExtraOption, displayAll, isReadOnly }) {
  const [isOpen, setOpen] = useState(false);

  const { t } = useTranslation();

  const currentUser = useSelector((state) => state.users.currentUser);
  const currency = useSelector((state) => state.users.currency);

  const nbExtraOptionDisplay = displayAll ? 100000 : 2;

  if (!car?.extraOptions || car?.extraOptions.length === 0) {
    return null;
  }

  function selectExtraOption(option) {
    if (setSelectedExtraOptions) {
      const toRemove = selectedExtraOption.some((eO) => eO.id === option?.id);
      if (toRemove) {
        setSelectedExtraOptions([...selectedExtraOption.filter((op) => op?.id !== option?.id)]);
      } else {
        setSelectedExtraOptions([...selectedExtraOption, option]);
      }
    }
  }

  function getSuffix(option) {
    const time = option.perTime === 'day' ? t('extraOption.perDay') : t('extraOption.perRental');

    const price = getPriceFromCurrency(option?.price, currentUser?.currency, currency, 10);
    return ` - ${price} ${time}`;
  }

  const extraOptionsToDisplay = isReadOnly
    ? car?.extraOptions.filter((eO) => selectedExtraOption.some((e) => e?.id === eO?.id))
    : car?.extraOptions.slice(0, nbExtraOptionDisplay);

  return (
    <VStack>
      <VStack space={'md'}>
        <Heading>{isReadOnly ? t('extraOption.titleReadyOnly') : t('extraOption.title')}</Heading>
        <VStack space={'sm'}>
          {extraOptionsToDisplay &&
            extraOptionsToDisplay.map((option) => (
              <Checkbox
                key={option?.id}
                aria-label={'day'}
                size="md"
                justifyContent={'space-between'}
                isDisabled={isReadOnly}
                isChecked={selectedExtraOption ? selectedExtraOption.some((e) => e?.id === option?.id) : false}
                onChange={() => selectExtraOption(option)}>
                <Box w={'80%'}>
                  <ContentTranslation
                    object={car}
                    field={option?.id}
                    lang={lang ?? getDefaultLanguage()}
                    limit={100000000}
                    suffix={getSuffix(option)}
                  />
                </Box>

                <CheckboxIndicator ml="$2">
                  <CheckboxIcon as={Ionicons} name={'checkmark-outline'} />
                </CheckboxIndicator>
              </Checkbox>
            ))}
        </VStack>
        {car?.extraOptions && car?.extraOptions?.length > nbExtraOptionDisplay && (
          <UnderLineButton text={t('global.seeMore')} onPress={() => setOpen(true)} />
        )}
        <Divider />
      </VStack>
      {car?.extraOptions && car?.extraOptions?.length > nbExtraOptionDisplay && (
        <ActionSheet isOpen={isOpen} setOpen={setOpen} headerText={t('extraOption.title')}>
          <VStack mx={'$screenMargin'} space={'sm'}>
            {car?.extraOptions &&
              car?.extraOptions.map((option) => (
                <Checkbox
                  key={option?.id}
                  aria-label={'day'}
                  size="md"
                  justifyContent={'space-between'}
                  isDisabled={isReadOnly}
                  isChecked={selectedExtraOption ? selectedExtraOption.some((e) => e?.id === option?.id) : false}
                  onChange={() => selectExtraOption(option)}>
                  <Box w={'80%'}>
                    <ContentTranslation
                      object={car}
                      field={option?.id}
                      lang={lang ?? getDefaultLanguage()}
                      limit={100000000}
                      suffix={getSuffix(option)}
                    />
                  </Box>
                  <CheckboxIndicator ml="$2">
                    <CheckboxIcon as={Ionicons} name={'checkmark-outline'} />
                  </CheckboxIndicator>
                </Checkbox>
              ))}
          </VStack>
        </ActionSheet>
      )}
    </VStack>
  );
}

export default ChooseExtraOption;
