import { dateRangeToArrayDays } from './date-transforms';

/**
 * Generates a filter query string based on provided date range and user filters.
 * @param {object} dateRange - An object containing `from` and `to` date strings.
 * @param {object} filters - An object containing various filter criteria.
 * @returns {string} The generated filter query string.
 */
function getFilterString(dateRange, filters) {
  let filterParts = ['visible:true']; // Example base filter

  if (dateRange && dateRange?.from && dateRange?.to) {
    const arrayDays = dateRangeToArrayDays(dateRange);
    arrayDays.forEach((day) => {
      filterParts.push("NOT bookedDays:'" + day + "'");
    });
  }

  // Additional filters based on user selection
  if (filters) {
    if (filters.transmission) {
      filterParts.push(`transmission:'${filters.transmission}'`);
    }
    if (filters.fuel) {
      filterParts.push(`fuel:'${filters.fuel}'`);
    }
    if (filters.carType && filters.carType.length) {
      // Assuming "carType" is an array of selected types
      const carTypeFilter = filters.carType.map((type) => `type:'${type}'`).join(' OR ');
      filterParts.push(`(${carTypeFilter})`);
    }
    if (filters.feature && filters.feature.length) {
      // Assuming "feature" is an array of selected features
      const featureFilter = filters.feature.map((feat) => `features:'${feat}'`).join(' AND ');
      filterParts.push(`(${featureFilter})`);
    }
  }

  return filterParts.join(' AND ');
}

export { getFilterString };
