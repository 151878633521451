import React, { useEffect, useState } from 'react';

import { Divider, Heading, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DeliveryLocationDisplay from '../../../shared/components/deliveryLocation/deliveryLocationDisplay';
import ChooseExtraOption from '../../../shared/components/extraOption/chooseExtraOption';
import InvoiceDetail from '../../../shared/components/info/invoiceDetail';
import SmallCard from '../../../shared/components/pressable/smallCard';
import { firebaseApp } from '../../../shared/services/firebase/firebaseFactory';
import { quoteToReservation } from '../../../shared/util/booking-utils';
import { getThumbnailUrl } from '../../../shared/util/car-utils';
import { formatCurrency } from '../../../shared/util/currency';
import { formatDateByLocaleWithCheckTime } from '../../../shared/util/date-transforms';
import { cleanFirestoreResult } from '../../../shared/util/firestore-utils';

function PreviewQuote() {
  const [car, setCar] = useState({});
  const [reservation, setReservation] = useState({});
  const { t } = useTranslation();

  const currentUser = useSelector((state) => state.users.currentUser);
  const quote = useSelector((state) => state.quote.quote);
  const currency = useSelector((state) => state.users.currency);

  useEffect(() => {
    if (currentUser?.uid) {
      firebaseApp
        .firestore()
        .collection('cars')
        .doc(quote?.carId)
        .get()
        .then(cleanFirestoreResult)
        .then((resCar) => setCar(resCar));
    }
  }, [currentUser?.uid, quote?.carId]);

  useEffect(() => {
    if (car?.uid) {
      setReservation(
        quoteToReservation(currentUser, car, currency, quote?.dateRange, quote?.extraOptions, quote?.location, quote?.discountAmount ?? 0),
      );
    }
  }, [car, quote, currency, currentUser]);

  return (
    <VStack space={'md'}>
      <Text variant={'grey'}>{t('quote.previewSubTitle')}</Text>
      <SmallCard
        loading={!car?.brand}
        imageUrl={getThumbnailUrl(car?.images)}
        title={car?.brand + ' ' + car?.model}
        subTitle={t('car.carCardInfo', { type: t('addCar.' + car?.type), fuel: car?.fuel, seats: car?.seats })}
        info={
          formatCurrency(
            Math.round(
              (reservation?.invoiceOwner?.totalPrice + reservation?.invoiceOwner?.serviceFee) /
                reservation?.invoiceOwner?.numberOfDay /
                reservation?.invoiceOwner?.smallerCurrencyUnit,
            ),
            reservation?.invoiceOwner?.currency,
          ) +
          ' ' +
          t('extraOption.perDay')
        }
        isDisable={true}
      />
      <Divider />
      <HStack justifyContent={'space-evenly'} alignItems={'center'} space={'md'}>
        <VStack space={'sm'} maxWidth={'45%'}>
          <Heading size={'h4'}>{t('rental.pickUp')}</Heading>
          <Text>
            {formatDateByLocaleWithCheckTime(quote?.dateRange?.from, quote?.dateRange?.checkInHour, quote?.dateRange?.checkInMinute)}
          </Text>
        </VStack>
        <Divider orientation="vertical" />
        <VStack space={'sm'} maxWidth={'45%'}>
          <Heading size={'h4'}>{t('rental.return')}</Heading>
          <Text>
            {formatDateByLocaleWithCheckTime(quote?.dateRange?.to, quote?.dateRange?.checkOutHour, quote?.dateRange?.checkOutMinute)}
          </Text>
        </VStack>
      </HStack>
      <Divider />
      <DeliveryLocationDisplay car={car} location={quote?.location} setLocation={() => null} isReadOnly={true} />
      <Divider />
      {quote?.extraOptions && (
        <ChooseExtraOption
          car={car}
          lang={currentUser?.language}
          selectedExtraOption={quote?.extraOptions ?? []}
          setSelectedExtraOptions={() => null}
          displayAll={true}
          isReadOnly={true}
        />
      )}
      <Heading>{t('quote.invoice')}</Heading>
      <InvoiceDetail reservationInvoice={reservation?.invoice} ownerInvoice={reservation?.invoiceOwner} isLoading={!reservation?.carId} />
    </VStack>
  );
}

export default PreviewQuote;
