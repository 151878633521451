import React from 'react';

import { HStack, Switch, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TimePicker from '../../../shared/components/timePicker/timePicker';
import Toggle from '../../../shared/components/toggle/toggle';
import { carAddingSetter } from '../../../shared/reducers/car.reducer';

function BookingSetting() {
  const car = useSelector((state) => state.cars.carAdding);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function isAvailableAllTime() {
    return car?.availableFromHour + car?.availableFromMinute === 0 && car?.availableToHour * 100 + car?.availableToMinute === 2400;
  }

  function pressAllTime(booled) {
    dispatch(
      carAddingSetter({
        ...car,
        availableFromHour: booled ? 0 : 6,
        availableFromMinute: 0,
        availableToHour: booled ? 24 : 21,
        availableToMinute: 0,
      }),
    );
  }

  return (
    <VStack space={'md'}>
      <Text mb={'$4'} variant={'grey'} size={'secondary'}>
        {t('addCar.bookingSettingDescription')}
      </Text>
      <HStack space={'md'} alignItems={'center'} justifyContent={'space-between'}>
        <Text>{t('addCar.allTimeAvailable')}</Text>
        <Toggle isChecked={isAvailableAllTime()} onPress={pressAllTime} />
      </HStack>
      {!isAvailableAllTime() && (
        <>
          <TimePicker
            w={'100%'}
            title={t('addCar.availableFrom')}
            time={car?.availableFromHour ? new Date().setHours(car?.availableFromHour, car?.availableFromMinute, 0, 0) : undefined}
            setTime={(time) =>
              dispatch(carAddingSetter({ ...car, availableFromHour: time.getHours(), availableFromMinute: time.getMinutes() }))
            }
          />
          <TimePicker
            w={'100%'}
            title={t('addCar.availableTo')}
            time={car?.availableToHour ? new Date().setHours(car?.availableToHour, car?.availableToMinute, 0, 0) : undefined}
            setTime={(time) =>
              dispatch(carAddingSetter({ ...car, availableToHour: time.getHours(), availableToMinute: time.getMinutes() }))
            }
          />
        </>
      )}
    </VStack>
  );
}

export default BookingSetting;
