import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { Box, Divider, Heading, ScrollView, VStack } from '@gluestack-ui/themed';
import { Link, useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';

import { setUserMode, userCurrentLogOut, userUpdateRequest } from '../../../shared/reducers/user.reducer';
import { signOut } from '../../services/firebase/auth-firebase';
import { firebaseApp } from '../../services/firebase/firebaseFactory';
import { InternalLinks } from '../../util/link-utils';
import ProfileCard from '../entities/user/ProfileCard';
import ProfileCardById from '../entities/user/ProfileCardById';
import { errorHandler } from '../errorHandler/errorHander';
import { PressableRow } from '../pressable/pressableRow';
import { RentYourCar } from '../static/rentYourCar';

function DrawerContent() {
  const [isAdmin, setAdmin] = useState(false);
  const currentUser = useSelector((state) => state.users.currentUser);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const { height } = useWindowDimensions();
  const navigation = useNavigation();
  const { top } = useSafeAreaInsets();

  function logout() {
    signOut().catch((e) => errorHandler(e, true));
    dispatch(setUserMode(true));
    dispatch(userCurrentLogOut());
  }

  useEffect(() => {
    if (firebaseApp.auth().currentUser) {
      firebaseApp
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          // Confirm the user is an Admin.
          if (idTokenResult?.claims?.admin) {
            // Show admin UI.
            setAdmin(true);
          } else {
            // Show regular user UI.
            setAdmin(false);
          }
        })
        .catch((error) => {
          errorHandler(error, true);
        });
    }
  }, [currentUser]);

  return (
    <ScrollView h={height - 200} bgColor={'$white'} mt={top}>
      <VStack p={'$4'} space={'lg'} w={'$full'}>
        {currentUser?.uid && (
          <VStack space={'md'} w={'$full'}>
            <RentYourCar />
            <Link w={'$full'} to={{ screen: 'Profile' }}>
              <Box w={'$full'}>
                <ProfileCardById userId={currentUser?.uid} />
              </Box>
            </Link>
            <VStack w={'$full'}>
              <Divider />
              <PressableRow to={{ screen: 'Search' }} text={t('placeHolder.getACar')} leftIconName={'search-outline'} />
              <PressableRow py={'$2'} to={{ screen: 'Message' }} text={t('screen.message')} leftIconName={'chatbubbles-outline'} />
              <Divider />
            </VStack>
            <VStack>
              <Heading mt={'$2'} size={'h4'}>
                {t('global.driver')}
              </Heading>
              <PressableRow py={'$2'} to={{ screen: 'Rental' }} text={t('screen.reservations')} leftIconName={'key-outline'} />
              <Divider />
            </VStack>
            {currentUser?.isOwner !== undefined && (
              <>
                <VStack>
                  <Heading size={'h4'} mt={'$2'}>
                    {t('global.owner')}
                  </Heading>
                  <PressableRow py={'$2'} to={{ screen: 'OwnerHome' }} text={t('ownerCar.yourEvent')} leftIconName={'bookmark-outline'} />
                  <PressableRow py={'$2'} to={{ screen: 'OwnerCars' }} text={t('screen.ownerCar')} leftIconName={'car-outline'} />
                  <PressableRow py={'$2'} to={{ screen: 'QuoteTabs' }} text={t('screen.quotes')} leftIconName={'receipt-outline'} />
                  <PressableRow py={'$2'} to={{ screen: 'OwnerCalendar' }} text={t('screen.calendar')} leftIconName={'calendar-outline'} />
                  <Divider />
                </VStack>
              </>
            )}
            {isAdmin && (
              <>
                <PressableRow
                  py={'$2'}
                  to={{ screen: 'BackOfficeHome' }}
                  text={t('screen.backOffice')}
                  leftIconName={'shield-checkmark-outline'}
                />
                <Divider />
              </>
            )}
            <VStack space={'sm'}>
              <PressableRow
                py={'$2'}
                text={t('ownerRequest.getHelp')}
                onPress={() => navigation.navigate('CustomWebView', { url: InternalLinks.helpHome, title: t('profile.getHelp') })}
                isExternal
                leftIconName={'information-circle-outline'}
              />
              <Divider />
              <PressableRow py={'$2'} text={t('login.logout')} onPress={logout} leftIconName={'log-out-outline'} />
            </VStack>
          </VStack>
        )}
        {!currentUser?.uid && (
          <>
            <PressableRow py={'$2'} to={{ screen: 'Search' }} text={t('placeHolder.getACar')} leftIconName={'search-outline'} />
            <Divider />
            <PressableRow
              py={'$2'}
              to={{ screen: 'LoginOrSignUp' }}
              text={t('screen.loginOrSignUp')}
              href={'/' + i18n.language + '/login-or-sign-up'}
              leftIconName={'log-in-outline'}
            />
            <Divider />
            <PressableRow
              py={'$2'}
              text={t('ownerRequest.getHelp')}
              onPress={() => navigation.navigate('CustomWebView', { url: InternalLinks.helpHome, title: t('profile.getHelp') })}
              leftIconName={'information-circle-outline'}
            />
          </>
        )}
      </VStack>
    </ScrollView>
  );
}

export default DrawerContent;
