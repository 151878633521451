import * as ImageManipulator from 'expo-image-manipulator';
import { SaveFormat } from 'expo-image-manipulator';
import uuid from 'react-native-uuid';

import { IS_WEB } from './device-utils';
import appConfig from '../../config/app-config';
import { errorHandler } from '../components/errorHandler/errorHander';
import { firebaseApp } from '../services/firebase/firebaseFactory';

export function uriToBlob(uri) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    // If successful -> return with blob
    xhr.onload = function () {
      resolve(xhr.response);
    };

    // reject on error
    xhr.onerror = function () {
      reject(new Error('uriToBlob failed'));
    };

    // Set the response type to 'blob' - this means the server's response
    // will be accessed as a binary object
    xhr.responseType = 'blob';

    // Initialize the request. The third argument set to 'true' denotes
    // that the request is asynchronous
    xhr.open('GET', uri, true);

    // Send the request. The 'null' argument means that no body content is given for the request
    xhr.send(null);
  });
}

export async function updloadMedia(path, storagePath, onSuccess, step = -1, rotate = 0) {
  const fileUrl = `${storagePath}/${uuid.v4()}.jpeg`;

  const reference = firebaseApp.storage().ref(fileUrl);

  const manipulatorResult = await ImageManipulator.manipulateAsync(path, [{ rotate: rotate }], {
    compress: 0.9,
    format: SaveFormat.JPEG,
  });

  try {
    console.debug('imageToUpload', path, fileUrl);

    const task = IS_WEB ? reference.put(await uriToBlob(manipulatorResult.uri)) : reference.putFile(manipulatorResult.uri);

    task.catch((e) => errorHandler(e, true));

    await task.then(() => {
      console.debug('imageUploaded', path, fileUrl);
      if (step >= 0) {
        onSuccess(step, `https://${appConfig.storageBucketDomain}/${fileUrl}`);
      } else {
        onSuccess(path, `https://${appConfig.storageBucketDomain}/${fileUrl}`); // check in google service file the bucket name
      }
    });
  } catch (e) {
    errorHandler(e, true);
  }
}

export async function updloadDocument(path, storagePath, onSuccess) {
  const fileUrl = `${storagePath}/${uuid.v4()}.pdf`;

  const reference = firebaseApp.storage().ref(fileUrl);

  try {
    console.debug('docToUpload', path, fileUrl);
    const task = IS_WEB ? reference.put(await uriToBlob(path)) : reference.putFile(path);

    task.catch((e) => errorHandler(e, true));

    await task.then(() => {
      console.debug('docUploaded', path, fileUrl);
      onSuccess(path, `https://${appConfig.storageBucketDomain}/${fileUrl}`); // check in google service file the bucket name
    });
  } catch (e) {
    errorHandler(e, true);
  }
}

export function setCookie(name, value, days) {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function getCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export const expiredDateInUTC = (additionalDays) => {
  const expiredDate = new Date();

  expiredDate.setDate(expiredDate.getDate() + additionalDays);

  return expiredDate;
};
