import appConfig from '../../config/app-config';
import { firebaseApp } from '../services/firebase/firebaseFactory';

export function analyticsSetUserId(userId) {
  firebaseApp.analytics().setUserId(userId);

  if (!window?.fbq) {
    console.warn('No FB Function');
    return;
  }

  window?.fbq('init', appConfig.facebookId, { external_id: userId });
}

export function analyticsCurrency(currency) {}

export function analyticsAddPayment() {
  if (!window?.fbq) {
    console.warn('No FB Function');
    return;
  }
}

export function analyticsRegistration(method) {
  if (!window?.fbq) {
    console.warn('No FB Function');
    return;
  }
  window?.fbq('track', 'CompleteRegistration', {});
}

function getAvgPrice(car) {
  if (!car?.priceData) {
    return 0;
  }

  let sum = 0;

  car?.priceData?.forEach((range) => {
    sum += range?.price;
  });
  return sum / car?.priceData?.length;
}

export function analyticsAddCar(car) {
  firebaseApp.analytics().logEvent('addCar', car);
  if (!window?.fbq) {
    console.warn('No FB Function');
    return;
  }
  window?.fbq('track', 'SubmitApplication', {
    content_ids: car?.uid,
    content_type: car?.type,
    currency: car?.currency,
    contents: [{ id: car?.uid, quantity: 1 }],
    value: getAvgPrice(car),
  });
}

export function analyticsSearch(location, dateRange) {}

export function analyticsScreenView(routeName) {}

export function analyticsPurchase(reservation) {
  firebaseApp.analytics().logEvent('reservationPayed', reservation);

  if (!window?.fbq) {
    console.warn('No FB Function');
    return;
  }

  window?.fbq('track', 'Purchase', {
    value: reservation?.invoice?.totalPrice / 100,
    content_ids: [reservation?.car?.uid],
    content_type: reservation?.car?.type,
    currency: reservation?.invoice?.currency,
  });
}

export function analyticsCarDisplayed(car) {
  firebaseApp.analytics().logEvent('viewCar', car);

  if (!window?.fbq) {
    console.warn('No FB Function');
    return;
  }

  window?.fbq('track', 'ViewContent', {
    content_ids: [car?.uid],
    content_type: car?.type,
    currency: car?.currency,
    value: getAvgPrice(car),
  });
}

export function analyticsReservationCreated(reservation) {
  firebaseApp.analytics().logEvent('reservationCreated', reservation);

  if (!window?.fbq) {
    console.warn('No FB Function');
    return;
  }

  window?.fbq('track', 'AddToCart', {
    content_ids: reservation?.car?.uid,
    content_type: reservation?.car?.type,
    currency: reservation?.invoice?.currency,
    contents: [{ id: reservation?.car?.uid, quantity: 1 }],
    value: reservation?.invoice?.totalPrice / 100,
  });
}

export function analyticsAppMounted() {
  firebaseApp.analytics().logEvent('appMounted');
}
