import React, { useState } from 'react';

import { Box, Heading, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { getDefaultLanguage } from '../../util/language-utils';
import ActionSheet from '../actionSheet/actionSheet';
import UnderLineButton from '../pressable/underLineButton';
import Skeleton from '../skeleton/skeleton';
import ContentTranslation from '../translation/contentTanslation';

export default function InfoExtender({ object, field, lang, headerText, showHeader, limit, loading, seeMoreText, color }) {
  const [isOpen, setOpen] = useState(false);
  const [isSeeMore, setSeeMore] = useState(false);

  const { t } = useTranslation();

  if (loading) {
    return <Skeleton.Text lines={2} />;
  }

  return (
    <Box>
      <VStack space={'md'}>
        {showHeader ? <Heading color={color}>{headerText}</Heading> : null}
        <ContentTranslation object={object} field={field} lang={lang ?? getDefaultLanguage()} limit={limit} onTruncated={setSeeMore} />
        {isSeeMore && (
          <UnderLineButton color={color} text={seeMoreText ? seeMoreText : t('global.seeMore')} onPress={() => setOpen(true)} />
        )}
      </VStack>
      <ActionSheet isOpen={isOpen} setOpen={setOpen} headerText={headerText}>
        <Box mx={'$4'}>
          <ContentTranslation object={object} field={field} lang={lang} limit={100000000} />
        </Box>
      </ActionSheet>
    </Box>
  );
}
