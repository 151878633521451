import React, { useCallback, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, Divider, Heading, HStack, VStack } from '@gluestack-ui/themed';
import { Text } from '@gluestack-ui/themed';
import { useToken } from '@gluestack-ui/themed';
import Handlebars from 'react-native-handlebars';
import WebView from 'react-native-webview';

import BottomSheet from '../../shared/components/bottomSheet/bottomSheet';
import FilterComponent from '../../shared/components/firestore/filterComponent';
import FirestoreList from '../../shared/components/firestore/firestoreList';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { IS_WEB } from '../../shared/util/device-utils';
import { cleanFirestoreListResult, cleanFirestoreResult } from '../../shared/util/firestore-utils';

const baseFilter = [];

function BackofficeMail() {
  const [filters, setFilters] = useState(baseFilter);
  const [isOpen, setOpen] = useState(false);
  const [emailToDisplay, setEmailToDisplay] = useState(null);
  const maxWidth = useMaxWidth();
  const space4 = useToken('space', '4');
  const screenMargin = useToken('space', 'screenMargin');

  async function displayEmail(mail) {
    const templateStored = await firebaseApp
      .firestore()
      .collection('template_mail')
      .doc(mail?.template?.name)
      .get()
      .then(cleanFirestoreResult);

    const templatePartials = await firebaseApp
      .firestore()
      .collection('template_mail')
      .where('partial', '==', true)
      .get()
      .then(cleanFirestoreListResult);

    templatePartials.map((partial) => Handlebars.registerPartial(partial.uid, partial.html));

    setEmailToDisplay({
      html: Handlebars.compile(templateStored?.html)(mail?.template?.data),
      subject: Handlebars.compile(templateStored?.subject)(mail?.template?.data),
      ...mail,
    });
    setOpen(true);
  }

  const renderItem = useCallback(
    ({ item }) => (
      <TouchableOpacity onPress={() => displayEmail(item)}>
        <HStack justifyContent="space-between" alignItems="center">
          <VStack space={'sm'} w={'50%'}>
            <Heading>{item?.to}</Heading>
            <Text>{item?.template?.data?.subject}</Text>
            <Text>{item?.uid}</Text>
          </VStack>
          <Text>{item?.delivery?.state ?? 'NOT TRIGGERED YET'}</Text>
        </HStack>

        <Divider />
      </TouchableOpacity>
    ),
    [],
  );

  return (
    <>
      <FirestoreList
        headerComponent={
          <FilterComponent
            containerStyles={{ alignSelf: 'center', width: maxWidth, px: screenMargin }}
            allowedFields={['uid', 'to', 'from']}
            baseFilters={baseFilter}
            filters={filters}
            setFilters={setFilters}
          />
        }
        keyExtractor={(item) => item?.uid}
        ItemSeparatorComponent={<Box my={'$2'} />}
        isConnected={true}
        orderBy={{ field: 'createAt', order: 'desc' }}
        collectionName={'mail'}
        filters={filters}
        renderItem={renderItem}
        contentContainerStyle={{ alignSelf: 'center', width: maxWidth, paddingVertical: space4, paddingHorizontal: screenMargin }}
      />
      <BottomSheet isOpen={isOpen} setOpen={setOpen} snapToTop={true} headerText={'Email content'}>
        <VStack space={'md'} mx={'$screenMargin'} flex={1}>
          <VStack m={'$2'} p={'$2'} rounded={'$md'} borderWidth={'$1'}>
            <Text>Create at {new Date((emailToDisplay?.createAt ?? 0) * 1000).toString()}</Text>
            <Text>End at: {new Date((emailToDisplay?.delivery?.endTime?.seconds ?? 0) * 1000).toString()}</Text>
            <Text>Error: {emailToDisplay?.delivery?.error}</Text>
            <Text>Subject: {emailToDisplay?.subject}</Text>
          </VStack>
          <Box rounded={'$md'} borderWidth={'$1'} overflow="hidden" flex={1}>
            {IS_WEB ? (
              <div dangerouslySetInnerHTML={{ __html: emailToDisplay?.html }} />
            ) : (
              <WebView originWhitelist={['*']} source={{ html: emailToDisplay?.html }} style={{ flex: 1 }} />
            )}
          </Box>
        </VStack>
      </BottomSheet>
    </>
  );
}

export default BackofficeMail;
