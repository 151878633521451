import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RefreshControl, TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Button, ButtonText, Divider, Heading, HStack, Icon, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '../../shared/components/badge/alert';
import CalendarAvailabilitySheet from '../../shared/components/calendar/calendarAvailability';
import CarReviews from '../../shared/components/entities/review/carReviews';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import InfoCard from '../../shared/components/infoCard/infoCard';
import { PressableRow } from '../../shared/components/pressable/pressableRow';
import UnderLineButton from '../../shared/components/pressable/underLineButton';
import ReservationEvent from '../../shared/components/reservationEvent/reservationEvent';
import successToast from '../../shared/components/toast/successToast';
import WebScrollView from '../../shared/components/web/webScrollView';
import WebSpliter from '../../shared/components/web/webSpliter';
import { carAddingSetter } from '../../shared/reducers/car.reducer';
import { oneCarListener, updateOneCar } from '../../shared/services/firebase/car-firebase';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { getAllInterestingCarReservation } from '../../shared/services/firebase/reservation-firebase';
import { alertTypes } from '../../shared/util/alert-utils';
import { addCarStep } from '../../shared/util/car-step-list';
import { carDocumentTypes, reservationStatus } from '../../shared/util/car-utils';
import { cleanFirestoreListResult, cleanFirestoreResult } from '../../shared/util/firestore-utils';

function OwnerCarsDetailScreen({ route }) {
  const { carId } = route?.params ?? {};
  const currentUser = useSelector((state) => state.users.currentUser);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [car, setCar] = useState({});
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [ownerBookedDays, setOwnerBookedDays] = useState([]);

  const navigation = useNavigation();
  const { t } = useTranslation();

  function getCarReservation(carUId) {
    if (carUId && currentUser?.uid) {
      setLoadingEvents(true);
      getAllInterestingCarReservation(carUId, currentUser?.uid)
        .then((res) => {
          setReservations(res);
          setLoadingEvents(false);
        })
        .catch((e) => {
          errorHandler(e, true);
          setLoadingEvents(false);
        });
    }
  }

  useEffect(() => {
    console.debug('Effect: refresh car, navigation');
    if (car?.brand) {
      navigation.setOptions({ title: car?.brand + ' ' + car?.model });
    }
    setOwnerBookedDays(car?.ownerBookedDays ? car?.ownerBookedDays : []);
  }, [car, navigation]);

  function onCarSnapshot(snapshot) {
    const cleanData = cleanFirestoreResult(snapshot);
    firebaseApp
      .firestore()
      .collection('cars')
      .doc(cleanData.uid)
      .collection('documents')
      .get()
      .then(cleanFirestoreListResult)
      .then((documents) => {
        setCar({ ...cleanData, documents: documents });
        setLoading(false);
      })
      .catch(onCarSnapshotError);
  }

  function onCarSnapshotError(e) {
    errorHandler(e, true);
    setLoading(false);
  }

  useEffect(() => {
    if (carId && currentUser?.uid) {
      console.debug('Effect: refresh car detail');
      getCarReservation(carId);
      const subscriber = oneCarListener(carId, onCarSnapshot, onCarSnapshotError);
      return () => subscriber();
    }
  }, [carId, currentUser]);

  function editCarListing() {
    dispatch(carAddingSetter(car));
    navigation.navigate('OwnerCarsEditListingScreen', { carId: carId });
  }

  const bookedDays = useMemo(() => (car?.bookedDays ? car?.bookedDays : []), [car?.bookedDays]);
  const originalOwnerBookedDays = useMemo(() => (car?.ownerBookedDays ? car?.ownerBookedDays : []), [car?.ownerBookedDays]);

  const updateBookedDays = useCallback(() => {
    setLoadingUpdate(true);

    const bD = bookedDays.filter(
      (item) =>
        ownerBookedDays.filter((oB) => oB === item).length === 0 && originalOwnerBookedDays.filter((oB) => oB === item).length === 0,
    );
    updateOneCar({ uid: carId, bookedDays: [...bD, ...ownerBookedDays], ownerBookedDays: ownerBookedDays })
      .then(() => {
        successToast(t('ownerCar.successChangeCarAvailability'));
        setLoadingUpdate(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoadingUpdate(false);
      });
  }, [bookedDays, carId, originalOwnerBookedDays, ownerBookedDays, t]);

  function refresh() {
    getCarReservation(carId);
  }

  return (
    <WebScrollView refreshControl={<RefreshControl refreshing={loading || loadingEvents || loadingUpdate} onRefresh={refresh} />}>
      <CalendarAvailabilitySheet
        isOpen={isCalendarOpen}
        setOpen={setCalendarOpen}
        ownerBookedDays={ownerBookedDays}
        originalOwnerBookedDay={originalOwnerBookedDays}
        setOwnerBookedDays={setOwnerBookedDays}
        bookedDays={bookedDays.filter((item) => originalOwnerBookedDays.filter((oB) => oB === item).length === 0)}
        rightButtonText={t('ownerCar.update')}
        onPress={updateBookedDays}
        info={t('ownerCar.selectUnavailable')}
      />
      <WebSpliter
        space={'md'}
        inverse={true}
        left={
          <VStack space={'md'} mx={'$screenMargin'}>
            <HStack justifyContent={'space-between'} mt={'$4'}>
              <Heading>{t('ownerCar.yourEvent')}</Heading>
              <UnderLineButton
                text={t('global.seeMore')}
                hideLeftIcon={true}
                onPress={() => navigation.navigate('OwnerReservationList', { carId: car?.uid })}
              />
            </HStack>
            <ReservationEvent
              loading={loadingEvents}
              reservationList={reservations.filter(
                (r) => r.status !== reservationStatus.ownerReject || r.status !== reservationStatus.finished,
              )}
            />
          </VStack>
        }
        right={
          <VStack space={'md'} mx={'$screenMargin'}>
            {!loading && car?.accountValidated === false && (
              <TouchableOpacity onPress={() => navigation.navigate('OwnerHome')}>
                <InfoCard text={t('ownerCar.setPaymentAccount')} mode={'error'} />
              </TouchableOpacity>
            )}
            {car?.accountValidated && car?.carValidated && !car?.carApproved && <Alert types={[alertTypes.waitingValidation]} />}
            <Button
              variant={'outline'}
              onPress={() => navigation.navigate('AddQuoteFlow', { carId: car?.uid })}
              startIcon={<Icon as={Ionicons} name={'add-outline'} color={'$black'} size={'md'} />}>
              <Icon as={Ionicons} name={'add'} />
              <ButtonText>{t('quote.createReservation')}</ButtonText>
            </Button>
            <VStack>
              <PressableRow
                isSkeleton={loading}
                infoElement={
                  <Alert badgeOnly={true} types={addCarStep.map((step) => car?.alert?.[step?.titleToTranslate]).filter((a) => a)} />
                }
                leftIconName={'create-outline'}
                text={t('ownerCar.editListing')}
                onPress={editCarListing}
              />
              <Divider />
              <PressableRow
                isSkeleton={loading}
                leftIconName={'calendar-outline'}
                text={t('ownerCar.editAvailability')}
                onPress={() => setCalendarOpen(true)}
              />
              <Divider />
              <PressableRow
                isSkeleton={loading}
                leftIconName={'document-text-outline'}
                text={t('ownerCar.carDocuments')}
                onPress={() => navigation.navigate('OwnerCarsDocumentsScreen', { carId: car.uid })}
                infoElement={
                  <Alert
                    badgeOnly={true}
                    types={[
                      car?.alert?.[carDocumentTypes.autorizationOfUse],
                      car?.alert?.[carDocumentTypes.registrationCard],
                      car?.alert?.ID,
                      car?.alert?.[carDocumentTypes.insuranceCertificate] || car?.alert?.[carDocumentTypes.insurancePolicy],
                      car?.alert?.[carDocumentTypes.technicalInspection],
                    ]}
                  />
                }
              />
              <Divider />
            </VStack>
            <UnderLineButton
              onPress={() => navigation.navigate('CarDetail', { carId: car.uid, hideBookingAction: true })}
              text={t('ownerCar.showPreview')}
            />
          </VStack>
        }
      />
      <VStack mx={'$screenMargin'} mt={'$8'} space={'md'}>
        <Heading>{t('ownerCar.reviews')}</Heading>
        <CarReviews carId={car.uid} />
      </VStack>
    </WebScrollView>
  );
}

export default OwnerCarsDetailScreen;
