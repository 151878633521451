import { getLocales } from 'expo-localization';

export const supportedLang = ['ar', 'cs', 'de', 'en', 'es', 'fr', 'he', 'it', 'ja', 'pt', 'ru', 'sk', 'zh'];

export function getCalendarLang(userLang) {
  if (!userLang) {
    return 'en';
  }

  if (userLang in ['es', 'en', 'fr', 'zh', 'ru']) {
    return userLang;
  } else {
    return 'en';
  }
}

export function getDefaultLanguage() {
  if (!getLocales()[0]?.languageCode) {
    return 'en';
  }
  if (supportedLang.includes(getLocales()[0]?.languageCode)) {
    return getLocales()[0]?.languageCode;
  }
  return 'en';
}
