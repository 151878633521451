import { getLocales } from 'expo-localization';

import { moment } from './moment';

export function formatDateByLocale(date, format = 'lll', removeYear = true) {
  // Convert the date to a Moment.js object
  let momentDate = moment(date).utc();

  // Set the locale based on the language tag

  const deviceLangCode = getLocales()[0]?.languageCode;
  if (moment.locales().includes(deviceLangCode)) {
    momentDate.locale(deviceLangCode);
  }

  // Format and return the date Without Year
  if (removeYear) {
    const year = momentDate.year();
    return momentDate.format(format).replace(year, '');
  }
  return momentDate.format(format);
}

export function formatDateByLocaleNoUtc(date, format = 'lll', removeYear = true) {
  // Convert the date to a Moment.js object
  let momentDate = moment(date);

  // Set the locale based on the language tag

  const deviceLangCode = getLocales()[0]?.languageCode;
  if (moment.locales().includes(deviceLangCode)) {
    momentDate.locale(deviceLangCode);
  }

  // Format and return the date Without Year
  if (removeYear) {
    const year = momentDate.year();
    return momentDate.format(format).replace(year, '');
  }
  return momentDate.format(format);
}

export function formatDateByLocaleUnix(date, format = 'lll', removeYear = true) {
  // Convert the date to a Moment.js object
  let momentDate = moment.unix(date).utc();

  return formatDateByLocale(momentDate, format, removeYear);
}

export function formatDateByLocaleWithCheckTime(date, hour, minute) {
  if (date === undefined || hour === undefined || minute === undefined) {
    return '';
  }
  // Convert the date to a Moment.js object
  let momentDate = moment.unix(date).utc().startOf('day').hours(hour).minute(minute);

  return formatDateByLocale(momentDate);
}

export const defaultDateRange = { checkInHour: 15, checkInMinute: 0, checkOutHour: 11, checkOutMinute: 0 };

export function dayOfYearToString(dayOfYear) {
  const date = moment('2024-01-01').dayOfYear(dayOfYear);
  return date.format('MMM DD');
}

export function displayRangeDate(
  from,
  to,
  size = 'small',
  placeholder = 'When ?*',
  checkInHour = 0,
  checkInMinute = 0,
  checkOutHour = 0,
  checkOutMinute = 0,
) {
  if (!from || !to) {
    return placeholder;
  }
  const fromDate = moment.unix(from).utc().startOf('day').hours(checkInHour).minute(checkInMinute);
  const toDate = moment.unix(to).utc().startOf('day').hours(checkOutHour).minute(checkOutMinute);

  if (size === 'large') {
    return formatDateByLocaleNoUtc(fromDate) + ' - ' + formatDateByLocale(toDate);
  }
  return formatDateByLocaleNoUtc(fromDate, 'MMM D', false) + ' - ' + formatDateByLocaleNoUtc(toDate, 'MMM D', false);
}

export function displayTime(dt) {
  const toFormat = moment(dt);

  return formatDateByLocaleNoUtc(toFormat, 'LT', false);
}

export function dateToCalendarTimeString(date) {
  const toFormat = moment(date).utc();

  return formatDateByLocale(toFormat, 'YYYY-MM-DD', false);
}

export function dateToMoment(date) {
  return moment(date).utc();
}

const leapDayYear = [2024, 2028, 2032, 2036];

export function dateToDayOfYears(date) {
  const dayOfYear = moment(date).utc().dayOfYear();
  const year = moment(date).utc().year();

  if (!leapDayYear.includes(year) && dayOfYear >= 60) {
    return dayOfYear + 1;
  }
  return dayOfYear;
}

export function dateRangeToDayOfYearsArray(dateRange) {
  const arrayDate = dateRangeToArrayDays(dateRange);

  return arrayDate.map((d) => {
    const dayOfYear = moment(d).utc().dayOfYear();
    const year = moment(d).utc().year();

    if (!leapDayYear.includes(year) && dayOfYear >= 60) {
      return dayOfYear + 1;
    }
    return dayOfYear;
  });
}

export function rangeToDateRange(
  from,
  to,
  checkInHour = defaultDateRange.checkInHour,
  checkInMinute = defaultDateRange.checkInMinute,
  checkOutHour = defaultDateRange.checkOutHour,
  checkOutMinute = defaultDateRange.checkOutMinute,
) {
  return {
    from: parseInt(from, 10),
    fromDateString: dateToCalendarTimeString(new Date(from * 1000)),
    to: parseInt(to, 10),
    toDateString: dateToCalendarTimeString(new Date(to * 1000)),
    checkInHour: checkInHour,
    checkInMinute: checkInMinute,
    checkOutHour: checkOutHour,
    checkOutMinute: checkOutMinute,
  };
}

export function dateRangeToArrayDays(dateRange) {
  const arrayDays = [];
  if (dateRange?.fromDateString) {
    arrayDays.push(dateRange?.fromDateString);
  }
  if (dateRange?.toDateString) {
    const tDate = new Date(dateRange.to * 1000);
    for (let dt = new Date(dateRange.from * 1000 + 86400000); dt.getTime() < tDate.getTime(); dt = new Date(dt.getTime() + 86400000)) {
      arrayDays.push(dateToCalendarTimeString(dt));
    }
    arrayDays.push(dateRange?.toDateString);
  }
  return arrayDays;
}

export function displayCheckCarTime(checkHour, checkMinute) {
  const hour = checkHour < 10 ? '0' + checkHour : checkHour;
  const minute = checkMinute < 10 ? '0' + checkMinute : checkMinute;
  return hour + ':' + minute;
}
