import React from 'react';

import { FormControl, FormControlLabel, FormControlLabelText, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Select from '../../shared/components/select/select';
import WebScrollView from '../../shared/components/web/webScrollView';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { userUpdateRequest } from '../../shared/reducers/user.reducer';
import { supportedCurrency } from '../../shared/util/currency';

function CardDetail() {
  const currentUser = useSelector((state) => state.users.currentUser);
  const dispatch = useDispatch();
  const maxWidth = useMaxWidth();

  const { t } = useTranslation();

  const updateUser = (user) => {
    dispatch(userUpdateRequest(user));
  };

  return (
    <WebScrollView maxWidth={maxWidth > 700 ? 700 : maxWidth} hideFooter={true}>
      <VStack space={'md'} mx={'$screenMargin'} mt={'$4'}>
        <FormControl isRequired isReadOnly>
          <FormControlLabel>
            <FormControlLabelText>{t('global.currency')}</FormControlLabelText>
          </FormControlLabel>
          <Select
            title={t('global.currency')}
            data={supportedCurrency}
            onSelect={(item) => updateUser({ uid: currentUser?.uid, currency: item.value })}
            placeHolder={t('global.currency')}
            value={currentUser?.currency}
          />
        </FormControl>
      </VStack>
    </WebScrollView>
  );
}

export default CardDetail;
