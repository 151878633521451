import { useState } from 'react';

import { Box, Button, ButtonText, Heading, Input, InputField, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import BottomAction from '../../shared/components/bottomAction/bottomAction';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Select from '../../shared/components/select/select';
import successToast from '../../shared/components/toast/successToast';
import WebScrollView from '../../shared/components/web/webScrollView';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { setUserMode, userCurrentLogOut } from '../../shared/reducers/user.reducer';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';

function DeleteAccountScreen() {
  const [deleteInfo, setDeleteInfo] = useState();
  const [bottomActionHeight, setBottomActionHeight] = useState(0);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigation = useNavigation();
  const maxWidth = useMaxWidth();

  async function deleteAccount() {
    await firebaseApp
      .firestore()
      .collection('users')
      .doc(firebaseApp.auth()?.currentUser?.uid)
      .update({ deleteInfo })
      .then(() => {
        firebaseApp
          .auth()
          .currentUser.delete()
          .then(() => {
            dispatch(setUserMode(true));
            dispatch(userCurrentLogOut());
            navigation.popToTop();
            successToast(t('delete.success'));
          })
          .catch((e) => {
            errorHandler(e, true);
          });
      })
      .catch((e) => errorHandler(e, true));
  }

  return (
    <>
      <WebScrollView maxWidth={maxWidth > 700 ? 700 : maxWidth} hideFooter={true} mb={bottomActionHeight}>
        <VStack space={'lg'} mx={'$screenMargin'} mt={'$8'}>
          <Text>{t('delete.info')}</Text>
          <VStack space={'md'}>
            <Heading size={'h4'}>{t('delete.reasonInfo')}</Heading>
            <Select
              title={t('delete.selectReason')}
              data={[
                { value: 'notSatisfied', label: t('delete.reason.notSatisfied') },
                { value: 'notUse', label: t('delete.reason.notUse') },
                { value: 'other', label: t('delete.reason.other') },
              ]}
              onSelect={(item) => setDeleteInfo((prev) => ({ ...prev, reason: item.value }))}
              placeholder={t('delete.selectReason')}
              value={deleteInfo?.reason}
            />
          </VStack>
          <VStack space={'md'}>
            <Heading size={'h4'}>{t('delete.confirmTitle', { keyword: t('delete.keyword') })}</Heading>
            <Input>
              <InputField
                defaultValue={deleteInfo?.keyword}
                onChangeText={(value) => setDeleteInfo((prev) => ({ ...prev, keyword: value }))}
              />
            </Input>
          </VStack>
        </VStack>
      </WebScrollView>
      <BottomAction
        onLayout={(event) => {
          setBottomActionHeight(event?.nativeEvent?.layout?.height ?? 0);
        }}>
        <Box width={maxWidth > 700 ? 700 : maxWidth} alignSelf="center" px={'$screenMargin'} py={'$4'}>
          <Button
            colorScheme={'error'}
            isDisabled={deleteInfo?.keyword !== t('delete.keyword') || !deleteInfo?.reason}
            onPress={deleteAccount}>
            <ButtonText>{t('delete.button')}</ButtonText>
          </Button>
        </Box>
      </BottomAction>
    </>
  );
}

export default DeleteAccountScreen;
