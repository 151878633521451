import React, { useState } from 'react';

import { Box, Button, ButtonSpinner, ButtonText, Divider, Heading, ScrollView, Text, View, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import * as Linking from 'expo-linking';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';

import BottomAction from '../../shared/components/bottomAction/bottomAction';
import CalendarSheet from '../../shared/components/calendar/calendarSheet';
import DeliveryLocationDisplay from '../../shared/components/deliveryLocation/deliveryLocationDisplay';
import CarSmallCard from '../../shared/components/entities/car/carSmallCard';
import LoginPlaceHolder from '../../shared/components/entities/user/loginPlaceHolder';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import ChooseExtraOption from '../../shared/components/extraOption/chooseExtraOption';
import InvoiceDetail from '../../shared/components/info/invoiceDetail';
import InsuranceDetail from '../../shared/components/insurance/insuranceDetail';
import Layout from '../../shared/components/layout/layout';
import PaymentLegalText from '../../shared/components/legal/paymentLegal';
import { PressableRow } from '../../shared/components/pressable/pressableRow';
import errorToast from '../../shared/components/toast/errorToast';
import successToast from '../../shared/components/toast/successToast';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { Metrics } from '../../shared/themes';
import { analyticsPurchase } from '../../shared/util/analytics';
import { displayRangeDate } from '../../shared/util/date-transforms';
import { InternalLinks } from '../../shared/util/link-utils';
import UserDocuments from '../profile/driverDocuments/userDocumentsDetail';

function Booking({
  paymentInfo,
  dateRange,
  setDateRange,
  isInvoiceLoading,
  car,
  location,
  setLocation,
  selectedExtraOption,
  setSelectedExtraOptions,
  quoteId,
}) {
  const currentUser = useSelector((state) => state.users.currentUser);
  const currency = useSelector((state) => state.users.currency);

  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [driverDocuments, setDriverDocuments] = useState([]);
  const [deliveryOk, setDeliveryOk] = useState(false);
  const [bottomActionHeight, setBottomActionHeight] = useState(0);

  const navigation = useNavigation();
  const stripe = useStripe();
  const elements = useElements();

  const { t, i18n } = useTranslation();
  const { bottom } = useSafeAreaInsets();
  const maxWidth = useMaxWidth();

  function timeValidator() {
    const availableFromTime = Number(car.availableFromHour) * 100 + Number(car.availableFromMinute);
    const availableToTime = Number(car.availableToHour) * 100 + Number(car.availableToMinute);
    const checkInTime = Number(dateRange.checkInHour) * 100 + Number(dateRange.checkInMinute);
    const checkOutTime = Number(dateRange.checkOutHour) * 100 + Number(dateRange.checkOutMinute);

    return (
      checkInTime >= availableFromTime &&
      checkInTime <= availableToTime &&
      checkOutTime >= availableFromTime &&
      checkOutTime <= availableToTime
    );
  }

  function driverValidator() {
    return driverDocuments?.length > 0;
  }

  function validator() {
    return timeValidator() && driverValidator();
  }

  function reserveCar() {
    if (validator()) {
      console.info('Start ReservationPayment');
      setPaymentLoading(true);
      stripe
        .confirmPayment({
          elements,
          confirmParams: {
            return_url: Linking.createURL(
              '/' + i18n.language + `/reservation-detail?carId=${car.uid}&reservationId=${paymentInfo.reservation.uid}`,
            ),
          },
        })
        .then((confirmPay) => {
          if (confirmPay.error) {
            errorHandler(confirmPay.error, true);
            setPaymentLoading(false);
          } else {
            successToast(t('success.paymentSuccess'));
            setPaymentLoading(false);
            analyticsPurchase(paymentInfo?.reservation);
          }
        })
        .catch((e) => {
          errorHandler(e, true);
          setPaymentLoading(false);
        });
    } else {
      if (!timeValidator()) {
        errorToast(t('error.ownerAvailableTime'));
      } else if (!driverValidator()) {
        errorToast(t('error.addDriver'));
      } else {
        errorToast(t('error.setCard'));
      }
      setPaymentLoading(false);
    }
  }

  if (!currentUser?.uid) {
    return (
      <Layout>
        <Box py={'$4'} px={'$screenMargin'} w={maxWidth} alignSelf="center">
          <LoginPlaceHolder />
        </Box>
      </Layout>
    );
  }

  return (
    <View flex={1}>
      <ScrollView
        contentContainerStyle={{ width: maxWidth > 700 ? 700 : maxWidth, alignSelf: 'center', paddingBottom: bottomActionHeight }}>
        <VStack space={'md'} my={'$4'} mx={'$screenMargin'}>
          <CarSmallCard car={car} dateRange={dateRange} disabled={true} />
          <Divider />
          <Heading>{t('reservation.date')}</Heading>
          <Box>
            <PressableRow
              leftIconName={null}
              onPress={() => setCalendarOpen(true)}
              rightIconName={'chevron-forward-outline'}
              text={displayRangeDate(
                dateRange.from,
                dateRange.to,
                'large',
                '',
                dateRange.checkInHour,
                dateRange.checkInMinute,
                dateRange.checkOutHour,
                dateRange.checkOutMinute,
              )}
            />
            <CalendarSheet
              isOpen={isCalendarOpen}
              setOpen={setCalendarOpen}
              dateRange={dateRange}
              setDateRange={setDateRange}
              priceData={car?.priceData}
            />
          </Box>
          {!isInvoiceLoading && !timeValidator() && (
            <Text size={'secondary'} color={'$error500'}>
              {t('reservation.ownerAvailable', {
                from: car.availableFromHour + ':' + car.availableFromMinute,
                to: car.availableToHour + ':' + car.availableToMinute,
              })}
            </Text>
          )}
          <Divider />
          <DeliveryLocationDisplay
            isReadOnly={quoteId !== undefined}
            car={car}
            loading={isInvoiceLoading}
            location={paymentInfo?.reservation?.delivery}
            setLocation={setLocation}
            setDeliveryOk={setDeliveryOk}
          />
          <Divider />
          <ChooseExtraOption
            car={car}
            lang={currentUser?.language}
            selectedExtraOption={selectedExtraOption}
            setSelectedExtraOptions={setSelectedExtraOptions}
          />
          <Heading>{t('reservation.invoice')}</Heading>
          <InvoiceDetail reservationInvoice={paymentInfo?.reservation?.invoice} isLoading={isInvoiceLoading} />
          <Divider />
          <InsuranceDetail
            insurances={car?.insurances ?? []}
            car={car}
            currency={currency}
            loading={!car?.uid}
            documents={paymentInfo.reservation?.insuranceDocuments}
          />
          <UserDocuments
            userId={currentUser?.uid}
            setDocuments={setDriverDocuments}
            isEditable={true}
            info={t('reservation.documentShareWithOwner')}
          />
          <Divider />
          <Heading>{t('reservation.paymentMethod')}</Heading>
          <PaymentElement />
          <Divider />
          <Heading>{t('reservation.cancelationPolicy')}</Heading>
          <PressableRow
            text={t('reservation.cancelationPolicy')}
            onPress={() =>
              navigation.navigate('CustomWebView', {
                url: InternalLinks.cancellationsPolicie,
                title: t('reservation.cancelationPolicy'),
              })
            }
          />
          <Divider />
        </VStack>
      </ScrollView>

      <BottomAction
        onLayout={(event) => {
          setBottomActionHeight(event?.nativeEvent?.layout?.height ?? 0);
        }}>
        <VStack space={'sm'} px={'$screenMargin'} py={'$2'} w={maxWidth > 700 ? 700 : maxWidth} alignSelf="center">
          <PaymentLegalText />
          <Button isDisabled={isPaymentLoading || isInvoiceLoading} onPress={reserveCar} mb={bottom}>
            {(isPaymentLoading || isInvoiceLoading) && <ButtonSpinner mr="$1" />}
            <ButtonText>{t('reservation.confirmAndPay')}</ButtonText>
          </Button>
          <Text>{t('reservation.confirmUntilOwnerConfirmInfo')}</Text>
        </VStack>
      </BottomAction>
    </View>
  );
}

export default Booking;
