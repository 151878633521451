import { FontAwesome, Fontisto, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';

export const carFeaturesList = [
  {
    name: 'airConditioning',
    translateName: 'addCar.airConditioning',
    iconName: 'snow-outline',
    iconLibrary: Ionicons,
    needToAsk: false,
  },
  {
    name: 'bluetooth',
    translateName: 'addCar.bluetooth',
    iconName: 'bluetooth-outline',
    iconLibrary: Ionicons,
    needToAsk: false,
  },
  {
    name: 'carPlay',
    translateName: 'addCar.carPlay',
    iconName: 'logo-apple',
    iconLibrary: Ionicons,
    needToAsk: false,
  },
  {
    name: 'androidAuto',
    translateName: 'addCar.androidAuto',
    iconName: 'logo-android',
    iconLibrary: Ionicons,
    needToAsk: false,
  },
  {
    name: 'gps',
    translateName: 'addCar.gps',
    iconName: 'navigate-circle-outline',
    iconLibrary: Ionicons,
    needToAsk: false,
  },
  {
    name: 'wheelChair',
    translateName: 'addCar.wheelChair',
    iconName: 'wheelchair',
    iconLibrary: FontAwesome,
    needToAsk: true,
  },
];

export const carFuelList = [
  {
    name: 'gazoline',
    translateName: 'addCar.gazoline',
  },
  {
    name: 'diesel',
    translateName: 'addCar.diesel',
  },
  {
    name: 'electric',
    translateName: 'addCar.electric',
  },
  {
    name: 'hybrid',
    translateName: 'addCar.hybrid',
  },
];

export const carTransmissionList = [
  {
    name: 'manual',
    translateName: 'addCar.manual',
  },
  {
    name: 'automatic',
    translateName: 'addCar.automatic',
  },
];

export const carTypeList = [
  {
    name: '4X4',
    translateName: 'addCar.4X4',
  },
  {
    name: 'SUV',
    translateName: 'addCar.SUV',
  },
  {
    name: 'VAN',
    translateName: 'addCar.VAN',
  },
  {
    name: 'SUPER_CAR',
    translateName: 'addCar.SUPER_CAR',
  },
  {
    name: 'MINI_BUS',
    translateName: 'addCar.MINIBUS',
  },
  {
    name: 'CAMPER_VAN',
    translateName: 'addCar.CAMPER_VAN',
  },
  {
    name: 'COUPE',
    translateName: 'addCar.COUPE',
  },
  {
    name: 'CITY',
    translateName: 'addCar.CITY',
  },
  {
    name: 'CABRIOLET',
    translateName: 'addCar.CABRIOLET',
  },
  {
    name: 'MICRO',
    translateName: 'addCar.MICRO',
  },
  {
    name: 'PICKUP',
    translateName: 'addCar.PICKUP',
  },
];

export function getThumbnailUrl(images) {
  if (images && images.length > 0) {
    const thumbnailArray = images.filter((i) => i.isThumbnail === true);
    if (thumbnailArray.length > 0) {
      return thumbnailArray[0].url;
    }
  }
}

// Don't forget to update the firebase functions status too
export const reservationStatus = {
  waitingOwner: 'WAITING_OWNER',
  ownerReject: 'OWNER_REJECT',
  waitingUser: 'WAITING_USER',
  waitingReview: 'WAITING_REVIEW',
  waitingCheckIn: 'WAITING_CHECK_IN',
  waitingCheckOut: 'WAITING_CHECK_OUT',
  waitingPayment: 'WAITING_PAYMENT',
  payementSucced: 'PAYMENT_SUCCED',
  waitingCollectPayment: 'WAITING_COLLECT_PAYMENT',
  finished: 'FINISHED',
};

export const carDocumentTypes = {
  registrationCard: 'REGISTRATION_CARD',
  autorizationOfUse: 'AUTHORIZATION_OF_USE',
  insuranceCertificate: 'INSURANCE_CERTIFICATE',
  insurancePolicy: 'INSURANCE_POLICY',
  technicalInspection: 'TECHNICAL_INSPECTION',
};

export function reservationStatusToColorSheme(reservStatus) {
  switch (reservStatus) {
    case reservationStatus.waitingReview:
    case reservationStatus.waitingOwner:
      return 'error';
    case reservationStatus.ownerReject:
      return 'coolGray';
    case reservationStatus.waitingUser:
    case reservationStatus.waitingCheckIn:
    case reservationStatus.waitingCheckOut:
    case reservationStatus.waitingPayment:
    case reservationStatus.waitingCollectPayment:
      return 'info';
    case reservationStatus.payementSucced:
    case reservationStatus.finished:
      return 'success';
  }
}

export const carDefaultField = () => ({
  avgRating: 5,
  numRatings: 0,
  visible: false,
  discountPerWeek: 0,
  discountPerMonth: 0,
  availableFromHour: 0,
  availableFromMinute: 0,
  availableToHour: 24,
  availableToMinute: 0,
  deliveryRadius: 10,
  priceData: [{ from: 1, to: 366, price: 70 }],
  currency: 'USD',
});
