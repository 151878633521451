import React, { useMemo } from 'react';

import { Ionicons } from '@expo/vector-icons';
import { Box, Divider, Heading, HStack, Icon, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { countryCodes } from 'react-native-country-codes-picker';

import { formatDateByLocale } from '../../util/date-transforms';
import Image from '../image/image';
import UnderLineButton from '../pressable/underLineButton';
import Skeleton from '../skeleton/skeleton';

export default function ProfileBigCard({
  loading,
  imageUrl,
  name,
  createAt,
  countryCode,
  numRatings,
  avgRating,
  numRentals,
  numTrips,
  isOwner,
  canEdit,
  isVerified,
  isMobile,
}) {
  const { t, i18n } = useTranslation();
  const navigation = useNavigation();

  const countryData = useMemo(() => {
    const cD = countryCodes.find((c) => c.code === countryCode);
    return {
      flag: cD?.flag,
      name: cD?.name && i18n.language in cD?.name ? cD?.name[i18n.language] : cD?.name?.en,
    };
  }, [countryCode, i18n]);

  if (loading) {
    return (
      <Box rounded={'$2xl'} hardShadow="3" bgColor="$white" w={'$full'}>
        <VStack mx={'$4'} alignItems="center" space="md" mt={'$8'}>
          <Skeleton rounded={'$full'} source={{ uri: imageUrl }} h={'$24'} w={'$24'} />
          <VStack space="xs" alignItems="center">
            <Skeleton.Text h={'$7'} w={100} />
            <Skeleton.Text w={120} />
          </VStack>
        </VStack>
        <VStack space="md" m={'$8'} alignItems={isMobile ? 'center' : 'flex-start'}>
          <HStack space="md" alignItems="center">
            <Skeleton.Text w={'$4'} />
            <Skeleton.Text w={50} />
          </HStack>
          <Divider />
          {isMobile ? (
            <HStack space="md" alignItems="center" justifyContent="space-evenly" w={'$full'}>
              <HStack space="md" alignItems="center">
                <Skeleton.Text w={'$4'} />
                <Skeleton.Text w={50} />
              </HStack>
              <Divider orientation="vertical" />
              <HStack space="md" alignItems="center">
                <Skeleton.Text w={'$4'} />
                <Skeleton.Text w={50} />
              </HStack>
            </HStack>
          ) : (
            <>
              <HStack space="md" alignItems="center">
                <Skeleton.Text w={'$4'} />
                <Skeleton.Text w={50} />
              </HStack>
              <Divider />
              <HStack space="md" alignItems="center">
                <Skeleton.Text w={'$4'} />
                <Skeleton.Text w={50} />
              </HStack>
            </>
          )}
        </VStack>
      </Box>
    );
  }

  return (
    <Box rounded={'$2xl'} hardShadow="3" bgColor="$white" w={'$full'}>
      {canEdit && (
        <Box position={'absolute'} top={'$4'} right={'$screenMargin'}>
          <UnderLineButton
            hideLeftIcon={true}
            text={t('profile.editProfile')}
            onPress={() => navigation.navigate('EditPublicProfileScreen')}
          />
        </Box>
      )}
      <VStack mx={'$4'} alignItems="center" space="md" mt={'$8'}>
        <Box h={'$24'} w={'$24'} rounded={'$full'}>
          <Image rounded={'$full'} source={{ uri: imageUrl }} h={'$24'} w={'$24'} />
          {isVerified && (
            <Icon as={Ionicons} name={'checkmark-circle'} color="$primary400" size="xl" position="absolute" bottom={'-$1'} right={'-$1'} />
          )}
        </Box>

        <VStack space="xs" alignItems="center">
          <Heading size="h1">{name}</Heading>
          <Text variant="grey" size="secondary">
            {t('profile.memberSince', {
              date: formatDateByLocale(new Date((createAt ?? 0) * 1000), 'DD MMM YYYY', false),
            })}
          </Text>
        </VStack>
        <HStack space="md" alignItems="center">
          <Heading>{countryData?.flag}</Heading>
          <Text>{countryData?.name}</Text>
        </HStack>
      </VStack>
      <VStack space="md" my={'$8'} mx={'$screenMargin'} alignItems={isMobile ? 'center' : 'flex-start'}>
        <HStack space="md" alignItems="center">
          <HStack space="xs">
            <Heading>{avgRating}</Heading>
            <Icon as={Ionicons} name={'star'} color="$black" size={'xs'} />
          </HStack>
          <Text fontSize={'$secondary'} fontFamily="$heading">
            {t('profile.rating')}
          </Text>
        </HStack>
        <Divider />
        {isMobile ? (
          <HStack space="md" alignItems="center" justifyContent="space-evenly" w={'$full'}>
            <HStack space="md" alignItems="center">
              <Heading>{numRatings ?? 0}</Heading>
              <Text fontSize={'$secondary'} fontFamily="$heading">
                {t('profile.reviews')}
              </Text>
            </HStack>
            <Divider orientation="vertical" />
            <HStack space="md" alignItems="center">
              <Heading>{isOwner ? numRentals ?? 0 : numTrips ?? 0}</Heading>
              <Text fontSize={'$secondary'} fontFamily="$heading">
                {isOwner ? t('profile.rentals') : t('profile.trips')}
              </Text>
            </HStack>
          </HStack>
        ) : (
          <>
            <HStack space="md" alignItems="center">
              <Heading>{numRatings ?? 0}</Heading>
              <Text fontSize={'$secondary'} fontFamily="$heading">
                {t('profile.reviews')}
              </Text>
            </HStack>
            <Divider />
            <HStack space="md" alignItems="center">
              <Heading>{isOwner ? numRentals ?? 0 : numTrips ?? 0}</Heading>
              <Text fontSize={'$secondary'} fontFamily="$heading">
                {isOwner ? t('profile.rentals') : t('profile.trips')}
              </Text>
            </HStack>
          </>
        )}
      </VStack>
    </Box>
  );
}
