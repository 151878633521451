import React from 'react';
import { SafeAreaView } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { goBackOrDefault } from '../../navigation/nav-ref';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Steps from '../../shared/components/steps/steps';
import successToast from '../../shared/components/toast/successToast';
import { updateOneCar } from '../../shared/services/firebase/car-firebase';
import { addCarStep } from '../../shared/util/car-step-list';

function OwnerCarsEditListingItem({ route }) {
  const carAdding = useSelector((state) => state.cars.carAdding);

  const { stepName } = route?.params ?? {};

  const { t } = useTranslation();
  const navigation = useNavigation();

  function updateCar() {
    updateOneCar(carAdding)
      .then((res) => {
        successToast(t('car.carUpdated'));
        goBackOrDefault('OwnerHome');
      })
      .catch((e) => errorHandler(e, true));
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Steps
        key={stepName + carAdding?.uid}
        routes={addCarStep.filter((step) => step.titleToTranslate === stepName)}
        onFinish={updateCar}
        onBack={() => navigation.navigate('OwnerCarsEditListingScreen', { carId: carAdding?.uid })}
        buttonFinishTitle={t('car.update')}
        dataToValidate={carAdding}
        loading={false}
      />
    </SafeAreaView>
  );
}

export default OwnerCarsEditListingItem;
