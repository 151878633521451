import React, { useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { Box, Heading, useToken } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import StepFooter from './stepbar/StepFooter';
import { goBackOrDefault } from '../../../navigation/nav-ref';
import { useIsDrawer } from '../../provider/isDrawerProvider';
import { useMaxWidth } from '../../provider/maxWidthProvider';
import { Metrics } from '../../themes';
import KeyboardAvoiding from '../keyboardAvoiding/keyboardAvoiding';

const Steps = ({
  routes,
  onFinish,
  onBack,
  loading,
  dataToValidate,
  buttonFinishTitle,
  bgColor,
  textColor,
  loadBarColor,
  lockDown,
  forceWidth,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState(routes[0]);
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const maxWidth = useMaxWidth();
  const screenMargin = useToken('space', 'screenMargin');

  function changeStepTo(id) {
    setCurrentStep(id);
    setSteps(routes[id]);
  }
  // Proceed to next step
  function nextStep() {
    if (currentStep + 1 < routes.length) {
      changeStepTo(currentStep + 1);
    } else {
      onFinish();
    }
  }

  // Go back to prev step
  function prevStep() {
    if (currentStep - 1 >= 0) {
      changeStepTo(currentStep - 1);
    } else {
      if (onBack) {
        onBack();
      } else {
        goBackOrDefault('Search');
      }
    }
  }

  return (
    <Box bgColor={bgColor} h={'$full'} w={'$full'} alignSelf={'center'}>
      <Box variant={'contentWithFooter'} h={height - 100}>
        {steps?.full ? (
          steps.component()
        ) : (
          <KeyboardAvoiding maxWidth={forceWidth ? '100%' : maxWidth > 700 ? 700 : maxWidth} mx={screenMargin}>
            <Heading color={textColor} size={'h1'} my={'$4'} numberOfLines={3}>
              {t(steps?.titleToTranslate)}
            </Heading>
            <Box pb={100} mb={100}>
              {steps ? steps.component() : <></>}
            </Box>
          </KeyboardAvoiding>
        )}
      </Box>
      <StepFooter
        maxWidth={forceWidth ? '100%' : maxWidth > 700 ? 700 : maxWidth}
        lockDown={lockDown}
        bgColor={bgColor}
        textColor={textColor}
        loadBarColor={loadBarColor}
        maxStep={routes?.length}
        currentStep={currentStep + 1}
        prevStep={prevStep}
        nextStep={nextStep}
        isForwardActive={() => steps.validator(dataToValidate)}
        loading={loading}
        buttonText={buttonFinishTitle ? buttonFinishTitle : t(steps?.buttonTextTranslate)}
      />
    </Box>
  );
};

export default Steps;
