import React from 'react';
import { Dimensions, I18nManager, Platform } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Heading, Icon } from '@gluestack-ui/themed';

import { IS_WEB } from './device-utils';
import { Colors, Metrics } from '../themes';

export const navigationOption = {
  headerBackImage: (p) => (
    <Icon
      ml={'$2'}
      rounded={'$full'}
      as={Ionicons}
      name={'chevron-back-outline'}
      style={{ transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }] }}
      size={'4xl'}
      color={'$black'}
    />
  ),
  headerTitle: ({ children }) => (
    <Heading size={'h1'} flexWrap={'wrap'} ml={'$2'} numberOfLines={1}>
      {children}
    </Heading>
  ),
  headerTitleAlign: 'left',
  headerStyle: { backgroundColor: Colors.fullWhite },
  headerShadowVisible: false,
  headerBackTitleVisible: false,
};

export const tabScreenOption = {
  tabBarStyle: { backgroundColor: 'rgba(0, 0, 0, 0)' },
  tabBarLabelStyle: { textTransform: 'none', fontFamily: 'Heebo_400Regular', fontSize: 16 },
  tabBarIndicatorStyle: { backgroundColor: Colors.blue[900] },
  tabBarContentContainerStyle: {
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  tabBarItemStyle: {
    width: Dimensions.get('window').width > 768 ? 150 : undefined,
    paddingHorizontal: 0,
    position: 'relative',
    padding: 0,
    height: 45,
  },
};
