/* eslint-disable no-undef */

import { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { Divider, Spinner, VStack } from '@gluestack-ui/themed';
import * as ImagePicker from 'expo-image-picker';
import { useTranslation } from 'react-i18next';

import { updloadMedia } from '../../util/storage-utils';
import ActionSheet from '../actionSheet/actionSheet';
import { errorHandler } from '../errorHandler/errorHander';
import { PressableRow } from '../pressable/pressableRow';

/* Custom media picker and uploader
add the component where you want with onUpload function 
it will allow user choose between lybrary and camera
it will upload in firebase store the media and return the dowload url as param of function onUpload
*/
export function Media(props) {
  const { selectorOpen, setSelector, onUpload, storagePath, forceCamera } = props;

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (forceCamera && selectorOpen) {
      openCamera();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectorOpen]);

  function success(path, url) {
    onUpload(url);
    onClose();
  }

  async function updloadImage(uri) {
    await updloadMedia(uri, storagePath, success);
  }

  // This function is triggered when the "Open camera" button pressed
  const openCamera = async () => {
    setLoading(true);
    const permission = ImagePicker.getCameraPermissionsAsync();

    if ((await permission).granted === false) {
      // Ask the user for the permission to access the camera
      const permissionResult = await ImagePicker.requestCameraPermissionsAsync();

      if (permissionResult.granted === false) {
        errorToast(t('error.permissionCmeraRequired'));
        return;
      }
    }

    const result = await ImagePicker.launchCameraAsync().catch((e) => errorHandler(e, true));

    // Explore the result

    if (result?.canceled !== undefined && !result?.canceled) {
      await updloadImage(result.assets[0].uri);
    } else {
      errorHandler(result, false);
      setLoading(false);
    }
  };

  const ImageChoice = async () => {
    setLoading(true);
    try {
      if (Platform.OS === 'ios') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          errorToast(t('error.permissionLibraryRequired'));
          return;
        }
      }
      const result = await ImagePicker.launchImageLibraryAsync().catch((e) => errorHandler(e, true));
      if (result?.canceled !== undefined && !result?.canceled) {
        for (let img of result.assets) {
          await updloadImage(img.uri);
        }
      } else {
        errorHandler(result, false);
      }
    } catch (e) {
      errorHandler(e, false);
      setLoading(false);
      errorToast(t('error.errorSelectImage'));
    }
  };

  function onClose() {
    setLoading(false);
    setSelector(false);
  }

  return (
    <VStack>
      <ActionSheet isOpen={selectorOpen} setOpen={setSelector} headerText={t('media.selectMedia')}>
        {loading ? (
          <Spinner p={'$4'} size={'lg'} />
        ) : (
          <VStack mx={'$4'}>
            <PressableRow leftIconName={'camera-outline'} text={t('media.camera')} onPress={openCamera} removeRightCheveron={true} />
            <Divider />
            <PressableRow leftIconName={'image-outline'} text={t('media.library')} onPress={ImageChoice} removeRightCheveron={true} />
            <Divider />
          </VStack>
        )}
      </ActionSheet>
    </VStack>
  );
}
