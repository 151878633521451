import React from 'react';

import { Box } from '@gluestack-ui/themed';

import Image from '../../../shared/components/image/image';

export default function PointIcon({ url, bgColor }) {
  return (
    <Box rounded={'$md'} bgColor={bgColor ?? '$light100'} h={'$12'} w={'$12'} alignItems="center" justifyContent="center">
      <Image source={{ uri: url }} h={'$8'} w={'$8'} resizeMode={'contain'} />
    </Box>
  );
}
