import React, { useState } from 'react';

import { GoogleLogin } from '@react-oauth/google';
import * as WebBrowser from 'expo-web-browser';
import { useTranslation } from 'react-i18next';

import UserConnectionListener from '../../shared/components/entities/user/userConnectionListener';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';

WebBrowser.maybeCompleteAuthSession();

function GoogleAuth({ onSuccess }) {
  const [userId, setUserId] = useState(null);

  const { i18n } = useTranslation();

  return (
    <>
      <GoogleLogin
        theme={'outline'}
        text={'continue_with'}
        locale={i18n.language}
        width={300}
        size="large"
        useOneTap={true}
        onSuccess={(credentialResponse) => {
          const googleCredential = firebaseApp.auth.GoogleAuthProvider.credential(credentialResponse.credential);
          firebaseApp
            .auth()
            .signInWithCredential(googleCredential)
            .then((user) => {
              setUserId(user.user.uid);
            });
        }}
        onError={(e) => {
          errorHandler(e, true);
        }}
      />
      <UserConnectionListener userId={userId} />
    </>
  );
}

export default GoogleAuth;
