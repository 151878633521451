import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Platform, useWindowDimensions } from 'react-native';

import { Box, Button, ButtonText, Center, HStack, useToken, VStack } from '@gluestack-ui/themed';
import { BottomSheetVirtualizedList } from '@gorhom/bottom-sheet';
import { useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { CalendarList } from 'react-native-calendars';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';

import CallendarCell from '../../../screen/calendar/calendarCell';
import { Colors, Metrics } from '../../themes';
import { calendarTheme } from '../../themes/calendarTheme';
import { IS_WEB, screenBreakPoint } from '../../util/device-utils';
import BottomSheet from '../bottomSheet/bottomSheet';
import BottomSheetFooter from '../bottomSheet/BottomSheetFooter';
import InfoCard from '../infoCard/infoCard';
import UnderLineButton from '../pressable/underLineButton';

function CalendarAvailabilitySheet({
  ownerBookedDays,
  originalOwnerBookedDay,
  setOwnerBookedDays,
  isOpen,
  setOpen,
  bookedDays,
  onPress,
  rightButtonText,
  info,
}) {
  const [markedDays, setMarkedDays] = useState({});

  const { t } = useTranslation();
  const { bottom } = useSafeAreaInsets();
  const { width } = useWindowDimensions();
  const spaceScreenMargin = useToken('space', Metrics.screenWidthMarging);

  const maxWidth = useMemo(
    () =>
      screenBreakPoint(width, [
        width - spaceScreenMargin * 2,
        width - spaceScreenMargin * 2,
        width - spaceScreenMargin * 2,
        990 * 0.5,
        1080 * 0.5,
      ]),
    [width, spaceScreenMargin],
  );

  useEffect(() => {
    console.debug('Effect: refresh ownerBookedDays, bookedDay');
    let marked = {};
    if (ownerBookedDays && ownerBookedDays.length > 0) {
      ownerBookedDays.forEach((d) => {
        marked[d] = { blockeds: [{ index: 0, color: Colors.black }], maxIndex: 1 };
      });
    }
    if (bookedDays && bookedDays.length > 0) {
      bookedDays.forEach((d) => {
        marked[d] = { disabled: true };
      });
    }
    setMarkedDays(marked);
  }, [ownerBookedDays, bookedDays]);

  const dayPress = useCallback(
    async (date) => {
      const dateFormated = date.dateString;
      setOwnerBookedDays((prev) => {
        const oB = prev ? prev : [];
        if (oB.filter((d) => d === dateFormated).length > 0) {
          return [...oB.filter((d) => d !== dateFormated)];
        } else {
          return [...oB, dateFormated];
        }
      });
    },
    [setOwnerBookedDays],
  );

  const clearFilter = useCallback(() => {
    setOwnerBookedDays(originalOwnerBookedDay);
  }, [originalOwnerBookedDay, setOwnerBookedDays]);

  const closeActionSheet = useCallback(() => {
    setOpen(false);
    if (onPress) {
      onPress();
    }
  }, [onPress, setOpen]);

  const renderFooter = useCallback(
    (props) => (
      <BottomSheetFooter {...props} bottomInset={bottom}>
        <VStack w={'100%'} bgColor={'$white'} mb={-bottom} paddingBottom={bottom}>
          {info && (
            <Box mx={'$2'}>
              <InfoCard text={info} />
            </Box>
          )}
          <HStack p={'$4'} justifyContent={'space-between'} w="100%">
            <Center>
              <UnderLineButton text={t('search.clear')} hideLeftIcon={true} onPress={clearFilter} />
            </Center>
            <Center>
              <Button pr={'$4'} pl={'$4'} onPress={closeActionSheet}>
                <ButtonText>{rightButtonText ? rightButtonText : t('global.select')}</ButtonText>
              </Button>
            </Center>
          </HStack>
        </VStack>
      </BottomSheetFooter>
    ),
    [bottom, info, t, clearFilter, closeActionSheet, rightButtonText],
  );

  return (
    <BottomSheet isOpen={isOpen} setOpen={setOpen} renderFooter={renderFooter}>
      <Box space={'md'} h={'100%'} w={'100%'} pb={100}>
        {!IS_WEB ? (
          <BottomSheetVirtualizedList // Fake VList to have gesture working on android
            data={['one']}
            focusHook={useFocusEffect}
            keyExtractor={(a) => a}
            getItemCount={(data) => data.length}
            getItem={(data, index) => data[index]}
            renderItem={() => (
              <CalendarList
                pastScrollRange={0}
                futureScrollRange={12}
                theme={calendarTheme}
                markingType={'period'}
                markedDates={markedDays}
                dayComponent={CallendarCell}
                onDayPress={dayPress}
              />
            )}
          />
        ) : (
          <CalendarList
            pastScrollRange={0}
            futureScrollRange={12}
            theme={calendarTheme}
            markingType={'period'}
            markedDates={markedDays}
            dayComponent={CallendarCell}
            onDayPress={dayPress}
            calendarWidth={maxWidth}
          />
        )}
      </Box>
    </BottomSheet>
  );
}

export default CalendarAvailabilitySheet;
