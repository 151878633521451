import React, { useEffect, useState } from 'react';

import { Button, ButtonText, Heading, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CalendarSheet from '../../../shared/components/calendar/calendarSheet';
import { errorHandler } from '../../../shared/components/errorHandler/errorHander';
import TimePicker from '../../../shared/components/timePicker/timePicker';
import { quoteSetter } from '../../../shared/reducers/quote.reducer';
import { firebaseApp } from '../../../shared/services/firebase/firebaseFactory';
import { displayRangeDate } from '../../../shared/util/date-transforms';
import { cleanFirestoreResult } from '../../../shared/util/firestore-utils';

function QuoteSelectDateRange() {
  const [car, setCar] = useState({});
  const [loading, setLoading] = useState(true);
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.users.currentUser);
  const quote = useSelector((state) => state.quote.quote);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser?.uid) {
      setLoading(true);
      firebaseApp
        .firestore()
        .collection('cars')
        .doc(quote?.carId)
        .get()
        .then((resCar) => {
          setCar(cleanFirestoreResult(resCar));
          setLoading(false);
        })
        .catch((e) => {
          errorHandler(e, true);
          setLoading(false);
        });
    }
  }, [currentUser?.uid, quote?.carId]);

  function setDateRange(dateRange) {
    dispatch(quoteSetter({ ...quote, dateRange: dateRange }));
  }

  return (
    <VStack space={'md'}>
      <Text variant={'grey'}>{t('quote.dateRangeSubTitle')}</Text>
      <Heading>{t('search.when')}</Heading>
      <Button variant={'outline'} onPress={() => setCalendarOpen(true)}>
        <ButtonText>
          {quote?.dateRange?.from && quote?.dateRange?.to
            ? displayRangeDate(quote?.dateRange?.from, quote?.dateRange?.to, 'small')
            : t('car.selectTimeRange')}
        </ButtonText>
      </Button>
      {quote?.dateRange?.from && quote?.dateRange?.to && !isCalendarOpen && (
        <HStack space={'2xl'} w={'50%'}>
          <TimePicker
            title={t('rental.pickUp')}
            time={
              quote?.dateRange?.checkInHour
                ? new Date().setHours(quote?.dateRange?.checkInHour, quote?.dateRange?.checkInMinute, 0, 0)
                : undefined
            }
            setTime={(time) =>
              dispatch(
                quoteSetter({
                  ...quote,
                  dateRange: { ...quote?.dateRange, checkInHour: time.getHours(), checkInMinute: time.getMinutes() },
                }),
              )
            }
          />
          <TimePicker
            title={t('rental.return')}
            time={
              quote?.dateRange?.checkOutHour
                ? new Date().setHours(quote?.dateRange?.checkOutHour, quote?.dateRange?.checkOutMinute, 0, 0)
                : undefined
            }
            setTime={(time) =>
              dispatch(
                quoteSetter({
                  ...quote,
                  dateRange: { ...quote?.dateRange, checkOutHour: time.getHours(), checkOutMinute: time.getMinutes() },
                }),
              )
            }
          />
        </HStack>
      )}
      <CalendarSheet
        priceData={car?.priceData}
        bookedDays={car.bookedDays}
        isOpen={isCalendarOpen}
        setOpen={setCalendarOpen}
        dateRange={quote?.dateRange}
        setDateRange={setDateRange}
      />
    </VStack>
  );
}

export default QuoteSelectDateRange;
