import { getLocales } from 'expo-localization';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { supportedLang } from '../util/language-utils';

const resources = {
  ar: { translation: require('./trans/ar.json') },
  cs: { translation: require('./trans/cs.json') },
  de: { translation: require('./trans/de.json') },
  en: { translation: require('./trans/en.json') },
  es: { translation: require('./trans/es.json') },
  fr: { translation: require('./trans/fr.json') },
  he: { translation: require('./trans/he.json') },
  it: { translation: require('./trans/it.json') },
  ja: { translation: require('./trans/ja.json') },
  pt: { translation: require('./trans/pt.json') },
  ru: { translation: require('./trans/ru.json') },
  sk: { translation: require('./trans/sk.json') },
  zh: { translation: require('./trans/zh.json') },
};

i18n.use(initReactI18next).init({
  resources,
  lng: getLocales()[0]?.languageCode,
  fallbackLng: 'en',
  compatibilityJSON: 'v3',
  interpolation: {
    escapeValue: false,
  },
  supportedLngs: supportedLang,
});
