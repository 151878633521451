import React, { useEffect, useState } from 'react';
import { TouchableOpacity, useWindowDimensions } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Button, HStack, Icon, Text, VStack } from '@gluestack-ui/themed';
import * as Linking from 'expo-linking';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import ua from 'ua-parser-js';

import { Images, Metrics } from '../../themes';
import imagesUrl from '../../themes/imagesUrl';
import { expiredDateInUTC } from '../../util/storage-utils';
import Image from '../image/image';

function SmartBanner(props) {
  const [deviceType, setDeviceType] = useState('');
  const [cookies, setCookie] = useCookies(['smartbannerClosed']);
  const width = useWindowDimensions().width;
  const { t } = useTranslation();

  useEffect(() => {
    const agent = ua(window.navigator.userAgent);

    if (agent.os.name === 'iOS' && (parseInt(agent.os.version, 10) < 6 || agent.browser.name !== 'Mobile Safari')) {
      setDeviceType('ios');
    } else if (agent.os.name === 'Android') {
      setDeviceType('android');
    }
  }, []);

  function onClose() {
    setCookie('smartbannerClosed', true, { path: '/', expires: expiredDateInUTC(1) });
  }

  function onInstall() {
    setCookie('smartbannerClosed', true, { path: '/', expires: expiredDateInUTC(1) });
    if (deviceType === 'ios') {
      Linking.openURL('itms-apps://itunes.apple.com/app/id6449300955');
    } else if (deviceType === 'android') {
      Linking.openURL('market://details?id=com.flyandcar.app');
    }
  }

  if (cookies?.smartbannerClosed || !deviceType) {
    return null;
  }

  return (
    <HStack
      left={0}
      width={width}
      height={72}
      lineHeight={72}
      bgColor={'$light100'}
      zIndex={9998}
      overflow={'hidden'}
      justifyContent={'space-between'}
      px={'$2'}
      borderBottomWidth={1}
      borderColor={'$light200'}>
      <HStack space={'sm'} pr={500}>
        <HStack space={'xs'}>
          <TouchableOpacity onPress={onClose} style={{ alignSelf: 'center' }}>
            <Icon as={Ionicons} name={'close-outline'} size={'md'} />
          </TouchableOpacity>
          <Box alignSelf={'center'}>
            <Image source={{ uri: imagesUrl.logo }} h={40} w={40} rounded={'$xl'} />
          </Box>
        </HStack>
        <VStack alignContent={'center'} alignSelf={'center'} width={width - 200}>
          <Text size={'secondary'} fontWeight={'$bold'}>
            {t('smartbanner.getApp')}
          </Text>
          <Text variant={'grey'} size={'secondary'}>
            {t('smartbanner.info')}
          </Text>
        </VStack>
      </HStack>
      <Box alignSelf={'center'} flexGrow={1} position={'absolute'} right={8}>
        <TouchableOpacity onPress={onInstall}>
          <Box rounded={'$full'} bgColor={'$primary500'} py={'$2'} px={'$3'}>
            <Text fontWeight={'$bold'} color={'$white'}>
              {t('smartbanner.install')}
            </Text>
          </Box>
        </TouchableOpacity>
      </Box>
    </HStack>
  );
}

export default SmartBanner;
