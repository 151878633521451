import React, { useEffect, useMemo, useState } from 'react';

import { HStack, Input, InputField, InputSlot, Text, useToken, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { atoi } from '../../util/conversion';
import { getSymbol } from '../../util/currency';
import BottomSheetTextInput from '../bottomSheet/BottomSheetTextInput';

export default function InputPriceCard({ testID, dolarPrice, currency, onChangePrice, placeHolder, leftElement, w, isBottomSheet }) {
  const [text, setText] = useState('');
  const [error, setError] = useState(undefined);

  const symbol = useMemo(() => getSymbol(0, currency.current), [currency]);

  const { t } = useTranslation();
  const fontSizePrimary = useToken('fontSizes', 'primary');

  var countDecimals = function (value) {
    let text = value.toString();
    // count decimals for number in representation like "0.123456"
    if (text.includes('.') && Math.floor(value) !== value) {
      return value.toString().split('.')[1].length || 0;
    }
    return 0;
  };

  useEffect(() => {
    console.debug('Effect refresh dolarPrice');

    const decText = countDecimals(text);
    const pow = Math.pow(10, decText);
    const roundDol = Math.round(dolarPrice ? dolarPrice * currency[currency?.current] * pow : 0);
    const powText = atoi(text, -1) * pow;
    if (roundDol === powText) {
      return;
    }
    setText(dolarPrice ? (dolarPrice * currency[currency?.current]).toString() : '');
  }, [dolarPrice, currency]);

  function changeInput(newPrice) {
    setText(newPrice);
    const newPriceNumber = atoi(newPrice, -1);
    if (newPriceNumber >= 0) {
      onChangePrice(newPriceNumber / currency[currency?.current]);
      setError(undefined);
    } else if (newPrice?.length === 0) {
      setError(t('error.addPrice'));
    } else {
      setError(t('error.addGoodNumberFormat'));
    }
  }

  return (
    <VStack w={w}>
      {isBottomSheet ? (
        <HStack rounded={'$md'} borderWidth={'$1'} px={'$8'} py={'$3'} space={'md'} justifyContent={'center'} borderColor={'$light500'}>
          <Text alignSelf={'center'} ml={'$4'}>
            {symbol}
          </Text>
          <BottomSheetTextInput
            testID={testID}
            style={{ fontSize: fontSizePrimary, minWidth: 50 }}
            keyboardType={'number-pad'}
            textAlign={'center'}
            defaultValue={text}
            placeholder={placeHolder}
            onChangeText={changeInput}
          />
        </HStack>
      ) : (
        <Input size={'xl'}>
          <InputSlot pl="$3">
            <Text size={'h2'}>{symbol}</Text>
          </InputSlot>
          <InputField
            testID={testID}
            keyboardType={'number-pad'}
            textAlign={'center'}
            rounded={'$md'}
            value={text}
            placeholder={placeHolder}
            onChangeText={changeInput}
          />
        </Input>
      )}

      {error !== undefined && (
        <Text size={'secondary'} color={'$error500'}>
          {error}
        </Text>
      )}
    </VStack>
  );
}
