export function itoa(num, defaultValue) {
  if (!isNaN(num) && num.toString()) {
    return num.toString();
  }
  return defaultValue.toString();
}

export function atoi(text, defaultValue) {
  const parsed = parseFloat(text);

  if (!isNaN(parsed) && parsed.toString() === text) {
    return parsed;
  }
  return defaultValue;
}
