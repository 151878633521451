import React, { useEffect, useRef, useState } from 'react';
import { Dimensions, I18nManager, Platform, TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Center, Divider, HStack, Icon, Modal, ScrollView, Spinner, View, VStack } from '@gluestack-ui/themed';
import { Camera, CameraType, FlashMode } from 'expo-camera';
import { getDocumentAsync } from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { v4 as uuidv4 } from 'uuid';

import appConfig from '../../../config/app-config';
import { firebaseApp } from '../../services/firebase/firebaseFactory';
import { Images, Metrics } from '../../themes';
import { updloadDocument, updloadMedia } from '../../util/storage-utils';
import ActionSheet from '../actionSheet/actionSheet';
import { errorHandler } from '../errorHandler/errorHander';
import Image from '../image/image';
import { PressableRow } from '../pressable/pressableRow';
import errorToast from '../toast/errorToast';

const { height } = Dimensions.get('screen');

export default function MediaUploader({
  testID,
  initialList,
  onUpdate,
  storagePath,
  multipleSelectionAllow,
  cameraOnly,
  document,
  rounded = '$2xl',
  frontCamera,
  children,
}) {
  const [images, setImages] = useState(initialList && initialList?.length > 0 ? initialList : []);
  const [isOpen, setOpen] = useState(false);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [cameraReady, setCameraReady] = useState(false);
  const [cameraType, setCameraType] = useState(frontCamera ? CameraType.front : CameraType.back);
  const [flashMode, setFlashMode] = useState(FlashMode.off);
  const [isAdmin, setAdmin] = useState(false);

  const refCamera = useRef();
  const { bottom } = useSafeAreaInsets();

  const { t } = useTranslation();

  useEffect(() => {
    onUpdate(images);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  function onDelete(url) {
    const imageToKeep = images.filter((image) => image.url !== url);

    setImages(imageToKeep);
  }

  useEffect(() => {
    if (firebaseApp.auth().currentUser?.id) {
      firebaseApp
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          // Confirm the user is an Admin.
          if (idTokenResult?.claims?.admin) {
            // Show admin UI.
            setAdmin(true);
          } else {
            // Show regular user UI.
            setAdmin(false);
          }
        })
        .catch((error) => {
          errorHandler(error, true);
        });
    }
  }, []);

  function onUpload(path, url) {
    const imageToChange = {};
    imageToChange.uploaded = true;
    imageToChange.uploading = false;
    imageToChange.url = url;

    console.debug('image to updateList', path, url);

    setImages((prevImages) => {
      const arrayImages = [...prevImages];
      const indexImage = arrayImages.findIndex((image) => image.path === path);

      arrayImages[indexImage] = imageToChange;
      return arrayImages;
    });
  }

  // This function is triggered when the "Open camera" button pressed
  const openCamera = async () => {
    try {
      const permission = await Camera.getCameraPermissionsAsync();

      if (permission.granted === false) {
        // Ask the user for the permission to access the camera
        const permissionResult = await Camera.requestCameraPermissionsAsync();

        if (permissionResult.granted === false) {
          errorToast(t('media.allowCamera'));
          return;
        }
      }
      setCameraOpen(true);
      setOpen(false);
    } catch (e) {
      setOpen(false);

      errorHandler(e, false);
      errorToast(t('error.errorTakingPicture'));
    }
  };

  const pickDocument = async () => {
    let result = await getDocumentAsync({ multiple: true, type: 'application/pdf' }).catch((e) => errorHandler(e, true));
    if (result.type === 'cancel') {
      setOpen(false);
      return;
    }
    if (result?.assets?.length > 0) {
      const imageToUpload = [];

      result.assets.forEach((asset) => {
        imageToUpload.push({ path: asset.uri, uploaded: false, uploading: true });
      });

      setImages((prevImages) => [...prevImages, ...imageToUpload]);
      setOpen(false);
      for (let img of imageToUpload) {
        await updloadDocument(img.path, storagePath, onUpload);
      }
      //imageToUpload.forEach((img) => .then(() => {}));
    }
    setOpen(false);
  };

  const ImageChoice = async () => {
    if (appConfig.isDetox) {
      setImages((prevImages) => [...prevImages, { url: `https://picsum.photos/seed/${uuidv4()}/400/200.jpg` }]);
      setOpen(false);
      return;
    }
    try {
      if (Platform.OS === 'ios') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          errorToast(t('error.permissionLibraryRequired'));
          return;
        }
      }
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsMultipleSelection: multipleSelectionAllow,
      }).catch((e) => errorHandler(e, true));
      if (result?.canceled !== undefined && !result?.canceled) {
        const imageToUpload = [];

        result.assets.forEach((asset) => {
          imageToUpload.push({ path: asset.uri, uploaded: false, uploading: true });
        });

        setImages((prevImages) => [...prevImages, ...imageToUpload]);

        setOpen(false);
        for (let img of imageToUpload) {
          await updloadMedia(img.path, storagePath, onUpload);
        }
      } else {
        setOpen(false);
      }
    } catch (e) {
      setOpen(false);

      errorHandler(e, false);
      errorToast(t('error.errorSelectImage'));
    }
  };

  function renderImage(image, m) {
    const imageUrl = image?.url ?? image?.path ?? '';
    const isImage = !(['pdf'].includes(imageUrl.split('.').pop()) || imageUrl.startsWith('data:application/pdf'));

    return (
      <Box m={m} borderWidth={'$1'} borderColor={'$light200'} rounded={rounded}>
        <Image
          overflow={'hidden'}
          rounded={rounded}
          height={Metrics.images.xlarge}
          width={'100%'}
          resizeMode="cover"
          alt={'image'}
          source={{
            uri: isImage ? imageUrl : 'https://assets.flyandcar.com/assets/document-placeholder.jpeg',
          }}
        />
        {!image?.url && (
          <Box
            zIndex={1}
            variant={'absoluteMidle'}
            justifyContent={'center'}
            width={'100%'}
            height={'100%'}
            backgroundColor={'rgba(0, 0, 0, 0.7)'}
            rounded={rounded}>
            <Spinner color={'$light300'} size={100} />
          </Box>
        )}
        {image?.url && (
          <Box variant={'absoluteTopRight'}>
            <TouchableOpacity onPress={() => onDelete(image.url)}>
              <Box rounded={'$full'} backgroundColor={'$light300'} m={'$1'} justifyContent={'center'} p="$2">
                <Icon as={Ionicons} name={'close'} color={'$black'} size={'md'} />
              </Box>
            </TouchableOpacity>
          </Box>
        )}
      </Box>
    );
  }

  function openActionSheet() {
    if (cameraOnly && !isAdmin) {
      openCamera();
    } else {
      setOpen(true);
    }
  }

  async function takePic() {
    if (refCamera.current) {
      setCameraReady(false);
      const photo = await refCamera.current.takePictureAsync();
      if (photo?.uri) {
        const imageToUpload = { path: photo.uri, uploaded: false, uploading: true };
        setImages((prevImages) => [imageToUpload, ...prevImages]);
        setCameraReady(true);
        if (!multipleSelectionAllow) {
          setCameraOpen(false);
        }
        await updloadMedia(imageToUpload.path, storagePath, onUpload);
      } else {
        setCameraReady(true);
        if (!multipleSelectionAllow) {
          setCameraOpen(false);
        }
      }
    }
  }

  const childrenWithProps = React.Children.map(children, (child) => {
    // Only try to clone if the child is a valid element
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onPress: openActionSheet });
    }
    return child;
  });

  return (
    <>
      <Modal
        flex={1}
        h={'$full'}
        w={'$full'}
        mb={bottom}
        isOpen={cameraOpen}
        onClose={() => setCameraOpen(false)}
        statusBarTranslucent
        backgroundColor={'$black'}>
        {cameraOpen && (
          <Camera
            style={{ height: height, width: (height / 4) * 3 }}
            type={cameraType}
            flashMode={flashMode}
            ref={refCamera}
            onCameraReady={() => setCameraReady(true)}
          />
        )}
        <Box variant={'absoluteBottom'} my={'$4'} w={'$full'}>
          <VStack space={'md'} mx={'$screenMargin'}>
            <Box w={'$10'} h={'$10'}>
              <TouchableOpacity onPress={() => setFlashMode(flashMode === FlashMode.off ? FlashMode.torch : FlashMode.off)}>
                <Box backgroundColor={'$white'} w={'$10'} h={'$10'} rounded={'$full'} alignItems={'center'} justifyContent={'center'}>
                  <Icon as={Ionicons} name={flashMode === FlashMode.off ? 'flash-outline' : 'flash-off-outline'} color={'$light600'} />
                </Box>
              </TouchableOpacity>
            </Box>
            {!frontCamera && (
              <Box w={'$10'} h={'$10'}>
                <TouchableOpacity onPress={() => setCameraType(cameraType === CameraType.back ? CameraType.front : CameraType.back)}>
                  <Box backgroundColor={'$white'} w={'$10'} h={'$10'} rounded={'$full'} alignItems={'center'} justifyContent={'center'}>
                    <Icon as={Ionicons} name="sync-outline" color={'$light600'} />
                  </Box>
                </TouchableOpacity>
              </Box>
            )}
            <ScrollView horizontal showsHorizontalScrollIndicator={false}>
              <HStack height={50} width={'$full'} space={'sm'}>
                {images.map((image, i) => (
                  <Box key={image?.url ?? image.path} width={50} height={50}>
                    <Image
                      bgColor={'$light200'}
                      rounded={'$2xl'}
                      height={'100%'}
                      width={'100%'}
                      resizeMode="cover"
                      alt={'image'}
                      source={{
                        uri: image?.url ?? image.path,
                      }}
                    />
                    {!image?.url && (
                      <Box variant={'absoluteMidle'} alignSelf="center" justifyContent="center">
                        <Spinner size={'small'} />
                      </Box>
                    )}
                  </Box>
                ))}
              </HStack>
            </ScrollView>
            <HStack justifyContent={'space-between'} width={'100%'} alignItems={'flex-end'}>
              <TouchableOpacity style={{ width: '25%' }} onPress={() => setCameraOpen(false)}>
                <Box backgroundColor={'$white'} rounded={'$full'} alignItems={'center'} justifyContent={'center'} height={'$10'}>
                  <Icon
                    as={Ionicons}
                    name="chevron-back-outline"
                    color={'$light600'}
                    style={{ transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }] }}
                  />
                </Box>
              </TouchableOpacity>
              <TouchableOpacity onPress={takePic} disabled={!cameraReady}>
                <Box backgroundColor={'$white'} rounded={'$full'} alignItems={'center'} justifyContent={'center'} p={'$3'}>
                  <Box rounded={'$full'} bgColor={'$light600'} w={'$12'} h={'$12'} />
                </Box>
              </TouchableOpacity>
              {multipleSelectionAllow ? (
                <TouchableOpacity style={{ width: '25%' }} onPress={() => setCameraOpen(false)} disabled={!cameraReady}>
                  <Box backgroundColor={'$green300'} rounded={'$full'} alignItems={'center'} justifyContent={'center'} height={'$10'}>
                    <Icon as={Ionicons} name="checkmark-outline" size={'xl'} color={'$white'} />
                  </Box>
                </TouchableOpacity>
              ) : (
                <Box w={'25%'} />
              )}
            </HStack>
          </VStack>
        </Box>
      </Modal>
      {childrenWithProps !== undefined ? (
        childrenWithProps
      ) : (
        <>
          {!multipleSelectionAllow ? (
            images && images?.length > 0 ? (
              renderImage(images[0], 0)
            ) : (
              <TouchableOpacity testID={`${testID}.picker`} onPress={openActionSheet}>
                <Image
                  rounded={rounded}
                  height={Metrics.images.xlarge}
                  width={'100%'}
                  resizeMode="cover"
                  alt={'image'}
                  source={Images.noImage}
                />
              </TouchableOpacity>
            )
          ) : (
            <HStack flexWrap={'wrap'} space={'sm'}>
              {images.map((image, i) => (
                <Box key={image?.uid ?? image.url ? image.url : image.path} width={Metrics.images.xlarge} height={Metrics.images.xlarge}>
                  {renderImage(image, 0)}
                </Box>
              ))}
              <Box width={Metrics.images.xlarge} height={Metrics.images.xlarge}>
                <TouchableOpacity testID={`${testID}.picker`} onPress={openActionSheet}>
                  <Image
                    rounded={rounded}
                    height={Metrics.images.xlarge}
                    width={Metrics.images.xlarge}
                    resizeMode="cover"
                    alt={'image'}
                    source={Images.noImage}
                  />
                </TouchableOpacity>
              </Box>
            </HStack>
          )}
        </>
      )}

      <ActionSheet isOpen={isOpen} setOpen={setOpen} headerText={t('media.selectMedia')}>
        <VStack mx={'$4'}>
          <PressableRow
            testID={`${testID}.cameraPicker`}
            leftIconName={'camera-outline'}
            text={t('media.camera')}
            onPress={openCamera}
            removeRightCheveron={true}
          />
          <Divider />
          <PressableRow
            testID={`${testID}.libraryPicker`}
            leftIconName={'image-outline'}
            text={t('media.library')}
            onPress={ImageChoice}
            removeRightCheveron={true}
          />
          <Divider />
          {document === true && (
            <>
              <PressableRow
                testID={`${testID}.documentPicker`}
                leftIconName={'document-text-outline'}
                text={t('media.document')}
                onPress={pickDocument}
                removeRightCheveron={true}
              />
              <Divider />
            </>
          )}
        </VStack>
      </ActionSheet>
    </>
  );
}
