import React from 'react';

import { Box, Divider, Heading, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { formatDateByLocaleWithCheckTime } from '../../util/date-transforms';
import ReservationBadge from '../badge/reservationBadge';
import Skeleton from '../skeleton/skeleton';

export default function CarRentalDetailBar({ car, reservation }) {
  const { t } = useTranslation();

  if (!car?.brand) {
    return (
      <VStack space={'md'}>
        <VStack space={'md'} alignItems={'flex-start'}>
          <Skeleton height={'$6'} width={300} rounded={'$full'} />
          <Skeleton.Text lines={1} width={200} />
        </VStack>
        <Divider />
        <HStack space={'md'} justifyContent={'space-evenly'} alignItems={'center'}>
          <VStack space={'md'}>
            <Skeleton.Text lines={1} width={50} />
            <Skeleton.Text lines={1} width={100} />
          </VStack>
          <Divider orientation="vertical" />
          <VStack space={'md'}>
            <Skeleton.Text lines={1} width={50} />
            <Skeleton.Text lines={1} width={100} />
          </VStack>
        </HStack>
        <Divider />
      </VStack>
    );
  }

  return (
    <VStack space={'md'}>
      <Box>
        <ReservationBadge reservation={reservation} />
        <Heading>{car?.brand + ' ' + car?.model}</Heading>
        <Text variant={'grey'} size={'secondary'}>
          {car?.placeName}
        </Text>
      </Box>
      <Divider />
      <HStack space={'md'} justifyContent={'space-between'} alignItems={'center'}>
        <VStack space={'sm'} w={'45%'} alignItem={'flex-start'}>
          <Heading size={'h4'}>{t('rental.pickUp')}</Heading>
          <Text>{formatDateByLocaleWithCheckTime(reservation?.from, reservation?.checkInHour, reservation?.checkInMinute)}</Text>
        </VStack>
        <Divider orientation="vertical" />
        <VStack space={'sm'} w={'45%'} alignItem={'start'}>
          <Heading size={'h4'}>{t('rental.return')}</Heading>
          <Text>{formatDateByLocaleWithCheckTime(reservation?.to, reservation?.checkOutHour, reservation?.checkOutMinute)}</Text>
        </VStack>
      </HStack>
      <Divider />
    </VStack>
  );
}
