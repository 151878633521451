import React, { useEffect } from 'react';

import { useNavigation } from '@react-navigation/native';
import { Helmet } from 'react-helmet';

export default function MetaTag({ title, description, image }) {
  const navigation = useNavigation();

  useEffect(() => {
    if (!title) {
      return;
    }
    const addTitle = setTimeout(() => {
      navigation.setOptions({ title: title });
    }, 1000);

    return () => clearTimeout(addTitle);
  }, [title, navigation]);

  if (!title || !description || !image) {
    return null;
  }

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={title} />

      <meta name="og:title" content={title} />
      <meta name="twitter:title" content={title} />

      <meta name="og:description" content={description} />
      <meta name="description" content={description} />
      <meta name="twitter:description" content={description} />

      <meta name="og:image" content={image} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
}
