import React, { useEffect, useState } from 'react';

import { Box, Heading, HStack, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { Images } from '../../themes';
import imagesUrl from '../../themes/imagesUrl';
import EventCard from '../entities/car/eventCard';
import PlaceHolder from '../placeHolder/placeHolder';
import Skeleton from '../skeleton/skeleton';

export default function ReservationEvent({ reservationList, loading }) {
  const [events, setEvents] = useState({
    today: [],
    tomorow: [],
    thisWeek: [],
    thisMonth: [],
    afterMonth: [],
  });

  const navigation = useNavigation();

  const { t } = useTranslation();

  function compareDate(a, b) {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    console.debug('Effect: refresh reservationList');
    const time = new Date();
    time.setUTCHours(0, 0, 0, 0);
    const tmpEventsToday = [];
    const tmpEventsTomorow = [];
    const tmpEventsThisWeek = [];
    const tmpEventsThisMonth = [];
    const tmpEventsAfterMonth = [];
    reservationList &&
      reservationList.map((reservation) => {
        const resFromDate = new Date(reservation.from * 1000);
        // add check in event
        if (resFromDate.getTime() >= time.getTime() && resFromDate.getTime() < time.getTime() + 86000000) {
          tmpEventsToday.push({
            date: resFromDate,
            type: 'check-in',
            reservation: reservation,
            id: reservation.uid + 'check-in',
          });
        } else if (resFromDate.getTime() >= time.getTime() && resFromDate.getTime() < time.getTime() + 86000000 * 2) {
          tmpEventsTomorow.push({
            date: resFromDate,
            type: 'check-in',
            reservation: reservation,
            id: reservation.uid + 'check-in',
          });
        } else if (resFromDate.getTime() > time.getTime() && resFromDate.getTime() < time.getTime() + 86000000 * 7) {
          tmpEventsThisWeek.push({
            date: resFromDate,
            type: 'check-in',
            reservation: reservation,
            id: reservation.uid + 'check-in',
          });
        } else if (resFromDate.getTime() > time.getTime() && resFromDate.getTime() < time.getTime() + 86000000 * 30) {
          tmpEventsThisMonth.push({
            date: resFromDate,
            type: 'check-in',
            reservation: reservation,
            id: reservation.uid + 'check-in',
          });
        } else if (resFromDate.getTime() > time.getTime() + 86000000 * 30) {
          tmpEventsAfterMonth.push({
            date: resFromDate,
            type: 'check-in',
            reservation: reservation,
            id: reservation.uid + 'check-in',
          });
        }

        const resToDate = new Date(reservation.to * 1000);
        // add check Out Event
        if (resToDate.getTime() >= time.getTime() && resToDate.getTime() < time.getTime() + 86000000) {
          tmpEventsToday.push({
            date: resToDate,
            type: 'check-out',
            reservation: reservation,
            id: reservation.uid + 'check-out',
          });
        } else if (resToDate.getTime() > time.getTime() && resToDate.getTime() < time.getTime() + 86000000 * 2) {
          tmpEventsTomorow.push({
            date: resToDate,
            type: 'check-out',
            reservation: reservation,
            id: reservation.uid + 'check-out',
          });
        } else if (resToDate.getTime() > time.getTime() && resToDate.getTime() < time.getTime() + 86000000 * 7) {
          tmpEventsThisWeek.push({
            date: resToDate,
            type: 'check-out',
            reservation: reservation,
            id: reservation.uid + 'check-out',
          });
        } else if (resToDate.getTime() > time.getTime() && resToDate.getTime() < time.getTime() + 86000000 * 30) {
          tmpEventsThisMonth.push({
            date: resToDate,
            type: 'check-out',
            reservation: reservation,
            id: reservation.uid + 'check-out',
          });
        } else if (resToDate.getTime() > time.getTime() + 86000000 * 30) {
          tmpEventsAfterMonth.push({
            date: resToDate,
            type: 'check-out',
            reservation: reservation,
            id: reservation.uid + 'check-out',
          });
        }
      });
    setEvents({
      today: tmpEventsToday.sort(compareDate),
      tomorow: tmpEventsTomorow.sort(compareDate),
      thisWeek: tmpEventsThisWeek.sort(compareDate),
      thisMonth: tmpEventsThisMonth.sort(compareDate),
      afterMonth: tmpEventsAfterMonth.sort(compareDate),
    });
  }, [reservationList]);

  function goToReservation(reservation) {
    navigation.navigate('RequestDetailScreen', { reservationId: reservation.uid, userId: reservation?.userId, carId: reservation.carId });
  }

  if (loading && (!reservationList || reservationList.length === 0)) {
    return (
      <HStack space={'md'} py={'$4'}>
        <Skeleton w={'$2'} h={250} rounded={'$full'} />
        <VStack space={'md'}>
          <EventCard loading={true} />
          <EventCard loading={true} />
        </VStack>
      </HStack>
    );
  }

  if (
    events?.today.length === 0 &&
    events?.tomorow.length === 0 &&
    events?.thisWeek.length === 0 &&
    events?.thisMonth.length === 0 &&
    events?.afterMonth.length === 0
  ) {
    return (
      <PlaceHolder image={{ uri: imagesUrl.mountain }} title={t('placeHolder.noEventDescription')} text={t('placeHolder.noEventInfo')} />
    );
  }

  function renderEvent(listEvent, title) {
    return (
      <VStack space={'md'}>
        <Heading>{title}</Heading>
        {listEvent.map((event) => (
          <EventCard
            key={event?.id}
            onPress={() => goToReservation(event.reservation)}
            reservation={event.reservation}
            type={event?.type}
            date={event?.date}
          />
        ))}
      </VStack>
    );
  }

  return (
    <HStack space={'md'} py={'$4'}>
      <Box backgroundColor={'$light300'} width={'$2'} rounded={'$full'} />
      <VStack space={'lg'}>
        {events?.today && events?.today.length > 0 && renderEvent(events?.today, t('date.today'))}
        {events?.tomorow && events?.tomorow.length > 0 && renderEvent(events?.tomorow, t('date.tomorow'))}
        {events?.thisWeek && events?.thisWeek.length > 0 && renderEvent(events?.thisWeek, t('date.next7Day'))}
        {events?.thisMonth && events?.thisMonth.length > 0 && renderEvent(events?.thisMonth, t('date.next30Day'))}
        {events?.afterMonth && events?.afterMonth.length > 0 && renderEvent(events?.afterMonth, t('date.moreThanMonth'))}
      </VStack>
    </HStack>
  );
}
