import { firebaseApp } from './firebaseFactory';
import { reservationStatus } from '../../util/car-utils';
import { cleanFirestoreResult } from '../../util/firestore-utils';

export function getAllUserReservation(userId, limit = 10) {
  return firebaseApp
    .firestore()
    .collection('reservations')
    .where('userId', '==', userId)
    .where('toDisplay', '==', true)
    .orderBy('from', 'desc')
    .limit(limit)
    .get()
    .then((res) => {
      const reservations = [];
      console.info('Total getAllUserReservation: ', res.size);
      res.forEach((r) => reservations.push({ ...r.data(), uid: r.id }));
      return reservations;
    });
}

export function oneReservationListener(reservationId, onResult, onError) {
  return firebaseApp.firestore().collection('reservations').doc(reservationId).onSnapshot(onResult, onError);
}

export function getAllOwnerReservation(userId, limit = 10) {
  return firebaseApp
    .firestore()
    .collection('reservations')
    .where('ownerId', '==', userId)
    .where('toDisplay', '==', true)
    .orderBy('from', 'desc')
    .limit(limit)
    .get()
    .then((res) => {
      const reservations = [];
      console.info('Total getAllOwnerReservation: ', res.size);
      res.forEach((r) => reservations.push({ ...r.data(), uid: r.id }));
      return reservations;
    });
}

export function getAllOwnerReservationDayEvents(userId, day, limit = 10) {
  return firebaseApp
    .firestore()
    .collection('reservations')
    .where('ownerId', '==', userId)
    .where('toDisplay', '==', true)
    .where('events', 'array-contains', day)
    .orderBy('from', 'desc')
    .limit(limit)
    .get()
    .then((res) => {
      const reservations = [];
      console.info('Total getAllOwnerReservationDayEvents: ', res.size);
      res.forEach((r) => reservations.push({ ...r.data(), uid: r.id }));
      return reservations;
    });
}

export function getInterestingOwnerReservation(userId, limit = 10) {
  return firebaseApp
    .firestore()
    .collection('reservations')
    .where('ownerId', '==', userId)
    .where('toDisplay', '==', true)
    .where('status', 'not-in', [reservationStatus.waitingPayment, reservationStatus.ownerReject, reservationStatus.finished])
    .orderBy('status', 'desc')
    .orderBy('from', 'desc')
    .limit(limit)
    .get()
    .then((res) => {
      const reservations = [];
      console.info('Total getInterestingOwnerReservation: ', res.size);
      res.forEach((r) => reservations.push({ ...r.data(), uid: r.id }));
      return reservations;
    });
}

export function getWaitingOwnerReservation(userId, limit = 10) {
  return firebaseApp
    .firestore()
    .collection('reservations')
    .where('ownerId', '==', userId)
    .where('toDisplay', '==', true)
    .where('status', '==', reservationStatus.waitingOwner)
    .orderBy('from', 'desc')
    .limit(limit)
    .get()
    .then((res) => {
      const reservations = [];
      console.info('Total getWaitingOwnerReservation: ', res.size);
      res.forEach((r) => reservations.push({ ...r.data(), uid: r.id }));
      return reservations;
    });
}

export function getAllOwnerCurrentReservation(userId, carId, limit = 10) {
  const curTime = Math.round(new Date().getTime() / 1000);

  if (carId) {
    return firebaseApp
      .firestore()
      .collection('reservations')
      .where('ownerId', '==', userId)
      .where('carId', '==', carId)
      .where('toDisplay', '==', true)
      .where('to', '>', curTime)
      .orderBy('to', 'desc')
      .limit(limit)
      .get()
      .then((res) => {
        const reservations = [];
        console.info('Total with carId getAllOwnerCurrentReservation: ', res.size);
        res.forEach((r) => {
          if (r.data().from < curTime) {
            reservations.push({ ...r.data(), uid: r.id });
          }
        });
        return reservations;
      });
  } else {
    return firebaseApp
      .firestore()
      .collection('reservations')
      .where('ownerId', '==', userId)
      .where('toDisplay', '==', true)
      .where('to', '>', curTime)
      .orderBy('to', 'desc')
      .limit(limit)
      .get()
      .then((res) => {
        const reservations = [];
        console.info('Total getAllOwnerCurrentReservation: ', res.size);
        res.forEach((r) => {
          if (r.data().from < curTime) {
            reservations.push({ ...r.data(), uid: r.id });
          }
        });
        return reservations;
      });
  }
}

export function getAllOwnerUpCommingReservation(userId, carId, limit = 10) {
  const curTime = Math.round(new Date().getTime() / 1000);

  if (carId) {
    return firebaseApp
      .firestore()
      .collection('reservations')
      .where('ownerId', '==', userId)
      .where('carId', '==', carId)
      .where('toDisplay', '==', true)
      .where('from', '>', curTime)
      .orderBy('from', 'desc')
      .limit(limit)
      .get()
      .then((res) => {
        const reservations = [];
        console.info('Total withCarId getAllOwnerUpCommingReservation: ', res.size);
        res.forEach((r) => {
          reservations.push({ ...r.data(), uid: r.id });
        });
        return reservations;
      });
  } else {
    return firebaseApp
      .firestore()
      .collection('reservations')
      .where('ownerId', '==', userId)
      .where('toDisplay', '==', true)
      .where('from', '>', curTime)
      .orderBy('from', 'desc')
      .limit(limit)
      .get()
      .then((res) => {
        const reservations = [];
        console.info('Total getAllOwnerUpCommingReservation: ', res.size);
        res.forEach((r) => {
          reservations.push({ ...r.data(), uid: r.id });
        });
        return reservations;
      });
  }
}

export function getAllOwnerPassedReservation(userId, carId, limit = 10) {
  const curTime = Math.round(new Date().getTime() / 1000);

  if (carId) {
    return firebaseApp
      .firestore()
      .collection('reservations')
      .where('ownerId', '==', userId)
      .where('carId', '==', carId)
      .where('toDisplay', '==', true)
      .where('to', '<', curTime)
      .orderBy('to', 'desc')
      .limit(limit)
      .get()
      .then((res) => {
        const reservations = [];
        console.info('Total with carId getAllOwnerPassedReservation: ', res.size);
        res.forEach((r) => {
          reservations.push({ ...r.data(), uid: r.id });
        });
        return reservations;
      });
  } else {
    return firebaseApp
      .firestore()
      .collection('reservations')
      .where('ownerId', '==', userId)
      .where('toDisplay', '==', true)
      .where('to', '<', curTime)
      .orderBy('to', 'desc')
      .limit(limit)
      .get()
      .then((res) => {
        const reservations = [];
        console.info('Total getAllOwnerPassedReservation: ', res.size);
        res.forEach((r) => {
          reservations.push({ ...r.data(), uid: r.id });
        });
        return reservations;
      });
  }
}

export function getAllInterestingCarReservation(carId, ownerId, limit = 10) {
  return firebaseApp
    .firestore()
    .collection('reservations')
    .where('carId', '==', carId)
    .where('ownerId', '==', ownerId)
    .where('toDisplay', '==', true)
    .where('status', 'not-in', [reservationStatus.waitingPayment, reservationStatus.ownerReject, reservationStatus.finished])
    .orderBy('status', 'desc')
    .orderBy('from', 'desc')
    .limit(limit)
    .get()
    .then((res) => {
      const reservations = [];
      console.info('Total getAllInterestingCarReservation: ', res.size);
      res.forEach((r) => reservations.push({ ...r.data(), uid: r.id }));
      return reservations;
    });
}

export function getOneReservation(reservationId) {
  return firebaseApp.firestore().collection('reservations').doc(reservationId).get().then(cleanFirestoreResult);
}
