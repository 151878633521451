import React from 'react';

import { Heading, Text, TextArea, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import MediaUploader from '../../../shared/components/media/mediaUploader';
import { checkCarSetter } from '../../../shared/reducers/checkCar.reducer';
import { getExtraInputProps } from '../../../shared/util/input-utils';

function AddCheckCarPhoto() {
  const checkCar = useSelector((state) => state.checkCar.checkCar);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const imageArray = checkCar?.images ?? [];

  const imageArrayOfType = imageArray.filter((i) => i.type === 'ADDITIONAL');

  function updateImages(images) {
    const cleanImages = images.filter((image) => image?.url !== undefined).map((image) => ({ ...image, type: 'ADDITIONAL', part: 'NONE' }));
    dispatch(checkCarSetter({ ...checkCar, images: [...imageArray.filter((i) => i.type !== 'ADDITIONAL'), ...cleanImages] }));
  }

  return (
    <VStack space={'md'} w={'100%'}>
      <Text variant={'grey'} size={'secondary'}>
        {t('checkCar.addAdditionalPhotoInfo')}
      </Text>
      <MediaUploader
        initialList={imageArrayOfType}
        onUpdate={(images) => updateImages(images)}
        storagePath={'check-car'}
        multipleSelectionAllow={true}
        cameraOnly={true}
      />
    </VStack>
  );
}

export default AddCheckCarPhoto;
