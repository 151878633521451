import React, { useEffect, useState } from 'react';
import { RefreshControl } from 'react-native';

import { Box, Button, ButtonText, Divider, Heading, ScrollView, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CarReviewCard from '../../shared/components/entities/car/carReviewCard';
import CarSmallCard from '../../shared/components/entities/car/carSmallCard';
import ReservationBigCard from '../../shared/components/entities/reservation/reservationBigCard';
import LoginPlaceHolder from '../../shared/components/entities/user/loginPlaceHolder';
import Layout from '../../shared/components/layout/layout';
import NotificationManager from '../../shared/components/notificationManager/notificationManager';
import PlaceHolder from '../../shared/components/placeHolder/placeHolder';
import CarRentalCardSkeleton from '../../shared/components/skeleton/carRentalCardSkeleton';
import StoreReviewComponent from '../../shared/components/storeReview/storeReview';
import WebSpliter from '../../shared/components/web/webSpliter';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { Images } from '../../shared/themes';
import imagesUrl from '../../shared/themes/imagesUrl';
import { reservationStatus, reservationStatusToColorSheme } from '../../shared/util/car-utils';
import { rangeToDateRange } from '../../shared/util/date-transforms';
import { cleanFirestoreListResult } from '../../shared/util/firestore-utils';

function ReservationScreen() {
  const currentUser = useSelector((state) => state.users.currentUser);

  const [onGoingReservations, setOnGoingReservations] = useState([]);
  const [upComingReservations, setUpComingReservations] = useState([]);
  const [historyReservations, setHistoryReservations] = useState([]);
  const [toReviewReservations, setToReviewReservations] = useState([]);
  const [cancelReservations, setCancelReservations] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const maxWidth = useMaxWidth();

  const navigation = useNavigation();
  const { t } = useTranslation();

  useEffect(() => {
    if (currentUser?.uid) {
      setLoading(true);
      const subscriber = firebaseApp
        .firestore()
        .collection('reservations')
        .where('userId', '==', currentUser?.uid)
        .where('toDisplay', '==', true)
        .onSnapshot((resDirty) => {
          const res = cleanFirestoreListResult(resDirty);
          const currentTimeStamp = new Date().getTime() / 1000;
          if (res) {
            setOnGoingReservations(
              res.filter(
                (r) =>
                  r.from < currentTimeStamp &&
                  ([
                    reservationStatus.waitingCheckIn,
                    reservationStatus.waitingCheckOut,
                    reservationStatus.waitingCollectPayment,
                    reservationStatus.waitingPayment,
                    reservationStatus.payementSucced,
                  ].includes(r.status) ||
                    (r?.status === reservationStatus.waitingReview && !r?.isDriverReviewed)),
              ),
            );
            setUpComingReservations(
              res.filter(
                (r) => r?.from > currentTimeStamp && ![reservationStatus.finished, reservationStatus.ownerReject].includes(r?.status),
              ),
            );
            setHistoryReservations(
              res.filter(
                (r) => r?.status === reservationStatus.finished || (r?.status === reservationStatus.waitingReview && r?.isDriverReviewed),
              ),
            );
            setToReviewReservations(res.filter((r) => r?.status === reservationStatus.waitingReview && !r.isDriverReviewed));
            setCancelReservations(res.filter((r) => [reservationStatus.ownerReject].includes(r?.status)));
          }
          setLoading(false);
        });

      return () => subscriber();
    }
  }, [currentUser?.uid]);

  if (!currentUser?.uid) {
    return (
      <Layout>
        <Box py={'$4'} px={'$screenMargin'} w={maxWidth} alignSelf="center">
          <LoginPlaceHolder />
        </Box>
      </Layout>
    );
  }

  return (
    <Layout isBottomBar>
      <StoreReviewComponent toTrigger={upComingReservations?.length > 0} />
      <ScrollView
        refreshControl={<RefreshControl refreshing={isLoading} />}
        contentContainerStyle={{ width: maxWidth, alignSelf: 'center' }}>
        <NotificationManager />
        <WebSpliter
          space={'md'}
          left={
            <VStack space={'md'} my={'$4'} mx={'$screenMargin'}>
              {isLoading && onGoingReservations?.length === 0 && upComingReservations?.length === 0 && historyReservations?.length === 0 ? (
                <CarRentalCardSkeleton />
              ) : null}
              {!isLoading &&
                toReviewReservations?.length === 0 &&
                onGoingReservations?.length === 0 &&
                upComingReservations?.length === 0 && (
                  <PlaceHolder
                    image={{ uri: imagesUrl.mountain }}
                    title={t('placeHolder.noReservation')}
                    text={t('placeHolder.noReservationInfo')}
                    element={
                      <Button w={'100%'} onPress={() => navigation.navigate('Search')}>
                        <ButtonText>{t('placeHolder.getACar')}</ButtonText>
                      </Button>
                    }
                  />
                )}
              {toReviewReservations?.length > 0 ? (
                <VStack space={'md'}>
                  {toReviewReservations.map((item) => (
                    <CarReviewCard
                      key={item?.uid}
                      reservation={item}
                      onPress={(car, reservation) => {
                        navigation.navigate('AddReview', {
                          carId: car?.uid,
                          reservationId: reservation?.uid,
                          reviewedUserId: car?.ownerId,
                        });
                      }}
                    />
                  ))}
                  <Divider />
                </VStack>
              ) : null}
              {onGoingReservations?.length > 0 ? (
                <VStack space={'lg'}>
                  <Heading>{t('rental.onGoing')}</Heading>
                  {onGoingReservations.map((item) => (
                    <VStack key={item?.uid} space={'sm'}>
                      <ReservationBigCard
                        reservation={item}
                        onPress={(car, reservation) => {
                          navigation.navigate('ReservationDetail', { carId: car?.uid, reservationId: reservation?.uid });
                        }}
                      />
                    </VStack>
                  ))}
                </VStack>
              ) : null}
              {upComingReservations?.length > 0 ? (
                <VStack space={'lg'}>
                  <Heading mt={'$4'}>{t('rental.upComing')}</Heading>
                  <VStack space={'sm'}>
                    {upComingReservations.map((item) => (
                      <VStack key={item?.uid} space={'sm'}>
                        <ReservationBigCard
                          reservation={item}
                          onPress={(car, reservation) => {
                            navigation.navigate('ReservationDetail', { carId: car?.uid, reservationId: reservation?.uid });
                          }}
                        />
                      </VStack>
                    ))}
                  </VStack>
                </VStack>
              ) : null}
            </VStack>
          }
          right={
            <VStack space={'md'} my={'$4'} mx={'$screenMargin'}>
              {historyReservations?.length > 0 ? (
                <>
                  <Heading>{t('rental.history')}</Heading>
                  {historyReservations.map((item) => (
                    <CarSmallCard
                      key={item?.uid}
                      car={{ uid: item?.carId }}
                      dateRange={rangeToDateRange(item?.from, item?.to)}
                      onPress={(car, reservation) => {
                        navigation.navigate('ReservationDetail', { carId: item?.carId, reservationId: item?.uid });
                      }}
                    />
                  ))}
                  <Divider />
                </>
              ) : null}
              {cancelReservations?.length > 0 ? (
                <>
                  <Heading>{t('rental.cancel')}</Heading>
                  {cancelReservations.map((item) => (
                    <CarSmallCard
                      key={item?.uid}
                      car={{ uid: item?.carId }}
                      dateRange={rangeToDateRange(item?.from, item?.to)}
                      badge={{ color: reservationStatusToColorSheme(item?.status), text: t('reservationStatus.' + item?.status) }}
                      onPress={(car, reservation) => {
                        navigation.navigate('ReservationDetail', { carId: item?.carId, reservationId: item?.uid });
                      }}
                    />
                  ))}
                </>
              ) : null}
            </VStack>
          }
        />
      </ScrollView>
    </Layout>
  );
}

export default ReservationScreen;
