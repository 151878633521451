import React from 'react';
import { I18nManager, TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { HStack, Icon, Spinner, Text, VStack } from '@gluestack-ui/themed';
import { Link, useNavigation } from '@react-navigation/native';

import Badge from '../badge/badge';
import Skeleton from '../skeleton/skeleton';

export function PressableRow({
  testID,
  py,
  isDisable,
  leftElement,
  leftIconName,
  leftIconColor,
  text,
  onPress,
  infoElement,
  badge,
  loading,
  isSkeleton,
  removeRightCheveron,
  color,
  to,
}) {
  const navigation = useNavigation();

  function onPressRow() {
    if (onPress) {
      onPress();
    } else if (to) {
      navigation.navigate(to?.screen);
    }
  }

  if (isSkeleton) {
    return (
      <VStack space={'sm'} rounded={'$md'} py={py ?? '$4'}>
        <HStack alignItems={'center'} justifyContent={'space-between'}>
          <HStack alignItems={'center'} space={'md'} maxWidth={'70%'}>
            <Skeleton w={'$5'} h={'$5'} rounded={'$full'} />
            <Skeleton.Text w={150} lines={1} />
          </HStack>
          <Skeleton w={'$5'} h={'$5'} rounded={'$full'} lines={1} />
        </HStack>
      </VStack>
    );
  }

  return (
    <TouchableOpacity testID={testID} onPress={onPressRow} disabled={loading || isDisable}>
      <VStack space={'sm'} py={py ?? '$4'} w={'$full'}>
        <HStack alignItems={'center'} justifyContent={'space-between'}>
          <HStack alignItems={'center'} space={'md'} maxWidth={'70%'}>
            {leftElement}
            {leftIconName ? (
              <Icon alignSelf={'center'} as={Ionicons} size={'md'} name={leftIconName} color={leftIconColor ? leftIconColor : '$black'} />
            ) : null}
            <Text alignSelf={'center'} color={color} numberOfLines={1}>
              {text}
            </Text>
            {infoElement}
          </HStack>
          {loading && <Spinner />}
          {!removeRightCheveron && (
            <Icon
              color={color}
              as={Ionicons}
              size={'md'}
              name={'chevron-forward-outline'}
              style={{ transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }] }}
            />
          )}
        </HStack>
        {badge && <Badge colorScheme={badge.color}>{badge.text}</Badge>}
      </VStack>
    </TouchableOpacity>
  );
}
