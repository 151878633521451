import { Divider, HStack, Text } from '@gluestack-ui/themed';

export default function DividerWithText({ text }) {
  return (
    <HStack justifyContent={'space-between'} alignItems={'center'}>
      <Divider my="2" width={'40%'} />
      <Text>{text}</Text>
      <Divider width={'40%'} my="2" />
    </HStack>
  );
}
