import React, { useEffect, useState } from 'react';
import { Platform, TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionTitleText,
  AccordionTrigger,
  Button,
  ButtonText,
  Divider,
  HStack,
  VStack,
} from '@gluestack-ui/themed';
import { Text } from '@gluestack-ui/themed';
import * as Linking from 'expo-linking';
import { useSelector } from 'react-redux';

import BottomSheet from '../../../shared/components/bottomSheet/bottomSheet';
import { errorHandler } from '../../../shared/components/errorHandler/errorHander';
import Image from '../../../shared/components/image/image';
import Select from '../../../shared/components/select/select';
import successToast from '../../../shared/components/toast/successToast';
import ImageView from '../../../shared/generic/ImageView';
import { firebaseApp } from '../../../shared/services/firebase/firebaseFactory';
import imagesUrl from '../../../shared/themes/imagesUrl';
import { alertTypes } from '../../../shared/util/alert-utils';
import { cleanFirestoreListResult } from '../../../shared/util/firestore-utils';

function BackofficeCarDocument({ carId, alerts }) {
  const currentUser = useSelector((state) => state.users.currentUser);
  const [documents, setDocuments] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [docToView, setDocToView] = useState({});
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (currentUser?.uid && carId) {
      firebaseApp
        .firestore()
        .collection('cars')
        .doc(carId)
        .collection('documents')
        .get()
        .then(cleanFirestoreListResult)
        .then((res) => {
          setDocuments(res);
        });
    }
  }, [currentUser, carId]);

  function getColorFromAlert(alertType) {
    switch (alertType) {
      case alertTypes.resolved:
        return '$success500';
      case alertTypes.actionRequired:
        return '$error500';
      case alertTypes.waitingValidation:
        return '$info500';
      case alertTypes.updateNeeded:
        return '$warning500';
      case alertTypes.none:
        return '$light500';
      default:
        break;
    }
  }

  function clickOnDoc(doc) {
    setDocToView(doc);
    setOpen(true);
  }

  function updateAlerts(alertKey, alertStatus) {
    firebaseApp
      .firestore()
      .collection('cars')
      .doc(carId)
      .update({
        alert: {
          ...alerts,
          [alertKey]: alertStatus,
        },
      })
      .then(() => {
        successToast('Car alert updated successfully');
      })
      .catch((e) => {
        errorHandler(e, true);
      });
  }

  return (
    <>
      <Accordion size="md" variant="filled" type="single" isCollapsible={true} isDisabled={false}>
        <AccordionItem value="a">
          <AccordionHeader>
            <AccordionTrigger bgColor={'$light200'}>
              {({ isExpanded }) => {
                return (
                  <>
                    <AccordionTitleText>Car Documents</AccordionTitleText>
                    {isExpanded ? (
                      <AccordionIcon as={Ionicons} name={'chevron-up-outline'} ml="$3" />
                    ) : (
                      <AccordionIcon as={Ionicons} name={'chevron-down-outline'} ml="$3" />
                    )}
                  </>
                );
              }}
            </AccordionTrigger>
          </AccordionHeader>
          <AccordionContent>
            <VStack space={'md'}>
              {documents.map((document) => (
                <Button key={document?.uid} onPress={() => clickOnDoc(document)}>
                  <ButtonText>{document?.type}</ButtonText>
                </Button>
              ))}
            </VStack>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="b">
          <AccordionHeader>
            <AccordionTrigger bgColor={'$light200'}>
              {({ isExpanded }) => {
                return (
                  <>
                    <AccordionTitleText>Car Alerts</AccordionTitleText>
                    {isExpanded ? (
                      <AccordionIcon as={Ionicons} name={'chevron-up-outline'} ml="$3" />
                    ) : (
                      <AccordionIcon as={Ionicons} name={'chevron-down-outline'} ml="$3" />
                    )}
                  </>
                );
              }}
            </AccordionTrigger>
          </AccordionHeader>
          <AccordionContent>
            <VStack space={'md'}>
              {alerts &&
                Object.keys(alerts)
                  .sort((a, b) => b.toLocaleLowerCase().localeCompare(a.toLocaleLowerCase()))
                  .map((alertKey) => (
                    <VStack space={'sm'} key={alertKey}>
                      <HStack justifyContent={'space-between'} alignItem="center">
                        <Text alignSelf="center" color={getColorFromAlert(alerts[alertKey])}>
                          {alertKey}
                        </Text>
                        <Select
                          testID={'alert'}
                          title={alertKey}
                          data={Object.keys(alertTypes).map((al) => ({ value: alertTypes[al], label: alertTypes[al] }))}
                          onSelect={(item) => updateAlerts(alertKey, item.value)}
                          value={alerts[alertKey]}
                        />
                      </HStack>
                      <Divider />
                    </VStack>
                  ))}
            </VStack>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <BottomSheet isOpen={isOpen} setOpen={setOpen} snapToTop={true} headerText={docToView?.type}>
        <VStack space={'md'} mx={'$screenMargin'}>
          {Object.keys(docToView)
            .sort((a, b) => b.toLocaleLowerCase().localeCompare(a.toLocaleLowerCase()))
            .map((docKey) => {
              if (docKey === 'images' || docKey === 'imagesOwnership' || docKey === 'imagesStatement') {
                return (
                  <HStack key={docKey} flexWrap={'wrap'}>
                    {docToView[docKey].map((im) => (
                      <TouchableOpacity
                        key={im.url}
                        onPress={() => {
                          if (im.url.endsWith('.pdf')) {
                            if (Platform.OS === 'web') {
                              window.open(im.url, '_blank');
                            } else {
                              Linking.openURL(im.url);
                            }
                          } else {
                            setImages(docToView[docKey].map((i) => ({ uri: i?.url })));
                            setOpen(false);
                            setVisible(true);
                          }
                        }}>
                        <Image
                          source={{
                            uri: im.url.endsWith('.pdf') ? imagesUrl.documentPlaceHolder : im.url,
                          }}
                          size={'lg'}
                        />
                      </TouchableOpacity>
                    ))}
                  </HStack>
                );
              }
              if (docKey === 'insuranceDate' || docKey === 'technicalControlDate') {
                return (
                  <HStack key={docKey} justifyContent={'space-between'}>
                    <Text>{docKey} : </Text>
                    <Text>{new Date(docToView[docKey] * 1000).toDateString()}</Text>
                  </HStack>
                );
              } else {
                return (
                  <HStack key={docKey} justifyContent={'space-between'}>
                    <Text>{docKey} : </Text>
                    <Text>{JSON.stringify(docToView[docKey], undefined, 2)}</Text>
                  </HStack>
                );
              }
            })}
        </VStack>
      </BottomSheet>
      <ImageView images={images} imageIndex={0} visible={isVisible} onRequestClose={() => setVisible(false)} />
    </>
  );
}

export default BackofficeCarDocument;
