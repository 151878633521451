import React, { Component } from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, Text, View, VStack } from '@gluestack-ui/themed';

export default class CallendarCell extends Component {
  state = {
    isBlocked: false,
    periods: [],
    dots: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      periods: props.marking?.periods && props.marking?.periods?.length > 0 ? props.marking?.periods : [],
      blockeds: props.marking?.blockeds && props.marking?.blockeds?.length > 0 ? props.marking?.blockeds : [],
      dots: props.marking?.dots && props.marking?.dots?.length > 0 ? props.marking?.dots : [],
    };
    this.setDots = this.setDots.bind(this);
    this.setPeriods = this.setPeriods.bind(this);
    this.setBlocked = this.setBlocked.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const isDisabled = this.props.marking?.disabled ? this.props.marking?.disabled : false;
    const isEvent = this.props.marking?.isEvent ? this.props.marking?.isEvent : false;
    const maxIndex = this.props.marking?.maxIndex ? this.props.marking.maxIndex : 0;
    const periods = this.props.marking?.periods && this.props.marking?.periods?.length > 0 ? this.props.marking?.periods : [];
    const blockeds = this.props.marking?.blockeds && this.props.marking?.blockeds?.length > 0 ? this.props.marking?.blockeds : [];
    const dots = this.props.marking?.dots && this.props.marking?.dots?.length > 0 ? this.props.marking?.dots : [];

    const nextMaxIndex = nextProps.marking?.maxIndex ? nextProps.marking.maxIndex : 0;
    const nextisDisabled = nextProps.marking?.disabled ? nextProps.marking?.disabled : false;
    const nextisEvent = nextProps.marking?.isEvent ? nextProps.marking?.isEvent : false;
    const nextPeriods = nextProps.marking?.periods && nextProps.marking?.periods?.length > 0 ? nextProps.marking?.periods : [];
    const nextBlockeds = nextProps.marking?.blockeds && nextProps.marking?.blockeds?.length > 0 ? nextProps.marking?.blockeds : [];
    const nextDots = nextProps.marking?.dots && nextProps.marking?.dots?.length > 0 ? nextProps.marking?.dots : [];

    if (
      blockeds !== nextBlockeds ||
      isEvent !== nextisEvent ||
      dots.length !== nextDots?.length ||
      periods?.length !== nextPeriods?.length ||
      isDisabled !== nextisDisabled ||
      maxIndex !== nextMaxIndex
    ) {
      return true;
    } else {
      return false;
    }
  }

  setDaySelected(date) {
    this.setState({ ...this.state, selectedDay: date });
  }
  setDots(dots) {
    this.setState({ ...this.state, dots: dots });
  }
  setPeriods(periods) {
    this.setState({ ...this.state, periods: periods });
  }
  setBlocked(isBlocked) {
    this.setState({ ...this.state, isBlocked: isBlocked });
  }

  openEvent(day) {
    this.setDaySelected(day.dateString);
  }

  render() {
    const isDisable = this.props.marking?.disabled ? this.props.marking?.disabled : false;
    const isEvent = this.props.marking?.isEvent ? this.props.marking?.isEvent : false;
    const periods = this.props.marking?.periods && this.props.marking?.periods?.length > 0 ? this.props.marking?.periods : [];
    const blockeds = this.props.marking?.blockeds && this.props.marking?.blockeds?.length > 0 ? this.props.marking?.blockeds : [];
    const maxIndex = this.props.marking?.maxIndex ? this.props.marking.maxIndex : 0;
    const dots = this.props.marking?.dots && this.props.marking?.dots?.length > 0 ? this.props.marking?.dots : [];
    //{...(isEvent ? {rounded: 'full', backgroundColor: '$blue400'}: null)}
    return (
      <Box>
        <TouchableOpacity onPress={() => this.props.onPress(this.props.date)} disabled={isDisable}>
          {isEvent ? (
            <Box rounded="$full" backgroundColor="$blue900" w={30} h={30} justifyContent={'center'} mt={-1}>
              <Text alignSelf={'center'} textAlign={'center'} color={'$white'} maxFontSizeMultiplier={1}>
                {this.props.date.day}
              </Text>
            </Box>
          ) : (
            <VStack w={55} h={30} justifyContent={'flex-start'} alignItems={'center'} space={'xs'}>
              <Box justifyContent={'center'} alignItems={'center'} w={30}>
                <Text size={'primary'} alignSelf={'center'} color={isDisable ? '$light300' : '$black'} maxFontSizeMultiplier={1}>
                  {this.props.date.day}
                </Text>
                {maxIndex > 0 && (
                  <VStack space={'xs'} position={'absolute'}>
                    {[...Array(maxIndex)].map((a, i) => {
                      const blocked = blockeds && blockeds?.length > 0 && blockeds.filter((p) => p.index === i);
                      if (blocked && blocked.length > 0) {
                        return (
                          <View
                            key={i}
                            style={{
                              transform: [{ rotate: '17deg' }],
                              width: 25,
                              height: 1,
                              top: 2,
                              borderBottomColor: blocked[0].color,
                              borderBottomWidth: 2,
                            }}
                          />
                        );
                      } else {
                        return (
                          <View
                            key={i}
                            style={{
                              width: 25,
                              height: 1,
                            }}
                          />
                        );
                      }
                    })}
                  </VStack>
                )}
              </Box>
              <Box h={15} alignItems={'center'}>
                {maxIndex > 0 && (
                  <VStack space={'xs'} alignItems={'center'}>
                    {[...Array(maxIndex)].map((a, i) => {
                      const period = periods && periods?.length > 0 && periods.filter((p) => p.index === i);
                      const dot = dots && dots?.length > 0 && dots.filter((p) => p.index === i);
                      if (period && period.length > 0) {
                        return <Box key={i} h={3} w={55} backgroundColor={period[0].color} />;
                      } else if (dot && dot?.length > 0) {
                        return <Box key={i} h={3} w={3} rounded={'$full'} backgroundColor={dot[0].color} />;
                      } else {
                        return <Box key={i} h={3} />;
                      }
                    })}
                    <Box />
                  </VStack>
                )}
              </Box>
            </VStack>
          )}
        </TouchableOpacity>
      </Box>
    );
  }
}
