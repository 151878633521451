import { Platform } from 'react-native';

import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';

import { errorHandler } from '../components/errorHandler/errorHander';

export async function registerForPushNotificationsAsync(requestPermission = false) {
  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'Default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    }).catch((e) => errorHandler(e, true));
    await Notifications.setNotificationChannelAsync('direct-messages', {
      name: 'Direct messages',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    }).catch((e) => errorHandler(e, true));
    await Notifications.setNotificationChannelAsync('rental', {
      name: 'Rental',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    }).catch((e) => errorHandler(e, true));
  }

  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted' && requestPermission) {
      const a = await Notifications.requestPermissionsAsync().catch((e) => errorHandler(e, false));
      finalStatus = a.status;
    }
    if (finalStatus !== 'granted') {
      throw new Error('Failed to get push token for push notification!');
    }
    const token = (await Notifications.getExpoPushTokenAsync({ projectId: Constants.expoConfig?.extra?.eas?.projectId })).data;
    return token;
  } else {
    throw new Error('Must use physical device for Push Notifications');
  }
}
