import React, { useCallback, useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, Button, ButtonText, Divider, Heading, HStack, ScrollView, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AdditionalPaymentBadge from '../../shared/components/badge/additionalPaymentBadge';
import BottomAction from '../../shared/components/bottomAction/bottomAction';
import LoginPlaceHolder from '../../shared/components/entities/user/loginPlaceHolder';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Image from '../../shared/components/image/image';
import InvoiceDetail from '../../shared/components/info/invoiceDetail';
import Layout from '../../shared/components/layout/layout';
import PlaceHolder from '../../shared/components/placeHolder/placeHolder';
import successToast from '../../shared/components/toast/successToast';
import ContentTranslation from '../../shared/components/translation/contentTanslation';
import WebScrollView from '../../shared/components/web/webScrollView';
import ImageView from '../../shared/generic/ImageView';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { oneReservationListener } from '../../shared/services/firebase/reservation-firebase';
import { Images, Metrics } from '../../shared/themes';
import imagesUrl from '../../shared/themes/imagesUrl';
import { reservationStatus } from '../../shared/util/car-utils';
import { cleanFirestoreResult } from '../../shared/util/firestore-utils';
import { resizedImage } from '../../shared/util/image-utils';

function AdditionalPaymentDetail({ route }) {
  const { reservationId, additionalPaymentId, isOwner, fromPayment } = route.params ?? {};

  const currentUser = useSelector((state) => state.users.currentUser);

  const [additionalPayment, setAdditionalPayment] = useState({});
  const [reservation, setReservation] = useState({});
  const [loading, setLoading] = useState(true);
  const [bottomActionHeight, setBottomActionHeight] = useState(true);

  const [isVisible, setVisible] = useState(false);
  const [imageIndex, setIndex] = useState(0);
  const [imageZoom, setImageZoom] = useState([]);

  const { t } = useTranslation();
  const navigation = useNavigation();

  const maxWidth = useMaxWidth();

  const onReservationSnapshot = useCallback(
    (snapshot) => {
      setLoading(true);
      console.debug('Reservation Snapshot for Additional payment');
      const cleanData = cleanFirestoreResult(snapshot);
      const adPay = cleanData?.additionalPayments ? cleanData?.additionalPayments.filter((aP) => aP.uid === additionalPaymentId) : [];
      setReservation(cleanData);
      setAdditionalPayment(adPay?.length ? adPay[0] : {});
      setLoading(false);
    },
    [additionalPaymentId],
  );

  function onReservationSnapshotError(e) {
    errorHandler(e, true);
    setLoading(false);
  }

  useEffect(() => {
    if (fromPayment) {
      successToast(t('success.additionalPaymentSuccess'));
    }
  }, [fromPayment, t]);

  useEffect(() => {
    console.debug('Effect: refresh owner reservation');
    setLoading(true);
    const subscriber = oneReservationListener(reservationId, onReservationSnapshot, onReservationSnapshotError);
    return () => subscriber();
  }, [reservationId, additionalPaymentId, onReservationSnapshot]);

  function zoomInImage(imArr, index) {
    setImageZoom(
      imArr.map((i) => {
        return { uri: i.url };
      }),
    );
    setIndex(index);
    setVisible(true);
  }

  const isDriver = currentUser?.uid === reservation?.userId;

  if (!currentUser?.uid) {
    return (
      <Layout>
        <Box py={'$4'} px={'$screenMargin'} w={maxWidth} alignSelf="center">
          <LoginPlaceHolder />
        </Box>
      </Layout>
    );
  }

  if (!isDriver && reservation?.ownerId !== currentUser?.uid && !loading) {
    return <PlaceHolder image={{ uri: imagesUrl.empty }} title={t('error.operationNotAllowed')} />;
  }

  return (
    <>
      <WebScrollView>
        <Box mb={bottomActionHeight}>
          <VStack my={'$4'} mx={'$screenMargin'} space={'md'}>
            <ImageView images={imageZoom} imageIndex={imageIndex} visible={isVisible} onRequestClose={() => setVisible(false)} />
            <VStack>
              {additionalPayment?.status && <AdditionalPaymentBadge additionalPayment={additionalPayment} w={'45%'} />}
              <Heading w={'45%'}>{t('additionalPayment.request')}</Heading>
            </VStack>
            <InvoiceDetail
              ownerInvoice={isOwner ? { ...additionalPayment.invoiceOwner, reason: additionalPayment?.originalContent?.reason } : undefined}
              reservationInvoice={{ ...additionalPayment.invoice, reason: additionalPayment?.originalContent?.reason }}
              receiptUrl={additionalPayment?.receiptUrl}
              isLoading={loading}
            />
            <Divider />
            <Heading>{t('additionalPayment.note')}</Heading>
            <ContentTranslation object={additionalPayment} field={'note'} lang={currentUser?.language} limit={100000000} />
            <Divider />
          </VStack>
          {additionalPayment?.images && additionalPayment?.images.length > 0 && (
            <VStack space={'md'} my={'$4'}>
              <Heading mx={'$screenMargin'}>{t('additionalPayment.photo')}</Heading>
              <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                <HStack>
                  {additionalPayment?.images.map((i, index) => (
                    <TouchableOpacity key={i.url} onPress={() => zoomInImage(additionalPayment?.images, index)}>
                      <Image
                        rounded={'$2xl'}
                        alt="inside car"
                        mx={'$screenMargin'}
                        height={Metrics.images.xlarge}
                        width={Metrics.images.xxlarge}
                        source={{ uri: resizedImage(i.url) }}
                      />
                    </TouchableOpacity>
                  ))}
                </HStack>
              </ScrollView>
              <Divider />
            </VStack>
          )}
        </Box>
      </WebScrollView>
      {isDriver && additionalPayment.status === reservationStatus.waitingPayment && (
        <BottomAction
          onLayout={(event) => {
            setBottomActionHeight(event?.nativeEvent?.layout?.height ?? 0);
          }}>
          <Box w={maxWidth} alignSelf={'center'} px={'$screenMargin'} py={'$4'}>
            <Button
              onPress={() =>
                navigation.navigate('AdditionalPayment', { reservationId: reservationId, additionalPaymentId: additionalPayment.uid })
              }>
              <ButtonText>{t('additionalPayment.payNow')}</ButtonText>
            </Button>
          </Box>
        </BottomAction>
      )}
    </>
  );
}

export default AdditionalPaymentDetail;
