import React, { useEffect, useState } from 'react';

import { VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { getCarReviews } from '../../../services/firebase/review-firebase';
import { errorHandler } from '../../errorHandler/errorHander';
import ReviewCard from '../../reviewCard/reviewCard';
import ReviewSkeleton from '../../skeleton/reviewSkeleton';

export default function CarReviews({ carId, limit }) {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigation = useNavigation();
  const { t } = useTranslation();

  useEffect(() => {
    console.debug('Effect: refresh carID, limit');
    if (carId) {
      setLoading(true);
      getCarReviews(carId, limit)
        .then((res) => {
          setLoading(false);
          setReviews(res);
        })
        .catch((e) => {
          errorHandler(e, true);
          setLoading(false);
        });
    }
  }, [carId, limit]);

  if (loading) {
    return (
      <VStack space={'md'} my={'$4'}>
        <ReviewSkeleton />
        <ReviewSkeleton />
        <ReviewSkeleton />
      </VStack>
    );
  }

  return (
    <VStack space={'md'} my={'$4'}>
      {reviews.length > 0 ? reviews.map((review) => <ReviewCard key={review.uid} review={review} />) : null}
    </VStack>
  );
}
