import React, { useState } from 'react';

import {
  Box,
  Button,
  ButtonSpinner,
  ButtonText,
  FormControl,
  FormControlLabel,
  Heading,
  HStack,
  Input,
  InputField,
  ScrollView,
  Text,
  Textarea,
  TextareaInput,
  VStack,
} from '@gluestack-ui/themed';
import { FormControlLabelText } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import appConfig from '../../config/app-config';
import { goBackOrDefault } from '../../navigation/nav-ref';
import BottomAction from '../../shared/components/bottomAction/bottomAction';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import InputPrice from '../../shared/components/input/inputPrice';
import Layout from '../../shared/components/layout/layout';
import MediaUploader from '../../shared/components/media/mediaUploader';
import UnderstandPrice from '../../shared/components/static/understandPrice';
import errorToast from '../../shared/components/toast/errorToast';
import successToast from '../../shared/components/toast/successToast';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { requestAdditionalPayment } from '../../shared/services/firebase/function-firebase';
import { Metrics } from '../../shared/themes';
import { formatCurrency } from '../../shared/util/currency';
import { getExtraInputProps } from '../../shared/util/input-utils';

function OwnerRequestAdditionalPayment({ route }) {
  const { reservationId } = route?.params ?? {};

  const currentUser = useSelector((state) => state.users.currentUser);
  const currency = useSelector((state) => state.users.currency);

  const [additionalPayment, setAdditionalPayment] = useState({ reservationId: reservationId, language: currentUser?.language });
  const [loadingAdditionalPayment, setLoadingAdditionalPayment] = useState(false);

  const { t } = useTranslation();
  const maxWidth = useMaxWidth();

  const [bottomActionHeight, setBottomActionHeight] = useState(0);

  const validationSchema = Yup.object().shape({
    reason: Yup.string().required().label('Reason'),
    price: Yup.number().required().label('Price'),
  });

  function sendPaymentRequest() {
    validationSchema
      .validate({ ...additionalPayment?.originalContent, price: additionalPayment?.price }, { abortEarly: true })
      .then((valid) => {
        setLoadingAdditionalPayment(true);
        requestAdditionalPayment(additionalPayment)
          .then((res) => {
            successToast(t('additionalPayment.requestSend'));
            setLoadingAdditionalPayment(false);
            goBackOrDefault('OwnerHome');
          })
          .catch((e) => {
            errorHandler(e, true);
            setLoadingAdditionalPayment(false);
          });
      })
      .catch((err) => {
        errorToast(err?.errors[0]);
        setLoadingAdditionalPayment(false);
      });
  }

  const imageArray = additionalPayment?.images ? additionalPayment?.images : [];

  function updateImages(images) {
    const cleanImages = images.filter((image) => image?.url !== undefined);
    setAdditionalPayment((prevCheckCar) => {
      return { ...prevCheckCar, images: [...cleanImages] };
    });
  }

  console.log(loadingAdditionalPayment, !additionalPayment?.price, !additionalPayment?.originalContent?.reason);
  return (
    <Layout>
      <ScrollView
        contentContainerStyle={{ width: maxWidth > Metrics.maxWidthSmall ? Metrics.maxWidthSmall : maxWidth, alignSelf: 'center' }}
        mb={bottomActionHeight}>
        <VStack my={'$4'} mx={'$screenMargin'} space={'md'}>
          <Text variant={'grey'} size={'secondary'}>
            {t('additionalPayment.subTitle')}
          </Text>
          <VStack space={'sm'}>
            <FormControl>
              <FormControlLabel>
                <FormControlLabelText>{t('additionalPayment.paymentInfo')}</FormControlLabelText>
              </FormControlLabel>
              <HStack justifyContent={'space-between'} alignItems="center">
                <Input w={'60%'}>
                  <InputField
                    inputMode="text"
                    value={additionalPayment?.originalContent?.reason}
                    placeholder={t('additionalPayment.requestReasonPlaceHolder')}
                    onChangeText={(text) =>
                      setAdditionalPayment({
                        ...additionalPayment,
                        originalContent: { ...additionalPayment?.originalContent, reason: text },
                      })
                    }
                    maxLength={15}
                  />
                </Input>
                <InputPrice
                  w={'30%'}
                  currency={currency}
                  dolarPrice={additionalPayment?.price}
                  onChangePrice={(value) => setAdditionalPayment({ ...additionalPayment, price: value, currency: 'USD' })}
                />
              </HStack>
            </FormControl>
            <UnderstandPrice>
              <HStack alignItems={'center'} space={'sm'}>
                <Text w={'50%'} underline>
                  {t('addCar.driverWillPay')}
                </Text>
                <Text fontWeight={'$bold'}>
                  {formatCurrency(
                    Math.round(additionalPayment?.price * currency[currency?.current] * appConfig.serviceFee),
                    currency.current,
                  )}
                </Text>
              </HStack>
            </UnderstandPrice>
          </VStack>
          <VStack space={'sm'}>
            <FormControl>
              <FormControlLabel>
                <FormControlLabelText>{t('additionalPayment.note')}</FormControlLabelText>
              </FormControlLabel>
              <Textarea {...getExtraInputProps()}>
                <TextareaInput
                  value={additionalPayment?.originalContent?.note}
                  placeholder={t('additionalPayment.notePlaceholder')}
                  onChangeText={(text) =>
                    setAdditionalPayment({ ...additionalPayment, originalContent: { ...additionalPayment?.originalContent, note: text } })
                  }
                />
              </Textarea>
            </FormControl>
          </VStack>
          <VStack space={'sm'}>
            <Heading>{t('additionalPayment.photo')}</Heading>
            <Text variant={'grey'} size={'secondary'}>
              {t('additionalPayment.photoDescription')}
            </Text>
            <MediaUploader
              initialList={imageArray}
              onUpdate={(images) => updateImages(images)}
              storagePath={'additional-payment'}
              multipleSelectionAllow={true}
            />
          </VStack>
        </VStack>
      </ScrollView>
      <BottomAction
        onLayout={(event) => {
          setBottomActionHeight(event?.nativeEvent?.layout?.height ?? 0);
        }}>
        <Box px={'$screenMargin'} py={'$4'} width={maxWidth > Metrics.maxWidthSmall ? Metrics.maxWidthSmall : maxWidth} alignSelf="center">
          <Button
            isDisabled={loadingAdditionalPayment || !additionalPayment?.price || !additionalPayment?.originalContent?.reason}
            onPress={sendPaymentRequest}>
            {loadingAdditionalPayment && <ButtonSpinner mr="$1" />}
            <ButtonText>{t('additionalPayment.request')}</ButtonText>
          </Button>
        </Box>
      </BottomAction>
    </Layout>
  );
}
export default OwnerRequestAdditionalPayment;
