import React from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { HStack, Icon, Text } from '@gluestack-ui/themed';
import { Heading } from '@gluestack-ui/themed';
import { VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';

import Image from '../../../shared/components/image/image';
import imagesUrl from '../../../shared/themes/imagesUrl';

export default function SectionReview({ name, review, imageUrl }) {
  const navigation = useNavigation();
  return (
    <VStack w={'$full'} space="2xl" alignItems="center">
      <Image source={{ uri: imagesUrl.quoteMark }} h={'$12'} w={'$12'} resizeMode={'contain'} />
      <HStack space="sm" mt={'$8'}>
        <Icon as={Ionicons} name={'star'} color={'$gold'} size="md" />
        <Icon as={Ionicons} name={'star'} color={'$gold'} size="md" />
        <Icon as={Ionicons} name={'star'} color={'$gold'} size="md" />
        <Icon as={Ionicons} name={'star'} color={'$gold'} size="md" />
        <Icon as={Ionicons} name={'star'} color={'$gold'} size="md" />
      </HStack>
      <Text textAlign="center">“{review}”</Text>
      <TouchableOpacity onPress={() => navigation.navigate('PublicProfileScreen', { userId: '3mSWPs4ZDtXR4H9CcIFkr5r3Lem2' })}>
        <VStack space="sm">
          <Image source={{ uri: imageUrl }} h={'$16'} w={'$16'} rounded={'$full'} />
          <Heading size="primary">{name}</Heading>
        </VStack>
      </TouchableOpacity>
    </VStack>
  );
}
