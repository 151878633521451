import React, { useMemo, useState } from 'react';

import { Box, FlatList, useToken } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ReservationCard from './reservationCard';
import { useMaxWidth } from '../../../provider/maxWidthProvider';
import { Images } from '../../../themes';
import imagesUrl from '../../../themes/imagesUrl';
import FirestoreList from '../../firestore/firestoreList';
import PlaceHolder from '../../placeHolder/placeHolder';
import SmallCard from '../../pressable/smallCard';

function ReservationList({ type, carId }) {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const maxWidth = useMaxWidth();
  const space4 = useToken('space', '4');
  const screenMargin = useToken('space', 'screenMargin');

  const currentUser = useSelector((state) => state.users.currentUser);

  const query = useMemo(() => {
    const baseFilter = [];
    const curTime = Math.round(new Date().getTime() / 1000);
    if (!currentUser?.uid) {
      return {};
    }

    switch (type) {
      case 'current':
        baseFilter.push({ field: 'ownerId', operator: '==', value: currentUser?.uid });
        baseFilter.push({ field: 'toDisplay', operator: '==', value: true });
        baseFilter.push({ field: 'to', operator: '>', value: curTime });
        if (carId) {
          baseFilter.push({ field: 'carId', operator: '==', value: carId });
        }
        return { filters: baseFilter, orderBy: { field: 'to', order: 'desc' } };
      case 'up-comming':
        baseFilter.push({ field: 'ownerId', operator: '==', value: currentUser?.uid });
        baseFilter.push({ field: 'toDisplay', operator: '==', value: true });
        baseFilter.push({ field: 'from', operator: '>', value: curTime });
        if (carId) {
          baseFilter.push({ field: 'carId', operator: '==', value: carId });
        }
        return { filters: baseFilter, orderBy: { field: 'from', order: 'desc' } };
      case 'passed':
        baseFilter.push({ field: 'ownerId', operator: '==', value: currentUser?.uid });
        baseFilter.push({ field: 'toDisplay', operator: '==', value: false });
        baseFilter.push({ field: 'to', operator: '<', value: curTime });
        if (carId) {
          baseFilter.push({ field: 'carId', operator: '==', value: carId });
        }
        return { filters: baseFilter, orderBy: { field: 'to', order: 'desc' } };
    }
  }, [carId, currentUser?.uid, type]);

  function transformData(data) {
    const curTime = Math.round(new Date().getTime() / 1000);

    switch (type) {
      case 'current':
        return data.filter((d) => d?.from < curTime);
      case 'up-comming':
        return data;
      case 'passed':
        return data;
    }
  }

  return (
    <FirestoreList
      keyExtractor={(item) => item?.uid}
      ItemSeparatorComponent={<Box my={'$1'} />}
      isConnected={true}
      collectionName={'reservations'}
      transformer={transformData}
      orderBy={query?.orderBy}
      filters={query?.filters}
      renderItem={({ item }) => (
        <ReservationCard reservation={item} onPress={() => navigation.navigate('RequestDetailScreen', { reservationId: item.uid })} />
      )}
      contentContainerStyle={{ alignSelf: 'center', width: maxWidth, paddingVertical: space4, paddingHorizontal: screenMargin }}
      ListEmptyComponent={<PlaceHolder image={{ uri: imagesUrl.empty }} title={t('placeHolder.noReservation')} />}
    />
  );
}

export default ReservationList;
