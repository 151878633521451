import React, { useEffect, useState } from 'react';

import { Text } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

export default function CountDown({ to, durationUntil }) {
  const [time, setTime] = useState();

  const { t } = useTranslation();

  function getTime() {
    const remainingTime = to - durationUntil - new Date().getTime() / 1000;
    const days = Math.floor(remainingTime / 86400);
    const hours = Math.floor((remainingTime % 86400) / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = Math.floor(remainingTime % 60);

    if (hours === 0 && minutes === 0 && days === 0) {
      setTime(t('countDown.seconds', { hours: hours, minutes: minutes, seconds: seconds }));
    } else if (hours === 0 && days === 0) {
      setTime(t('countDown.minutes', { hours: hours, minutes: minutes, seconds: seconds }));
    } else if (days === 0) {
      setTime(t('countDown.hours', { hours: hours, minutes: minutes, seconds: seconds }));
    } else {
      setTime(t('countDown.days', { days: days, hours: hours, minutes: minutes, seconds: seconds }));
    }
  }

  useEffect(() => {
    getTime();
    setInterval(() => {
      getTime();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Text color={'$white'} alignSelf={'center'} textAlign={'center'}>
      {time}
    </Text>
  );
}
