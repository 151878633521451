import React from 'react';
import { Platform } from 'react-native';

import { Switch } from '@gluestack-ui/themed';

export default function Toggle({ onPress, isChecked }) {
  return (
    <Switch
      size={Platform.OS === 'ios' ? 'sm' : 'md'}
      offTrackColor="$light300"
      onTrackColor="$blue900"
      onThumbColor="$light100"
      offThumbColor="$light100"
      onToggle={onPress}
      value={isChecked}
    />
  );
}
