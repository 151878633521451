import { IS_WEB } from '../util/device-utils';

// Used via Metrics.baseMargin
const metrics = {
  headerSize: 70,
  bottomTabSize: 49,
  headerTitleSize: 80,
  fullWidth: IS_WEB ? '100dvw' : '100%',
  fullHeight: IS_WEB ? '100dvh' : '100%',
  marginHorizontal: 4,
  marginVertical: 4,
  baseMargin: 4,
  screenWidthMarging: 6,
  doubleBaseMargin: 8,
  inputHeight: 12,
  smallMargin: 2,
  tinyMargin: 1,
  doubleSection: 50,
  maxWidthSmall: 700,
  radius: 20,
  images: {
    tiny: 15,
    small: 20,
    medium: 40,
    large: 60,
    logo: 100,
    xlarge: 150,
    xxlarge: 200,
  },
  sizes: {
    px: '1px',
    0: 0,
    0.5: 2,
    1: 4,
    2: 8,
    3: 12,
    4: 16,
    5: 20,
    6: 24,
    7: 28,
    8: 32,
    9: 36,
    10: 40,
    12: 48,
    16: 64,
    18: 72,
    20: 80,
    24: 96,
    32: 128,
    40: 160,
    48: 192,
    56: 224,
    64: 256,
    full: '100%',
  },
};

export default metrics;
