import React, { useEffect, useState } from 'react';

import { Divider, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import ProfileCard from '../../shared/components/entities/user/ProfileCard';
import ProfileCardById from '../../shared/components/entities/user/ProfileCardById';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import InfoExtender from '../../shared/components/info/infoExtender';
import Toggle from '../../shared/components/toggle/toggle';
import UserCarsCarousel from '../../shared/components/userCarsCarousel/userCarsCarousel';
import WebScrollView from '../../shared/components/web/webScrollView';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { getOneUser } from '../../shared/services/firebase/user-firebase';

function BackofficeOwnerDetail({ route }) {
  const { userId } = route?.params ?? {};

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState({});

  const { t } = useTranslation();
  const navigation = useNavigation();

  function getUser() {
    setLoading(true);
    getOneUser(userId)
      .then((res) => {
        setUser(res);
        setLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoading(false);
      });
  }
  useEffect(() => {
    console.debug('Effect: userId');
    getUser();
  }, [userId]);

  useEffect(() => {
    console.debug('Effect: refresh navigation');
    navigation.getParent()?.setOptions({
      tabBarStyle: {
        display: 'none',
      },
    });
    return () =>
      navigation.getParent()?.setOptions({
        tabBarStyle: undefined,
      });
  }, [navigation]);

  function updateUserVerified() {
    firebaseApp.firestore().collection('profiles').doc(userId).update({ isVerified: !user?.isVerified }).then(getUser);
  }

  return (
    <WebScrollView mt={'$4'}>
      <VStack space={'md'}>
        <VStack space={'md'} mx={'$4'}>
          <HStack space={'md'}>
            <ProfileCard
              loading={loading}
              profile={user}
              onPress={() => navigation.navigate('PublicProfileScreen', { userId: user?.uid })}
            />
          </HStack>
          <Text selectable={true}>ID: {userId}</Text>
          <HStack space="md">
            <Text>Verfied</Text>
            <Toggle isChecked={user?.isVerified} onPress={updateUserVerified} />
          </HStack>
          <Divider />
          <InfoExtender object={user} field={'about'} loading={loading} headerText={t('profile.about')} showHeader={true} limit={100} />
          <Divider />
        </VStack>
        <UserCarsCarousel
          displayAllCar={true}
          userId={userId}
          title={t('car.usersCar', { name: user.firstName })}
          onPress={(item) => navigation.navigate('BackOfficeCar', { carId: item?.uid })}
        />
      </VStack>
    </WebScrollView>
  );
}

export default BackofficeOwnerDetail;
