import React, { useEffect } from 'react';
import { StyleSheet, TextInput, View } from 'react-native';

import { PanGestureHandler } from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedProps,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';

import { Colors } from '../../themes';
import { shadow } from '../../themes/nativeBaseTheme';

const minThumbSize = 30;
const maxThumbSize = 40;

const Slider = ({ sliderWidth, min, max, value, step, onValueChange, textPrefix, textSufix, color }) => {
  const maxValue = sliderWidth - minThumbSize;
  const position = useSharedValue(value ? (value * maxValue) / max : min);
  const opacity = useSharedValue(0);
  const zIndex = useSharedValue(0);
  const size = useSharedValue(25);

  useEffect(() => {
    position.value = withSpring(value ? maxValue - ((max - min - value + min) / (max - min)) * maxValue : min, { mass: 1, velocity: 30 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const gestureHandler = useAnimatedGestureHandler({
    onStart: (_, ctx) => {
      ctx.startX = position.value;
      opacity.value = 1;
      size.value = maxThumbSize;
    },
    onActive: (e, ctx) => {
      opacity.value = 1;
      size.value = maxThumbSize;
      if (ctx.startX + e.translationX < 0) {
        position.value = 0;
      } else if (ctx.startX + e.translationX > maxValue) {
        position.value = maxValue;
        zIndex.value = 1;
      } else {
        position.value = ctx.startX + e.translationX;
      }
    },
    onCancel: () => {
      opacity.value = 0;
      size.value = minThumbSize;
      runOnJS(onValueChange)(min + Math.round(position.value / (maxValue / ((max - min) / step))) * step);
    },
    onFail: () => {
      opacity.value = 0;
      size.value = minThumbSize;
      runOnJS(onValueChange)(min + Math.round(position.value / (maxValue / ((max - min) / step))) * step);
    },
    onFinish: () => {
      opacity.value = 0;
      size.value = minThumbSize;
      runOnJS(onValueChange)(min + Math.round(position.value / (maxValue / ((max - min) / step))) * step);
    },
    onEnd: () => {
      opacity.value = 0;
      size.value = minThumbSize;
      runOnJS(onValueChange)(min + Math.round(position.value / (maxValue / ((max - min) / step))) * step);
    },
  });

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateX: position.value }],
    zIndex: zIndex.value,
    height: size.value,
    width: size.value,
  }));

  const opacityStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
  }));

  const sliderStyle = useAnimatedStyle(() => ({
    width: position.value,
  }));

  const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);
  const minLabelText = useAnimatedProps(() => {
    return {
      text: `${textPrefix}${min + Math.round(position.value / (maxValue / ((max - min) / step))) * step}${textSufix}`,
    };
  });
  return (
    <View style={[styles.sliderContainer, { width: sliderWidth }]}>
      <View style={[styles.sliderBack, { width: sliderWidth }]} />
      <Animated.View style={[sliderStyle, styles.sliderFront, { backgroundColor: color ?? Colors.blue[900] }]} />
      <PanGestureHandler onGestureEvent={gestureHandler} onHandlerStateChange={gestureHandler}>
        <Animated.View style={[animatedStyle, styles.thumbLeft]}>
          <Animated.View style={[opacityStyle, styles.label]}>
            <AnimatedTextInput
              style={styles.labelText}
              animatedProps={minLabelText}
              editable={false}
              defaultValue={`${textPrefix}${min + Math.round(position.value / (maxValue / ((max - min) / step))) * step}${textSufix}`}
            />
          </Animated.View>
        </Animated.View>
      </PanGestureHandler>
    </View>
  );
};

export default Slider;

const styles = StyleSheet.create({
  sliderContainer: {
    justifyContent: 'center',
    alignSelf: 'center',
  },
  sliderBack: {
    height: 4,
    backgroundColor: Colors.light[200],
    borderRadius: 20,
  },
  sliderFront: {
    height: 4,
    backgroundColor: Colors.blue[900],
    borderRadius: 20,
    position: 'absolute',
  },
  thumbLeft: {
    left: 0,
    width: minThumbSize,
    height: minThumbSize,
    position: 'absolute',
    backgroundColor: 'white',
    borderColor: Colors.blue[900],
    ...shadow,
    boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: maxThumbSize / 2,
  },
  thumbRight: {
    right: 0,
    width: minThumbSize,
    height: minThumbSize,
    position: 'absolute',
    backgroundColor: 'white',
    borderColor: Colors.blue[900],
    ...shadow,
    boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: maxThumbSize / 2,
  },
  label: {
    position: 'absolute',
    top: -maxThumbSize,
    bottom: maxThumbSize + 4,
    backgroundColor: 'black',
    borderRadius: 5,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelText: {
    color: Colors.white,
    padding: 5,
    fontWeight: '500',
    fontSize: 16,
    width: '100%',
  },
});
