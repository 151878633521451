import React, { useEffect, useState } from 'react';

import { Ionicons } from '@expo/vector-icons';
import { Icon } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { getOneUser } from '../../../services/firebase/user-firebase';
import { Colors } from '../../../themes';
import { errorHandler } from '../../errorHandler/errorHander';
import SmallCard from '../../pressable/smallCard';

export default function UserReviewCard({ userId, reservation, onPress, disabled } = { disabled: true }) {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    console.debug('Effect: refresh reservation.carId');
    setLoading(true);
    getOneUser(userId)
      .then((res) => {
        setUser(res);
        setLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoading(false);
      });
  }, [userId]);

  return (
    <SmallCard
      imageUrl={user.photoURL}
      title={t('rental.reviewDriver', { name: user?.firstName + ' ' + user?.lastName })}
      subTitle={
        <>
          <Icon as={Ionicons} size={'md'} color={'$light300'} name={'star'} />
          <Icon as={Ionicons} size={'md'} color={'$light300'} name={'star'} />
          <Icon as={Ionicons} size={'md'} color={'$light300'} name={'star'} />
          <Icon as={Ionicons} size={'md'} color={'$light300'} name={'star'} />
          <Icon as={Ionicons} size={'md'} color={'$light300'} name={'star'} />
        </>
      }
      loading={loading}
      onPress={onPress}
    />
  );
}
