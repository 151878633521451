import React from 'react';

import { FormControl, FormControlLabel, FormControlLabelText, Heading, Input, InputField, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { carAddingSetter } from '../../../shared/reducers/car.reducer';

function NumberPlate() {
  const car = useSelector((state) => state.cars.carAdding);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const setCar = (car) => dispatch(carAddingSetter(car));

  return (
    <VStack space={'md'}>
      <Text mb={'$4'} variant={'grey'} size={'secondary'}>
        {t('addCar.numberPlateDescription')}
      </Text>
      <VStack space={'xs'}>
        <FormControlLabel>
          <FormControlLabelText>{t('addCar.numberPlate')}</FormControlLabelText>
        </FormControlLabel>
        <Input>
          <InputField
            testID="input.numberPlate"
            value={car?.numberPlate}
            placeholder={t('addCar.numberPlate')}
            onChangeText={(value) => setCar({ ...car, numberPlate: value })}
          />
        </Input>
      </VStack>
    </VStack>
  );
}

export default NumberPlate;
