import React, { useEffect, useState } from 'react';

import { Divider, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import Alert from '../../../shared/components/badge/alert';
import { errorHandler } from '../../../shared/components/errorHandler/errorHander';
import PressableRowCard from '../../../shared/components/pressable/pressableRowCard';
import WebScrollView from '../../../shared/components/web/webScrollView';
import { useMaxWidth } from '../../../shared/provider/maxWidthProvider';
import { oneCarListener } from '../../../shared/services/firebase/car-firebase';
import { firebaseApp } from '../../../shared/services/firebase/firebaseFactory';
import { Metrics } from '../../../shared/themes';
import { carDocumentTypes } from '../../../shared/util/car-utils';
import { cleanFirestoreListResult, cleanFirestoreResult } from '../../../shared/util/firestore-utils';

function OwnerCarsDocumentsScreen({ route }) {
  const { carId } = route?.params ?? {};

  const [loading, setLoading] = useState(true);
  const [car, setCar] = useState({});

  const { t } = useTranslation();
  const navigation = useNavigation();
  const maxWidth = useMaxWidth();

  function onCarSnapshot(snapshot) {
    const cleanData = cleanFirestoreResult(snapshot);
    setLoading(true);
    firebaseApp
      .firestore()
      .collection('cars')
      .doc(cleanData.uid)
      .collection('documents')
      .get()
      .then(cleanFirestoreListResult)
      .then((documents) => {
        setCar({ ...cleanData, documents: documents });
        setLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoading(false);
      });
  }

  function onCarSnapshotError(e) {
    errorHandler(e, true);
    setLoading(false);
  }

  useEffect(() => {
    console.debug('Effect: refresh car detail');
    const subscriber = oneCarListener(carId, onCarSnapshot, onCarSnapshotError);
    return () => subscriber();
  }, [carId]);

  return (
    <WebScrollView hideFooter={true} maxWidth={maxWidth > 700 ? 700 : maxWidth}>
      <VStack space={'lg'} mx={'$screenMargin'}>
        <Text variant={'grey'}>{t('carDocuments.info')}</Text>
        <VStack space={'sm'}>
          <PressableRowCard
            title={t('carDocuments.ownershipTitle')}
            info={t('carDocuments.ownershipInfo')}
            loading={loading}
            onPress={() => navigation.navigate('OwnershipScreen', { carId: carId })}
            bottomElement={
              <Alert
                types={[car?.alert?.[carDocumentTypes.autorizationOfUse], car?.alert?.[carDocumentTypes.registrationCard], car?.alert?.ID]}
              />
            }
          />
          <Divider />
          <PressableRowCard
            title={t('carDocuments.insuranceTitle')}
            info={t('carDocuments.insuranceInfo')}
            loading={loading}
            onPress={() => navigation.navigate('InsuranceScreen', { carId: carId })}
            bottomElement={
              <Alert types={[car?.alert?.[carDocumentTypes.insuranceCertificate], car?.alert?.[carDocumentTypes.insurancePolicy]]} />
            }
          />
          <Divider />
          <PressableRowCard
            title={t('carDocuments.technicalCheckTitle')}
            info={t('carDocuments.technicalCheckInfo')}
            loading={loading}
            onPress={() => navigation.navigate('TechnicalInspectionScreen', { carId: carId })}
            bottomElement={<Alert types={[car?.alert?.[carDocumentTypes.technicalInspection]]} />}
          />
          <Divider />
        </VStack>
      </VStack>
    </WebScrollView>
  );
}

export default OwnerCarsDocumentsScreen;
