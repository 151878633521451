import { useMemo, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, Divider, Heading, HStack, Text, View, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import InsuranceDisplay from './insurance';
import { Metrics } from '../../themes';
import { getPriceFromCurrency } from '../../util/currency';
import { insuranceTypes } from '../../util/insurance-utils';
import { InternalLinks } from '../../util/link-utils';
import ActionSheet from '../actionSheet/actionSheet';
import UnderLineButton from '../pressable/underLineButton';
import Skeleton from '../skeleton/skeleton';

export default function InsuranceDetail({ insurances, documents, car, currency, loading }) {
  const [isOpen, setOpen] = useState(false);
  const ins = useMemo(() => insuranceTypes.filter((ins) => insurances.some((i) => i === ins.type) || ins.type === 'DP'), [insurances]);
  const { t } = useTranslation();
  const navigation = useNavigation();

  function understandYourCoverage() {
    setOpen(false);
    navigation.navigate('CustomWebView', { url: InternalLinks.understandingCoverage, title: t('car.understandingCoverage') });
  }

  if (loading) {
    return (
      <VStack space={'md'}>
        <View direction="row" wrap={'wrap'} space={'sm'}>
          <Box variant={'cardContainer'} rounded={'$full'} px={'$2'} py={'$1'} mr={'$2'} mb={'$2'} backgroundColor={'$white'}>
            <HStack alignItems={'center'} space={'sm'}>
              <Skeleton rounded={'$full'} w={'$6'} h={'$6'} />
              <Skeleton rounded={'$full'} w={'$8'} h={'$4'} />
            </HStack>
          </Box>
        </View>
      </VStack>
    );
  }

  return (
    <>
      <VStack space={'md'}>
        <Heading>{t('car.coverage')}</Heading>
        <TouchableOpacity onPress={() => setOpen(true)} w={'100%'}>
          <HStack flexWrap={'wrap'} space={'sm'}>
            {ins.map((insuranceType) => (
              <Box
                key={insuranceType.type}
                variant={'cardContainer'}
                rounded={'$full'}
                px={'$2'}
                py={'$1'}
                mr={'$2'}
                mb={'$2'}
                backgroundColor={'$white'}>
                <Text size={'secondary'}>{`${insuranceType.emoji} (${insuranceType.type})`}</Text>
              </Box>
            ))}
          </HStack>
        </TouchableOpacity>
        <Divider mt={'$2'} />
      </VStack>
      <ActionSheet isOpen={isOpen} setOpen={setOpen} headerText={t('car.coverage')}>
        <VStack space={'lg'} mx={'$screenMargin'}>
          <VStack space={'md'}>
            {insuranceTypes.map((insuranceType) => (
              <HStack justifyContent={'space-between'} key={insuranceType.type} space={'md'}>
                {insuranceType.type === 'DP' ? (
                  <VStack>
                    <HStack justifyContent={'space-between'} alignItems={'center'}>
                      <Text w={'70%'}>{`${insuranceType.emoji} ${t('insuranceType.' + insuranceType.type)} (${insuranceType.type})`}</Text>
                      <Text fontWeight={'$bold'}>{getPriceFromCurrency(car?.insuranceDeductibleAmount, car?.currency, currency)}</Text>
                    </HStack>
                    <Text variant={'grey'} size={'secondary'}>
                      {t('car.deductibleAmountInfo')}
                    </Text>
                  </VStack>
                ) : (
                  <Text strikeThrough={!insurances.some((i) => i === insuranceType.type)}>
                    {`${insuranceType.emoji} ${t('insuranceType.' + insuranceType.type)} (${insuranceType.type})`}
                  </Text>
                )}
              </HStack>
            ))}
          </VStack>
          <UnderLineButton onPress={understandYourCoverage} text={t('car.understandingCoverage')} hideLeftIcon={true} />
          {documents && <InsuranceDisplay insuranceDocuments={documents} />}
        </VStack>
      </ActionSheet>
    </>
  );
}
