import { t } from 'i18next';

import { firebaseApp } from '../../services/firebase/firebaseFactory';
import { IS_WEB } from '../../util/device-utils';
import errorToast from '../toast/errorToast';

const errorFirebaseList = [
  'auth/admin-restricted-operation',
  'auth/argument-error',
  'auth/app-not-authorized',
  'auth/app-not-installed',
  'auth/captcha-check-failed',
  'auth/code-expired',
  'auth/cordova-not-ready',
  'auth/cors-unsupported',
  'auth/credential-already-in-use',
  'auth/custom-token-mismatch',
  'auth/requires-recent-login',
  'auth/dependent-sdk-initialized-before-auth',
  'auth/dynamic-link-not-activated',
  'auth/email-change-needs-verification',
  'auth/email-already-in-use',
  'auth/emulator-config-failed',
  'auth/expired-action-code',
  'auth/cancelled-popup-request',
  'auth/internal-error',
  'auth/invalid-api-key',
  'auth/invalid-app-credential',
  'auth/invalid-app-id',
  'auth/invalid-user-token',
  'auth/invalid-auth-event',
  'auth/invalid-cert-hash',
  'auth/invalid-verification-code',
  'auth/invalid-continue-uri',
  'auth/invalid-cordova-configuration',
  'auth/invalid-custom-token',
  'auth/invalid-dynamic-link-domain',
  'auth/invalid-email',
  'auth/invalid-emulator-scheme',
  'auth/invalid-credential',
  'auth/invalid-message-payload',
  'auth/invalid-multi-factor-session',
  'auth/invalid-oauth-client-id',
  'auth/invalid-oauth-provider',
  'auth/invalid-action-code',
  'auth/unauthorized-domain',
  'auth/wrong-password',
  'auth/invalid-persistence-type',
  'auth/invalid-phone-number',
  'auth/invalid-provider-id',
  'auth/invalid-recipient-email',
  'auth/invalid-sender',
  'auth/invalid-verification-id',
  'auth/invalid-tenant-id',
  'auth/multi-factor-info-not-found',
  'auth/multi-factor-auth-required',
  'auth/missing-android-pkg-name',
  'auth/missing-app-credential',
  'auth/auth-domain-config-required',
  'auth/missing-verification-code',
  'auth/missing-continue-uri',
  'auth/missing-iframe-start',
  'auth/missing-ios-bundle-id',
  'auth/missing-or-invalid-nonce',
  'auth/missing-multi-factor-info',
  'auth/missing-multi-factor-session',
  'auth/missing-phone-number',
  'auth/missing-verification-id',
  'auth/app-deleted',
  'auth/account-exists-with-different-credential',
  'auth/network-request-failed',
  'auth/null-user',
  'auth/no-auth-event',
  'auth/no-such-provider',
  'auth/operation-not-allowed',
  'auth/operation-not-supported-in-this-environment',
  'auth/popup-blocked',
  'auth/popup-closed-by-user',
  'auth/provider-already-linked',
  'auth/quota-exceeded',
  'auth/redirect-cancelled-by-user',
  'auth/redirect-operation-pending',
  'auth/rejected-credential',
  'auth/second-factor-already-in-use',
  'auth/maximum-second-factor-count-exceeded',
  'auth/tenant-id-mismatch',
  'auth/timeout',
  'auth/user-token-expired',
  'auth/too-many-requests',
  'auth/unauthorized-continue-uri',
  'auth/unsupported-first-factor',
  'auth/unsupported-persistence-type',
  'auth/unsupported-tenant-operation',
  'auth/unverified-email',
  'auth/user-cancelled',
  'auth/user-not-found',
  'auth/user-disabled',
  'auth/user-mismatch',
  'auth/user-signed-out',
  'auth/weak-password',
  'auth/web-storage-unsupported',
  'auth/already-initialized',
  'auth/recaptcha-not-enabled',
  'auth/missing-recaptcha-token',
  'auth/invalid-recaptcha-token',
  'auth/invalid-recaptcha-action',
  'auth/missing-client-type',
  'auth/missing-recaptcha-version',
  'auth/invalid-recaptcha-version',
  'auth/invalid-req-type',
  'firestore/unavailable',
  'firestore/aborted',
  'firestore/already-exists',
  'firestore/cancelled',
  'firestore/data-lost',
  'firestore/deadline-exeeded',
  'firestore/failed-precondition',
  'firestore/internal',
  'firestore/invalid-argument',
  'firestore/not-found',
  'firestore/ok',
  'firestore/out-of-range',
  'firestore/permission-denied',
  'firestore/resource-exhausted',
  'firestore/unauthenticated',
  'firestore/unimplemented',
  'firestore/unknown',
  // TODO find the key when realtimedatabase has error
  // "Disconnected":"The operation had to be aborted due to a network disconnect.",
  // "ExpiredToken" :"The supplied auth token has expired.",
  // "InvalidToken" :"The specified authentication token is invalid.",
  // "MaxRetries" :"The transaction had too many retries.",
  // "NetworkError" :"The operation could not be performed due to a network error.",
  // "OperationFailed" :" The server indicated that this operation failed.",
  // "OverriddenBySet" :" The transaction was overridden by a subsequent set.",
  // "PermissionDenied" :" This client does not have permission to perform this operation.",
  // "Unavailable" :"The service is unavailable.",
  // "UnknownError" :"An unknown error occurred.",
  // "UserCodeException": "An exception occurred in user code.",
  // "WriteCanceled": "he write was canceled locally.",
];

export function translateError(error) {
  if (errorFirebaseList.includes(error?.code)) {
    errorToast(t('error.firebase.' + error.code));
  } else {
    errorToast(error.message);
  }
}

export function errorHandler(error, toDisplay) {
  if (!error) {
    console.warn('error without error!', error);
    return;
  }

  console.error('error: ', error?.message, '\nstack: ', error?.stack);
  !IS_WEB && firebaseApp.crashlytics().recordError(error);
  if (toDisplay) {
    translateError(error);
  }
}
