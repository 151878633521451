import React, { useEffect, useState } from 'react';

import { Ionicons } from '@expo/vector-icons';
import {
  Box,
  Button,
  ButtonText,
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  Heading,
  HStack,
  Icon,
  Input,
  InputField,
  Text,
  Textarea,
  TextareaInput,
  VStack,
} from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { getLocales } from 'expo-localization';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import UserDocuments from './driverDocuments/userDocumentsDetail';
import { goBackOrDefault } from '../../navigation/nav-ref';
import BottomAction from '../../shared/components/bottomAction/bottomAction';
import RegionPicker from '../../shared/components/countryPicker/countryPicker';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import KeyboardAvoiding from '../../shared/components/keyboardAvoiding/keyboardAvoiding';
import Layout from '../../shared/components/layout/layout';
import MediaUploader from '../../shared/components/media/mediaUploader';
import errorToast from '../../shared/components/toast/errorToast';
import DatePicker from '../../shared/generic/DatePicker';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { userUpdateRequest } from '../../shared/reducers/user.reducer';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { Metrics } from '../../shared/themes';
import { userToAccount } from '../../shared/util/account-utils';
import { analyticsRegistration } from '../../shared/util/analytics';
import { getExtraInputProps } from '../../shared/util/input-utils';

function EditProfileScreen({ route }) {
  const currentUser = useSelector((state) => state.users.currentUser);
  const tmpUserData = useSelector((state) => state.users.tmpUserData);
  const dispatch = useDispatch();
  const maxWidth = useMaxWidth();

  const [bottomActionHeight, setBottomActionHeight] = useState(0);

  const register = !currentUser?.conditionAccepted;

  const { screen, params } = route?.params ?? {};

  function getDefaultName() {
    if (!currentUser?.firstName || !currentUser?.lastName) {
      return tmpUserData;
    }
    return {};
  }

  const [formData, setData] = useState({ ...currentUser, ...getDefaultName() });

  const { t } = useTranslation();
  const navigation = useNavigation();

  const [date, setDate] = useState(formData?.birthday ? new Date(formData?.birthday * 1000) : new Date());

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required().label('firstName'),
    lastName: Yup.string().required().label('lastName'),
  });

  useEffect(() => {
    if (register) {
      navigation.setOptions({
        headerLeft: () => null,
      });
    }
  }, [navigation, register]);

  function getAge(birthDate) {
    var today = new Date();
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  function getDate(dateToCheck) {
    return dateToCheck instanceof Date && !isNaN(dateToCheck) ? dateToCheck : new Date();
  }

  const onSubmit = async () => {
    if (getAge(date) < 18) {
      errorToast(t('error.tooYoung'));
      return;
    }
    validationSchema
      .validate(formData, { abortEarly: true })
      .then(() => {
        const updateData = register
          ? {
              ...formData,
              conditionAccepted: true,
              birthday: date.getTime() / 1000,
              currency: getLocales()[0]?.currencyCode,
              language: getLocales()[0]?.languageCode,
            }
          : { ...formData, birthday: date.getTime() / 1000 };

        firebaseApp.auth().currentUser.updateProfile(userToAccount(updateData));
        dispatch(userUpdateRequest(updateData));

        if (register) {
          analyticsRegistration(firebaseApp.auth().currentUser?.providerId);
        }

        if (screen) {
          navigation.navigate(screen, params);
        } else {
          goBackOrDefault('Profile');
        }
      })
      .catch((err) => {
        errorHandler(err, false);
        errorToast(err);
      });
  };

  function updateAvatar(images) {
    if (images && images?.length > 0 && images[0]?.url) {
      setData({ ...formData, photoURL: images[0].url });
    }
  }

  return (
    <Layout>
      <KeyboardAvoiding maxWidth={maxWidth > 700 ? 700 : maxWidth}>
        <VStack space={'lg'} my={'$4'} mx={'$screenMargin'} pb={bottomActionHeight}>
          {formData?.conditionAccepted && (
            <>
              <FormControl>
                <FormControlLabel>
                  <FormControlLabelText>{t('login.email')}</FormControlLabelText>
                </FormControlLabel>
                <Input isReadOnly={true} isDisabled={true}>
                  <InputField defaultValue={formData?.email} onChangeText={(value) => setData({ ...formData, email: value })} />
                </Input>
              </FormControl>
            </>
          )}
          <>
            <FormControl>
              <FormControlLabel>
                <FormControlLabelText>{t('login.firstName')}</FormControlLabelText>
              </FormControlLabel>
              <Input>
                <InputField defaultValue={formData?.firstName} onChangeText={(value) => setData({ ...formData, firstName: value })} />
              </Input>
            </FormControl>
            <FormControl>
              <FormControlLabel>
                <FormControlLabelText>{t('login.lastName')}</FormControlLabelText>
              </FormControlLabel>
              <Input>
                <InputField defaultValue={formData?.lastName} onChangeText={(value) => setData({ ...formData, lastName: value })} />
              </Input>
              <Text variant={'grey'} size={'secondary'}>
                {t('login.checkNameMatchId')}
              </Text>
            </FormControl>
          </>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('login.birthdate')}</FormControlLabelText>
            </FormControlLabel>
            <DatePicker
              title={t('login.birthdate')}
              maximumDate={new Date(new Date().getFullYear(), 11, 31)}
              date={getDate(date)}
              mode={'date'}
              onConfirm={(d) => {
                setDate(d);
              }}
            />
            <Text variant={'grey'} size={'secondary'}>
              {t('login.moreThan18')}
            </Text>
          </FormControl>

          <RegionPicker
            title={t('login.country')}
            defaultCountryCode={formData?.countryCode}
            placeHolder={t('login.countryPlaceHolder')}
            setCountryCode={(value) => setData({ ...formData, countryCode: value })}
          />
          {formData?.conditionAccepted && (
            <>
              <UserDocuments userId={formData.uid} isEditable={true} />
            </>
          )}
        </VStack>
      </KeyboardAvoiding>
      <BottomAction
        onLayout={(event) => {
          setBottomActionHeight(event?.nativeEvent?.layout?.height ?? 0);
        }}>
        <Box px={'$screenMargin'} py={'$4'} width={maxWidth > 700 ? 700 : maxWidth} alignSelf="center">
          <Button onPress={onSubmit}>
            <ButtonText>{formData?.conditionAccepted ? t('login.updateAndContinue') : t('login.agreeAndContinue')}</ButtonText>
          </Button>
        </Box>
      </BottomAction>
    </Layout>
  );
}

export default EditProfileScreen;
