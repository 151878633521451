import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@gluestack-ui/themed';
import { useToken } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import ReservationCard from '../../shared/components/entities/reservation/reservationCard';
import FirestoreList from '../../shared/components/firestore/firestoreList';
import Select from '../../shared/components/select/select';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { Metrics } from '../../shared/themes';
import { reservationStatus } from '../../shared/util/car-utils';

function BackofficeReservations() {
  const [filterStatus, setFilterStatus] = useState(reservationStatus.waitingCheckOut);

  const navigation = useNavigation();

  const { t } = useTranslation();
  const maxWidth = useMaxWidth();
  const space4 = useToken('space', '4');
  const screenMargin = useToken('space', 'screenMargin');

  const filters = useMemo(
    () => [
      { field: 'isDemo', operator: '==', value: false },
      { field: 'status', operator: '==', value: filterStatus },
    ],
    [filterStatus],
  );

  const renderItem = useCallback(
    ({ item }) => (
      <ReservationCard reservation={item} onPress={() => navigation.navigate('RequestDetailScreen', { reservationId: item.uid })} />
    ),
    [navigation],
  );

  return (
    <FirestoreList
      headerComponent={
        <Box alignSelf={'center'} width={maxWidth} px={screenMargin}>
          <Select
            title={t('screen.reservationDetail')}
            data={Object.entries(reservationStatus).map(([key, value]) => ({ value: value, label: t('reservationStatus.' + value) }))}
            onSelect={(item) => setFilterStatus(item.value)}
            value={filterStatus}
          />
        </Box>
      }
      keyExtractor={(item) => item?.uid}
      ItemSeparatorComponent={<Box my={'$2'} />}
      isConnected={true}
      collectionName={'reservations'}
      filters={filters}
      renderItem={renderItem}
      contentContainerStyle={{ alignSelf: 'center', width: maxWidth, paddingVertical: space4, paddingHorizontal: screenMargin }}
    />
  );
}

export default BackofficeReservations;
