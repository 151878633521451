import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Heading, HStack, Icon, View, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import HeaderWeb from './headerWeb';
import HeaderWebOwner from './headerWebOwner';
import appConfig from '../../../config/app-config';
import { goBackOrDefault } from '../../../navigation/nav-ref';
import { useIsDrawer } from '../../provider/isDrawerProvider';
import { useMaxWidth } from '../../provider/maxWidthProvider';
import { Metrics } from '../../themes';

export default function HeaderBarOwner(props) {
  const { displayBar, displayNav, canGoBack, rightElement } = props;

  const maxWidth = useMaxWidth();
  const isDrawer = useIsDrawer();
  const { top } = useSafeAreaInsets();

  const { t } = useTranslation();

  return (
    <VStack style={{ marginTop: top }}>
      <View>
        {appConfig.env !== 'PROD' && <Box bgColor={'$red200'} h={5} />}
        {(displayBar || isDrawer) && <HeaderWebOwner />}
        {displayNav && (
          <HStack w={maxWidth} alignSelf={'center'} px={'$screenMargin'} alignItems={'center'} my={'$2'} justifyContent={'space-between'}>
            <HStack space={'md'}>
              {canGoBack && (
                <TouchableOpacity onPress={() => goBackOrDefault('Search')}>
                  <Icon as={Ionicons} name="chevron-back-outline" size={'xl'} />
                </TouchableOpacity>
              )}
              <Heading size={'h1'} alignSelf={'center'} numberOfLines={5} maxWidth={maxWidth * 0.7}>
                {t(props.options.title)}
              </Heading>
            </HStack>
            {rightElement ?? null}
          </HStack>
        )}
      </View>
      {props.children}
    </VStack>
  );
}
