import React, { useCallback, useState } from 'react';

import { Box, useToken } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';

import CarTinyCard from '../../shared/components/entities/car/carTinyCard';
import FilterComponent from '../../shared/components/firestore/filterComponent';
import FirestoreList from '../../shared/components/firestore/firestoreList';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';

const baseFilter = [
  { field: 'carValidated', operator: '==', value: true },
  { field: 'carApproved', operator: '==', value: false },
];

function BackofficeCarToValidate() {
  const navigation = useNavigation();
  const [filters, setFilters] = useState(baseFilter);
  const maxWidth = useMaxWidth();
  const space4 = useToken('space', '4');
  const screenMargin = useToken('space', 'screenMargin');

  const renderItem = useCallback(
    ({ item }) => <CarTinyCard car={item} onPress={() => navigation.navigate('BackOfficeCar', { carId: item?.uid })} />,
    [navigation],
  );

  return (
    <FirestoreList
      headerComponent={
        <FilterComponent
          containerStyles={{ alignSelf: 'center', width: maxWidth, px: screenMargin }}
          allowedFields={['uid', 'ownerId', 'userId', 'brand', 'model', 'numberPlate']}
          baseFilters={baseFilter}
          filters={filters}
          setFilters={setFilters}
        />
      }
      keyExtractor={(item) => item?.uid}
      ItemSeparatorComponent={<Box my={'$2'} />}
      isConnected={true}
      collectionName={'cars'}
      filters={filters}
      renderItem={renderItem}
      contentContainerStyle={{ alignSelf: 'center', width: maxWidth, paddingVertical: space4, paddingHorizontal: screenMargin }}
    />
  );
}

export default BackofficeCarToValidate;
