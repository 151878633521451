import React, { useEffect, useMemo, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Divider, Heading, HStack, Icon, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import BackofficeCarDocument from './components/backofficeCarDocument';
import { goBackOrDefault } from '../../navigation/nav-ref';
import CarTinyCard from '../../shared/components/entities/car/carTinyCard';
import MessageButton from '../../shared/components/entities/message/messageButton';
import ReservationCard from '../../shared/components/entities/reservation/reservationCard';
import ProfileCardById from '../../shared/components/entities/user/ProfileCardById';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import CarDetailCover from '../../shared/components/info/carDetailCover';
import successToast from '../../shared/components/toast/successToast';
import Toggle from '../../shared/components/toggle/toggle';
import WebScrollView from '../../shared/components/web/webScrollView';
import WebSpliter from '../../shared/components/web/webSpliter';
import { oneCarListener } from '../../shared/services/firebase/car-firebase';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { Metrics } from '../../shared/themes';
import { screenBreakPoint } from '../../shared/util/device-utils';
import { cleanFirestoreListResult, cleanFirestoreResult } from '../../shared/util/firestore-utils';

function BackOfficeCar({ route, hideBooking }) {
  const { t, i18n } = useTranslation();
  const { width } = useWindowDimensions();
  const navigation = useNavigation();

  const isMobile = useMemo(() => screenBreakPoint(width, [true, true, false, false, false]), [width]);

  const { carId } = route?.params ?? { hideBookingAction: hideBooking };

  const [loading, setLoading] = useState(true);
  const [car, setCar] = useState({});
  const [reservations, setReservations] = useState([]);

  function onCarSnapshot(snapshot) {
    const cleanData = cleanFirestoreResult(snapshot);
    setLoading(true);
    firebaseApp
      .firestore()
      .collection('cars')
      .doc(cleanData.uid)
      .collection('documents')
      .get()
      .then(cleanFirestoreListResult)
      .then((documents) => {
        setCar({ ...cleanData, documents: documents });
        setLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoading(false);
      });
  }

  function onCarSnapshotError(e) {
    errorHandler(e, true);
    setLoading(false);
  }

  function getReservations() {
    if (carId) {
      setLoading(true);
      firebaseApp
        .firestore()
        .collection('reservations')
        .where('carId', '==', carId)
        .get()
        .then((res) => {
          setReservations(cleanFirestoreListResult(res));
        })
        .catch((e) => {
          errorHandler(e, true);
        });
    }
  }

  useEffect(() => {
    console.debug('Effect: refresh car detail');
    const subscriber = oneCarListener(carId, onCarSnapshot, onCarSnapshotError);
    getReservations();
    return () => subscriber();
  }, [carId]);

  useEffect(() => {
    console.debug('Effect: refresh car, navigation');
    if (car?.brand) {
      navigation.setOptions({ title: car?.brand + ' ' + car?.model });
    }
  }, [car, navigation]);

  const comparedIsThumbnail = (a, b) => {
    return a.isThumbnail ? -1 : 1;
  };

  function updateCarApprove() {
    firebaseApp
      .firestore()
      .collection('cars')
      .doc(car?.uid)
      .update({ carApproved: !car?.carApproved })
      .then(() => successToast(t('backoffice.carApproveSuccess')))
      .catch((e) => errorHandler(e, true));
  }

  return (
    <WebScrollView
      title={t('metaTag.title', { title: car?.brand + ' ' + car?.model })}
      description={
        car?.translatedContent?.carDescription && i18n.language in car?.translatedContent?.carDescription
          ? car?.translatedContent?.carDescription[i18n.language]
          : car?.originalContent?.carDescription
      }
      image={car?.images && car?.images.sort(comparedIsThumbnail)[0].url}>
      <VStack mx={isMobile ? 0 : Metrics.screenWidthMarging} mb={'$4'}>
        <CarDetailCover isMobile={isMobile} images={car?.images} carId={car?.uid} onClosePress={() => goBackOrDefault('BackOfficeHome')} />
      </VStack>
      <VStack mb={'$4'} mx={'$screenMargin'} space={'lg'}>
        <WebSpliter
          left={
            <VStack space={'lg'}>
              <CarTinyCard
                key={JSON.stringify(car)}
                carId={carId}
                onPress={() => navigation.navigate('OwnerCarsDetailScreen', { carId: carId })}
              />
              <Text selectable={true}>ID: {carId}</Text>
              <Text selectable={true}>Plate: {car?.numberPlate}</Text>
              <Divider />
              <BackofficeCarDocument carId={carId} alerts={car?.alert ?? {}} />
            </VStack>
          }
          right={
            <VStack mx={'$4'} space={'md'}>
              <ProfileCardById
                userId={car?.ownerId}
                onPress={() => navigation.navigate('BackofficeOwnerDetail', { userId: car.ownerId })}
              />
              <MessageButton text={t('ownerRequest.message')} carId={car.uid} userToContact={car.ownerId} />
              <VStack space={'sm'}>
                <Divider />
                <HStack space={'md'} justifyContent={'space-between'}>
                  <Text>{t('backoffice.isCarValidate')}</Text>
                  <Icon
                    as={Ionicons}
                    size={'lg'}
                    name={car?.carValidated ? 'chevron-down-circle-outline' : 'close-circle-outline'}
                    color={car?.carValidated ? '$green500' : '$red500'}
                  />
                </HStack>
                <Divider />
                <HStack space={'md'} justifyContent={'space-between'}>
                  <Text>{t('backoffice.isCarApproved')}</Text>
                  <Toggle isChecked={car?.carApproved ?? false} onPress={updateCarApprove} />
                  <Icon
                    as={Ionicons}
                    size={'lg'}
                    name={car?.carApproved ? 'chevron-down-circle-outline' : 'close-circle-outline'}
                    color={car?.carApproved ? '$green500' : '$red500'}
                  />
                </HStack>
                <Divider />
                <HStack space={'md'} justifyContent={'space-between'}>
                  <Text>{t('backoffice.isCarVisible')}</Text>
                  <Icon
                    as={Ionicons}
                    size={'lg'}
                    name={car?.visible ? 'chevron-down-circle-outline' : 'close-circle-outline'}
                    color={car?.visible ? '$green500' : '$red500'}
                  />
                </HStack>
              </VStack>
              <Divider />
              <Heading>Email reminder</Heading>
              <VStack space={'sm'} bgColor={'$light200'} rounded={'$md'} p={'$2'}>
                <HStack justifycontent={'space-between'}>
                  <Text>TechnicalCheck Limit date: </Text>
                  <Text>{car?.technicalControlDate ? new Date(car?.technicalControlDate * 1000).toDateString() : 'NONE'}</Text>
                </HStack>
                <HStack justifycontent={'space-between'}>
                  <Text>TechnicalCheck Last Email: </Text>
                  <Text>{car?.lastEmailTechnicalControl ? new Date(car?.lastEmailTechnicalControl).toDateString() : 'NONE'}</Text>
                </HStack>
              </VStack>
              <VStack space={'sm'} bgColor={'$light200'} rounded={'$md'} p={'$2'}>
                <HStack justifycontent={'space-between'}>
                  <Text>Insurance Limit date: </Text>
                  <Text>{car?.insuranceDate ? new Date(car?.insuranceDate * 1000).toDateString() : 'NONE'}</Text>
                </HStack>
                <HStack justifycontent={'space-between'}>
                  <Text>Insurance Last Email: </Text>
                  <Text>{car?.lastEmailInsurance ? new Date(car?.lastEmailInsurance * 1000).toDateString() : 'NONE'}</Text>
                </HStack>
              </VStack>
            </VStack>
          }
          space={'md'}
        />
        <Divider />
        <Heading>{t('backoffice.reservationTitle')}</Heading>
        {reservations.map((reservation) => (
          <ReservationCard
            key={reservation?.uid}
            reservation={reservation}
            onPress={() => navigation.navigate('RequestDetailScreen', { reservationId: reservation.uid })}
          />
        ))}
      </VStack>
    </WebScrollView>
  );
}

export default BackOfficeCar;
