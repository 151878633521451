import React from 'react';

import { Box, Heading, Text, VStack } from '@gluestack-ui/themed';

import Image from '../image/image';

export default function PlaceHolder({ image, title, text, element }) {
  return (
    <VStack
      space={'md'}
      justifyContent={'center'}
      alignItems={'center'}
      borderColor={'$light200'}
      borderWidth={'$1'}
      rounded={'$xl'}
      p={'$4'}>
      <Image source={image} h={200} w={'100%'} alt={'placeholder'} resizeMode={'contain'} removeTransition={true} />
      <Heading size={'h4'} alignSelf={'center'} textAlign={'center'}>
        {title}
      </Heading>
      {text && (
        <Text alignSelf={'center'} textAlign={'center'}>
          {text}
        </Text>
      )}
      <Box w={'100%'}>{element}</Box>
    </VStack>
  );
}
