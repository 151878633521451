import React from 'react';
import { TouchableOpacity } from 'react-native';

import { Divider, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { reservationStatusToColorSheme } from '../../util/car-utils';
import { formatCurrency } from '../../util/currency';
import BadgeText from '../badge/badgeText';

export default function AdditionalInvoiceDetail({ additionalPayments, isOwner, toClose }) {
  const { t } = useTranslation();
  const navigation = useNavigation();

  return (
    <VStack space={'md'}>
      {additionalPayments &&
        additionalPayments.map((additionalPayment) => (
          <VStack space={'md'} key={additionalPayment.uid} justifyContent={'space-between'}>
            <TouchableOpacity
              onPress={() => {
                toClose && toClose();
                navigation.navigate('AdditionalPaymentDetail', {
                  reservationId: additionalPayment.reservationId,
                  additionalPaymentId: additionalPayment.uid,
                  isOwner: isOwner,
                });
              }}>
              {additionalPayment?.invoice &&
                additionalPayment.invoice.listProduct.map((product) => (
                  <HStack justifyContent={'space-between'} my={'$2'} key={product.type}>
                    <Text w={'30%'} textAlign={'left'}>
                      {t('invoice.' + product.type, {
                        ...product,
                        reason: additionalPayment?.originalContent?.reason,
                        basePriceDisplay: formatCurrency(
                          Math.round(product.basePrice) / additionalPayment.invoice?.smallerCurrencyUnit,
                          product?.currency,
                        ),
                      })}
                    </Text>
                    <Text w={'30%'} textAlign={'right'} fontWeight={'$bold'}>
                      {formatCurrency(
                        Math.round(product.basePrice) / additionalPayment.invoice?.smallerCurrencyUnit,
                        additionalPayment.invoice?.currency,
                      )}
                    </Text>
                  </HStack>
                ))}
              <BadgeText colorScheme={reservationStatusToColorSheme(additionalPayment?.status)}>
                {t('reservationStatus.' + additionalPayment?.status)?.toUpperCase()}
              </BadgeText>
            </TouchableOpacity>
            <Divider />
          </VStack>
        ))}
    </VStack>
  );
}
