import React from 'react';

import { Button, ButtonText, Heading } from '@gluestack-ui/themed';
import { Text } from '@gluestack-ui/themed';
import { HStack, VStack } from '@gluestack-ui/themed';

import Image from '../../../shared/components/image/image';

export default function HeroPagePro({ maxWidth, title, subTitle, button1Text, button1OnPress, button2Text, button2OnPress, imageUrl }) {
  return (
    <>
      <Image source={{ uri: imageUrl }} $base-h={200} $md-h={0} $base-w={'$full'} $md-w={0} resizeMode={'contain'} />
      <HStack w={'$full'} justifyContent="space-between">
        <VStack $base-w={'$full'} $md-w={'50%'} space="4xl" $md-minWidth={300}>
          <VStack space="md">
            <Heading fontSize={40} lineHeight={56}>
              {title}
            </Heading>
            <Text>{subTitle}</Text>
          </VStack>
          <HStack space="md" flexWrap="wrap" $base-justifyContent="center" $md-justifyContent="flex-start">
            <Button variant="outline" onPress={button1OnPress} $base-w={'$full'} $md-w={'$40'}>
              <ButtonText>{button1Text}</ButtonText>
            </Button>
            <Button onPress={button2OnPress} $base-w={'$full'} $md-w={'$40'}>
              <ButtonText>{button2Text}</ButtonText>
            </Button>
          </HStack>
        </VStack>
        <Image
          source={{ uri: imageUrl }}
          $base-h={0}
          $md-h={'$full'}
          $base-w={'$full'}
          $md-w={'40%'}
          resizeMode={'contain'}
          $md-minWidth={300}
        />
      </HStack>
    </>
  );
}
