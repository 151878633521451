import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import {
  Button,
  ButtonSpinner,
  ButtonText,
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  Heading,
  Input,
  InputField,
  Text,
  VStack,
} from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import AppleAuth from './appleAuth';
import FacebookAuth from './facebookAuth';
import GoogleAuth from './googleAuth';
import { ButtonPhoneAuth } from './phoneAuth';
import CardContainer from '../../shared/components/container/cardContainer';
import DividerWithText from '../../shared/components/divider/dividerWithText';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import errorToast from '../../shared/components/toast/errorToast';
import WebScrollView from '../../shared/components/web/webScrollView';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { Metrics } from '../../shared/themes';
import { IS_WEB } from '../../shared/util/device-utils';

function LoginOrSignUpScreen({ navigation }) {
  const currentUser = useSelector((state) => state.users.currentUser);
  const lastRedirectableRoute = useSelector((state) => state.users.lastRedirectableRoute);

  const [formData, setData] = useState({});
  const [fetching, setFetching] = useState(false);
  const { t } = useTranslation();

  function authSucceed() {
    if (currentUser?.uid && currentUser?.conditionAccepted) {
      if (lastRedirectableRoute) {
        navigation.navigate(lastRedirectableRoute?.name, lastRedirectableRoute?.params);
      } else {
        navigation.popToTop();
      }
    }
  }

  useEffect(() => {
    console.debug('Effect: refresh currentUser');
    if (currentUser) {
      authSucceed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, navigation]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email().label('Email'),
  });

  function checkEmail() {
    try {
      setFetching(true);
      firebaseApp
        .auth()
        .fetchSignInMethodsForEmail(formData.email)
        .then((response) => {
          setFetching(false);
          navigation.navigate('LoginWithEmail', { email: formData.email, isNew: !response?.length });
        })
        .catch((e) => {
          setFetching(false);
          errorHandler(e, true);
        });
    } catch (e) {
      setFetching(false);
      errorHandler(e, false);
    }
  }

  const onSubmit = async () => {
    validationSchema
      .validate(formData, { abortEarly: true })
      .then((valid) => {
        checkEmail();
      })
      .catch((err) => {
        errorToast(err?.errors[0]);
      });
  };

  return (
    <WebScrollView>
      <CardContainer>
        <VStack my={'$4'} mx={'$screenMargin'} space={'md'} alignItems={'center'} minWidth={'50%'}>
          <FormControl w={'$full'}>
            <FormControlLabel>
              <FormControlLabelText>{t('login.email')}</FormControlLabelText>
            </FormControlLabel>
            <Input w={'$full'}>
              <InputField
                testID="input.email"
                inputMode="email"
                placeholder={t('login.email')}
                onChangeText={(value) => setData({ ...formData, email: value })}
              />
            </Input>
          </FormControl>
          <Button testID="button.loginContinue" w={'100%'} isDisabled={fetching} onPress={onSubmit}>
            {fetching && <ButtonSpinner mr="$1" />}
            <ButtonText>{t('global.continue')}</ButtonText>
          </Button>
          <DividerWithText text={t('global.or')} />
          <GoogleAuth onSuccess={authSucceed} />
          {/* <ButtonPhoneAuth onSuccess={authSucceed} /> */}
          {/* <FacebookAuth onSuccess={authSucceed} /> */}
          {Platform.OS === 'ios' && <AppleAuth />}
        </VStack>
      </CardContainer>
    </WebScrollView>
  );
}

export default LoginOrSignUpScreen;
