import React, { useEffect, useState } from 'react';

import { getOneCar } from '../../../services/firebase/car-firebase';
import { alertTypes } from '../../../util/alert-utils';
import { getThumbnailUrl } from '../../../util/car-utils';
import Alert from '../../badge/alert';
import { errorHandler } from '../../errorHandler/errorHander';
import SmallCard from '../../pressable/smallCard';

export default function CarTinyCard({ car, carId, onPress }) {
  const [carData, setCarData] = useState(car);

  useEffect(() => {
    console.debug('Effect: car.uid');
    if (!carData?.brand && (carId || car?.uid)) {
      getOneCar(carId ?? car?.uid)
        .then((res) => {
          setCarData(res);
        })
        .catch((e) => {
          errorHandler(e, true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carId]);

  return (
    <SmallCard
      loading={!carData?.brand}
      imageUrl={getThumbnailUrl(carData?.images)}
      title={carData?.brand + ' ' + carData?.model}
      subTitle={carData?.transmission + ' - ' + carData?.year + ' - ' + carData?.numberPlate}
      info={
        carData?.alert ? (
          <Alert
            types={[
              ...Object.keys(carData?.alert)
                .map((key) => carData?.alert[key])
                .filter((k) => k !== alertTypes.resolved),
              carData?.accountValidated && carData?.carValidated && !carData?.carApproved ? alertTypes.waitingValidation : alertTypes.none,
            ]}
          />
        ) : null
      }
      onPress={onPress}
    />
  );
}
