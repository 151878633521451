import React, { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Heading, HStack, Icon, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { firebaseApp } from '../../services/firebase/firebaseFactory';
import { quoteToReservation } from '../../util/booking-utils';
import { getThumbnailUrl } from '../../util/car-utils';
import { formatCurrency } from '../../util/currency';
import { displayRangeDate } from '../../util/date-transforms';
import { cleanFirestoreResult } from '../../util/firestore-utils';
import { resizedImage } from '../../util/image-utils';
import Image from '../image/image';
import Skeleton from '../skeleton/skeleton';

const QuoteCard = ({ quote, onPress }) => {
  const [car, setCar] = useState(null);
  const [reservation, setReservation] = useState(null);
  const { I18n } = useTranslation();

  const currency = useSelector((state) => state.users.currency);
  const currentUser = useSelector((state) => state.users.currentUser);

  useEffect(() => {
    console.debug('UseEffect get car and user');
    if (!car || (quote?.carId && quote?.carId !== car?.uid)) {
      firebaseApp
        .firestore()
        .collection('cars')
        .doc(quote?.carId)
        .get()
        .then((res) => setCar(cleanFirestoreResult(res)));
    }
  }, [car, quote]);

  useEffect(() => {
    console.debug('UseEffect get resa');
    if (car) {
      setReservation(
        quoteToReservation(currentUser, car, currency, quote?.dateRange, quote?.extraOptions, quote?.location, quote?.discountAmount ?? 0),
      );
    }
  }, [car, currency, currentUser, quote]);

  if (!car || !quote) {
    return (
      <HStack space={'sm'} justifyContent={'space-between'} alignItems={'center'}>
        <HStack space={'md'} alignItems={'center'}>
          <Skeleton h={'$16'} w={'$16'} rounded={'$md'} />
          <VStack space="xs">
            <Skeleton.Text lines={1} w={200} />
            <Skeleton.Text lines={1} w={100} />
            <Skeleton.Text lines={1} w={100} />
          </VStack>
        </HStack>
        <Skeleton.Text lines={1} w={'50%'} />
        <Skeleton h={'$8'} w={'$8'} rounded={'$full'} />
      </HStack>
    );
  }

  return (
    <TouchableOpacity onPress={onPress}>
      <HStack space={'sm'} justifyContent={'space-between'} alignItems={'center'}>
        <HStack space={'md'} alignItems={'center'}>
          <Image size="md" source={{ uri: resizedImage(getThumbnailUrl(car?.images)) }} rounded={'$xl'} />
          <VStack>
            <Heading size={'h4'}>{car?.brand + ' ' + car?.model}</Heading>
            <Text variant={'grey'} size={'secondary'}>
              {quote?.email}
            </Text>
            <Text variant={'grey'} size={'secondary'}>
              {displayRangeDate(quote?.dateRange?.from, quote?.dateRange?.to, 'small')}
            </Text>
          </VStack>
        </HStack>
        <HStack space={'md'}>
          <Heading size={'h4'} alignSelf={'center'} justifyContent={'flex-end'}>
            {formatCurrency(
              Math.round(reservation?.invoice?.totalPrice) / reservation?.invoice?.smallerCurrencyUnit,
              reservation?.invoice?.currency,
            )}
          </Heading>
          <Icon as={Ionicons} size={'md'} name={'chevron-forward-outline'} style={{ transform: [{ scaleX: I18n?.isRTL ? -1 : 1 }] }} />
        </HStack>
      </HStack>
    </TouchableOpacity>
  );
};

export default QuoteCard;
