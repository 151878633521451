import React from 'react';

import { Picker } from '@react-native-picker/picker';

import { atoi } from '../util/conversion';

export default function WheelPicker({ initIndex, data, onSelected }) {
  return (
    <Picker
      style={{ width: 50, height: 30, borderRadius: 5 }}
      selectedValue={initIndex}
      onValueChange={(value, itemIndex) => onSelected({ value: atoi(value) })}>
      {data.map((item) => (
        <Picker.Item label={item.title} value={item.value} />
      ))}
    </Picker>
  );
}
