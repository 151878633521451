import React, { useEffect, useState } from 'react';

import { Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { errorHandler } from '../../../shared/components/errorHandler/errorHander';
import ChooseExtraOption from '../../../shared/components/extraOption/chooseExtraOption';
import Skeleton from '../../../shared/components/skeleton/skeleton';
import { quoteSetter } from '../../../shared/reducers/quote.reducer';
import { firebaseApp } from '../../../shared/services/firebase/firebaseFactory';
import { cleanFirestoreResult } from '../../../shared/util/firestore-utils';

function QuoteSelectExtraOption() {
  const [car, setCar] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.users.currentUser);
  const quote = useSelector((state) => state.quote.quote);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser?.uid) {
      setLoading(true);
      firebaseApp
        .firestore()
        .collection('cars')
        .doc(quote?.carId)
        .get()
        .then((resCar) => {
          setCar(cleanFirestoreResult(resCar));
          setLoading(false);
        })
        .catch((e) => {
          errorHandler(e, true);
          setLoading(false);
        });
    }
  }, [currentUser?.uid, quote?.carId]);

  function setSelectedExtraOptions(extraOptions) {
    dispatch(quoteSetter({ ...quote, extraOptions: extraOptions }));
  }

  return (
    <VStack space={'lg'}>
      <Text variant={'grey'}>{t('quote.extraOptionSubTitle')}</Text>
      {loading && <Skeleton.Text lines={2} />}
      <ChooseExtraOption
        car={car}
        lang={currentUser?.language}
        selectedExtraOption={quote?.extraOptions ?? []}
        setSelectedExtraOptions={setSelectedExtraOptions}
        displayAll={true}
      />
    </VStack>
  );
}

export default QuoteSelectExtraOption;
