import { useCallback, useEffect } from 'react';

import { useNavigation, useRoute } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';

import { navigationRef } from '../../../navigation/nav-ref';
import { setDateRange, setFilters, setLocation } from '../../reducers/search.reducer';
import { rangeToDateRange } from '../../util/date-transforms';
import { getPlaceDetail } from '../../util/place-utils';
import { paramToArray } from '../../util/url-utils';
import { useDidUpdateEffect } from '../../util/use-did-update-effect';

function SearchParamsHooker() {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.search.location);
  const dateRange = useSelector((state) => state.search.dateRange);
  const filters = useSelector((state) => state.search.filters);

  const navigation = useNavigation();
  const route = useRoute();

  const {
    from,
    to,
    pickUpPlaceId,
    returnPlaceId,
    checkInHour,
    checkInMinute,
    checkOutHour,
    checkOutMinute,
    carType,
    feature,
    transmission,
    fuel,
  } = route?.params ?? {};

  useEffect(() => {
    if (from && to) {
      dispatch(setDateRange(rangeToDateRange(from, to, checkInHour, checkInMinute, checkOutHour, checkOutMinute)));
    } else {
      dispatch(setDateRange({}));
    }
  }, [dispatch, checkInHour, checkInMinute, checkOutHour, checkOutMinute, from, to]);

  useEffect(() => {
    if (pickUpPlaceId) {
      getPlaceDetail(pickUpPlaceId).then((pickUpRes) => {
        if (returnPlaceId) {
          getPlaceDetail(returnPlaceId).then((returnRes) => dispatch(setLocation({ pickUp: pickUpRes, return: returnRes })));
        } else {
          dispatch(setLocation({ pickUp: pickUpRes }));
        }
      });
    } else {
      dispatch(setLocation({}));
    }
  }, [dispatch, pickUpPlaceId, returnPlaceId]);

  useEffect(() => {
    if (carType || feature || transmission || fuel) {
      dispatch(setFilters({ fuel: fuel, feature: paramToArray(feature), transmission: transmission, carType: paramToArray(carType) }));
    } else {
      dispatch(setFilters({}));
    }
  }, [dispatch, carType, feature, transmission, fuel]);

  useDidUpdateEffect(() => {
    navigation.navigate(navigationRef.current.getCurrentRoute()?.name, {
      ...(navigationRef.current.getCurrentRoute()?.params ?? {}),
      ...(dateRange?.from ? { ...dateRange } : {}),
      ...(location?.pickUp && { pickUpPlaceId: location?.pickUp?.placeId }),
      ...(location?.return && { returnPlaceId: location?.return?.placeId }),
      ...filters,
    });
  }, [
    dateRange?.from,
    dateRange?.to,
    dateRange?.checkInHour,
    dateRange?.checkInMinute,
    dateRange?.checkOutHour,
    dateRange?.checkOutMinute,
    location?.pickUp,
    location?.return,
    filters?.carType,
    filters?.feature,
    filters?.transmission,
    filters?.fuel,
  ]);

  return null;
}

export default SearchParamsHooker;
