import { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Heading, HStack, Icon, View, VStack } from '@gluestack-ui/themed';
import { Text } from '@gluestack-ui/themed';
import NetInfo from '@react-native-community/netinfo';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import HeaderWeb from './headerWeb';
import appConfig from '../../../config/app-config';
import { goBackOrDefault } from '../../../navigation/nav-ref';
import { useIsDrawer } from '../../provider/isDrawerProvider';
import { useMaxWidth } from '../../provider/maxWidthProvider';
import { firebaseApp } from '../../services/firebase/firebaseFactory';

export default function HeaderBar(props) {
  const { displayBar, displayNav, canGoBack, rightElement } = props;

  const maxWidth = useMaxWidth();
  const isDrawer = useIsDrawer();
  const { top } = useSafeAreaInsets();

  const { t } = useTranslation();
  const [isConnectionInfoOpen, setConnectionInfoOpen] = useState(false);

  useEffect(() => {
    console.debug('Effect: Network check');
    // Subscribe
    const unsubscribe = NetInfo.addEventListener((state) => {
      if (state.isConnected) {
        setConnectionInfoOpen(false);
        firebaseApp.database().goOnline();
      } else {
        setConnectionInfoOpen(true);
        firebaseApp.database().goOffline();
      }
    });

    // Unsubscribe
    return () => unsubscribe();
  }, []);

  return (
    <VStack style={{ marginTop: top }}>
      <View>
        {isConnectionInfoOpen && (
          <View>
            <HStack space={'md'} justifyContent="center" backgroundColor={'$light200'} h={30} alignItems={'center'}>
              <Icon as={Ionicons} name={'warning-outline'} color={'$light900'} />
              <Text alignSelf={'center'} color="$light900" fontWeight={'$bold'}>
                {t('error.noInternet')}
              </Text>
            </HStack>
          </View>
        )}
        {appConfig.env !== 'PROD' && <Box bgColor={'$red200'} h={5} />}
        {(displayBar || isDrawer) && <HeaderWeb />}
        {displayNav && (
          <HStack
            w={maxWidth}
            alignSelf={'center'}
            pl={canGoBack ? '$4' : '$screenMargin'}
            pr={'$screenMargin'}
            my={'$2'}
            justifyContent={'space-between'}>
            <HStack space={'md'} alignItems={'flex-start'}>
              {canGoBack && (
                <TouchableOpacity testID="nav.back" onPress={() => goBackOrDefault('Search')}>
                  <Icon size={'xl'} as={Ionicons} name={'chevron-back-outline'} color={'$black'} mt={'$1'} />
                </TouchableOpacity>
              )}
              <Heading testID={`nav.${props.options.title}`} size={'h1'} alignSelf={'center'} numberOfLines={5} maxWidth={maxWidth * 0.7}>
                {t(props.options.title)}
              </Heading>
            </HStack>
            {rightElement ?? null}
          </HStack>
        )}
      </View>
      {props.children}
    </VStack>
  );
}
