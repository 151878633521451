import React, { useEffect, useState } from 'react';

import { Ionicons } from '@expo/vector-icons';
import { HStack, Icon, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { getOneCar } from '../../../services/firebase/car-firebase';
import { getThumbnailUrl } from '../../../util/car-utils';
import { displayRangeDate } from '../../../util/date-transforms';
import { errorHandler } from '../../errorHandler/errorHander';
import SmallCard from '../../pressable/smallCard';

export default function CarReviewCard({ reservation, onPress, disabled } = { disabled: true }) {
  const [car, setCar] = useState({ uid: reservation?.carId });
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    console.debug('Effect: refresh reservation.carId');
    setLoading(true);
    getOneCar(reservation?.carId)
      .then((res) => {
        setCar(res);
        setLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoading(false);
      });
  }, [reservation?.carId]);

  return (
    <SmallCard
      imageUrl={getThumbnailUrl(car?.images)}
      title={t('rental.reviewYourRental')}
      subTitle={
        <VStack>
          <Text variant={'grey'} size={'secondary'}>
            {car?.brand + ' ' + car?.model + ' - ' + displayRangeDate(reservation?.from, reservation?.to)}
          </Text>
          <HStack>
            <Icon as={Ionicons} size={'md'} color={'$light300'} name={'star'} />
            <Icon as={Ionicons} size={'md'} color={'$light300'} name={'star'} />
            <Icon as={Ionicons} size={'md'} color={'$light300'} name={'star'} />
            <Icon as={Ionicons} size={'md'} color={'$light300'} name={'star'} />
            <Icon as={Ionicons} size={'md'} color={'$light300'} name={'star'} />
          </HStack>
        </VStack>
      }
      loading={loading}
      onPress={() => onPress(car, reservation)}
    />
  );
}
