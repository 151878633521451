import React from 'react';

import { Button, ButtonText, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { Images } from '../../../themes';
import imagesUrl from '../../../themes/imagesUrl';
import PlaceHolder from '../../placeHolder/placeHolder';

export default function LoginPlaceHolder(props) {
  const { t } = useTranslation();
  const navigation = useNavigation();

  return (
    <PlaceHolder
      image={{ uri: imagesUrl.toll }}
      title={t('placeHolder.pleaseSign')}
      text={t('placeHolder.loginInfo')}
      element={
        <Button onPress={() => navigation.navigate('LoginOrSignUp')}>
          <ButtonText>{t('placeHolder.loginButton')}</ButtonText>
        </Button>
      }
    />
  );
}
