import React, { useEffect, useState } from 'react';

import ProfileCard from './ProfileCard';
import { firebaseApp } from '../../../services/firebase/firebaseFactory';
import { getOneUser } from '../../../services/firebase/user-firebase';
import { cleanFirestoreResult } from '../../../util/firestore-utils';
import { errorHandler } from '../../errorHandler/errorHander';

export default function ProfileCardById({ userId, onPress, isDisable }) {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    console.debug('Effect: refresh user', userId);
    if (userId) {
      setLoading(true);
      firebaseApp
        .firestore()
        .collection('profiles')
        .doc(userId)
        .get()
        .then(cleanFirestoreResult)
        .then((res) => {
          setProfile(res);
          setLoading(false);
        })
        .catch((e) => {
          errorHandler(e, true);
          setLoading(false);
        });
    }
  }, [userId]);

  return <ProfileCard isDisable={isDisable} loading={loading} profile={profile} onPress={onPress} />;
}
