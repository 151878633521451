import React, { useEffect, useState } from 'react';

import { FormControl, FormControlLabel, FormControlLabelText, Heading, Input, InputField, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ProfileCard from '../../../shared/components/entities/user/ProfileCard';
import { errorHandler } from '../../../shared/components/errorHandler/errorHander';
import Select from '../../../shared/components/select/select';
import Skeleton from '../../../shared/components/skeleton/skeleton';
import LangSwitcher from '../../../shared/components/web/langSwitcher';
import { quoteSetter } from '../../../shared/reducers/quote.reducer';
import { firebaseApp } from '../../../shared/services/firebase/firebaseFactory';
import { cleanFirestoreResult } from '../../../shared/util/firestore-utils';

function AddEmail() {
  const [driver, setDriver] = useState({});
  const [driverLoading, setDriverLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote.quote);
  const currentUser = useSelector((state) => state.users.currentUser);
  const setQuote = (quote) => dispatch(quoteSetter(quote));

  useEffect(() => {
    if (quote?.driverId) {
      setDriverLoading(true);
      firebaseApp
        .firestore()
        .collection('profiles')
        .doc(quote?.driverId)
        .get()
        .then((p) => {
          setDriver(cleanFirestoreResult(p));
          setDriverLoading(false);
        })
        .catch((e) => {
          errorHandler(e, true);
          setDriverLoading(false);
        });
    }
  }, [quote?.driverId]);

  if (quote?.driverId) {
    return (
      <VStack space={'lg'}>
        {driverLoading ? (
          <Skeleton.Text w={200} />
        ) : (
          <Text variant={'grey'} size={'secondary'}>
            {t('quote.sendQuoteToUser', { user: driver?.name ?? `${driver?.firstName} ${driver?.lastName}` })}
          </Text>
        )}

        <ProfileCard loading={driverLoading} profile={driver} isDisable={true} />
      </VStack>
    );
  }

  return (
    <VStack space={'lg'}>
      <Text variant={'grey'}>{t('quote.addEmailSubTitle')}</Text>
      <FormControl>
        <FormControlLabel>
          <FormControlLabelText>{t('quote.language')}</FormControlLabelText>
        </FormControlLabel>
        <Select
          title={t('language.language')}
          data={[
            { value: 'en', label: '🇬🇧 English' },
            { value: 'es', label: '🇪🇸 Español' },
            { value: 'fr', label: '🇫🇷 Français' },
            { value: 'ar', label: 'اللغة العربية (تم إنشاؤها)' },
            { value: 'cz', label: '🇨🇿 Čeština (generováno)' },
            { value: 'de', label: '🇩🇪 deutsch (generiert)' },
            { value: 'he', label: 'עברית (נוצרה) 🇮🇱' },
            { value: 'it', label: '🇮🇹 Italiano (generato)' },
            { value: 'ja', label: '🇯🇵 日本人(生成)' },
            { value: 'pt', label: '🇵🇹 Português (Gerado)' },
            { value: 'ru', label: '🇷🇺 Русский (сгенерированный)' },
            { value: 'sk', label: '🇸🇰 Slovenčina (generovaný)' },
            { value: 'zh', label: '🇨🇳 中文（生成）' },
          ]}
          onSelect={(item) => setQuote({ ...quote, language: item.value })}
          placeHolder={t('language.language')}
          value={quote?.language}
        />
      </FormControl>
      <FormControl>
        <FormControlLabel>
          <FormControlLabelText>{t('login.email')}</FormControlLabelText>
        </FormControlLabel>
        <Input>
          <InputField defaultValue={quote?.email} inputMode="email" onChangeText={(value) => setQuote({ ...quote, email: value })} />
        </Input>
      </FormControl>
    </VStack>
  );
}

export default AddEmail;
