import { createStyle } from '@gluestack-style/react';

export const Text = createStyle({
  color: '$textLight700',
  _dark: {
    color: '$textDark200',
  },
  fontWeight: '$normal',
  fontFamily: '$body',
  fontStyle: 'normal',
  letterSpacing: '$md',

  variants: {
    variant: {
      grey: {
        color: '$light500',
      },
      link: {
        color: '$blue500',
      },
    },
    isTruncated: {
      true: {
        props: {
          // @ts-ignore
          numberOfLines: 1,
          ellipsizeMode: 'tail',
        },
      },
    },
    bold: {
      true: {
        fontWeight: '$bold',
      },
    },
    underline: {
      true: {
        textDecorationLine: 'underline',
      },
    },
    strikeThrough: {
      true: {
        textDecorationLine: 'line-through',
      },
    },
    sub: {
      true: {
        fontSize: '$xs',
        lineHeight: '$xs',
      },
    },
    italic: {
      true: {
        fontStyle: 'italic',
      },
    },
    highlight: {
      true: {
        bg: '$yellow500',
      },
    },
    size: {
      tertiary: {
        fontSize: '$tertiary',
        lineHeight: '$tertiary',
      },
      secondary: {
        fontSize: '$secondary',
        lineHeight: '$secondary',
      },

      primary: {
        fontSize: '$primary',
        lineHeight: '$primary',
      },

      h1: {
        fontSize: '$h1',
        lineHeight: '$h1',
      },

      h2: {
        fontSize: '$h2',
        lineHeight: '$h2',
      },

      h3: {
        fontSize: '$h3',
        lineHeight: '$h3',
      },

      h4: {
        fontSize: '$h4',
        lineHeight: '$h4',
      },

      h5: {
        fontSize: '$h5',
        lineHeight: '$h5',
      },

      h6: {
        fontSize: '$h6',
        lineHeight: '$h6',
      },

      '2xs': {
        fontSize: '$2xs',
        lineHeight: '$2xs',
      },
      xs: {
        fontSize: '$xs',
        lineHeight: '$sm',
      },

      sm: {
        fontSize: '$sm',
        lineHeight: '$sm',
      },

      md: {
        fontSize: '$md',
        lineHeight: '$md',
      },

      lg: {
        fontSize: '$lg',
        lineHeight: '$xl',
      },

      xl: {
        fontSize: '$xl',
        lineHeight: '$xl',
      },

      '2xl': {
        fontSize: '$2xl',
        lineHeight: '$2xl',
      },

      '3xl': {
        fontSize: '$3xl',
        lineHeight: '$3xl',
      },

      '4xl': {
        fontSize: '$4xl',
        lineHeight: '$4xl',
      },

      '5xl': {
        fontSize: '$5xl',
        lineHeight: '$6xl',
      },

      '6xl': {
        fontSize: '$6xl',
        lineHeight: '$7xl',
      },
    },
  },

  defaultProps: {
    size: 'md',
  },
});
