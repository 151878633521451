import React, { useMemo } from 'react';
import { I18nManager, TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Heading, HStack, Icon, Spinner, Text, VStack } from '@gluestack-ui/themed';

import { Metrics } from '../../themes';
import imagesUrl from '../../themes/imagesUrl';
import { resizedImage } from '../../util/image-utils';
import Badge from '../badge/badge';
import BadgeText from '../badge/badgeText';
import Image from '../image/image';
import Skeleton from '../skeleton/skeleton';

export default function SmallCard({
  isDisable,
  isSpiner,
  imageUrl,
  imageMode,
  loading,
  title,
  onPress,
  subTitle,
  info,
  isRounded,
  badge,
  badgeColorSheme,
  bgColor,
  size,
}) {
  const imageUri = useMemo(() => resizedImage(imageUrl ?? imagesUrl.unavailable), [imageUrl]);

  if (loading) {
    return (
      <HStack justifyContent={'space-between'} alignItems={'center'} w={'100%'}>
        <HStack space={'md'}>
          <Skeleton h={Metrics.images.large} w={Metrics.images.large} rounded={isRounded ? '$full' : '$md'} />
          <VStack justifyContent={'center'} space={'sm'}>
            <Skeleton.Text lines={1} w={100} />
            <Skeleton.Text lines={1} w={200} />
          </VStack>
        </HStack>
        <Skeleton h={'$5'} w={'$5'} rounded={'$full'} />
      </HStack>
    );
  }

  return (
    <TouchableOpacity onPress={onPress} disabled={isDisable || !onPress}>
      <VStack space={'sm'} bgColor={bgColor} rounded={'$md'} borderWidth={'$1'} borderColor={bgColor ?? '$transparent'}>
        <HStack justifyContent={'space-between'} alignItems={'center'}>
          <HStack space={'md'} alignItems={'center'} maxWidth={'90%'}>
            {!isRounded ? (
              <Image
                bgColor={'$light300'}
                rounded={'$2xl'}
                source={{ uri: resizedImage(imageUrl ?? imagesUrl.unavailable) }}
                size={size ? size : 'md'}
                resizeMode={imageMode ? imageMode : 'cover'}
                alt={'Thumbnail'}
              />
            ) : (
              <Image source={{ uri: resizedImage(imageUrl) }} size={size ? size : 'md'} rounded={'$full'} />
            )}
            <VStack justifyContent={'center'} space={'xs'} w={'70%'}>
              <Heading size={'h4'}>{title}</Heading>
              {badge ? (
                <BadgeText colorScheme={badge.color}>{badge.text}</BadgeText>
              ) : (
                subTitle && (
                  <Text variant={'grey'} size={'secondary'} numberOfLines={1} maxw={'80%'}>
                    {subTitle}
                  </Text>
                )
              )}
              {info && (
                <Text size={'secondary'} maxw={'80%'}>
                  {info}
                </Text>
              )}
            </VStack>
          </HStack>
          {!isDisable && isSpiner ? (
            <Spinner size={'lg'} />
          ) : (
            !isDisable && (
              <Icon
                as={Ionicons}
                size={'lg'}
                name={'chevron-forward-outline'}
                maxWidth={'10%'}
                style={{ transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }] }}
              />
            )
          )}
        </HStack>
      </VStack>
    </TouchableOpacity>
  );
}
