import React, { useEffect, useMemo, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { Box, Button, ButtonText, Divider, Heading, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';

import appConfig from '../../config/app-config';
import { goBackOrDefault } from '../../navigation/nav-ref';
import ActionSheet from '../../shared/components/actionSheet/actionSheet';
import DeliveryLocationDisplay from '../../shared/components/deliveryLocation/deliveryLocationDisplay';
import MessageButton from '../../shared/components/entities/message/messageButton';
import CarReviews from '../../shared/components/entities/review/carReviews';
import ProfileCardById from '../../shared/components/entities/user/ProfileCardById';
import ChooseExtraOption from '../../shared/components/extraOption/chooseExtraOption';
import CarDateRangeSelector from '../../shared/components/info/carDateRangeSelector';
import CarDetailBar from '../../shared/components/info/carDetailBar';
import CarDetailCover from '../../shared/components/info/carDetailCover';
import CarFeatures from '../../shared/components/info/carFeatures';
import InfoExtender from '../../shared/components/info/infoExtender';
import PriceDisplay from '../../shared/components/info/priceDisplay';
import InsuranceDetail from '../../shared/components/insurance/insuranceDetail';
import Skeleton from '../../shared/components/skeleton/skeleton';
import WebScrollView from '../../shared/components/web/webScrollView';
import WebSpliter from '../../shared/components/web/webSpliter';
import { Metrics } from '../../shared/themes';
import { getPriceFromCurrency } from '../../shared/util/currency';
import { rangeToDateRange } from '../../shared/util/date-transforms';
import { screenBreakPoint } from '../../shared/util/device-utils';

function CarPreviewScreen({ route, hideBooking }) {
  const currentUser = useSelector((state) => state.users.currentUser);
  const currency = useSelector((state) => state.users.currency);
  const car = useSelector((state) => state.cars.carAdding);

  const { t } = useTranslation();

  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => screenBreakPoint(width, [true, true, false, false, false]), [width]);

  const { carId, from, to, hideBookingAction, placeId } = route?.params ?? { hideBookingAction: hideBooking };

  const [isOptionOpen, setOptionOpen] = useState(false);
  const [deliveryOk, setDeliveryOk] = useState(false);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState({ placeId: placeId });
  const [selectedExtraOption, setSelectedExtraOptions] = useState([]);

  const [dateRange, setDateRange] = useState(rangeToDateRange(from, to));

  const navigation = useNavigation();

  useEffect(() => {
    console.debug('Effect: refresh from to date');
    setDateRange(rangeToDateRange(from, to));
  }, [from, to]);

  useEffect(() => {
    if (!location?.placeId && car?.placeId) {
      setLocation({ placeId: car?.placeId, location: car?._geoloc, name: car?.placeName });
    }
  }, [car, location?.placeId]);

  useEffect(() => {
    console.debug('Effect: refresh car, navigation');
    if (car?.brand) {
      navigation.setOptions({ title: car?.brand + ' ' + car?.model });
    }
  }, [car, navigation]);

  return (
    <>
      <WebScrollView {...(isMobile && { pb: 200 })}>
        <VStack mx={isMobile ? 0 : Metrics.screenWidthMarging} mb={'$4'}>
          <CarDetailCover isMobile={isMobile} images={car?.images} carId={car?.uid} onClosePress={() => goBackOrDefault('OwnerHome')} />
        </VStack>
        <VStack mb={'$4'} mx={'$screenMargin'} space={'lg'}>
          <CarDetailBar car={car} currency={currency} fullReview={true} />
          <Divider />
          <WebSpliter
            left={
              <VStack space={'lg'}>
                <InfoExtender
                  object={car}
                  field={'carDescription'}
                  loading={loading}
                  limit={1000}
                  showHeader={false}
                  headerText={t('car.description')}
                />

                <Divider />
                <VStack>
                  <VStack space={'md'}>
                    <Heading>{t('car.whatThisCarOffer')}</Heading>
                    <CarFeatures features={car.features} mode={'small'} />
                    <Button variant={'outline'} onPress={() => setOptionOpen(true)}>
                      <ButtonText>{t('car.showAllOptions')}</ButtonText>
                    </Button>
                  </VStack>
                  <ActionSheet isOpen={isOptionOpen} setOpen={setOptionOpen} headerText={t('car.whatThisCarOffer')}>
                    <Box mx={'$4'}>
                      <CarFeatures features={car?.features} />
                    </Box>
                  </ActionSheet>
                </VStack>
                <Divider />
                <InfoExtender
                  object={car}
                  field={'carGuidelines'}
                  loading={loading}
                  limit={1000}
                  showHeader={true}
                  headerText={t('car.ownerGuidlines')}
                />
              </VStack>
            }
            right={
              <VStack mx={'$4'} space={'md'}>
                {!hideBookingAction && !isMobile ? (
                  <Box variant={'cardContainer'} p={'$4'}>
                    <VStack space={'md'}>
                      <HStack justifyContent={'space-between'} alignItems={'center'} space={'md'}>
                        <PriceDisplay priceData={car?.priceData} carCurrency={car?.currency} currency={currency} dateRange={dateRange} />
                        <CarDateRangeSelector
                          dateRange={dateRange}
                          setDateRange={setDateRange}
                          bookedDays={car.bookedDays}
                          priceData={car?.priceData}
                        />
                      </HStack>
                      <VStack space={'sm'}>
                        <Button
                          isDisabled={!dateRange.from || !dateRange.to || !deliveryOk}
                          px={Metrics.baseMargin}
                          onPress={() =>
                            navigation.navigate('ConfirmAndPay', {
                              carId: car.uid,
                              from: dateRange.from,
                              to: dateRange.to,
                              checkInHour: dateRange.checkInHour,
                              checkInMinute: dateRange.checkInMinute,
                              checkOutHour: dateRange.checkOutHour,
                              checkOutMinute: dateRange.checkOutMinute,
                              extraOptions: selectedExtraOption,
                              placeId: location?.placeId,
                            })
                          }>
                          <ButtonText>{t('global.book')}</ButtonText>
                        </Button>
                        <Text variant={'grey'} size={'secondary'}>
                          {t('reservation.confirmUntilOwnerConfirmInfo')}
                        </Text>
                      </VStack>
                      {dateRange.from && dateRange.to && (
                        <VStack space={'md'}>
                          <Divider />
                          <HStack space={'md'} justifyContent={'space-between'}>
                            <Text size={'h3'}>{t('reservation.total', { currency: currency.current })}</Text>
                            <Text size={'h3'} fontWeight={'$bold'}>
                              {car?.pricePerDay ? (
                                getPriceFromCurrency(
                                  car?.pricePerDay * ((dateRange?.to - dateRange?.from) / 60 / 60 / 24) * appConfig.serviceFee,
                                  car?.currency,
                                  currency,
                                )
                              ) : (
                                <Skeleton h={'$8'} w={'$12'} rounded={'$full'} />
                              )}
                            </Text>
                          </HStack>
                        </VStack>
                      )}
                    </VStack>
                  </Box>
                ) : null}
              </VStack>
            }
            space={'md'}
          />
          <Divider />
          <DeliveryLocationDisplay
            car={car}
            loading={loading}
            location={location}
            setLocation={setLocation}
            setDeliveryOk={setDeliveryOk}
          />
          <Divider />
          <InsuranceDetail insurances={car?.insurances ?? []} />
          <ChooseExtraOption
            car={car}
            lang={currentUser?.language}
            selectedExtraOption={selectedExtraOption}
            setSelectedExtraOptions={setSelectedExtraOptions}
          />
          <VStack w={isMobile ? '100%' : '50%'} space={'md'}>
            <Heading>{t('car.owner')}</Heading>
            <ProfileCardById userId={car?.ownerId} onPress={() => navigation.navigate('PublicProfileScreen', { userId: car.ownerId })} />
            <MessageButton text={t('ownerRequest.message')} carId={car.uid} userToContact={car.ownerId} />
          </VStack>

          <Divider />
          <Heading>{t('car.reviews')}</Heading>
          <CarReviews carId={car.uid} limit={5} />
        </VStack>
      </WebScrollView>
      {isMobile && (
        <Box bgColor={'$white'} p={'$4'} position={'absolute'} bottom={0} w={'100%'} borderTopWidth={1} borderColor={'$light200'}>
          <VStack space={'md'}>
            <HStack justifyContent={'space-between'} alignItems={'center'} space={'md'}>
              <VStack alignItems={'flex-start'} w={'100%'}>
                <VStack space={'md'} w={'100%'}>
                  <HStack space={'md'} justifyContent={'space-between'}>
                    <CarDateRangeSelector
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      bookedDays={car.bookedDays}
                      priceData={car?.priceData}
                    />
                    {dateRange.from && dateRange.to && (
                      <Text size={'h3'} fontWeight={'$bold'}>
                        {car?.pricePerDay ? (
                          getPriceFromCurrency(
                            car?.pricePerDay * ((dateRange?.to - dateRange?.from) / 60 / 60 / 24) * appConfig.serviceFee,
                            car?.currency,
                            currency,
                          )
                        ) : (
                          <Skeleton h={'$8'} w={'$12'} rounded={'$full'} />
                        )}
                      </Text>
                    )}
                  </HStack>
                </VStack>
              </VStack>
            </HStack>
            <VStack space={'sm'}>
              <Button
                isDisabled={!dateRange.from || !dateRange.to || !deliveryOk}
                px={Metrics.baseMargin}
                onPress={() =>
                  navigation.navigate('ConfirmAndPay', {
                    carId: car.uid,
                    from: dateRange.from,
                    to: dateRange.to,
                    checkInHour: dateRange.checkInHour,
                    checkInMinute: dateRange.checkInMinute,
                    checkOutHour: dateRange.checkOutHour,
                    checkOutMinute: dateRange.checkOutMinute,
                    extraOptions: selectedExtraOption,
                    placeId: location?.placeId,
                  })
                }>
                <ButtonText>{t('global.book')}</ButtonText>
              </Button>
              <Text variant={'grey'} size={'secondary'}>
                {t('reservation.confirmUntilOwnerConfirmInfo')}
              </Text>
            </VStack>
          </VStack>
        </Box>
      )}
    </>
  );
}

export default CarPreviewScreen;
