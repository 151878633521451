import { I18nManager } from 'react-native';

import { extendTheme } from '@gluestack-ui/themed';
import { DefaultTheme } from '@react-navigation/native';

import { Colors, Fonts, Metrics } from '.';

export const themeNavigation = {
  ...DefaultTheme,
  dark: false,
  colors: {
    primary: Colors.blue[400],
    background: Colors.fullWhite,
    card: Colors.white,
    text: Colors.darkText,
    border: Colors.white,
    notification: 'rgb(255, 69, 58)',
  },
};

export const shadow = {
  shadowColor: 'coolGray.200',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,
  style: {
    boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)',
  },

  elevation: 5,
};

export const theme = {
  colors: {
    ...Colors,
    background: Colors.white,
    foreground: Colors.black,
    primary: Colors.blue,
  },
  fontConfig: Fonts.fontConfig,
  // Make sure values below matches any of the keys in `fontConfig`
  fonts: Fonts.fonts,
  fontSizes: Fonts.sizes,
  sizes: Metrics.sizes,
  components: {
    Image: {
      sizes: {
        xs: '8',
        sm: '12',
        md: '16',
        lg: '24',
        xl: '32',
        '2xl': '64',
        full: '100%',
      },
    },
    Icon: {
      sizes: {
        xs: 3,
        sm: 4,
        md: 6,
        lg: 7,
        xl: 8,
        '2xl': 9,
      },
    },
    Text: {
      defaultProps: {
        selectable: true,
        fontSize: 'primary',
        fontWeight: '400',
        maxFontSizeMultiplier: 2,
        alignSelf: 'flex-start',
      },
      variants: {
        link: {
          color: Colors.blue,
        },
        grey: {
          color: Colors.grey,
        },
      },
    },
    Link: {
      baseStyle: {
        colors: Colors.red,
      },
    },
    Toast: {
      baseStyle: {
        backgroundColor: 'red',
      },
    },
    Badge: {
      defaultProps: {
        rounded: 'sm',
      },
    },
    Heading: {
      baseStyle: {
        numberOfLines: 2,
        maxFontSizeMultiplier: 2,
        alignSelf: 'flex-start',
      },
      sizes: {
        h1: { fontSize: 'h1' },
        h2: { fontSize: 'h2' },
        h3: { fontSize: 'h3' },
        h4: { fontSize: 'h4' },
        h5: { fontSize: 'h5' },
      },
      defaultProps: {
        size: 'h3',
        fontWeight: '500',
      },
    },
    Button: {
      sizes: {
        md: {
          px: '3',
          py: '3',
          _text: {
            fontSize: 'primary',
          },
          _icon: {
            size: 'sm',
          },
        },
      },
      baseStyle: {
        borderRadius: 'lg',
        minWidth: '150',
        _text: { fontWeight: '500', fontSize: 'primary', textAlign: 'center' },
      },
      variants: {
        outline: {
          _text: { color: Colors.black },
          borderColor: Colors.light[500],
          borderWidth: 1,
          _pressed: { backgroundColor: Colors.light[100] },
        },
      },
    },
    Container: {
      baseStyle: {
        width: '100%',
      },
    },
    Input: {
      baseStyle: {
        borderRadius: 'lg',
        borderWidth: 1,
        height: Metrics.inputHeight,
        borderColor: Colors.light[500],
        writingDirection: I18nManager.isRTL ? 'rtl' : 'ltr',
      },
      variants: {
        searchBar: {
          rounded: 'md',
          borderWidth: 0,
          backgroundColor: Colors.light[100],
          _text: {
            fontSize: 'primary',
          },
        },
      },
    },
    Box: {
      variants: {
        select: {
          borderWidth: 1,
          borderColor: '$light500',
          rounded: 'lg',
          paddingTop: 2,
          paddingRight: 3,
          paddingBottom: 2,
          paddingLeft: 3,
          height: Metrics.inputHeight,
        },
        shadow: {
          ...shadow,
        },
        cardContainer: {
          ...shadow,
          rounded: '2xl',
          backgroundColor: Colors.white,
          borderWidth: 0,
        },
        featureBox: {
          width: '100%',
          borderRadius: 'lg',
          backgroundColor: Colors.white,
          borderWidth: 2,
          borderColor: Colors.black,
        },
        cardGrey: {
          width: '100%',
          borderRadius: 'lg',
          backgroundColor: Colors.light[50],
          borderWidth: 1,
          borderColor: Colors.light[300],
        },
        footer: {
          backgroundColor: Colors.white,
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
        },
        contentWithFooter: {
          marginBottom: 80,
        },
        absoluteTopLeft: {
          position: 'absolute',
          left: 0,
          top: 0,
        },
        absoluteTop: {
          position: 'absolute',
          top: 0,
        },
        absoluteMidle: {
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
        },
        absoluteBottomLeft: {
          position: 'absolute',
          left: 0,
          bottom: 0,
        },
        absoluteBottomRight: {
          position: 'absolute',
          right: 0,
          bottom: 0,
        },
        absoluteTopRight: {
          position: 'absolute',
          right: 0,
          top: 0,
        },
        absoluteBottom: {
          position: 'absolute',
          bottom: 0,
        },
        absoluteRight: {
          position: 'absolute',
          right: 0,
        },
        absoluteLeft: {
          position: 'absolute',
          left: 0,
        },
      },
    },
  },
  config: {
    // Changing initialColorMode to 'dark'
    initialColorMode: 'light',
  },
};
