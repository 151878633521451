import React, { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';

import { Heading, HStack, Input, InputField, Text, Textarea, TextareaInput, useToken, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Slider from '../../../shared/components/slider/slider';
import { checkCarSetter } from '../../../shared/reducers/checkCar.reducer';
import { Colors } from '../../../shared/themes';
import { getExtraInputProps } from '../../../shared/util/input-utils';

const WindowWidth = Dimensions.get('window').width;

function AddCheckCarMileage() {
  const checkCar = useSelector((state) => state.checkCar.checkCar);
  const dispatch = useDispatch();

  const [data, setData] = useState(
    checkCar?.originalContent && 'noteOwner' in checkCar.originalContent ? checkCar?.originalContent.noteOwner : '',
  );

  const { t } = useTranslation();
  const space4 = useToken('space', '4');

  useEffect(() => {
    const content = checkCar?.originalContent ? { ...checkCar?.originalContent } : {};
    content.noteOwner = data;
    dispatch(checkCarSetter({ ...checkCar, originalContent: content }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <VStack space={'lg'}>
      <Text color={'$white'}>{t('checkCar.mileageDescription')}</Text>
      <VStack space={'md'}>
        <HStack justifyContent={'space-between'}>
          <Heading color={'$white'} size={'h4'}>
            {t('checkCar.fuel')}
          </Heading>
          <Text color={'$white'} variant={'grey'} size={'secondary'}>
            {checkCar?.fuel + '/4'}
          </Text>
        </HStack>
        <Slider
          color={Colors.blue[500]}
          value={checkCar?.fuel}
          sliderWidth={WindowWidth - space4 * 2 - 20}
          max={4}
          min={0}
          textPrefix={''}
          textSufix={'/4'}
          step={1}
          onValueChange={(value) => dispatch(checkCarSetter({ ...checkCar, fuel: value }))}
        />
      </VStack>

      <VStack space={'md'}>
        <Heading color={'$white'} size={'h4'}>
          {t('checkCar.mileage')}
        </Heading>
        <Input>
          <InputField
            color={'$white'}
            value={checkCar.mileage}
            inputMode="numeric"
            onChangeText={(text) => dispatch(checkCarSetter({ ...checkCar, mileage: text }))}
          />
        </Input>
      </VStack>
      <VStack space={'md'}>
        <Heading color={'$white'} size={'h4'}>
          {t('checkCar.addNoteTitle')}
        </Heading>
        <Textarea {...getExtraInputProps()}>
          <TextareaInput color={'$white'} value={data} onChangeText={setData} />
        </Textarea>
      </VStack>
    </VStack>
  );
}

export default AddCheckCarMileage;
