import { firebaseApp } from './firebaseFactory';

export function getOneCheckCar(checkCarId) {
  return firebaseApp
    .firestore()
    .collection('checkCar')
    .doc(checkCarId)
    .get()
    .then((res) => {
      return { ...res.data(), uid: res.id };
    });
}

export function addOneCheckCar(checkCar) {
  return firebaseApp
    .firestore()
    .collection('checkCar')
    .add(checkCar)
    .then((res) => {
      return { uid: res.id };
    });
}
