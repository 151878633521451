import { getSmallestCurrencyUnit } from './payment-utils';
import appConfig from '../../config/app-config';

function getPriceFromDayOfYear(priceData, day) {
  if (!priceData) {
    return 0;
  } else {
    const p = [
      ...(priceData?.filter((r) => {
        if (r.from < r.to && day >= r.from && day <= r.to) {
          return true;
        }
        if (r.from > r.to && (day >= r.from || day <= r.to)) {
          return true;
        } else {
          return false;
        }
      }) || {}),
    ].shift() ?? { price: -1 };
    return p?.price;
  }
}

const leapDayYear = [2024, 2028, 2032, 2036];

function dateToDayOfYears(date) {
  const d = new Date(date);
  const dayOfYear = (d - new Date(d.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24;
  const year = d.getFullYear();

  if (leapDayYear.includes(year) && dayOfYear >= 60) {
    return Math.trunc(dayOfYear + 1);
  }
  return Math.trunc(dayOfYear);
}

function dateToCalendarTimeString(date) {
  let k = date.getFullYear() + '-';
  if (date.getUTCMonth() + 1 < 10) {
    k = k + '0' + (date.getUTCMonth() + 1) + '-';
  } else {
    k = k + (date.getUTCMonth() + 1) + '-';
  }
  if (date.getUTCDate() < 10) {
    k = k + '0' + date.getUTCDate();
  } else {
    k = k + date.getUTCDate();
  }

  return k;
}

function dateRangeToArrayDays(from, to) {
  const arrayDays = [];
  const fromDate = new Date(from * 1000);
  const toDate = new Date(to * 1000);
  arrayDays.push(dateToCalendarTimeString(fromDate));

  for (let dt = new Date(from * 1000 + 86400000); dt.getTime() < toDate.getTime(); dt = new Date(dt.getTime() + 86400000)) {
    arrayDays.push(dateToCalendarTimeString(dt));
  }
  arrayDays.push(dateToCalendarTimeString(toDate));

  return arrayDays;
}

const getPriceArray = (priceData, from, to) => {
  return dateRangeToArrayDays(from, to)
    .map((d) => dateToDayOfYears(d))
    .map((d) => getPriceFromDayOfYear(priceData, d));
};

function getDiscountPrice(car, basePrice, numberOfDay) {
  if (numberOfDay > 30) {
    return { discountPrice: Math.round(basePrice * (car?.discountPerMonth / 100)), discountPercentage: car?.discountPerMonth };
  } else if (numberOfDay > 7) {
    return { discountPrice: Math.round(basePrice * (car?.discountPerWeek / 100)), discountPercentage: car?.discountPerWeek };
  }

  return { discountPrice: 0, discountPercentage: 0 };
}

function getCarInvoice(priceArray, ratio, currency) {
  const prices = priceArray.reduce((x, y) => ((x[y] = (x[y] || 0) + 1), x), {});

  const invoiceItems = [];
  for (const key in prices) {
    const { amount } = getSmallestCurrencyUnit(key, currency);

    invoiceItems.push({
      basePrice: Math.round(amount * ratio),
      quantity: prices[key],
      totalPrice: Math.round(amount * prices[key] * ratio),
      type: 'CAR',
    });
  }
  return invoiceItems;
}

export function quoteToReservation(user, car, currency, dateRange, selectedExtraOption, location, discount) {
  const userCurrency = currency.current;
  const ownerCurrency = currency.current;
  const userCurrencyRatio = currency[userCurrency];
  const ownerCurrencyRatio = currency[ownerCurrency];

  const hardDiscount = getSmallestCurrencyUnit(discount, currency?.current).amount;

  const priceArray = getPriceArray(car?.priceData, dateRange?.from, dateRange?.to).slice(0, -1);

  const numberOfDay = priceArray?.length;

  const avgPrice = priceArray.reduce((a, b) => a + b) / priceArray.length;

  const { amount, unit } = getSmallestCurrencyUnit(avgPrice, car?.currency);
  const daylyCarPrice = amount;
  const carPrice = daylyCarPrice * numberOfDay;
  let { discountPrice, discountPercentage } = getDiscountPrice(car, carPrice, numberOfDay);
  let basePrice = carPrice - discountPrice - hardDiscount;

  const ownerMinUnit = getSmallestCurrencyUnit(1, ownerCurrency).unit;
  const ownerUnitRatio = unit / ownerMinUnit;
  const userMinUnit = getSmallestCurrencyUnit(1, userCurrency).unit;
  const userUnitRatio = unit / userMinUnit;

  const deliveryPickUp = getSmallestCurrencyUnit(location?.pickUp?.price ?? 0, car?.currency);
  const deliveryReturn = getSmallestCurrencyUnit(location?.return?.price ?? location?.pickUp?.price ?? 0, car?.currency);

  const deliveryPickUpDiscount = getDiscountPrice(car, deliveryPickUp.amount, numberOfDay);
  const deliveryReturnDiscount = getDiscountPrice(car, deliveryReturn.amount, numberOfDay);

  discountPrice += deliveryPickUpDiscount.discountPrice + deliveryReturnDiscount.discountPrice;
  const totalDeliveryAmount = deliveryPickUp.amount + deliveryReturn.amount;
  basePrice += totalDeliveryAmount - deliveryPickUpDiscount.discountPrice - deliveryReturnDiscount.discountPrice;

  const selectedExtraOptions = selectedExtraOption ?? [];

  const extraOption = selectedExtraOptions.map((option) => {
    const data = { type: 'EXTRA', extra: option };

    if (option?.perTime === 'day') {
      data.basePrice = option?.price * unit;
      data.quantity = numberOfDay;
      data.totalPrice = option?.price * unit * numberOfDay;
      data.type = 'EXTRA_DAY';
    } else {
      data.basePrice = option?.price * unit;
      data.quantity = 1;
      data.totalPrice = option?.price * unit;
      data.type = 'EXTRA_RENTAL';
    }

    const extraDiscount = getDiscountPrice(car, data.totalPrice, numberOfDay);

    discountPrice += extraDiscount.discountPrice;
    basePrice += data.totalPrice - extraDiscount.discountPrice;
    return data;
  });

  const feePrice = Math.round(basePrice * (appConfig.serviceFee - 1));

  const totalPrice = basePrice + feePrice;
  const totalPriceOwner = basePrice - feePrice;

  return {
    insuranceDeductibleAmount: car?.insuranceDeductibleAmount ?? 0,
    createdAt: new Date(),
    orderId: new Date().getTime().toString(36).toUpperCase(),
    carId: car.uid,
    ownerId: car.ownerId,
    isDemo: car.ownerId == '8pKsf4A0lMPy3UfsD0H4RuscaQJ2',
    checkInHour: dateRange?.checkInHour,
    checkInMinute: dateRange?.checkInMinute,
    checkOutHour: dateRange?.checkOutHour,
    checkOutMinute: dateRange?.checkOutMinute,
    checkInReminderSend: false,
    checkOutReminderSend: false,
    from: dateRange?.from,
    to: dateRange?.to,
    ownerAccountId: user?.stripeAccountId,
    car: car,
    toDisplay: false,
    delivery: location,
    invoiceOwner: {
      currency: ownerCurrency,
      totalPrice: Math.round(totalPriceOwner * ownerCurrencyRatio * ownerUnitRatio),
      serviceFee: Math.round(feePrice * ownerCurrencyRatio * ownerUnitRatio),
      numberOfDay: numberOfDay,
      smallerCurrencyUnit: ownerMinUnit,
      listProduct: [
        ...getCarInvoice(priceArray, ownerCurrencyRatio * ownerUnitRatio, car?.currency),
        {
          basePrice: Math.round(totalDeliveryAmount * ownerCurrencyRatio * ownerUnitRatio),
          quantity: 1,
          totalPrice: Math.round(totalDeliveryAmount * ownerCurrencyRatio * ownerUnitRatio),
          type: 'DELIVERY',
        },
        ...extraOption.map((option) => ({
          basePrice: Math.round(option.basePrice * ownerCurrencyRatio * ownerUnitRatio),
          quantity: option.quantity,
          totalPrice: Math.round(option.totalPrice * ownerCurrencyRatio * ownerUnitRatio),
          type: option.type,
          extra: option.extra,
        })),
        {
          basePrice: discountPercentage,
          quantity: 1,
          totalPrice: Math.round(discountPrice),
          type: 'DISCOUNT',
        },
        ...(discount > 0
          ? [
              {
                basePrice: hardDiscount * ownerCurrencyRatio * ownerUnitRatio,
                quantity: 1,
                totalPrice: Math.round(hardDiscount * ownerCurrencyRatio * ownerUnitRatio),
                type: 'HARD_DISCOUNT',
              },
            ]
          : []),
        {
          basePrice: -Math.round(feePrice * ownerCurrencyRatio * ownerUnitRatio),
          quantity: 1,
          totalPrice: -Math.round(feePrice * ownerCurrencyRatio * ownerUnitRatio),
          type: 'FEE',
        },
      ],
    },
    invoice: {
      currency: userCurrency,
      totalPrice: Math.round(totalPrice * userCurrencyRatio * userUnitRatio),
      serviceFee: Math.round(feePrice * userCurrencyRatio * userUnitRatio),
      numberOfDay: numberOfDay,
      smallerCurrencyUnit: userMinUnit,
      listProduct: [
        ...getCarInvoice(priceArray, userCurrencyRatio * userUnitRatio, car?.currency),
        {
          basePrice: Math.round(totalDeliveryAmount * userCurrencyRatio * userUnitRatio),
          quantity: 1,
          totalPrice: Math.round(totalDeliveryAmount * userCurrencyRatio * userUnitRatio),
          type: 'DELIVERY',
        },
        ...extraOption.map((option) => ({
          basePrice: Math.round(option.basePrice * userCurrencyRatio * userUnitRatio),
          quantity: option.quantity,
          totalPrice: Math.round(option.totalPrice * userCurrencyRatio * userUnitRatio),
          type: option.type,
          extra: option.extra,
        })),
        {
          basePrice: discountPercentage,
          quantity: 1,
          totalPrice: discountPrice,
          type: 'DISCOUNT',
        },
        ...(discount > 0
          ? [
              {
                basePrice: hardDiscount * userCurrencyRatio * userUnitRatio,
                quantity: 1,
                totalPrice: Math.round(hardDiscount * userCurrencyRatio * userUnitRatio),
                type: 'HARD_DISCOUNT',
              },
            ]
          : []),
        {
          basePrice: Math.round(feePrice * userCurrencyRatio * userUnitRatio),
          quantity: 1,
          totalPrice: Math.round(feePrice * userCurrencyRatio * userUnitRatio),
          type: 'FEE',
        },
      ],
    },
  };
}
