import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RefreshControl, useWindowDimensions } from 'react-native';

import { Box, Button, ButtonText, Center, FlatList, HStack, useToken, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CarBigCard from '../../shared/components/entities/car/carBigCard';
import Layout from '../../shared/components/layout/layout.js';
import PlaceHolder from '../../shared/components/placeHolder/placeHolder';
import UnderLineButton from '../../shared/components/pressable/underLineButton';
import Footer from '../../shared/components/web/footer';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider.js';
import { setDateRange, setFilters, setLocation } from '../../shared/reducers/search.reducer';
import { fetchCars } from '../../shared/services/carService.js'; // Assume this is a new service function you will create
import imagesUrl from '../../shared/themes/imagesUrl';
import { getUnique } from '../../shared/util/array-utils';
import { screenBreakPoint } from '../../shared/util/device-utils';
import { getFilterString } from '../../shared/util/filterUtils'; // A utility function to create filter strings
import { renderDone } from '../../shared/util/web.utils';

const SearchScreen = () => {
  const currency = useSelector((state) => state.users.currency);
  const location = useSelector((state) => state.search.location);
  const dateRange = useSelector((state) => state.search.dateRange);
  const filters = useSelector((state) => state.search.filters);

  const dispatch = useDispatch();

  const navigation = useNavigation();
  const { t } = useTranslation();

  const screenMargin = useToken('space', 'screenMargin');
  const space4 = useToken('space', '4');

  const maxWidth = useMaxWidth();

  const [cars, setCars] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [numberPages, setNumberPages] = useState(0);

  const { width } = useWindowDimensions();
  const nbColumn = useMemo(() => screenBreakPoint(width, [1, 2, 2, 3, 3]), [width]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        setLoading(true);
        const filterString = getFilterString(dateRange, filters);
        const { results, nbPages } = await fetchCars(filterString, location, page);
        setCars(page > 0 ? (prevCars) => getUnique([...prevCars, ...results], 'objectID') : results);
        setNumberPages(nbPages);
        renderDone();
      } catch (error) {
        console.error(error); // Consider a more user-friendly error handling
      } finally {
        setLoading(false);
      }
    };

    fetchSearchResults();
  }, [dateRange, filters, location, page]);

  const handleRefresh = () => {
    setPage(0); // Reset to the first page
  };

  const handleLoadMore = () => {
    if (page + 1 < numberPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePressCar = (car) => {
    navigation.navigate('CarDetail', {
      carId: car.objectID,
      ...dateRange,
      pickUpPlaceId: location?.pickUp?.placeId,
      returnPlaceId: location?.return?.placeId,
    });
  };

  function renderCar({ item, index }) {
    return (
      <Box flex={1} maxWidth={`${100 / nbColumn}%`}>
        <CarBigCard
          testID={'carItem'}
          car={item}
          currency={currency}
          onPress={() => handlePressCar(item)}
          dateRange={dateRange}
          location={location}
        />
      </Box>
    );
  }

  function clearAllFilter() {
    dispatch(setLocation({}));
    dispatch(setDateRange({}));
    dispatch(setFilters({}));
    navigation.navigate('Search');
  }

  const renderPlaceholder = () => (
    <Box m={'$screenMargin'}>
      <PlaceHolder
        image={{ uri: imagesUrl.empty }}
        title={t('placeHolder.noSearchTitle')}
        text={t('placeHolder.noSearchDescription')}
        element={
          <Button variant={'outline'} onPress={clearAllFilter}>
            <ButtonText>{t('search.clearAllFilter')}</ButtonText>
          </Button>
        }
      />
    </Box>
  );

  const skeleton = useCallback(() => {
    if (nbColumn === 3) {
      return (
        <VStack testID="searchSkeleton3" space={'md'}>
          <HStack space={'md'}>
            <Box flex={1}>
              <CarBigCard />
            </Box>
            <Box flex={1}>
              <CarBigCard />
            </Box>
            <Box flex={1}>
              <CarBigCard />
            </Box>
          </HStack>
          <HStack space={'md'}>
            <Box flex={1}>
              <CarBigCard />
            </Box>
            <Box flex={1}>
              <CarBigCard />
            </Box>
            <Box flex={1}>
              <CarBigCard />
            </Box>
          </HStack>
        </VStack>
      );
    } else if (nbColumn === 2) {
      return (
        <VStack testID="searchSkeleton2" space={'md'}>
          <HStack space={'md'}>
            <Box flex={1}>
              <CarBigCard />
            </Box>
            <Box flex={1}>
              <CarBigCard />
            </Box>
          </HStack>
          <HStack space={'md'}>
            <Box flex={1}>
              <CarBigCard />
            </Box>
            <Box flex={1}>
              <CarBigCard />
            </Box>
          </HStack>
        </VStack>
      );
    }
    return (
      <VStack testID="searchSkeleton1" space={'md'}>
        <HStack space={'md'}>
          <Box flex={1}>
            <CarBigCard />
          </Box>
        </HStack>
        <HStack space={'md'}>
          <Box flex={1}>
            <CarBigCard />
          </Box>
        </HStack>
        <HStack space={'md'}>
          <Box flex={1}>
            <CarBigCard />
          </Box>
        </HStack>
      </VStack>
    );
  }, [nbColumn]);

  return (
    <Layout
      isBottomBar
      title={location?.pickUp?.locality ? t('metaTag.homeTitleIn', { location: location?.pickUp?.locality }) : t('metaTag.homeTitle')}
      description={t('metaTag.homeDescription')}
      image={imagesUrl.homeImage}>
      <FlatList
        contentContainerStyle={{ paddingHorizontal: screenMargin, paddingVertical: space4, width: maxWidth, alignSelf: 'center' }}
        ItemSeparatorComponent={<Box m={'$2'} />}
        data={cars}
        renderItem={renderCar}
        keyExtractor={(item) => item.objectID}
        refreshControl={<RefreshControl refreshing={isLoading} onRefresh={handleRefresh} />}
        onEndReached={handleLoadMore}
        onEndReachedThreshold={0.5}
        numColumns={nbColumn}
        key={nbColumn}
        columnWrapperStyle={nbColumn > 1 ? { gap: space4 } : undefined}
        ListEmptyComponent={isLoading ? skeleton() : renderPlaceholder}
        ListFooterComponent={
          <VStack mt={'$8'}>
            {page + 1 < numberPages && (
              <Center m={'$4'}>
                <UnderLineButton hideLeftIcon={true} onPress={handleRefresh} text={t('global.loadMore')} />
              </Center>
            )}
            <Footer />
          </VStack>
        }
      />
    </Layout>
  );
};

export default SearchScreen;
