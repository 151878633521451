import React, { useCallback, useState } from 'react';

import { Box, useToken } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';

import ProfileCard from '../../shared/components/entities/user/ProfileCard';
import ProfileCardById from '../../shared/components/entities/user/ProfileCardById';
import FilterComponent from '../../shared/components/firestore/filterComponent';
import FirestoreList from '../../shared/components/firestore/firestoreList';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { Metrics } from '../../shared/themes';

const baseFilter = [{ field: 'isOwner', operator: '==', value: true }];

function BackofficeOwners() {
  const navigation = useNavigation();
  const [filters, setFilters] = useState(baseFilter);
  const maxWidth = useMaxWidth();
  const space4 = useToken('space', '4');
  const screenMargin = useToken('space', 'screenMargin');

  const renderItem = useCallback(
    ({ item }) => (
      <ProfileCardById userId={item?.uid} onPress={() => navigation.navigate('BackofficeOwnerDetail', { userId: item?.uid })} />
    ),
    [navigation],
  );

  return (
    <FirestoreList
      headerComponent={
        <FilterComponent
          containerStyles={{ alignSelf: 'center', width: maxWidth, px: screenMargin }}
          allowedFields={['uid', 'email', 'firstName', 'lastName']}
          baseFilters={baseFilter}
          filters={filters}
          setFilters={setFilters}
        />
      }
      keyExtractor={(item) => item?.uid}
      ItemSeparatorComponent={<Box my={'$2'} />}
      isConnected={true}
      collectionName={'users'}
      filters={filters}
      renderItem={renderItem}
      contentContainerStyle={{ alignSelf: 'center', width: maxWidth, paddingVertical: space4, paddingHorizontal: screenMargin }}
    />
  );
}

export default BackofficeOwners;
