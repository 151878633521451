import { AntDesign, Ionicons } from '@expo/vector-icons';
import { Button, ButtonText, Icon } from '@gluestack-ui/themed';
import { t } from 'i18next';

import appConfig from '../config/app-config';
import addCarFlowScreen from '../screen/addCarFlow/addCarFlowScreen';
import backofficeCar from '../screen/backoffice/backofficeCar';
import backOfficeHome from '../screen/backoffice/backOfficeHome';
import backofficeOwnerDetail from '../screen/backoffice/backofficeOwnerDetail';
import ownerCalendarScreen from '../screen/calendar/ownerCalendar-screen';
import carDetail from '../screen/car/carDetail';
import carPreviewScreen from '../screen/car/carDetailPreview';
import searchScreen from '../screen/car/search-screen';
import addCheckCarScreen from '../screen/checkCar/addCheckCar';
import checkCarScreen from '../screen/checkCar/checkCarScreen';
import LandingPagePro from '../screen/landingPage/landingPagePro';
import changePasswordScreen from '../screen/login/change-password-screen';
import DeleteAccountScreen from '../screen/login/delete-account';
import forgotPasswordScreen from '../screen/login/forgot-password-screen';
import LoginOrSignUpScreen from '../screen/login/loginOrSignUp';
import loginWithEmailScreen from '../screen/login/loginWithEmail-screen';
import phoneAuth from '../screen/login/phoneAuth';
import verificationCode from '../screen/login/verificationCode';
import ChatScreen from '../screen/message/chat';
import messageScreen from '../screen/message/messageScreen';
import NotFound from '../screen/notFound/notFound';
import insuranceCertificate from '../screen/owner/carDocuments/insurance/insuranceCertificate';
import insurancePolicy from '../screen/owner/carDocuments/insurance/insurancePolicy';
import insuranceScreen from '../screen/owner/carDocuments/insurance/insuranceScreen';
import OwnerCarsDocumentsScreen from '../screen/owner/carDocuments/ownerCarDocuments';
import addUserID from '../screen/owner/carDocuments/ownership/addUserID';
import authorizationOfUse from '../screen/owner/carDocuments/ownership/authorizationOfUse';
import ownershipScreen from '../screen/owner/carDocuments/ownership/ownershipScreen';
import registrationCard from '../screen/owner/carDocuments/ownership/registrationCard';
import technicalInspection from '../screen/owner/carDocuments/technicalInspection/technicalInspection';
import technicalInspectionScreen from '../screen/owner/carDocuments/technicalInspection/technicalInspectionScreen';
import OwnerCarsDetailScreen from '../screen/owner/ownerCarDetail';
import ownerCarEditListingScreen from '../screen/owner/ownerCarEditListing-screen';
import ownerCarEditListingItem from '../screen/owner/ownerCarEditListingItem';
import ownerCarsScreen from '../screen/owner/ownerCars-screen';
import ownerHomeScreen from '../screen/owner/ownerHome-screen';
import ownerRequestAdditionalPayment from '../screen/owner/ownerRequestAdditionalPayment';
import ownerReservationList from '../screen/owner/ownerReservationList';
import requestReservationScreen from '../screen/owner/requestDetail';
import additionalPayment from '../screen/payment/additionalPayment';
import additionalPaymentDetail from '../screen/payment/additionalPaymentDetail';
import cardDetail from '../screen/payment/cardDetail';
import ConfirmAndPay from '../screen/payment/confirmAndPay';
import addUserDocument from '../screen/profile/driverDocuments/addUserDocument';
import EditProfileScreen from '../screen/profile/edit-profile-screen';
import EditPublicProfileScreen from '../screen/profile/editPublicProfile';
import languageScreen from '../screen/profile/language-screen';
import profileScreen from '../screen/profile/profile-screen';
import publicProfile from '../screen/profile/publicProfile';
import RegisterScreen from '../screen/profile/register';
import AddQuoteFlow from '../screen/quote/addQuote';
import quoteDetail from '../screen/quote/quoteDetail';
import QuoteList from '../screen/quote/quoteList';
import rentalScreen from '../screen/reservation/reservation-screen';
import reservationDetail from '../screen/reservation/reservationDetail';
import addReview from '../screen/review/addReview';
import allReviews from '../screen/review/allReviews';
import CustomWebView from '../screen/webView/webView';
import MessageButton from '../shared/components/entities/message/messageButton';
import HeaderBar from '../shared/components/header/headerBar';
import HeaderBarOwner from '../shared/components/header/headerBarOwner';
import UnderLineButton from '../shared/components/pressable/underLineButton';
import { IS_WEB } from '../shared/util/device-utils';

const defaultScreenOption = {
  headerBackTitleVisible: false,
  presentation: 'card',
  headerTitleAlign: 'left',
  headerShown: true,
  // statusBar: {
  //   visible: true,
  //   style: 'light',
  // },
};

export const tabScreens = {
  search: {
    name: 'Search',
    route: '',
    component: searchScreen,
    options: {
      title: 'metaTag.homeTitle',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayBar={true} displayNav={false} />,
      tabBarTestID: 'bottomBarSearch',
      tabBarLabel: () => {
        return null;
      },
    },
    iconName: 'search1',
    iconLibrary: AntDesign,
  },
  message: {
    name: 'Message',
    route: 'message',
    component: messageScreen,
    options: {
      title: 'screen.message',
      ...defaultScreenOption,
      header: (props) => (
        <HeaderBar
          {...props}
          displayNav={true}
          rightElement={<MessageButton userToContact={appConfig.supportId} isSupport={true} size={'sm'} />}
        />
      ),
      tabBarTestID: 'bottomBarMessage',
      tabBarLabel: () => {
        return null;
      },
    },
    iconName: 'message1',
    iconLibrary: AntDesign,
  },
  calendar: {
    name: 'OwnerCalendar',
    route: 'calendar',
    component: ownerCalendarScreen,
    options: {
      title: 'screen.calendar',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} />,
      tabBarTestID: 'bottomBarOwnerCalendar',
      tabBarLabel: () => {
        return null;
      },
    },
    iconName: 'calendar',
    iconLibrary: AntDesign,
  },
  ownerCar: {
    name: 'OwnerCars',
    route: 'owner-car',
    component: ownerCarsScreen,
    options: {
      title: 'screen.ownerCar',
      ...defaultScreenOption,
      header: (props) => (
        <HeaderBar
          {...props}
          displayNav={true}
          rightElement={
            <UnderLineButton onPress={() => props?.navigation.navigate('QuoteTabs')} text={t('quote.seeAll')} hideLeftIcon={true} />
          }
        />
      ),
      tabBarTestID: 'bottomBarOwnerCars',
      tabBarLabel: () => {
        return null;
      },
    },
    iconName: 'car',
    iconLibrary: AntDesign,
  },
  ownerHome: {
    name: 'OwnerHome',
    route: 'owner-home',
    component: ownerHomeScreen,
    options: {
      title: 'screen.ownerHome',
      ...defaultScreenOption,
      header: (props) => (
        <HeaderBar
          {...props}
          displayNav={true}
          rightElement={
            <Button
              variant={'outline'}
              size={'md'}
              pt={0}
              pb={0}
              h={'$8'}
              onPress={() => props?.navigation.navigate('AddQuoteFlow')}
              startIcon={<Icon as={Ionicons} name={'add-outline'} color={'$black'} />}>
              <Icon as={Ionicons} name={'add'} />
              <ButtonText>{t('quote.createReservation')}</ButtonText>
            </Button>
          }
        />
      ),
      tabBarTestID: 'bottomBarOwnerHome',
      tabBarLabel: () => {
        return null;
      },
    },
    iconName: 'home',
    iconLibrary: AntDesign,
  },
  profile: {
    name: 'Profile',
    route: 'account',
    component: profileScreen,
    options: {
      title: 'screen.account',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} />,
      tabBarTestID: 'bottomBarProfile',
      tabBarLabel: () => {
        return null;
      },
    },
    iconName: 'user',
    iconLibrary: AntDesign,
  },
  rental: {
    name: 'Rental',
    route: 'rental',
    component: rentalScreen,
    options: {
      title: 'screen.reservations',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} />,
      tabBarTestID: 'bottomBarRental',
      tabBarLabel: () => {
        return null;
      },
    },
    iconName: 'car',
    iconLibrary: AntDesign,
  },
};

export const getTabRoutes = () => {
  const routes = {
    Search: tabScreens.search.route,
    Profile: tabScreens.profile.route,
    Message: tabScreens.message.route,
    OwnerCalendar: tabScreens.calendar.route,
    Rental: tabScreens.rental.route,
    OwnerHome: tabScreens.ownerHome.route,
    OwnerCars: tabScreens.ownerCar.route,
  };
  return routes;
};

export const registerScreens = [
  {
    name: 'Register',
    route: 'register',
    component: RegisterScreen,
    auth: false,
    options: {
      title: 'screen.createProfile',
      header: (props) => <HeaderBar {...props} displayNav={true} />,
    },
  },
  {
    name: 'CustomWebView',
    route: 'web-view',
    component: CustomWebView,
    auth: false,
    options: {
      title: 'screen.webView',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
];

export const screens = [
  {
    name: 'BackOfficeHome',
    route: 'back-office',
    subScreen: ['to-validate', 'owners', 'reservations'],
    component: backOfficeHome,
    auth: false,
    options: {
      title: 'screen.backOffice',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'LandingPagePro',
    route: 'pro',
    component: LandingPagePro,
    auth: false,
    options: {
      title: 'screen.landingPagePro',
      ...defaultScreenOption,
      header: (props) => <HeaderBarOwner {...props} displayNav={false} />,
    },
  },
  {
    name: 'BackofficeOwnerDetail',
    route: 'back-office/owner/:userId',
    component: backofficeOwnerDetail,
    auth: false,
    options: {
      title: 'screen.backOfficeOwner',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'BackOfficeCar',
    route: 'back-office/car-detail/:carId',
    component: backofficeCar,
    auth: false,
    options: {
      title: 'screen.backOfficeCar',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'OwnerCarsEditListingItem',
    route: 'owner-car-edit-item',
    component: ownerCarEditListingItem,
    auth: false,
    options: {
      title: 'screen.ownerCarEditItem',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={false} />,
    },
  },
  {
    name: 'QuoteTabs',
    route: 'quotes',
    component: QuoteList,
    auth: false,
    options: {
      title: 'screen.quotes',
      ...defaultScreenOption,
      header: (props) => (
        <HeaderBar
          {...props}
          displayNav={true}
          canGoBack={true}
          rightElement={
            <Button
              variant={'outline'}
              size={'md'}
              pt={0}
              pb={0}
              h={'$8'}
              onPress={() => props?.navigation.navigate('AddQuoteFlow')}
              startIcon={<Icon as={Ionicons} name={'add-outline'} color={'$black'} />}>
              <Icon as={Ionicons} name={'add'} />
              <ButtonText>{t('quote.createReservation')}</ButtonText>
            </Button>
          }
        />
      ),
    },
  },
  {
    name: 'AddQuoteFlow',
    route: 'add-quote',
    component: AddQuoteFlow,
    auth: false,
    options: {
      title: 'screen.addQuoteFlow',
      ...defaultScreenOption,
      header: (props) => <HeaderBarOwner {...props} displayNav={false} />,
    },
  },
  {
    name: 'QuoteDetail',
    route: 'quote/:quoteId',
    component: quoteDetail,
    auth: false,
    options: {
      title: 'quote.invoice',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'DeleteAccountScreen',
    route: 'delete-account',
    component: DeleteAccountScreen,
    auth: false,
    options: {
      title: 'screen.deleteAccount',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'CustomWebView',
    route: 'web-view',
    component: CustomWebView,
    auth: false,
    options: {
      title: 'screen.webView',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'VerificationCode',
    route: 'verification-code',
    component: verificationCode,
    auth: false,
    options: {
      title: 'screen.verificationCode',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'CarPreviewScreen',
    route: 'car-preview',
    component: carPreviewScreen,
    auth: false,
    options: {
      title: 'screen.previewCar',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={false} canGoBack={false} />,
    },
  },
  {
    name: 'EditProfileScreen',
    route: 'account/edit',
    component: EditProfileScreen,
    auth: false,
    options: {
      title: 'screen.editProfile',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'EditPublicProfileScreen',
    route: 'profile/edit',
    component: EditPublicProfileScreen,
    auth: false,
    options: {
      title: 'screen.editProfile',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'OwnerRequestAdditionalPayment',
    route: 'owner-request-aditional-payment',
    component: ownerRequestAdditionalPayment,
    auth: false,
    options: {
      title: 'screen.ownerRequestAdditionalPayment',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'AddUserDocument',
    route: 'add-user-document',
    component: addUserDocument,
    auth: false,
    options: {
      title: 'screen.addUserDocument',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'AdditionalPayment',
    route: 'additional-payment',
    component: additionalPayment,
    auth: false,
    options: {
      title: 'screen.additionalPayment',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'AdditionalPaymentDetail',
    route: 'additional-payment-detail',
    component: additionalPaymentDetail,
    auth: false,
    options: {
      title: 'screen.additionalPayment',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'CarDetail',
    route: 'car-detail/:carId',
    component: carDetail,
    options: {
      title: 'screen.carDetail',
      ...defaultScreenOption,
      headerShown: false,
      header: (props) => <HeaderBar {...props} displayNav={false} canGoBack={true} />,
    },
  },
  {
    name: 'ConfirmAndPay',
    route: 'confirm-and-pay',
    component: ConfirmAndPay,
    options: {
      title: 'screen.confirmAndPay',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'PublicProfileScreen',
    route: 'profile/:userId',
    subScreen: ['reviews', 'listings'],
    component: publicProfile,
    options: {
      title: 'screen.publicProfile',
      ...defaultScreenOption,
      headerShown: IS_WEB,
      header: (props) => <HeaderBar {...props} displayNav={false} canGoBack={true} />,
    },
  },
  {
    name: 'AllReview',
    route: 'reviews',
    component: allReviews,
    options: {
      title: 'screen.allReviews',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'ReservationDetail',
    route: 'reservation-detail',
    component: reservationDetail,
    options: {
      title: 'screen.reservationDetail',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={false} />,
      headerShown: false,
    },
  },
  {
    name: 'AddReview',
    route: 'add-review',
    component: addReview,
    options: {
      title: 'screen.addReview',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'LoginOrSignUp',
    route: 'login-or-sign-up',
    component: LoginOrSignUpScreen,
    auth: false,
    options: {
      title: 'screen.loginOrSignUp',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'LoginWithEmail',
    route: 'login-with-email',
    component: loginWithEmailScreen,
    options: {
      title: 'screen.loginWithEmail',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'ForgotPassword',
    route: 'reset-password',
    component: forgotPasswordScreen,
    auth: false,
    options: {
      title: 'screen.forgotPassword',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'ChangePassword',
    route: 'change-password',
    component: changePasswordScreen,
    auth: true,
    options: {
      title: 'screen.changePassword',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'LoginWithPhone',
    route: 'login-with-phone',
    component: phoneAuth,
    auth: false,
    options: {
      title: 'screen.loginWithPhone',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'AddCarFlow',
    route: 'add-car',
    component: addCarFlowScreen,
    options: {
      title: 'screen.addCar',
      ...defaultScreenOption,
      header: (props) => <HeaderBarOwner {...props} displayNav={false} />,
    },
    auth: false,
  },
  {
    name: 'LanguageScreen',
    route: 'language',
    component: languageScreen,
    options: {
      title: 'screen.language',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'CardDetail',
    route: 'card-detail',
    component: cardDetail,
    options: {
      title: 'screen.cardDetail',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'RequestDetailScreen',
    route: 'request-detail',
    component: requestReservationScreen,
    options: {
      title: 'screen.reservationDetail',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'OwnerCarsDetailScreen',
    route: 'owner-car-detail',
    component: OwnerCarsDetailScreen,
    options: {
      title: 'screen.ownerCarDetail',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'OwnerCarsEditListingScreen',
    route: 'owner-car-edit',
    component: ownerCarEditListingScreen,
    options: {
      title: 'screen.ownerCarEditListing',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'AddCheckCarScreen',
    route: 'check-car',
    component: addCheckCarScreen,
    options: {
      title: 'screen.addCheckCar',
      ...defaultScreenOption,
      headerShown: false,
      header: (props) => <HeaderBar {...props} />,
    },
  },
  {
    name: 'CheckCarScreen',
    route: 'check-car-screen',
    component: checkCarScreen,
    options: {
      title: 'screen.checkCarScreen',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'OwnerReservationList',
    route: 'owner-reservation-list',
    subScreen: ['current', 'up-comming', 'passed'],
    component: ownerReservationList,
    options: {
      title: 'screen.ownerReservationList',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'ChatScreen',
    route: 'chat',
    component: ChatScreen,
    options: {
      title: 'screen.chat',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'OwnerCarsDocumentsScreen',
    route: 'car/:carId/documents',
    component: OwnerCarsDocumentsScreen,
    options: {
      title: 'carDocuments.navTitle',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'OwnershipScreen',
    route: 'car/:carId/documents/ownership',
    component: ownershipScreen,
    options: {
      title: 'carDocuments.ownership.navTitle',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'InsuranceScreen',
    route: 'car/:carId/documents/insurance',
    component: insuranceScreen,
    options: {
      title: 'carDocuments.insurance.navTitle',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'TechnicalInspectionScreen',
    route: 'car/:carId/documents/technicalInspection',
    component: technicalInspectionScreen,
    options: {
      title: 'carDocuments.technicalInspection.navTitle',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'AddUserID',
    route: 'user/:userId/addId',
    component: addUserID,
    options: {
      title: 'carDocuments.ownership.myIdTitle',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'RegistrationCard',
    route: 'car/:carId/document/registration-card',
    component: registrationCard,
    options: {
      title: 'carDocuments.ownership.registrationCardTitle',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'AuthorizationOfUse',
    route: 'car/:carId/document/authorization-of-use',
    component: authorizationOfUse,
    options: {
      title: 'carDocuments.ownership.authorizationOfUseTitle',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'InsuranceCertificate',
    route: 'car/:carId/document/insurance-certificate',
    component: insuranceCertificate,
    options: {
      title: 'carDocuments.insurance.certificateTitle',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'InsurancePolicy',
    route: 'car/:carId/document/insurance-policy',
    component: insurancePolicy,
    options: {
      title: 'carDocuments.insurance.policyTitle',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
  {
    name: 'TechnicalInspection',
    route: 'car/:carId/document/technical-inspection',
    component: technicalInspection,
    options: {
      title: 'carDocuments.technicalInspection.technicalInspectionTitle',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
];

export const notFoundScreen = [
  {
    name: 'NotFound',
    route: '*',
    component: NotFound,
    auth: false,
    options: {
      title: 'screen.notFound',
      ...defaultScreenOption,
      header: (props) => <HeaderBar {...props} displayNav={true} canGoBack={true} />,
    },
  },
];

export const getScreenRoutes = () => {
  const routes = {};
  screens.forEach((screen) => {
    if (screen?.subScreen) {
      routes[screen.name] = {
        path: screen.route,
        screens: {
          ...screen?.subScreen.map((name) => {
            const subS = {};
            return (subS[name] = name);
          }),
          NotFound: '*',
        },
      };
    } else if (screen.route) {
      routes[screen.name] = screen.route;
    }
  });
  return routes;
};
