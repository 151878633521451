import React, { useCallback, useEffect } from 'react';

import { Link, LinkText, View } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { goBackOrDefault } from '../../navigation/nav-ref';
import { blogTradUrl, helpTradUrl, legalTradUrl } from '../../shared/util/url-utils';

function CustomWebView({ route, navigation }) {
  const { url, title } = route?.params ?? {};
  const currentUser = useSelector((state) => state.users.currentUser);

  const { t } = useTranslation();

  const getRightLang = useCallback(() => {
    if (!currentUser?.language) {
      return url;
    }
    const linkLang = ['en', 'es', 'fr', 'de'].includes(currentUser?.language) ? currentUser?.language : 'en';

    if (url.startsWith(blogTradUrl.en)) {
      return url.replace(blogTradUrl.en, blogTradUrl[linkLang]);
    } else if (url.startsWith(helpTradUrl.en)) {
      return url.replace(helpTradUrl.en, helpTradUrl[linkLang]);
    } else if (url.startsWith(legalTradUrl.en)) {
      return url.replace(legalTradUrl.en, legalTradUrl[linkLang]);
    }

    return url;
  }, [currentUser?.language, url]);

  useEffect(() => {
    console.debug('Effect: refresh car, navigation');
    if (title) {
      navigation.setOptions({ title: title });
    }
    window.open(getRightLang(), '_blank', 'noreferrer');
    setTimeout(() => goBackOrDefault('Search'), 10000);
  }, [getRightLang, navigation, title, url]);

  return (
    <View flex={1} justifyContent={'center'} alignItems={'center'}>
      <Link href={url}>
        <LinkText>{t('global.redirectTo', { title: title })}</LinkText>
      </Link>
    </View>
  );
}

export default CustomWebView;
