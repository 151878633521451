import React from 'react';

import { Box, Button, ButtonText, Divider, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import AppVersion from '../../../shared/components/info/appVersion';
import UnderLineButton from '../../../shared/components/pressable/underLineButton';
import { RentYourCar } from '../../../shared/components/static/rentYourCar';
import WebScrollView from '../../../shared/components/web/webScrollView';
import { useMaxWidth } from '../../../shared/provider/maxWidthProvider';
import { Metrics } from '../../../shared/themes';

export function ProfileNotConnectedScreen() {
  const navigation = useNavigation();
  const maxWidth = useMaxWidth();

  const { t } = useTranslation();

  return (
    <WebScrollView maxWidth={maxWidth > 700 ? 700 : maxWidth}>
      <VStack mt={'$4'} mx={'$screenMargin'} space={'md'}>
        <Text variant={'grey'} size={'secondary'} textAlign={'left'}>
          {t('profile.loginToBook')}
        </Text>
        <Button testID={'button.loginOrSignUp'} onPress={() => navigation.navigate('LoginOrSignUp')}>
          <ButtonText>{t('login.signIn')}</ButtonText>
        </Button>
        <HStack space={'sm'} w={'100%'} alignItems={'center'}>
          <Text textAlign={'left'}>{t('login.newUser')}</Text>
          <UnderLineButton onPress={() => navigation.navigate('LoginOrSignUp')} text={t('login.signUp')} hideLeftIcon={true} />
        </HStack>
        <Divider my="4" />
        <RentYourCar />
        <AppVersion />
      </VStack>
    </WebScrollView>
  );
}
