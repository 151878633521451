import React from 'react';

import { Text } from '@gluestack-ui/themed';
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';

import { IS_WEB } from '../../util/device-utils';

export default function AppVersion() {
  return (
    <Text mb={'$2'} variant={'grey'} size={'secondary'}>
      {IS_WEB ? Constants?.expoConfig?.version : Updates?.runtimeVersion + '/' + Updates?.channel + '/' + Constants?.expoConfig?.version}
    </Text>
  );
}
