import React, { useState } from 'react';

import {
  Box,
  Button,
  ButtonSpinner,
  ButtonText,
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  Heading,
  Input,
  InputField,
  Text,
  VStack,
} from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { goBackOrDefault } from '../../../navigation/nav-ref';
import BottomAction from '../../../shared/components/bottomAction/bottomAction';
import RegionPicker from '../../../shared/components/countryPicker/countryPicker';
import { errorHandler } from '../../../shared/components/errorHandler/errorHander';
import MediaUploader from '../../../shared/components/media/mediaUploader';
import errorToast from '../../../shared/components/toast/errorToast';
import WebScrollView from '../../../shared/components/web/webScrollView';
import DatePicker from '../../../shared/generic/DatePicker';
import { addUserDocuments } from '../../../shared/services/firebase/user-firebase';
import { userDocumentStatus, userDocumentType } from '../../../shared/util/userDocument-utils';

function AddUserDocumentsDetail() {
  const currentUser = useSelector((state) => state.users.currentUser);

  const [document, setDocument] = useState({
    deliverTime: new Date().getTime() / 1000,
    status: userDocumentStatus.waintingApproval,
    type: userDocumentType.driverLicence,
  });
  const [loading, setLoading] = useState(false);
  const [bottomActionHeight, setBottomActionHeight] = useState(0);

  const { t } = useTranslation();

  function valid() {
    const isValid = document?.deliverTime < new Date().getTime() / 1000;
    return (
      document?.number &&
      isValid &&
      document?.countryCode &&
      document?.images &&
      document?.images?.length > 0 &&
      document?.firstName &&
      document?.lastName
    );
  }

  function saveDocuments() {
    if (valid()) {
      setLoading(true);
      addUserDocuments(currentUser?.uid, document)
        .then(() => {
          setLoading(false);
          goBackOrDefault('Profile');
        })
        .catch((e) => {
          errorHandler(e, true);
          setLoading(false);
        });
    } else {
      errorToast(t('error.completeForm'));
    }
  }

  function updateImages(images) {
    const cleanImages = images.filter((image) => image?.url !== undefined);
    if (cleanImages && cleanImages?.length > 0) {
      setDocument((prevDoc) => ({ ...prevDoc, images: cleanImages }));
    }
  }

  return (
    <>
      <WebScrollView testID={'scrollView'}>
        <VStack my={'$4'} mx={'$screenMargin'} space={'md'} mb={bottomActionHeight}>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('login.firstName')}</FormControlLabelText>
            </FormControlLabel>
            <Input>
              <InputField
                testID="input.firstName"
                defaultValue={document?.firstName}
                onChangeText={(value) => setDocument({ ...document, firstName: value })}
              />
            </Input>
          </FormControl>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('login.lastName')}</FormControlLabelText>
            </FormControlLabel>
            <Input>
              <InputField
                testID="input.lastName"
                defaultValue={document?.lastName}
                onChangeText={(value) => setDocument({ ...document, lastName: value })}
              />
            </Input>
          </FormControl>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('driverDocument.driverLicenceNumber')}</FormControlLabelText>
            </FormControlLabel>
            <Input>
              <InputField
                testID="input.idNumber"
                defaultValue={document?.number}
                onChangeText={(value) => setDocument({ ...document, number: value })}
              />
            </Input>
          </FormControl>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('driverDocument.deliverTime')}</FormControlLabelText>
            </FormControlLabel>
            <DatePicker
              title={t('driverDocument.deliverTime')}
              maximumDate={new Date(new Date().getFullYear(), 11, 31)}
              date={document?.deliverTime ? new Date(document?.deliverTime * 1000) : undefined}
              onConfirm={(date) => setDocument((prev) => ({ ...prev, deliverTime: date.getTime() / 1000 }))}
            />
          </FormControl>
          <RegionPicker
            title={t('login.country')}
            defaultCountryCode={document?.countryCode}
            placeHolder={t('login.countryPlaceHolder')}
            setCountryData={(cData) => setDocument({ ...document, countryCode: cData.code, countryFlag: cData.flag })}
          />
          <VStack space={'sm'}>
            <Heading>{t('profile.driverLicenceTitle')}</Heading>
            <Text variant={'grey'} size={'secondary'}>
              {t('profile.driverLicenceSubTitle')}
            </Text>
            <MediaUploader
              testID={'idDocument'}
              initialList={[]}
              onUpdate={(images) => updateImages(images)}
              storagePath={'user-documents'}
              multipleSelectionAllow={true}
            />
          </VStack>
        </VStack>
      </WebScrollView>
      <BottomAction onLayout={(event) => setBottomActionHeight(event?.nativeEvent?.layout?.height ?? 0)}>
        <Box mx={'$screenMargin'} my={'$4'}>
          <Button testID="button.addDocument" isDisabled={loading || !valid()} onPress={saveDocuments}>
            {loading && <ButtonSpinner mr="$1" />}
            <ButtonText>{t('driverDocument.addDocument')}</ButtonText>
          </Button>
        </Box>
      </BottomAction>
    </>
  );
}

export default AddUserDocumentsDetail;
