import React, { useEffect, useState } from 'react';

import {
  Button,
  ButtonSpinner,
  ButtonText,
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  Input,
  InputField,
  VStack,
} from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import CardContainer from '../../shared/components/container/cardContainer';
import UserConnectionListener from '../../shared/components/entities/user/userConnectionListener';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Layout from '../../shared/components/layout/layout';
import errorToast from '../../shared/components/toast/errorToast';
import { userCurrentRequest } from '../../shared/reducers/user.reducer';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';

function LoginWithEmailScreen({ navigation, route }) {
  const { email, isNew } = route?.params ?? {};

  // setup error state for displaying error messages
  const [formData, setData] = useState({ email: email });
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);

  const currentUser = useSelector((state) => state.users.currentUser);
  const lastRedirectableRoute = useSelector((state) => state.users.lastRedirectableRoute);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    console.debug('LogWE: Effect:refresh currentUser, to: ', lastRedirectableRoute?.name);
    if (currentUser?.uid && currentUser?.conditionAccepted) {
      if (lastRedirectableRoute) {
        navigation.navigate(lastRedirectableRoute?.name, lastRedirectableRoute?.params);
      } else {
        navigation.popToTop();
      }
      setLoading(false);
    }
  }, [currentUser, lastRedirectableRoute, navigation]);

  // set up validation schema for the form
  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email().label('Email'),
    password: Yup.string().required().min(6).label('Password'),
  });

  const onSubmit = async () => {
    validationSchema
      .validate(formData, { abortEarly: true })
      .then((valid) => {
        setLoading(true);
        if (isNew) {
          firebaseApp
            .auth()
            .createUserWithEmailAndPassword(formData.email, formData.password)
            .then((user) => {
              setUserId(user.user.uid);
            })
            .catch((e) => {
              errorHandler(e, true);
              setLoading(false);
            });
        } else {
          firebaseApp
            .auth()
            .signInWithEmailAndPassword(formData.email, formData.password)
            .then((u) => dispatch(userCurrentRequest(u.user.uid)))
            .catch((e) => {
              errorHandler(e, true);
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        errorHandler(err, false);
        setLoading(false);
        errorToast(err?.errors[0]);
      });
  };

  return (
    <Layout>
      <CardContainer>
        <VStack space={'md'} my={'$4'} mx={'$screenMargin'}>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('login.email')}</FormControlLabelText>
            </FormControlLabel>
            <Input>
              <InputField
                isReadOnly={true}
                isDisabled={true}
                inputMode="email"
                placeholder="car@flyncar.com"
                defaultValue={email ? email : ''}
                onChangeText={(value) => setData({ ...formData, email: value })}
              />
            </Input>
          </FormControl>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('login.password')}</FormControlLabelText>
            </FormControlLabel>
            <Input>
              <InputField testID="input.password" type="password" onChangeText={(value) => setData({ ...formData, password: value })} />
            </Input>
            {!isNew ? (
              <Button
                onPress={() => navigation.navigate('ForgotPassword', { email: formData?.email })}
                justifyContent={'flex-end'}
                variant="link">
                <ButtonText>{t('login.forgetPassword')}</ButtonText>
              </Button>
            ) : null}
          </FormControl>
          <Button testID="button.login" isDisabled={loading} onPress={onSubmit}>
            {loading && <ButtonSpinner mr="$1" />}
            <ButtonText>{isNew ? t('login.createAccount') : t('login.signIn')}</ButtonText>
          </Button>
          <UserConnectionListener userId={userId} />
        </VStack>
      </CardContainer>
    </Layout>
  );
}

export default LoginWithEmailScreen;
