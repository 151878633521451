export const defaultCheckCarSteps = [
  {
    part: 'STEP_1',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/car_clockwise.webp',
    isStep: true,
    title: 'checkCar.step1Title',
    subTitle: 'checkCar.step1SubTitle',
    text: 'checkCar.step1Text',
    image: 'https://assets.flyandcar.com/assets/check_car/car_clockwise.webp',
    buttonText: 'checkCar.step1ButtonText',
  },
  {
    part: 'RIGHT',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/right.webp',
    type: 'OUTSIDE',
  },
  {
    part: 'BACK_RIGHT',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/back_right.webp',
    skippable: true,
    type: 'OUTSIDE',
  },
  {
    part: 'BACK',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/back.webp',
    type: 'OUTSIDE',
  },
  {
    part: 'UNDER_BACK',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/under_back.webp',
    type: 'OUTSIDE',
    skippable: true,
  },
  {
    part: 'BACK_LEFT',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/back_left.webp',
    type: 'OUTSIDE',
    skippable: true,
  },
  {
    part: 'LEFT',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/left.webp?tto=a',
    type: 'OUTSIDE',
  },
  {
    part: 'FRONT_LEFT',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/front_left.webp',
    type: 'OUTSIDE',
    skippable: true,
  },
  {
    part: 'FRONT',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/front.webp',
    type: 'OUTSIDE',
  },
  {
    part: 'UNDER_FRONT',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/under_front.webp',
    type: 'OUTSIDE',
    skippable: true,
  },
  {
    part: 'FRONT_RIGHT',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/front_right.webp',
    type: 'OUTSIDE',
    skippable: true,
  },
  {
    part: 'ROOF',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/roof.webp',
    type: 'OUTSIDE',
    skippable: true,
  },
  {
    part: 'STEP_2',
    placeHolder: 'https://pixy.org/src2/578/5782085.jpg',
    isStep: true,
    title: 'checkCar.step2Title',
    subTitle: 'checkCar.step2SubTitle',
    text: 'checkCar.step2Text',
    image: 'https://assets.flyandcar.com/assets/check_car/inside_clockwise.webp',
    buttonText: 'checkCar.step2ButtonText',
  },
  {
    part: 'PASSENGER_DOOR',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/passenger_door.webp',
    type: 'INSIDE',
    skippable: true,
  },
  {
    part: 'PASSENGER_SEAT',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/passenger_seat.webp',
    type: 'INSIDE',
  },
  {
    part: 'BACK_RIGHT_DOOR',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/back_right_door.webp',
    type: 'INSIDE',
    skippable: true,
  },
  {
    part: 'BACK_RIGHT_SEAT',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/back_right_seat.webp',
    type: 'INSIDE',
  },
  {
    part: 'TRUNK',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/trunk.webp',
    type: 'INSIDE',
    skippable: true,
  },
  {
    part: 'BACK_LEFT_DOOR',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/back_left_door.webp',
    type: 'INSIDE',
    skippable: true,
  },
  {
    part: 'BACK_LEFT_SEAT',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/back_left_seat.webp',
    type: 'INSIDE',
    skippable: true,
  },
  {
    part: 'DRIVER_DOOR',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/driver_door.webp',
    type: 'INSIDE',
    skippable: true,
  },
  {
    part: 'DRIVER_SEAT',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/driver_seat.webp',
    type: 'INSIDE',
  },
  {
    part: 'CENTER_CONSOLE',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/center_console.webp',
    type: 'INSIDE',
    skippable: true,
  },
  {
    part: 'INFOTAINMENT_SYSTEM',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/infotainment_system.webp',
    type: 'INSIDE',
    skippable: true,
  },
  {
    part: 'DASHBOARD',
    placeHolder: 'https://assets.flyandcar.com/assets/check_car/dashboard.webp',
    type: 'INSIDE',
  },
  {
    part: 'UPLOAD',
    isUpload: true,
    title: 'checkCar.uploadTitle',
    subTitle: 'checkCar.uploadSubTitle',
    image: 'https://assets.flyandcar.com/assets/check_car/back_left_door.webp',
    buttonText: 'checkCar.uploadButtonText',
  },
];

export const checkCarTypes = {
  preCheck: 'PRE_CHECK',
  signIn: 'SIGN_IN',
  postCheck: 'POST_CHECK',
  signOut: 'SIGN_OUT',
  finished: 'FINISHED',
};
