/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { Platform, TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import {
  Box,
  Button,
  ButtonText,
  Divider,
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  Heading,
  HStack,
  Icon,
  ScrollView,
  Text,
  VStack,
} from '@gluestack-ui/themed';
import { getLocales } from 'expo-localization';
import { CountryList, CountryPicker } from 'react-native-country-codes-picker';
import { countryCodes } from 'react-native-country-codes-picker/constants/countryCodes';
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';

import { IS_WEB } from '../../util/device-utils';
import { supportedLang } from '../../util/language-utils';

export default function RegionPicker({
  displayPhone,
  placeHolder,
  title,
  setCountryData,
  setCountryCode,
  setPhoneCode,
  defaultCountryCode,
}) {
  const [isOpen, setOpen] = useState(false);
  const [country, setCountry] = useState();
  const { top } = useSafeAreaInsets();

  const lang = supportedLang.includes(getLocales()[0]?.languageCode) ? getLocales()[0]?.languageCode : 'en';

  const defCountry = countryCodes.filter((i) => i.code === defaultCountryCode);

  function press(item) {
    setOpen(false);
    setCountry(item);
    if (setCountryCode) {
      setCountryCode(item.code);
    }
    if (setCountryData) {
      setCountryData(item);
    }
    if (setPhoneCode) {
      setPhoneCode(item.dial_code);
    }
  }

  const renderItem = ({ item, name, style, rest }) => (
    <TouchableOpacity
      testID={`countryPicker.item.${item.name.en}`}
      key={item.name.en}
      onPress={() => {
        press(item);
        setCountry({ ...item, oName: name });
      }}>
      <HStack p={'$4'} space={'md'} my={'$1'} rounded={'$md'} backgroundColor={'$light100'}>
        <Text w={30}>{item.flag}</Text>
        {displayPhone && <Text w={50}>{item.dial_code}</Text>}
        <Text w={300}>{name}</Text>
      </HStack>
    </TouchableOpacity>
  );

  function displayButtonText(it, l) {
    if (displayPhone) {
      return it.flag + ' ' + it.dial_code;
    }
    return it.flag + ' ' + it.name[l];
  }

  function ListHeaderComponent({ countries, locale }) {
    return (
      <Box style={{ marginTop: Platform.OS === 'ios' ? top : 0 }}>
        <TouchableOpacity onPress={() => setOpen(false)}>
          <Icon as={Ionicons} name={'close-outline'} size={'xl'} />
        </TouchableOpacity>
        {countries?.map((country, index) => renderItem({ item: country, name: country?.name?.[locale || 'en'] }))}
        <Divider my={'$4'} />
      </Box>
    );
  }

  return (
    <>
      <FormControl>
        {!displayPhone && (
          <FormControlLabel>
            <FormControlLabelText>{title}</FormControlLabelText>
          </FormControlLabel>
        )}
        {IS_WEB && isOpen && (
          <ScrollView height={300}>
            <CountryList
              style={{
                modal: {
                  height: '50%',
                  backgroundColor: 'white',
                  alignSelf: 'center',
                },
              }}
              itemTemplate={renderItem}
              onBackdropPress={() => setOpen(false)}
              lang={lang}
              show={isOpen}
              popularCountries={['FR', 'US', 'CR', 'GB', 'DE', 'ES']}
              ListHeaderComponent={ListHeaderComponent}
              // when picker button press you will get the country object with dial code
              pickerButtonOnPress={press}
            />
          </ScrollView>
        )}
        {((!isOpen && IS_WEB) || !IS_WEB) && (
          <Button testID="button.regionPicker" variant={'outline'} onPress={() => setOpen(true)} minW={100}>
            <ButtonText>
              {country
                ? displayButtonText(country, lang)
                : defCountry?.length && defCountry?.length > 0
                  ? displayButtonText(defCountry[0], lang)
                  : placeHolder}
            </ButtonText>
          </Button>
        )}
      </FormControl>
      {!IS_WEB && (
        <CountryPicker
          style={{
            modal: {
              height: '50%',
              backgroundColor: 'white',
              alignSelf: 'center',
            },
          }}
          itemTemplate={renderItem}
          onBackdropPress={() => setOpen(false)}
          lang={lang}
          show={isOpen}
          popularCountries={['FR', 'US', 'CR', 'GB', 'DE', 'ES']}
          ListHeaderComponent={ListHeaderComponent}
          // when picker button press you will get the country object with dial code
          pickerButtonOnPress={press}
        />
      )}
    </>
  );
}
