import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowDimensions, View } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Button, ButtonText, Divider, Heading, HStack, Icon, Text, useToken, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { LineChart } from 'react-native-chart-kit';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';

import appConfig from '../../../config/app-config';
import ActionSheet from '../../../shared/components/actionSheet/actionSheet';
import BottomSheetFooter from '../../../shared/components/bottomSheet/BottomSheetFooter';
import InputPrice from '../../../shared/components/input/inputPrice';
import KeyboardAvoiding from '../../../shared/components/keyboardAvoiding/keyboardAvoiding';
import { PressableRow } from '../../../shared/components/pressable/pressableRow';
import UnderLineButton from '../../../shared/components/pressable/underLineButton';
import UnderstandPrice from '../../../shared/components/static/understandPrice';
import WheelPicker from '../../../shared/generic/wheelPicker';
import { carAddingSetter } from '../../../shared/reducers/car.reducer';
import { formatCurrency, getSymbol } from '../../../shared/util/currency';
import { dayOfYearToString } from '../../../shared/util/date-transforms';
import { IS_WEB, screenBreakPoint } from '../../../shared/util/device-utils';

const defaultPrice = -1;

function SeasonalPrice() {
  const car = useSelector((state) => state.cars.carAdding);
  const currency = useSelector((state) => state.users.currency);

  const dispatch = useDispatch();

  const setCar = useCallback((car) => dispatch(carAddingSetter(car)), [dispatch]);

  const [isOpen, setOpen] = useState(false);
  const [from, setFrom] = useState({ day: 0, month: 0 });
  const [to, setTo] = useState({ day: 30, month: 11 });
  const [editRange, setEditRange] = useState({});

  const { t } = useTranslation();
  const { bottom } = useSafeAreaInsets();
  const { width } = useWindowDimensions();
  const maxWidth = useMemo(() => screenBreakPoint(width, [width, width, width, 990, 1080]), [width]);

  const colorLight400 = useToken('colors', 'light400');
  const colorPrimary400 = useToken('colors', 'primary400');
  const colorBlack = useToken('colors', 'black');
  const colorDanger200 = useToken('colors', 'error200');

  const data = useMemo(() => {
    const allDay = [];

    let min = 10000000000;
    let max = 0;

    for (let index = 1; index <= 366; index++) {
      const range = [
        ...car?.priceData?.filter((r) => {
          if (r.from < r.to && index >= r.from && index <= r.to) {
            return true;
          }
          if (r.from > r.to && (index >= r.from || index <= r.to)) {
            return true;
          } else {
            return false;
          }
        }),
      ].shift() ?? { price: defaultPrice };

      const price = range.price * currency[currency.current];
      allDay.push(price);

      if (min > price) {
        min = price;
      }
      if (max < price) {
        max = price;
      }
    }

    return {
      max: max * 1.1,
      min: min * 0.9,
      datasets: allDay,
      avgPrice: allDay.length > 0 ? allDay.reduce((a, b) => a + b) / currency[currency.current] / allDay.length : 0,
    };
  }, [car?.priceData, currency]);

  useEffect(() => {
    const dFrom = new Date(2024, from?.month, (from?.day ?? 0) + 1);
    const dayOfYearFrom = Math.floor((dFrom - new Date(dFrom.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
    const dTo = new Date(2024, to?.month, to?.day + 1);
    const dayOfYearTo = Math.floor((dTo - new Date(dTo.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
    setEditRange((prev) => ({ ...prev, to: dayOfYearTo, from: dayOfYearFrom }));
  }, [from, to]);

  function toEditRange(range) {
    const nextFrom = range?.from ?? 1;
    const nextTo = range?.to ?? 366;
    const nextFromDate = new Date(2024, 0, nextFrom);
    const nextToDate = new Date(2024, 0, nextTo);
    setFrom({ day: nextFromDate.getDate() - 1, month: nextFromDate.getMonth() });
    setTo({ day: nextToDate.getDate() - 1, month: nextToDate.getMonth() });
    setEditRange(range ?? { from: nextFrom, to: nextTo });
    setOpen(true);
  }

  const addEditedRange = useCallback(() => {
    const nextRange = [];

    const nextData = data.datasets.map((price, index) => {
      if (editRange.from < editRange.to && index + 1 >= editRange.from && index + 1 <= editRange.to) {
        return editRange.price * currency[currency?.current] ?? defaultPrice;
      }
      if (editRange.from > editRange.to && (index + 1 >= editRange.from || index + 1 <= editRange.to)) {
        return editRange.price * currency[currency?.current] ?? defaultPrice;
      } else {
        return price;
      }
    });

    let currentPrice = nextData.shift();
    let lastIndex = 0;
    nextData.forEach((price, index) => {
      if (currentPrice !== price) {
        nextRange.push({ from: lastIndex + 1, to: index + 1, price: currentPrice / currency[currency.current] });
        currentPrice = price;
        lastIndex = index + 1;
      }
    });
    nextRange.push({ from: lastIndex + 1, to: 366, price: currentPrice / currency[currency.current] });
    setCar({ ...car, priceData: nextRange });
    setOpen(false);
  }, [car, currency, data.datasets, editRange.from, editRange.price, editRange.to, setCar]);

  const renderFooter = useCallback(
    (props) => (
      <BottomSheetFooter {...props} bottomInset={bottom}>
        <Box mx={'$screenMargin'} backgroundColor={'$white'} mb={-bottom} paddingBottom={bottom}>
          <Button
            testID="button.confirm"
            my={'$2'}
            onPress={addEditedRange}
            isDisabled={!editRange?.price || !editRange?.from || !editRange?.to}>
            <ButtonText>{t('global.save')}</ButtonText>
          </Button>
        </Box>
      </BottomSheetFooter>
    ),
    [bottom, addEditedRange, editRange?.price, editRange?.from, editRange?.to, t],
  );

  const dayData = useMemo(() => {
    const day = Array.from({ length: 31 }).map((_, i) => i + 1);
    return day.map((d, i) => ({ title: d.toString(), value: i }));
  }, []);

  const monthData = useMemo(() => {
    const month = [
      t('month.jan'),
      t('month.feb'),
      t('month.mar'),
      t('month.apr'),
      t('month.may'),
      t('month.jun'),
      t('month.jul'),
      t('month.aug'),
      t('month.sep'),
      t('month.oct'),
      t('month.nov'),
      t('month.dec'),
    ];
    return month.map((m, i) => ({ title: m, value: i }));
  }, [t]);

  const maskedComponents = (
    <>
      <View
        style={{
          height: 30 * Math.trunc(3 / 2),
          backgroundColor: colorLight400,
        }}
      />
      <View style={{ height: 30, backgroundColor: colorBlack }} />
      <View
        style={{
          height: 30 * Math.trunc(3 / 2),
          backgroundColor: colorLight400,
        }}
      />
    </>
  );

  return (
    <KeyboardAvoiding maxWidth={maxWidth > 700 ? 700 : maxWidth}>
      <VStack space={'md'} w={'$full'}>
        <VStack space={'md'} mx={'$screenMargin'}>
          <Heading size={'h1'} mt={'$4'} mb={'$4'} numberOfLines={2}>
            {t('addCar.setSeasonalPrice')}
          </Heading>
          <Text mb={'$4'} variant={'grey'} size={'secondary'}>
            {t('addCar.seasonalPriceSubTitle')}
          </Text>
          <HStack space={'sm'} justifyContent={'space-between'}>
            <Text color={'$black'} size={'primary'}>
              {t('addCar.avgPice')}
            </Text>
            <Text fontWeight={'$bold'}>{formatCurrency(Math.round(data.avgPrice * currency[currency?.current]), currency.current)}</Text>
          </HStack>
          <UnderstandPrice>
            <HStack alignItems={'center'} space={'sm'}>
              <Text color={'$black'} size={'secondary'} fontWeight={'$bold'} underline>
                {t('addCar.driverWillPay')}
              </Text>
              <Text size={'secondary'} fontWeight={'$bold'}>
                {formatCurrency(Math.round(data.avgPrice * currency[currency?.current] * appConfig.serviceFee), currency.current)}
              </Text>
            </HStack>
          </UnderstandPrice>
        </VStack>
        <LineChart
          data={{
            labels: [
              t('month.jan'),
              t('month.feb'),
              t('month.mar'),
              t('month.apr'),
              t('month.may'),
              t('month.jun'),
              t('month.jul'),
              t('month.aug'),
              t('month.sep'),
              t('month.oct'),
              t('month.nov'),
              t('month.dec'),
            ],
            datasets: [
              {
                data: data.datasets,
                withDots: false,
              },
              {
                data: [data.min < 0 ? 0 : data.min],
                withDots: false,
              },
              {
                data: [data.max],
                withDots: false,
              },
            ],
          }}
          withInnerLines={false}
          width={maxWidth === width ? maxWidth : maxWidth * 0.65} // from react-native
          height={200}
          yAxisLabel={getSymbol(0, currency?.current)}
          yAxisSuffix=""
          verticalLabelRotation={IS_WEB ? 0 : -30}
          horizontalLabelRotation={0}
          chartConfig={{
            backgroundColor: 'rgba(0,0,0,0)',
            backgroundGradientFromOpacity: 0,
            backgroundGradientToOpacity: 0,
            decimalPlaces: 0, // optional, defaults to 2dp
            color: (opacity = 1) => colorPrimary400,
            labelColor: (opacity = 1) => colorBlack,
            propsForVerticalLabels: {
              color: colorDanger200,
            },
          }}
          bezier
          style={{}}
        />
        <VStack space={'md'} mx={'$screenMargin'}>
          <HStack justifyContent={'space-between'} alignItems={'center'} space={'$4'}>
            <Heading>{t('addCar.seasonalPrice')}</Heading>
            <Box w={'40%'} alignItems="flex-end">
              <UnderLineButton testID={'addSeasonalPrice'} text={t('addCar.addSeasonalPrice')} hideLeftIcon={true} onPress={toEditRange} />
            </Box>
          </HStack>
          <VStack>
            {car?.priceData?.map((range) => (
              <VStack key={range.from + ' ' + range.to + ' ' + range.price}>
                <PressableRow
                  onPress={() => toEditRange(range)}
                  text={`${dayOfYearToString(range.from)} ${t('filter.to')} ${dayOfYearToString(range.to)}`}
                  infoElement={
                    <Text fontWeight={'$bold'}>
                      {formatCurrency(Math.round(range?.price * currency[currency?.current]), currency.current)}
                    </Text>
                  }
                />
                <Divider />
              </VStack>
            ))}
          </VStack>
        </VStack>
        <ActionSheet
          isOpen={isOpen}
          setOpen={setOpen}
          headerText={t('addCar.seasonalPrice')}
          scrollDisabled={true}
          renderFooter={renderFooter}>
          <VStack space={'md'} mx={'$screenMargin'}>
            <Text mb={'$4'}>{t('addCar.seasonalPriceEditSubTitle')}</Text>
            <HStack space={'sm'} justifyContent={'space-evenly'} alignItems={'center'}>
              <Text fontWeight={'$bold'} maxWidth={'40%'} alignSelf={'center'} textAlign={'center'}>
                {t('addCar.seasonalPriceFrom')}
              </Text>
              <Text fontWeight={'$bold'} maxWidth={'40%'} alignSelf={'center'} textAlign={'center'}>
                {t('addCar.seasonalPriceTo')}
              </Text>
            </HStack>
            <HStack space={'sm'} justifyContent={'space-between'} alignItems={'center'}>
              <HStack space={'xs'} maxWidth={'40%'}>
                <WheelPicker
                  testID={'wheel.fromDay'}
                  initialIndex={from?.day}
                  data={dayData}
                  onSelected={(item) => setFrom((prev) => ({ ...prev, day: item?.value }))}
                />
                <WheelPicker
                  testID={'wheel.fromMonth'}
                  initialIndex={from?.month}
                  data={monthData}
                  onSelected={(item) => setFrom((prev) => ({ ...prev, month: item?.value }))}
                />
              </HStack>
              <Icon as={Ionicons} name={'chevron-forward-outline'} />
              <HStack space={'xs'} maxWidth={'40%'}>
                <WheelPicker
                  testID={'wheel.toDay'}
                  initialIndex={to?.day}
                  data={dayData}
                  onSelected={(item) => setTo((prev) => ({ ...prev, day: item?.value }))}
                />
                <WheelPicker
                  testID={'wheel.toMonth'}
                  initialIndex={to?.month}
                  data={monthData}
                  onSelected={(item) => setTo((prev) => ({ ...prev, month: item?.value }))}
                />
              </HStack>
            </HStack>
            <VStack space={'sm'}>
              <Text>{t('addCar.price')}</Text>
              <InputPrice
                testID={'input.price'}
                isBottomSheet={true}
                w={'100%'}
                currency={currency}
                dolarPrice={editRange?.price}
                placeHolder={t('addCar.price')}
                onChangePrice={(value) => setEditRange({ ...editRange, price: value, currency: 'USD' })}
              />
              <UnderstandPrice>
                <HStack alignItems={'center'} space={'sm'}>
                  <Text color={'$black'} size={'secondary'} fontWeight={'$bold'} underline>
                    {t('addCar.driverWillPay')}
                  </Text>
                  <Text size={'secondary'} fontWeight={'$bold'}>
                    {formatCurrency(
                      editRange?.price > 0 ? Math.round(editRange?.price * currency[currency?.current] * appConfig.serviceFee) : 0,
                      currency.current,
                    )}
                  </Text>
                </HStack>
              </UnderstandPrice>
            </VStack>
          </VStack>
        </ActionSheet>
      </VStack>
    </KeyboardAvoiding>
  );
}

export default SeasonalPrice;
