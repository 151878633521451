import React, { useCallback, useEffect, useState } from 'react';
import { RefreshControl } from 'react-native';

import { Center, FlatList, Spinner, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { firebaseApp } from '../../services/firebase/firebaseFactory';
import { errorHandler } from '../errorHandler/errorHander';
import UnderLineButton from '../pressable/underLineButton';

const FirestoreList = ({
  isConnected,
  collectionName,
  filters,
  renderItem,
  contentContainerStyle,
  orderBy,
  limit = 10,
  headerComponent,
  keyExtractor,
  ItemSeparatorComponent,
  ListEmptyComponent,
  ListFooterComponent,
  ListFooterNoEmptyComponent,
  transformer,
  numColumns = 1,
}) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const currentUser = useSelector((state) => state.users.currentUser);

  const { t } = useTranslation();

  function setLastItem(lastItemQueried, numberOfItemReturned) {
    if (numberOfItemReturned < limit) {
      setLastVisible(null);
    } else {
      setLastVisible(lastItemQueried);
    }
  }

  function queryBuilder(lastItem = undefined) {
    let query = firebaseApp.firestore().collection(collectionName);

    filters.forEach((filter) => {
      query = query.where(
        filter?.field === 'uid' ? firebaseApp.firestore.FieldPath.documentId() : filter?.field,
        filter?.operator,
        filter?.value,
      );
    });
    if (orderBy) {
      query = query.orderBy(orderBy?.field, orderBy?.order);
    }
    if (lastItem) {
      query = query.startAfter(lastItem);
    }
    query = query.limit(limit);

    return query;
  }

  function transformData(data) {
    return transformer ? transformer(data) : data;
  }

  const fetchItems = useCallback(async () => {
    setLoading(true);
    try {
      const query = queryBuilder();

      // Fetch initial data
      const snapshot = await query.get();
      const fetchedItems = snapshot.docs.map((doc) => ({ uid: doc.id, ...doc.data() }));
      setItems(transformData(fetchedItems));
      setLastItem(snapshot.docs[snapshot.docs.length - 1], snapshot.docs.length);
    } catch (error) {
      errorHandler(error, true);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionName, filters, limit, orderBy]);

  const fetchMore = async () => {
    if (lastVisible) {
      console.debug('trigger more...');
      setLoading(true);
      try {
        const query = queryBuilder(lastVisible);

        const snapshot = await query.get();
        const fetchedItems = snapshot.docs.map((doc) => ({ uid: doc.id, ...doc.data() }));
        setItems(transformData([...items, ...fetchedItems]));
        setLastItem(snapshot.docs[snapshot.docs.length - 1], snapshot.docs.length);
      } catch (error) {
        errorHandler(error, true);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if ((isConnected && currentUser?.uid) || !isConnected) {
      fetchItems();
    }
  }, [currentUser?.uid, fetchItems, isConnected]);

  return (
    <FlatList
      contentContainerStyle={contentContainerStyle}
      initialNumToRender={items?.length}
      data={items}
      numColumns={numColumns}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      onEndReached={fetchMore}
      scrollEnabled={true}
      nestedScrollEnabled={true}
      ListHeaderComponent={headerComponent}
      refreshControl={<RefreshControl refreshing={loading} onRefresh={fetchItems} />}
      ListEmptyComponent={loading ? null : ListEmptyComponent}
      ListFooterComponent={
        <VStack>
          <Center>
            {lastVisible ? (
              loading ? (
                <Spinner size={'small'} />
              ) : (
                <UnderLineButton hideLeftIcon={true} onPress={fetchMore} text={t('global.loadMore')} />
              )
            ) : null}
          </Center>
          {ListFooterComponent}
          {items?.length > 0 && ListFooterNoEmptyComponent}
        </VStack>
      }
      ItemSeparatorComponent={ItemSeparatorComponent}
    />
  );
};

export default FirestoreList;
