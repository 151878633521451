import AddEmail from '../../screen/quote/steps/addEmail';
import ConfirmUser from '../../screen/quote/steps/confirmUser';
import PreviewQuote from '../../screen/quote/steps/previewQuote';
import QuoteSelectCar from '../../screen/quote/steps/selectCar';
import QuoteSelectDateRange from '../../screen/quote/steps/selectDateRange';
import QuoteDiscount from '../../screen/quote/steps/selectDiscount';
import QuoteSelectExtraOption from '../../screen/quote/steps/selectExtraOption';
import QuoteSelectLocation from '../../screen/quote/steps/selectLocation';

export const addQuoteStep = [
  {
    titleToTranslate: 'quote.selectDateRange',
    component: () => <QuoteSelectDateRange />,
    validator: (quote) => quote?.dateRange?.from && quote?.dateRange?.to && quote?.dateRange?.checkInHour && quote?.dateRange?.checkOutHour,
    nameToTranslate: 'quote.selectDateRange',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'quote.selectCar',
    component: () => <QuoteSelectCar />,
    validator: (quote) => quote?.carId,
    nameToTranslate: 'quote.selectCar',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'quote.selectLocation',
    component: () => <QuoteSelectLocation />,
    validator: (quote) => quote?.location?.pickUp?.placeId,
    nameToTranslate: 'quote.selectLocation',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'quote.selectExtraOption',
    component: () => <QuoteSelectExtraOption />,
    validator: (quote) => true,
    nameToTranslate: 'quote.selectExtraOption',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'quote.selectDiscount',
    component: () => <QuoteDiscount />,
    validator: (quote) => true,
    nameToTranslate: 'quote.selectDiscount',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'quote.preview',
    component: () => <PreviewQuote />,
    validator: (quote) => true,
    nameToTranslate: 'quote.preview',
    buttonTextTranslate: 'global.next',
  },
  {
    titleToTranslate: 'quote.addEmail',
    component: () => <AddEmail />,
    validator: (quote) => true,
    nameToTranslate: 'quote.addEmail',
    buttonTextTranslate: 'global.send',
  },
  {
    titleToTranslate: 'quote.confirmUser',
    component: () => <ConfirmUser />,
    validator: (quote) => true,
    nameToTranslate: 'quote.confirmUser',
    buttonTextTranslate: 'global.send',
  },
];
