import React, { useMemo, useState } from 'react';

import { Ionicons } from '@expo/vector-icons';
import { Alert, HStack, Icon, Text } from '@gluestack-ui/themed';

export default function InfoCard({ text, mode }) {
  const getColorFromMode = () => {
    if (mode && mode === 'error') {
      return { bColor: '$red50', textColor: '$red900' };
    }
    return { bColor: '$blue50', textColor: '$black' };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const color = useMemo(() => getColorFromMode(), [mode]);

  return (
    <HStack space={'sm'} p={'$2'} rounded={'$md'} backgroundColor={color.bColor} w={'100%'}>
      <Icon m={0.5} w={'10%'} color={color.textColor} size={'md'} as={Ionicons} name={'information-circle-outline'} />
      <Text size={'secondary'} w={'90%'} color="$black">
        {text}
      </Text>
    </HStack>
  );
}
