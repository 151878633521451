import React, { useEffect, useMemo, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { HStack, Icon, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { getDefaultLanguage } from '../../util/language-utils';
import { truncateString } from '../../util/string-utils';

const translationContentField = 'translatedContent';
const originalContentField = 'originalContent';

export default function ContentTranslation({ object, field, lang, limit, onTruncated, textColor, switchTextColor, suffix }) {
  const [text, setText] = useState('');
  const [isOriginal, setOriginal] = useState(false);
  const [isNothing, setNothing] = useState(false);

  const userLanguage = useMemo(() => lang ?? getDefaultLanguage(), [lang]);

  const { t } = useTranslation();

  function getFromOriginal() {
    if (originalContentField in object && field in object[originalContentField]) {
      setText(truncateString(object[originalContentField][field], limit));
      onTruncated && onTruncated(object[originalContentField][field].length > limit);
    } else {
      setText('');
      onTruncated && onTruncated(false);
      setNothing(true);
    }
  }

  function getFromTranslation() {
    if (translationContentField && object && translationContentField in object && field in object[translationContentField]) {
      if (field in object[translationContentField] && userLanguage in object[translationContentField][field]) {
        setText(truncateString(object[translationContentField][field][userLanguage], limit));
        onTruncated && onTruncated(object[translationContentField][field][userLanguage].length > limit);
      } else {
        setText('');
        onTruncated && onTruncated(false);
        setNothing(true);
      }
    } else if (originalContentField && object && originalContentField in object && field in object[originalContentField]) {
      setText(truncateString(object[originalContentField][field], limit));
      onTruncated && onTruncated(object[originalContentField][field].length > limit);
    } else {
      setText('');
      onTruncated && onTruncated(false);
      setNothing(true);
    }
  }

  function getText() {
    if (isOriginal) {
      getFromOriginal();
    } else {
      getFromTranslation();
    }
  }

  useEffect(() => {
    getText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object, translationContentField, originalContentField, lang, isOriginal]);

  if (limit === 0 || text.trim().length === 0) {
    return null;
  }

  return (
    <VStack space={'xs'}>
      {object?.language !== userLanguage && !isNothing && (
        <TouchableOpacity onPress={() => setOriginal(!isOriginal)}>
          <HStack space={'sm'}>
            <Icon as={Ionicons} name={'language-outline'} color={'$light500'} />
            <Text variant={'grey'} size={'secondary'} color={switchTextColor !== undefined && switchTextColor}>
              {isOriginal ? t('global.seeTranslation') : t('global.seeOriginal')}
            </Text>
          </HStack>
        </TouchableOpacity>
      )}
      <Text color={textColor ? textColor : '$black'}>
        {text.trim()} {suffix}
      </Text>
    </VStack>
  );
}
