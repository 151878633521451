import React, { useEffect, useState } from 'react';

import { Box, FormControl, Text, Textarea, TextareaInput, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CarActions, { carAddingSetter } from '../../../shared/reducers/car.reducer';
import { getExtraInputProps } from '../../../shared/util/input-utils';

function CarGuidelines() {
  const dispatch = useDispatch();
  const car = useSelector((state) => state.cars.carAdding);
  const { t } = useTranslation();

  const [text, setText] = useState(
    car?.originalContent && car?.originalContent?.carGuidelines ? car?.originalContent?.carGuidelines : t('addCar.addCarGuidelinesExample'),
  );

  function changeText(textChange) {
    setText(textChange);
  }

  useEffect(() => {
    dispatch(carAddingSetter({ ...car, originalContent: { ...car?.originalContent, carGuidelines: text } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    <VStack space={'md'}>
      <Text mb={'$4'} variant={'grey'} size={'secondary'}>
        {t('addCar.addCarGuidelinesDescription')}
      </Text>
      <Box>
        <FormControl>
          <Textarea testID="input.carGuidelines" {...getExtraInputProps()}>
            <TextareaInput placeholder={t('addCar.addCarGuidelinesExample')} defaultValue={text} onChangeText={changeText} />
          </Textarea>
        </FormControl>
      </Box>
    </VStack>
  );
}

export default CarGuidelines;
