import React, { useEffect, useState } from 'react';

import { Box, Divider, ScrollView } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '../../shared/components/badge/alert';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import { PressableRow } from '../../shared/components/pressable/pressableRow';
import Steps from '../../shared/components/steps/steps';
import successToast from '../../shared/components/toast/successToast';
import ScreenSpliter from '../../shared/components/web/screenSpliter';
import { useIsDrawer } from '../../shared/provider/isDrawerProvider';
import CarActions, { carAddingSetter } from '../../shared/reducers/car.reducer';
import UserAction, { userCurrentRequest } from '../../shared/reducers/user.reducer';
import { oneCarListener, updateOneCar } from '../../shared/services/firebase/car-firebase';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { addCarStep } from '../../shared/util/car-step-list';
import { cleanFirestoreListResult, cleanFirestoreResult } from '../../shared/util/firestore-utils';

function OwnerCarsEditListingScreen({ route }) {
  const carAdding = useSelector((state) => state.cars.carAdding);
  const dispatch = useDispatch();

  const { carId } = route?.params ?? {};

  const [loading, setLoading] = useState(true);
  const [stepName, setStepName] = useState(undefined);
  const isMobile = !useIsDrawer();

  const { t } = useTranslation();
  const navigation = useNavigation();

  function onCarSnapshot(snapshot) {
    const cleanData = cleanFirestoreResult(snapshot);
    setLoading(true);
    firebaseApp
      .firestore()
      .collection('cars')
      .doc(cleanData.uid)
      .collection('documents')
      .get()
      .then(cleanFirestoreListResult)
      .then((documents) => {
        dispatch(carAddingSetter({ ...cleanData, documents: documents }));
        setLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoading(false);
      });
  }

  function onCarSnapshotError(e) {
    errorHandler(e, true);
    setLoading(false);
  }

  useEffect(() => {
    console.debug('Effect: refresh car detail');
    const subscriber = oneCarListener(carId, onCarSnapshot, onCarSnapshotError);
    return () => subscriber();
  }, [carId]);

  function updateCar() {
    updateOneCar(carAdding)
      .then((res) => {
        successToast(t('car.carUpdated'));
      })
      .catch((e) => errorHandler(e, true));
  }

  function displayStep(stepNameTranslate) {
    if (isMobile) {
      navigation.navigate('OwnerCarsEditListingItem', { stepName: stepNameTranslate });
    } else {
      setStepName(stepNameTranslate);
    }
  }

  return (
    <ScreenSpliter
      left={
        <ScrollView mx={'$screenMargin'}>
          {addCarStep
            .filter((s) => s?.atEdit)
            .map((step) => (
              <Box key={step.titleToTranslate}>
                <PressableRow
                  isSelected={step.titleToTranslate === stepName}
                  text={t(step.nameToTranslate)}
                  onPress={() => displayStep(step.titleToTranslate)}
                  infoElement={<Alert badgeOnly={true} types={[carAdding?.alert?.[step.titleToTranslate]]} />}
                />
                <Divider />
              </Box>
            ))}
        </ScrollView>
      }
      right={
        stepName ? (
          <Steps
            lockDown={true}
            forceWidth={true}
            key={stepName + carAdding?.uid}
            routes={addCarStep.filter((step) => step.titleToTranslate === stepName)}
            onFinish={updateCar}
            onBack={() => navigation.navigate('OwnerCarsEditListingScreen', { carId: carAdding?.uid })}
            buttonFinishTitle={t('car.update')}
            dataToValidate={carAdding}
            loading={false}
          />
        ) : null
      }
      isRigthDisplayable={stepName !== undefined}
      onClose={() => setStepName(undefined)}
      headerText={t('screen.profile')}
    />
  );
}

export default OwnerCarsEditListingScreen;
