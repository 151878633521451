import React from 'react';
import { useEffect, useState } from 'react';

import { Ionicons } from '@expo/vector-icons';
import { Heading, HStack, Icon, Text } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { getOneCar } from '../../../services/firebase/car-firebase';
import { getThumbnailUrl } from '../../../util/car-utils';
import { displayRangeDate } from '../../../util/date-transforms';
import { errorHandler } from '../../errorHandler/errorHander';
import SmallCard from '../../pressable/smallCard';

export default function CarSmallCard({ car, userCar, dateRange, onPress, disabled, badge } = { disabled: true }) {
  const { t } = useTranslation();

  const [loadingCar, setLoadingCar] = useState(true);
  const [carData, setCarData] = useState(car);

  useEffect(() => {
    if (!car?.brand && car?.uid) {
      console.debug('Effect: refresh car small card');
      setLoadingCar(true);
      getOneCar(car?.uid)
        .then((res) => {
          setCarData(res);
          setLoadingCar(false);
        })
        .catch((e) => {
          errorHandler(e, true);
          setLoadingCar(false);
        });
    } else {
      setCarData(car);
    }
  }, [car]);

  return (
    <SmallCard
      loading={loadingCar && !carData?.brand}
      imageUrl={getThumbnailUrl(carData?.images)}
      title={
        <HStack space={'sm'}>
          <Heading size={'h4'}>{carData?.brand + ' ' + carData?.model}</Heading>
          <HStack space={'sm'} alignItems={'flex-start'}>
            <Icon as={Ionicons} size={'sm'} name={'star'} color={'$light700'} />
            <Text variant={'grey'} size={'secondary'}>
              {carData?.avgRating + ' (' + carData?.numRatings + ')'}
            </Text>
          </HStack>
        </HStack>
      }
      subTitle={userCar?.firstName && t('car.ownBy', { name: userCar?.firstName + ' ' + userCar?.lastName })}
      isDisable={disabled}
      badge={badge}
      info={displayRangeDate(dateRange.from, dateRange.to, 'small')}
      onPress={onPress}
    />
  );
}
