export const insuranceTypes = [
  {
    emoji: '👤',
    type: 'TP',
  },
  {
    emoji: '🚗',
    type: 'LDW',
  },
  {
    emoji: '💥',
    type: 'CDW',
  },
  {
    emoji: '🛞',
    type: 'TWP',
  },
  {
    emoji: '🛡️',
    type: 'TPL',
  },
  {
    emoji: '🆘',
    type: 'RSP',
  },
  {
    emoji: '🤕',
    type: 'PAI',
  },
  {
    emoji: '💰',
    type: 'DP',
  },
]