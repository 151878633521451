import React from 'react';

import { Box, useToken } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FirestoreList from '../../shared/components/firestore/firestoreList';
import Layout from '../../shared/components/layout/layout';
import PlaceHolder from '../../shared/components/placeHolder/placeHolder';
import QuoteCard from '../../shared/components/quote/quoteCard';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { Images } from '../../shared/themes';
import imagesUrl from '../../shared/themes/imagesUrl';

const QuoteList = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const currentUser = useSelector((state) => state.users.currentUser);
  const maxWidth = useMaxWidth();
  const space4 = useToken('space', '4');
  const screenMargin = useToken('space', 'screenMargin');

  const renderQuote = ({ item }) => <QuoteCard quote={item} onPress={() => navigation.navigate('QuoteDetail', { quoteId: item?.uid })} />;

  return (
    <Layout>
      <FirestoreList
        keyExtractor={(item) => item.uid}
        ItemSeparatorComponent={<Box my={'$1'} />}
        isConnected={true}
        collectionName={'quotes'}
        orderBy={{ field: 'createdAt', order: 'desc' }}
        filters={[{ field: 'userId', operator: '==', value: currentUser?.uid }]}
        renderItem={renderQuote}
        contentContainerStyle={{ alignSelf: 'center', width: maxWidth, paddingVertical: space4, paddingHorizontal: screenMargin }}
        ListEmptyComponent={<PlaceHolder image={{ uri: imagesUrl.jungle1 }} title={t('quote.noQuote')} />}
      />
    </Layout>
  );
};

export default QuoteList;
