import fonts from './fonts';

import { Colors, Fonts } from '.';

export const calendarTheme = {
  textSectionTitleColor: Colors.blue[400],
  textDayFontFamily: Fonts.fontConfig.Heebo[400].normal,
  textDayFontWeight: 'normal',
  textDayHeaderFontFamily: Fonts.fontConfig.Heebo[500].normal,
  textDayHeaderFontWeight: '500',
  textMonthFontFamily: Fonts.fontConfig.Heebo[500].normal,
  textMonthFontWeight: '500',
};

export const calendariotheme = {
  activeDayColor: {},
  monthTitleTextStyle: {
    color: Colors.black,
    fontWeight: '500',
    fontSize: 18,
  },
  emptyMonthContainerStyle: {},
  emptyMonthTextStyle: {},
  weekColumnsContainerStyle: {},
  weekColumnStyle: {
    paddingVertical: 10,
  },
  weekColumnTextStyle: {
    color: Colors.blue[400],
    fontSize: 13,
  },
  nonTouchableDayContainerStyle: {},
  nonTouchableDayTextStyle: {
    color: Colors.light[300],
  },
  startDateContainerStyle: {
    backgroundColor: Colors.blue[900],
  },
  endDateContainerStyle: {
    backgroundColor: Colors.blue[900],
  },
  dayContainerStyle: {},
  dayTextStyle: {
    color: Colors.black,
    fontWeight: '500',
    fontSize: 15,
  },
  dayOutOfRangeContainerStyle: {},
  dayOutOfRangeTextStyle: {},
  todayContainerStyle: {
    backgroundColor: Colors.light[200],
    borderRadius: 20,
  },
  todayTextStyle: {},
  activeDayContainerStyle: {
    backgroundColor: Colors.light[400],
  },
  activeDayTextStyle: {
    color: Colors.white,
  },
  nonTouchableLastMonthDayTextStyle: {},
};
