import React from 'react';

import { Heading, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import Image from '../../../shared/components/image/image';
import { Images, Metrics } from '../../../shared/themes';
import imagesUrl from '../../../shared/themes/imagesUrl';

function Step1() {
  const { t } = useTranslation();

  return (
    <VStack space={'md'} mt={'$16'}>
      <Image source={{ uri: imagesUrl.garage }} alt={'Step1'} h={Metrics.images.xxlarge} w={'100%'} resizeMode="contain" />
      <Heading>{t('addCar.tellUsAboutYourCar')}</Heading>
      <Text>{t('addCar.tellUsAboutYourCarDescription')}</Text>
    </VStack>
  );
}

export default Step1;
