import React, { useMemo, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Box, Button, ButtonText, HStack, Icon, Link, LinkText, VStack } from '@gluestack-ui/themed';
import { Text } from '@gluestack-ui/themed';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { screenBreakPoint } from '../../util/device-utils';
import { InternalLinks } from '../../util/link-utils';
import { expiredDateInUTC } from '../../util/storage-utils';

export default function CookieBanner() {
  const [cookie, setCookie] = useCookies(['approveCookie']);

  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const isMobile = useMemo(() => screenBreakPoint(width, [true, false, false, false, false]), [width]);

  function onAccept() {
    setCookie('approveCookie', true, expiredDateInUTC(120));
  }

  return !cookie?.approveCookie ? (
    <Box
      position={'absolute'}
      bottom={0}
      right={0}
      left={0}
      p={'$4'}
      bgColor={'$light100'}
      alignItems={'center'}
      borderTopWidth={1}
      borderColor={'$light200'}>
      {isMobile ? (
        <VStack space={'md'} alignItems={'center'}>
          <HStack space={'sm'} alignItems={'center'} w={width}>
            <Icon as={MaterialCommunityIcons} name={'cookie-check-outline'} size={'xl'} />
            <Link isExternal={true} href={InternalLinks.TOU}>
              <Text textAlign={'center'} size={'secondary'} w={width - 40}>
                {t('cookie.cookieBannerText')}
              </Text>
            </Link>
          </HStack>
          <Button onPress={onAccept}>
            <ButtonText>{t('global.accept')}</ButtonText>
          </Button>
        </VStack>
      ) : (
        <HStack space={'md'} alignItems={'center'}>
          <Icon as={MaterialCommunityIcons} name={'cookie-check-outline'} size={'xl'} />
          <Link isExternal={true} href={InternalLinks.TOU}>
            <Text size={'secondary'} w={width - 200}>
              {t('cookie.cookieBannerText')}
            </Text>
          </Link>
          <Button onPress={onAccept}>
            <ButtonText>{t('global.accept')}</ButtonText>
          </Button>
        </HStack>
      )}
    </Box>
  ) : null;
}
