import React from 'react';

import { Ionicons } from '@expo/vector-icons';
import { HStack, Icon, Text } from '@gluestack-ui/themed';

import { Colors } from '../../../themes';
import SmallCard from '../../pressable/smallCard';

export default function ProfileCard({ profile, loading, onPress, isDisable }) {
  return (
    <SmallCard
      isDisable={isDisable}
      isRounded={true}
      loading={loading}
      imageUrl={profile?.photoURL}
      title={profile?.name ?? `${profile?.firstName} ${profile?.lastName}`}
      subTitle={
        <HStack space={'sm'} alignItems={'center'}>
          <Icon as={Ionicons} size={'sm'} name={'star'} color={'$light700'} />
          {profile?.numRatings > 0 ? (
            <Text variant={'grey'} size={'secondary'}>
              {profile?.avgRating + ' (' + profile?.numRatings + ')'}
            </Text>
          ) : (
            <Text variant={'grey'} size={'secondary'}>
              {profile?.avgRating}
            </Text>
          )}
        </HStack>
      }
      onPress={onPress}
    />
  );
}
