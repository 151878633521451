import { firebaseApp } from './firebaseFactory';

export function getCurrency(curency) {
  return firebaseApp
    .firestore()
    .collection('currencies')
    .doc(curency)
    .get()
    .then((res) => {
      return { ...res.data(), uid: res.id };
    });
}

export function getAllCurrency() {
  return firebaseApp
    .firestore()
    .collection('currencies')
    .get()
    .then((res) => {
      const currencies = [];
      console.info('Total currencies: ', res.size);
      res.forEach((r) => currencies.push({ ...r.data(), uid: r.id }));
      return currencies;
    });
}
