import { createStyle } from '@gluestack-style/react';

export const Image = createStyle({
  maxWidth: '$full',
  alt: 'image',
  variants: {
    size: {
      xs: {
        w: '$8',
        h: '$8',
      },
      sm: {
        w: '$12',
        h: '$12',
      },
      md: {
        w: '$16',
        h: '$16',
      },
      lg: {
        w: '$24',
        h: '$24',
      },
      xl: {
        w: '$32',
        h: '$32',
      },
      '2xl': {
        w: '$64',
        h: '$64',
      },
      full: {
        w: '$full',
        h: '$full',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
});
