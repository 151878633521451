import { createElement, useEffect, useState } from 'react';

import { useToken } from '@gluestack-ui/themed';

export default function DatePicker({ onConfirm, date }) {
  const [dateVar, setDate] = useState(date ? new Date(date) : new Date());

  const space2 = useToken('space', '2');
  const radiiMd = useToken('radii', 'md');
  const space12 = useToken('space', '12');
  const colorBlack = useToken('colors', 'black');

  useEffect(() => {
    console.debug('UseEffect DatePicker');
    onConfirm && onConfirm(dateVar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateVar]);

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d.valueOf());
  }

  return createElement('input', {
    type: 'date',
    value: dateVar?.toISOString().split('T')[0],
    onChange: (event) => {
      if (isValidDate(new Date(event.target.value))) {
        setDate(new Date(event.target.value));
      }
    },
    style: {
      height: space12,
      padding: space2,
      border: `1px solid ${colorBlack}`,
      borderRadius: radiiMd,
      width: '100%',
      boxSizing: 'border-box',
    },
  });
}
