import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { notFoundScreen, registerScreens, screens } from './screens';
import tabNavigation from './tab-navigation';
import { navigationOption } from '../shared/util/navigation-utils';

const Stack = createStackNavigator();

function StackNavigator() {
  const { t } = useTranslation();
  // Use useSelector to access the current state
  const currentUser = useSelector((state) => state.users.currentUser);
  const isManageRegisterFlow = useSelector((state) => state.users.isManageRegisterFlow);

  return (
    <Stack.Navigator>
      {currentUser?.uid && !currentUser?.conditionAccepted ? (
        registerScreens.map((screen) => (
          <Stack.Screen
            key={screen.name}
            name={screen.name}
            component={screen.component}
            options={{
              ...navigationOption,
              ...screen.options,
              ...(screen.optionsMobile ?? {}),
              title: t(screen.options.title),
            }}
          />
        ))
      ) : (
        <>
          <Stack.Screen name="Tab" component={tabNavigation} options={{ ...navigationOption, headerShown: false }} />
          {screens.map((screen) => (
            <Stack.Screen
              name={screen.name}
              component={screen.component}
              key={screen.name}
              options={{
                ...navigationOption,
                ...screen.options,
                ...(screen.optionsMobile ?? {}),
                title: t(screen.options.title),
              }}
            />
          ))}
          {notFoundScreen.map((screen) => (
            <Stack.Screen
              name={screen.name}
              component={screen.component}
              key={screen.name}
              options={{
                ...navigationOption,
                ...screen.options,
                ...(screen.optionsMobile ?? {}),
                title: t(screen.options.title),
              }}
            />
          ))}
        </>
      )}
    </Stack.Navigator>
  );
}

export default StackNavigator;
