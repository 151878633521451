import React, { useState } from 'react';
import { Share, TouchableOpacity } from 'react-native';

import { Feather, Ionicons } from '@expo/vector-icons';
import { Box, FlatList, HStack, Icon, Pressable, useToken, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import appConfig from '../../../config/app-config';
import { goBackOrDefault } from '../../../navigation/nav-ref';
import ImageView from '../../generic/ImageView';
import { useIsDrawer } from '../../provider/isDrawerProvider';
import { useMaxWidth } from '../../provider/maxWidthProvider';
import { IS_WEB } from '../../util/device-utils';
import { resizedImage } from '../../util/image-utils';
import { errorHandler } from '../errorHandler/errorHander';
import Image from '../image/image';
import Skeleton from '../skeleton/skeleton';
import successToast from '../toast/successToast';

export default function CarDetailCover({ images, onClosePress, isMobile, carId, hideShare, loading }) {
  const [index, setIndex] = useState(0);
  const [isVisible, setVisible] = useState(false);

  const { t, i18n } = useTranslation();
  const { top } = useSafeAreaInsets();
  const isDrawer = useIsDrawer();
  const space1 = parseInt(useToken('space', '1'), 10);
  const space2 = parseInt(useToken('space', '2'), 10);
  const spaceMargin = parseInt(useToken('space', 'screenMargin'), 10);

  const maxWidthHook = useMaxWidth();
  const maxWidth = maxWidthHook - (isDrawer ? spaceMargin * 2 : 0);
  const imageRatioHeight = 2;
  const imagesSorted = [...(images || [])].sort((a, b) => (a.isThumbnail ? -1 : 1));

  if (!images || images.length === 0 || loading) {
    return (
      <Box
        width={isMobile ? '$full' : maxWidth}
        height={maxWidth / imageRatioHeight + top}
        mt={isMobile ? '$none' : '$4'}
        mx={isMobile ? '$none' : '$screenMargin'}>
        {isMobile ? (
          <Skeleton height={maxWidth / imageRatioHeight + top} width={'$full'} rounded={'$none'} />
        ) : (
          <HStack space={'sm'}>
            <Skeleton borderTopLeftRadius={'$xl'} borderBottomLeftRadius={'$xl'} height={maxWidth / imageRatioHeight + top} w={'50%'} />

            <VStack space="sm">
              <HStack space="sm">
                <Skeleton height={(maxWidth / imageRatioHeight + top) / 2 - space2 / 2} w={maxWidth / imageRatioHeight / 2 - space1 * 2} />
                <Skeleton
                  height={(maxWidth / imageRatioHeight + top) / 2 - space2 / 2}
                  w={maxWidth / imageRatioHeight / 2 - space1 * 2}
                  borderTopRightRadius={'$xl'}
                />
              </HStack>
              <HStack space={'sm'}>
                <Skeleton height={(maxWidth / imageRatioHeight + top) / 2 - space2 / 2} w={maxWidth / imageRatioHeight / 2 - space1 * 2} />
                <Skeleton
                  height={(maxWidth / imageRatioHeight + top) / 2 - space2 / 2}
                  w={maxWidth / imageRatioHeight / 2 - space1 * 2}
                  borderBottomRightRadius={'$xl'}
                />
              </HStack>
            </VStack>
          </HStack>
        )}
      </Box>
    );
  }

  const onShare = async () => {
    try {
      const message = IS_WEB ? window.location.href : `${appConfig.deepLinkUrl}/${i18n.language}/car-detail/${carId}`;
      if (IS_WEB) {
        await navigator.clipboard.writeText(message);
        successToast(t('global.linkCopied'));
      } else {
        await Share.share({ message });
      }
    } catch (error) {
      errorHandler(error, true);
    }
  };

  const openViewer = (i) => {
    setIndex(i);
    setVisible(true);
  };

  const renderItem = ({ item, index }) => {
    const imageParams = {
      height: (maxWidth / imageRatioHeight + top) / 2 - space2 / 2,
      w: maxWidth / imageRatioHeight / 2 - space1 * 2,
      resizeMode: 'cover',
      rounded: '$none',
    };

    const borderRadius = {
      borderTopRightRadius: index === 1 ? '$xl' : '$none',
      borderBottomRightRadius: index === 3 ? '$xl' : '$none',
      mb: index < 2 ? space1 : '$none',
      mt: index > 1 ? space1 : '$none',
      mx: space1,
      overflow: 'hidden',
    };

    return (
      <TouchableOpacity onPress={() => openViewer(item?.isCheckImage ? 0 : index + 1)}>
        <Box {...borderRadius}>
          {item?.isCheckImage ? (
            <Box {...imageParams} bgColor={'$light300'} alignItems={'center'} justifyContent={'center'}>
              <Icon as={Ionicons} name="eye-outline" size={'xl'} />
            </Box>
          ) : (
            <Image {...imageParams} source={{ uri: resizedImage(item.url) }} alt="car" />
          )}
        </Box>
      </TouchableOpacity>
    );
  };

  return (
    <Box
      width={isMobile ? '$full' : maxWidth}
      height={maxWidth / imageRatioHeight + top}
      mt={isMobile ? '$none' : '$4'}
      mx={isMobile ? '$none' : '$screenMargin'}>
      {isMobile ? (
        <TouchableOpacity onPress={() => setVisible(true)}>
          <Image
            source={{ uri: resizedImage(imagesSorted[0].url) }}
            height={maxWidth / imageRatioHeight + top}
            width={'$full'}
            rounded={'$none'}
          />
        </TouchableOpacity>
      ) : (
        <HStack>
          <TouchableOpacity style={{ height: maxWidth / imageRatioHeight + top, width: '50%' }} onPress={() => openViewer(0)}>
            <Image
              source={{ uri: resizedImage(imagesSorted[0].url) }}
              borderTopLeftRadius={'$xl'}
              borderBottomLeftRadius={'$xl'}
              height={maxWidth / imageRatioHeight + top}
              w={'100%'}
            />
          </TouchableOpacity>
          <FlatList
            data={imagesSorted
              .filter((img) => !img?.isThumbnail)
              .slice(0, 3)
              .concat({ isCheckImage: true })}
            renderItem={renderItem}
            keyExtractor={(item) => item.url}
            numColumns={2}
          />
        </HStack>
      )}
      {!hideShare && (
        <Pressable
          bgColor="$white"
          h={'$8'}
          w={'$8'}
          rounded={'$full'}
          alignContent="center"
          justifyContent="center"
          onPress={onShare}
          position={'absolute'}
          right={space2}
          top={top + space2}
          hardShadow="1">
          <Icon as={Feather} name={'share'} color="$black" />
        </Pressable>
      )}
      <Pressable
        onPress={() => (onClosePress ? onClosePress() : goBackOrDefault('Search'))}
        bgColor="$white"
        h={'$8'}
        w={'$8'}
        rounded={'$full'}
        alignContent="center"
        justifyContent="center"
        position={'absolute'}
        left={space2}
        top={top + space2}
        hardShadow="1">
        <Icon as={Ionicons} name={'close'} color="$black" />
      </Pressable>
      <ImageView
        images={imagesSorted.map((doc) => ({ uri: doc.url }))}
        imageIndex={index}
        visible={isVisible}
        onRequestClose={() => setVisible(false)}
      />
    </Box>
  );
}
