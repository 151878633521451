import React from 'react';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { t } from 'i18next';

import BackofficeCarToValidate from './backofficeCarToValidate';
import backofficeMail from './backofficeMail';
import BackofficeOwners from './backofficeOwners';
import BackofficeReservations from './backofficeReservations';
import Layout from '../../shared/components/layout/layout';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { tabScreenOption } from '../../shared/util/navigation-utils';

const Tab = createMaterialTopTabNavigator();

function BackOfficeHome() {
  const maxWidth = useMaxWidth();
  return (
    <Layout>
      <Tab.Navigator
        screenOptions={{
          ...tabScreenOption,
          tabBarStyle: {
            ...tabScreenOption.tabBarStyle,
            width: maxWidth,
            alignSelf: 'center',
          },
        }}>
        <Tab.Screen name="to-validate" component={BackofficeCarToValidate} options={{ title: t('backoffice.validate') }} />
        <Tab.Screen name="owners" component={BackofficeOwners} options={{ title: t('backoffice.owners') }} />
        <Tab.Screen name="reservations" component={BackofficeReservations} options={{ title: t('backoffice.reservations') }} />
        <Tab.Screen name="mail" component={backofficeMail} options={{ title: t('backoffice.email') }} />
      </Tab.Navigator>
    </Layout>
  );
}

export default BackOfficeHome;
