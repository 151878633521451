import { IS_WEB } from './device-utils';

export function renderDone() {
  if (!IS_WEB) {
    return;
  }
  setTimeout(() => {
    window.prerenderReady = true;
  }, 5000);
}
