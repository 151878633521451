import React, { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import {
  Heading,
  Icon,
  Modal,
  ModalBackdrop,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ScrollView,
} from '@gluestack-ui/themed';

import { Colors } from '../../themes';
import { screenBreakPoint } from '../../util/device-utils';

export default function BottomSheet({ testID, isOpen, setOpen, headerText, children, renderFooter }) {
  const { width } = useWindowDimensions();
  const size = useMemo(() => screenBreakPoint(width, ['full', 'full', 'xl', 'xl', 'xl']), [width]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      useRNModal={true}
      size={size}
      animationPreset={size === 'full' ? 'slide' : 'fade'}>
      <ModalBackdrop />
      <ModalContent
        testID={testID}
        maxHeight={'80%'}
        {...(size === 'full' ? { marginBottom: 0, marginTop: 'auto', minHeight: '60%' } : {})}>
        {headerText && (
          <ModalHeader>
            <Heading size={'h2'}>{headerText}</Heading>
            <ModalCloseButton>
              <Icon as={Ionicons} name={'close'} />
            </ModalCloseButton>
          </ModalHeader>
        )}
        <ModalBody bgColor={Colors.fullWhite} py={'$4'}>
          <ScrollView>{children}</ScrollView>
        </ModalBody>
        {renderFooter && <ModalFooter bgColor={'$white'}>{renderFooter()}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
}
