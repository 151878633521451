import React, { useEffect, useState } from 'react';

import { Heading, HStack, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CarTinyCard from '../../shared/components/entities/car/carTinyCard';
import PaymentAccountCard from '../../shared/components/entities/paymentAccountCard/paymentAccountCard';
import ReservationCard from '../../shared/components/entities/reservation/reservationCard';
import UserReviewCard from '../../shared/components/entities/user/userReviewCard';
import NotificationManager from '../../shared/components/notificationManager/notificationManager';
import UnderLineButton from '../../shared/components/pressable/underLineButton';
import ReservationEvent from '../../shared/components/reservationEvent/reservationEvent';
import StoreReviewComponent from '../../shared/components/storeReview/storeReview';
import WebScrollView from '../../shared/components/web/webScrollView';
import WebSpliter from '../../shared/components/web/webSpliter';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { reservationStatus } from '../../shared/util/car-utils';
import { cleanFirestoreListResult } from '../../shared/util/firestore-utils';

function OwnerHomeScreen() {
  const currentUser = useSelector((state) => state.users.currentUser);

  const [reservations, setReservations] = useState([]);
  const [unvalidatedCars, setUnvalidatedCars] = useState([]);
  const [reservationsRequest, setReservationsRequest] = useState([]);
  const [toReviewReservations, setToReviewReservations] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigation = useNavigation();
  const { t } = useTranslation();

  useEffect(() => {
    if (currentUser?.uid) {
      const subscriber = firebaseApp
        .firestore()
        .collection('reservations')
        .where('ownerId', '==', currentUser?.uid)
        .where('toDisplay', '==', true)
        .where('status', 'in', [reservationStatus.waitingCheckIn, reservationStatus.waitingCheckOut])
        .onSnapshot((res) => {
          setReservations(cleanFirestoreListResult(res));
          setLoading(false);
        });

      return () => subscriber();
    }
  }, [currentUser?.uid]);

  useEffect(() => {
    if (currentUser?.uid) {
      const subscriber = firebaseApp
        .firestore()
        .collection('reservations')
        .where('ownerId', '==', currentUser?.uid)
        .where('toDisplay', '==', true)
        .where('isOwnerReviewed', '==', false)
        .where('status', '==', reservationStatus.waitingReview)
        .onSnapshot((res) => {
          setToReviewReservations(cleanFirestoreListResult(res));
        });

      return () => subscriber();
    }
  }, [currentUser?.uid]);

  useEffect(() => {
    if (currentUser?.uid) {
      const subscriber = firebaseApp
        .firestore()
        .collection('reservations')
        .where('ownerId', '==', currentUser?.uid)
        .where('toDisplay', '==', true)
        .where('status', '==', reservationStatus.waitingOwner)
        .onSnapshot((res) => {
          setReservationsRequest(cleanFirestoreListResult(res));
        });

      return () => subscriber();
    }
  }, [currentUser?.uid]);

  useEffect(() => {
    if (currentUser?.uid) {
      const subscriber = firebaseApp
        .firestore()
        .collection('cars')
        .where('ownerId', '==', currentUser?.uid)
        .where('carValidated', '==', false)
        .onSnapshot((res) => {
          setUnvalidatedCars(cleanFirestoreListResult(res));
        });

      return () => subscriber();
    }
  }, [currentUser?.uid]);

  return (
    <WebScrollView isBottomBar>
      <NotificationManager />
      <StoreReviewComponent toTrigger={reservations?.length > 0} />
      <WebSpliter
        space={'lg'}
        left={
          <VStack mx={'$screenMargin'} mt={'$4'} space={'lg'}>
            <PaymentAccountCard userId={currentUser?.uid} />
            {reservationsRequest?.length > 0 && (
              <VStack space={'md'}>
                <Heading>{t('ownerHome.requests')}</Heading>
                {reservationsRequest.map((r) => (
                  <ReservationCard
                    key={r.uid}
                    reservation={r}
                    onPress={() => navigation.navigate('RequestDetailScreen', { reservationId: r.uid, userId: r.userId, carId: r.carId })}
                  />
                ))}
              </VStack>
            )}
            {toReviewReservations?.length > 0 ? (
              <VStack space={'md'}>
                <Heading>{t('ownerCar.reviews')}</Heading>
                {toReviewReservations.map((item) => (
                  <UserReviewCard
                    key={item.uid}
                    userId={item.userId}
                    onPress={() => {
                      navigation.navigate('AddReview', { carId: item.carId, reservationId: item?.uid, reviewedUserId: item.userId });
                    }}
                  />
                ))}
              </VStack>
            ) : null}
            {unvalidatedCars?.length > 0 && (
              <VStack space={'md'}>
                <Heading>{t('ownerHome.completeListing')}</Heading>
                {unvalidatedCars.map((c) => (
                  <CarTinyCard key={c?.uid} car={c} onPress={() => navigation.navigate('OwnerCarsDetailScreen', { carId: c?.uid })} />
                ))}
              </VStack>
            )}
          </VStack>
        }
        right={
          <VStack mx={'$screenMargin'} mt={'$4'} space={'lg'} w={'100%'}>
            <VStack space={'md'} w={'90%'}>
              <HStack justifyContent={'space-between'} mt={'$4'}>
                <Heading>{t('ownerHome.events')}</Heading>
                <UnderLineButton
                  text={t('global.seeMore')}
                  hideLeftIcon={true}
                  onPress={() => navigation.navigate('OwnerReservationList')}
                />
              </HStack>
              <ReservationEvent loading={loading} reservationList={reservations} />
            </VStack>
          </VStack>
        }
      />
    </WebScrollView>
  );
}
export default OwnerHomeScreen;
