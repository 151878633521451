import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { getThumbnailUrl, reservationStatusToColorSheme } from '../../../util/car-utils';
import { displayRangeDate } from '../../../util/date-transforms';
import SmallCard from '../../pressable/smallCard';

export default function ReservationCard({ reservation, onPress, disabled }) {
  const { t } = useTranslation();

  return (
    <SmallCard
      imageUrl={getThumbnailUrl(reservation?.car?.images)}
      title={`${reservation?.car?.brand} ${reservation?.car?.model}`}
      badge={{
        color: reservationStatusToColorSheme(reservation.status),
        text: t(`reservationStatus.${reservation.status}`),
      }}
      info={displayRangeDate(reservation?.from, reservation?.to)}
      onPress={onPress}
      isDisable={disabled}
    />
  );
}
