import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { MaterialIcons } from '@expo/vector-icons';
import { Box, Button, ButtonText, HStack, Icon, Input, InputField, View, VStack } from '@gluestack-ui/themed';
import { Text } from '@gluestack-ui/themed';

import Select from '../select/select';

const FilterComponent = ({ allowedFields, filters, setFilters, baseFilters, containerStyles }) => {
  const [currentFilter, setCurrentFilter] = useState({ field: '', operator: '', value: '' });
  const [showForm, setShowForm] = useState(false); // New state for form visibility

  const handleAddFilter = () => {
    if (currentFilter?.field && currentFilter?.operator && currentFilter?.value) {
      setFilters([...filters, currentFilter]);
      setCurrentFilter({ field: '', operator: '', value: '' });
    }
  };

  const handleRemoveFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
  };

  const isFieldDisabled = (field) => {
    return baseFilters.some((f) => f.field === field);
  };

  const toggleForm = () => setShowForm(!showForm);

  return (
    <VStack {...containerStyles}>
      <Button onPress={toggleForm}>
        <ButtonText>{showForm ? 'Hide Filters' : 'Show Filters'}</ButtonText>
      </Button>
      {showForm && (
        <HStack direction={['column', 'column', 'row']} space={'sm'} justifyItems={'center'} my={'$1'}>
          <Select
            testID={'brand'}
            title="Select a field"
            data={allowedFields.map((f) => ({ value: f, label: f }))}
            onSelect={(item) => setCurrentFilter({ ...currentFilter, field: item.value })}
            placeHolder="Select a field"
            value={currentFilter.field}
          />
          <Select
            testID={'brand'}
            title="Select a field"
            data={[
              { label: '==', value: '==' },
              { label: '!=', value: '!=' },
              { label: '<', value: '<' },
              { label: '<=', value: '<=' },
              { label: '>', value: '>' },
              { label: '>=', value: '>=' },
            ]}
            onSelect={(item) => setCurrentFilter({ ...currentFilter, operator: item.value })}
            placeHolder="Select a field"
            value={currentFilter.operator}
          />
          <Input>
            <InputField
              alignSelf={'center'}
              width={['full', 'full', 200]}
              placeholder="Value"
              value={currentFilter.value}
              onChangeText={(text) => setCurrentFilter({ ...currentFilter, value: text })}
            />
          </Input>
          <Button onPress={handleAddFilter}>
            <ButtonText>Add Filter</ButtonText>
          </Button>
        </HStack>
      )}

      <HStack flexWrap={'wrap'}>
        {filters.map((filter, index) => (
          <Box key={`${filter.field} ${filter.operator} ${filter.value}`} variant={'cardContainer'} p={'$2'} m={'$2'}>
            <HStack space={'sm'} alignItems="center">
              <Text>{`${filter.field} ${filter.operator} ${filter.value}`}</Text>
              <TouchableOpacity onPress={() => handleRemoveFilter(index)} isDisabled={isFieldDisabled(filter.field)}>
                <Icon as={MaterialIcons} name="delete" color={'$light400'} />
              </TouchableOpacity>
            </HStack>
          </Box>
        ))}
      </HStack>
    </VStack>
  );
};

export default FilterComponent;
