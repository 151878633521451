import React, { useEffect, useState } from 'react';

import {
  Button,
  ButtonSpinner,
  ButtonText,
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  Input,
  InputField,
  Text,
  VStack,
} from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { getLocales } from 'expo-localization';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import BottomAction from '../../shared/components/bottomAction/bottomAction';
import RegionPicker from '../../shared/components/countryPicker/countryPicker';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import KeyboardAvoiding from '../../shared/components/keyboardAvoiding/keyboardAvoiding';
import Layout from '../../shared/components/layout/layout';
import LoginLegalText from '../../shared/components/legal/loginLegal';
import errorToast from '../../shared/components/toast/errorToast';
import DatePicker from '../../shared/generic/DatePicker';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { setLastRedirectableRoute, userCurrentRequest } from '../../shared/reducers/user.reducer';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { userToAccount } from '../../shared/util/account-utils';
import { analyticsRegistration } from '../../shared/util/analytics';

function RegisterScreen() {
  const currentUser = useSelector((state) => state.users.currentUser);
  const tmpUserData = useSelector((state) => state.users.tmpUserData);
  const lastRedirectableRoute = useSelector((state) => state.users.lastRedirectableRoute);

  const [bottomActionHeight, setBottomActionHeight] = useState(0);
  const [updating, setUpdating] = useState(false);

  const dispatch = useDispatch();
  const maxWidth = useMaxWidth();
  const getCurrentUser = (userId) => dispatch(userCurrentRequest(userId));

  function getDefaultName() {
    if (!currentUser?.firstName || !currentUser?.lastName) {
      return tmpUserData;
    }
    return {};
  }

  const [formData, setData] = useState({ ...currentUser, ...getDefaultName(), countryCode: getLocales()[0]?.regionCode });

  const { t } = useTranslation();
  const navigation = useNavigation();
  const { bottom } = useSafeAreaInsets();

  const [date, setDate] = useState(formData?.birthday ? new Date(formData?.birthday * 1000) : new Date());

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required().label('firstName'),
    lastName: Yup.string().required().label('lastName'),
  });

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => null,
    });
    dispatch(setLastRedirectableRoute({ ...lastRedirectableRoute, justRegister: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getAge(birthDate) {
    var today = new Date();
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  function getDate(dateToCheck) {
    return dateToCheck instanceof Date && !isNaN(dateToCheck) ? dateToCheck : new Date();
  }

  const onSubmit = async () => {
    if (getAge(date) < 18) {
      errorToast(t('error.tooYoung'));
      return;
    }
    setUpdating(true);
    validationSchema
      .validate(formData, { abortEarly: true })
      .then(() => {
        const data = {
          ...formData,
          conditionAccepted: true,
          birthday: date.getTime() / 1000,
          currency: getLocales()[0]?.currencyCode,
          language: getLocales()[0]?.languageCode,
        };
        firebaseApp.auth().currentUser.updateProfile(userToAccount(data));
        firebaseApp
          .firestore()
          .collection('users')
          .doc(firebaseApp.auth().currentUser?.uid)
          .update(data)
          .then(() => {
            getCurrentUser(firebaseApp.auth().currentUser?.uid);
            setUpdating(false);
          })
          .catch((e) => {
            errorHandler(e, true);
            setUpdating(false);
          });

        analyticsRegistration(firebaseApp.auth().currentUser?.providerId);
      })
      .catch((err) => {
        errorHandler(err, false);
        errorToast(err);
        setUpdating(false);
      });
  };

  return (
    <Layout>
      <KeyboardAvoiding maxWidth={maxWidth > 700 ? 700 : maxWidth}>
        <VStack space={'md'} my={'$4'} mx={'$screenMargin'} pb={bottom + bottomActionHeight}>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('login.firstName')}</FormControlLabelText>
            </FormControlLabel>
            <Input>
              <InputField
                testID="input.firstName"
                defaultValue={formData?.firstName}
                onChangeText={(value) => setData({ ...formData, firstName: value })}
              />
            </Input>
          </FormControl>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('login.lastName')}</FormControlLabelText>
            </FormControlLabel>
            <Input>
              <InputField
                testID="input.lastName"
                defaultValue={formData?.lastName}
                onChangeText={(value) => setData({ ...formData, lastName: value })}
              />
            </Input>
          </FormControl>

          <Text variant={'grey'} size={'secondary'}>
            {t('login.checkNameMatchId')}
          </Text>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('login.birthdate')}</FormControlLabelText>
            </FormControlLabel>
            <DatePicker
              title={t('login.birthdate')}
              maximumDate={new Date(new Date().getFullYear(), 11, 31)}
              date={getDate(date)}
              mode={'date'}
              onConfirm={(date) => {
                setDate(date);
              }}
            />
          </FormControl>
          <Text variant={'grey'} size={'secondary'}>
            {t('login.moreThan18')}
          </Text>
          <RegionPicker
            title={t('login.country')}
            defaultCountryCode={formData?.countryCode}
            placeHolder={t('login.countryPlaceHolder')}
            setCountryCode={(value) => setData({ ...formData, countryCode: value })}
          />
        </VStack>
      </KeyboardAvoiding>
      <BottomAction
        onLayout={(event) => {
          setBottomActionHeight(event?.nativeEvent?.layout?.height ?? 0);
        }}>
        <VStack px={'$screenMargin'} py={'$4'} space="md" w={maxWidth > 700 ? 700 : maxWidth} alignSelf="center">
          <LoginLegalText />
          <Button testID="button.register" onPress={onSubmit} isDisabled={updating}>
            {updating && <ButtonSpinner mr="$1" />}
            <ButtonText>{formData?.conditionAccepted ? t('login.updateAndContinue') : t('login.agreeAndContinue')}</ButtonText>
          </Button>
        </VStack>
      </BottomAction>
    </Layout>
  );
}

export default RegisterScreen;
