import { useEffect, useRef } from 'react';
import { useWindowDimensions } from 'react-native';

import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { useReduxDevToolsExtension } from '@react-navigation/devtools';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';

import drawerNavigator from './drawerNavigator';
import { linking, webLinking } from './linking';
import { isReadyRef, navigationRef } from './nav-ref';
import stackNavigator from './stackNavigator';
import { useIsDrawer } from '../shared/provider/isDrawerProvider';
import { setLastRedirectableRoute } from '../shared/reducers/user.reducer'; // Assuming this is an action creator
import { themeNavigation } from '../shared/themes/nativeBaseTheme';
import { analyticsScreenView } from '../shared/util/analytics';
import { screenBreakPoint } from '../shared/util/device-utils';
import { supportedLang } from '../shared/util/language-utils';

const LanguageStack = createStackNavigator();

function NavigContainer() {
  const routeNameRef = useRef();
  const dispatch = useDispatch(); // Used to dispatch actions

  // Accessing Redux state using the useSelector hook
  const lastRedirectableRoute = useSelector((state) => state.users.lastRedirectableRoute);

  const isDrawer = useIsDrawer();

  useEffect(() => {
    console.debug('Effect: Nav ready');
    return () => {
      isReadyRef.current = false;
    };
  }, []);

  useReduxDevToolsExtension(navigationRef);

  return (
    <NavigationContainer
      key={isDrawer}
      linking={isDrawer ? webLinking : linking}
      ref={navigationRef}
      theme={themeNavigation}
      onReady={() => {
        isReadyRef.current = true;
        const route = navigationRef.current.getCurrentRoute();
        routeNameRef.current = route.name;
        if (lastRedirectableRoute?.justRegister && !['Register', 'CustomWebView'].includes(route.name)) {
          navigationRef.current.navigate(lastRedirectableRoute?.name, lastRedirectableRoute?.params);
          dispatch(setLastRedirectableRoute({ ...lastRedirectableRoute, justRegister: false }));
        } else {
          dispatch(setLastRedirectableRoute(navigationRef.current.getCurrentRoute()));
        }
      }}
      onStateChange={async (state) => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current.getCurrentRoute().name;
        console.debug('change screen to: ', currentRouteName);
        if (previousRouteName !== currentRouteName) {
          analyticsScreenView(currentRouteName);
        }
        if (lastRedirectableRoute?.justRegister && !['Register', 'CustomWebView'].includes(currentRouteName)) {
          navigationRef.current.navigate(lastRedirectableRoute?.name, lastRedirectableRoute?.params);
          dispatch(setLastRedirectableRoute({ ...lastRedirectableRoute, justRegister: false }));
        } else {
          dispatch(setLastRedirectableRoute(navigationRef.current.getCurrentRoute()));
        }

        routeNameRef.current = currentRouteName;
      }}>
      <GestureHandlerRootView style={{ flex: 1 }}>
        <BottomSheetModalProvider>
          <LanguageStack.Navigator initialRouteName="en">
            {supportedLang.map((lang) => (
              <LanguageStack.Screen
                key={lang}
                name={lang}
                component={isDrawer ? drawerNavigator : stackNavigator}
                options={{ headerShown: false }}
              />
            ))}
          </LanguageStack.Navigator>
        </BottomSheetModalProvider>
      </GestureHandlerRootView>
    </NavigationContainer>
  );
}

export default NavigContainer;
