import React from 'react';

import { Box, Heading, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import MediaUploader from '../../../shared/components/media/mediaUploader';
import { carAddingSetter } from '../../../shared/reducers/car.reducer';
import { Metrics } from '../../../shared/themes';

function AddCarPhoto() {
  const car = useSelector((state) => state.cars.carAdding);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const imageArray = car?.images ? car.images : [];

  function updateImages(images, isThumbnail) {
    const cleanImages = images.filter((image) => image?.url).map((image) => ({ url: image.url, isThumbnail }));
    dispatch(carAddingSetter({ ...car, images: [...imageArray.filter((c) => c.isThumbnail !== isThumbnail), ...cleanImages] }));
  }

  return (
    <VStack space={'md'}>
      <Text mb={'$4'} variant={'grey'} size={'secondary'}>
        {t('addCar.addCarPhotoDescription')}
      </Text>
      <Heading>{t('addCar.thumbnail')}</Heading>
      <Box height={Metrics.images.xlarge}>
        <MediaUploader
          testID={'thumnail'}
          initialList={imageArray.filter((i) => i.isThumbnail === true)}
          onUpdate={(images) => updateImages(images, true)}
          storagePath={'car-profile'}
          multipleSelectionAllow={false}
        />
      </Box>
      <MediaUploader
        testID={'additional'}
        initialList={imageArray.filter((i) => i.isThumbnail !== true)}
        onUpdate={(images) => updateImages(images, false)}
        storagePath={'car-profile'}
        multipleSelectionAllow={true}
      />
    </VStack>
  );
}

export default AddCarPhoto;
