import { useEffect } from 'react';

import * as StoreReview from 'expo-store-review';
import { useDispatch, useSelector } from 'react-redux';

import { userLastPromtStoreReview } from '../../reducers/user.reducer';
import { errorHandler } from '../errorHandler/errorHander';

const interval = 30 * 60 * 60 * 1000; // 30 hours

function StoreReviewComponent() {
  const dispatch = useDispatch();
  const lastPromtStoreReview = useSelector((state) => state.users.lastPromtStoreReview);
  const toTrigger = useSelector((state) => state.users.toTrigger);

  const updateLastPromtStoreReview = (time) => {
    dispatch(userLastPromtStoreReview(time));
  };

  useEffect(() => {
    StoreReview.isAvailableAsync().then((res) => {
      if (res) {
        StoreReview.hasAction().then((r) => {
          if (r === true && toTrigger && (!lastPromtStoreReview || lastPromtStoreReview + interval < new Date().getTime())) {
            updateLastPromtStoreReview(new Date().getTime());
            StoreReview.requestReview().catch((e) => errorHandler(e, false));
          } else {
            console.info('no action');
          }
        });
      } else {
        console.warn('no available');
      }
    });
  }, [toTrigger]);

  return null;
}

export default StoreReviewComponent;
