import { createStyle } from '@gluestack-style/react';

export const shadow = {
  shadowColor: 'coolGray.200',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,

  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)',

  elevation: 5,
};

export const Box = createStyle({
  variants: {
    variant: {
      select: {
        borderWidth: '$1',
        borderColor: '$light500',
        rounded: '$lg',
        h: '$12',
        py: '$2',
        px: '$3',
      },
      shadow: {
        ...shadow,
      },
      cardContainer: {
        ...shadow,
        rounded: '$2xl',
        backgroundColor: '$white',
        borderWidth: 0,
      },
      featureBox: {
        width: '100%',
        rounded: '$lg',
        backgroundColor: '$white',
        borderWidth: 2,
        borderColor: '$black',
      },
      cardGrey: {
        width: '100%',
        rounded: '$lg',
        backgroundColor: '$light50',
        borderWidth: 1,
        borderColor: '$light300',
      },
      footer: {
        backgroundColor: '$white',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
      },
      contentWithFooter: {
        marginBottom: '80px',
      },
      absoluteTopLeft: {
        position: 'absolute',
        left: 0,
        top: 0,
      },
      absoluteTop: {
        position: 'absolute',
        top: 0,
      },
      absoluteMidle: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
      },
      absoluteBottomLeft: {
        position: 'absolute',
        left: 0,
        bottom: 0,
      },
      absoluteBottomRight: {
        position: 'absolute',
        right: 0,
        bottom: 0,
      },
      absoluteTopRight: {
        position: 'absolute',
        right: 0,
        top: 0,
      },
      absoluteBottom: {
        position: 'absolute',
        bottom: 0,
      },
      absoluteRight: {
        position: 'absolute',
        right: 0,
      },
      absoluteLeft: {
        position: 'absolute',
        left: 0,
      },
    },
  },
});
