// leave off @2x/@3x
const imagesUrl = {
  quoteMark: 'https://assets.flyandcar.com/assets/icons/quoteMark.png',
  iconClock: 'https://assets.flyandcar.com/assets/icons/clock.png',
  iconFlash: 'https://assets.flyandcar.com/assets/icons/flash.png',
  iconCard: 'https://assets.flyandcar.com/assets/icons/card.png',
  iconBell: 'https://assets.flyandcar.com/assets/icons/bell.png',
  iconTarget: 'https://assets.flyandcar.com/assets/icons/target.png',
  iconPlay: 'https://assets.flyandcar.com/assets/icons/play.png',
  iconMessage: 'https://assets.flyandcar.com/assets/icons/message.png',
  iconDocument: 'https://assets.flyandcar.com/assets/icons/document.png',
  iconSuccess: 'https://assets.flyandcar.com/assets/icons/success.png',
  benefitPro: 'https://assets.flyandcar.com/assets/illu/illuBenefitPro.png',
  phonePro: 'https://assets.flyandcar.com/assets/illu/illuPhonePro.png',
  previewPro: 'https://assets.flyandcar.com/assets/previewPro.png',
  homeImage: 'https://assets.flyandcar.com/assets/homeImg.jpg',
  documentPlaceHolder: 'https://assets.flyandcar.com/assets/document-placeholder.jpeg',
  unavailable: 'https://assets.flyandcar.com/assets/unavailableImage.png',
  addCarIlu: 'https://assets.flyandcar.com/assets/addCarIlu.png',
  defaultCover: 'https://assets.flyandcar.com/assets/defaultCov.webp',
  accident1: 'https://assets.flyandcar.com/assets/illu/accident1.png',
  empty: 'https://assets.flyandcar.com/assets/illu/empty.png',
  garage: 'https://assets.flyandcar.com/assets/illu/garage.png',
  jungle1: 'https://assets.flyandcar.com/assets/illu/jungle1.png',
  jungle2: 'https://assets.flyandcar.com/assets/illu/jungle2.png',
  mechanic: 'https://assets.flyandcar.com/assets/illu/mechanic.png',
  mountain: 'https://assets.flyandcar.com/assets/illu/mountain.png',
  parking: 'https://assets.flyandcar.com/assets/illu/parking.png',
  podium: 'https://assets.flyandcar.com/assets/illu/podium.png',
  river: 'https://assets.flyandcar.com/assets/illu/river.png',
  snow: 'https://assets.flyandcar.com/assets/illu/snow.png',
  street: 'https://assets.flyandcar.com/assets/illu/street.png',
  toll: 'https://assets.flyandcar.com/assets/illu/toll.png',
  wash: 'https://assets.flyandcar.com/assets/illu/wash.png',
  appStoreDowload: 'https://assets.flyandcar.com/assets/social/appStoreDowload.png',
  facebook: 'https://assets.flyandcar.com/assets/social/facebook.png',
  google: 'https://assets.flyandcar.com/assets/social/google.png',
  playStoreDowload: 'https://assets.flyandcar.com/assets/social/playStoreDowload.png',
  brandName: 'https://assets.flyandcar.com/assets/logo-text.png',
  brandNameDark: 'https://assets.flyandcar.com/assets/logo-text-dark.png',
  logo: 'https://assets.flyandcar.com/assets/logo.png',
  systemMessageIcon: 'https://assets.flyandcar.com/assets/icons/systemMessage.png',
  update: 'https://assets.flyandcar.com/assets/update.png',
  bgCardPayment: 'https://assets.flyandcar.com/assets/card-payment.png',
  paymentRequested: 'https://assets.flyandcar.com/assets/illu/payment-requested.png',
};

export default imagesUrl;
