import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import reduxPersistConfig from '../../config/redux-persist-config';
import carReducer from '../reducers/car.reducer';
import checkCarReducer from '../reducers/checkCar.reducer';
import quoteReducer from '../reducers/quote.reducer';
import searchReducer from '../reducers/search.reducer';
import userReducer from '../reducers/user.reducer';
import rootSaga from '../sagas';

/* ------------- Assemble The Reducers ------------- */
export const reducers = combineReducers({
  users: userReducer,
  cars: carReducer,
  quote: quoteReducer,
  checkCar: checkCarReducer,
  search: searchReducer,
});

// const logger = createLogger({
//   duration: true, // print the duration of each action?

//   logger: console, // implementation of the `console` API.
//   logErrors: true, // should the logger catch, log, and re-throw errors?

//   diff: true, // (alpha) show diff between states?
// });

const persistedReducer = persistReducer(reduxPersistConfig, reducers);
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware),
  // .concat(logger),
});

export const persistor = persistStore(store);

// We need to cancel and restart the sagas when hot reloading the reducers it happend in fastRealod in DEV but also in update in PROD
let sagaRunner;

export const runSaga = () => {
  if (sagaRunner) {
    console.warn('cancel Saga Middleware');
    sagaRunner.cancel();
  }
  console.debug('run Saga Middleware');

  sagaRunner = sagaMiddleware.run(rootSaga);
};
