import React, { useMemo, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { Box, View, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { Agenda, Calendar, CalendarList } from 'react-native-calendars';
import { useSelector } from 'react-redux';

import CallendarCell from './calendarCell';
import ActionSheet from '../../shared/components/actionSheet/actionSheet';
import EventCard from '../../shared/components/entities/car/eventCard';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import PlaceHolder from '../../shared/components/placeHolder/placeHolder';
import { getAllOwnerReservationDayEvents } from '../../shared/services/firebase/reservation-firebase';
import { Images } from '../../shared/themes';
import { calendarTheme } from '../../shared/themes/calendarTheme';
import imagesUrl from '../../shared/themes/imagesUrl';
import { reservationStatus } from '../../shared/util/car-utils';
import { dateToCalendarTimeString } from '../../shared/util/date-transforms';
import { screenBreakPoint } from '../../shared/util/device-utils';

function AgendaComponent({ markedDates }) {
  const [items, setItems] = useState({});
  const [isOpen, setOpen] = useState(false);
  const [dateOpen, setDateOpen] = useState();

  const currentUser = useSelector((state) => state.users.currentUser);

  const navigation = useNavigation();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const maxWidth = useMemo(() => screenBreakPoint(width, [width, width, width, '990px', '1080px']), [width]);

  const loadItems = (day) => {
    console.debug('Load Item: agenda event daySelected', day.dateString);
    const strTime = day.dateString;
    const itemList = [];
    getAllOwnerReservationDayEvents(currentUser?.uid, day.dateString, 1000)
      .then((reservations) => {
        reservations
          .filter((r) => r.status !== reservationStatus.ownerReject)
          .map((res) => {
            const isCheckIn = dateToCalendarTimeString(res.from * 1000) === day.dateString;
            itemList.push({ ...res, isCheckIn: isCheckIn });
          });
        setItems((prev) => {
          const temp = { ...prev };
          temp[strTime] = itemList.sort((a, b) => {
            if (a.isCheckIn && b?.isCheckIn) {
              return b.checkInHour - a.checkInHour;
            } else if (!a.isCheckIn && !b?.isCheckIn) {
              return b.checkOutHour - a.checkOutHour;
            } else if (!a.isCheckIn && b?.isCheckIn) {
              return b.checkOutHour - a.checkInHour;
            } else if (a.isCheckIn && !b?.isCheckIn) {
              return b.checkInHour - a.checkOutHour;
            }
          });
          return temp;
        });
      })
      .catch((e) => {
        errorHandler(e, true);
        setItems((prev) => {
          const temp = { ...prev };
          temp[strTime] = itemList;
          return temp;
        });
      });
  };

  function goToReservation(reservation) {
    navigation.navigate('RequestDetailScreen', { reservationId: reservation.uid, userId: reservation?.userId, carId: reservation.carId });
  }

  function renderItem(item, isFirst) {
    const time = item.isCheckIn ? new Date(item.from * 1000) : new Date(item.to * 1000);
    return (
      <Box my={'$2'}>
        <EventCard
          key={item?.uid}
          onPress={() => {
            setOpen(false);
            goToReservation(item);
          }}
          reservation={item}
          type={item.isCheckIn ? 'check-in' : 'check-out'}
          date={time}
          cardOnly={true}
        />
      </Box>
    );
  }

  const renderEmptyDate = () => {
    return (
      <Box my={'$8'}>
        <PlaceHolder image={{ uri: imagesUrl.mountain }} title={t('placeHolder.noEvent')} />
      </Box>
    );
  };

  const rowHasChanged = (r1, r2) => {
    return r1?.uid !== r2?.uid;
  };

  function onDayPressed(d) {
    setDateOpen(d.dateString);
    loadItems(d);
    setOpen(true);
  }

  return (
    <>
      <CalendarList
        items={items}
        theme={calendarTheme}
        loadItemsForMonth={loadItems}
        onPress={onDayPressed}
        onDayPress={onDayPressed}
        renderItem={renderItem}
        renderEmptyDate={renderEmptyDate}
        rowHasChanged={rowHasChanged}
        markingType={'custom'}
        markedDates={markedDates}
        dayComponent={CallendarCell}
        hideExtraDays={true}
        showClosingKnob={true}
        showOnlySelectedDayItems={true}
        pastScrollRange={2}
        futureScrollRange={7}
        calendarWidth={maxWidth}
      />
      <ActionSheet isOpen={isOpen} setOpen={setOpen} headerText={dateOpen}>
        <VStack space={'sm'}>
          {items && dateOpen && dateOpen in items && items[dateOpen].map((it) => <Box key={it.uid}>{renderItem(it)}</Box>)}
          {items && dateOpen && dateOpen in items && !items[dateOpen].length && (
            <PlaceHolder image={{ uri: imagesUrl.mountain }} title={t('placeHolder.noEvent')} />
          )}
        </VStack>
      </ActionSheet>
    </>
  );
}

export default AgendaComponent;
