import React, { useState } from 'react';

import {
  Button,
  ButtonSpinner,
  ButtonText,
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  Input,
  InputField,
  VStack,
} from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { goBackOrDefault } from '../../navigation/nav-ref';
import CardContainer from '../../shared/components/container/cardContainer';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Layout from '../../shared/components/layout/layout';
import errorToast from '../../shared/components/toast/errorToast';
import successToast from '../../shared/components/toast/successToast';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';

function ForgotPasswordScreen({ navigation, route }) {
  const { email } = route?.params ?? {};

  // setup error state for displaying error messages
  const [formData, setData] = useState({ email: email });
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  // set up validation schema for the form
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required().label('email'),
  });

  const onSubmit = async () => {
    validationSchema
      .validate(formData, { abortEarly: true })
      .then((valid) => {
        setLoading(true);
        firebaseApp
          .auth()
          .sendPasswordResetEmail(email)
          .then(() => {
            successToast(t('login.forgetPasswordSend'));
            setLoading(false);
            goBackOrDefault('Profile');
          })
          .catch((e) => errorHandler(e, true));
      })
      .catch((err) => {
        errorHandler(err, false);
        errorToast(err?.errors[0]);
      });
  };

  return (
    <Layout>
      <CardContainer>
        <VStack space={'md'} my={'$4'} mx={'$screenMargin'}>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('login.login')}</FormControlLabelText>
            </FormControlLabel>
            <Input>
              <InputField defaultValue={email} onChangeText={(value) => setData({ ...formData, email: value })} />
            </Input>
          </FormControl>
          <Button isDisabled={loading} onPress={onSubmit}>
            {loading && <ButtonSpinner mr="$1" />}
            <ButtonText>{t('login.forgotPassword')}</ButtonText>
          </Button>
        </VStack>
      </CardContainer>
    </Layout>
  );
}

export default ForgotPasswordScreen;
