import React, { useEffect, useState } from 'react';

import { Ionicons } from '@expo/vector-icons';
import {
  Box,
  Button,
  ButtonIcon,
  ButtonSpinner,
  ButtonText,
  FormControlLabelText,
  Input,
  InputField,
  ScrollView,
  Text,
  Textarea,
  TextareaInput,
  VStack,
} from '@gluestack-ui/themed';
import { FormControl } from '@gluestack-ui/themed';
import { FormControlLabel } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import BottomAction from '../../shared/components/bottomAction/bottomAction';
import RegionPicker from '../../shared/components/countryPicker/countryPicker';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Image from '../../shared/components/image/image';
import Layout from '../../shared/components/layout/layout';
import MediaUploader from '../../shared/components/media/mediaUploader';
import successToast from '../../shared/components/toast/successToast';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { Metrics } from '../../shared/themes';
import imagesUrl from '../../shared/themes/imagesUrl';
import { cleanFirestoreResult } from '../../shared/util/firestore-utils';
import { getExtraInputProps } from '../../shared/util/input-utils';

function EditPublicProfileScreen({ route }) {
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const maxWidth = useMaxWidth();
  const { t, i18n } = useTranslation();
  const [bottomActionHeight, setBottomActionHeight] = useState(0);

  const currentUser = useSelector((state) => state.users.currentUser);

  const navigation = useNavigation();

  useEffect(() => {
    if (firebaseApp.auth()?.currentUser?.uid) {
      console.debug('Effect: userId');
      setLoading(true);
      firebaseApp
        .firestore()
        .collection('profiles')
        .doc(firebaseApp.auth()?.currentUser?.uid)
        .get()
        .then(cleanFirestoreResult)
        .catch((e) => {
          errorHandler(e, true);
          setLoading(false);
        })
        .then((u) => {
          setProfile(u);
          setLoading(false);
        });
    }
  }, [currentUser]);

  function updateAvatar(images) {
    if (images && images?.length > 0 && images[0]?.url) {
      setProfile((prev) => ({ ...prev, photoURL: images[0].url }));
    }
  }

  function updateBanner(images) {
    if (images && images?.length > 0 && images[0]?.url) {
      setProfile((prev) => ({ ...prev, bannerURL: images[0].url }));
    }
  }

  function onSubmit() {
    setLoading(true);
    firebaseApp
      .firestore()
      .collection('profiles')
      .doc(firebaseApp.auth()?.currentUser?.uid)
      .update({
        ...(profile?.originalContent ? { originalContent: profile?.originalContent } : {}),
        ...(profile?.countryCode ? { countryCode: profile?.countryCode } : {}),
        ...(profile?.photoURL ? { photoURL: profile?.photoURL } : {}),
        ...(profile?.name ? { name: profile?.name } : {}),
        ...(profile?.bannerURL ? { bannerURL: profile?.bannerURL } : {}),
      })
      .then(() => {
        firebaseApp.auth().currentUser.updateProfile({ photoURL: profile?.photoURL });
        successToast(t('profile.successfullyUpdated'));
        setLoading(false);
        navigation.canGoBack() && navigation.goBack();
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoading(false);
      });
  }

  return (
    <Layout>
      <ScrollView mb={bottomActionHeight}>
        <Box w={'$full'} h={'$48'}>
          <Image
            source={{
              uri: profile?.bannerURL ?? imagesUrl.defaultCover,
            }}
            w={'$full'}
            h={'$48'}
          />
          <Box position="absolute" alignSelf="center" top={0} w={maxWidth} h={'$48'}>
            <MediaUploader
              initialList={[]}
              onUpdate={updateBanner}
              rounded={'$full'}
              storagePath={'profile'}
              multipleSelectionAllow={false}>
              <Button position="absolute" right={'$4'} top={'$4'} bgColor="$white" size="sm" hardShadow="1">
                <ButtonIcon as={Ionicons} name={'add-circle-outline'} color="$black" />
                <ButtonText color={'$black'}>{t('profile.changeCover')}</ButtonText>
              </Button>
            </MediaUploader>
          </Box>
        </Box>
        <Box
          alignSelf="center"
          rounded={'$full'}
          hardShadow="1"
          bgColor="$white"
          w={Metrics.images.xlarge}
          h={Metrics.images.xlarge}
          mt={-64}>
          <MediaUploader
            key={profile?.photoURL}
            initialList={profile?.photoURL ? [{ url: profile?.photoURL }] : []}
            onUpdate={updateAvatar}
            rounded={'$full'}
            storagePath={'profile'}
            multipleSelectionAllow={false}
          />
        </Box>
        <VStack w={maxWidth > 700 ? 700 : maxWidth} alignSelf="center" space="md" px={'$screenMargin'}>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('profile.name')}</FormControlLabelText>
            </FormControlLabel>
            <Input>
              <InputField
                isReadOnly={true}
                isDisabled={true}
                inputMode="text"
                placeholder={t('profile.placeHolderName')}
                defaultValue={profile?.name ?? ''}
                onChangeText={(value) => setProfile((prev) => ({ ...prev, name: value }))}
              />
            </Input>
            <Text variant={'grey'} size={'secondary'}>
              {t('profile.changeName')}
            </Text>
          </FormControl>
          <FormControl>
            <FormControlLabel>
              <FormControlLabelText>{t('profile.about')}</FormControlLabelText>
            </FormControlLabel>
            <Textarea {...getExtraInputProps()}>
              <TextareaInput
                {...getExtraInputProps()}
                defaultValue={profile?.originalContent?.about}
                onChangeText={(text) => setProfile((prev) => ({ ...prev, originalContent: { ...prev?.originalContent, about: text } }))}
              />
            </Textarea>
          </FormControl>
        </VStack>
      </ScrollView>
      <BottomAction
        onLayout={(event) => {
          setBottomActionHeight(event?.nativeEvent?.layout?.height ?? 0);
        }}>
        <Box px={'$screenMargin'} py={'$4'} width={maxWidth > 700 ? 700 : maxWidth} alignSelf="center">
          <Button onPress={onSubmit} isDisabled={loading}>
            {loading && <ButtonSpinner mr="$1" />}
            <ButtonText>{t('login.updateAndContinue')}</ButtonText>
          </Button>
        </Box>
      </BottomAction>
    </Layout>
  );
}

export default EditPublicProfileScreen;
