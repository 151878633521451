

import { Colors } from '../themes';

export const navigationOptionDrawer = {
  // header: (props) => <CustomHeader {...props} />,
  headerShown: true,
  webHeaderTitleShow: true,
  webHeaderBarShow: true,
  hideNavigationBar: false,
  hideWhenScrolling: false,
  drawerPosition: 'right',
  // drawerStyle: { width: Dimensions.get('window').width - 100, maxWidth: 300 },
};

export const tabScreenOption = {
  tabBarStyle: { backgroundColor: Colors.fullWhite },
  tabBarLabelStyle: { textTransform: 'none', fontFamily: 'Heebo_400Regular', fontSize: 16 },
  tabBarIndicatorStyle: { backgroundColor: Colors.blue[900] },
  tabBarContentContainerStyle: {
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  tabBarItemStyle: {
    width: 100,
    paddingHorizontal: 0,
    position: 'relative',
    padding: 0,
    height: 45,
  },
};
