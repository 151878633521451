import React, { useState } from 'react';

import { Ionicons } from '@expo/vector-icons';
import {
  Button,
  ButtonSpinner,
  ButtonText,
  FormControl,
  FormControlError,
  FormControlErrorIcon,
  FormControlLabel,
  FormControlLabelText,
  Input,
  InputField,
  Textarea,
  TextareaInput,
  VStack,
} from '@gluestack-ui/themed';
import { FormControlErrorText } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { errorHandler } from '../../../shared/components/errorHandler/errorHander';
import successToast from '../../../shared/components/toast/successToast';
import { contactFlynCarByForm } from '../../../shared/services/firebase/function-firebase';
import { getExtraInputProps } from '../../../shared/util/input-utils';

export default function ContactForm() {
  const [data, setData] = useState({});
  const [invalid, setInvalid] = useState({ name: false, email: false, phone: false, message: false });
  const [sending, setSending] = useState(false);
  const { t } = useTranslation();

  function contactUs() {
    setInvalid({ name: !data?.name, email: !data?.email, phone: !data?.phone, message: !data?.message });
    if (!data?.name || !data?.email || !data?.phone || !data?.message) {
      return;
    }
    setSending(true);
    contactFlynCarByForm(data)
      .then(() => {
        successToast(t('contact.success'));
        setSending(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setSending(false);
      });
  }

  return (
    <VStack space="md" softShadow="2" bgColor="$fullWhite" p={'$8'} rounded={'$md'} w={'$full'}>
      <FormControl isInvalid={invalid?.name}>
        <FormControlLabel>
          <FormControlLabelText>{t('contact.name')}</FormControlLabelText>
        </FormControlLabel>
        <Input>
          <InputField
            placeholder={t('contact.namePlaceHolder')}
            defaultValue={data?.name}
            onChangeText={(value) => setData({ ...data, name: value })}
          />
        </Input>
        <FormControlError>
          <FormControlErrorIcon as={Ionicons} name={'alert-circle-outline'} />
          <FormControlErrorText>{t('contact.error.needToBeFilled')}</FormControlErrorText>
        </FormControlError>
      </FormControl>
      <FormControl isInvalid={invalid?.phone}>
        <FormControlLabel>
          <FormControlLabelText>{t('contact.phone')}</FormControlLabelText>
        </FormControlLabel>
        <Input>
          <InputField
            placeholder={t('contact.phonePlaceHolder')}
            defaultValue={data?.phone}
            onChangeText={(value) => setData({ ...data, phone: value })}
          />
        </Input>
        <FormControlError>
          <FormControlErrorIcon as={Ionicons} name={'alert-circle-outline'} />
          <FormControlErrorText>{t('contact.error.needToBeFilled')}</FormControlErrorText>
        </FormControlError>
      </FormControl>
      <FormControl isInvalid={invalid?.email}>
        <FormControlLabel>
          <FormControlLabelText>{t('contact.email')}</FormControlLabelText>
        </FormControlLabel>
        <Input>
          <InputField
            placeholder={t('contact.emailPlaceHolder')}
            defaultValue={data?.email}
            onChangeText={(value) => setData({ ...data, email: value })}
          />
        </Input>
        <FormControlError>
          <FormControlErrorIcon as={Ionicons} name={'alert-circle-outline'} />
          <FormControlErrorText>{t('contact.error.needToBeFilled')}</FormControlErrorText>
        </FormControlError>
      </FormControl>
      <FormControl isInvalid={invalid?.message}>
        <FormControlLabel>
          <FormControlLabelText>{t('contact.message')}</FormControlLabelText>
        </FormControlLabel>
        <Textarea {...getExtraInputProps()}>
          <TextareaInput
            placeholder={t('contact.messagePlaceHolder')}
            value={data?.message}
            onChangeText={(value) => setData({ ...data, message: value })}
          />
        </Textarea>
        <FormControlError>
          <FormControlErrorIcon as={Ionicons} name={'alert-circle-outline'} />
          <FormControlErrorText>{t('contact.error.needToBeFilled')}</FormControlErrorText>
        </FormControlError>
      </FormControl>
      <Button onPress={contactUs} disabled={sending} mt={'$4'}>
        {sending && <ButtonSpinner />}
        <ButtonText>{t('contact.buttonText')}</ButtonText>
      </Button>
    </VStack>
  );
}
