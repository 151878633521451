import React from 'react';

import { ScrollView } from '@gluestack-ui/themed';

import Footer from './footer';
import { useMaxWidth } from '../../provider/maxWidthProvider';
import Layout from '../layout/layout';

export default function WebScrollView({ title, description, image, isAeraView, isBottomBar, maxWidth, hideFooter, children, ...props }) {
  const maxWidthHook = useMaxWidth();
  return (
    <Layout title={title} description={description} image={image} isAeraView={isAeraView} isBottomBar={isBottomBar}>
      <ScrollView contentContainerStyle={{ width: maxWidth ?? maxWidthHook, alignSelf: 'center', ...props }}>
        {children}
        {!hideFooter && <Footer />}
      </ScrollView>
    </Layout>
  );
}
