import React from 'react';
// useEffect, but it skips running on the first render
export function useDidUpdateEffect(fn, inputs) {
  const didMountRef = React.useRef(false);
  React.useEffect(() => {
    console.debug('Effect: didUpdateEffect');
    if (didMountRef.current) fn();
    else didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
}
