import { firebaseApp } from './firebaseFactory';

export function addOneReview(review) {
  return firebaseApp
    .firestore()
    .collection('reviews')
    .add(review)
    .then((res) => {
      return { uid: res.id };
    });
}

export function getCarReviews(carId, limit = 5) {
  return firebaseApp
    .firestore()
    .collection('reviews')
    .where('carId', '==', carId)
    .limit(limit)
    .get()
    .then((querySnapshot) => {
      const reviews = [];
      console.info('Total comment: ', querySnapshot.size);
      querySnapshot.forEach((r) => reviews.push({ ...r.data(), uid: r.id }));
      return reviews;
    });
}

export function getUserReviews(userId, limit = 5) {
  return firebaseApp
    .firestore()
    .collection('reviews')
    .where('reviewedUserId', '==', userId)
    .limit(limit)
    .get()
    .then((querySnapshot) => {
      const reviews = [];
      console.info('Total comment: ', querySnapshot.size);
      querySnapshot.forEach((r) => reviews.push({ ...r.data(), uid: r.id }));
      return reviews;
    });
}
