import React from 'react';
import { Text } from 'react-native';

import { VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { InternalLinks } from '../../util/link-utils';

export default function LoginLegalText() {
  const { t } = useTranslation();
  const navigation = useNavigation();
  return (
    <Text space={'xs'}>
      <Text>{t('legal.signUpContent')}</Text>{' '}
      <Text
        style={[{ textDecorationLine: 'underline' }]}
        onPress={() => navigation.navigate('CustomWebView', { url: InternalLinks.TOU, title: t('legal.termOfUse') })}>
        {t('legal.termOfUse')}
      </Text>
      {' & '}
      <Text
        style={[{ textDecorationLine: 'underline' }]}
        onPress={() => navigation.navigate('CustomWebView', { url: InternalLinks.privacyPolicy, title: t('legal.privacyPolicy') })}>
        {t('legal.privacyPolicy')}
      </Text>
    </Text>
  );
}
