import { formatCurrency as fCur } from 'react-native-format-currency';

export function formatCurrency(price, curr) {
  const [priceFormated, numberFormated, symbol] = fCur({ amount: price, code: curr });

  const priceF = isNaN(price) ? 0 : priceFormated;

  const customCurrencySymbols = {
    CRC: '₡',
  };

  if (curr in customCurrencySymbols) {
    return customCurrencySymbols[curr] + priceF;
  }

  return priceF;
}

export function getSymbol(price, curr) {
  const [priceFormated, numberFormated, symbol] = fCur({ amount: price, code: curr });

  const customCurrencySymbols = {
    USD: '$',
    CAD: 'CA$',
    EUR: '€',
    AED: 'AED',
    AFN: 'Af',
    ALL: 'ALL',
    AMD: 'AMD',
    ARS: 'AR$',
    AUD: 'AU$',
    AZN: 'man.',
    BAM: 'KM',
    BDT: 'Tk',
    BGN: 'BGN',
    BHD: 'BD',
    BIF: 'FBu',
    BND: 'BN$',
    BOB: 'Bs',
    BRL: 'R$',
    BWP: 'BWP',
    BYN: 'Br',
    BZD: 'BZ$',
    CDF: 'CDF',
    CHF: 'CHF',
    CLP: 'CL$',
    CNY: 'CN¥',
    COP: 'CO$',
    CRC: '₡',
    CVE: 'CV$',
    CZK: 'Kč',
    DJF: 'Fdj',
    DKK: 'Dkr',
    DOP: 'RD$',
    DZD: 'DA',
    EEK: 'Ekr',
    EGP: 'EGP',
    ERN: 'Nfk',
    ETB: 'Br',
    GBP: '£',
    GEL: 'GEL',
    GHS: 'GH₵',
    GNF: 'FG',
    GTQ: 'GTQ',
    HKD: 'HK$',
    HNL: 'HNL',
    HRK: 'kn',
    HUF: 'Ft',
    IDR: 'Rp',
    ILS: '₪',
    INR: 'Rs',
    IQD: 'IQD',
    IRR: 'IRR',
    ISK: 'Ikr',
    JMD: 'J$',
    JOD: 'JD',
    JPY: '¥',
    KES: 'Ksh',
    KHR: 'KHR',
    KMF: 'CF',
    KRW: '₩',
    KWD: 'KD',
    KZT: 'KZT',
    LBP: 'L.L.',
    LKR: 'SLRs',
    LTL: 'Lt',
    LVL: 'Ls',
    LYD: 'LD',
    MAD: 'MAD',
    MDL: 'MDL',
    MGA: 'MGA',
    MKD: 'MKD',
    MMK: 'MMK',
    MOP: 'MOP$',
    MUR: 'MURs',
    MXN: 'MX$',
    MYR: 'RM',
    MZN: 'MTn',
    NAD: 'N$',
    NGN: '₦',
    NIO: 'C$',
    NOK: 'Nkr',
    NPR: 'NPRs',
    NZD: 'NZ$',
    OMR: 'OMR',
    PAB: 'B/.',
    PEN: 'S/.',
    PHP: '₱',
    PKR: 'PKRs',
    PLN: 'zł',
    PYG: '₲',
    QAR: 'QR',
    RON: 'RON',
    RSD: 'din.',
    RUB: 'RUB',
    RWF: 'RWF',
    SAR: 'SR',
    SDG: 'SDG',
    SEK: 'Skr',
    SGD: 'S$',
    SOS: 'Ssh',
    SYP: 'SY£',
    THB: '฿',
    TND: 'DT',
    TOP: 'T$',
    TRY: 'TL',
    TTD: 'TT$',
    TWD: 'NT$',
    TZS: 'TSh',
    UAH: '₴',
    UGX: 'USh',
    UYU: '$U',
    UZS: 'UZS',
    VEF: 'Bs.F.',
    VND: '₫',
    XAF: 'FCFA',
    XOF: 'CFA',
    YER: 'YR',
    ZAR: 'R',
    ZMK: 'ZK',
    ZWL: 'ZWL$',
  };

  if (curr in customCurrencySymbols) {
    return customCurrencySymbols[curr];
  }

  return symbol ? symbol : curr;
}

export function getPriceFromCurrency(price, priceCurrency, currencyRate, decimal = 1) {
  if (!currencyRate || !price) {
    return '-';
  }

  const amount = currencyRate ? Math.round(price * currencyRate[currencyRate.current] * decimal) / decimal : price;

  if (isNaN(amount)) {
    return formatCurrency(price, priceCurrency);
  }

  return formatCurrency(amount, currencyRate?.current);
}

export const supportedCurrency = [
  { value: 'USD', label: 'United States Dollar' },
  { value: 'AED', label: 'United Arab Emirates Dirham' },
  { value: 'AFN', label: 'Afghan Afghani' },
  { value: 'ALL', label: 'Albanian Lek' },
  { value: 'AMD', label: 'Armenian Dram' },
  { value: 'ANG', label: 'Netherlands Antillean Guilder' },
  { value: 'AOA', label: 'Angolan Kwanza' },
  { value: 'ARS', label: 'Argentine Peso' },
  { value: 'AUD', label: 'Australian Dollar' },
  { value: 'AWG', label: 'Aruban Florin' },
  { value: 'AZN', label: 'Azerbaijani Manat' },
  { value: 'BAM', label: 'Bosnia-Herzegovina Convertible Mark' },
  { value: 'BBD', label: 'Barbadian Dollar' },
  { value: 'BDT', label: 'Bangladeshi Taka' },
  { value: 'BGN', label: 'Bulgarian Lev' },
  { value: 'BIF', label: 'Burundian Franc' },
  { value: 'BMD', label: 'Bermudian Dollar' },
  { value: 'BND', label: 'Brunei Dollar' },
  { value: 'BOB', label: 'Bolivian Boliviano' },
  { value: 'BRL', label: 'Brazilian Real' },
  { value: 'BSD', label: 'Bahamian Dollar' },
  { value: 'BWP', label: 'Botswanan Pula' },
  { value: 'BYN', label: 'Belarusian Ruble' },
  { value: 'BZD', label: 'Belize Dollar' },
  { value: 'CAD', label: 'Canadian Dollar' },
  { value: 'CDF', label: 'Congolese Franc' },
  { value: 'CHF', label: 'Swiss Franc' },
  { value: 'CLP', label: 'Chilean Peso' },
  { value: 'CNY', label: 'Chinese Yuan' },
  { value: 'COP', label: 'Colombian Peso' },
  { value: 'CRC', label: 'Costa Rican Colón' },
  { value: 'CVE', label: 'Cape Verdean Escudo' },
  { value: 'CZK', label: 'Czech Republic Koruna' },
  { value: 'DJF', label: 'Djiboutian Franc' },
  { value: 'DKK', label: 'Danish Krone' },
  { value: 'DOP', label: 'Dominican Peso' },
  { value: 'DZD', label: 'Algerian Dinar' },
  { value: 'EGP', label: 'Egyptian Pound' },
  { value: 'ETB', label: 'Ethiopian Birr' },
  { value: 'EUR', label: 'Euro' },
  { value: 'FJD', label: 'Fijian Dollar' },
  { value: 'FKP', label: 'Falkland Islands Pound' },
  { value: 'GBP', label: 'British Pound Sterling' },
  { value: 'GEL', label: 'Georgian Lari' },
  { value: 'GIP', label: 'Gibraltar Pound' },
  { value: 'GMD', label: 'Gambian Dalasi' },
  { value: 'GNF', label: 'Guinean Franc' },
  { value: 'GTQ', label: 'Guatemalan Quetzal' },
  { value: 'GYD', label: 'Guyanaese Dollar' },
  { value: 'HKD', label: 'Hong Kong Dollar' },
  { value: 'HNL', label: 'Honduran Lempira' },
  { value: 'HTG', label: 'Haitian Gourde' },
  { value: 'HUF', label: 'Hungarian Forint' },
  { value: 'IDR', label: 'Indonesian Rupiah' },
  { value: 'ILS', label: 'Israeli New Sheqel' },
  { value: 'INR', label: 'Indian Rupee' },
  { value: 'ISK', label: 'Icelandic Króna' },
  { value: 'JMD', label: 'Jamaican Dollar' },
  { value: 'JPY', label: 'Japanese Yen' },
  { value: 'KES', label: 'Kenyan Shilling' },
  { value: 'KGS', label: 'Kyrgyzstani Som' },
  { value: 'KHR', label: 'Cambodian Riel' },
  { value: 'KMF', label: 'Comorian Franc' },
  { value: 'KRW', label: 'South Korean Won' },
  { value: 'KYD', label: 'Cayman Islands Dollar' },
  { value: 'KZT', label: 'Kazakhstani Tenge' },
  { value: 'LAK', label: 'Laotian Kip' },
  { value: 'LBP', label: 'Lebanese Pound' },
  { value: 'LKR', label: 'Sri Lankan Rupee' },
  { value: 'LRD', label: 'Liberian Dollar' },
  { value: 'LSL', label: 'Lesotho Loti' },
  { value: 'MAD', label: 'Moroccan Dirham' },
  { value: 'MDL', label: 'Moldovan Leu' },
  { value: 'MGA', label: 'Malagasy Ariary' },
  { value: 'MKD', label: 'Macedonian Denar' },
  { value: 'MMK', label: 'Myanmar Kyat' },
  { value: 'MNT', label: 'Mongolian Tugrik' },
  { value: 'MOP', label: 'Macanese Pataca' },
  { value: 'MRO', label: 'Mauritanian Ouguiya' },
  { value: 'MUR', label: 'Mauritian Rupee' },
  { value: 'MVR', label: 'Maldivian Rufiyaa' },
  { value: 'MWK', label: 'Malawian Kwacha' },
  { value: 'MXN', label: 'Mexican Peso' },
  { value: 'MYR', label: 'Malaysian Ringgit' },
  { value: 'MZN', label: 'Mozambican Metical' },
  { value: 'NAD', label: 'Namibian Dollar' },
  { value: 'NGN', label: 'Nigerian Naira' },
  { value: 'NIO', label: 'Nicaraguan Córdoba' },
  { value: 'NOK', label: 'Norwegian Krone' },
  { value: 'NPR', label: 'Nepalese Rupee' },
  { value: 'NZD', label: 'New Zealand Dollar' },
  { value: 'PAB', label: 'Panamanian Balboa' },
  { value: 'PEN', label: 'Peruvian Nuevo Sol' },
  { value: 'PGK', label: 'Papua New Guinean Kina' },
  { value: 'PHP', label: 'Philippine Peso' },
  { value: 'PKR', label: 'Pakistani Rupee' },
  { value: 'PLN', label: 'Polish Zloty' },
  { value: 'PYG', label: 'Paraguayan Guarani' },
  { value: 'QAR', label: 'Qatari Rial' },
  { value: 'RON', label: 'Romanian Leu' },
  { value: 'RSD', label: 'Serbian Dinar' },
  { value: 'RUB', label: 'Russian Ruble' },
  { value: 'RWF', label: 'Rwandan Franc' },
  { value: 'SAR', label: 'Saudi Riyal' },
  { value: 'SBD', label: 'Solomon Islands Dollar' },
  { value: 'SCR', label: 'Seychellois Rupee' },
  { value: 'SEK', label: 'Swedish Krona' },
  { value: 'SGD', label: 'Singapore Dollar' },
  { value: 'SHP', label: 'Saint Helena Pound' },
  { value: 'SLE', label: 'Sierra Leonean Leone' },
  { value: 'SOS', label: 'Somali Shilling' },
  { value: 'SRD', label: 'Surinamese Dollar' },
  { value: 'STD', label: 'São Tomé and Príncipe Dobra' },
  { value: 'SZL', label: 'Swazi Lilangeni' },
  { value: 'THB', label: 'Thai Baht' },
  { value: 'TJS', label: 'Tajikistani Somoni' },
  { value: 'TOP', label: "Tongan Pa'anga" },
  { value: 'TRY', label: 'Turkish Lira' },
  { value: 'TTD', label: 'Trinidad and Tobago Dollar' },
  { value: 'TWD', label: 'New Taiwan Dollar' },
  { value: 'TZS', label: 'Tanzanian Shilling' },
  { value: 'UAH', label: 'Ukrainian Hryvnia' },
  { value: 'UGX', label: 'Ugandan Shilling' },
  { value: 'UYU', label: 'Uruguayan Peso' },
  { value: 'UZS', label: 'Uzbekistani Som' },
  { value: 'VND', label: 'Vietnamese Dong' },
  { value: 'VUV', label: 'Vanuatu Vatu' },
  { value: 'WST', label: 'Samoan Tala' },
  { value: 'XAF', label: 'CFA Franc BEAC' },
  { value: 'XCD', label: 'East Caribbean Dollar' },
  { value: 'XOF', label: 'CFA Franc BCEAO' },
  { value: 'XPF', label: 'CFP Franc' },
  { value: 'YER', label: 'Yemeni Rial' },
  { value: 'ZAR', label: 'South African Rand' },
  { value: 'ZMW', label: 'Zambian Kwacha' },
  { value: 'BHD', label: 'Bahraini Dinar' },
  { value: 'JOD', label: 'Jordanian Dinar' },
  { value: 'KWD', label: 'Kuwaiti Dinar' },
  { value: 'OMR', label: 'Omani Rial' },
  { value: 'TND', label: 'Tunisian Dinar' },
];

export const defaultCurrencyRate = {
  AED: 3.6725,
  AFN: 86.3411,
  ALL: 101.0112,
  AMD: 386.5971,
  ANG: 1.79,
  AOA: 513.5963,
  ARS: 222.2257,
  AUD: 1.5117,
  AWG: 1.79,
  AZN: 1.6992,
  BAM: 1.7762,
  BBD: 2,
  BDT: 105.7478,
  BGN: 1.7763,
  BHD: 0.376,
  BIF: 2080.7441,
  BMD: 1,
  BND: 1.3356,
  BOB: 6.9219,
  BRL: 4.9932,
  BSD: 1,
  BWP: 13.2141,
  BYN: 2.8158,
  BZD: 2,
  CAD: 1.3557,
  CDF: 2046.5655,
  CHF: 0.8947,
  CLP: 805.3226,
  CNY: 6.9266,
  COP: 4673.5141,
  CRC: 542.7726,
  CVE: 100.1396,
  CZK: 21.3474,
  DJF: 177.721,
  DKK: 6.7753,
  DOP: 54.6381,
  DZD: 135.4507,
  EGP: 30.8933,
  ETB: 54.4582,
  EUR: 0.9082,
  FJD: 2.222,
  FKP: 0.7974,
  GBP: 0.7971,
  GEL: 2.4947,
  GIP: 0.7974,
  GMD: 62.5057,
  GNF: 8513.1658,
  GTQ: 7.8022,
  GYD: 211.4714,
  HKD: 7.8498,
  HNL: 24.5864,
  HTG: 153.0891,
  HUF: 338.3218,
  IDR: 14672.1282,
  ILS: 3.6441,
  INR: 81.8418,
  ISK: 135.7599,
  JMD: 153.225,
  JOD: 0.709,
  JPY: 136.2462,
  KES: 135.9425,
  KGS: 87.5672,
  KHR: 4134.9652,
  KMF: 446.7912,
  KRW: 1336.7237,
  KWD: 0.3063,
  KYD: 0.8333,
  KZT: 452.0728,
  LAK: 17189.7753,
  LBP: 15000,
  LKR: 320.3225,
  LRD: 164.2014,
  LSL: 18.3005,
  MAD: 10.0638,
  MDL: 17.9853,
  MGA: 4380.5334,
  MKD: 56.0119,
  MMK: 2097.8223,
  MNT: 3481.1184,
  MOP: 8.0852,
  MUR: 44.8752,
  MVR: 15.4322,
  MWK: 1028.7678,
  MXN: 18.0205,
  MYR: 4.4601,
  MZN: 63.8475,
  NAD: 18.3005,
  NGN: 460.3429,
  NIO: 36.6658,
  NOK: 10.674,
  NPR: 130.9468,
  NZD: 1.619,
  OMR: 0.3845,
  PAB: 1,
  PEN: 3.7142,
  PGK: 3.5226,
  PHP: 55.4211,
  PKR: 283.5283,
  PLN: 4.169,
  PYG: 7206.9529,
  QAR: 3.64,
  RON: 4.4845,
  RSD: 106.5445,
  RUB: 80.3687,
  RWF: 1123.92,
  SAR: 3.75,
  SBD: 8.296,
  SCR: 13.5311,
  SEK: 10.2704,
  SGD: 1.3356,
  SHP: 0.7974,
  SLE: 22.8117,
  SOS: 568.1321,
  SRD: 37.5359,
  SZL: 18.3005,
  THB: 34.1078,
  TJS: 10.8802,
  TND: 3.0422,
  TOP: 2.3348,
  TRY: 19.4741,
  TTD: 6.7796,
  TWD: 30.6946,
  TZS: 2353.4593,
  UAH: 36.9162,
  UGX: 3735.4598,
  USD: 1,
  UYU: 38.6752,
  UZS: 11386.6665,
  VND: 23465.2329,
  VUV: 118.8229,
  WST: 2.727,
  XAF: 595.7216,
  XCD: 2.7,
  XOF: 595.7216,
  XPF: 108.374,
  YER: 250.1805,
  ZAR: 18.2995,
  ZMW: 17.8253,
  current: 'USD',
  uid: 'USD',
};
