import { createSlice } from '@reduxjs/toolkit';

import { defaultCurrencyRate } from '../util/currency';

// Define initial state
export const initialState = {
  fetchingAll: false,
  fetchingOne: false,
  fetchingCurrent: false,
  updating: false,
  deleting: false,
  user: null,
  currentUser: null,
  currency: defaultCurrencyRate,
  tmpUserData: null,
  lastPromtStoreReview: 0,
  lastNotificationClose: 0,
  userList: [],
  errorAll: null,
  errorOne: null,
  errorCurrent: null,
  errorUpdating: null,
  errorDeleting: null,
  isManageRegisterFlow: false,
  userMode: true,
  lastRedirectableRoute: null,
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    userAllRequest: (state) => {
      state.fetchingAll = true;
      state.userList = [];
    },
    userRequest: (state) => {
      state.fetchingOne = true;
      state.user = null;
    },
    userCurrentRequest: (state) => {
      state.fetchingCurrent = true;
    },
    userCurrentLogOut: (state) => {
      state.currentUser = null;
    },
    userUpdateRequest: (state) => {
      state.updating = true;
    },
    userDeleteRequest: (state) => {
      state.deleting = true;
    },
    userCurrencyUpdate: (state, action) => {
      state.currency = action.payload;
    },
    userTmpDataUpdate: (state, action) => {
      state.tmpUserData = action.payload;
    },
    userLastPromtStoreReview: (state, action) => {
      state.lastPromtStoreReview = action.payload;
    },
    userLastNotificationClose: (state, action) => {
      state.lastNotificationClose = action.payload;
    },
    userManagedRegisterFlow: (state, action) => {
      state.isManageRegisterFlow = action.payload;
    },
    userAllSuccess: (state, action) => {
      state.fetchingAll = false;
      state.errorAll = null;
      state.userList = action.payload;
    },
    userSuccess: (state, action) => {
      state.fetchingOne = false;
      state.errorOne = null;
      state.user = action.payload;
    },
    userCurrentSuccess: (state, action) => {
      state.fetchingCurrent = false;
      state.errorCurrent = null;
      state.currentUser = action.payload;
    },
    userUpdateSuccess: (state) => {
      state.updating = false;
      state.errorUpdating = null;
    },
    userDeleteSuccess: (state) => {
      state.deleting = false;
      state.errorDeleting = null;
      state.user = null;
    },
    userAllFailure: (state, action) => {
      state.fetchingAll = false;
      state.errorAll = action.payload;
      state.userList = [];
    },
    userFailure: (state, action) => {
      state.fetchingOne = false;
      state.errorOne = action.payload;
      state.user = null;
    },
    userFailureCurrent: (state, action) => {
      state.fetchingCurrent = false;
      state.errorCurrent = action.payload;
      state.currentUser = null;
    },
    userUpdateFailure: (state, action) => {
      state.updating = false;
      state.errorUpdating = action.payload;
    },
    userDeleteFailure: (state, action) => {
      state.deleting = false;
      state.errorDeleting = action.payload;
    },
    setUserMode: (state, action) => {
      state.userMode = action.payload;
    },
    setLastRedirectableRoute: (state, action) => {
      const route = action.payload;
      if (
        !route?.name ||
        [
          'Register',
          'CustomWebView',
          'VerificationCode',
          'EditProfileScreen',
          'LoginOrSignUp',
          'LoginWithEmail',
          'ForgotPassword',
          'ChangePassword',
          'LoginWithPhone',
          '',
        ].includes(route?.name)
      ) {
        return;
      }
      state.lastRedirectableRoute = route;
    },
  },
});

export const {
  userAllRequest,
  userRequest,
  userCurrentRequest,
  userCurrentLogOut,
  userUpdateRequest,
  userDeleteRequest,
  userCurrencyUpdate,
  userTmpDataUpdate,
  userLastPromtStoreReview,
  userLastNotificationClose,
  userManagedRegisterFlow,
  userAllSuccess,
  userSuccess,
  userCurrentSuccess,
  userUpdateSuccess,
  userDeleteSuccess,
  userAllFailure,
  userFailure,
  userFailureCurrent,
  userUpdateFailure,
  userDeleteFailure,
  setUserMode,
  setLastRedirectableRoute,
} = userSlice.actions;

export default userSlice.reducer;
