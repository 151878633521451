import React, { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, Divider, Heading, HStack, ScrollView, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { errorHandler } from '../../../shared/components/errorHandler/errorHander';
import Image from '../../../shared/components/image/image';
import InfoExtender from '../../../shared/components/info/infoExtender';
import InsuranceDisplay from '../../../shared/components/insurance/insurance';
import { PressableRow } from '../../../shared/components/pressable/pressableRow';
import ImageView from '../../../shared/generic/ImageView';
import { firebaseApp } from '../../../shared/services/firebase/firebaseFactory';
import { getOneReservation } from '../../../shared/services/firebase/reservation-firebase';
import { Metrics } from '../../../shared/themes';
import { checkCarTypes } from '../../../shared/util/check-car-steps';
import { cleanFirestoreListResult } from '../../../shared/util/firestore-utils';

function DriverSummary() {
  const checkCar = useSelector((state) => state.checkCar.checkCar);
  const currentUser = useSelector((state) => state.users.currentUser);

  const [isVisible, setVisible] = useState(false);
  const [imageIndex, setIndex] = useState(0);
  const [imageZoom, setImageZoom] = useState([]);
  const [reservation, setReservation] = useState({});

  const [imagesMileage, setImagesMileage] = useState([]);
  const [imagesInside, setImagesInside] = useState([]);
  const [imagesAdditional, setImagesAdditional] = useState(checkCar?.images?.filter((im) => im?.type === 'ADDITIONAL'));
  const [imagesOutside, setImagesOutside] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    setImagesAdditional(checkCar?.images?.filter((im) => im?.type === 'ADDITIONAL'));
    if (reservation?.uid === undefined) {
      getOneReservation(checkCar?.reservationId).then((r) => setReservation(r));
    }

    if (checkCar?.type === checkCarTypes.signIn) {
      firebaseApp
        .firestore()
        .collection('checkCar')
        .where('reservationId', '==', checkCar?.reservationId)
        .where('type', '==', checkCarTypes.preCheck)
        .get()
        .then(cleanFirestoreListResult)
        .then((res) => {
          if (res?.length > 0) {
            setImagesMileage(res[0]?.images?.filter((im) => im?.part === 'DASHBOARD'));
            setImagesOutside(res[0]?.images?.filter((im) => im?.type === 'OUTSIDE'));
            setImagesInside(res[0]?.images?.filter((im) => im?.type === 'INSIDE'));
          }
        })
        .catch((e) => errorHandler(e, true));
    } else if (checkCar?.type === checkCarTypes.signOut) {
      firebaseApp
        .firestore()
        .collection('checkCar')
        .where('reservationId', '==', checkCar?.reservationId)
        .where('type', '==', checkCarTypes.postCheck)
        .get()
        .then(cleanFirestoreListResult)
        .then((res) => {
          if (res?.length > 0) {
            setImagesMileage(res[0]?.images?.filter((im) => im?.part === 'DASHBOARD'));
            setImagesOutside(res[0]?.images?.filter((im) => im?.type === 'OUTSIDE'));
            setImagesInside(res[0]?.images?.filter((im) => im?.type === 'INSIDE'));
          }
        })
        .catch((e) => errorHandler(e, true));
    }
  }, [checkCar]);

  function zoomInImage(imArr, index) {
    setImageZoom(imArr.map((i) => ({ uri: i?.url })));
    setIndex(index);
    setVisible(true);
  }

  return (
    <>
      <ImageView images={imageZoom} imageIndex={imageIndex} visible={isVisible} onRequestClose={() => setVisible(false)} />
      <VStack space={'md'} my={'$4'}>
        <Text color={'$white'} variant={'grey'} size={'secondary'}>
          {t('checkCar.checkCarDescription')}
        </Text>
        <Heading color={'$white'}>{t('checkCar.outSidePhoto')}</Heading>
        <ScrollView zIndex={10} horizontal showsHorizontalScrollIndicator={false}>
          <HStack space={'sm'}>
            {imagesOutside?.map((image, index) => (
              <TouchableOpacity key={image?.part + '_' + image?.url} onPress={() => zoomInImage(imagesOutside, index)}>
                <VStack space={'sm'}>
                  <Image
                    rounded={'$2xl'}
                    alt={t('checkCarPart.' + image?.part)}
                    height={Metrics.images.xlarge}
                    width={Metrics.images.xxlarge}
                    source={{ uri: image.url }}
                  />
                  <Text color={'$white'} variant={'grey'} size={'secondary'}>
                    {t('checkCarPart.' + image?.part)}
                  </Text>
                </VStack>
              </TouchableOpacity>
            ))}
          </HStack>
        </ScrollView>
        <Heading color={'$white'}>{t('checkCar.inSidePhoto')}</Heading>
        <ScrollView zIndex={10} horizontal showsHorizontalScrollIndicator={false}>
          <HStack space={'sm'}>
            {imagesInside?.map((image, index) => (
              <TouchableOpacity key={image?.part + '_' + image?.url} onPress={() => zoomInImage(imagesInside, index)}>
                <VStack space={'sm'}>
                  <Image
                    rounded={'$2xl'}
                    alt={t('checkCarPart.' + image?.part)}
                    height={Metrics.images.xlarge}
                    width={Metrics.images.xxlarge}
                    source={{ uri: image.url }}
                  />
                  <Text color={'$white'} variant={'grey'} size={'secondary'}>
                    {t('checkCarPart.' + image?.part)}
                  </Text>
                </VStack>
              </TouchableOpacity>
            ))}
          </HStack>
        </ScrollView>
        {imagesAdditional?.length > 0 && (
          <>
            <Heading color={'$white'}>{t('checkCar.additionalPhoto')}</Heading>
            <ScrollView zIndex={10} horizontal showsHorizontalScrollIndicator={false}>
              <HStack space={'sm'}>
                {imagesAdditional?.map((image, index) => (
                  <TouchableOpacity key={image?.part + '_' + image?.url} onPress={() => zoomInImage(imagesOutside, index)}>
                    <Image
                      rounded={'$2xl'}
                      alt={t('checkCarPart.' + image?.part)}
                      height={Metrics.images.xlarge}
                      width={Metrics.images.xxlarge}
                      source={{ uri: image.url }}
                    />
                  </TouchableOpacity>
                ))}
              </HStack>
            </ScrollView>
          </>
        )}
        <InfoExtender
          color={'$white'}
          object={checkCar}
          field={'noteOwner'}
          lang={currentUser?.language}
          limit={0}
          showHeader={false}
          headerText={t('checkCar.addNoteTitle')}
          seeMoreText={t('checkCar.additionalNote')}
        />
        {(checkCar?.type === checkCarTypes.signIn || checkCar?.type === checkCarTypes.signOut) && (
          <VStack space={'md'}>
            <Heading color={'$white'} mt={'$4'}>
              {t('checkCar.odometer')}
            </Heading>
            <Box>
              <PressableRow
                color={'$white'}
                text={t('checkCar.mileage') + ' ' + checkCar.mileage}
                onPress={() => zoomInImage(imagesMileage, 0)}
              />
              <Divider />
              <PressableRow
                color={'$white'}
                text={t('checkCar.fuel') + ' ' + checkCar.fuel + '/4'}
                onPress={() => zoomInImage(imagesMileage, 0)}
              />
              <Divider />
            </Box>
          </VStack>
        )}
        <VStack space={'md'}>
          <Heading color={'$white'} mt={'$4'}>
            {t('addCar.insurance')}
          </Heading>
          <InsuranceDisplay color={'$white'} insuranceDocuments={reservation?.insuranceDocuments} />
        </VStack>
      </VStack>
    </>
  );
}

export default DriverSummary;
