import { firebaseApp } from './firebaseFactory';
import { cleanFirestoreResult } from '../../util/firestore-utils';
import { userDocumentType } from '../../util/userDocument-utils';

export function getOneUser(userId) {
  return firebaseApp.firestore().collection('profiles').doc(userId).get().then(cleanFirestoreResult);
}

export function getDriverDocuments(userId, curUserId) {
  return firebaseApp
    .firestore()
    .collection('users')
    .doc(userId)
    .collection('documents')
    .where('allowedUsers', 'array-contains', curUserId)
    .get()
    .then((querySnapshot) => {
      const cs = [];
      console.info('Total user allow docs: ', querySnapshot.size);
      querySnapshot.forEach((c) => cs.push({ ...c.data(), uid: c.id }));
      return cs;
    });
}

export function getUserDocuments(userId) {
  return firebaseApp
    .firestore()
    .collection('users')
    .doc(userId)
    .collection('documents')
    .get()
    .then((querySnapshot) => {
      const cs = [];
      console.info('Total user docs: ', querySnapshot.size);
      querySnapshot.forEach((c) => cs.push({ ...c.data(), uid: c.id }));
      return cs;
    });
}

export function getUserDriversLicence(userId) {
  return firebaseApp
    .firestore()
    .collection('users')
    .doc(userId)
    .collection('documents')
    .where('type', '==', userDocumentType.driverLicence)
    .get()
    .then((querySnapshot) => {
      const cs = [];
      console.info('Total user driver docs: ', querySnapshot.size);
      querySnapshot.forEach((c) => cs.push({ ...c.data(), uid: c.id }));
      return cs;
    });
}

export function addUserDocuments(userId, document) {
  return firebaseApp.firestore().collection('users').doc(userId).collection('documents').add(document);
}

export function updateUserDocument(userId, documentId, document) {
  return firebaseApp.firestore().collection('users').doc(userId).collection('documents').doc(documentId).update(document);
}
