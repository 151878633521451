import React, { useCallback, useMemo } from 'react';

import { Box, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import FeaturesCard from '../../../shared/components/pressable/features';
import { carAddingSetter } from '../../../shared/reducers/car.reducer';
import { carFeaturesList } from '../../../shared/util/car-utils';

function MoreFeature() {
  const car = useSelector((state) => state.cars.carAdding);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Memoize the calculated features to avoid unnecessary recalculations
  const carFeatures = useMemo(() => {
    const features = car?.features || [];
    return features.filter((featureName) => carFeaturesList.some((feat) => feat.name === featureName));
  }, [car?.features]);

  // Update features using useCallback to memoize the function and prevent unnecessary re-renders
  const changeFeature = useCallback(
    (featureName) => {
      const updatedFeatures = carFeatures.includes(featureName)
        ? carFeatures.filter((cF) => cF !== featureName)
        : [...carFeatures, featureName];

      dispatch(carAddingSetter({ ...car, features: updatedFeatures }));
    },
    [car, carFeatures, dispatch],
  );

  return (
    <VStack space={'md'}>
      <Text mb={'$4'} variant={'grey'} size={'secondary'}>
        {t('addCar.addMoreFeaturesDescription')}
      </Text>
      <HStack flexWrap={'wrap'} space={'sm'}>
        {carFeaturesList.map((feat) => (
          <Box key={feat.name} w={'45%'} mr={'$2'} mb={'$2'}>
            <FeaturesCard
              testID={feat.name}
              iconLibrary={feat.iconLibrary}
              iconName={feat.iconName}
              text={t(feat.translateName)}
              onPress={() => changeFeature(feat.name)}
              isPressed={carFeatures.includes(feat.name)}
            />
          </Box>
        ))}
      </HStack>
    </VStack>
  );
}

export default MoreFeature;
