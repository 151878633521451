import { useCallback, useEffect } from 'react';

import { createDrawerNavigator } from '@react-navigation/drawer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { notFoundScreen, registerScreens, screens, tabScreens } from './screens';
import DrawerContent from '../shared/components/web/drawerContent';
import { supportedLang } from '../shared/util/language-utils';
import { navigationOptionDrawer } from '../shared/util/navigation-utils-drawer';

const Drawer = createDrawerNavigator();

function StackNavigator({ navigation }) {
  const { t, i18n } = useTranslation();

  const currentUser = useSelector((state) => state.users.currentUser);

  useEffect(() => {
    const lang = navigation?.getState().routes[0].name;

    if (lang && supportedLang.includes(lang) && i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDrawer = useCallback((props) => {
    return <DrawerContent {...props} />;
  }, []);

  return (
    <Drawer.Navigator backBehavior="history" defaultStatus="closed" drawerContent={renderDrawer}>
      {currentUser?.uid && !currentUser?.conditionAccepted ? (
        <>
          {registerScreens.map((screen) => (
            <Drawer.Screen
              name={screen.name}
              component={screen.component}
              key={screen.name}
              options={{
                webHeaderShow: true,
                ...navigationOptionDrawer,
                ...screen.options,
                title: t(screen.options.title),
              }}
            />
          ))}
        </>
      ) : (
        <>
          {Object.keys(tabScreens).map((key) => (
            <Drawer.Screen
              name={tabScreens[key].name}
              component={tabScreens[key].component}
              key={tabScreens[key].name}
              options={{
                ...navigationOptionDrawer,
                ...tabScreens[key].options,
                title: t(tabScreens[key].options.title),
              }}
            />
          ))}
          {screens.map((screen) => (
            <Drawer.Screen
              name={screen.name}
              component={screen.component}
              key={screen.name}
              options={{
                ...navigationOptionDrawer,
                ...screen.options,
                title: t(screen.options.title),
              }}
            />
          ))}
          {notFoundScreen.map((screen) => (
            <Drawer.Screen
              name={screen.name}
              component={screen.component}
              key={screen.name}
              options={{
                ...navigationOptionDrawer,
                ...screen.options,
                title: t(screen.options.title),
              }}
            />
          ))}
        </>
      )}
    </Drawer.Navigator>
  );
}

export default StackNavigator;
