import React, { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, Divider, Heading, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { getOneCar } from '../../../services/firebase/car-firebase';
import { getOneUser } from '../../../services/firebase/user-firebase';
import { Metrics } from '../../../themes';
import { getThumbnailUrl, reservationStatus } from '../../../util/car-utils';
import { displayRangeDate, formatDateByLocaleUnix } from '../../../util/date-transforms';
import { resizedImage } from '../../../util/image-utils';
import ReservationBadge from '../../badge/reservationBadge';
import { errorHandler } from '../../errorHandler/errorHander';
import Image from '../../image/image';
import CarRentalCardSkeleton from '../../skeleton/carRentalCardSkeleton';

export default function ReservationBigCard({ reservation, onPress, disabled } = { disabled: true }) {
  const [car, setCar] = useState({ uid: reservation?.carId });
  const [userData, setUserData] = useState({});
  const [isLoading, setLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    console.debug('Effect: refresh reservation.carId');
    setLoading(true);
    getOneCar(reservation?.carId)
      .then((res) => {
        setCar(res);
        setLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoading(false);
      });
  }, [reservation?.carId]);

  useEffect(() => {
    console.debug('Effect: refresh car.ownerId');
    setLoading(true);
    getOneUser(car?.ownerId)
      .then((res) => {
        setUserData(res);
        setLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoading(false);
      });
  }, [car.ownerId]);

  if (isLoading) {
    return <CarRentalCardSkeleton />;
  }

  return (
    <Box variant={'cardContainer'}>
      <TouchableOpacity disabled={disabled} onPress={() => onPress(car, reservation)}>
        <Image
          borderTopLeftRadius={'$2xl'}
          borderTopRightRadius={'$2xl'}
          source={{ uri: resizedImage(getThumbnailUrl(car?.images)) }}
          h={Metrics.images.xxlarge}
          w={'100%'}
          alt={'carThumbnail'}
        />
        <VStack m={'$4'} space={'md'} alignItems={'flex-start'}>
          <VStack>
            <ReservationBadge reservation={reservation} w={'80%'} />
            <Heading>{car?.brand + ' ' + car?.model}</Heading>
            <Text variant={'grey'} size={'secondary'}>
              {t('car.ownBy', { name: userData.firstName + ' ' + userData.lastName })}
            </Text>
          </VStack>
          <Divider />
          <HStack space={'md'} alignItems={'center'}>
            <VStack space={'sm'}>
              <Text>{displayRangeDate(reservation?.from, reservation?.to)}</Text>
              <Text variant={'grey'} size={'secondary'}>
                {formatDateByLocaleUnix(reservation?.from, 'yyyy', false)}
              </Text>
            </VStack>
            <Divider orientation="vertical" />
            <Text size={'secondary'} alignSelf={'center'} w={'50%'}>
              {reservation?.status === reservationStatus.waitingCheckOut
                ? reservation?.delivery?.return?.name
                : reservation?.delivery?.pickUp?.name}
            </Text>
          </HStack>
        </VStack>
      </TouchableOpacity>
    </Box>
  );
}
