import { H1, H2, H3, H4, H5, H6 } from '@expo/html-elements';
import { createStyle } from '@gluestack-style/react';

export const Heading = createStyle({
  color: '$black',
  letterSpacing: '$sm',
  fontWeight: '$medium',
  fontFamily: '$heading',

  // Overrides expo-html default styling
  marginVertical: 0,
  _dark: {
    color: '$textDark50',
  },
  variants: {
    isTruncated: {
      true: {
        props: {
          // @ts-ignore
          numberOfLines: 1,
          ellipsizeMode: 'tail',
        },
      },
    },
    bold: {
      true: {
        fontWeight: '$bold',
      },
    },
    underline: {
      true: {
        textDecorationLine: 'underline',
      },
    },
    strikeThrough: {
      true: {
        textDecorationLine: 'line-through',
      },
    },
    sub: {
      true: {
        fontSize: '$secondary',
        lineHeight: '$secondary',
      },
    },
    italic: {
      true: {
        fontStyle: 'italic',
      },
    },
    highlight: {
      true: {
        bg: '$yellow500',
      },
    },
    size: {
      h1: {
        //@ts-ignore
        props: { as: H1 },
        fontSize: '$h1',
        lineHeight: '$h1',
      },
      h2: {
        //@ts-ignore
        props: { as: H2 },
        fontSize: '$h2',
        lineHeight: '$h2',
      },
      h3: {
        //@ts-ignore
        props: { as: H3 },
        fontSize: '$h3',
        lineHeight: '$h3',
      },
      h4: {
        //@ts-ignore
        props: { as: H4 },
        fontSize: '$h4',
        lineHeight: '$h4',
      },
      h5: {
        //@ts-ignore
        props: { as: H5 },
        fontSize: '$h5',
        lineHeight: '$h5',
      },
      h6: {
        //@ts-ignore
        props: { as: H6 },
        fontSize: '$h6',
        lineHeight: '$h6',
      },

      primary: {
        //@ts-ignore
        props: { as: H4 },
        fontSize: '$primary',
        lineHeight: '$primary',
      },
      secondary: {
        //@ts-ignore
        props: { as: H5 },
        fontSize: '$secondary',
        lineHeight: '$secondary',
      },
      tertiary: {
        //@ts-ignore
        props: { as: H6 },
        fontSize: '$tertiary',
        lineHeight: '$tertiary',
      },
    },
  },

  defaultProps: {
    size: 'h3',
    maxFontSizeMultiplier: 2,
  },
});
