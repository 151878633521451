export const InternalLinks = {
  TOU: 'https://legal.flyandcar.com/terms-and-policies/tou',
  blog: 'https://blog.flyandcar.com/',
  TOR: 'https://legal.flyandcar.com/terms-and-policies/terms-and-conditions-for-rental',
  privacyPolicy: 'https://legal.flyandcar.com/terms-and-policies/privacy-policy',
  rentalAgreement: 'https://legal.flyandcar.com/terms-and-policies/terms-and-conditions-for-rental',
  chargePaymentMethod: 'https://help.flyandcar.com/for-driver/payment/security-deposit',
  helpHome: 'https://help.flyandcar.com/',
  carIssue: 'https://help.flyandcar.com/for-owner/manage-booking/handling-incidents-and-accidents',
  driverGetHelp: 'https://help.flyandcar.com/for-driver/manage-reservation',
  ownerGetHelp: 'https://help.flyandcar.com/for-owner/manage-booking',
  cancellationsPolicie: 'https://legal.flyandcar.com/terms-and-policies/cancellation-policy',
  guideRental: 'https://help.flyandcar.com/for-owner/manage-booking',
  understandingCoverage: 'https://help.flyandcar.com/for-driver/manage-reservation/guide-to-car-coverage',
  playStore: 'https://play.google.com/store/apps/details?id=com.flyandcar.app',
  appStore: 'https://apps.apple.com/app/flyncar/id6449300955',
};
