import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { Box, Button, ButtonText, Heading, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Image from '../../../shared/components/image/image';
import PlaceHolder from '../../../shared/components/placeHolder/placeHolder';
import Skeleton from '../../../shared/components/skeleton/skeleton';
import { quoteSetter } from '../../../shared/reducers/quote.reducer';
import { firebaseApp } from '../../../shared/services/firebase/firebaseFactory';
import { Images } from '../../../shared/themes';
import imagesUrl from '../../../shared/themes/imagesUrl';
import { getThumbnailUrl } from '../../../shared/util/car-utils';
import { dateRangeToArrayDays } from '../../../shared/util/date-transforms';
import { cleanFirestoreListResult } from '../../../shared/util/firestore-utils';
import { resizedImage } from '../../../shared/util/image-utils';

function QuoteSelectCar() {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const navigation = useNavigation();
  const currentUser = useSelector((state) => state.users.currentUser);
  const quote = useSelector((state) => state.quote.quote);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser?.uid) {
      setLoading(true);
      const subscriber = firebaseApp
        .firestore()
        .collection('cars')
        .where('visible', '==', true)
        .where('ownerId', '==', currentUser?.uid)
        .onSnapshot(
          (res) => {
            const cleanData = cleanFirestoreListResult(res);
            if (quote?.dateRange?.from && quote?.dateRange?.to) {
              const days = dateRangeToArrayDays(quote?.dateRange);
              setCars(cleanData.filter((car) => !car?.bookedDays?.some((d) => days.includes(d))));
            } else {
              setCars(cleanData);
            }
            setLoading(false);
          },
          () => setLoading(false),
        );

      return () => subscriber();
    }
  }, [currentUser?.uid, quote?.dateRange]);

  function selectCar(car) {
    dispatch(quoteSetter({ ...quote, carId: car?.uid }));
  }

  return (
    <View>
      {cars?.map((car) => (
        <TouchableOpacity onPress={() => selectCar(car)} key={car?.uid}>
          <HStack p={'$2'} space={'md'} bgColor={quote?.carId === car?.uid ? '$transparent2' : '$transparent'} rounded={'$md'}>
            <Image source={{ uri: resizedImage(getThumbnailUrl(car?.images)) }} size={'md'} rounded={'$md'} />
            <VStack justifyContent={'center'}>
              <Heading size={'h4'}>{car?.brand + ' ' + car?.model}</Heading>
              <Text size={'secondary'}>{t('car.carCardInfo', { type: t('addCar.' + car?.type), fuel: car?.fuel, seats: car?.seats })}</Text>
            </VStack>
          </HStack>
        </TouchableOpacity>
      ))}
      {!loading && cars?.length === 0 && (
        <Box mx={'$screenMargin'} my={'$4'}>
          <PlaceHolder
            image={{ uri: imagesUrl.empty }}
            title={t('placeHolder.noCarTitle')}
            text={t('placeHolder.noCarInfo')}
            element={
              <Button onPress={() => navigation.navigate('AddCarFlow')}>
                <ButtonText>{t('ownerCar.addNewCar')}</ButtonText>
              </Button>
            }
          />
        </Box>
      )}
      {loading && (
        <HStack p={'$2'} space={'md'} rounded={'$md'} alignItems={'center'}>
          <Skeleton h={'$16'} w={'$16'} />
          <Skeleton.Text lines={'$2'} />
        </HStack>
      )}
    </View>
  );
}

export default QuoteSelectCar;
