import React from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, Center, Heading, HStack, Text, useToken, VStack } from '@gluestack-ui/themed';

import { resizedImage } from '../../util/image-utils';
import Image from '../image/image';

export default function Card({ testID, title, subTitle, imageUrl, isDisable, onPress }) {
  const radiiXl = useToken('radii', '2xl');

  return (
    <Box variant={'cardContainer'}>
      <TouchableOpacity
        testID={`button.${testID}`}
        disabled={isDisable}
        onPress={onPress}
        style={{ overflow: 'hidden', borderRadius: radiiXl }}>
        <HStack justifyContent={'space-between'} alignItems={'center'}>
          <VStack space={'xs'} maxWidth={'50%'} mx={'$4'} my={'$2'} py={'5%'}>
            <Heading>{title}</Heading>
            <Text fontSize="$secondary">{subTitle}</Text>
          </VStack>
          <Image resizeMode="cover" h={'$full'} w={'40%'} source={{ uri: resizedImage(imageUrl) }} alt="image" bottom={0} />
        </HStack>
      </TouchableOpacity>
    </Box>
  );
}
