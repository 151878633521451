import { getLocales } from 'expo-localization';
export function userToAccount(user) {
  return {
    displayName: user?.firstName + ' ' + user?.lastName,
    photoURL: user?.photoURL,
  };
}
export function defaultUserData() {
  return {
    countryCode: getLocales()[0]?.regionCode,
    currency: getLocales()[0]?.currencyCode,
    language: getLocales()[0]?.languageCode,
  };
}
