import React, { useMemo } from 'react';

import { Ionicons } from '@expo/vector-icons';
import { Box, Divider, Heading, HStack, Icon, Spinner, Text, useToken, VStack } from '@gluestack-ui/themed';
import { TouchableOpacity } from '@gorhom/bottom-sheet';
import { useTranslation } from 'react-i18next';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { useSelector } from 'react-redux';

import appConfig from '../../../../config/app-config';
import { Colors } from '../../../themes';
import { getPriceFromCurrency } from '../../../util/currency';
import { IS_WEB } from '../../../util/device-utils';
import { distanceBetweenCoord } from '../../../util/place-utils';
import BottomSheetScrollView from '../../bottomSheet/bottomSheetScrollView';

const predefinedPlaces = [
  {
    structured_formatting: {
      main_text: 'Juan Santamaría International Airport',
    },
    description: 'Aéroport international Juan Santamaría, Alajuela Province, Río Segundo, Costa Rica',
    place_id: 'ChIJc3hQUMT5oI8RpDE_DiJie7I',
    reference: 'ChIJc3hQUMT5oI8RpDE_DiJie7I',
    geometry: {
      location: {
        lat: 9.9979207,
        lng: -84.20401489999999,
      },
    },
    types: ['airport', 'point_of_interest', 'establishment'],
  },
  {
    structured_formatting: {
      main_text: 'Daniel Oduber Quirós International Airport.',
    },
    description: 'Daniel Oduber Quirós International Airport., Boulevard Aeropuerto, Guanacaste Province, Liberia, Costa Rica',
    place_id: 'ChIJQZQl7CN-dY8RrKHqXmXX7kY',
    reference: 'ChIJQZQl7CN-dY8RrKHqXmXX7kY',
    geometry: {
      location: {
        lat: 10.6006042,
        lng: -85.53580029999999,
      },
    },
    types: ['airport', 'point_of_interest', 'establishment'],
  },
];

function AeraSelector({ location, setLocation, deliveryLocation, carLocation }) {
  const { t, i18n } = useTranslation();
  const currency = useSelector((state) => state.users.currency);

  const fontSizesPrimary = useToken('fontSizes', 'primary');
  const colorLight400 = useToken('colors', 'light400');
  const radiiXl = useToken('radii', 'xl');

  const predefLoc = useMemo(() => (deliveryLocation && deliveryLocation.length > 0 ? [] : predefinedPlaces), [deliveryLocation]);

  const renderDescription = (data) => {
    let iconName = 'map-outline';
    if (data?.types.includes('airport')) {
      iconName = 'airplane-outline';
    } else if (
      data?.types.includes('train_station') ||
      data?.types.includes('light_rail_station') ||
      data?.types.includes('subway_station')
    ) {
      iconName = 'train-outline';
    } else if (data?.types.includes('bus_station')) {
      iconName = 'bus-outline';
    } else if (data?.types.includes('parking')) {
      iconName = 'car-outline';
    } else if (data?.types.includes('establishment')) {
      iconName = 'business-outline';
    }
    return (
      <HStack testID={'item.deliveryLoc'} space={'sm'}>
        <Icon as={Ionicons} name={iconName} size={'md'} />
        <Text>{data?.description}</Text>
      </HStack>
    );
  };

  function selectDeliveryLocation(loc) {
    setLocation(loc);
  }

  return (
    <>
      <VStack mx={'$screenMargin'} mt={'$4'} space="md">
        <GooglePlacesAutocomplete
          debounce={100}
          disableScroll={false}
          fetchDetails
          listLoaderComponent={<Spinner size={'small'} />}
          GooglePlacesDetailsQuery={{ fields: 'geometry' }}
          GooglePlacesSearchQuery={{
            rankby: 'distance',
          }}
          listViewDisplayed={true}
          placeholder={location?.name ?? t('global.search')}
          renderRow={renderDescription}
          onPress={(data, details = null) => {
            // 'details' is provided when fetchDetails = true
            selectDeliveryLocation({
              placeId: data.place_id,
              location: details?.geometry?.location,
              name: data?.structured_formatting?.main_text,
              description: data?.description,
              radius: distanceBetweenCoord(details?.geometry?.viewport?.northeast, details?.geometry?.viewport?.southwest) / 2,
            });
          }}
          styles={{
            container: {
              flex: IS_WEB ? 1 : 0,
            },
            textInput: {
              fontSize: fontSizesPrimary,
              borderWidth: 1,
              borderColor: colorLight400,
              borderRadius: radiiXl,
            },
            row: {
              paddingLeft: 0,
            },
          }}
          enablePoweredByContainer={false}
          query={{
            key: appConfig.googleMapKey,
            language: i18n.language,
            radius: 2000,
            location: `${carLocation?.lat},${carLocation?.lng}`,
          }}
          requestUrl={{
            useOnPlatform: 'web', // or "all"
            url: 'https://map.flyandcar.com', // or any proxy server that hits https://maps.googleapis.com/maps/api
          }}
          predefinedPlaces={predefLoc}
        />
      </VStack>
      <BottomSheetScrollView>
        <Box mx={'$screenMargin'}>
          {deliveryLocation && deliveryLocation.length > 0 && (
            <VStack space={'sm'} mt={'$4'}>
              <VStack space={'sm'} mt={'$2'}>
                {deliveryLocation &&
                  deliveryLocation?.map((deliveryLoc) => (
                    <TouchableOpacity
                      testID={'item.deliveryLoc'}
                      key={deliveryLoc?.uid}
                      space={'md'}
                      onPress={() => selectDeliveryLocation(deliveryLoc)}>
                      <HStack
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        rounded={'$md'}
                        px={'$2'}
                        bgColor={location?.placeId === deliveryLoc?.placeId ? '$light300' : Colors.transparent}>
                        <Text w={'80%'}>{deliveryLoc?.name}</Text>
                        <Text alignSelf={'center'}>
                          {deliveryLoc?.price
                            ? getPriceFromCurrency(deliveryLoc?.price, deliveryLoc?.currency, currency)
                            : t('global.free')}
                        </Text>
                      </HStack>
                      <Divider mt={'$2'} />
                    </TouchableOpacity>
                  ))}
              </VStack>
            </VStack>
          )}
        </Box>
      </BottomSheetScrollView>
    </>
  );
}

export default AeraSelector;
