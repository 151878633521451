import React, { useEffect, useState } from 'react';
import { RefreshControl, TouchableOpacity } from 'react-native';

import { Box, Divider, Heading, HStack, ScrollView, Spacer, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Image from '../../shared/components/image/image';
import InfoExtender from '../../shared/components/info/infoExtender';
import InsuranceDisplay from '../../shared/components/insurance/insurance';
import PlaceHolder from '../../shared/components/placeHolder/placeHolder';
import { PressableRow } from '../../shared/components/pressable/pressableRow';
import Skeleton from '../../shared/components/skeleton/skeleton';
import ImageView from '../../shared/generic/ImageView';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { getOneReservation } from '../../shared/services/firebase/reservation-firebase';
import { Images, Metrics } from '../../shared/themes';
import imagesUrl from '../../shared/themes/imagesUrl';
import { driverMediaType } from '../../shared/types/checkCar';
import { checkCarTypes } from '../../shared/util/check-car-steps';
import { resizedImage } from '../../shared/util/image-utils';

function CheckCarDescription({ checkCarCheck, checkCarSign, loading }) {
  const currentUser = useSelector((state) => state.users.currentUser);

  const [isVisible, setVisible] = useState(false);
  const [imageIndex, setIndex] = useState(0);
  const [imageZoom, setImageZoom] = useState([]);

  const [reservation, setReservation] = useState({});

  const [imagesOutside, setImagesOutside] = useState([]);
  const [imagesInside, setImagesInside] = useState([]);
  const [imagesMileage, setImagesMileage] = useState([]);
  const [imagesSelfie, setImagesSelfie] = useState([]);
  const [imagesSignature, setImagesSignature] = useState([]);
  const [imagesAdditional, setImagesAdditional] = useState([]);

  const maxWidth = useMaxWidth();
  const { t } = useTranslation();

  useEffect(() => {
    setImagesOutside(checkCarCheck?.images ? checkCarCheck?.images.filter((i) => i?.type === 'OUTSIDE' || i?.type === 'outside') : []);
    setImagesInside(checkCarCheck?.images ? checkCarCheck?.images.filter((i) => i?.type === 'INSIDE' || i?.type === 'inside') : []);
    setImagesMileage(checkCarCheck?.images ? checkCarCheck?.images.filter((i) => i?.part === 'DASHBOARD' || i?.type === 'mileage') : []);
    setImagesSelfie(
      checkCarSign?.images ? checkCarSign.images.filter((i) => i?.type === driverMediaType.selfie || i?.type === 'selfie') : [],
    );
    setImagesAdditional(checkCarSign?.images ? checkCarSign?.images?.filter((im) => im?.type === 'ADDITIONAL') : []);
    setImagesSignature(
      checkCarSign?.images ? checkCarSign?.images.filter((i) => i?.type === driverMediaType.signature || i?.type === 'signature') : [],
    );

    if (reservation?.uid === undefined && checkCarCheck?.reservationId) {
      getOneReservation(checkCarCheck?.reservationId).then((r) => setReservation(r));
    }
  }, [checkCarCheck?.images, checkCarCheck?.reservationId, checkCarSign?.images, reservation?.uid]);

  function zoomInImage(imArr, index) {
    setImageZoom(
      imArr?.length
        ? imArr.map((i) => {
            return { uri: i.url };
          })
        : [{ uri: '' }],
    );
    setIndex(index);
    setVisible(true);
  }

  if (loading) {
    return (
      <VStack space={'md'} my={'$4'} mx={'$screenMargin'}>
        <Skeleton.Text lines={1} />
        <Skeleton.Text lines={1} width={100} />
        <HStack space={'md'}>
          <Skeleton h={Metrics.images.xlarge} w={Metrics.images.xxlarge} rounded={'$lg'} />
          <Skeleton h={Metrics.images.xlarge} w={Metrics.images.xxlarge} rounded={'$lg'} />
        </HStack>
        <Skeleton.Text lines={1} width={100} />
        <HStack space={'md'}>
          <Skeleton h={Metrics.images.xlarge} w={Metrics.images.xxlarge} rounded={'$lg'} />
          <Skeleton h={Metrics.images.xlarge} w={Metrics.images.xxlarge} rounded={'$lg'} />
        </HStack>
        <Skeleton.Text lines={1} width={100} />
        <Skeleton.Text lines={1} />
        <Skeleton.Text lines={1} width={100} />
        <Skeleton.Text lines={1} />
        <Skeleton.Text lines={1} />
      </VStack>
    );
  }

  if (!checkCarCheck?.reservationId && !checkCarSign?.reservationId) {
    return (
      <Box mt={'$16'} mx={'$screenMargin'}>
        <PlaceHolder image={{ uri: imagesUrl.empty }} title={t('placeHolder.noCheckCar')} />
      </Box>
    );
  }

  return (
    <ScrollView contentContainerStyle={{ width: maxWidth > 700 ? 700 : maxWidth, alignSelf: 'center' }}>
      <ImageView images={imageZoom} imageIndex={imageIndex} visible={isVisible} onRequestClose={() => setVisible(false)} />
      <VStack space={'md'} my={'$4'}>
        <Text variant={'grey'} size={'secondary'} mx={'$screenMargin'}>
          {t('checkCar.checkCarDescription')}
        </Text>
        {imagesOutside?.length > 0 && (
          <>
            <Heading mx={'$screenMargin'}>{t('checkCar.outSidePhoto')}</Heading>
            <ScrollView zIndex={10} horizontal showsHorizontalScrollIndicator={false}>
              <HStack space={'sm'}>
                <Box width={'$2'} />
                {imagesOutside?.map((image, index) => (
                  <TouchableOpacity key={image?.part + '_' + image?.url} onPress={() => zoomInImage(imagesOutside, index)}>
                    <VStack space={'sm'}>
                      <Image
                        rounded={'$2xl'}
                        alt={t('checkCarPart.' + image?.part)}
                        height={Metrics.images.xlarge}
                        width={Metrics.images.xxlarge}
                        source={{ uri: resizedImage(image.url) }}
                      />
                      <Text variant={'grey'} size={'secondary'}>
                        {t('checkCarPart.' + image?.part)}
                      </Text>
                    </VStack>
                  </TouchableOpacity>
                ))}
                <Box width={'$2'} />
              </HStack>
            </ScrollView>
          </>
        )}
        {imagesInside?.length > 0 && (
          <>
            <Heading mx={'$screenMargin'}>{t('checkCar.inSidePhoto')}</Heading>
            <ScrollView zIndex={10} horizontal showsHorizontalScrollIndicator={false}>
              <HStack space={'sm'}>
                <Box width={'$2'} />
                {imagesInside?.map((image, index) => (
                  <TouchableOpacity key={image?.part + '_' + image?.url} onPress={() => zoomInImage(imagesInside, index)}>
                    <VStack space={'sm'}>
                      <Image
                        rounded={'$2xl'}
                        alt={t('checkCarPart.' + image?.part)}
                        height={Metrics.images.xlarge}
                        width={Metrics.images.xxlarge}
                        source={{ uri: resizedImage(image.url) }}
                      />
                      <Text variant={'grey'} size={'secondary'}>
                        {t('checkCarPart.' + image?.part)}
                      </Text>
                    </VStack>
                  </TouchableOpacity>
                ))}
                <Box width={'$2'} />
              </HStack>
            </ScrollView>
          </>
        )}
        {imagesAdditional?.length > 0 && (
          <>
            <Heading mx={'$screenMargin'}>{t('checkCar.additionalPhoto')}</Heading>
            <ScrollView zIndex={10} horizontal showsHorizontalScrollIndicator={false}>
              <HStack space={'sm'}>
                <Box width={'$2'} />
                {imagesAdditional?.map((image, index) => (
                  <TouchableOpacity key={image?.part + '_' + image?.url} onPress={() => zoomInImage(imagesOutside, index)}>
                    <Image
                      rounded={'$2xl'}
                      alt={t('checkCarPart.' + image?.part)}
                      height={Metrics.images.xlarge}
                      width={Metrics.images.xxlarge}
                      source={{ uri: resizedImage(image.url) }}
                    />
                  </TouchableOpacity>
                ))}
                <Box width={'$2'} />
              </HStack>
            </ScrollView>
          </>
        )}
        <VStack mx={'$screenMargin'} space={'md'}>
          <InfoExtender
            object={checkCarSign}
            field={'noteOwner'}
            lang={currentUser?.language}
            limit={0}
            showHeader={false}
            headerText={t('checkCar.addNoteTitle')}
            seeMoreText={t('checkCar.additionalNote')}
          />

          {(checkCarSign?.type === checkCarTypes.signIn || checkCarSign?.type === checkCarTypes.signOut) && (
            <VStack space={'md'}>
              <Heading mt={'$4'}>{t('checkCar.odometer')}</Heading>
              <Box>
                <PressableRow text={t('checkCar.mileage') + ' ' + checkCarSign.mileage} onPress={() => zoomInImage(imagesMileage, 0)} />
                <Divider />
                <PressableRow text={t('checkCar.fuel') + ' ' + checkCarSign.fuel + '/4'} onPress={() => zoomInImage(imagesMileage, 0)} />
                <Divider />
              </Box>
              <Heading mt={'$4'}>{t('checkCar.driverSelfieAndSignature')}</Heading>
              <Box>
                <PressableRow text={t('checkCar.driverSelfie')} onPress={() => zoomInImage(imagesSelfie, 0)} />
                <Divider />
                <PressableRow text={t('checkCar.driverSignature')} onPress={() => zoomInImage(imagesSignature, 0)} />
                <Divider />
              </Box>
            </VStack>
          )}
          <VStack space={'md'}>
            <Heading mt={'$4'}>{t('addCar.insurance')}</Heading>
            <InsuranceDisplay insuranceDocuments={reservation?.insuranceDocuments} />
          </VStack>
        </VStack>
      </VStack>
    </ScrollView>
  );
}

export default CheckCarDescription;
