import React, { useEffect, useState } from 'react';

import { Box, HStack, Input, InputField, Text, useToken, VStack } from '@gluestack-ui/themed';
import { InputSlot } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { atoi } from '../../util/conversion';
import { getSymbol } from '../../util/currency';
import BottomSheetTextInput from '../bottomSheet/BottomSheetTextInput';

export default function InputPriceCard({
  testID,
  dolarPrice,
  currency,
  onChangePrice,
  placeHolder,
  label,
  info,
  leftElement,
  rightElement,
  isBottomSheet,
}) {
  const [text, setText] = useState('');
  const [error, setError] = useState(undefined);

  const { t } = useTranslation();
  const fontSizeH1 = useToken('fontSizes', 'h1');

  useEffect(() => {
    console.debug('Effect refresh dolarPrice');
    setText(dolarPrice ? Math.round(dolarPrice * currency[currency?.current]).toString() : '');
  }, [dolarPrice, currency]);

  function changeInput(newPrice) {
    setText(newPrice);
    const newPriceNumber = atoi(newPrice, -1);
    if (newPriceNumber >= 0) {
      onChangePrice(newPriceNumber / currency[currency?.current]);
      setError(undefined);
    } else if (newPrice?.length === 0) {
      setError(t('error.addPrice'));
    } else {
      setError(t('error.addGoodNumberFormat'));
    }
  }

  return (
    <Box variant={'cardGrey'} mt={'$2'}>
      <VStack space={'sm'} alignItems={'center'} m={'$4'}>
        <VStack alignItems={'center'}>
          {isBottomSheet ? (
            <HStack rounded={'$xl'} borderWidth={'$1'} px={'$8'} py={'$4'} space={'md'}>
              <Text alignSelf={'center'} ml={'$4'} size={'h2'}>
                {getSymbol(0, currency.current)}
              </Text>
              <BottomSheetTextInput
                testID={testID}
                style={{ fontSize: fontSizeH1 }}
                leftElement={
                  <Text alignSelf={'center'} ml={'$4'} size={'h2'}>
                    {getSymbol(0, currency.current)}
                  </Text>
                }
                keyboardType={'number-pad'}
                textAlign={'center'}
                defaultValue={text}
                placeholder={placeHolder}
                onChangeText={changeInput}
              />
            </HStack>
          ) : (
            <Input size={'xl'}>
              <InputSlot pl="$3">
                <Text size={'h1'}>{getSymbol(0, currency.current)}</Text>
              </InputSlot>
              <InputField
                testID={testID}
                keyboardType={'number-pad'}
                textAlign={'center'}
                rounded={'$xl'}
                value={text}
                placeholder={placeHolder}
                onChangeText={changeInput}
              />
            </Input>
          )}

          {error !== undefined && (
            <Text alignSelf={'center'} size={'secondary'} color={'$error500'}>
              {error}
            </Text>
          )}
        </VStack>

        {label !== undefined && (
          <Text alignSelf={'center'} variant={'grey'} size={'secondary'}>
            {label}
          </Text>
        )}
        {info !== undefined && (
          <Text alignSelf={'center'} mt={'$2'} size={'secondary'} textAlign={'center'} maxWidth={'80%'}>
            {info}
          </Text>
        )}
      </VStack>
    </Box>
  );
}
