import React, { useEffect, useState } from 'react';

import { Box } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import LoginPlaceHolder from '../../shared/components/entities/user/loginPlaceHolder';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Layout from '../../shared/components/layout/layout';
import ModalSwitcher from '../../shared/components/modalSwitcher/modalSwitcher';
import Steps from '../../shared/components/steps/steps';
import successToast from '../../shared/components/toast/successToast';
import { carAddingSetter } from '../../shared/reducers/car.reducer';
import { setUserMode, userUpdateRequest } from '../../shared/reducers/user.reducer';
import { addOneCar } from '../../shared/services/firebase/car-firebase';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { analyticsAddCar } from '../../shared/util/analytics';
import { addCarStep } from '../../shared/util/car-step-list';
import { carDefaultField } from '../../shared/util/car-utils';

function AddCarFlow() {
  const car = useSelector((state) => state.cars.carAdding);
  const currentUser = useSelector((state) => state.users.currentUser);

  const dispatch = useDispatch();

  const [isSwitchModalOpen, setSwitchModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const navigation = useNavigation();

  useEffect(() => {
    console.debug('Effect addCar setup');
    if (car?.uid) {
      dispatch(carAddingSetter({ ...carDefaultField(), ownerId: firebaseApp.auth()?.currentUser?.uid, language: currentUser?.language }));
    } else {
      dispatch(
        carAddingSetter({ ...carDefaultField(), ownerId: firebaseApp.auth()?.currentUser?.uid, language: currentUser?.language, ...car }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  function switchUserMode(carId) {
    setSwitchModalOpen(true);
    dispatch(userUpdateRequest({ uid: firebaseApp.auth()?.currentUser?.uid, userMode: false }));
    setTimeout(() => {
      dispatch(setUserMode(false));
      setSwitchModalOpen(false);
      navigation.canGoBack() && navigation.goBack();
      navigation.navigate('OwnerCarsDetailScreen', { carId: carId });
    }, 500);
  }

  function addCar() {
    setLoading(true);
    addOneCar({ ...car, ownerId: firebaseApp.auth()?.currentUser?.uid })
      .then((added) => {
        successToast(t('addCar.success'));
        analyticsAddCar({ ...car, uid: added.uid });
        dispatch(carAddingSetter({}));
        switchUserMode(added.uid);
        setLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoading(false);
      });
  }

  if (!firebaseApp.auth().currentUser?.uid) {
    return (
      <Layout maxWidth={700}>
        <Box m={'$4'}>
          <LoginPlaceHolder />
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Steps loading={loading} routes={addCarStep.filter((s) => s?.atCreation)} onFinish={addCar} dataToValidate={car} />
      <ModalSwitcher isOpen={isSwitchModalOpen} setOpen={setSwitchModalOpen} text={t('profile.switchToPro')} />
    </Layout>
  );
}

export default AddCarFlow;
