import React, { useState } from 'react';

import { Box, Divider } from '@gluestack-ui/themed';
import * as Linking from 'expo-linking';
import { useTranslation } from 'react-i18next';

import { Metrics } from '../../themes';
import ActionSheet from '../actionSheet/actionSheet';
import { PressableRow } from '../pressable/pressableRow';

export default function InsuranceDisplay({ insuranceDocuments, color }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <PressableRow
        color={color}
        leftIconColor={color}
        leftIconName={'document-text-outline'}
        text={t('checkCar.insuranceDocument')}
        onPress={() => setOpen(true)}
      />
      <Divider />
      <ActionSheet isOpen={open} setOpen={setOpen} headerText={t('checkCar.insuranceDocument')}>
        {insuranceDocuments &&
          insuranceDocuments.length > 0 &&
          insuranceDocuments.map((doc, i) => (
            <Box key={doc?.url} mx={'$screenMargin'}>
              <PressableRow text={t('checkCar.insuranceDocumentNumber', { number: i + 1 })} onPress={() => Linking.openURL(doc.url)} />
              <Divider />
            </Box>
          ))}
      </ActionSheet>
    </>
  );
}
