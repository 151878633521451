import React, { useState } from 'react';

import { Ionicons } from '@expo/vector-icons';
import { Button, ButtonIcon, ButtonSpinner, ButtonText } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import appConfig from '../../../../config/app-config';
import { useIsDrawer } from '../../../provider/isDrawerProvider';
import { getRoom } from '../../../services/firebase/function-firebase';
import { errorHandler } from '../../errorHandler/errorHander';
import errorToast from '../../toast/errorToast';

function MessageButton({ carId, userToContact, text, variant, size, isSupport }) {
  const [contactLoading, setContactLoading] = useState(false);

  const currentUser = useSelector((state) => state.users.currentUser);

  const navigation = useNavigation();
  const { t } = useTranslation();
  const isDrawer = useIsDrawer();

  function contactUser() {
    if (currentUser?.uid && currentUser?.uid !== appConfig.supportId) {
      setContactLoading(true);
      getRoom(userToContact, carId)
        .then((res) => {
          setContactLoading(false);
          if (isDrawer) {
            navigation.navigate('Message', { roomId: res.uid });
          } else {
            navigation.navigate('ChatScreen', { roomId: res.uid });
          }
        })
        .catch((e) => {
          setContactLoading(false);
          errorHandler(e, true);
        });
    } else {
      errorToast(t('error.needToBeLoggedIn'));
    }
  }
  return (
    <Button size={size ?? 'md'} variant={variant ? variant : 'outline'} isDisabled={contactLoading} onPress={contactUser}>
      {contactLoading && <ButtonSpinner mr="$1" />}
      {isSupport && !contactLoading && <ButtonIcon as={Ionicons} name="information-circle-outline" color={'$black'} />}
      <ButtonText>{isSupport ? t('global.contactSupport') : text}</ButtonText>
    </Button>
  );
}

export default MessageButton;
