import { IS_WEB } from '../util/device-utils';

const fontConfig = {
  Heebo: {
    100: {
      normal: 'Heebo_400Regular',
    },
    200: {
      normal: 'Heebo_400Regular',
    },
    300: {
      normal: 'Heebo_400Regular',
    },
    400: {
      normal: 'Heebo_400Regular',
    },
    500: {
      normal: 'Heebo_500Medium',
    },
    600: {
      normal: 'Heebo_500Medium',
    },
    700: {
      normal: 'Heebo_500Medium',
    },
    800: {
      normal: 'Heebo_500Medium',
    },
  },
};

const fonts = {
  heading: 'Heebo',
  body: 'Heebo',
  mono: 'Heebo',
  customFont: 'Heebo',
};

const sizes = {
  secondary: 14,
  primary: 16,

  h5: 14,
  h4: 16,
  h3: 20,
  h2: 24,
  h1: 28,
};

export default {
  sizes,
  fonts,
  fontConfig,
};
