import { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Heading, HStack, Icon, Link, LinkText, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { showLocation } from 'react-native-map-link';
import { useSelector } from 'react-redux';

import { getPriceFromCurrency } from '../../util/currency';
import { IS_WEB } from '../../util/device-utils';
import { distanceBetweenCoord } from '../../util/place-utils';
import BadgeText from '../badge/badgeText';
import AeraPicker from '../entities/aera/aeraPicker';
import InfoExtender from '../info/infoExtender';
import RadiusMap from '../map/radiusMap';
import UnderLineButton from '../pressable/underLineButton';
import Skeleton from '../skeleton/skeleton';

function DeliveryLocationDisplay({ car, loading, location, setLocation, setDeliveryOk, isReadOnly }) {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.users.currentUser);
  const currency = useSelector((state) => state.users.currency);

  const [isOpen, setOpen] = useState(false);
  const [isError, setError] = useState(false);
  const [isSelected, setSelected] = useState(false);

  useEffect(() => {
    if (!location?.pickUp?.location?.lat || !location?.pickUp?.location?.lng) {
      setError(true);
      setSelected(false);
      setDeliveryOk && setDeliveryOk(false);
      return;
    }
    setSelected(true);
    const distancePickUp = distanceBetweenCoord(location?.pickUp?.location, car._geoloc);
    const distanceReturn = distanceBetweenCoord(location?.return?.location, car._geoloc);
    const allDelLoc = car?.deliveryLocation ?? [];
    if (allDelLoc.some((d) => d?.placeId === location?.pickUp?.placeId) || distancePickUp < car?.deliveryRadius * 1000) {
      if (
        !location?.return?.location ||
        allDelLoc.some((d) => d?.placeId === location?.return?.placeId) ||
        distanceReturn < car?.deliveryRadius * 1000
      ) {
        setError(false);
        setDeliveryOk && setDeliveryOk(true);
      } else {
        setError(true);
        setDeliveryOk && setDeliveryOk(false);
      }
    } else {
      setError(true);
      setDeliveryOk && setDeliveryOk(false);
    }
  }, [location?.pickUp, location?.return, car, setDeliveryOk]);

  if (loading) {
    return (
      <VStack space={'sm'}>
        <HStack space={'sm'} alignItems={'center'} justifyContent={'space-between'}>
          <Skeleton w={'30%'} h={'$6'} rounded={'$full'} />
          <Skeleton w={'30%'} h={'$6'} rounded={'$full'} />
        </HStack>
        <Skeleton.Text lines={1} />
        <RadiusMap />
        <Skeleton.Text lines={1} />
      </VStack>
    );
  }

  function onDeliveryClick(place) {
    if (IS_WEB) {
      window.open(`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${place?.placeId}`, 'blank');
    } else {
      showLocation({ latitude: place?.location?.lat, longitude: place?.location?.lng });
    }
  }

  return (
    <>
      <VStack space={'md'}>
        <HStack space={'sm'} alignItems={'center'} justifyContent={'space-between'}>
          <HStack space={'sm'} alignItems={'center'}>
            <Heading alignSelf={'center'} alignContent={'center'}>
              {t('car.delivery')}
            </Heading>
            {!isError && isSelected && !isReadOnly && (
              <HStack alignItems={'center'} space={'sm'}>
                <Text alignSelf={'center'}>-</Text>
                <Text alignSelf={'center'}>
                  {location?.pickUp?.price || location?.return?.price
                    ? getPriceFromCurrency(
                        (location?.pickUp?.price ?? 0) + (location?.return ? location?.return?.price ?? 0 : location?.pickUp?.price ?? 0),
                        location?.pickUp?.currency,
                        currency,
                      )
                    : t('global.free')}
                </Text>
              </HStack>
            )}
          </HStack>
          {!isReadOnly && <UnderLineButton hideLeftIcon={true} text={t('car.selectLocation')} onPress={() => setOpen(true)} />}
        </HStack>
        {location?.return && location?.pickUp?.placeId !== location?.return?.placeId ? (
          <VStack space={'sm'}>
            {!isError && isSelected && !isReadOnly && <Text>{t('car.deliveryOptionSubTitle')}</Text>}
            {!isReadOnly && isError && isSelected && <BadgeText colorScheme={'error'}>{t('car.deliveryNotPossible')}</BadgeText>}
            {!isReadOnly && isError && !isSelected && <BadgeText colorScheme={'error'}>{t('car.selectDelivery')}</BadgeText>}

            <HStack space="sm">
              <Box h={80} w={80}>
                <RadiusMap height={80} coord={car?._geoloc ?? {}} radius={car?.deliveryRadius ?? 10} toMap={isReadOnly} />
              </Box>
              <VStack space="xs" justifyContent="space-between" w={'60%'}>
                <Link onPress={() => onDeliveryClick(location?.pickUp)}>
                  <HStack space="md">
                    <Icon as={Ionicons} name={'location-sharp'} color="$primary900" />
                    <LinkText size={'secondary'} flexWrap={'wrap'} numberOfLines={1}>
                      {location?.pickUp?.name}
                    </LinkText>
                  </HStack>
                </Link>
                <HStack>
                  <Icon as={Ionicons} name={'ellipsis-vertical'} />
                </HStack>
                <Link onPress={() => onDeliveryClick(location?.return)}>
                  <HStack space="md">
                    <Icon as={Ionicons} name={'location-sharp'} color="$primary700" />
                    <LinkText size={'secondary'} flexWrap={'wrap'} numberOfLines={1}>
                      {location?.return?.name}
                    </LinkText>
                  </HStack>
                </Link>
              </VStack>
            </HStack>

            {!isError && isSelected && (
              <InfoExtender
                headerText={t('car.delivery')}
                key={location?.pickUp?.uid ?? 'carLocationInfo'}
                object={car}
                field={location?.pickUp?.uid ?? 'carLocationInfo'}
                lang={currentUser?.language}
                loading={loading}
                limit={100}
                showHeader={false}
              />
            )}
          </VStack>
        ) : (
          <VStack space={'sm'}>
            {!isError && isSelected && !isReadOnly && <Text>{t('car.deliveryOptionSubTitle')}</Text>}
            {!isReadOnly && isError && isSelected && <BadgeText colorScheme={'error'}>{t('car.deliveryNotPossible')}</BadgeText>}
            {!isReadOnly && isError && !isSelected && <BadgeText colorScheme={'error'}>{t('car.selectDelivery')}</BadgeText>}
            {!isError && isSelected && (
              <Link onPress={() => onDeliveryClick(location?.pickUp)}>
                <HStack space="md" w={'80%'}>
                  <Icon as={Ionicons} name={'location-sharp'} color="$primary900" />
                  <LinkText size={'secondary'} flexWrap={'wrap'} numberOfLines={1}>
                    {location?.pickUp?.name}
                  </LinkText>
                </HStack>
              </Link>
            )}
            <RadiusMap
              coord={car?._geoloc ?? {}}
              radius={car?.deliveryRadius ?? 10}
              locationCoord={location?.pickUp?.location}
              toMap={isReadOnly}
            />
            {!isError && isSelected && (
              <InfoExtender
                headerText={t('car.delivery')}
                key={location?.pickUp?.uid ?? 'carLocationInfo'}
                object={car}
                field={location?.pickUp?.uid ?? 'carLocationInfo'}
                lang={currentUser?.language}
                loading={loading}
                limit={100}
                showHeader={false}
              />
            )}
          </VStack>
        )}
      </VStack>
      <AeraPicker
        isOpen={isOpen}
        setOpen={setOpen}
        location={location}
        setLocation={setLocation}
        deliveryLocation={car?.deliveryLocation}
        carLocation={car?._geoloc}
      />
    </>
  );
}

export default DeliveryLocationDisplay;
