import React from 'react';

import { Text } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { InternalLinks } from '../../util/link-utils';

export default function PaymentLegalText() {
  const { t } = useTranslation();
  const navigation = useNavigation();

  return (
    <Text size={'secondary'} space={'xs'}>
      <Text size={'secondary'}>{t('reservation.confirmAndPayInfoPrefix')}</Text>{' '}
      <Text
        size={'secondary'}
        style={[{ textDecorationLine: 'underline' }]}
        onPress={() => navigation.navigate('CustomWebView', { url: InternalLinks.TOU, title: t('reservation.confirmAndPayInfoLink') })}>
        {t('reservation.confirmAndPayInfoLink')}
      </Text>
      {', '}
      <Text
        size={'secondary'}
        style={[{ textDecorationLine: 'underline' }]}
        onPress={() => navigation.navigate('CustomWebView', { url: InternalLinks.TOU, title: t('legal.termOfUse') })}>
        {t('legal.termOfUse')}
      </Text>
      {', '}
      <Text
        size={'secondary'}
        style={[{ textDecorationLine: 'underline' }]}
        onPress={() => navigation.navigate('CustomWebView', { url: InternalLinks.privacyPolicy, title: t('legal.privacyPolicy') })}>
        {t('legal.privacyPolicy')}
      </Text>{' '}
      <Text size={'secondary'}>{t('reservation.confirmAndPayChargePaymentInfoPrefix')}</Text>{' '}
      <Text
        size={'secondary'}
        style={[{ textDecorationLine: 'underline' }]}
        onPress={() =>
          navigation.navigate('CustomWebView', { url: InternalLinks.chargePaymentMethod, title: t('legal.chargePaymentMethod') })
        }>
        {t('legal.chargePaymentMethod')}
      </Text>{' '}
      <Text size={'secondary'}>{t('reservation.confirmAndPayChargePaymentInfoSufix')}</Text>
    </Text>
  );
}
