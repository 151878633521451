import { Toast, ToastDescription } from '@gluestack-ui/themed';

import { globalToast } from '../../provider/toastProvider';

export default function errorToast(message) {
  globalToast()?.show({
    placement: 'top',
    duration: 4000,
    avoidKeyboard: true,
    render: ({ id }) => {
      const toastId = 'toast-' + id;
      return (
        <Toast nativeID={toastId} action="error" variant="accent">
          <ToastDescription>{message}</ToastDescription>
        </Toast>
      );
    },
  });
}
