const colors = {
  blue: {
    50: '#CEDEF4',
    100: '#b7e0f7',
    200: '#97cff0',
    300: '#79bee6',
    400: '#58afe0',
    500: '#4aa2d6',
    600: '#3e97ca',
    700: '#276183',
    800: '#20455b',
    900: '#152a36',
  },
  green: {
    50: '#8eeca7',
    100: '#72e18f',
    200: '#57d478',
    300: '#3fc463',
    400: '#34a853',
    500: '#34904c',
    600: '#337946',
    700: '#30643e',
    800: '#2c5136',
    900: '#273e2d',
  },
  transparent: 'rgba(0,0,0,0)',
  transparent2: 'rgba(0,0,0,0.2)',
  transparent3: 'rgba(0,0,0,0.8)',
  red: {
    50: '#f97c7c',
    100: '#f25c5c',
    200: '#e83d3d',
    300: '#dc2222',
    400: '#b71d1d',
    500: '#9f1f1f',
    600: '#872121',
    700: '#6f2020',
    800: '#591f1f',
    900: '#451c1c',
  },
  light: {
    50: '#fafaf9',
    100: '#F2F2F2',
    200: '#e7e5e4',
    300: '#DBDBDB',
    400: '#a8a29e',
    500: '#757575',
    600: '#57534e',
    700: '#44403c',
    800: '#292524',
    900: '#1c1917',
  },
  gold: '#FFD335',
  white: '#FBFBFB',
  fullWhite: '#FFFFFF',
  lightText: '#FBFBFB',
  black: '#1E1E1E',
  darkText: '#1E1E1E',
};

export default colors;
