import React from 'react';

import { Box } from '@gluestack-ui/themed';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export default function BottomAction({ children, onLayout }) {
  const { bottom } = useSafeAreaInsets();

  return (
    <Box
      onLayout={onLayout}
      bgColor={'$white'}
      position={'absolute'}
      bottom={0}
      w={'$full'}
      borderTopWidth={1}
      borderTopColor={'$light200'}>
      {children}
      <Box h={bottom} bgColor={'$white'} />
    </Box>
  );
}
