import React, { useState } from 'react';

import { Center } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { displayRangeDate } from '../../util/date-transforms';
import CalendarSheet from '../calendar/calendarSheet';
import UnderLineButton from '../pressable/underLineButton';

export default function CarDateRangeSelector({ dateRange, setDateRange, bookedDays, priceData }) {
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <Center>
      <UnderLineButton
        testID={'selectDates'}
        hideLeftIcon={true}
        onPress={() => setCalendarOpen(true)}
        text={dateRange?.from && dateRange?.to ? displayRangeDate(dateRange?.from, dateRange?.to, 'small') : t('car.selectTimeRange')}
      />
      <CalendarSheet
        priceData={priceData}
        bookedDays={bookedDays}
        isOpen={isCalendarOpen}
        setOpen={setCalendarOpen}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
    </Center>
  );
}
