import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, ButtonText, Divider, Heading, HStack, ScrollView, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { goBackOrDefault } from '../../navigation/nav-ref';
import ActionSheet from '../../shared/components/actionSheet/actionSheet';
import BottomAction from '../../shared/components/bottomAction/bottomAction';
import SearchParamsHooker from '../../shared/components/carSearchBar/searchParamsHooker';
import DeliveryLocationDisplay from '../../shared/components/deliveryLocation/deliveryLocationDisplay';
import MessageButton from '../../shared/components/entities/message/messageButton';
import CarReviews from '../../shared/components/entities/review/carReviews';
import ProfileCard from '../../shared/components/entities/user/ProfileCard';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import ChooseExtraOption from '../../shared/components/extraOption/chooseExtraOption';
import CarDateRangeSelector from '../../shared/components/info/carDateRangeSelector';
import CarDetailBar from '../../shared/components/info/carDetailBar';
import CarDetailCover from '../../shared/components/info/carDetailCover';
import CarFeatures from '../../shared/components/info/carFeatures';
import InfoExtender from '../../shared/components/info/infoExtender';
import PriceDisplay from '../../shared/components/info/priceDisplay';
import PriceDisplayTotal from '../../shared/components/info/priceDisplayTotal';
import InsuranceDetail from '../../shared/components/insurance/insuranceDetail';
import Layout from '../../shared/components/layout/layout';
import UnderLineButton from '../../shared/components/pressable/underLineButton';
import Skeleton from '../../shared/components/skeleton/skeleton';
import Footer from '../../shared/components/web/footer';
import WebSpliter from '../../shared/components/web/webSpliter';
import { useIsDrawer } from '../../shared/provider/isDrawerProvider';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { getOneCar } from '../../shared/services/firebase/car-firebase';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import { Metrics } from '../../shared/themes';
import { IS_WEB } from '../../shared/util/device-utils';
import { cleanFirestoreResult } from '../../shared/util/firestore-utils';
import { renderDone } from '../../shared/util/web.utils';

function CarDetailScreen({ route, hideBooking }) {
  const { t, i18n } = useTranslation();

  const isMobile = !useIsDrawer();
  const { carId, hideBookingAction } = route?.params ?? { hideBookingAction: hideBooking };
  const [isOptionOpen, setOptionOpen] = useState(false);
  const [deliveryOk, setDeliveryOk] = useState(false);
  const [loading, setLoading] = useState(true);
  const [car, setCar] = useState({});
  const [owner, setOwner] = useState({});
  const [loadingOwner, setLoadingOwner] = useState(true);
  const [bottomActionHeight, setBottomActionHeight] = useState(0);
  const [selectedExtraOption, setSelectedExtraOptions] = useState([]);
  const navigation = useNavigation();
  const maxWidth = useMaxWidth();

  const currentUser = useSelector((state) => state.users.currentUser);
  const currency = useSelector((state) => state.users.currency);
  const searchLocation = useSelector((state) => state.search.location);
  const searchDateRange = useSelector((state) => state.search.dateRange);

  const [location, setLocationState] = useState(searchLocation);
  const [dateRange, setDateRange] = useState(searchDateRange);

  useEffect(() => {
    console.debug('Effect: refresh carId');
    setLoading(true);
    if (carId && (carId !== car?.uid || !car?.brand)) {
      getOneCar(carId)
        .then((res) => {
          setCar(res);
          if (!location?.pickUp?.placeId && res?.placeId) {
            const newLocation = {
              pickUp: { placeId: res?.placeId, location: res?._geoloc, name: res?.placeName },
              return: { placeId: res?.placeId, location: res?._geoloc, name: res?.placeName },
            };
            setLocationState(newLocation);
            // dispatch(setLocation(newLocation));
          }
          setLoading(false);
          renderDone();
        })
        .catch((e) => {
          errorHandler(e, true);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [carId, car?.uid, car?.brand, location?.pickUp?.placeId]);

  useEffect(() => {
    console.debug('Effect: refresh owner');
    if (car?.ownerId) {
      setLoadingOwner(true);
      firebaseApp
        .firestore()
        .collection('profiles')
        .doc(car?.ownerId)
        .get()
        .then(cleanFirestoreResult)
        .catch((e) => {
          errorHandler(e, true);
          setLoadingOwner(false);
        })
        .then((res) => {
          setOwner(res);
          setLoadingOwner(false);
        });
    }
  }, [car?.ownerId]);

  useEffect(() => {
    if (searchLocation?.pickUp?.placeId) {
      setLocationState(searchLocation);
    }
  }, [searchLocation]);

  const refreshLocation = (newLocation) => {
    setLocationState(newLocation);
    // dispatch(setLocation(newLocation));
  };

  const setNewDateRange = (newDateRange) => {
    setDateRange(newDateRange);
    // dispatch(setDateRange(newDateRange));
  };

  const comparedIsThumbnail = (a, b) => {
    return a.isThumbnail ? -1 : 1;
  };

  const renderLeft = useCallback(
    () => (
      <VStack space={'lg'}>
        <Divider />
        <InfoExtender
          object={car}
          field={'carDescription'}
          loading={loading}
          limit={300}
          showHeader={false}
          headerText={t('car.description')}
        />

        <Divider />
        <VStack>
          <VStack space={'md'}>
            {loading ? <Skeleton h={'$8'} w={'70%'} rounded={'$full'} /> : <Heading>{t('car.whatThisCarOffer')}</Heading>}
            <CarFeatures features={car.features} mode={'small'} loading={loading} />
            {loading ? (
              <Skeleton h={'$10'} w={'100%'} rounded={'$md'} />
            ) : (
              <Button variant={'outline'} onPress={() => setOptionOpen(true)}>
                <ButtonText>{t('car.showAllOptions')}</ButtonText>
              </Button>
            )}
          </VStack>
          <ActionSheet isOpen={isOptionOpen} setOpen={setOptionOpen} headerText={t('car.whatThisCarOffer')}>
            <Box mx={'$4'}>
              <CarFeatures features={car?.features} />
            </Box>
          </ActionSheet>
        </VStack>
        <Divider />
        <InfoExtender
          object={car}
          field={'carGuidelines'}
          loading={loading}
          limit={300}
          showHeader={true}
          headerText={t('car.ownerGuidlines')}
        />
        <Divider />
        <DeliveryLocationDisplay
          car={car}
          loading={loading}
          location={location}
          setLocation={refreshLocation}
          setDeliveryOk={setDeliveryOk}
        />
        <Divider />
        <InsuranceDetail insurances={car?.insurances ?? []} />
        <ChooseExtraOption
          car={car}
          lang={currentUser?.language}
          selectedExtraOption={selectedExtraOption}
          setSelectedExtraOptions={setSelectedExtraOptions}
        />
        <VStack space={'md'}>
          <Heading>{t('car.owner')}</Heading>
          <ProfileCard
            profile={owner}
            loading={loadingOwner}
            onPress={() => navigation.navigate('PublicProfileScreen', { userId: car.ownerId })}
          />
          <MessageButton text={t('ownerRequest.message')} carId={car.uid} userToContact={owner?.uid} />
        </VStack>
      </VStack>
    ),
    [car, currentUser?.language, isOptionOpen, loading, loadingOwner, location, navigation, owner, selectedExtraOption, t],
  );

  const renderRight = useCallback(
    () => (
      <VStack mx={'$4'} space={'md'} position="sticky" top={'$8'} alignSelf="flex-end">
        {!hideBookingAction && !isMobile ? (
          <Box variant={'cardContainer'} p={'$4'} maxWidth={400} alignSelf={'center'}>
            <VStack space={'md'}>
              <HStack justifyContent={'space-between'} alignItems={'center'} space={'md'}>
                <PriceDisplay priceData={car?.priceData} carCurrency={car?.currency} currency={currency} dateRange={dateRange} />
                <CarDateRangeSelector
                  dateRange={dateRange}
                  setDateRange={setNewDateRange}
                  bookedDays={car.bookedDays}
                  priceData={car?.priceData}
                />
              </HStack>
              <VStack space={'sm'}>
                <Button
                  isDisabled={!dateRange.from || !dateRange.to || !deliveryOk}
                  px={Metrics.baseMargin}
                  onPress={() =>
                    navigation.navigate('ConfirmAndPay', {
                      carId: car.uid,
                      from: dateRange.from,
                      to: dateRange.to,
                      checkInHour: dateRange.checkInHour,
                      checkInMinute: dateRange.checkInMinute,
                      checkOutHour: dateRange.checkOutHour,
                      checkOutMinute: dateRange.checkOutMinute,
                      extraOptions: selectedExtraOption,
                      pickUpPlaceId: location?.pickUp?.placeId,
                      returnPlaceId: location?.return?.placeId ?? location?.pickUp?.placeId,
                    })
                  }>
                  <ButtonText>{t('global.book')}</ButtonText>
                </Button>
                <Text variant={'grey'} size={'secondary'}>
                  {t('reservation.confirmUntilOwnerConfirmInfo')}
                </Text>
              </VStack>
              {dateRange?.from && dateRange?.to && (
                <VStack space={'md'}>
                  <Divider />
                  <HStack space={'md'} justifyContent={'space-between'}>
                    <Text size={'h3'}>{t('reservation.total', { currency: currency.current })}</Text>
                    <PriceDisplayTotal
                      priceData={car?.priceData}
                      carCurrency={car?.currency}
                      currency={currency}
                      dateRange={dateRange}
                      selectedExtraOption={selectedExtraOption}
                      location={location}
                    />
                  </HStack>
                </VStack>
              )}
            </VStack>
          </Box>
        ) : null}
      </VStack>
    ),
    [
      car.bookedDays,
      car?.currency,
      car?.priceData,
      car.uid,
      currency,
      dateRange,
      deliveryOk,
      hideBookingAction,
      isMobile,
      location,
      navigation,
      selectedExtraOption,
      t,
    ],
  );

  return (
    <Layout
      title={t('metaTag.titleBy', {
        title: `${car?.brand} ${car?.model}`,
        ownerName: `${owner?.firstName} ${owner?.lastName}`,
        location: `${car?.placeDescription ?? car?.placeName}`,
        seats: car?.seats,
        type: car?.type,
      })}
      description={
        car?.translatedContent?.carDescription && i18n.language in (car?.translatedContent?.carDescription || [])
          ? car?.translatedContent?.carDescription[i18n.language]
          : car?.originalContent?.carDescription
      }
      image={car?.images && car?.images.sort(comparedIsThumbnail)[0].url}>
      <SearchParamsHooker />
      <ScrollView mb={bottomActionHeight} contentContainerStyle={{ alignSelf: 'center', width: maxWidth }}>
        <VStack mx={isMobile ? 0 : Metrics.screenWidthMarging} mb={'$4'}>
          <CarDetailCover
            loading={loading}
            isMobile={isMobile}
            images={car?.images}
            carId={car?.uid}
            onClosePress={() => goBackOrDefault('Search')}
          />
        </VStack>
        <VStack mb={'$4'} mx={'$screenMargin'} space={'lg'}>
          <CarDetailBar loading={loading} size={'h2'} car={car} currency={currency} fullReview={true} hidePrice={true} />
          <WebSpliter left={renderLeft()} right={renderRight()} space={'md'} />

          {car?.numRatings > 0 && (
            <VStack space={'lg'}>
              <Divider />
              <HStack justifyContent={'space-between'}>
                <Heading>{t('car.reviews')}</Heading>
                <UnderLineButton
                  hideLeftIcon={true}
                  onPress={() => navigation.navigate('AllReview', { carId: carId })}
                  text={t('global.seeMore')}
                />
              </HStack>
              <CarReviews carId={car.uid} limit={5} />
            </VStack>
          )}
        </VStack>
        {IS_WEB && <Footer />}
      </ScrollView>
      {isMobile && (
        <BottomAction
          onLayout={(event) => {
            setBottomActionHeight(event?.nativeEvent?.layout?.height ?? 0);
          }}>
          <VStack space={'md'} mx={'$screenMargin'} py={'$2'}>
            <HStack justifyContent={'space-between'} alignItems={'center'} space={'md'}>
              <VStack alignItems={'flex-start'} w={'100%'}>
                <VStack space={'md'} w={'100%'}>
                  <HStack space={'md'} justifyContent={'space-between'}>
                    <CarDateRangeSelector
                      dateRange={dateRange}
                      setDateRange={setNewDateRange}
                      bookedDays={car.bookedDays}
                      priceData={car?.priceData}
                    />
                    {dateRange?.from && dateRange?.to ? (
                      <PriceDisplayTotal
                        priceData={car?.priceData}
                        carCurrency={car?.currency}
                        currency={currency}
                        dateRange={dateRange}
                        selectedExtraOption={selectedExtraOption}
                        location={location}
                      />
                    ) : (
                      <PriceDisplay priceData={car?.priceData} carCurrency={car?.currency} currency={currency} dateRange={dateRange} />
                    )}
                  </HStack>
                </VStack>
              </VStack>
            </HStack>
            <VStack space={'sm'}>
              <Button
                testID="button.book"
                isDisabled={!dateRange.from || !dateRange.to || !deliveryOk}
                px={Metrics.baseMargin}
                onPress={() =>
                  navigation.navigate('ConfirmAndPay', {
                    carId: car.uid,
                    from: dateRange.from,
                    to: dateRange.to,
                    checkInHour: dateRange.checkInHour,
                    checkInMinute: dateRange.checkInMinute,
                    checkOutHour: dateRange.checkOutHour,
                    checkOutMinute: dateRange.checkOutMinute,
                    extraOptions: selectedExtraOption,
                    pickUpPlaceId: location?.pickUp?.placeId,
                    returnPlaceId: location?.return?.placeId ?? location?.pickUp?.placeId,
                  })
                }>
                <ButtonText>{t('global.book')}</ButtonText>
              </Button>
              <Text variant={'grey'} size={'secondary'}>
                {t('reservation.confirmUntilOwnerConfirmInfo')}
              </Text>
            </VStack>
          </VStack>
        </BottomAction>
      )}
    </Layout>
  );
}

export default CarDetailScreen;
