import React, { useEffect, useState } from 'react';

import { Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ProfileCard from '../../../shared/components/entities/user/ProfileCard';
import { errorHandler } from '../../../shared/components/errorHandler/errorHander';
import Skeleton from '../../../shared/components/skeleton/skeleton';
import { firebaseApp } from '../../../shared/services/firebase/firebaseFactory';
import { cleanFirestoreResult } from '../../../shared/util/firestore-utils';

function ConfirmUser() {
  const [driver, setDriver] = useState({});
  const [driverLoading, setDriverLoading] = useState(false);
  const { t } = useTranslation();
  const quote = useSelector((state) => state.quote.quote);

  useEffect(() => {
    if (quote?.driverId) {
      setDriverLoading(true);
      firebaseApp
        .firestore()
        .collection('profiles')
        .doc(quote?.driverId)
        .get()
        .then((p) => {
          setDriver(cleanFirestoreResult(p));
          setDriverLoading(false);
        })
        .catch((e) => {
          errorHandler(e, true);
          setDriverLoading(false);
        });
    }
  }, [quote?.driverId]);

  return (
    <VStack space={'lg'}>
      {driverLoading ? (
        <Skeleton.Text w={200} />
      ) : (
        <Text variant={'grey'}>{t('quote.sendQuoteToUser', { user: driver?.name ?? `${driver?.firstName} ${driver?.lastName}` })}</Text>
      )}

      <ProfileCard loading={driverLoading} profile={driver} isDisable={true} />
    </VStack>
  );
}

export default ConfirmUser;
