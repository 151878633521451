import React from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Heading, HStack, Icon, Text, VStack } from '@gluestack-ui/themed';

import Skeleton from '../skeleton/skeleton';

export default function PressableRowCard({ title, info, bottomElement, loading, onPress }) {
  function onPressRow() {
    if (onPress) {
      onPress();
    }
  }

  if (loading) {
    return (
      <VStack space={'sm'}>
        <HStack justifyContent={'space-between'} w={'$full'}>
          <VStack space={'md'} w={'80%'}>
            <Skeleton.Text lines={1} w={'50%'} />
            <Skeleton.Text lines={2} h={'$2'} />
          </VStack>
          <Skeleton alignSelf={'center'} h={'$4'} w={'$4'} rounded={'$full'} />
        </HStack>
      </VStack>
    );
  }

  return (
    <TouchableOpacity onPress={onPressRow}>
      <HStack justifyContent={'space-between'} w={'$full'} my={'$4'}>
        <VStack space={'xs'} w={'80%'}>
          <Heading size={'h4'}>{title}</Heading>
          <Text variant={'grey'} size={'secondary'}>
            {info}
          </Text>
          {bottomElement ? bottomElement : null}
        </VStack>
        <Icon alignSelf={'center'} as={Ionicons} name={'chevron-forward-outline'} />
      </HStack>
    </TouchableOpacity>
  );
}
