import React from 'react';

import { ScrollView } from '@gluestack-ui/themed';

import BottomSheet from '../bottomSheet/bottomSheet';

export default function ActionSheet({ isOpen, setOpen, headerText, children, renderFooter }) {
  return (
    <BottomSheet isOpen={isOpen} setOpen={setOpen} renderFooter={renderFooter} headerText={headerText}>
      <ScrollView>{children}</ScrollView>
    </BottomSheet>
  );
}
