import { Ionicons } from '@expo/vector-icons';
import { Checkbox, CheckboxIcon, CheckboxIndicator, CheckboxLabel, Heading, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { insuranceTypes } from '../../util/insurance-utils';
import { InternalLinks } from '../../util/link-utils';
import UnderLineButton from '../pressable/underLineButton';

export default function InsuranceProctectionSelector({ selectedInsurance, setSelectedInsurance }) {
  const { t } = useTranslation();
  const navigation = useNavigation();

  return (
    <VStack space={'md'}>
      <Heading>{t('addCar.chooseInsuranceTitle')}</Heading>
      <VStack space={'md'}>
        {insuranceTypes.map((insuranceType) => (
          <Checkbox
            key={insuranceType?.type}
            aria-label={`${insuranceType?.emoji} ${t('insuranceType.' + insuranceType?.type)} (${insuranceType?.type})`}
            size="md"
            justifyContent={'space-between'}
            isChecked={selectedInsurance.some((ins) => ins === insuranceType.type)}
            onChange={() => setSelectedInsurance(insuranceType.type)}>
            <CheckboxLabel w={'80%'}>{`${insuranceType?.emoji} ${t('insuranceType.' + insuranceType?.type)} (${
              insuranceType?.type
            })`}</CheckboxLabel>
            <CheckboxIndicator ml="$2">
              <CheckboxIcon as={Ionicons} name={'checkmark-outline'} />
            </CheckboxIndicator>
          </Checkbox>
        ))}
      </VStack>
      <UnderLineButton
        onPress={() =>
          navigation.navigate('CustomWebView', { url: InternalLinks.understandingCoverage, title: t('car.understandingCoverage') })
        }
        text={t('car.understandingCoverage')}
        hideLeftIcon={true}
      />
    </VStack>
  );
}
