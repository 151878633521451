import React, { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Heading, VStack } from '@gluestack-ui/themed';
import { HStack } from '@gluestack-ui/themed';
import { Text } from '@gluestack-ui/themed';

import { getOneRoom } from '../../../services/firebase/message-firebase';
import imagesUrl from '../../../themes/imagesUrl';
import { resizedImage } from '../../../util/image-utils';
import { errorHandler } from '../../errorHandler/errorHander';
import Image from '../../image/image';
import Skeleton from '../../skeleton/skeleton';

export default function RoomCard({ roomId, userId, onPress, isSelected }) {
  const [room, setRoom] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.info('Focus on room card');
    setLoading(true);
    getOneRoom(roomId)
      .then((res) => {
        setRoom(res);
        setLoading(false);
      })
      .catch((e) => {
        errorHandler(e, true);
        setLoading(false);
      });
  }, [roomId]);

  if (loading) {
    return (
      <HStack justifyContent={'space-between'} alignItems={'center'} w={'100%'}>
        <HStack space={'md'}>
          <Skeleton h={'$12'} w={'$12'} rounded={'$full'} />
          <VStack justifyContent={'center'} space={'sm'}>
            <Skeleton.Text lines={1} w={100} />
            <Skeleton.Text lines={1} w={200} />
          </VStack>
        </HStack>
      </HStack>
    );
  }

  return (
    <TouchableOpacity onPress={onPress}>
      <VStack
        space={'sm'}
        bgColor={isSelected ? '$light200' : '$transparent'}
        rounded={'$md'}
        borderWidth={'$1'}
        borderColor={'$transparent'}
        p={'$1'}>
        <HStack justifyContent={'space-between'} alignItems={'center'}>
          <HStack space={'md'} alignItems={'center'} maxWidth={'90%'}>
            <Image
              source={{ uri: resizedImage(userId === room.ownerId ? room.userAvatar : room.ownerAvatar ?? imagesUrl.unavailable) }}
              size={'sm'}
              rounded={'$full'}
            />
            <VStack justifyContent={'center'} space={'xs'} w={'70%'}>
              <Heading size={'h4'}>{userId === room.ownerId ? room.userName : room.ownerName}</Heading>
              <Text variant={'grey'} size={'secondary'} numberOfLines={1} maxw={'80%'}>
                {userId === room.ownerId ? room.lastMessageForOwner : room.lastMessageForDriver}
              </Text>
            </VStack>
          </HStack>
        </HStack>
      </VStack>
    </TouchableOpacity>
  );
}
