import React from 'react';

import { Ionicons } from '@expo/vector-icons';
import { Heading, HStack, Icon } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import { alertTypes } from '../../util/alert-utils';

export default function Alert({ types, badgeOnly }) {
  const { t } = useTranslation();

  if (types.includes(alertTypes.actionRequired)) {
    return (
      <HStack alignItem={'center'} space="xs">
        <Icon as={Ionicons} name={'alert-circle'} color={'$error400'} size={'sm'} />
        {!badgeOnly && <Heading size="h5">{t('alert.actionRequired')}</Heading>}
      </HStack>
    );
  } else if (types.includes(alertTypes.updateNeeded)) {
    return (
      <HStack alignItem={'center'} space="xs">
        <Icon as={Ionicons} name={'alert-circle'} color={'$warning400'} size={'sm'} />
        {!badgeOnly && <Heading size="h5">{t('alert.actionNeeded')}</Heading>}
      </HStack>
    );
  } else if (types.includes(alertTypes.waitingValidation)) {
    return (
      <HStack alignItem={'center'} space="xs">
        <Icon as={Ionicons} name={'checkmark-circle'} color={'$primary700'} size={'sm'} />
        {!badgeOnly && <Heading size="h5">{t('alert.awaitValidation')}</Heading>}
      </HStack>
    );
  } else if (types.includes(alertTypes.resolved)) {
    return (
      <HStack alignItem={'center'} space="xs">
        <Icon as={Ionicons} name={'checkmark-circle'} color={'$success400'} size={'sm'} />
        {!badgeOnly && <Heading size="h5">{t('alert.done')}</Heading>}
      </HStack>
    );
  }
  return null;
}
