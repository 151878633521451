import React, { useEffect } from 'react';

import { Ionicons } from '@expo/vector-icons';
import { Box, HStack, Icon, Spinner, Text, useToken, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

import { Colors, Metrics } from '../../themes';
import { resizedImage } from '../../util/image-utils';
import Image from '../image/image';

function StepItem({ item, step, isSelected, width, imageSize, orientation }) {
  const translateX = useSharedValue(0);
  const scale = useSharedValue(0.5);
  const { t } = useTranslation();
  const shadow4 = useToken('shadows', '4');

  const animatedStyles = useAnimatedStyle(() => ({
    transform: [
      { translateX: withTiming(-translateX.value - imageSize / 2) },
      { scale: withTiming(scale.value) },
      { translateY: withTiming(-imageSize + imageSize * scale.value) },
      { rotateZ: `${orientation?.value}deg` },
    ],
  }));

  useEffect(() => {
    translateX.value = step * imageSize - width / 2;
    if (isSelected) {
      scale.value = 1;
    } else {
      scale.value = 0.5;
    }
  }, [step, width, imageSize]);

  if (item?.isStep || item?.isUpload) {
    return (
      <Animated.View style={[{ alignItems: 'center', height: imageSize, width: imageSize }, animatedStyles]}>
        <Box p={'$2'} backgroundColor="$blue900" rounded="$lg" justifyContent="center" alignContent="center">
          <Text color="white" alignSelf="center">
            {t('checkCarPart.' + item?.part)}
          </Text>
        </Box>
      </Animated.View>
    );
  }

  return (
    <Animated.View
      style={[shadow4, { alignContent: 'flex-start', justifyContent: 'flex-start', height: imageSize, width: imageSize }, animatedStyles]}>
      <Image
        bgColor={'$light400'}
        rounded="$md"
        key={item?.url ?? item.path ?? item.placeHolder}
        h={imageSize}
        w={imageSize}
        source={{ uri: item?.url ?? item.path ?? resizedImage(item.placeHolder) }}
      />
      {item.path && !item?.url && (
        <Box variant="absoluteMidle" bgColor={'$transparent2'} justifyContent="center" alignItems="center" h="100%" w="100%" rounded="$lg">
          <Spinner color="$light300" size={100} alignSelf="center" justifyContent="center" />
        </Box>
      )}
    </Animated.View>
  );
}

const leftIconSize = 25;
export default function MediaStepRaw({ stepList, step, width, orientation }) {
  return (
    <VStack style={{ width: width }} h={80} alignItems="center" zIndex={100}>
      <Icon as={Ionicons} name="chevron-down-outline" size="lg" color="white" height={leftIconSize} />
      <HStack w={Metrics.fullWidth} height={80}>
        {stepList?.length > 0 &&
          stepList?.map((item, index) => (
            <StepItem
              item={item}
              isSelected={index === step}
              step={step}
              key={item?.part}
              width={width}
              imageSize={80}
              orientation={orientation}
            />
          ))}
      </HStack>
    </VStack>
  );
}
