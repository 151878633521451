import React from 'react';

import { VStack } from '@gluestack-ui/themed';

import CarReviews from '../../shared/components/entities/review/carReviews';
import UserReviews from '../../shared/components/entities/review/userReviews';
import WebScrollView from '../../shared/components/web/webScrollView';

function AllReviews({ route }) {
  const { carId, userId } = route?.params ?? {};

  return (
    <WebScrollView mx={'$screenMargin'}>
      <VStack w={'90%'}>
        {carId ? <CarReviews carId={carId} /> : null}
        {userId ? <UserReviews userId={userId} /> : null}
      </VStack>
    </WebScrollView>
  );
}

export default AllReviews;
