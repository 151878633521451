import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';

import { getScreenRoutes, getTabRoutes } from './screens';
import appConfig from '../config/app-config';
import { supportedLang } from '../shared/util/language-utils';

export const linking = {
  prefixes: [Linking.createURL(''), appConfig.deepLinkUrl],
  config: {
    screens: {
      ...Object.fromEntries(
        supportedLang.map((k) => [
          k,
          {
            path: k,
            initialRouteName: 'Tab',
            screens: {
              ...getScreenRoutes(),
              Tab: {
                path: '',
                initialRouteName: 'Search',
                screens: {
                  ...getTabRoutes(),
                },
              },
              NotFound: '*',
            },
          },
        ]),
      ),
      NotFound: '*',
    },
  },
  async getInitialURL() {
    // First, you may want to do the default deep link handling
    // Check if app was opened from a deep link
    const url = await Linking.getInitialURL();
    console.debug('initial URL: ', url);

    if (url != null) {
      return url;
    }

    // Handle URL from expo push notifications
    const response = await Notifications.getLastNotificationResponseAsync();

    return response?.notification.request.content.data.url;
  },
  subscribe(listener) {
    const onReceiveURL = (d) => {
      if (d && d?.url) {
        listener(d.url);
      }
    };

    // Listen to incoming links from deep linking
    const eventListener = Linking.addEventListener('url', onReceiveURL);

    // Listen to expo push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener((response) => {
      const dataRef = response?.notification.request.content.data;
      if (dataRef && dataRef?.url) {
        const url = dataRef?.url;

        if (url) {
          listener(url);
        }
        // Let React Navigation handle the URL
      }
    });

    return () => {
      // Clean up the event listeners
      eventListener.remove();
      subscription.remove();
    };
  },
};

export const webLinking = {
  ...linking,
  config: {
    screens: {
      ...Object.fromEntries(
        supportedLang.map((k) => [
          k,
          {
            initialRouteName: 'Search',
            path: k,
            screens: {
              ...getScreenRoutes(),
              ...getTabRoutes(),
              NotFound: '*',
            },
          },
        ]),
      ),
      NotFound: '*',
    },
  },
};
