import React, { useEffect, useState } from 'react';

import { Box, Spinner, Text, VStack } from '@gluestack-ui/themed';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AdditionalPaymentElement from './additionalPaymentElement.web';
import appConfig from '../../config/app-config';
import LoginPlaceHolder from '../../shared/components/entities/user/loginPlaceHolder';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Image from '../../shared/components/image/image';
import Layout from '../../shared/components/layout/layout';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { processAdditionalPayment } from '../../shared/services/firebase/function-firebase';
import { Images } from '../../shared/themes';
import imagesUrl from '../../shared/themes/imagesUrl';

const stripePromise = loadStripe(appConfig.stripeKey);

function AdditionalPayment({ route }) {
  const { reservationId, additionalPaymentId } = route?.params ?? {};
  const maxWidth = useMaxWidth();

  const currentUser = useSelector((state) => state.users.currentUser);

  const [paymentInfo, setPaymentInfo] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    processAdditionalPayment(reservationId, additionalPaymentId)
      .then((paymentInfoData) => {
        setPaymentInfo(paymentInfoData);
      })
      .catch((e) => {
        errorHandler(e, true);
      });
  }, [reservationId, additionalPaymentId]);

  if (!currentUser?.uid) {
    return (
      <Layout>
        <Box py={'$4'} px={'$screenMargin'} w={maxWidth} alignSelf="center">
          <LoginPlaceHolder />
        </Box>
      </Layout>
    );
  }

  if (!paymentInfo?.paymentIntent) {
    return (
      <VStack space={'md'} justifyContent={'center'} alignItems={'center'} backgroundColor={'$blue900'} flex={1}>
        <Image source={{ uri: imagesUrl.brandName }} height={100} w={300} alt={'logo'} removeTransition={true} />
        <Text alignSelf={'center'} color={'$white'}>
          {t('payment.paymentPageWaiting')}
        </Text>
        <Spinner color={'$white'} size={100} />
      </VStack>
    );
  }

  const options = {
    clientSecret: paymentInfo?.paymentIntent?.paymentIntentClientSecret,
    appearance: { theme: 'flat', labels: 'floating' },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <AdditionalPaymentElement paymentInfo={paymentInfo} reservationId={reservationId} additionalPaymentId={additionalPaymentId} />
    </Elements>
  );
}

export default AdditionalPayment;
