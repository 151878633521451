import React from 'react';

import { FormControlLabel, FormControlLabelText, Heading, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import appConfig from '../../../config/app-config';
import InfoCard from '../../../shared/components/infoCard/infoCard';
import InputPriceCard from '../../../shared/components/input/inputPriceCard';
import KeyboardAvoiding from '../../../shared/components/keyboardAvoiding/keyboardAvoiding';
import UnderLineButton from '../../../shared/components/pressable/underLineButton';
import UnderstandPrice from '../../../shared/components/static/understandPrice';
import { carAddingSetter } from '../../../shared/reducers/car.reducer';
import { formatCurrency } from '../../../shared/util/currency';
import { InternalLinks } from '../../../shared/util/link-utils';

function SetYourPrice() {
  const car = useSelector((state) => state.cars.carAdding);
  const currency = useSelector((state) => state.users.currency);

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const navigation = useNavigation();

  function getDefaultPrice() {
    const range = [...car?.priceData]?.shift();
    return range?.price;
  }

  function setCar(updatedCar) {
    dispatch(carAddingSetter(updatedCar));
  }

  return (
    <KeyboardAvoiding>
      <VStack space={'md'}>
        <Text mb={'$4'} variant={'grey'} size={'secondary'}>
          {t('addCar.setYourPriceDescription')}
        </Text>
        <VStack space={'xs'}>
          <FormControlLabel>
            <FormControlLabelText>{t('addCar.carPrice')}</FormControlLabelText>
          </FormControlLabel>
          <InputPriceCard
            testID={'input.price'}
            placeHolder={t('addCar.price')}
            currency={currency}
            dolarPrice={getDefaultPrice()}
            onChangePrice={(value) => setCar({ ...car, currency: 'USD', priceData: [{ from: 1, to: 366, price: value }] })}
            label={t('addCar.perDay')}
            info={t('addCar.priceRange', {
              min: formatCurrency(Math.round(50 * currency[currency?.current]), currency.current),
              max: formatCurrency(Math.round(90 * currency[currency?.current]), currency.current),
            })}
          />
          <UnderstandPrice>
            <HStack alignItems={'center'} space={'sm'}>
              <Text w={'50%'} underline>
                {t('addCar.driverWillPay')}
              </Text>
              <Text fontWeight={'$bold'}>
                {formatCurrency(Math.round(getDefaultPrice() * currency[currency?.current] * appConfig.serviceFee), currency.current)}
              </Text>
            </HStack>
          </UnderstandPrice>
          <Text variant={'grey'} size={'secondary'}>
            {t('addCar.actualCurrencyInfo', { currency: currency.current })}
          </Text>
        </VStack>

        <InfoCard
          text={
            <>
              <Text>{t('addCar.securityDepositInfo')}</Text>{' '}
              <UnderLineButton
                text={t('global.seeMore')}
                hideLeftIcon={true}
                onPress={() => navigation.navigate('CustomWebView', { url: InternalLinks.carIssue, title: t('profile.getHelp') })}
              />
            </>
          }
        />
      </VStack>
    </KeyboardAvoiding>
  );
}

export default SetYourPrice;
