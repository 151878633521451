import appConfig from '../../config/app-config';

export function getPlaceDetail(placeId) {
  if (!placeId) {
    console.error('noPlaceID');
  }
  return fetch(
    `https://map.flyandcar.com/place/details/json?place_id=${placeId}&key=${appConfig.googleMapKey}&fields=geometry,name,formatted_address,address_components`,
  )
    .then((response) => response.json())
    .then((p) => {
      const localityComponent = p.result.address_components.find((component) => component.types.includes('locality'));
      return {
        placeId: placeId,
        location: p.result.geometry.location,
        name: p.result.name,
        description: p.result.formatted_address,
        ...(localityComponent?.long_name ? { locality: localityComponent?.long_name } : {}),
      };
    });
}

const earthRadius = 6378137;
const toRadius = (value) => (value * Math.PI) / 180;

export function distanceBetweenCoord(from, to) {
  if (!from || !to) {
    return 0;
  }
  const distance =
    Math.acos(
      Math.sin(toRadius(to.lat)) * Math.sin(toRadius(from.lat)) +
        Math.cos(toRadius(to.lat)) * Math.cos(toRadius(from.lat)) * Math.cos(toRadius(from.lng) - toRadius(to.lng)),
    ) * earthRadius;

  return Math.round(distance);
}

export async function getDeliveryLocation(deliveryLocation, pickUpPlaceId, returnPlaceId) {
  let location = {};

  const deliveryLocs = deliveryLocation ?? [];

  const pickPl = deliveryLocs.filter((d) => d?.placeId === pickUpPlaceId).pop();

  if (pickPl) {
    location.pickUp = pickPl;
  } else {
    location.pickUp = await getPlaceDetail(pickUpPlaceId);
  }

  const returnPl = deliveryLocs.filter((d) => d?.placeId === returnPlaceId).pop();

  if (returnPl) {
    location.return = returnPl;
  } else {
    location.return = await getPlaceDetail(returnPlaceId);
  }

  return location;
}

export function getMapRegion(coordinates, radius) {
  if (!coordinates || coordinates.length === 0) {
    return null;
  }

  let minLat, maxLat, minLng, maxLng;

  // Initialize min and max values
  [minLat, maxLat, minLng, maxLng] = [coordinates[0].lat, coordinates[0].lat, coordinates[0].lng, coordinates[0].lng];

  // Calculate min and max lat, lng
  coordinates.forEach((coord) => {
    minLat = Math.min(minLat, coord.lat);
    maxLat = Math.max(maxLat, coord.lat);
    minLng = Math.min(minLng, coord.lng);
    maxLng = Math.max(maxLng, coord.lng);
  });

  const centerLat = (minLat + maxLat) / 2;
  const centerLng = (minLng + maxLng) / 2;

  const deltaLat = maxLat - minLat;
  const deltaLng = maxLng - minLng;

  return {
    lat: centerLat,
    lng: centerLng,
    deltaLat: Math.max(deltaLat, radius / 50),
    deltaLng: Math.max(deltaLng, radius / 50),
  };
}
