import React, { useCallback, useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Heading, HStack, Icon, Text, Textarea, TextareaInput, useToken, VStack } from '@gluestack-ui/themed';
import { WINDOW_WIDTH } from '@gorhom/bottom-sheet';
import { getLocales } from 'expo-localization';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AeraPicker from '../../../shared/components/entities/aera/aeraPicker';
import RadiusMap from '../../../shared/components/map/radiusMap';
import Slider from '../../../shared/components/slider/slider';
import { useIsDrawer } from '../../../shared/provider/isDrawerProvider';
import { useMaxWidth } from '../../../shared/provider/maxWidthProvider';
import CarActions, { carAddingSetter } from '../../../shared/reducers/car.reducer';
import { getExtraInputProps } from '../../../shared/util/input-utils';

const maxDeliveryKm = 60;
const minDeliveryKm = 3;
const defaultDeliveryKm = 10;

function AeraOfActivity() {
  const car = useSelector((state) => state.cars.carAdding);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const space4 = parseInt(useToken('space', '4'), 10);
  const maxWidth = useMaxWidth();
  const isDrawer = useIsDrawer();

  // Keeping the state for location consolidated to manage updates more cleanly
  const [location, setLocation] = useState({
    name: car.placeName,
    placeId: car.placeId,
    location: car?._geoloc,
  });

  const [isAeraPickerOpen, setAeraPickerOpen] = useState(false);
  const [text, setText] = useState(car?.carLocationInfo);

  function changeText(textChange) {
    setText(textChange);
  }

  const setCar = useCallback(
    (updates) => {
      dispatch(carAddingSetter({ ...car, ...updates }));
    },
    [dispatch, car],
  );

  // Text effect
  useEffect(() => {
    if (text !== car?.carLocationInfo) {
      setCar({ carLocationInfo: text });
    }
  }, [text, setCar, car?.carLocationInfo]);

  // Location effect
  useEffect(() => {
    if (location.placeId !== car.placeId || JSON.stringify(location.location) !== JSON.stringify(car._geoloc)) {
      setCar({
        placeId: location?.placeId,
        _geoloc: location?.location,
        placeName: location?.name,
        placeDescription: location?.description,
      });
    }
  }, [location, setCar, car.placeId, car._geoloc]);

  const getMeasureUnit = useCallback(() => {
    return getLocales()[0].measurementSystem === 'us' ? 'mi' : 'km';
  }, []);

  return (
    <VStack space={'lg'}>
      <Text variant={'grey'} size={'secondary'}>
        {t('addCar.aeraDescription')}
      </Text>
      <VStack space={'sm'}>
        <Box variant={'cardContainer'} rounded={'$full'} px={'$4'} py={'$4'} mt={'$2'} mx={'$4'}>
          <TouchableOpacity testID="button.selectAera" onPress={() => setAeraPickerOpen(true)}>
            <HStack space={'sm'}>
              <HStack space={'sm'} justifyContent={'center'} alignItems={'center'}>
                <Icon as={Ionicons} size={'md'} name="search" color={'$black'} />
                <Text w={'90%'} numberOfLines={1}>
                  {location?.name ? location?.name : t('addCar.whereYourCarLocated')}
                </Text>
              </HStack>
            </HStack>
          </TouchableOpacity>
        </Box>
        <AeraPicker
          isOpen={isAeraPickerOpen}
          setOpen={setAeraPickerOpen}
          location={location}
          setLocation={setLocation}
          forOwner={true}
          onlyOneLocation={true}
        />
        {location?.location && <RadiusMap coord={location?.location} radius={car?.deliveryRadius ?? defaultDeliveryKm} />}
      </VStack>
      <VStack space={'md'}>
        <Heading mt={'$2'}>{t('addCar.chooseRadiusTitle')}</Heading>
        <Text mb={'$4'}>{t('addCar.chooseRadiusInfo')}</Text>
        <Slider
          value={
            getMeasureUnit() === 'km'
              ? car?.deliveryRadius ?? defaultDeliveryKm
              : car?.deliveryRadius
                ? car?.deliveryRadius / 1.609
                : defaultDeliveryKm / 1.609
          }
          sliderWidth={isDrawer ? maxWidth - 300 - space4 * 2 - 20 : maxWidth - space4 * 2 - 20}
          max={getMeasureUnit() === 'km' ? maxDeliveryKm : Math.round(maxDeliveryKm / 1.609)}
          min={getMeasureUnit() === 'km' ? minDeliveryKm : Math.round(minDeliveryKm / 1.609)}
          textPrefix={''}
          textSufix={getMeasureUnit()}
          step={1}
          onValueChange={(value) => setCar({ deliveryRadius: getMeasureUnit() === 'km' ? value : value * 1.609 })}
        />
      </VStack>
      <VStack space={'md'}>
        <Heading mt={'$2'}>{t('addCar.locationDetailTitle')}</Heading>
        <Text>{t('addCar.locationDetailInfo')}</Text>
        <Textarea {...getExtraInputProps()}>
          <TextareaInput placeholder={t('addCar.carLocationDetailExample')} value={text} onChangeText={changeText} />
        </Textarea>
      </VStack>
    </VStack>
  );
}

export default AeraOfActivity;
