import React from 'react';

import { Avatar, HStack } from '@gluestack-ui/themed';

import Skeleton from './skeleton';

export default function ReviewSkeleton() {
  return (
    <HStack space={'md'}>
      <Avatar size={'lg'} bg={'$light500'} />
      <Skeleton.Text lines={3} />
    </HStack>
  );
}
