import appConfig from '../../config/app-config';

export function resizedImage(url) {
  try {
    // Parse the URL
    const parsedUrl = new URL(url);
    // Check if the URL starts with any of the authorized domains
    if (!url.startsWith(`https://${appConfig.storageBucketDomain}`)) {
      console.debug('not authorized domain', url);
      return url;
    }

    // Check for query parameters to prevent injection
    if (parsedUrl?.search) {
      console.error('url have param', url);
      return url;
    }

    // Modify the filename
    const pathSegments = parsedUrl.pathname.split('.');
    const extension = pathSegments.pop();
    if (['jpg', 'jpeg', 'webp', 'png', 'tiff', 'webp', 'gif'].includes(extension.toLowerCase())) {
      parsedUrl.pathname = `${pathSegments.join('.')}_720x720.${extension}`;
    }

    return parsedUrl.toString();
  } catch (error) {
    console.debug('error to resize url image:', url, error);
    return url;
  }
}
