import React, { useCallback, useState } from 'react';
import { Linking, TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Button, ButtonText, HStack, Icon, Text } from '@gluestack-ui/themed';
import { useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { userLastNotificationClose, userUpdateRequest } from '../../reducers/user.reducer';
import { IS_WEB } from '../../util/device-utils';
import { registerForPushNotificationsAsync } from '../../util/notification-utils';

const interval = 30 * 60 * 60 * 1000; // 30 hours

function NotificationManager() {
  const lastNotificationClose = useSelector((state) => state.users.lastNotificationClose);
  const currentUser = useSelector((state) => state.users.currentUser);
  const dispatch = useDispatch();

  const [toDisplay, setDisplay] = useState(false);

  const { t } = useTranslation();

  useFocusEffect(
    useCallback(() => {
      registerForPushNotificationsAsync(false)
        .catch((e) => {
          setDisplay(true);
        })
        .then((token) => {
          if (token) {
            setDisplay(false);
            const settedToken = currentUser?.tokens ?? [];
            if (!settedToken.includes(token)) {
              dispatch(userUpdateRequest({ uid: currentUser?.uid, tokens: [...settedToken, token] }));
            }
          }
        });
    }, [currentUser?.tokens, currentUser?.uid, dispatch]),
  );

  function onPress() {
    registerForPushNotificationsAsync(true)
      .catch((e) => Linking.openSettings())
      .then((token) => {
        setDisplay(false);
        const settedToken = currentUser?.tokens ? currentUser.tokens : [];
        if (!settedToken.includes(token)) {
          dispatch(userUpdateRequest({ uid: currentUser?.uid, tokens: [...settedToken, token] }));
        }
      });
  }

  if (IS_WEB) {
    return null;
  }

  if (!toDisplay) {
    return null;
  }

  if (lastNotificationClose + interval > new Date().getTime()) {
    return null;
  }

  return (
    <Box mr={'$2'} my={'$2'}>
      <HStack
        mx={'$screenMargin'}
        alignItems={'center'}
        backgroundColor={'$light100'}
        rounded={'$lg'}
        px={'$4'}
        py={'$2'}
        justifyContent={'space-between'}>
        <Text size={'secondary'} maxWidth={'70%'}>
          {t('notification.notificationNotAllowed')}
        </Text>
        <Button minWidth={'$12'} size={'xs'} onPress={onPress}>
          <ButtonText size={'secondary'}>{t('notification.enable')}</ButtonText>
        </Button>
      </HStack>
      <Box variant={'absoluteTopRight'} mr={'$4'} rounded={'$full'} backgroundColor={'grey'}>
        <TouchableOpacity onPress={() => dispatch(userLastNotificationClose(new Date().getTime()))}>
          <Icon as={Ionicons} name={'close'} size={'md'} color={'$white'} />
        </TouchableOpacity>
      </Box>
    </Box>
  );
}

export default NotificationManager;
