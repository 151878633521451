import React from 'react';

import { Text } from '@gluestack-ui/themed';
import { Heading, HStack, VStack } from '@gluestack-ui/themed';
import { Box } from '@gluestack-ui/themed';

import ContactForm from './contactForm';

export default function SectionContact({ title, description }) {
  return (
    <HStack
      alignItems="center"
      w={'$full'}
      $base-justifyContent="space-around"
      $md-justifyContent="space-between"
      flexWrap="wrap"
      space="4xl">
      <VStack $base-w={'$full'} $md-w={'50%'} space="2xl" $md-minWidth={300}>
        <Heading size="h1">{title}</Heading>
        <Text>{description}</Text>
      </VStack>
      <Box $base-w={'$full'} $md-w={'40%'} $md-minWidth={300}>
        <ContactForm />
      </Box>
    </HStack>
  );
}
