import React, { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Heading, HStack, Icon, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import QuoteCard from './quoteCard';
import { firebaseApp } from '../../services/firebase/firebaseFactory';
import { quoteToReservation } from '../../util/booking-utils';
import { getThumbnailUrl } from '../../util/car-utils';
import { formatCurrency } from '../../util/currency';
import { displayRangeDate } from '../../util/date-transforms';
import { cleanFirestoreResult } from '../../util/firestore-utils';
import { resizedImage } from '../../util/image-utils';
import Image from '../image/image';
import Skeleton from '../skeleton/skeleton';

const QuoteCardById = ({ quoteId, onPress }) => {
  const [quote, setQuote] = useState(null);

  useEffect(() => {
    console.debug('UseEffect get car and user');
    if (quoteId) {
      firebaseApp
        .firestore()
        .collection('quotes')
        .doc(quoteId)
        .get()
        .then((res) => setQuote(cleanFirestoreResult(res)));
    }
  }, [quoteId]);

  return <QuoteCard onPress={onPress} quote={quote} />;
};

export default QuoteCardById;
