import React, { useCallback, useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Box, Button, ButtonText, HStack, Icon, useToken, View, VStack } from '@gluestack-ui/themed';
import { Bubble, GiftedChat } from 'react-native-gifted-chat';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import 'react-native-get-random-values';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import ChatCTA from './components/chatCTA';
import appConfig from '../../config/app-config';
import ReservationCardById from '../../shared/components/entities/reservation/reservationCardById';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Image from '../../shared/components/image/image';
import { Media } from '../../shared/components/media/media';
import QuoteCardById from '../../shared/components/quote/quoteCardById';
import errorToast from '../../shared/components/toast/errorToast';
import ContentTranslation from '../../shared/components/translation/contentTanslation';
import { userUpdateRequest } from '../../shared/reducers/user.reducer';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import imagesUrl from '../../shared/themes/imagesUrl';
import { IS_WEB } from '../../shared/util/device-utils';
import { registerForPushNotificationsAsync } from '../../shared/util/notification-utils';

function ChatScreen({ route, navigation, roomUID }) {
  const currentUser = useSelector((state) => state.users.currentUser);
  const dispatch = useDispatch();

  const { roomId } = roomUID ? { roomId: roomUID } : route?.params ?? {};

  const [messages, setMessages] = useState([]);
  const [room, setRoom] = useState({});
  const [isMediaOpen, setMediaOpen] = useState(false);

  const light100 = useToken('colors', 'light100');
  const primary400 = useToken('colors', 'primary400');

  const { bottom } = useSafeAreaInsets();

  useEffect(() => {
    console.debug('Effect: refresh currentUser, room');
    if (room?.ownerName && room?.userName) {
      navigation.setOptions({ title: currentUser?.uid === room.userId ? room.ownerName : room.userName });
    }
  }, [room, currentUser, navigation]);

  useEffect(() => {
    console.debug('Effect: refresh currentUser updateUser');
    if (currentUser?.uid && !IS_WEB) {
      registerForPushNotificationsAsync(true)
        .then((token) => {
          if (currentUser?.tokens && currentUser?.tokens.length > 0 && currentUser?.tokens.filter((t) => t === token).length > 0) {
            return;
          } else {
            const toks = currentUser?.tokens ? [...currentUser.tokens, token] : [token];
            dispatch(userUpdateRequest({ uid: currentUser?.uid, tokens: toks.filter((item, index) => toks.indexOf(item) === index) }));
            console.info('New token added');
          }
        })
        .catch((e) => errorToast(e.message));
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    console.debug('Effect: refresh navigation');
    navigation.getParent()?.setOptions({
      tabBarStyle: {
        display: 'none',
      },
    });
    return () =>
      navigation.getParent()?.setOptions({
        tabBarStyle: undefined,
      });
  }, [navigation]);

  useEffect(() => {
    console.debug('Effect: listen room update', roomId);
    if (currentUser?.uid && roomId) {
      const refPath = `/rooms/${roomId}`;
      const onValueChange = firebaseApp
        .database()
        .ref(refPath)
        .on(
          'value',
          (snapshot) => {
            setRoom({ ...snapshot.val(), uid: roomId });
          },
          (e) => errorHandler(e, false),
        );
      // Stop listening for updates when no longer required
      return () => firebaseApp.database().ref(refPath).off('value', onValueChange);
    }
  }, [currentUser?.uid, roomId]);

  useEffect(() => {
    console.debug('Effect: refresh roomId');
    if (currentUser?.uid && roomId) {
      firebaseApp
        .database()
        .ref(`/messages/${roomId}`)
        .once(
          'value',
          (snapshot) => {
            const tmpArr = [];
            for (var k in snapshot.val()) {
              if (snapshot.val().hasOwnProperty(k)) {
                tmpArr.push(snapshot.val()[k]);
              }
            }
            if (tmpArr && tmpArr.length > 0) {
              setMessages(tmpArr.sort((a, b) => b.createdAt - a.createdAt));
            }
          },
          (e) => errorHandler(e, false),
        );
    }
  }, [roomId, currentUser]);

  useEffect(() => {
    console.debug('Effect: listen new roomId', roomId);
    if (currentUser?.uid && roomId) {
      const from = new Date().getTime();
      const refPath = `/messages/${roomId}`;
      const onValueChange = firebaseApp
        .database()
        .ref(refPath)
        .orderByChild('createdAt')
        .startAt(from, 'createdAt')
        .on(
          'child_changed',
          (snapshot) => {
            if (snapshot.val()?._id) {
              setMessages((previousMessages) =>
                GiftedChat.append(
                  previousMessages.filter((mes) => mes?._id !== snapshot.val()._id),
                  snapshot.val(),
                ),
              );
            }
          },
          (e) => errorHandler(e, false),
        );
      // Stop listening for updates when no longer required
      return () => firebaseApp.database().ref(refPath).off('child_added', onValueChange);
    }
  }, [currentUser?.uid, roomId]);

  function onSend(mes) {
    if (mes.length > 0) {
      const messagetoSend = { ...mes[0] };
      const messageText = messagetoSend?.text;
      const messageToSend = {
        ...messagetoSend,
        originalContent: { text: messageText },
        language: currentUser?.language,
        createdAt: messagetoSend?.createdAt?.getTime(),
        pending: false,
        sent: true,
      };
      const rr = firebaseApp.database().ref(`/messages/${roomId}`).push();
      rr.set(messageToSend).catch((e) => errorHandler(e, true));
      setMessages((previousMessages) =>
        GiftedChat.append(
          previousMessages.filter((mess) => mess?._id !== rr.key),
          { ...messageToSend, pending: true, sent: false },
        ),
      );
    }
  }

  const renderMessageText = (pro) => (
    <Box mx={'$4'} my={'$1'}>
      <ContentTranslation
        switchTextColor={pro?.position === 'left' ? 'grey' : '$white'}
        textColor={pro?.position === 'left' ? '$black' : '$white'}
        object={pro?.currentMessage ? pro?.currentMessage : {}}
        field={'text'}
        lang={currentUser?.language}
        limit={100000}
      />
    </Box>
  );

  const renderBubble = (prop) => (
    <Bubble
      key={prop?.currentMessage?._id}
      {...prop}
      renderMessageText={renderMessageText}
      wrapperStyle={{
        right: {
          backgroundColor: primary400,
        },
        left: {
          backgroundColor: light100,
        },
      }}
    />
  );

  const renderSystemMessage = useCallback(
    (pro) => {
      return (
        <VStack m={'$4'} space="md">
          <VStack backgroundColor={'$light300'} py={'$2'} px={'$4'} rounded={'$2xl'} space={'sm'}>
            <HStack space={'sm'}>
              <Image source={{ uri: imagesUrl.systemMessageIcon }} rounded={'$md'} h={'$8'} w={'$8'} />
              <Box w={'90%'}>
                <ContentTranslation
                  switchTextColor={'$black'}
                  textColor={'$black'}
                  object={pro?.currentMessage ? pro?.currentMessage : {}}
                  field={'text'}
                  lang={currentUser?.language}
                  limit={100000}
                />
              </Box>
            </HStack>
          </VStack>
          {pro?.currentMessage?.quoteId && (
            <VStack py={'$1'} px={'$4'} rounded={'$2xl'} space={'sm'} hardShadow="3" bgColor="$white">
              <QuoteCardById
                quoteId={pro?.currentMessage?.quoteId}
                onPress={() => navigation.navigate('QuoteDetail', { quoteId: pro?.currentMessage?.quoteId })}
              />
            </VStack>
          )}
          {pro?.currentMessage?.reservationId && (
            <VStack py={'$1'} px={'$4'} rounded={'$2xl'} space={'sm'} hardShadow="3" bgColor="$white">
              <ReservationCardById
                reservationId={pro?.currentMessage?.reservationId}
                onPress={() =>
                  navigation.navigate(room?.ownerId === currentUser?.uid ? 'RequestDetailScreen' : 'ReservationDetail', {
                    reservationId: pro?.currentMessage?.reservationId,
                  })
                }
              />
            </VStack>
          )}
        </VStack>
      );
    },
    [currentUser?.language, currentUser?.uid, navigation, room?.ownerId],
  );

  function stopAssistant() {
    firebaseApp.database().ref(`/rooms/${roomId}`).update({ isSupport: false });
  }

  return (
    <View flex={1} mb={bottom}>
      <ChatCTA room={room} />
      {room?.isSupport && currentUser?.uid === appConfig.supportId && (
        <Box m="$4">
          <Button onPress={stopAssistant}>
            <ButtonText>Stop Assistant</ButtonText>
          </Button>
        </Box>
      )}
      <Media
        selectorOpen={isMediaOpen}
        setSelector={setMediaOpen}
        onUpload={(url) =>
          onSend([
            {
              _id: uuidv4(),
              createdAt: new Date(),
              image: url,
              user: {
                _id: currentUser?.uid,
                name: currentUser?.firstName + ' ' + currentUser?.lastName,
                avatar: currentUser?.photoURL,
              },
            },
          ])
        }
        storagePath={'message'}
      />
      <GiftedChat
        key={room?.uid + messages?.length > 0}
        messages={messages}
        onSend={(mes) => onSend(mes)}
        renderBubble={renderBubble}
        renderSystemMessage={renderSystemMessage}
        renderActions={() => (
          <Box alignSelf="center" mx={'$2'}>
            <TouchableOpacity onPress={() => setMediaOpen(true)}>
              <Icon as={Ionicons} name={'camera-outline'} size={'md'} />
            </TouchableOpacity>
          </Box>
        )}
        user={{
          _id: currentUser?.uid,
          name: currentUser?.firstName + ' ' + currentUser?.lastName,
          avatar: currentUser?.photoURL,
        }}
      />
    </View>
  );
}

export default ChatScreen;
