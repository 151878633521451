import React, { useEffect, useState } from 'react';

import { Box, Button, ButtonSpinner, ButtonText, Heading, HStack, Text, VStack } from '@gluestack-ui/themed';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import BottomAction from '../../../../shared/components/bottomAction/bottomAction';
import { errorHandler } from '../../../../shared/components/errorHandler/errorHander';
import MediaUploader from '../../../../shared/components/media/mediaUploader';
import errorToast from '../../../../shared/components/toast/errorToast';
import successToast from '../../../../shared/components/toast/successToast';
import Toggle from '../../../../shared/components/toggle/toggle';
import WebScrollView from '../../../../shared/components/web/webScrollView';
import { useMaxWidth } from '../../../../shared/provider/maxWidthProvider';
import { firebaseApp } from '../../../../shared/services/firebase/firebaseFactory';
import { carDocumentTypes } from '../../../../shared/util/car-utils';
import { cleanFirestoreListResult } from '../../../../shared/util/firestore-utils';
import { userDocumentStatus } from '../../../../shared/util/userDocument-utils';

const emptyDocument = {
  status: userDocumentStatus.waintingApproval,
  type: carDocumentTypes.registrationCard,
};

function RegistrationCard({ route }) {
  const { carId } = route?.params ?? {};

  const currentUser = useSelector((state) => state.users.currentUser);

  const [document, setDocument] = useState(emptyDocument);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  const [bottomActionHeight, setBottomActionHeight] = useState(0);

  const { t } = useTranslation();
  const navigation = useNavigation();
  const maxWidth = useMaxWidth();

  const valid = () => document?.images?.length > 0 && document?.allowRenting;

  const SaveDocuments = () => {
    if (valid()) {
      setUpdating(true);
      if (!document?.uid) {
        firebaseApp
          .firestore()
          .collection('cars')
          .doc(carId)
          .collection('documents')
          .add(document)
          .then(() => {
            setUpdating(false);
            successToast(t('carDocuments.successfullyAdd'));
            navigation.canGoBack() && navigation.goBack();
          })
          .catch((e) => {
            errorHandler(e, true);
            setUpdating(false);
          });
      } else {
        firebaseApp
          .firestore()
          .collection('cars')
          .doc(carId)
          .collection('documents')
          .doc(document?.uid)
          .update(document)
          .then(() => {
            setUpdating(false);
            successToast(t('carDocuments.successfullyAdd'));
            navigation.canGoBack() && navigation.goBack();
          })
          .catch((e) => {
            errorHandler(e, true);
            setUpdating(false);
          });
      }
    } else {
      errorToast(t('error.completeForm'));
    }
  };

  const updateImages = (images) => {
    const cleanImages = images.filter((image) => image?.url !== undefined);
    if (cleanImages && cleanImages.length > 0) {
      setDocument((prevDoc) => {
        return { ...prevDoc, images: cleanImages };
      });
    }
  };

  useEffect(() => {
    if (currentUser?.uid && carId) {
      setLoading(true);
      firebaseApp
        .firestore()
        .collection('cars')
        .doc(carId)
        .collection('documents')
        .where('type', '==', carDocumentTypes.registrationCard)
        .get()
        .then(cleanFirestoreListResult)
        .then((res) => {
          if (res.length > 0) {
            setDocument(res[0]);
          } else {
            setDocument(emptyDocument);
          }
          setLoading(false);
        })
        .catch((e) => {
          errorHandler(e, true);
          setLoading(false);
        });
    }
  }, [currentUser, carId]);

  function onTogglePress(isAllow) {
    setDocument((prev) => ({ ...prev, allowRenting: isAllow }));
  }

  return (
    <>
      <WebScrollView testID={'scrollView'} hideFooter={true} maxWidth={maxWidth > 700 ? 700 : maxWidth}>
        <VStack my={'$4'} mx={'$screenMargin'} space={'md'} mb={bottomActionHeight}>
          <Text variant={'grey'}>{t('carDocuments.ownership.info')}</Text>
          <VStack space={'sm'}>
            <Heading>{t('carDocuments.ownership.addRegistrationCard')}</Heading>
            <Text variant={'grey'} size={'secondary'}>
              {t('carDocuments.ownership.uploadFrontAndBack')}
            </Text>
            <MediaUploader
              key={document?.uid}
              testID={'idDocument'}
              initialList={document?.images ?? []}
              onUpdate={(images) => updateImages(images)}
              storagePath={'user-documents'}
              multipleSelectionAllow={true}
              document={true}
            />
          </VStack>
          <VStack space="sm" mt={'$2'}>
            <Heading>{t('carDocuments.ownership.allowedToRentTitle')}</Heading>
            <HStack justifyContent="space-between" alignItems="center">
              <Text flex={1} mr={'$2'}>
                {t('carDocuments.ownership.allowedToRent')}
              </Text>
              <Toggle isChecked={document?.allowRenting} onPress={onTogglePress} />
            </HStack>
          </VStack>
        </VStack>
      </WebScrollView>
      <BottomAction
        onLayout={(event) => {
          setBottomActionHeight(event?.nativeEvent?.layout?.height ?? 0);
        }}>
        <Box px={'$screenMargin'} my={'$4'} w={maxWidth > 700 ? 700 : maxWidth} alignSelf={'center'}>
          <Button testID="button.addDocument" isDisabled={updating || !valid()} onPress={SaveDocuments}>
            {updating && <ButtonSpinner mr="$1" />}
            <ButtonText>{t('global.validate')}</ButtonText>
          </Button>
        </Box>
      </BottomAction>
    </>
  );
}

export default RegistrationCard;
