import React, { useCallback, useEffect, useState } from 'react';
import { Share } from 'react-native';

import { Feather, Ionicons } from '@expo/vector-icons';
import { Box, Button, ButtonIcon, ButtonText, Divider, Heading, HStack, Icon, ScrollView, useToken, VStack } from '@gluestack-ui/themed';
import { Pressable } from '@gluestack-ui/themed';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';

import appConfig from '../../config/app-config';
import UserReviews from '../../shared/components/entities/review/userReviews';
import { errorHandler } from '../../shared/components/errorHandler/errorHander';
import Image from '../../shared/components/image/image';
import InfoExtender from '../../shared/components/info/infoExtender';
import Layout from '../../shared/components/layout/layout';
import ProfileBigCard from '../../shared/components/ProfileBigCard/ProfileBigCard';
import successToast from '../../shared/components/toast/successToast';
import UserCars from '../../shared/components/userCarsCarousel/userCars';
import UserCarsCarousel from '../../shared/components/userCarsCarousel/userCarsCarousel';
import { useIsDrawer } from '../../shared/provider/isDrawerProvider';
import { useMaxWidth } from '../../shared/provider/maxWidthProvider';
import { firebaseApp } from '../../shared/services/firebase/firebaseFactory';
import imagesUrl from '../../shared/themes/imagesUrl';
import { IS_WEB } from '../../shared/util/device-utils';
import { cleanFirestoreResult } from '../../shared/util/firestore-utils';
import { tabScreenOption } from '../../shared/util/navigation-utils';

const Tab = createMaterialTopTabNavigator();

function PublicProfileScreen({ route }) {
  const { userId } = route?.params ?? {};

  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const maxWidth = useMaxWidth();
  const isDrawer = useIsDrawer();
  const { t, i18n } = useTranslation();
  const { top } = useSafeAreaInsets();
  const coverHeight = parseInt(useToken('space', '48'), 10);

  const currentUser = useSelector((state) => state.users.currentUser);

  const navigation = useNavigation();

  useFocusEffect(
    useCallback(() => {
      console.debug('Effect: userId');
      setLoading(true);
      firebaseApp
        .firestore()
        .collection('profiles')
        .doc(userId)
        .get()
        .then(cleanFirestoreResult)
        .catch((e) => {
          errorHandler(e, true);
          setLoading(false);
        })
        .then((u) => {
          setProfile(u);
          setLoading(false);
        });
    }, [userId]),
  );

  useEffect(() => {
    console.debug('Effect: refresh navigation');
    navigation.getParent()?.setOptions({
      tabBarStyle: {
        display: 'none',
      },
    });
    return () =>
      navigation.getParent()?.setOptions({
        tabBarStyle: undefined,
      });
  }, [navigation]);

  const onShare = async () => {
    try {
      const message = IS_WEB ? window.location.href : `${appConfig.deepLinkUrl}/${i18n.language}/profile/${userId}`;
      if (IS_WEB) {
        await navigator.clipboard.writeText(message);
        successToast(t('global.linkCopied'));
      } else {
        await Share.share({ message });
      }
    } catch (error) {
      errorHandler(error, true);
    }
  };

  const title = (profile?.name ?? `${profile?.firstName ?? ''} ${profile?.lastName ?? ''}`) + ' - FlynCar';
  const description =
    profile?.translatedContent?.about && i18n.language in (profile?.translatedContent?.about || [])
      ? profile?.translatedContent?.about[i18n.language]
      : profile?.originalContent?.about;
  const image = profile?.bannerURL ?? profile?.photoURL;

  if (!isDrawer) {
    return (
      <Layout title={title} description={description} image={image}>
        <ScrollView w={'$full'}>
          <Box w={'$full'} h={coverHeight + top}>
            {loading ? (
              <Box w={'$full'} h={coverHeight + top} bgColor="$light300" />
            ) : (
              <Image
                source={{
                  uri: profile?.bannerURL ?? imagesUrl.defaultCover,
                }}
                w={'$full'}
                h={coverHeight + top}
              />
            )}

            <Box position="absolute" alignSelf="center" top={top} w={maxWidth} h={coverHeight + top}>
              <Pressable
                bgColor="$white"
                h={'$8'}
                w={'$8'}
                rounded={'$full'}
                alignContent="center"
                justifyContent="center"
                onPress={() => (navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Profile'))}
                position="absolute"
                left={'$4'}
                top={'$4'}
                hardShadow="1">
                <Icon as={Ionicons} name={'arrow-back-outline'} color="$black" />
              </Pressable>
              <Pressable
                bgColor="$white"
                h={'$8'}
                w={'$8'}
                rounded={'$full'}
                alignContent="center"
                justifyContent="center"
                onPress={onShare}
                position="absolute"
                right={'$4'}
                top={'$4'}
                hardShadow="1">
                <Icon as={Feather} name={'share'} color="$black" />
              </Pressable>
            </Box>
          </Box>
          <VStack w={'$full'}>
            <Box>
              <Box alignSelf="center" w={'$full'} px={'$screenMargin'} mt={-64}>
                <ProfileBigCard
                  avgRating={profile?.avgRating}
                  loading={loading}
                  countryCode={profile?.countryCode}
                  createAt={profile?.createAt?.seconds}
                  imageUrl={profile?.photoURL}
                  name={profile?.name ?? `${profile?.firstName} ${profile?.lastName}`}
                  numRatings={profile?.numRatings}
                  numRentals={profile?.numRentals}
                  numTrips={profile?.numTrips}
                  isVerified={profile?.isVerified}
                  isOwner={profile?.isOwner}
                  canEdit={currentUser?.uid === profile?.uid}
                  isMobile={true}
                />
              </Box>
            </Box>
            <Box mx={'$screenMargin'}>
              <Divider my={'$4'} />
            </Box>
            {profile?.originalContent?.about?.length > 0 && (
              <>
                <VStack mx={'$screenMargin'} space="md">
                  <InfoExtender
                    object={profile}
                    field={'about'}
                    lang={i18n?.language}
                    loading={loading}
                    headerText={t('profile.about')}
                    showHeader={true}
                    limit={500}
                  />
                </VStack>
                <Box mx={'$screenMargin'}>
                  <Divider my={'$4'} />
                </Box>
              </>
            )}
            <UserCarsCarousel userId={userId} title={t('profile.listings')} />
            <VStack space={'md'} mx={'$screenMargin'}>
              <Heading>{t('profile.reviews')}</Heading>
              <UserReviews userId={userId} limit={5} />
            </VStack>
          </VStack>
        </ScrollView>
      </Layout>
    );
  }

  return (
    <Layout title={title} description={description} image={image}>
      <ScrollView>
        <Box w={'$full'} h={'$48'}>
          {loading ? (
            <Box w={'$full'} h={'$48'} bgColor="$light300" />
          ) : (
            <Image
              source={{
                uri: profile?.bannerURL ?? imagesUrl.defaultCover,
              }}
              w={'$full'}
              h={'$48'}
            />
          )}
          <Box position="absolute" alignSelf="center" top={0} w={maxWidth} h={'$48'}>
            <Pressable
              bgColor="$white"
              h={'$8'}
              w={'$8'}
              rounded={'$full'}
              alignContent="center"
              justifyContent="center"
              onPress={() => (navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Profile'))}
              position="absolute"
              left={'$4'}
              top={'$4'}
              hardShadow="1">
              <Icon as={Ionicons} name={'arrow-back-outline'} color="$black" />
            </Pressable>
            <Button position="absolute" right={'$4'} bottom={'$4'} bgColor="$white" size="sm" hardShadow="1" onPress={onShare}>
              <ButtonIcon as={Feather} name={'share'} color="$black" />
              <ButtonText color={'$black'}>{t('profile.share')}</ButtonText>
            </Button>
          </Box>
        </Box>
        <HStack w={maxWidth} alignSelf="center">
          <Box>
            <Box w={maxWidth / 3} mt={-64}>
              <ProfileBigCard
                avgRating={profile?.avgRating}
                loading={loading}
                countryCode={profile?.countryCode}
                createAt={profile?.createAt?.seconds}
                imageUrl={profile?.photoURL}
                name={profile?.name ?? `${profile?.firstName} ${profile?.lastName}`}
                numRatings={profile?.numRatings}
                numRentals={profile?.numRentals}
                numTrips={profile?.numTrips}
                isVerified={profile?.isVerified}
                isOwner={profile?.isOwner}
                canEdit={currentUser?.uid === profile?.uid}
              />
            </Box>
          </Box>
          <Box w={(maxWidth / 3) * 2}>
            <VStack m={'$8'} space="md">
              {profile?.originalContent?.about?.length > 0 && (
                <InfoExtender
                  object={profile}
                  field={'about'}
                  lang={i18n?.language}
                  loading={loading}
                  headerText={t('profile.about')}
                  showHeader={true}
                  limit={500}
                />
              )}
              <Tab.Navigator
                initialParams={{ profileId: userId }}
                screenOptions={{
                  ...tabScreenOption,
                  tabBarStyle: {
                    ...tabScreenOption.tabBarStyle,
                    width: '100%',
                    alignSelf: 'center',
                  },
                }}>
                <Tab.Screen
                  name="reviews"
                  children={(props) => <UserReviews userId={userId} limit={5} />}
                  options={{ title: title, tabBarLabel: t('profile.reviews') }}
                />
                {profile?.isOwner && (
                  <Tab.Screen
                    name="listings"
                    children={(props) => <UserCars userId={userId} title={t('profile.listings')} />}
                    options={{ title: title, tabBarLabel: t('profile.listings') }}
                  />
                )}
              </Tab.Navigator>
            </VStack>
          </Box>
        </HStack>
      </ScrollView>
    </Layout>
  );
}

export default PublicProfileScreen;
