import { createStyle } from '@gluestack-style/react';

export const FormControlLabelText = createStyle({
  fontWeight: '$normal',
  fontSize: '$secondary',
  color: '$black',
  _dark: {
    color: '$textDark50',
  },
});
