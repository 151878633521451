import React, { useMemo } from 'react';

import { Box, Text, useToken } from '@gluestack-ui/themed';

export default function Badge({ colorScheme, children }) {
  const color200 = useToken('colors', (colorScheme ?? 'light') + '200');
  const color900 = useToken('colors', (colorScheme ?? 'light') + '900');

  return (
    <Box bgColor={color200} rounded={'$md'}>
      <Text textAlign={'center'} color={color900} fontWeight={'$bold'} size={'secondary'} my={'$1'} mx={'$2'} alignSelf={'center'}>
        {children}
      </Text>
    </Box>
  );
}
