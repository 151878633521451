import React from 'react';

import { FormControlLabel, FormControlLabelText, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Select from '../../../shared/components/select/select';
import { carAddingSetter } from '../../../shared/reducers/car.reducer';
import { carFuelList, carTransmissionList, carTypeList } from '../../../shared/util/car-utils';

function SpecOfCar() {
  const car = useSelector((state) => state.cars.carAdding);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const setCar = (newCar) => dispatch(carAddingSetter(newCar));

  return (
    <VStack space={'md'}>
      <Text mb={'$4'} variant={'grey'} size={'secondary'}>
        {t('addCar.specOfCarDescription')}
      </Text>
      <VStack space={'xs'}>
        <FormControlLabel>
          <FormControlLabelText>{t('addCar.type')}</FormControlLabelText>
        </FormControlLabel>
        <Select
          testID={'type'}
          title={t('addCar.type')}
          data={carTypeList.map((catType) => ({ value: catType.name, label: t(catType.translateName) }))}
          onSelect={(item) => setCar({ ...car, type: item.value })}
          placeHolder={t('addCar.type')}
          value={car?.type}
        />
      </VStack>
      <VStack space={'xs'}>
        <FormControlLabel>
          <FormControlLabelText>{t('addCar.fuel')}</FormControlLabelText>
        </FormControlLabel>
        <Select
          testID={'fuel'}
          title={t('addCar.fuel')}
          data={carFuelList.map((fuelItem) => ({ value: fuelItem.name, label: t(fuelItem.translateName) }))}
          onSelect={(item) => setCar({ ...car, fuel: item.value })}
          placeHolder={t('addCar.fuel')}
          value={car?.fuel}
        />
      </VStack>
      <VStack space={'xs'}>
        <FormControlLabel>
          <FormControlLabelText>{t('addCar.transmission')}</FormControlLabelText>
        </FormControlLabel>
        <Select
          testID={'transmission'}
          title={t('addCar.transmission')}
          data={carTransmissionList.map((transmission) => ({ value: transmission.name, label: t(transmission.translateName) }))}
          onSelect={(item) => setCar({ ...car, transmission: item.value })}
          placeHolder={t('addCar.transmission')}
          value={car?.transmission}
        />
      </VStack>
      <VStack space={'xs'}>
        <FormControlLabel>
          <FormControlLabelText>{t('addCar.doors')}</FormControlLabelText>
        </FormControlLabel>
        <Select
          testID={'doors'}
          title={t('addCar.doors')}
          data={[
            { value: 3, label: '3' },
            { value: 5, label: '5' },
          ]}
          onSelect={(item) => setCar({ ...car, doors: item.value })}
          placeHolder={t('addCar.doors')}
          value={car?.doors}
        />
      </VStack>
      <VStack space={'xs'}>
        <FormControlLabel>
          <FormControlLabelText>{t('addCar.seats')}</FormControlLabelText>
        </FormControlLabel>
        <Select
          testID={'seats'}
          title={t('addCar.seats')}
          data={[
            { value: 2, label: '2' },
            { value: 3, label: '3' },
            { value: 4, label: '4' },
            { value: 5, label: '5' },
            { value: 6, label: '6' },
            { value: 7, label: '7' },
            { value: 8, label: '8' },
            { value: 9, label: '9' },
          ]}
          onSelect={(item) => setCar({ ...car, seats: item.value })}
          placeHolder={t('addCar.seats')}
          value={car?.seats}
        />
      </VStack>
      <VStack space={'xs'}>
        <FormControlLabel>
          <FormControlLabelText>{t('addCar.mileage')}</FormControlLabelText>
        </FormControlLabel>
        <Select
          testID={'mileage'}
          title={t('addCar.mileage')}
          data={[
            { value: 25000, label: '0 -> 50 000 km' },
            { value: 75000, label: '50 000 -> 100 000 km' },
            { value: 125000, label: '100 000 -> 150 000 km' },
            { value: 175000, label: '150 000 -> 200 000 km' },
            { value: 225000, label: '200 000 -> 250 000 km' },
            { value: 275000, label: '250 000 -> 300 000 km' },
            { value: 3000001, label: '+300 000 km' },
          ]}
          onSelect={(item) => setCar({ ...car, mileage: item.value })}
          placeHolder={t('addCar.mileage')}
          value={car?.mileage}
        />
      </VStack>
    </VStack>
  );
}

export default SpecOfCar;
