import React, { useEffect, useState } from 'react';

import { Box, FormControl, Text, Textarea, TextareaInput, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CarActions, { carAddingSetter } from '../../../shared/reducers/car.reducer';
import { getExtraInputProps } from '../../../shared/util/input-utils';

function CarDescription() {
  const car = useSelector((state) => state.cars.carAdding);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [text, setText] = useState(
    car?.originalContent && car?.originalContent?.carDescription ? car?.originalContent?.carDescription : t('addCar.carDescriptionExample'),
  );

  function changeText(textChange) {
    setText(textChange);
  }

  useEffect(() => {
    dispatch(
      carAddingSetter({
        ...car,
        originalContent: {
          ...car?.originalContent,
          carDescription: text,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    <VStack space={'md'}>
      <Text mb={'$4'} variant={'grey'} size={'secondary'}>
        {t('addCar.addCarDescriptionDescription')}
      </Text>
      <Box>
        <FormControl>
          <Textarea testID="input.carDescription" {...getExtraInputProps()}>
            <TextareaInput placeholder={t('addCar.carDescriptionExample')} defaultValue={text} onChangeText={changeText} />
          </Textarea>
        </FormControl>
      </Box>
    </VStack>
  );
}

export default CarDescription;
