import React, { useCallback, useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Heading, HStack, Icon, Link, LinkText, Text, VStack } from '@gluestack-ui/themed';
import { useTranslation } from 'react-i18next';

import LangSwitcher from './langSwitcher';
import { Images, Metrics } from '../../themes';
import imagesUrl from '../../themes/imagesUrl';
import { IS_WEB, screenBreakPoint } from '../../util/device-utils';
import { InternalLinks } from '../../util/link-utils';
import Image from '../image/image';

const Footer = () => {
  const { t, i18n } = useTranslation();

  const { width } = useWindowDimensions();

  const isMobile = useMemo(() => screenBreakPoint(width, [true, true, false, false, false]), [width]);

  const Spliter = useCallback(
    (props) =>
      isMobile ? (
        <VStack justifyContent={'center'} alignItems={'center'} {...props}>
          {props.children}
        </VStack>
      ) : (
        <HStack {...props}>{props.children}</HStack>
      ),
    [isMobile],
  );

  if (!IS_WEB) {
    return null;
  }
  return (
    <VStack
      p={'$6'}
      alignItems={'center'}
      space={'lg'}
      w={'100%'}
      justifyContent={'flex-end'}
      borderTopColor={'$light100'}
      borderTopWidth={1}
      mt={'$8'}>
      <Spliter justifyContent={'space-between'} space={'lg'} mx={'$screenMargin'}>
        <VStack space={'sm'} alignItems={'center'}>
          <Image source={{ uri: imagesUrl.brandNameDark }} h={51} w={119} resizeMode={'contain'} />
          <Link href={InternalLinks.appStore} isExternal>
            <Image source={{ uri: imagesUrl.appStoreDowload }} h={80} w={160} resizeMode={'contain'} />
          </Link>
          <Link href={InternalLinks.playStore} isExternal>
            <Image source={{ uri: imagesUrl.playStoreDowload }} h={80} w={160} resizeMode={'contain'} />
          </Link>
        </VStack>
        <VStack space={'lg'} alignItems="center">
          <Spliter space={'xl'}>
            <VStack space={'sm'} alignItems={isMobile ? 'center' : 'start'}>
              <Heading size={'h5'} alignSelf={isMobile ? 'center' : 'start'}>
                {t('footer.company')}
              </Heading>
              <Link href="https://www.flyandcar.com" isExternal>
                <LinkText size={'secondary'} color={'$black'}>
                  {t('footer.services')}
                </LinkText>
              </Link>
              <Link href={'/' + i18n.language + '/add-car'}>
                <LinkText size={'secondary'} color={'$black'}>
                  {t('footer.rentMyCar')}
                </LinkText>
              </Link>
              <Link href="https://www.flyandcar.com/contact" isExternal>
                <LinkText size={'secondary'} color={'$black'}>
                  {t('footer.getInTouch')}
                </LinkText>
              </Link>
            </VStack>

            <VStack space={'sm'} alignItems={isMobile ? 'center' : 'start'}>
              <Heading size={'h5'} alignSelf={isMobile ? 'center' : 'start'}>
                {t('footer.resources')}
              </Heading>
              <Link href="mailto:support@flyandcar.com">
                <LinkText size={'secondary'} color={'$black'}>
                  {t('footer.contact')}
                </LinkText>
              </Link>
              <Link href={InternalLinks.blog} isExternal>
                <LinkText size={'secondary'} color={'$black'}>
                  {t('footer.blog')}
                </LinkText>
              </Link>
              <Link href={InternalLinks.helpHome} isExternal>
                <LinkText size={'secondary'} color={'$black'}>
                  {t('footer.helpCenter')}
                </LinkText>
              </Link>
            </VStack>

            <VStack space={'sm'} alignItems={isMobile ? 'center' : 'start'}>
              <Heading size={'h5'} alignSelf={isMobile ? 'center' : 'start'}>
                {t('footer.termAndService')}
              </Heading>
              <Link href={InternalLinks.TOU} isExternal>
                <LinkText size={'secondary'} color={'$black'}>
                  {t('footer.TOU')}
                </LinkText>
              </Link>
              <Link href={InternalLinks.TOR} isExternal>
                <LinkText size={'secondary'} color={'$black'}>
                  {t('footer.TOR')}
                </LinkText>
              </Link>
              <Link href={InternalLinks.privacyPolicy} isExternal>
                <LinkText size={'secondary'} color={'$black'}>
                  {t('footer.privacyPolicy')}
                </LinkText>
              </Link>
              <Link href={InternalLinks.privacyPolicy} isExternal>
                <LinkText size={'secondary'} color={'$black'}>
                  {t('footer.cookiePolicy')}
                </LinkText>
              </Link>
            </VStack>
          </Spliter>
        </VStack>
      </Spliter>
      <HStack space={'md'}>
        <Link href="https://www.facebook.com/Flyncar/" isExternal>
          <Icon as={Ionicons} name="logo-facebook" size={'md'} color={'$blue900'} />
        </Link>
        <Link href="https://twitter.com/flyncar" isExternal>
          <Icon as={Ionicons} name="logo-twitter" size={'md'} color={'$blue900'} />
        </Link>
        <Link href="https://www.instagram.com/flyncar_app/" isExternal>
          <Icon as={Ionicons} name="logo-instagram" size={'md'} color={'$blue900'} />
        </Link>
      </HStack>
      <LangSwitcher />
      <Text alignSelf={'center'} size={'secondary'}>
        {t('footer.copyright')}
      </Text>
    </VStack>
  );
};

export default Footer;
